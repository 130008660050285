import {useState} from 'react';

export function useFetchWithErrorHandlingPdf() {
    const [error, setError] = useState<any>(null);

    if (error) {
        throw error;
    }

    return setError;
}

