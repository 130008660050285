import {MutableRefObject, ReactNode, forwardRef, useEffect, useRef} from "react";
import {RichTextEditorModule} from "ditmer-embla";
import Viewer from '@toast-ui/editor/dist/toastui-editor-viewer';
import useForwardedTextEditorActionsRef, { TextEditorActions } from "./useForwardedTextEditorActionsRef";
import classNames from "classnames";

type CustomButton = {
    options: toastui.ButtonOptions;
    onClick: () => void;
}

export type GenericTextEditorProps = {
    isViewer?: boolean;
    html?: string;
    markdown?: string;
    customButtons?: CustomButton[];
    showTableItem?: boolean;
    showImageItem?: boolean;
    additionalClassNames?: string;
    minHeightPx?: number;
    useConstantHeight?: boolean;
}

type TextEditorProps = {
    id: string
    onChange?: (markdown: string) => void;
    overrideTextarea?: ReactNode;
} & GenericTextEditorProps;

export const defaultTextEditorClasses = "default-rich-text-editor form-control";

export const TextEditor = forwardRef<TextEditorActions, TextEditorProps>(({
    id, 
    html, 
    markdown, 
    isViewer=false, 
    onChange, 
    additionalClassNames, 
    customButtons=[], 
    showTableItem=false, 
    showImageItem=false, 
    overrideTextarea,
    minHeightPx = 400,
    useConstantHeight = true,
}, forwardedRef
) => {
    const editor = useRef<RichTextEditorModule>(null)
    const viewer = useRef<toastui.Viewer>(null);

    const idSelector = `#${id}`;
    const editorContainerId = `editor-${id}-container`;

    useForwardedTextEditorActionsRef(forwardedRef, editor);

    useEffect(() => {
        if(isViewer) {
            (viewer as MutableRefObject<toastui.Viewer>).current = new Viewer({ el: $(idSelector)[0] });
        }
        else {
            if(!editor.current) {
                const minHeight = !!useConstantHeight ? undefined : `${minHeightPx}px`
                const height = !!useConstantHeight ? `${minHeightPx}px` : "auto";

                const editorModule = new RichTextEditorModule({
                    textArea: idSelector,
                    toolbarItems: { 
                        showTable: showTableItem,
                        showImage: showImageItem
                    },
                    minHeight: minHeight,
                    height: height,
                    onEditorChanged: () => {
                        if(onChange)
                            onChange(editor.current?.getMarkdown() ?? "");
                    },
                });

                const anyEditor = editorModule.editor as any;
                const toolbar = editorModule.editor.getUI().getToolbar();

                customButtons.forEach(btn => {

                    toolbar.addItem({ type: "button", options: btn.options });

                    anyEditor.eventManager?.addEventType(btn.options.event);
                    anyEditor.eventManager?.listen(btn.options.event, btn.onClick);
                });

                (editor as MutableRefObject<RichTextEditorModule>).current = editorModule;

                if (useConstantHeight) {
                    // Fix that min-height inside "tui-editor-contents" wouldn't be calculated intially in some cases:
                    const editorToolbarSectionHeight = 34;
                    const editorContentsMinHeightWithOffset = minHeightPx - editorToolbarSectionHeight;
  
                    const tuiContent = $(`#${editorContainerId}`).find("> * .tui-editor-contents[contenteditable=\"true\"]");
                    tuiContent.css("min-height", editorContentsMinHeightWithOffset); // Manually set "min-height" on editor-contents (prevent scrollbar)
                }
            }
        }

        // Removes data-tomark-pass attribute from all elements - target is the injected mergefields
        // Keeping the attribute will cause the following elements to loose their formatting when generating a PDF
        document.querySelectorAll('[data-tomark-pass]').forEach(e =>  e.removeAttribute('data-tomark-pass'));

    }, [customButtons, editorContainerId, id, idSelector, isViewer, minHeightPx, onChange, showImageItem, showTableItem, useConstantHeight]);

    
    useEffect(() => {
        if(!isViewer || !markdown) return;

        //Replace bruges for at editoren ikke formaterer \n\n som et <pre> element
        viewer.current?.setMarkdown(markdown.replaceAll("\n\n", "<br><br>"));
    }, [isViewer, markdown]);

    useEffect(() => {
        if(!html) return;

        editor.current?.editor.setHtml(html);
    }, [html]);

    return (
        <>
            <div id={editorContainerId} className="form-group margin-bottom-m">
                { !isViewer &&
                    <>
                        {overrideTextarea && 
                            overrideTextarea
                        }
       
                        {!overrideTextarea && 
                            <textarea
                                id={id}
                                name="default-rich-text-editor"
                                className={classNames(defaultTextEditorClasses, additionalClassNames)}
                                defaultValue={markdown}
                            />
                        }
                    </>
                }

                { isViewer &&
                    <div
                        id={id}
                        className="default-rich-text-editor"
                    />
                }
            </div>


        </>
    );
});
