import {DatasetModel} from "../datasetModel";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {toDate} from "../../../index";
import React from "react";
import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../../../core/layout/responsive";

interface DatasetCardSubheaderComponentProps {
    dataset: DatasetModel;
}

export function DatasetCardSubheaderComponent(props: DatasetCardSubheaderComponentProps) {
    const IsMobile = useMediaQuery(mobileMaxWidthQuery);

    const CreateXOfYString = (x?: number, y?: number, ofString= Localizer.global_af()) => {
        return `${x ?? 0} ${y ? ofString : ""} ${y ?? ""} `;
    }

    const OutOfMaxEvalueringerDisplayString = () => {

        const dataset = props.dataset;
        const antalEvalueringerIAlt = dataset.maxAmountEvalueringer === undefined ? 0 : dataset.maxAmountEvalueringer; //Denne er null, hvis der er under 3
        const antalForloebEvalueret = dataset.antalForloebEvalueretTotal ?? 0;
        const antalEvalueringerMedAdgang = dataset.uddannelsesstederEvalueringstatistik.map(e => e.antalEvalueringerMedAdgangTil).sum();

        if(antalForloebEvalueret === 0) {
            return !antalEvalueringerIAlt && antalEvalueringerMedAdgang === 0
                ? Localizer.evalueringPage_ingenEvalueringer()
                : Localizer.evalueringPage_underTreEvalueringer()
        }

        if (antalEvalueringerIAlt === 3 && antalForloebEvalueret < 3) {
            return Localizer.evalueringPage_underTreEvalueringer() + Localizer.evalueringPage_udAf() + antalEvalueringerIAlt + Localizer.evalueringPage_mulige()
        }

        const isEmpty = antalForloebEvalueret === 0;
        return isEmpty ? Localizer.evalueringPage_ingenEvalueringer() : CreateXOfYString(antalForloebEvalueret, antalEvalueringerIAlt === undefined ? 0 : antalEvalueringerIAlt);
    }

    function DetermineSpecialeDisplayString() {
        const hasSpecialer = props.dataset.uddannelsesspecialer && props.dataset.uddannelsesspecialer.length > 0;
        return hasSpecialer && props.dataset.isStandardVisning === false
            ? props.dataset.uddannelsesspecialer!.map((uddannelsesspeciale, index) => index === 0
                ? uddannelsesspeciale
                : uddannelsesspeciale).join(", ")
            : Localizer.global_all();
    }

    const DetermineUddannelsestypeDisplayString = () : string => {
        return (props.dataset.stillingsTypeName && props.dataset.stillingsTypeName.length === 0)
            ? Localizer.global_all()
            : props.dataset.stillingsTypeName?.join(", ") || Localizer.global_all();
    }

    const BreakPoint = () : JSX.Element => {
        if(IsMobile) {
            return <>&nbsp;</>;
        }

        return (
        <>
            &nbsp;
            <div> ⋅ </div>
            &nbsp;
        </>)
    }

    const SubheaderText = (title: string, text: string, addBreakPoint=true) : JSX.Element => {
        return (
        <>
            { addBreakPoint &&
                BreakPoint()
            }
            <div className="flex-container small wrap">
                <div className="subtle">{title}: </div>
                &nbsp;
                <div>{text}</div>
            </div>
        </>)
    }

    return (
        <>
            <div className="flex-container align-items-center wrap">
                {props.dataset.fromDate && props.dataset.toDate && <>
                    {SubheaderText(Localizer.global_periode(), `${toDate(props.dataset.fromDate).dateWithoutTimeFormat(true)} - ${toDate(props.dataset.toDate).dateWithoutTimeFormat(true)}`, false)}
                </>}
                {SubheaderText(Localizer.uddannelsesType(), DetermineUddannelsestypeDisplayString())}
                {SubheaderText(Localizer.global_speciale(), DetermineSpecialeDisplayString())}
                {SubheaderText(Localizer.evalueringPage_evalueringer(), OutOfMaxEvalueringerDisplayString())}

                {props.dataset.uddannelsesstederEvalueringstatistik.every(x => x.antalForloeb === 0) &&
                    <>
                        {BreakPoint()}
                        <div className="flex-container small">
                            <div className="subtle alert-warning">{Localizer.createEvalueringsstatistikPage_ingenForloebMedEvalueringer()}</div>
                        </div>
                    </>
                }
            </div>
        </>
    )
}
