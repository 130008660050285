import {Localizer} from "../../../infrastructure/localization/localizer";
import {
    erhvervsstyrelsesCookieBekendtgørelseUrl, 
    minecookiesUrl
} from "../../../infrastructure/redirectConstant";
import useScreenResolution from "core/hooks/useScreenResolution";
import ChangeYourCookieConsentBox from "./components/changeYourCookieConsentBox";
import useScrollToTopOnLink from "core/infrastructure/routing/hooks/useScrollToTopOnLink";
import CookiesInformationCookieList from "./components/cookiesInformationList/cookiesInformationList";
import useCookieConsentConfig from "core/components/cookies/hooks/useCookieConsentConfig";
import { getRequiredCookiesData, getStatisticsCookiesData } from "./components/cookiesInformationList/cookieInformationData";
import InformationPageSection from "../components/informationPageSection";

export const CookiesInformationPage = () => {
    useScrollToTopOnLink();
    const { isMobile } = useScreenResolution();
    const { cookieName, cookieOptions } = useCookieConsentConfig();

    const requiredCookiesData = getRequiredCookiesData(cookieName, cookieOptions.expires);
    const statisticsCookiesData = getStatisticsCookiesData();

  
    return (
        <div className="container">
            <div className="flex-container-column">
                <div className="margin-top-m margin-bottom-m">
                    <h2 className={`${isMobile ? "padding-top-l" : "flex-row-center padding-l"}`}>
                        {Localizer.cookiesPage_cookiesOnUddannelseslaegeTitle()}
                    </h2>
                    <p>
                        {Localizer.cookiesPage_cookiesOnUddannelseslaegeInfo()}
                    </p>
                </div>
            </div>
            <InformationPageSection
                title={Localizer.cookiesPage_whatIsACookieTitle()}
                contentText={Localizer.cookiesPage_whatIsACookieInfo()}
            />
            <InformationPageSection
                title={Localizer.cookiesPage_changeYourChoiceTitle()}
                contentText={Localizer.cookiesPage_changeYourChoiceInfo()}
            >
                <ChangeYourCookieConsentBox />
            </InformationPageSection>
            <InformationPageSection
                title={Localizer.cookiesPage_yourChoiceTitle()}
                contentText={Localizer.cookiesPage_yourChoiceInfoPart1()}
            >
                <p>
                    {Localizer.cookiesPage_yourChoiceInfoPart2()}
                </p>
            </InformationPageSection>
            <InformationPageSection
                title={Localizer.cookiesPage_yesToCookiesTitle()}
                contentText={Localizer.cookiesPage_yesToCookiesInfo()}
            >
                <ul>
                    <li>{Localizer.cookiesPage_yesToCookiesPointRememberConsent()}</li>
                    <li>{Localizer.cookiesPage_yesToCookiesPointMakeTheSiteWork()}</li>
                    <li>{Localizer.cookiesPage_yesToCookiesPointMitId()}</li>
                    <li>{Localizer.cookiesPage_yesToCookiesPointStatistics()}</li>
                </ul>
            </InformationPageSection>
            <InformationPageSection
                title={Localizer.cookiesPage_noToCookiesTitle()}
                contentText={Localizer.cookiesPage_noToCookiesInfo()}
            >
                <ul>
                    <li>{Localizer.cookiesPage_noToCookiesPointRememberConsent()}</li>
                    <li>{Localizer.cookiesPage_noToCookiesPointMakeTheSiteWork()}</li>
                    <li>{Localizer.cookiesPage_noToCookiesPointMitId()}</li>
                </ul>
                <span>{Localizer.cookiesPage_noToCookiesNoStatisticsInfo()}</span>
            </InformationPageSection>
            
            <InformationPageSection
                title={Localizer.cookiesPage_requiredCookiesTitle()}
                contentText={Localizer.cookiesPage_requiredCookiesInfo()}
            >
                <CookiesInformationCookieList 
                    cookiesData={requiredCookiesData} 
                />
                <p>{Localizer.cookiesPage_cookiesListSessionCookiesInfo()}</p>
            </InformationPageSection>
            <InformationPageSection
                title={Localizer.cookiesPage_statisticsCookiesTitle()}
                contentText={Localizer.cookiesPage_statisticsCookiesInfo()}
            >
                <CookiesInformationCookieList 
                    cookiesData={statisticsCookiesData} 
                />
            </InformationPageSection>
            <InformationPageSection
                title={Localizer.cookiesPage_whyDoWeInformTitle()}
                contentText={Localizer.cookiesPage_whyDoWeInformInfo()}
            >
                <p>
                    {Localizer.cookiesPage_whyDoWeInformInfoPreUrl()}
                    {" "}
                    <a href={erhvervsstyrelsesCookieBekendtgørelseUrl}>
                        {Localizer.cookiesPage_whyDoWeInformInfoBekendtgoerelseUrl()}
                    </a>
                </p>
            </InformationPageSection>
            <InformationPageSection
                title={Localizer.cookiesPage_howToDeletedCookiesTitle()}
            >
                <p>
                    {Localizer.cookiesPage_howToDeletedCookiesInfoPreUrl()}
                    <a href={minecookiesUrl}>{Localizer.cookiesPage_howToDeletedCookiesInfoUrl()}</a> 
                    {Localizer.cookiesPage_howToDeletedCookiesInfoPostUrl()}
                </p>
            </InformationPageSection>
        </div>
    )
}

