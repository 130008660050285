import React from "react";
import {YesNoRadio} from "../../kompetenceEvaluering/yesNoRadio";
import CalendarTimeDisplay from "../../../core/components/timedisplayers/Calendar";
import {SvarReadTypeProps} from "./svarReadTypeCompareMap";

export function DatoOgGodkendtReadSvar ({ evalueringSvar, renderId } : SvarReadTypeProps) {
    return (
        <div className={"margin-bottom-m"}>
            <CalendarTimeDisplay dato={evalueringSvar.dateSvar} additionalClasses={"margin-bottom-s"}/>
            <YesNoRadio id={evalueringSvar.id + renderId} yesNo={evalueringSvar.booleanSvar} disabled/>
        </div>
    )
}
