import {SkiftMaalbeskrivelseInputModel} from "../../../core/componentsPage/forloeb/skiftMaalbeskrivelseInputModel";


export class SkiftMaalbeskrivelsePostModel {
    constructor(inputModel: SkiftMaalbeskrivelseInputModel) {
        this.brugerId = inputModel.brugerId;
        this.forloebId = inputModel.forloebId;
        this.uddannelseId = inputModel.uddannelseId;
        this.nyMaalbeskrivelseId = inputModel.nyMaalbeskrivelseId;
        this.gammelMaalbeskrivelseId = inputModel.gammelMaalbeskrivelseId;
    }

    public brugerId: string;
    public forloebId: string;
    public uddannelseId: string;
    public gammelMaalbeskrivelseId: string;
    public nyMaalbeskrivelseId: string;
}

