import React from "react";
import {SvarInputTypeProps} from "./svarInputTypeCompareMap";
import {Localizer} from "../../../infrastructure/localization/localizer";

export function NummerInputSvar({spoergsmaal, svarNummerIndex, kompetenceSvarList, indsendt, nummerCallback}: SvarInputTypeProps) {
    return (<div className={"margin-bottom-m"}
                 key={spoergsmaal.id + svarNummerIndex}>
        <label
            htmlFor={`nummerSvar ${svarNummerIndex}`}>{Localizer.evalueringPage_angivNummeriskVaerdi()}</label>
        <input type="number"
               name={`nummerSvar ${svarNummerIndex}`}
               className="form-control"
               defaultValue={kompetenceSvarList.find(x => x.svarIndex === svarNummerIndex)?.nummerSvar ?? undefined}
               onBlur={(e) => nummerCallback(parseInt(e.target.value), svarNummerIndex)}
               disabled={indsendt}
               placeholder={Localizer.evalueringPage_angivNummeriskSvar()}/>
    </div>)
}
