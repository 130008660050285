import {PaginationModel} from "../../../sharedmodels/filter/PaginationModel";
import {Localizer} from "../../../../infrastructure/localization/localizer";
import {Loading} from "../../loading/loading";
import { CSSProperties } from "react";

const FilterXOutOfYCountText = (props: { xCount: string | number, yCount: string | number, showLoadingX: boolean, showLoadingY: boolean }) => {
    const notationItemCssProps: CSSProperties = {
        marginRight: '3px' //Spacing between string elements
    };

    //Make sure each string-element is in a different element, so that google-translate doesn't break this:
    return (
        <div className={"flex-container-row"}>
            <span style={notationItemCssProps}>{Localizer.global_filtrerede()}</span>
            <span translate="no" style={notationItemCssProps}>
                <Loading isLoading={props.showLoadingX} spinnerSize={"p-size"} timeout={2000} hideContentOnPreload={false}>
                    {props.xCount}
                </Loading>
            </span>
            <span style={notationItemCssProps}>{Localizer.evalueringPage_udAf()}</span>
            <span translate="no" style={notationItemCssProps}>
                <Loading isLoading={props.showLoadingY} spinnerSize={"p-size"} timeout={2000} hideContentOnPreload={false}>
                    {props.yCount}
                </Loading>
            </span>
        </div>
    )
}

export type FilterNotationProps = {
    paginationModel: PaginationModel;
    filteredRecords: number;
    customTotalRecords?: number;
    xOfYisLoading?: boolean;
    hidePaginatedCount?: boolean;
    additionalContainerClasses?: string;
}
const FilterNotation = ({
    paginationModel, 
    filteredRecords, 
    customTotalRecords, 
    xOfYisLoading = false,
    hidePaginatedCount = false,
    additionalContainerClasses,
} : FilterNotationProps) => {

    const recordsTotal = customTotalRecords === 0 || customTotalRecords === undefined ? paginationModel.recordCount : customTotalRecords;
    const startItem = ((paginationModel.page - 1) * paginationModel.pageLength) + 1;
    const totalPageNumber = Math.ceil(filteredRecords / paginationModel.pageLength);
    const endItem = totalPageNumber === paginationModel.page ? filteredRecords : (paginationModel.page * paginationModel.pageLength);

    const showPaginationCountOutOfFiltered = !hidePaginatedCount && recordsTotal > 0;

    return (
        <div className={additionalContainerClasses}>
            <FilterXOutOfYCountText
                xCount={filteredRecords}
                yCount={recordsTotal}
                showLoadingX={xOfYisLoading}
                showLoadingY={xOfYisLoading}
            />
            {showPaginationCountOutOfFiltered &&
                <FilterXOutOfYCountText 
                    xCount={`${startItem}-${endItem}`}
                    yCount={filteredRecords}
                    showLoadingX={false} // shouldn't show loading for "start-end"
                    showLoadingY={xOfYisLoading}
                />
            }
        </div>
    )
}

export default FilterNotation;
