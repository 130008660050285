import { useLayoutEffect } from "react"
import { useLocation } from "react-router-dom";

/** Hook for scrolling to the top of the page, when linking to the current page (only initial render) */
const useScrollToTopOnLink = () => {
  const location = useLocation();

    // scroll to top of page after a page transition.
    useLayoutEffect(() => {
        document.documentElement.scrollTo({ top:0, left:0, behavior: "auto" });
    }, [location.pathname]);
}

export default useScrollToTopOnLink;
