import {createContext, ReactNode, useCallback, useContext, useReducer} from "react";
import {UddannelseslaegeStatusEnum} from "./components/UddannelseslaegeStatusEnum";
import {LaegeWithSimpleForloebModel} from "core/sharedmodels/forloeb/laegeWithForloebModel";
import useUser from "../../core/hooks/useUser";

export type UddannelseslaegerFilterModel = {
    status: UddannelseslaegeStatusEnum[];
    search: string;
    speciale?: string;
    uddannelseSted?: string;
}

export type UddannelseslaegerContextType = {
    filter: UddannelseslaegerFilterModel;
    original: LaegeWithSimpleForloebModel[];
    filtered: LaegeWithSimpleForloebModel[];
    isLoading: boolean;
}

const UddannelseslaegerContext = createContext<{
    state: UddannelseslaegerContextType,
    setOriginalLaeger: (payload: LaegeWithSimpleForloebModel[]) => void,
    setFilteredLaeger: (payload: LaegeWithSimpleForloebModel[]) => void,
    setIsLoading: (payload: boolean) => void,
    setFilter: (payload: UddannelseslaegerFilterModel) => void,
} | undefined>(undefined);

enum UddannelseslaegerReducerActionType {
    SetFilter,
    SetOriginalLaeger,
    SetFilteredLaeger,
    SetIsLoading,
}

const uddannelseslaegerReducer = (
    state: UddannelseslaegerContextType,
    action: { type: UddannelseslaegerReducerActionType, payload: Partial<UddannelseslaegerContextType>}
): UddannelseslaegerContextType => {
    switch(action.type) {
        case UddannelseslaegerReducerActionType.SetOriginalLaeger:
            return { ...state,
                original: action.payload.original ?? state.original
            }
        case UddannelseslaegerReducerActionType.SetFilteredLaeger:
            return { ...state,
                filtered: action.payload.filtered ?? state.filtered
            }
        case UddannelseslaegerReducerActionType.SetIsLoading:
            return { ...state,
                isLoading: action.payload.isLoading ?? state.isLoading
            }
        case UddannelseslaegerReducerActionType.SetFilter:
            return { ...state,
                filter: action.payload.filter ?? state.filter
            }
    }
}

export const useUddannelseslaegerContext = () => {
    const context = useContext(UddannelseslaegerContext);
    if (!context) throw new Error("useUddannelseslaegerContext must be used in UddannelseslaegerContextProvider");

    return context;
};

type UddannelseslaegerContextProviderProps = { children?: ReactNode; }

const UddannelseslaegerContextProvider = ({ children }: UddannelseslaegerContextProviderProps) => {
    const { currentUser } = useUser();

    const [state, dispatch] = useReducer(uddannelseslaegerReducer, {
        filter: {
            status: currentUser.IsVejleder() ? [] : [UddannelseslaegeStatusEnum.Nuvaerenede],
            speciale: undefined,
            uddannelseSted: undefined,
            search: "",
        },
        original: [],
        filtered: [],
        isLoading: false,
    });

    const setFilter = useCallback((payload: UddannelseslaegerFilterModel) => {
        dispatch({
            type: UddannelseslaegerReducerActionType.SetFilter,
            payload: { filter: payload }
        });
    }, []);

    const setOriginalLaeger = useCallback((payload: LaegeWithSimpleForloebModel[]) => {
        dispatch({
            type: UddannelseslaegerReducerActionType.SetOriginalLaeger,
            payload: { original: payload }
        });
    }, []);

    const setFilteredLaeger = useCallback((payload: LaegeWithSimpleForloebModel[]) => {
        dispatch({
            type: UddannelseslaegerReducerActionType.SetFilteredLaeger,
            payload: { filtered: payload }
        });
    }, []);

    const setIsLoading = useCallback((payload: boolean) => {
        dispatch({
            type: UddannelseslaegerReducerActionType.SetIsLoading,
            payload: { isLoading: payload }
        });
    }, []);

    return (
        <UddannelseslaegerContext.Provider 
            value={{
                state,
                setOriginalLaeger,
                setFilteredLaeger,
                setIsLoading,
                setFilter,
            }}
        >
            {children}
        </UddannelseslaegerContext.Provider>
    );
};

export default UddannelseslaegerContextProvider;
