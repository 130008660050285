import {Localizer} from "../../../infrastructure/localization/localizer";

export enum SpoergsmaalTypeEnum {
    Grupperet = 1,
    Enkeltvis = 2,
}
export function GetSpoergsmaalstypeTranslation(type?: SpoergsmaalTypeEnum) {
    switch (type) {
        case SpoergsmaalTypeEnum.Grupperet:
            return Localizer.evalueringPage_grupperet();
        case SpoergsmaalTypeEnum.Enkeltvis:
            return Localizer.evalueringPage_enkeltvis();
        default:
            return "";
    }
}
