import {EvalueringsstatistikFiltersState} from "./evalueringsstatistikFiltersSlice";
import {UddannelsesstedModel} from "../../core/sharedmodels/laegeInstitution/uddannelsesstedModel";
import {SpoergsmaalTypeEnum} from "../../core/sharedmodels/evaluering/spoergsmaalTypeEnum";
import {RootTypeEnum} from "../../core/sharedmodels/laegeInstitution/rootTypeEnum";
import { toDate, toNullableDate } from "index";

export class EvalueringsstatistikFilterModel {
    constructor(allChecked?: boolean,
                regioner?: Array<string>,
                alleRegioner?: boolean,
                sygehuse?: Array<string>,
                praksis?: Array<UddannelsesstedModel>,
                uddannelsessteder?: Array<UddannelsesstedModel>,
                afdelinger?: Array<UddannelsesstedModel>,
                specialer?: Array<string>,
                spoergsmaalsType?: SpoergsmaalTypeEnum,
                spoergsmaalToShow?: string[],
                uddannelsestyper?: Array<string>,
                laegeinstitutionstype?: RootTypeEnum,
                startDato?: Date,
                slutDato?: Date,
                includeInactive?: boolean,
    ) {
        this.regioner = regioner;
        this.alleRegioner = alleRegioner;
        this.laegeinstitutionstype = laegeinstitutionstype;
        this.allChecked = allChecked;
        this.sygehuse = sygehuse;
        this.praksis = praksis;
        this.spoergsmaalsType = spoergsmaalsType;
        this.spoergsmaalToShow = spoergsmaalToShow;
        this.uddannelsessteder = uddannelsessteder;
        this.afdelinger = afdelinger;
        this.specialer = specialer;
        this.uddannelsestyper = uddannelsestyper;
        this.startDato = startDato;
        this.slutDato = slutDato;
        this.includeInactive = includeInactive;
    }

    regioner?: Array<string>;
    alleRegioner?: boolean;
    laegeinstitutionstype?: RootTypeEnum;
    sygehuse?: Array<string>;
    allChecked?: boolean;
    praksis?: UddannelsesstedModel[];
    spoergsmaalsType?: SpoergsmaalTypeEnum;
    spoergsmaalToShow?: string[];
    uddannelsessteder?: UddannelsesstedModel[];
    afdelinger?: UddannelsesstedModel[];
    refreshFilterPing?: boolean;
    specialer?: Array<string>;
    uddannelsestyper?: Array<string>;
    startDato?: Date;
    slutDato?: Date;
    includeInactive?: boolean;
}

export function filterToState(inputModel: EvalueringsstatistikFilterModel) : EvalueringsstatistikFiltersState {
    return {
        regioner: inputModel?.regioner,
        alleRegioner: inputModel.alleRegioner,
        laegeinstitutionstype: inputModel.laegeinstitutionstype,
        specialer: inputModel?.specialer,
        sygehuse: inputModel?.sygehuse,
        allChecked: inputModel?.allChecked,
        spoergsmaalsType: inputModel?.spoergsmaalsType,
        spoergsmaalToShow: inputModel?.spoergsmaalToShow,
        praksis: inputModel?.praksis,
        uddannelsessteder: inputModel?.uddannelsessteder,
        afdelinger: inputModel?.afdelinger,
        refreshFilterPing: inputModel?.refreshFilterPing,
        uddannelsestyper: inputModel?.uddannelsestyper,
        startDato: inputModel?.startDato?.toIsoDateTimeString(),
        slutDato: inputModel?.slutDato?.toIsoDateTimeString(),
        includeInactive: inputModel?.includeInactive
    } as EvalueringsstatistikFiltersState;
}

export function FromState(state: EvalueringsstatistikFiltersState): EvalueringsstatistikFilterModel {
    const model = new EvalueringsstatistikFilterModel();
    model.regioner = state.regioner?.map(x => x.id);
    model.alleRegioner = state.alleRegioner;
    model.laegeinstitutionstype = state.laegeinstitutionstype;
    model.specialer = state.specialer?.map(x => x.id);
    model.sygehuse = state.sygehuse?.map(x => x.id);
    model.uddannelsessteder = state.uddannelsessteder;
    model.afdelinger = state.afdelinger;
    model.uddannelsestyper = state.uddannelsestyper?.map(x => x.id);
    model.startDato = toNullableDate(state.startDato);
    model.slutDato = toNullableDate(state.slutDato);
    model.includeInactive = state.includeInactive;
    return model;
}
