import { calculateForloebColumnNumber } from "../forloebOverviewUtils/forloebOverviewUtils";
import { todayDayjs } from "index";
import { defaultMonthColPixelWidth } from "../forloebOverviewContainer";
import { DateFilterType } from "../ForloebOverviewDateFilterModel";
import dayjs from "dayjs";
import StyleVariables, { asPixelString, calculateDayPixel, createVariables } from "./StyleVariables";

const currentMonthLinePixelWidth = 2;
const maxPixelsPerDiv = defaultMonthColPixelWidth - currentMonthLinePixelWidth;
const noClass = "";

const getPixelsPerDay = (date: dayjs.Dayjs) => calculateDayPixel(date, maxPixelsPerDiv);

const getOverlayStyleVariables = (monthColumn: number, startDateColumn: number, endDateColumn: number, endDayjs: dayjs.Dayjs, startDayjs: dayjs.Dayjs) : StyleVariables  => {
    const emptyLine = createVariables(0, 0);
    const isStartDate = monthColumn === startDateColumn;
    const isEndDate = monthColumn === endDateColumn;
    if(isEndDate && isStartDate){
        const endPixels = calculateDayPixel(endDayjs, maxPixelsPerDiv);
        const endOffSet = Math.abs(endPixels - currentMonthLinePixelWidth);
        return {
            Line1: createVariables(0, getPixelsPerDay(startDayjs)),
            Line2: createVariables(endOffSet, defaultMonthColPixelWidth)
        };
    }

    const isBeforeStart = monthColumn < startDateColumn;
    const isAfterEnd = monthColumn > endDateColumn;
    if(isBeforeStart || isAfterEnd) return {
        Line1: createVariables(0, defaultMonthColPixelWidth),
        Line2: emptyLine
    };

    if(isEndDate) {
        const pixels = getPixelsPerDay(endDayjs);
        const offSet = pixels;
        return {
            Line1: createVariables(offSet, defaultMonthColPixelWidth),
            Line2: emptyLine
        };
    }

    const width = isStartDate ? getPixelsPerDay(startDayjs) : 0;
    return {
        Line1: createVariables(0, width),
        Line2: emptyLine
    }
};

type MonthProps = {
    monthKey: string;
    monthIndex: number;
    year: number;
    years: number[];
    paginationRowIndex: number;
    currentMonthColumn: number;
    endDateColumn: number;
    startDateColumn: number;
    dateFilterType: DateFilterType;
    endDayjs: dayjs.Dayjs;
    startDayjs: dayjs.Dayjs;
    renderOverlay: boolean;
};

const MonthColumn = ({monthKey, monthIndex, year, years, paginationRowIndex, currentMonthColumn, startDateColumn, endDateColumn, endDayjs, startDayjs, renderOverlay}: MonthProps) : JSX.Element => {
    const monthColumn = calculateForloebColumnNumber(monthIndex, year, years);
    const isCurrentMonth = monthColumn === currentMonthColumn;

    const dayAmountPixel = isCurrentMonth ? getPixelsPerDay(todayDayjs()) : 0;
    const overlayStyle = getOverlayStyleVariables(monthColumn, startDateColumn, endDateColumn, endDayjs, startDayjs);
    const hasOverlay = overlayStyle.Line1.leftOffset > 0 || overlayStyle.Line1.width > 0;
    const shouldApplyOverlay = hasOverlay && renderOverlay;

    const monthStyleClass = isCurrentMonth ? "current-month" : noClass;
    const overlayClass = shouldApplyOverlay ? "overlay" : noClass;
    const leftBorderClass = shouldApplyOverlay ? "grid-box-left-transparent" : "grid-box-left";
    const style = {
        "--month-column-index": monthColumn,
        "--laege-row-index": paginationRowIndex + 1,

        // Red Line
        "--day-amount-pixel": asPixelString(dayAmountPixel),
        "--current-month-line-width": asPixelString(currentMonthLinePixelWidth),

        // Overlays
        "--month-overlay-width": asPixelString(overlayStyle.Line1.width),
        "--month-overlay-left-offset": asPixelString(overlayStyle.Line1.leftOffset),
        "--month-overlay-2-width": asPixelString(overlayStyle.Line2.width),
        "--month-overlay-2-left-offset": asPixelString(overlayStyle.Line2.leftOffset),
    } as React.CSSProperties;

    return (
        <div key={monthKey} className={`forloeb-month-line ${monthStyleClass} ${overlayClass} ${leftBorderClass}`} style={style}>
            {renderOverlay && isCurrentMonth &&
                <div className={`month-outer current-month-with-overlay`}>
                    &nbsp;
                </div>
            }
        </div>
    );
}

export default MonthColumn;
