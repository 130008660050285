export enum AlertType {
    Primary = 1,
    Secondary = 2,
    Success = 3,
    Danger = 4,
    Warning = 5,
    Info = 6,
    Light = 7,
    Dark = 8
}

export const GetAlertCssClassFromAlertType = (alertType: AlertType) : string => {
    switch (alertType){
        case AlertType.Primary:
            return "alert-primary";
        case AlertType.Secondary:
            return "alert-secondary";
        case AlertType.Success:
            return "alert-success";
        case AlertType.Danger:
            return "alert-danger";
        case AlertType.Warning:
            return "alert-warning";
        case AlertType.Info:
            return "alert-info";
        case AlertType.Light:
            return "alert-light";
        case AlertType.Dark:
            return "alert-dark";

    }
}
