import useStamdataApi from "core/hooks/useStamdataApi";
import { useState, useCallback, useEffect } from "react";
import { StamdataUddannelseModel } from "services/api/stamdataUddannelse/stamdataUddannelseModel";

const useUddannelseModels = () => {
    const { stamdataUddannelseApi } = useStamdataApi();

    const [uddannelseModels, setUddannelseModels] = useState<StamdataUddannelseModel[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchUddannelsesModels = async () => {
            setUddannelseModels(await stamdataUddannelseApi.getUddannelser());
            setIsLoading(false);
        }

        fetchUddannelsesModels();
    }, [stamdataUddannelseApi]);

    const toUddannelsesTyperFromId = useCallback((uddannelseTyperIds: string[]) => {
        return uddannelseModels.filter(u => uddannelseTyperIds.includes(u.id)).map(u => u.uddannelseType);
    }, [uddannelseModels]);

    const toIdsFromUddannelsesTyper = useCallback((uddannelseTyper: number[] = []) => {
        return uddannelseModels.filter(u => uddannelseTyper.includes(u.uddannelseType)).map(u => u.id);
    }, [uddannelseModels]);

    return {
        uddannelseModels,
        toUddannelsesTyperFromId,
        toIdsFromUddannelsesTyper,
        isLoading,
    }
}

export default useUddannelseModels;