import { FileMetadataList } from "core/components/fileMetadata/fileMetadataList";
import useLogbogApi from "core/hooks/useLogbogApi";
import useUser from "core/hooks/useUser";
import { FileLocationEnum } from "core/sharedmodels/file/fileLocationEnum";
import { BasicNotatModel } from "core/sharedmodels/notat/basicNotatModel";
import { NotatType } from "core/sharedmodels/notat/notatType";
import { NotificationModule } from "ditmer-embla";
import { Localizer } from "infrastructure/localization/localizer";
import { useState } from "react";
import {STPSDokumentationUploadAllowedFileTypes} from "../../components/fileUpload/allowedFileTypes";
import { AsyncButton } from "core/components/button/asyncButton";
import { EmblaIcon } from "core/components/emblaIcon/emblaIcon";
import { EmblaIcons } from "core/emblaIcons";
import Title from "core/components/titels/title";

type attestationForTidFilesProps = {
    forloebId: string;
    userId: string;
    brugerMaalbeskrivelseId: string;
    filesUpdatedCallback?: (basicNotatModel: BasicNotatModel) => void;
    basicNotatModel?: BasicNotatModel;
    isReadonly?: boolean;
    isGodkendt?: boolean;
};

const AttestationForTidFileList = ({forloebId, userId, filesUpdatedCallback, basicNotatModel, brugerMaalbeskrivelseId, isReadonly=false, isGodkendt=false}: attestationForTidFilesProps) => {
    const { currentUser } = useUser();
    const { notatApi, logbogExportApi } = useLogbogApi();

    const [currentBasicNotatModel, setCurrentBasicNotatModel] = useState<BasicNotatModel | undefined>(basicNotatModel);

    const handleSuccessNotifications = (newBasicNotatModel: BasicNotatModel) => {
        if (currentBasicNotatModel) {
            const hasUploadedFiles = newBasicNotatModel.fileMetadatas.length > currentBasicNotatModel.fileMetadatas.length;
            if (!hasUploadedFiles) return;
        }

        NotificationModule.showSuccess(Localizer.forloebEdit_FileUploadedSuccess(), "");
    }

    const fileUploaded = async () => {
        let attestationForTidNotat = await notatApi.getNotatOnContext(NotatType.AttestationForTid, userId, forloebId);
        if (!attestationForTidNotat) return;

        setCurrentBasicNotatModel(attestationForTidNotat);
        handleSuccessNotifications(attestationForTidNotat);

        if(filesUpdatedCallback)
            filesUpdatedCallback(attestationForTidNotat);
    }

    const hasAccessToDownload = currentUser.IsAdminOrVus() || currentUser.IsLaege();
    const subTitleText = currentUser.IsSekretaer() ? "" : Localizer.forloebpage_AttestationForTidDescription();

    const showDeleteBtn = !isReadonly && !isGodkendt && (currentUser.IsAdminOrVus() || currentUser.IsUddannelsesansvarlig() || currentUser.IsLaege());
    const showDownloadBtn = (currentUser.IsAdminOrVus() || currentUser.IsUddannelsesansvarlig() || currentUser.IsLaege());
    const showUploader = !currentUser.IsSekretaer() && !isReadonly;

    return (
        <div className='margin-bottom-m'>

            {!isReadonly &&
                <Title title={Localizer.forloebpage_Attestation()} titleClasses="margin-bottom-s" subTitle={subTitleText} largeSubTitle>
                    {hasAccessToDownload &&
                        <AsyncButton
                            buttonText={Localizer.GetPdf()}
                            additionalClasses={`margin-bottom-m`}
                            clickFunction={async () => await logbogExportApi.getAttesationPdf(userId, brugerMaalbeskrivelseId, forloebId)}
                            child={<EmblaIcon iconName={EmblaIcons.Download}/>}
                        />
                    }
                </Title>
            }

            <div>
                <FileMetadataList
                    fileMetadatas={basicNotatModel?.fileMetadatas ?? []}
                    showDeleteButton={showDeleteBtn}
                    overrideFileTypes={STPSDokumentationUploadAllowedFileTypes}
                    notatInfo={{
                        contextId: forloebId,
                        notatType: NotatType.AttestationForTid,
                        userId: userId
                    }}
                    notat={currentBasicNotatModel}
                    withUploader={showUploader}
                    fileLocation={FileLocationEnum.Logbog}
                    filesUpdatedCallback={() => fileUploaded()}
                    withDownload={showDownloadBtn}
                />
            </div>
        </div>
    );
};

export default AttestationForTidFileList;
