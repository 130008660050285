import {Localizer} from "../../../infrastructure/localization/localizer";
import {useEffect, useState} from "react";
import {useRouteMatch} from "react-router-dom";
import PdfComponent from "core/components/pdf/pdfComponent";
import {useFetchWithErrorHandlingPdf} from "./useFetchWithErrorHandlingPdf";
import useLogbogApi from "../../hooks/useLogbogApi";
import {getDateDisplayString, getTimeDisplayString, setDocumentPdfAsReady} from "./helpers/pdfHelper";
import {VejledersamtaleModel} from "pages/vejledning/vejledersamtaler/vejledersamtaleModel";
import VejledersamtalePdfModel from "pages/vejledning/vejledersamtaler/vejledersamtalePdfModel";
import UserNameInfoSimpleModel from "../userProfile/userNameInfoSimpleModel";
import {PdfKompetenceHeaderComponent} from "./pdfHeaders/pdfKompetenceHeaderComponent";
import PdfHeaderRow from "./pdfHeaderRow";
import PdfRow from "./pdfRow";
import NoteTextViewer from "core/componentsPage/notat/tekstNotat/noteTextViewer";
import {NotatType} from "core/sharedmodels/notat/notatType";
import HtmlHeading from "../titels/HtmlHeadings";
import {dateTimeStringFromApiToDate} from "../../../index";

export type VejledersamtalePdfParams = {
    brugerId: string,
    brugerMaalbeskrivelseId: string,
    forloebId: string,
    stillingId: string
};

export const shouldFetchUserNames = (appointment: VejledersamtaleModel) => !(appointment.publicAccess || appointment.isPrivate) && appointment.visibleToUserIds.length > 0;

export const DetermineVisibility = (appointment: VejledersamtaleModel, userInfos: UserNameInfoSimpleModel[]) => {
    if (appointment.publicAccess) return Localizer.visibleToEveryoneWithAccessToLaege();
    if (appointment.isPrivate) return Localizer.visibleToUddannelseslaegeAndVejleder();
    if (appointment.visibleToUserIds.length === 0) return "";

    const idSet = new Set<string>(appointment.visibleToUserIds);
    return userInfos.filter(x => idSet.has(x.userId)).map(x => x.name).join(", ");
}

const VejledersamtalePdf = () => {

    const {vejledersamtaleApi, logbogUserInfoApi} = useLogbogApi();

    const [vejledersamtaler, setVejledersamtaler] = useState<VejledersamtalePdfModel[]>();

    const {brugerId, brugerMaalbeskrivelseId, forloebId, stillingId} = useRouteMatch<VejledersamtalePdfParams>().params;
    const setError = useFetchWithErrorHandlingPdf();

    useEffect(() => {

        const fetchVejledersamtaler = async (): Promise<VejledersamtalePdfModel[]> => {
            const samtaler = await vejledersamtaleApi.getVejledersamtalerByForloeb(forloebId);

            const userIdsToFetchNamesFor = samtaler.filter(x => shouldFetchUserNames(x)).flatMap(x => x.visibleToUserIds);
            const userInfos = await logbogUserInfoApi.getUserNamesInfo(userIdsToFetchNamesFor);

            return samtaler.sortBy(s => s.dato).map((s) => ({
                title: s.titel,
                referat: s.referat,
                vejlederName: s.vejlederNavn,
                visibility: DetermineVisibility(s, userInfos),
                dateDisplay: getDateDisplayString(s.dato),
                timeDisplay: getTimeDisplayString(s.starttidspunkt, s.sluttidspunkt),
                klarmeldtAt: s.klarmeldtAt,
                afholdtTilkendegivetDato: getDateDisplayString(s.afholdtTilkendegivetDato),
                afholdtTilkendegivetAf: s.afholdtTilkendegivetAf
            }));
        };

        fetchVejledersamtaler()
            .then(data => setVejledersamtaler(data))
            .catch(err => setError(err))
            .finally(() => setDocumentPdfAsReady());

    }, [forloebId, logbogUserInfoApi, setError, vejledersamtaleApi]);

    return (
        <PdfComponent>
            <PdfComponent.Title title={Localizer.global_vejledersamtaler()} heading={HtmlHeading.H3}/>

            <PdfKompetenceHeaderComponent
                brugerId={brugerId}
                brugerMaalbeskrivelseId={brugerMaalbeskrivelseId}
                stillingInfo={{
                    stillingId: stillingId,
                    forloebId: forloebId
                }}
            />

            <PdfComponent.BlockItem className={"card shadow-none"}>
                <PdfComponent.CardHeader>
                    <PdfHeaderRow asFragment>
                        <PdfHeaderRow.Col width={1}>{Localizer.global_title()}</PdfHeaderRow.Col>
                        <PdfHeaderRow.Col>{Localizer.referat()}</PdfHeaderRow.Col>
                        <PdfHeaderRow.Col>{Localizer.vejleder()}</PdfHeaderRow.Col>
                        <PdfHeaderRow.Col>{Localizer.global_visibility()}</PdfHeaderRow.Col>
                        <PdfHeaderRow.Col>{Localizer.dato()}</PdfHeaderRow.Col>
                        <PdfHeaderRow.Col width={1}>{Localizer.global_klarmeldtden()}</PdfHeaderRow.Col>
                        <PdfHeaderRow.Col>{Localizer.global_afholdt()}</PdfHeaderRow.Col>
                    </PdfHeaderRow>
                </PdfComponent.CardHeader>
                {vejledersamtaler &&
                    vejledersamtaler.map((u, i) =>
                        <PdfComponent.NonBreakableItem>
                            <PdfComponent.CardBody>
                                <PdfRow className="flex-align-c">
                                    <PdfRow.Col width={1}>{u.title}</PdfRow.Col>
                                    <PdfRow.Col>
                                        <NoteTextViewer
                                            id={"vejledersamtale-" + i}
                                            text={u.referat}
                                            type={NotatType.Vejledersamtale}
                                        />
                                    </PdfRow.Col>
                                    <PdfRow.Col>{u.vejlederName}</PdfRow.Col>
                                    <PdfRow.Col>{u.visibility}</PdfRow.Col>
                                    <PdfRow.Col>{u.dateDisplay}<br/>{u.timeDisplay}</PdfRow.Col>
                                    <PdfRow.Col
                                        width={1}>{u.klarmeldtAt ? dateTimeStringFromApiToDate(u.klarmeldtAt).dateWithoutTimeFormat(true) : ""}</PdfRow.Col>
                                    <PdfRow.Col>
                                        {u.afholdtTilkendegivetDato &&
                                            `${u.afholdtTilkendegivetAf} d.${u.afholdtTilkendegivetDato}`
                                        }
                                    </PdfRow.Col>
                                </PdfRow>
                            </PdfComponent.CardBody>
                        </PdfComponent.NonBreakableItem>
                    )
                }
            </PdfComponent.BlockItem>

        </PdfComponent>
    );
}

export default VejledersamtalePdf;
