import { TextEditor } from "core/components/textEditor/textEditor";
import { NotatType } from "core/sharedmodels/notat/notatType";

const useTextEditorForTypes: Set<NotatType> = new Set([
    NotatType.TekstNotat,
    NotatType.Merit,
    NotatType.Uddannelsesplan,
    NotatType.Kompetence,
    NotatType.Vejledersamtale
]);

const NoteTextViewer = ({id, text: description, type} : {id: string, text: string, type: NotatType}) => {
    
    return (
        <>
            {useTextEditorForTypes.has(type)
                ?
                    <div>
                        <TextEditor
                            id={id}
                            markdown={description}
                            isViewer
                        />
                    </div>
                :
                <p>{description}</p>
            }
        </>
    )
}

export default NoteTextViewer;
