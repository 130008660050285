import React, {useRef, useState} from 'react'
import { Localizer } from 'infrastructure/localization/localizer'
import { useRouteMatch } from "react-router-dom";
import { ValidationForm } from 'core/components/validation/components/validationForm';
import useEffectAsync from 'infrastructure/effect';
import { HttpClientService } from 'services/httpClient/httpClientService';
import { AccountService } from 'services/account/accountService';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { setSlideinState, SlideInStateEnum } from 'core/components/slideIn/slideInSlice';
import { ValidationFormSubmitButton } from 'core/components/validation/components/validationFormButton';
import { HandleError } from 'core/infrastructure/errors/errorBoundary';
import { BuildNew, CrudForloebInputModel, crudForloebValidationSchema, crudForloebWarningSchema } from './crudForloebInputModel';
import { ForloebApi } from "../../../services/api/forloeb/forloebApi";
import { forloebTableReducer, ForloebTableState } from 'pages/forloebList/forloebTableSlice';
import {ForloebModel} from "../../sharedmodels/forloeb/forloebModel";
import {ForloebCrudFields} from "./forloebCrudFields";
import {NotificationModule} from "ditmer-embla";
import {forloebReducer, ForloebSliceState, pingRefreshForloeb, setCurrentForloeb} from "../../forloebSlice";
import {ValidationTextarea} from "../../components/validation/components/validationTextarea";
import {nameof} from "ts-simple-nameof";

const forloebApi = new ForloebApi(new HttpClientService(), new AccountService())

export function CreateForloeb(props: { modalId: string }) {
    const routeMatch = useRouteMatch();
    const forloebTableState = useAppSelector(forloebTableReducer) as ForloebTableState;
    const dispatch = useAppDispatch();
    const _currentUser = useRef(new AccountService().getUser());
    const [createForloebFailed, setCreateForloebFailed] = useState(false);

    const uddannelseslaegeId = (routeMatch.params as { id: string }).id;
    const [forloebListState, setForloebListState] = useState<ForloebModel[]>([]);

    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    const createForloeb = async (inputModel: CrudForloebInputModel) => {

        dispatch(setSlideinState({ state: SlideInStateEnum.ActionOngoing, slideInId: props.modalId }));
        
        const newForloeb = await forloebApi.createForloeb(inputModel);

        if (newForloeb) {
            setCreateForloebFailed(false);
            dispatch(setSlideinState({ state: SlideInStateEnum.Closing, slideInId: props.modalId }))
            NotificationModule.showSuccess(Localizer.forloebOverviewPage_createdForloeb(), "");
        }

        if (uddannelseslaegeId) {
            const forloebList = await forloebApi.getForloebByBruger(uddannelseslaegeId);
            if (forloebSliceState.forloebState.id !== undefined && !forloebList.some(f => f.id === forloebSliceState.forloebState.id)) {
                const ikkeFravaerForloebModel = forloebList.filter(f => !f.erFravaer)[0];
                if (ikkeFravaerForloebModel) {
                    dispatch(setCurrentForloeb(ikkeFravaerForloebModel.ToState()))
                    dispatch(pingRefreshForloeb())
                }
            }
        }
    }

    useEffectAsync(async () => {
        if (uddannelseslaegeId)
            setForloebListState(await forloebApi.getForloebByBruger(uddannelseslaegeId));
    }, [uddannelseslaegeId, forloebTableState])

    const render = (forloebListState &&
        <ValidationForm
            key={forloebListState.map(f => f.id).join()}
            initialValues={BuildNew(uddannelseslaegeId, forloebListState)}
            validationSchema={crudForloebValidationSchema}
            warningSchema={crudForloebWarningSchema}
            onSubmit={async (values) => {
                await createForloeb(values)
                    .catch(err => { // catch is needed to handle unhandeled exception (eg. api offline). Formik onsubmithandler is catching all exception and writing them as warnings
                        HandleError(err);
                        setCreateForloebFailed(true);
                        dispatch(setSlideinState({ state: SlideInStateEnum.Opened, slideInId: props.modalId }))
                    });
            }}>
            {
                (formik) => (
                    <>
                        <ForloebCrudFields modalId={props.modalId} formik={formik} />
                        {(_currentUser.current.IsAdminOrVus() ||_currentUser.current.IsHrMedarbejder()) &&
                            <div className="row">
                                <div className="col-12">
                                    <ValidationTextarea
                                        model={{
                                            label: Localizer.forloebpage_BemaerkningerTilForloeb(),
                                            placeholder: Localizer.forloebpage_BemaerkningerTilForloebLabel(),
                                            htmlName: nameof<CrudForloebInputModel>(x => x.infoTekst)
                                        }}
                                        inputId={nameof<CrudForloebInputModel>(x => x.infoTekst)}
                                        formikProps={formik}
                                    />
                                </div>
                            </div>
                        }
                        <div className="modal-actions">
                            {createForloebFailed &&
                                <div className="form-group margin-bottom-m">
                                    <div className="field-validation-error">{Localizer.forloebpage_createFailed()}</div>
                                </div>
                            }

                            <ValidationFormSubmitButton formikProps={formik}>
                                {Localizer.forloebpage_createForloeb()}
                            </ValidationFormSubmitButton>
                        </div>
                    </>
                )
            }
        </ValidationForm>
    )

    return <>{render}</>
}
