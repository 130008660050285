import React, {useEffect} from "react";
import './accordionCard.scss';
import {EmblaIcon} from "../emblaIcon/emblaIcon";
import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../../layout/responsive";

export interface IAccordionItem {
    id: string;
    header: string;
    content: JSX.Element;
    isExpanded: boolean;
}

export interface IAccordionCardProps {
    accordionId: string;
    accordionItem: IAccordionItem;
    useCallbackForToggling?: (id: string) => void
}

export function AccordionCard(props: IAccordionCardProps) {
    const IsMobile = useMediaQuery(mobileMaxWidthQuery);
    const collapsibleItemId = "collapsible-" + props.accordionItem.id;
    const headingId = "heading-" + props.accordionItem.id;

    useEffect(() => {
        if (props.accordionItem.isExpanded)
            $("#" + collapsibleItemId).collapse("show");
        else {
            $("#" + collapsibleItemId).collapse("hide");
        }
    }, [collapsibleItemId, props.accordionItem.isExpanded])

    const toggleExpanded = () => {
        if (props.useCallbackForToggling) {
            props.useCallbackForToggling(props.accordionItem.id)
        }
    }

    return (
        <>
            <div className="card accordion-card">
                <div className="card-header accordion-card-header" id={`#${headingId}`}>
                    <h2 className="mb-0">
                        <button className="accordion-item-button btn btn-link btn-block text-left"
                                role="button"
                                data-toggle="collapse"
                                data-target={`#${collapsibleItemId}`}
                                aria-expanded="false"
                                aria-controls={`${collapsibleItemId}`}
                                onClick={toggleExpanded}>
                            <div className={"flex-container flex-space flex-align-c"}>
                                {IsMobile &&
                                    <h5 className="margin-right-m">
                                        {props.accordionItem.header}
                                    </h5>
                                }
                                {!IsMobile &&
                                    <h4 className="margin-right-m">
                                        {props.accordionItem.header}
                                    </h4>
                                }

                                <div>
                                    {props.accordionItem.isExpanded &&
                                      <EmblaIcon iconName="arrow-up"/>
                                    }
                                    {!props.accordionItem.isExpanded &&
                                      <EmblaIcon iconName="arrow-down"/>
                                    }
                                </div>
                            </div>
                        </button>
                    </h2>
                </div>
                <div id={collapsibleItemId}
                     className={"collapse"}
                     aria-labelledby={`${headingId}`}
                     data-parent={`#${props.accordionId}`}>
                    <div className="card-body">
                        {props.accordionItem.content}
                    </div>
                </div>
            </div>
        </>
    );
}
