import {ErrorMessage, useField } from "formik";
import React, { forwardRef } from "react";
import {ValidationDatepickerProps} from "../validationModel";
import {ProcessValidationInput} from "../ValidationInputHelper";
import {Datepicker, DefaultDatePickerType} from "../../datePicker/datePicker";
import { DatetimePickerModule } from "ditmer-embla";

export const ValidationDatepicker = forwardRef<DatetimePickerModule, ValidationDatepickerProps & Omit<React.HTMLProps<HTMLInputElement>, "ref">>(({
    datePickerType=DefaultDatePickerType, inputId, minimumDate, maximumDate, model, allowClear, readOnly=false, additionalWrapperDivClasses="", ...props}, forwardedRef
) => {
    const [field, meta, helpers] = useField<Date>(model.htmlName);
    const validationInputResult = ProcessValidationInput(meta.error);

    return (
        <>
            <div className={`form-group margin-bottom-m ${additionalWrapperDivClasses}`}>
                <Datepicker
                    ref={forwardedRef}
                    id={inputId}
                    label={model.label}
                    allowClear={allowClear}
                    datePickerType={datePickerType}
                    minimumDate={minimumDate}
                    maximumDate={maximumDate}
                    setDate={(date: Date) => {
                        helpers.setValue(date);

                        if (props.dateSelected){
                            props.dateSelected(date);
                        }
                    }}
                    additionalIconDivClasses={props.additionalIconClasses}
                    appendToInputGroup={props.appendToInputGroup}
                    defaultValue={field.value}
                    formName={field.name}
                    htmlName={model.htmlName}
                    disabled={readOnly}
                    formikField={{
                        field: field,
                        meta: meta,
                        helpers: helpers,
                    }}
                    placeholderText={props.placeholder ?? model.placeholder}
                />

                {validationInputResult?.IsWarning &&
                    <span className={`errorMessage text-warning`}>{validationInputResult.Message}</span>
                }

                {!validationInputResult.IsWarning &&
                    <ErrorMessage name={field.name}>
                        {() => <span className={`errorMessage field-validation-error`}>{validationInputResult.Message}</span>}
                    </ErrorMessage>
                }


            </div>
        </>
    );
});

