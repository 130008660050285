import * as yup from "yup";
import createValidationSchema from "../../components/validation/createValidationSchema";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {EvalueringsstatistikFiltersState} from "../../../pages/evalueringsstatistik/evalueringsstatistikFiltersSlice";
import {DatasetModel} from "../../../pages/evalueringsstatistik/datasetModel";
import { toDate, toNullableDate } from "index";
import {UddannelseTypeEnum} from "../../sharedmodels/stilling/uddannelseTypeEnum";
import dayjs from "dayjs";

export class EvalueringsstatistikInputModel {
    public startDato?: Date;
    public slutDato?: Date;
    public specialer?: string[];
    public uddannelsesstedIds?: string[];
    public uddannelsestyper?: UddannelseTypeEnum[];
    public includeInactive?: boolean;

    public constructor(init?:Partial<EvalueringsstatistikInputModel>) {
        Object.assign(this, init);
    }
}

export const BuildNew = (filterModel: EvalueringsstatistikFiltersState, startDato?: Date, slutDato?: Date): EvalueringsstatistikInputModel => {
    let inputModel = new EvalueringsstatistikInputModel();
    inputModel.startDato = startDato;
    inputModel.slutDato = slutDato;
    inputModel.uddannelsesstedIds = filterModel.uddannelsessteder?.filter(a => a.selected === true).map(a => a.id);
    inputModel.specialer = filterModel.specialer?.map(x => x.id) ?? [];
    inputModel.uddannelsestyper = filterModel.uddannelsestyper?.map(a => parseInt(a.id));
    inputModel.includeInactive = filterModel.includeInactive;
    return inputModel;
}

export const BuildInitial = (filterModel: EvalueringsstatistikFiltersState): EvalueringsstatistikInputModel => {
    let inputModel = new EvalueringsstatistikInputModel();
    inputModel.startDato = toNullableDate(filterModel.startDato);
    inputModel.slutDato = toNullableDate(filterModel.slutDato);
    inputModel.uddannelsesstedIds = filterModel.uddannelsessteder?.filter(a => a.selected === true).map(a => a.id);
    inputModel.specialer = filterModel.specialer?.map(x => x.id) ?? [];
    inputModel.uddannelsestyper = filterModel.uddannelsestyper?.map(a => parseInt(a.id));
    inputModel.includeInactive = filterModel.includeInactive;
    return inputModel;
}

export const BuildNewFromDataset = (filterModel: DatasetModel): EvalueringsstatistikInputModel => {
    let inputModel = new EvalueringsstatistikInputModel();
    inputModel.startDato = toNullableDate(filterModel.fromDate);
    inputModel.slutDato = toNullableDate(filterModel.toDate);
    inputModel.uddannelsesstedIds = filterModel.uddannelsessteder.map(x => x.id);
    inputModel.specialer = filterModel.specialeIds ?? [];
    inputModel.uddannelsestyper = filterModel?.stillingstyper?.map((str => parseInt(str)));
    return inputModel;
}

export const evalueringsstatistikValidationSchema = createValidationSchema<EvalueringsstatistikInputModel>({
    startDato: yup.date().required(Localizer.validate_startDatoRequired()),
    slutDato: yup.date().required(Localizer.validate_slutDatoRequired())
        .test(
            "date-validation",
            Localizer.validate_dateInvalid(),
            (value) => dayjs(value).isValid()
        )
        .test(
            "date-validation",
            Localizer.validate_slutDatoBeforeStartdate(),
            function (value) {
                const parent = this.parent as EvalueringsstatistikInputModel;
                return !value || !parent.startDato || value >= parent.startDato
            }
        ),
    specialer: yup.array().notRequired(),
    uddannelsestype: yup.object().nullable().notRequired(),
});
