import { Localizer } from "infrastructure/localization/localizer";
import { SetTitleBar } from "../../core/layout/titleBar/titleBarApi";
import { KompetenceOverview } from "./details/kompetenceOverview";

export type KompetencerOverviewPageRouteParams = {
    id: string;
    laegeKompetenceId?: string;
    activeTab?: string;
    getAllRegardlessOfMaalbeskrivelse?: string;
}

export function KompetencerOverviewPage() {

    SetTitleBar(Localizer.global_kompetencer(),
        [{ text: Localizer.global_kompetencer(), url: "" }]
    );
    return (
        <div className="card top-level-container">
            <KompetenceOverview />
        </div>
    );
}
