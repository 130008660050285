import BrevskabelonPdf from "../components/brevskabelonPdf";
import generatePdfFromJsxElement from "core/helpers/generatePdfFromJsxElement";

const useBrevMergeFieldPdfGenerator = (subject: string, htmlString: string) => {

    const generatePdf = async () => {
        await generatePdfFromJsxElement(subject, <BrevskabelonPdf subject={subject} htmlString={htmlString}/>);
    }

    return {
        generatePdf
    }
}

export default useBrevMergeFieldPdfGenerator;
