import EducationFilter, { EducationFilterResult } from "core/components/filter/components/educationFilter";
import PlaceFilter, { PlaceFilterResult } from "core/components/filter/components/placeFilter";
import Title from "core/components/titels/title";
import useUser from "core/hooks/useUser";
import UserFilterPermissions from "core/sharedmodels/userPermissions/userFilterPermissions";
import { Localizer } from "infrastructure/localization/localizer";

export const emptyEducationFilterValues: EducationFilterResult = {
    specialer: [],
    uddannelser: [],
};

export const emptyPlaceFilterValues: PlaceFilterResult = {
    regioner: [],
    omraader: [],
    afdelinger: [],
    praksiser: [],
    sygehuse: [],
    allRegionsSelected: false,
};

type FileFiltersType = {
    permissions: UserFilterPermissions;
    disable?: boolean;
    onEducationFilterResult: (result: EducationFilterResult) => void;
    onPlaceFilterResult: (result: PlaceFilterResult) => void;
    initialSelectedPlaceValues?: PlaceFilterResult;
    initialSelectedEducationValues?: EducationFilterResult;
}

const FileFilters = ({
    permissions,
    disable=false,
    onEducationFilterResult,
    onPlaceFilterResult,
    initialSelectedEducationValues,
    initialSelectedPlaceValues,
} : FileFiltersType) => {

    const { currentUser } = useUser();

    return (
        <Title title={Localizer.filePage_fileRelationsTitle()} >
            <EducationFilter
                permissions={permissions}
                onChange={onEducationFilterResult}
                disable={disable}
                initialSelectedValues={initialSelectedEducationValues} 
            />

            <PlaceFilter
                permissions={permissions}
                onChange={onPlaceFilterResult}
                disable={disable}
                visualizationConfig={currentUser.IsAdminOrVus() ? undefined : {
                    showRegioner: currentUser.IsKonsulentForLaegeligVidereuddannelse(),
                    showOmraader: currentUser.IsKonsulentForLaegeligVidereuddannelse(),
                    showPraksiser: currentUser.IsUddannelsesansvarlig() || currentUser.IsSekretaer(),
                    showSygehuse: currentUser.IsLedelse(),
                    showAfdelinger: currentUser.IsUddannelsesansvarlig() || currentUser.IsSekretaer(),
                }}
                initialSelectedValues={initialSelectedPlaceValues}
            /> 

            <div className="margin-top-m subtle">
                <p className="small">{Localizer.filePage_uploadWarningMain()}</p>
                <p className="small">{Localizer.filePage_uploadWarningSub()}</p>
            </div>

        </Title>
    )
}

export default FileFilters;