import CreateValidationSchema from "../../components/validation/createValidationSchema";
import * as yup from "yup";

export class CreateAttestationInputModel {
    public forloebId: string;
    public InfoTekst?: string;
}

export const BuildNew = (forloebId: string, infoText?: string): CreateAttestationInputModel => {
    let inputModel = new CreateAttestationInputModel();
    inputModel.forloebId = forloebId;
    inputModel.InfoTekst = infoText;

    return inputModel;
}

export const CreateAttestationValidationSchema = CreateValidationSchema<CreateAttestationInputModel>({
    forloebId: yup.string().notRequired(),
    InfoTekst: yup.string().notRequired().nullable()
})
