import { useState, useEffect } from "react";
import useStamdataApi from "./useStamdataApi";
import { FileMetadata } from "core/sharedmodels/fileMetaData/fileMetaData";

const useSpecialeSpecificFiles = (kompetenceId: string, regionId: string, brugerMaalbeskrivelseId: string) => {

    const { stamdataKompetenceApi } = useStamdataApi();

    const [allFileMetaData, setAllFileMetaData] = useState<FileMetadata[]>([]);

    useEffect(() => {
        const fetchFiles = async () => {
            if(!kompetenceId || !regionId || !brugerMaalbeskrivelseId) return;
            
            setAllFileMetaData((await stamdataKompetenceApi.getSpecialeSpecificFiles({kompetenceId, regionId, brugerMaalbeskrivelseId}))
                .filter(f => f.createdAt = new Date(f.createdAt) // Hack: Not doing this will make the the date formating fail.
            ));
        }

        fetchFiles();
    }, [brugerMaalbeskrivelseId, kompetenceId, regionId, stamdataKompetenceApi]);

    return {
        allFileMetaData
    }
}

export default useSpecialeSpecificFiles;