import React, {useEffect} from "react";
import {v4 as uuid} from "uuid";
import {AccordionCard, IAccordionItem} from "./accordionCard";

export interface IAccordionProps {
    elements: IAccordionItem[];
    useCallbackForToggling?: (id: string) => void
}

export function Accordion(props: IAccordionProps) {
    const accordionId = "accordion-" + uuid();

    useEffect(() => {
    }, [])

    return (
        <>
            <div className="accordion" id={accordionId}>
                {props.elements.map((element) =>
                    <React.Fragment key={element.id}>
                        <AccordionCard accordionId={accordionId}
                                       accordionItem={element}
                                       useCallbackForToggling={props.useCallbackForToggling}/>
                    </React.Fragment>
                )}
            </div>
        </>
    );
}
