import React from "react";
import {YesNoRadio} from "../../kompetenceEvaluering/yesNoRadio";
import {SvarInputTypeProps} from "./svarInputTypeCompareMap";

export function BooleanInputSvar({spoergsmaal, svarNummerIndex, kompetenceSvarList, indsendt, booleanCallback}: SvarInputTypeProps) {
    return (<div key={spoergsmaal.id + svarNummerIndex}>
        <YesNoRadio
            id={spoergsmaal.id + svarNummerIndex}
            yesNo={kompetenceSvarList.find(x => x.svarIndex === svarNummerIndex)?.booleanSvar}
            addBooleanSvar={x => booleanCallback(x, svarNummerIndex)}
            disabled={indsendt}/>
    </div>)
}
