import React, {useEffect, useState} from 'react'
import {Localizer} from 'infrastructure/localization/localizer'
import {Form, Formik, FormikProps} from "formik";
import {setSlideinState, SlideInStateEnum} from "../../../core/components/slideIn/slideInSlice";
import {
    BuildExistingCrudVejlederInputModel,
    createVejlederValidationSchema,
    CrudVejlederInputModel
} from "./crudVejlederInputModel";
import {useAppDispatch} from "../../../app/hooks";
import {VejlederCrudFields} from "./vejlederCrudFields";
import {HandleError} from "../../../core/infrastructure/errors/errorBoundary";
import {VejlederEditModel} from "./vejlederEditModel";
import {NotificationModule} from "ditmer-embla";
import {Loading} from "../../../core/components/loading/loading";
import {VejlederEditResponseModel} from "../../../services/api/stamdataVejlederApi/vejlederEditResponseModel";
import useStamdataApi from 'core/hooks/useStamdataApi';
import { nameof } from 'ts-simple-nameof';

type EditVejlederPageProps = {
    modalId: string,
    vejlederTilknytningId: string;
    readonly?: boolean;
}

export const VejlederEditFormId = 'VejlederEditForm';

export const VejlederEdit = ({vejlederTilknytningId, ...props}: EditVejlederPageProps) => {
    const dispatch = useAppDispatch();
    const [vejlederEditModel, setvejlederEditModel] = useState<VejlederEditModel>(new VejlederEditModel(new VejlederEditResponseModel()));
    const [isLoading, setIsLoading] = useState(true);

    const {stamdataVejlederApi} = useStamdataApi();

    const editVejleder = async (inputModel: CrudVejlederInputModel) => {
        dispatch(setSlideinState({state: SlideInStateEnum.ActionOngoing, slideInId: props.modalId}))
        const redigeretUddannelsesstedTilknytning = await stamdataVejlederApi.editVejleder(inputModel);
        if (redigeretUddannelsesstedTilknytning) {
            NotificationModule.showSuccess(Localizer.vejlederpage_vejlederEditted(), "")
        } else {
            NotificationModule.showError(Localizer.vejlederPage_editFailed(), "")
        }
        dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: props.modalId}));
    }

    useEffect(() => {
        const fetch = async () => {
            setvejlederEditModel(await stamdataVejlederApi.getVejlederEditModel(vejlederTilknytningId));
            setIsLoading(false)
        }
        
        fetch();
    }, [stamdataVejlederApi, vejlederTilknytningId])

    const render = (
        <Loading isLoading={isLoading} text={Localizer.profilepage_getVejleder()}>
            {!isLoading &&
                <Formik
                    initialValues={BuildExistingCrudVejlederInputModel(vejlederEditModel)}
                    validationSchema={createVejlederValidationSchema}
                    onSubmit={async (values: CrudVejlederInputModel) => {
                        await editVejleder(values)
                            .catch(err => { // catch is needed to handle unhandeled exception (eg. api offline). Formik onsubmithandler is catching all exception and writing them as warnings
                                dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: props.modalId}))
                                HandleError(err);
                            });
                    }}>
                    {
                        (formik) => (
                            <Form id={VejlederEditFormId}>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h5 className="margin-bottom-s">{vejlederEditModel.NavnAndEmail}</h5>
                                    </div>
                                </div>

                                <div className="margin-bottom-m" key={1}>
                                    <VejlederCrudFields
                                        uddannelsesstedNumber={1}
                                        totalUddannelsessteder={1}
                                        modalId={props.modalId}
                                        formik={formik}
                                        vejlederEditModel={vejlederEditModel}
                                        vejlederId={vejlederEditModel.BrugerId}
                                        readonly={props.readonly}
                                    />
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            }
        </Loading>
    )

    return <>{render}</>
}
