import React from 'react';
import './stateSwitchButtonGroup.scss';

type AriaLabel = "Left"|"Right";

export type StateSwitchButtonElement<T> = {
    selectedClassAction: () => boolean;
    state: T;
    text: string;
}

export type StateSwitchButtonInputProps<T> = {
    buttonInputs: Array<StateSwitchButtonElement<T>>;
    setStateAction: (state: T) => void;
    selectedClasses?: string;
    nonSelectedClasses?: string;
}

/** Each element in given array will result in a button being added to the group.
 * - Button styleclasses can be overwritten in props.*/
export function StateSwitchButtonGroup<T>(props:StateSwitchButtonInputProps<T>) : JSX.Element {
    
    const DefaultSelectedClasses = "btn btn-primary";
    const DefaultNonselectedClasses = "btn btn-default";
    const SelectedClasses = props.selectedClasses ?? DefaultSelectedClasses;
    const NonselectedClasses = props.nonSelectedClasses ?? DefaultNonselectedClasses;

    function StateButton(element: StateSwitchButtonElement<T>, ariaLabel: AriaLabel) : JSX.Element {
        return (
            <button 
                type="button"
                className={element.selectedClassAction() ? SelectedClasses : NonselectedClasses}
                aria-label={ariaLabel}
                onClick={() => props.setStateAction(element.state)}>
                {element.text}
            </button>
        )
    }

    return(
        <div className="btn-group" role="group">
            {props.buttonInputs.map((element: StateSwitchButtonElement<T>, index: number) => (
                <React.Fragment key={element.text}>
                    {StateButton(element, index === 0 ? "Left" : "Right")}
                </React.Fragment>
            ))}
        </div>
    );
}