import * as yup from "yup";
import createValidationSchema from "../../../core/components/validation/createValidationSchema";
import {Localizer} from "../../../infrastructure/localization/localizer";

export class SamletGodkendelsePaaAndetGrundlagInputModel {
    constructor(laegekursusGruppeId: string, uddannelsessted: string) {
        this.laegekursusGruppeId = laegekursusGruppeId;
        this.uddannelsessted = uddannelsessted;
        this.godkendelseKommentar = "";
    }
    public laegekursusGruppeId: string;
    public godkendelseKommentar: string;
    public uddannelsessted: string;
}

export const BuildExisting = (godkendelseKommentar: string, laegekursusGruppeId: string, uddannelsessted: string): SamletGodkendelsePaaAndetGrundlagInputModel => {
    const inputModel = new SamletGodkendelsePaaAndetGrundlagInputModel(laegekursusGruppeId, uddannelsessted);
    inputModel.godkendelseKommentar = godkendelseKommentar;
    return inputModel;
}

export const crudLaegekursusGruppeSamletGodkendelseValidationSchema = createValidationSchema<SamletGodkendelsePaaAndetGrundlagInputModel>({
    godkendelseKommentar: yup.string().required(Localizer.validate_kommentarRequiredNaarDerGodkendesPaaAndetGrundlag()),
    uddannelsessted: yup.string().notRequired(),
    laegekursusGruppeId: yup.string().notRequired(),
});
