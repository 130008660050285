import React from 'react'
import {LaegekursusModel} from "../../../../services/api/laegekursus/laegekursusModel";
import {LaegekursusStatusEnum} from "../../../../core/sharedmodels/kursus/laegekursusStatusEnum";
import {LaegekursusGruppeTypeEnum} from "../../../../core/sharedmodels/kursus/laegekursusGruppeTypeEnum";
import {Localizer} from "../../../../infrastructure/localization/localizer";
import {EmblaIcon} from "../../../../core/components/emblaIcon/emblaIcon";
import {ReactComponent as GreenCircle} from "../../../../content/icons/greenCircle.svg";
import {ReactComponent as BlueCircle} from "../../../../content/icons/blueCircle.svg";
import {HttpClientService} from "../../../../services/httpClient/httpClientService";
import {AccountService} from "../../../../services/account/accountService";
import {LogbogLaegekursusApi} from "../../../../services/api/laegekursus/logbogLaegekursusApi";
import {ConfirmButton} from "../../../../core/components/button/confirmButton";
import {NotificationModule} from "ditmer-embla";

interface DeletedLaegekursusCardProps {
    laegekursus: LaegekursusModel;
    laegekursusRestoredCallback: () => void;
}

export function DeletedLaegekursusCard(props: DeletedLaegekursusCardProps) {
    const hasAnyNotater = props.laegekursus.notatFileCount > 0;
    const manglerHandling = (props.laegekursus.status === LaegekursusStatusEnum.ManglerHandling);

    const logbogLaegekursusApi = new LogbogLaegekursusApi(new HttpClientService(), new AccountService());

    const kursusErGrundkursusIPsykoterapi = props.laegekursus.gruppeType === LaegekursusGruppeTypeEnum.GrundkursusIPsykoterapi;

    async function gendanKursus() {
        const gendannetLaegekursusId = await logbogLaegekursusApi.gendanLaegekursus(props.laegekursus.id);
        if (gendannetLaegekursusId) {
            NotificationModule.showSuccess(Localizer.kursusPage_gendannet(), "");
            props.laegekursusRestoredCallback();
        } else {
            NotificationModule.showError(Localizer.kursusPage_fejlVedGendannelseafKurset(), "");
        }
    }

    return (
        <React.Fragment>
            <div className="flex-container">
                <div className="flex-space flex-column flex-grow">
                    <div className="flex-align-c ">
                        <h5>{props.laegekursus.titel}</h5>
                        <div className="d-flex flex-align-r">
                            <ConfirmButton
                                icon={"before"}
                                asButton={true}
                                buttonTekst={Localizer.global_genskab()}
                                confirmCallback={() => gendanKursus()}
                            />
                        </div>
                    </div>
                    {!manglerHandling &&
                        <React.Fragment key={props.laegekursus.id}>
                            <div className="margin-top-s subtle small text-truncate">
                                {props.laegekursus.eventuelSpecifikation}
                            </div>
                            <div className="flex-align-c subtle margin-top-s">
                                {props.laegekursus.startDato && props.laegekursus.startDato && !kursusErGrundkursusIPsykoterapi &&
                                    <>
                                        <div className="card-list-calender-icon">
                                            <EmblaIcon iconName="calendar"/>
                                        </div>
                                        <div>
                                            {props.laegekursus.startDato?.dateWithoutTimeFormat(true)}
                                        </div>
                                        <div className="margin-right-m">
                                            &nbsp;- {props.laegekursus.slutDato?.dateWithoutTimeFormat(true)}
                                        </div>
                                    </>
                                }
                                {props.laegekursus.status === LaegekursusStatusEnum.Udfyldt && !kursusErGrundkursusIPsykoterapi &&
                                    <div className="margin-right-m ">
                                <span className="badge badge-pill badge-default padding-xs bg-white border ">
                                    <BlueCircle className="margin-right-xs"/>
                                    {Localizer.kursusPage_udfyldt()}
                                </span>
                                    </div>
                                }
                                {props.laegekursus.status === LaegekursusStatusEnum.Godkendt &&
                                    <div className="margin-right-m">
                                <span className="badge badge-pill badge-default padding-xs">
                                    <GreenCircle/> {Localizer.global_godkendt()}
                                </span>
                                    </div>
                                }
                                <div className={"margin-right-xs card-list-icon " + (hasAnyNotater ? "" : "subtle")}>
                                    <EmblaIcon iconName="comment"/>
                                    <span className={"margin-left-xs " + (hasAnyNotater ? "" : "subtle")}>
                                    {props.laegekursus?.notatFileCount}
                                </span>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>
            </div>
        </React.Fragment>
    );
}
