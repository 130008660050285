import { useState } from "react";
import { DropdownOption } from "../dropdown";
import {GenericEnumType, GenericEnumTypeKeys} from "../../../helpers/enumGenericTypes";

/**
 * Converts an enumType into dropdownOptions,
 * @param enumType - enum that we want to create options from
 * @param translateEnumToString - optional translate function to map enumKey to localized text
 * @param enumvaluesToExclude - optional enums that you want to exclude from the dropdown
 */
const getEnumDropdownOptions = <TType extends GenericEnumType, TKeys extends GenericEnumTypeKeys>(enumType: TType, translateEnumToString?: (enumKey: TKeys) => string, enumvaluesToExclude?: TKeys[]): DropdownOption<TKeys>[] => {
  const enumTypeLabels = Object.values(enumType)
    .filter(x => !enumvaluesToExclude || (Number.isInteger(x) && !enumvaluesToExclude.includes(x as TKeys)))
    .filter(x => translateEnumToString ? Number.isInteger(x) : !Number.isInteger(x))
    .map((x) => translateEnumToString ? translateEnumToString(x as TKeys) : x as string);

  const enumTypeValues = Object.values(enumType)
      .filter((x) => (!enumvaluesToExclude || !enumvaluesToExclude.includes(x as TKeys)) && Number.isInteger(x))
      .map((x) => x as TKeys);

  const enumTypeSelectOptions: DropdownOption<TKeys>[] = [];

  for (let i = 0; i < enumTypeValues.length; i++) {
      enumTypeSelectOptions.push({
          label: enumTypeLabels[i],
          value: enumTypeValues[i],
      });
  }
  return enumTypeSelectOptions;
};

//Implementation Note: Uses generic 'TEnumReturnType' to make it work (without needing to cast to specific enum-type when using hook)
/**
 * Hook that creates dropdown-options for a specific enum, with an optional translate-function (for label-generation)
 * @returns dropdown-options for the enum
 *
 * @example
 *
 * const [exampleEnumDropdownOptions] = useEnumDropdownOptions<ExampleEnum>(ExampleEnum);
 */
const useEnumDropdownOptions = <TEnumReturnType extends GenericEnumTypeKeys>(enumType: GenericEnumType, translateEnumToString?: (enumKey: GenericEnumTypeKeys) => string, enumvaluesToExclude?: GenericEnumTypeKeys[]) => {
  const [enumDropdownOptions] = useState<DropdownOption<TEnumReturnType>[]>(getEnumDropdownOptions(enumType, translateEnumToString, enumvaluesToExclude) as DropdownOption<TEnumReturnType>[]);

  return [
    enumDropdownOptions
  ]
}

export default useEnumDropdownOptions;
