import { FileMetadata, getNotatTypeText } from "core/sharedmodels/fileMetaData/fileMetaData"
import { EmblaIcon } from "../emblaIcon/emblaIcon"
import Tooltip from "../tooltips/Tooltip"
import { DeleteButton } from "./deleteButton"
import { DownloadButton } from "./downloadButton"
import { FileLocationEnum } from "core/sharedmodels/file/fileLocationEnum"
import { EmblaIcons } from "core/emblaIcons"
import useScreenResolution from "core/hooks/useScreenResolution"
import classNames from "classnames"

type fileMetadataListProps = {
    fileMetaDataList: FileMetadata[];
    fileLocation: FileLocationEnum;

    withDownload?: boolean;
    showDeleteButton?: boolean;
    hideCreatedBy?: boolean;
    showNotatFileType?: boolean;
    showTimeInDatetime?: boolean;
    hideDate?: boolean;
    showCustomBadgeText?: (fileMetadataId: string) => string;
    fileSelectedCallback?: (fileMetadataId: string, checked: boolean) => void;
    initialSelectedFileIds?: string[];
    selectedFileIds?: string[];
    fileDeletedCallback?: (fileMetadata: FileMetadata) => void;
    hideBorder?: boolean;
    editFileOnClick?: (fileMetadataId: string) => void;
    conditionalShowDeleteButton?: (fileMetadata: FileMetadata) => boolean;
    conditionalShowEditButton?: (fileMetadata: FileMetadata) => boolean;
}

const FileMetadataListComponent = ({
    fileMetaDataList,
    fileLocation,
    showCustomBadgeText,
    fileDeletedCallback,
    fileSelectedCallback,
    initialSelectedFileIds = [],
    selectedFileIds,
    withDownload=true,
    showDeleteButton=false,
    hideCreatedBy=false,
    showTimeInDatetime=false,
    hideDate=false,
    showNotatFileType = false,
    hideBorder = false,
    editFileOnClick,
    conditionalShowDeleteButton,
    conditionalShowEditButton
} : fileMetadataListProps) => {

    const { isMobile } = useScreenResolution();

    const fileInfoToShow = (fileMetadata: FileMetadata) => {
        const createdAtText = !hideDate && fileMetadata.createdAt
            ? showTimeInDatetime
                ? `${fileMetadata.createdAt?.dateWithTimeFormat()}`
                : `${fileMetadata.createdAt?.dateWithoutTimeFormat(false)}`
            : "";

        const createdByText = !hideCreatedBy && fileMetadata.createdByName ? `${fileMetadata.createdByName}` : "";
        const notatTypeText = showNotatFileType && !!fileMetadata.notatType ? `${getNotatTypeText(fileMetadata.notatType)}` : "";

        const infoTekst = [
            ...(!!createdAtText ? [createdAtText] : []),
            ...(!!createdByText ? [createdByText] : []),
            ...(!!notatTypeText ? [notatTypeText] : [])
            ]
            .join(" - ");

        return infoTekst;
    }

    return (
        <div className={`d-flex flex-column ${hideBorder ? "" : " border-bottom"}`}>
            {fileMetaDataList.map((fileMetadata, index) => (
                <div
                    className={classNames("wrap flex-align-c flex-row justify-content-between padding-s", !(hideBorder && index === 0) && "border-top")}
                    key={fileMetadata.id + fileMetadata.mappeId ?? ""}
                >
                    {!!fileSelectedCallback &&
                    <div className='flex-row flex-align-c'>
                            <input
                                type="checkbox"
                                id={`checkbox-${fileMetadata.id}`}
                                checked={!!selectedFileIds ? selectedFileIds.includes(fileMetadata.id) : undefined}
                                defaultChecked={initialSelectedFileIds.includes(fileMetadata.id)}
                                onChange={(e) => fileSelectedCallback(fileMetadata.id, e.target.checked)}
                            />
                            <label
                                className="file-list-component-checkbox-label"
                                htmlFor={`checkbox-${fileMetadata.id}`}
                                onClick={(event) => { event.stopPropagation() }}
                            />
                    </div>
                    }
                    <div className='flex-row flex-align-c'>
                        <EmblaIcon iconName={EmblaIcons.Document}/>
                    </div>
                    <div className='flex-row flex-align-l'>
                        <Tooltip
                            className={classNames(
                                "margin-s",
                                !isMobile && "file-info-container",
                                isMobile && "file-info-container-mobile"
                            )}
                            title={`${fileMetadata.fileName} - ${fileInfoToShow(fileMetadata)}`}
                            >
                            <p className='margin-bottom-0 text-truncate'>{fileMetadata.fileName}</p>
                            <p className='margin-top-0 small subtle text-truncate'>
                                {fileInfoToShow(fileMetadata)}
                            </p>
                        </Tooltip>
                    </div>
                    {showCustomBadgeText &&
                        <div className='flex-align-l margin-right-l'>
                            <div className="badge badge-pill badge-default padding-xs">
                                {showCustomBadgeText(fileMetadata.id)}
                            </div>
                        </div>
                    }
                    <div className='justify-content-end flex-row flex-align-c'>
                        <div>
                            {withDownload &&
                                <DownloadButton
                                    fileMetadata={fileMetadata}
                                    fileLocation={fileLocation}
                                />
                            }
                        </div>
                        <div className='margin-left-s'>
                            {(showDeleteButton || (!!conditionalShowDeleteButton && conditionalShowDeleteButton(fileMetadata)) ) && 
                                <DeleteButton
                                    fileMetadata={fileMetadata}
                                    fileDeletedCallback={fileDeletedCallback}
                                    fileLocation={fileLocation}
                                />
                            }
                        </div>
                        {editFileOnClick && (!conditionalShowEditButton || conditionalShowEditButton(fileMetadata)) &&
                            <div 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editFileOnClick(fileMetadata.id);
                                }}
                                className='margin-left-s'
                                role='button' 
                            >
                                <EmblaIcon iconName={EmblaIcons.Edit}/>
                            </div>
                        }
                    </div>
                </div>
            ))}
        </div>
    )
}

export default FileMetadataListComponent;
