import useStamdataApi from "core/hooks/useStamdataApi";
import UserFilterPermissions from "core/sharedmodels/userPermissions/userFilterPermissions";
import { useEffect, useState } from "react";
import useUser from "./useUser";

const initialModel: UserFilterPermissions = {
    adgangTilAlleForloeb: false,
    afdelingIds: [],
    omraadeIds: [],
    praksisIds: [],
    regionIds: [],
    sygehusIds: [],
    specialeIds: []
}

const useUserFilterPermissions = () => {
    
    const {stamdataUserApi} = useStamdataApi();
    const { currentUser } = useUser();

    const [permissions, setPermissions] = useState<UserFilterPermissions>(initialModel);
    const [isLoadingPermissions, setIsLoadingPermissions] = useState(true);

    useEffect(() => {
        
        const fetchPermissions = async () => {
            if(currentUser.Authenticated) {
                setPermissions(await stamdataUserApi.getUserFilterPermissions());
            }
            
            setIsLoadingPermissions(false);
        };

        fetchPermissions();
        
      }, [currentUser.Authenticated, stamdataUserApi]);

    return { permissions, isLoadingPermissions };
}
  
export default useUserFilterPermissions;