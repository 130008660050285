import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "../../app/store";
import {UddannelsesstedModel} from "../../core/sharedmodels/laegeInstitution/uddannelsesstedModel";
import {SpoergsmaalTypeEnum} from "../../core/sharedmodels/evaluering/spoergsmaalTypeEnum";
import {RootTypeEnum} from "../../core/sharedmodels/laegeInstitution/rootTypeEnum";
import {IdAndNameModel} from "../../core/sharedmodels/IdAndNameModel";

export interface EvalueringsstatistikFiltersState {
    regioner?: IdAndNameModel[];
    alleRegioner?: boolean;
    sygehuse?: IdAndNameModel[];
    praksis?: UddannelsesstedModel[];
    afdelinger?: UddannelsesstedModel[];
    uddannelsessteder?: UddannelsesstedModel[];
    refreshFilterPing: boolean;
    allChecked: boolean;
    spoergsmaalsType: SpoergsmaalTypeEnum;
    spoergsmaalToShow: string[];
    specialer?: IdAndNameModel[];
    uddannelsestyper?: IdAndNameModel[];
    omraader?: IdAndNameModel[];
    laegeinstitutionstype?: RootTypeEnum;
    startDato?: string;
    slutDato?: string;
    includeInactive: boolean;
}

const initialState: EvalueringsstatistikFiltersState = {
    regioner: [],
    alleRegioner: false,
    praksis: undefined,
    sygehuse: [],
    omraader: [],
    afdelinger: undefined,
    uddannelsessteder: undefined,
    refreshFilterPing: false,
    allChecked: false,
    spoergsmaalsType: SpoergsmaalTypeEnum.Enkeltvis,
    spoergsmaalToShow: [],
    specialer: [],
    uddannelsestyper: [],
    laegeinstitutionstype: undefined,
    startDato: undefined,
    slutDato: undefined,
    includeInactive: false
};

export const evalueringsstatistikFiltersSlice = createSlice({
    name: 'EvalueringsstatistikFilters',
    initialState,
    reducers: {
        applyFilters: (state, action: PayloadAction<Partial<EvalueringsstatistikFiltersState>>) => {
            state.regioner = action.payload.regioner ?? state.regioner;
            state.alleRegioner = action.payload.alleRegioner ?? state.alleRegioner;
            state.praksis = action.payload.praksis ?? state.praksis;
            state.sygehuse = action.payload.sygehuse ?? state.sygehuse;
            state.omraader = action.payload.omraader ?? state.omraader;
            state.afdelinger = action.payload.afdelinger ?? state.afdelinger;
            state.uddannelsessteder = action.payload.uddannelsessteder ?? state.uddannelsessteder;
            state.allChecked = action.payload.allChecked ?? state.allChecked;
            state.spoergsmaalsType = action.payload.spoergsmaalsType ?? state.spoergsmaalsType;
            state.spoergsmaalToShow = action.payload.spoergsmaalToShow ?? state.spoergsmaalToShow;
            state.specialer = action.payload.specialer ?? state.specialer;
            state.uddannelsestyper = action.payload.uddannelsestyper ?? state.uddannelsestyper;
            state.laegeinstitutionstype = action.payload.laegeinstitutionstype ?? state.laegeinstitutionstype;
            state.startDato = action.payload.startDato ?? state.startDato;
            state.slutDato = action.payload.slutDato ?? state.slutDato;
            state.includeInactive = action.payload.includeInactive ?? state.includeInactive;
        },
        clearFilters: (state) => {
            state.regioner = initialState.regioner;
            state.alleRegioner = initialState.alleRegioner;
            state.sygehuse = initialState.sygehuse;
            state.omraader = initialState.omraader;
            state.praksis = initialState.praksis;
            state.uddannelsessteder = initialState.uddannelsessteder;
            state.allChecked = initialState.allChecked;
            state.spoergsmaalsType = initialState.spoergsmaalsType;
            state.spoergsmaalToShow = initialState.spoergsmaalToShow;
            state.afdelinger = initialState.afdelinger;
            state.specialer = initialState.specialer;
            state.uddannelsestyper = initialState.uddannelsestyper;
            state.laegeinstitutionstype = initialState.laegeinstitutionstype;
            state.startDato = initialState.startDato;
            state.slutDato = initialState.slutDato;
            state.includeInactive = initialState.includeInactive;
        },
        clearLaegeinstitutionFilter: (state) => {
            state.laegeinstitutionstype = initialState.laegeinstitutionstype;
        },
        handleUddannelsesstederSelectOptionChanged: (state, action: PayloadAction<UddannelsesstedModel[]>) => {
            state.uddannelsessteder =  action.payload.map(x => ({...x, selected: state?.uddannelsessteder?.find(a => a.id === x.id)?.selected ?? false }))
            state.allChecked = state?.uddannelsessteder.length > 0 && state?.uddannelsessteder?.every(u => u.selected);
        },
        uddannelsesstedToggle: (state, action: PayloadAction<UddannelsesstedModel>) => {
            state.uddannelsessteder = state.uddannelsessteder?.map(x => ({...x, selected: action.payload.id === x.id ? !x.selected : x.selected }));
            state.allChecked = state.uddannelsessteder?.every(u => u.selected) ?? false;
        },
        setAllToggled: (state) => {
            const newState = !state.allChecked;
            if (newState)
                state.uddannelsessteder = state.uddannelsessteder?.map(x => ({
                    ...x,
                    selected: state.uddannelsessteder?.map(y => y.id).includes(x.id) ? x.selected = true : x.selected
                }));
            else
                state.uddannelsessteder = state.uddannelsessteder?.map(x => ({
                    ...x,
                    selected: state.uddannelsessteder?.map(y => y.id).includes(x.id) ? x.selected = false : x.selected
                }));
            state.allChecked = newState;
        },
    }
});

export const {applyFilters, clearFilters, clearLaegeinstitutionFilter, handleUddannelsesstederSelectOptionChanged, uddannelsesstedToggle,setAllToggled} = evalueringsstatistikFiltersSlice.actions;
export const evalueringsstatistikFiltersReducer = (state: RootState) => state.evalueringsstatistikFiltersReducer;
export default evalueringsstatistikFiltersSlice.reducer;
