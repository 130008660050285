export class UserWithForloebAndStillingId {
    public userId: string;
    public forloebId?: string;
    public stillingId?: string;
    constructor(userId:string, forloebId?: string, stillingId?: string) {
        this.userId = userId
        this.forloebId = forloebId
        this.stillingId = stillingId
    }
}
