import React, {useEffect, useState} from 'react'
import {Localizer} from 'infrastructure/localization/localizer'
import {
    BuildExisting,
    CheckIfAllIsChosen,
    KlarmeldingInputModel,
    crudKlarmeldingValidationSchema
} from "./klarmeldingInputModel";
import {ValidationForm} from "../../../core/components/validation/components/validationForm";
import {HandleError} from "../../../core/infrastructure/errors/errorBoundary";
import {Loading} from "../../../core/components/loading/loading";
import {KlarmeldingCrudFields} from "./klarmeldingCrudFields";
import {ValidationFormSubmitButton} from "../../../core/components/validation/components/validationFormButton";
import {KlarmeldingModel} from "../klarmeldingModel";
import {setSlideinState, SlideInStateEnum} from "../../../core/components/slideIn/slideInSlice";
import {useDispatch} from "react-redux";
import {NotificationModule} from "ditmer-embla";
import {KlarmeldingTypeEnum} from "../../../infrastructure/enums/klarmeldingTypeEnum";
import useLogbogApi from "../../../core/hooks/useLogbogApi";

interface EditKlarmeldingProps {
    modalId: string,
    contextId: string,
    klarmeldingType: KlarmeldingTypeEnum,
}

export function EditKlarmelding({contextId, modalId, klarmeldingType}: EditKlarmeldingProps) {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);

    const {klarmeldingApi} = useLogbogApi()

    const [klarmeldingBrugerModel, setKlarmeldingBrugerModel] = useState<KlarmeldingModel>();

    useEffect(() => {
        const setKlarmeldingBrugere = async () => {
            const klarmeldingModel = await klarmeldingApi.getKlarmeldingBrugereByContext(contextId, klarmeldingType);
            setKlarmeldingBrugerModel(klarmeldingModel);
        }
        setKlarmeldingBrugere().then(r => setIsLoading(false));
    },[contextId, klarmeldingApi, klarmeldingType]);

    const editKlarmelding = async (inputModel: KlarmeldingInputModel) => {
        dispatch(setSlideinState({ state: SlideInStateEnum.ActionOngoing, slideInId: modalId }))
        CheckIfAllIsChosen(inputModel);
        const editKlarmelding = await klarmeldingApi.editKlarmeldinger(inputModel);

        if (editKlarmelding) {
            dispatch(setSlideinState({ state: SlideInStateEnum.Closing, slideInId: modalId }));
            NotificationModule.showSuccess(Localizer.global_klarmeldingUpdated(), "");
        } else {
            dispatch(setSlideinState({ state: SlideInStateEnum.Opened, slideInId: modalId }));
            NotificationModule.showError(Localizer.global_klarmeldingErrorUpdating(), "");
        }
    }

    const render = (
        <Loading isLoading={isLoading} text={Localizer.global_getData()}>
            {klarmeldingBrugerModel &&
                <ValidationForm
                    initialValues={BuildExisting(klarmeldingBrugerModel, klarmeldingType)}
                    validationSchema={crudKlarmeldingValidationSchema}
                    onSubmit={async (values) => {
                        await editKlarmelding(values)
                            .catch(err => { // catch is needed to handle unhandeled exception (eg. api offline). Formik onsubmithandler is catching all exception and writing them as warnings
                                HandleError(err);
                            });
                    }}>
                    {
                        (formik) => {
                            return (
                                <>
                                    <KlarmeldingCrudFields
                                        modalId={modalId}
                                        klarmeldToDelkursusledere={klarmeldingType === KlarmeldingTypeEnum.Laegekursus}
                                        formik={formik}
                                        model={klarmeldingBrugerModel}
                                        editView={true}
                                    />
                                    <div className="modal-actions">
                                        <ValidationFormSubmitButton formikProps={formik}>
                                            {Localizer.global_save()}
                                        </ValidationFormSubmitButton>
                                    </div>
                                </>
                            );

                        }
                    }
                </ValidationForm>
            }
        </Loading>
    )

    return <>{render}</>
}
