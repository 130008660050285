import {EmblaIcon} from "../emblaIcon/emblaIcon";
import {SlideIn} from "../slideIn/slideIn";

type MobileFilterProps = {
    id: string;
    title: string;
    content: any;
    filterCount: number;
}

export function MobileFilter({filterCount = 0, ...props}: MobileFilterProps) {
    const slideInId = props.id + "mobile-filter-slideIn"

    const filterContent = () => {
        return <div className={"flex-space-around"}>
            {props.content}
        </div>
    }

    return (
        <>
            <button className="btn btn-default" data-toggle="modal" data-target={`#${slideInId}`}>
                {filterCount}
                <EmblaIcon iconName="filter"/>
            </button>

            <SlideIn
                id={slideInId}
                title={props.title}
                bodyContent={filterContent()}
                defaultOpen={false}
            />
        </>
    );
}
