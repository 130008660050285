import {ReactNode, useRef} from "react";
import "./forloebOverviewEmptyCard.scss";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {AccountService} from "../../../services/account/accountService";

export interface ForloebOverviewEmptyCardProps{
    children?: ReactNode;
    emptyText?: string;
}

export function ForloebOverviewEmptyCard(props: ForloebOverviewEmptyCardProps) {
    const _currentUser = useRef(new AccountService().getUser());

    return (
        <div className="card emptyInfoCard">
            <div className="card-body">
                {_currentUser.current.CanPlanlaeggeForloeb() &&
                    <>
                        {props.children}
                    </>
                }
                {!_currentUser.current.CanPlanlaeggeForloeb() && !props.emptyText &&
                    <p>{Localizer.forloebpage_notAnyPlaned()}</p>
                }
                {!_currentUser.current.CanPlanlaeggeForloeb() && props.emptyText &&
                    <p>{props.emptyText}</p>
                }
            </div>
        </div>
    );
}
