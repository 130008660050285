import { useAppSelector } from "app/hooks";
import { ForloebSliceState, forloebReducer } from "core/forloebSlice";
import { Localizer } from "infrastructure/localization/localizer";

export type ForloebSpecificPageWrapperProps = {
  hideAllContent?: boolean;
};

const ForloebSpecificPageWrapper = ({
  hideAllContent = false,
  children
}: React.PropsWithChildren<ForloebSpecificPageWrapperProps>) => {
  const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

  const canSeePageContent = !!forloebSliceState.forloebState.id && !forloebSliceState.forloebState.erFravaer;

  return (
    <>
      {canSeePageContent && !hideAllContent &&
        children
      }
      {!canSeePageContent && !hideAllContent &&
        <div className="card header-card forloeb-header">
            <div className="flex-space">
                <h3 className='forloeb-header-txt'>
                    {!forloebSliceState.forloebState.id &&
                        Localizer.forloebpage_NoForloeb()
                    }
                    {forloebSliceState.forloebState.erFravaer &&
                        Localizer.forloebpage_ForloebErFravaer()
                    }
                </h3>
            </div>
        </div>
      }
    </>
  );
}

export default ForloebSpecificPageWrapper;