import { Localizer } from 'infrastructure/localization/localizer';

type ActionButtonGroupType = {
    onClose: () => void,
    onApplyFilter: () => void
}

const FitlerActionButtonGroup = ({ onClose, onApplyFilter }: ActionButtonGroupType) => (
    <div className="modal-actions">

        <button className="btn btn-default margin-right-m" onClick={onClose}>
            {Localizer.global_luk()}
        </button>
        <button className="btn btn-primary margin-right-m" onClick={onApplyFilter}>
            {Localizer.forloebOverviewPage_anvendFilter()}
        </button>
    </div>
);

export default FitlerActionButtonGroup;