import React, {useState} from 'react'
import {Localizer} from 'infrastructure/localization/localizer'
import useEffectAsync from 'infrastructure/effect'
import {useHistory, useRouteMatch} from 'react-router-dom'
import {HttpClientService} from "../../services/httpClient/httpClientService";
import {Loading} from "../../core/components/loading/loading";
import {AccountService} from "../../services/account/accountService";
import {SetTitleBarWithActions} from "../../core/layout/titleBar/titleBarApi";
import {SlideIn} from "../../core/components/slideIn/slideIn";
import {NotificationModule} from "ditmer-embla";
import {ForloebApi} from "../../services/api/forloeb/forloebApi";
import {useAppDispatch, useAppSelector} from 'app/hooks';
import {setForloebTableState} from './forloebTableSlice';
import {Table} from "../../core/components/table/table";
import {ForloebModel} from "../../core/sharedmodels/forloeb/forloebModel";
import {CreateForloeb} from "../../core/componentsPage/forloeb/createforloeb";
import {EditForloeb} from "../../core/componentsPage/forloeb/editforloeb";
import {DataTableHeader} from "../../core/components/table/DataTableHeader";
import {LogbogExportApi} from "../../services/api/logbogExport/logbogExportApi";
import {FileDownloadHandler} from "../../services/api/logbogFile/fileDownloadHandler";
import {DataTableHeaderAction} from "../../core/components/table/DataTableHeaderAction";
import {UddannelseTypeEnum} from "../../core/sharedmodels/stilling/uddannelseTypeEnum";
import {GetFravaerskodeTranslation} from "../../core/sharedmodels/forloeb/fravaerskoderEnum";
import {ModalAcceptType, ModalSubmitMessage} from "../../core/components/modalMessage/modalSubmitMessage";
import {setSlideinState, SlideInStateEnum} from "../../core/components/slideIn/slideInSlice";
import {RoutePaths} from "../../infrastructure/routes";
import {forloebReducer, ForloebSliceState, pingRefreshForloeb, setCurrentForloeb} from "../../core/forloebSlice";
import {CrudForloebInputModel} from "../../core/componentsPage/forloeb/crudForloebInputModel";
import {
    ForloebSkalIndgaaIEvalueringsstatistikInputModel
} from "../../core/componentsPage/forloeb/forloebSkalEvalueresInputModel";
import { EmblaIcons } from 'core/emblaIcons';
import DynamicButton from 'core/components/button/dynamicButton';
import useScreenResolution from 'core/hooks/useScreenResolution';

const forloebApi = new ForloebApi(new HttpClientService(), new AccountService())
const exportApi = new LogbogExportApi(new HttpClientService(), new AccountService(), new FileDownloadHandler());

export function ForloebListPage() {
    const dispatch = useAppDispatch();
    const routeMatch = useRouteMatch();
    const [isLoading, setIsLoading] = useState(true)
    const [editModalOpen, setEditModalOpen] = useState(false)
    const [forloebList, setForloebList] = useState<ForloebModel[]>()
    const [clickedForloeb, setClickedForloeb] = useState<ForloebModel>();
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;
    const history = useHistory();
    const [showAfslutForloebManueltModalOpen, setShowAfslutForloebManueltModalOpen] = useState<boolean>(false)
    const [showDeleteForloebModalOpen, setShowDeleteForloebModalOpen] = useState<boolean>(false)
    const [forloebHasData, setForloebHasData] = useState<boolean>(false)
    const [showDeleteForloebEvalueringModalOpen, setShowDeleteForloebEvalueringModalOpen] = useState<boolean>(false)
    const [forloebInputModelState, setForloebInputModelState] = useState<CrudForloebInputModel>()
    const [godkendtAfsluttetEllerEvalueretState, setGodkendtAfsluttetEllerEvalueretState] = useState<boolean>()
    const afslutForloebModal = "afslut-forloeb-modal";
    const deleteForloebModal = "delete-forloeb-modal";
    const deleteForloebEvalueringModalId = "delete-forloebevaluering-modal";

    const { isMobile } = useScreenResolution();

    const [tableNumber, setTableNumber] = useState<number>(0);

    const forloebTableId = "forloebTable";
    const modalTarget = "forloeb-create-modal";
    const excelButtonId = "excel-btn";

    const uddannelseslaegeId = (routeMatch.params as { id: string }).id;

    SetTitleBarWithActions(Localizer.global_forloebList(),
        [{ text: Localizer.global_forloebList(), url: "" }],
        [{ text: Localizer.forloebpage_createForloeb(), dataTarget: modalTarget }]);

    useEffectAsync(async () => {
        await loadForloebList();
    }, [tableNumber])

    const loadForloebList = async () => {
        setIsLoading(true);

        const result = await forloebApi.getForloebByBruger(uddannelseslaegeId);
        result.sort((a, b) => a.fravaerskodeEnum && b.fravaerskodeEnum && a.fravaerskodeEnum > b.fravaerskodeEnum ? 1 : -1)
        setForloebList(result);
        setIsLoading(false);

        dispatch(setForloebTableState(result.map(f => f.id)))
    }

    const editmodalId = "forloeb-edit-modal";
    const rendermodal = (forloeb: ForloebModel) => {
        return <SlideIn
            id={editmodalId}
            title={Localizer.forloebEdit_EditForloeb()}
            actionText={Localizer.global_editing()}
            actionOnCloseCallback={() => {
                setEditModalOpen(false)
            }}
            actionFinishedCallback={() => {
                setTableNumber(tableNumber + 1);
                setEditModalOpen(false)
            }}
            defaultOpen={true}
            bodyContent={<EditForloeb modalId={editmodalId}
                model={forloeb}
                forloebList={forloebList}
                afslutForloebClickedCallback={() => setShowAfslutForloebManueltModalOpen(true)}
                areYouSureAfslutModalId={afslutForloebModal}
                deleteForloebClickedCallback={(forloebHasData?: boolean) => {
                    setForloebHasData(forloebHasData ?? false)
                    setShowDeleteForloebModalOpen(true)
                }}
                areYouSureDeleteModalId={deleteForloebModal}
                deleteForloebEvalueringClickedCallback={(inputModel: CrudForloebInputModel, godkendtAfsluttetEllerEvalueret: boolean) => {
                    setForloebInputModelState(inputModel);
                    setGodkendtAfsluttetEllerEvalueretState(godkendtAfsluttetEllerEvalueret);
                    setShowDeleteForloebEvalueringModalOpen(true)}
                }
                areYouSuredeleteForloebEvalueringModalId={deleteForloebEvalueringModalId}
            />}
        />
    }

    const exportExcel = async () => {
        await exportApi.getLaegeWithForloebDetails(uddannelseslaegeId);
    }

    const renderTableActions = () => {
        return (
            <>
            { forloebList !== undefined && forloebList.length > 0 &&
                <DynamicButton id={excelButtonId} additionalClasses={'margin-right-s'} useFlex>
                    <DynamicButton.TableHeaderActionLoadingContainer  />
                    <DynamicButton.TextIconItem iconName={EmblaIcons.Incoming} text={Localizer.GetExcel()}/>
                </DynamicButton>
            }
            </>
        )
    }

    const renderTable = (forloebList: ForloebModel[]) => {

        return <Table
            key={tableNumber}
            tableIdentifier={forloebTableId}
            renderHeaderActions={{renderMethod: renderTableActions, actions: [new DataTableHeaderAction(excelButtonId, exportExcel, isMobile)]}}
            tableHeader={Localizer.global_forloebList()}
            columnHeaders={[
                new DataTableHeader(Localizer.uddannelsessted(), true),
                new DataTableHeader(Localizer.afdeling(), true),
                new DataTableHeader(Localizer.startdato(), true),
                new DataTableHeader(Localizer.slutdato(), true),
                new DataTableHeader(Localizer.forloebVarighed(), true),
                new DataTableHeader(Localizer.global_stillingNummer(), true),
                new DataTableHeader(Localizer.uddannelsestrin(), true),
                new DataTableHeader(Localizer.Fravaersaarsag(), true),
                new DataTableHeader(Localizer.global_speciale(), true),
            ]}
            columnDefaultOrder={[2, "desc"]}
            renderTableBody={() => renderTableBody(forloebList)}
        />
    }

    const renderTableBody = (forloebModels: ForloebModel[]) => {
        return (
            <tbody>
                {forloebModels &&
                    forloebModels.map((forloeb, index) => {
                        const endDateWithAddedDay = forloeb.endDate.addDays(1); //We want ot include both selectedStartDate and selectedEndDate in duration
                        const forloebDurationInMonths = forloeb.startDate.getMonthDiff(endDateWithAddedDay);
                        const startDateWithAddedMonths = forloeb.startDate.addMonths(forloebDurationInMonths);
                        //Early exit if selectedStartDate is the first day of the month, and selectedEndDate is the last day of the month
                        const forloebDurationInDays = startDateWithAddedMonths.getDate() == endDateWithAddedDay.getDate() ? 0 : startDateWithAddedMonths.getDayDiff(endDateWithAddedDay);

                        return (
                            <tr
                                key={index}
                                className={`clickable-row ${forloeb.erFravaer ? "color-fravaer-forloeb" : forloeb.irrelevant ? "color-irrelevant-forloeb" : "" } ${forloeb.manueltAfsluttet && !forloeb.erFravaer ? "color-manuelt-afsluttet-forloeb" : "" }`}
                                data-toggle="modal"
                                data-id={index}
                                data-target={`#${editmodalId}`}
                                onClick={(event) => {
                                    // Fix so that responsive "expand-arrow" doesn't open "edit":
                                    const $target = $(event.target);
                                    if ($target.hasClass("expand-arrow") || $target.hasClass("stop-event")) return;

                                    setEditForloebState(forloeb);
                                }}
                            >
                                <td>{forloeb.laegeInstitutionName}</td>
                                <td>{forloeb.afdelingName}</td>
                                <td>{forloeb.startDate.dateWithoutTimeFormat(false)}</td>
                                <td>{forloeb.endDate.dateWithoutTimeFormat(false)}</td>
                                <td>{Localizer.forloebVarighedIMaaneder(forloebDurationInMonths, forloebDurationInDays)}</td>
                                <td>{forloeb.stillingNummer}</td>
                                <td>{!forloeb.stillingErAltidFravaer ? UddannelseTypeEnum[forloeb.uddannelseType] : ""}</td>
                                <td>{forloeb.erFravaer && forloeb.fravaerskodeEnum  ? `${forloeb.fravaerskodeEnum} - ${GetFravaerskodeTranslation(forloeb.fravaerskodeEnum)}` : ""}</td>
                                <td>{forloeb.specialeName}</td>
                            </tr>
                        );
                    })}
            </tbody>
        );
    }

    const setEditForloebState = (forloebModel: ForloebModel) => {
        setClickedForloeb(forloebModel);
        setEditModalOpen(true);
    }

    const afslutForloebManuelt = async () => {
        if (clickedForloeb) {
            dispatch(setSlideinState({ state: SlideInStateEnum.ActionOngoing, slideInId: editmodalId }))
            let afsluttetForloeb = await forloebApi.AfslutForloebManuelt(clickedForloeb.id);

            if (afsluttetForloeb) {
                dispatch(setSlideinState({ state: SlideInStateEnum.Closing, slideInId: editmodalId }))
                NotificationModule.showSuccess(Localizer.forloebpage_forloebAfsluttetManuelt(), "");
                let path = RoutePaths.ForloebList(forloebSliceState.forloebState.brugerId).url;
                history.push(path);
            } else {
                dispatch(setSlideinState({ state: SlideInStateEnum.Opened, slideInId: editmodalId }))
            }
        }
        setTableNumber(tableNumber + 1);
    }

    function sletForloebEvalueringModalSubmitCancel() {
        setShowDeleteForloebEvalueringModalOpen(false);
        setEditModalOpen(true);
        setClickedForloeb(clickedForloeb);
    }

    const sletForloebEvaluering = async () => {
        if (clickedForloeb) {
            const forloebEvalueringDeletedId = await forloebApi.deleteEvalueringOnForloeb(clickedForloeb.id);
            if (!forloebEvalueringDeletedId)
                NotificationModule.showError(Localizer.forloebpage_fejlVedForloebEvalueringSletning(), "")
            else
                NotificationModule.showSuccess(Localizer.evalueringPage_forloebEvalueringDeleted(), "")
            if (forloebInputModelState && !godkendtAfsluttetEllerEvalueretState) {
                const editedForloebId = await forloebApi.editForloeb(forloebInputModelState);
                if (editedForloebId)
                    forloebEdittedSuccessfully();
            } else if (forloebInputModelState && clickedForloeb.skalIndgaaIEvalueringsstatistik !== forloebInputModelState.skalIndgaaIEvalueringsstatistik) {
                const skalIndgaaIEvalueringsstatistikSetForForloebId = await forloebApi.setForloebEvalueringsstatistikStatus(new ForloebSkalIndgaaIEvalueringsstatistikInputModel(forloebInputModelState.id, forloebInputModelState.skalIndgaaIEvalueringsstatistik, forloebInputModelState.senesteDatoForEvaluering?.dateToApiPostFormat()));
                if (skalIndgaaIEvalueringsstatistikSetForForloebId)
                    forloebEdittedSuccessfully();
            }
        }
    }

    function forloebEdittedSuccessfully() {
        dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: editmodalId}))
        dispatch(pingRefreshForloeb);
        NotificationModule.showSuccess(Localizer.forloebpage_forloebEdited(), "");
    }

    const deleteForloeb = async () => {
        if (clickedForloeb) {
            let deletedForloeb = await forloebApi.DeleteForloeb(clickedForloeb.id, clickedForloeb.brugerId);
            if (deletedForloeb) {
                NotificationModule.showSuccess(Localizer.forloebpage_forloebDeleted(), "");
                history.push(RoutePaths.ForloebList(forloebSliceState.forloebState.brugerId).url);

                //Hvis man sletter det forløb man står i kontekst af, skal vi hente forløbslisten og sætte et nyt redux state - hvor det bliver et forløb som ikke er et afsluttet forløb.
                if (clickedForloeb.id === forloebSliceState.forloebState.id) {
                    const result = await forloebApi.getForloebByBruger(uddannelseslaegeId);
                    const ikkeFravaerForloeb = result.filter(x => !x.erFravaer);
                    if (ikkeFravaerForloeb.length > 0 ) {
                        dispatch(setCurrentForloeb(ikkeFravaerForloeb[0].ToState()));
                        history.push(RoutePaths.ForloebList(forloebSliceState.forloebState.brugerId).url);
                    }
                    else {
                        history.push(RoutePaths.forloebOverview.url);
                    }
                }

            } else {
                dispatch(setSlideinState({ state: SlideInStateEnum.Opened, slideInId: editmodalId }))
            }
        }
        setTableNumber(tableNumber + 1);
    }

    function afslutManueltModalSubmitCancel() {
        setShowAfslutForloebManueltModalOpen(false);
        setEditModalOpen(true);
        setClickedForloeb(clickedForloeb);
    }

    function deleteForloebModalSubmitCancel() {
        setShowDeleteForloebModalOpen(false);
        setEditModalOpen(true);
        setClickedForloeb(clickedForloeb);
    }

    function getAfslutManuelModalDescription() {
        return <><p>{Localizer.forloebOverviewPage_erDuSikkerManueltAfslut()}</p> <br/>
            <p><b>{Localizer.forloebOverviewPage_denneHandlingKanIkkeFortrydes()}</b></p></>;
    }

    function getDeleteModalDescription() {
        return <p>{Localizer.forloebEdit_erDuSikkerDeleteForloeb()}</p>;
    }
    function getDeleteModalDescriptionForForloebWithData() {
        return <p>{Localizer.forloebEdit_OBSDataPaaForloebDuSletterErDuSikkerDeleteForloeb()}</p>;
    }

    return (
        <>
            <Loading isLoading={isLoading} text={Localizer.forloebpage_getForloeb()}>
                {forloebList && renderTable(forloebList)}
                {editModalOpen && clickedForloeb && rendermodal(clickedForloeb)}
            </Loading>
            {
                <SlideIn
                    id={modalTarget}
                    title={Localizer.forloebpage_createForloeb()}
                    actionText={Localizer.forloebpage_creatingForloeb()}
                    actionFinishedCallback={() => loadForloebList()}
                    bodyContent={<CreateForloeb modalId={modalTarget} />}
                />
            }

            {showAfslutForloebManueltModalOpen &&
                <ModalSubmitMessage modalId={afslutForloebModal}
                                    title={Localizer.forloebEdit_AfslutForloebManuelt()}
                                    description={getAfslutManuelModalDescription()}
                                    cancelAction={() => afslutManueltModalSubmitCancel()}
                                    acceptAction={() => afslutForloebManuelt()}
                                    modalAcceptType={ModalAcceptType.danger}
                                    acceptButtonText={Localizer.forloebEdit_AfslutForloebManuelt()}
                />
            }

            {showDeleteForloebModalOpen &&
                <ModalSubmitMessage modalId={deleteForloebModal}
                                    title={Localizer.forloebEdit_DeleteForloeb()}
                                    description={forloebHasData ?  getDeleteModalDescriptionForForloebWithData() : getDeleteModalDescription()}
                                    cancelAction={() => deleteForloebModalSubmitCancel()}
                                    acceptAction={() => deleteForloeb()}
                                    modalAcceptType={ModalAcceptType.danger}
                                    acceptButtonText={Localizer.forloebEdit_DeleteForloeb()}
                />
            }

            {showDeleteForloebEvalueringModalOpen &&
                <ModalSubmitMessage modalId={deleteForloebEvalueringModalId}
                                    title={Localizer.forloebOverviewPage_sletForloebEvaluering()}
                                    description={<p>{Localizer.forloebOverviewPage_areYouSureSletForloebEvaluering()}</p>}
                                    cancelAction={() => sletForloebEvalueringModalSubmitCancel()}
                                    acceptAction={() => sletForloebEvaluering()}
                                    modalAcceptType={ModalAcceptType.danger}
                                    acceptButtonText={Localizer.forloebOverviewPage_sletForloebEvaluering()}
                />
            }
        </>
    );
}
