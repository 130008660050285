import { UserModel } from "services/account/userModel"
import { PrivateRoute } from "./privateRoute";
import { PublicRoute } from "./publicRoute";
import { LandingPage } from "pages/landing/landingPage";
import { Redirect } from "react-router-dom";
import { RoutePaths } from "infrastructure/routes";
import useScreenResolution from "core/hooks/useScreenResolution";

type IndexRedirectProps = {
  currentUser: UserModel;
}

/** 
 * Resolves the routing and optionally redirect, for the index path
 * 
 * Obs.: This needs to be called as an function (else it wont render, e.g. shows a white screen) 
 * */
const RenderIndexRedirect = ({ currentUser }: IndexRedirectProps) => {
  const { isMobile } = useScreenResolution();

  if (!currentUser.Authenticated) {
    return <PublicRoute exact path={RoutePaths.Index.path} isAuthenticated={currentUser.Authenticated}>
        <LandingPage/>
    </PublicRoute>
  }

  if (currentUser.IsAdmin()) {
      return <PrivateRoute exact path={RoutePaths.Index.path}>
          <Redirect
              to={{
                  pathname: !isMobile ? RoutePaths.forloebOverview.path : RoutePaths.UddannelseslaegeOverview.path
              }}
          />
      </PrivateRoute>
  }

  if (currentUser.IsUddannelsesansvarlig()) {
      return <PrivateRoute exact path={RoutePaths.Index.path}>
          <Redirect
              to={{
                  pathname: !isMobile ? RoutePaths.forloebOverview.path : RoutePaths.UddannelseslaegeOverview.path
              }}
          />
      </PrivateRoute>
  }

  if (currentUser.IsVejleder()) {
      return <PrivateRoute exact path={RoutePaths.Index.path}>
          <Redirect
              to={{
                  pathname: RoutePaths.UddannelseslaegeOverview.path
              }}
          />
      </PrivateRoute>
  }

  if (currentUser.IsVusMedarbejeder()) {
      return <PrivateRoute exact path={RoutePaths.Index.path}>
          <Redirect
              to={{
                  pathname: !isMobile ? RoutePaths.forloebOverview.path : RoutePaths.UddannelseslaegeOverview.path
              }}
          />
      </PrivateRoute>
  }

  if (currentUser.IsLaege()) {
      return <PrivateRoute exact path={RoutePaths.Index.path}>
          <Redirect
              to={RoutePaths.Forloeb([currentUser.UserId]).url}
          />
      </PrivateRoute>
  }

  if (currentUser.IsKursusleder()) {
      return <PrivateRoute exact path={RoutePaths.Index.path}>
          <Redirect
              to={{
                  pathname: !isMobile ? RoutePaths.forloebOverview.path : RoutePaths.Brugere.path
              }}
          />
      </PrivateRoute>
  }

  if (currentUser.IsKursusudbyder()) {
      return <PrivateRoute exact path={RoutePaths.Index.path}>
          <Redirect
              to={{
                  pathname: !isMobile ? RoutePaths.forloebOverview.path : RoutePaths.Brugere.path
              }}
          />
      </PrivateRoute>
  }

  if (currentUser.IsHrMedarbejder()) {
      return <PrivateRoute exact path={RoutePaths.Index.path}>
          <Redirect
              to={{
                  pathname: !isMobile ? RoutePaths.forloebOverview.path : RoutePaths.Brugere.path
              }}
          />
      </PrivateRoute>
  }

  if (currentUser.IsKonsulentForLaegeligVidereuddannelse()) {
      return <PrivateRoute exact path={RoutePaths.Index.path}>
          <Redirect
              to={{
                  // TODO: Fjern udkommenteret når eval-statistik er implementeret
                  // pathname: RoutePaths.Evalueringsstatistik.path
                  pathname: !isMobile ? RoutePaths.forloebOverview.path : RoutePaths.Brugere.path
              }}
          />
      </PrivateRoute>
  }

  if (currentUser.IsLedelse()) {
      return <PrivateRoute exact path={RoutePaths.Index.path}>
          <Redirect
              to={{
                  pathname: !isMobile ? RoutePaths.forloebOverview.path : RoutePaths.Brugere.path
              }}
          />
      </PrivateRoute>
  }

  if (currentUser.IsSekretaer()) {
      return <PrivateRoute exact path={RoutePaths.Index.path}>
          <Redirect
              to={{
                  pathname: !isMobile ? RoutePaths.forloebOverview.path : RoutePaths.Brugere.path
              }}
          />
      </PrivateRoute>
  }

  if (currentUser.IsForskningstraeningsvejleder()) {
      return <PrivateRoute exact path={RoutePaths.Index.path}>
          <Redirect
              to={{
                  pathname: !isMobile ? RoutePaths.forloebOverview.path : RoutePaths.Brugere.path
              }}
          />
      </PrivateRoute>
  }
}

export default RenderIndexRedirect;
