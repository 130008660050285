import {useMediaQuery} from "react-responsive";
import {SektionModel} from "../../../core/sharedmodels/evaluering/sektionModel";
import {LogbogForloebEvalueringApi} from "../../../services/api/logbogEvaluering/logbogForloebEvalueringApi";
import {HttpClientService} from "../../../services/httpClient/httpClientService";
import {mobileMaxWidthQuery} from "../../../core/layout/responsive";
import {SpoergsmaalType} from "../../../core/sharedmodels/evaluering/spoergsmaalType";
import React, {useState} from "react";
import {EvalueringModel} from "../../../core/sharedmodels/evaluering/evalueringModel";
import {SvarModel} from "../../../core/sharedmodels/evaluering/svarModel";
import {Localizer} from "../../../infrastructure/localization/localizer";
import "./evalueringSektion.scss";
import {EmblaIcon} from "../../../core/components/emblaIcon/emblaIcon";
import {SpoergsmaalModel} from "../../../core/sharedmodels/evaluering/spoergsmaalModel";
import {UserRoleTitleToString} from "../../../infrastructure/enums/rolleTitelEnum";
import {ValidationTextarea} from "../../../core/components/validation/components/validationTextarea";
import {nameof} from "ts-simple-nameof";
import {HovedvejlederInputModel} from "./HovedvejlederInputModel";
import {UserSimpleModel} from "../../../core/sharedmodels/user/userSimpleModel";
import {FormikProps} from "formik";
import {NotificationModule} from "ditmer-embla";

const evalueringApi = new LogbogForloebEvalueringApi(new HttpClientService());

export interface EvalueringSektionProps {
    evaluering: EvalueringModel;
    sektion: SektionModel;
    finishSektion: boolean;
    answerChosenCallback?: (svar: SvarModel) => void;
    nextButtonClickedCallback: () => void;
    setShowIndsendModalCallback: () => void;
    uddannelsesansvarlige?: UserSimpleModel[];
    formik: FormikProps<HovedvejlederInputModel>;
}

export function EvalueringSektion(props: EvalueringSektionProps) {
    const IsMobile = useMediaQuery(mobileMaxWidthQuery);
    const godkendModalId = "afdelingEvalueringGodkend";
    const [comment, setComment] = useState<string>();
    const [previousComment, setPreviousComment] = useState<string>();
    const [updatingAnswer, setUpdatingAnswer] = useState<boolean>(false);

    const PatchSvar = async (newSvar: SvarModel, avoidCallback?: boolean, previousTekstSvar?: string) => {
        if (props.evaluering.forloebEvalueringId) {
            if (newSvar.tekstSvar && previousTekstSvar === newSvar.tekstSvar) return;

            const evalueringId = await evalueringApi.patchForloebEvalueringSvar(props.evaluering.forloebEvalueringId, props.evaluering.indsendt, [newSvar]);

            if (evalueringId && props.answerChosenCallback && !avoidCallback) {
                props.answerChosenCallback(newSvar);
            }

            if (evalueringId && newSvar.kommentar && avoidCallback) {
                NotificationModule.showSuccess(Localizer.evalueringPage_kommentarIndsendt(), "")
            }
        }
    }

    const answerChosen = async (spoergsmaal: SpoergsmaalModel, spoergsmaalNummer: number, svarVaerdi?: number, svarmulighedId?: string, notRelevant: boolean = false) => {
        setPreviousComment(comment ?? spoergsmaal?.svar?.kommentar)
        if (props.evaluering.forloebEvalueringId) {
            setUpdatingAnswer(true);

            //gem svar
            const newSvar = spoergsmaal.svar ?? new SvarModel();
            newSvar.kommentar = comment ?? spoergsmaal?.svar?.kommentar;
            newSvar.svarmulighedId = svarmulighedId;
            newSvar.svarVaerdi = svarVaerdi;
            newSvar.spoergsmaalId = spoergsmaal.id;
            newSvar.ikkeRelevant = notRelevant;
            newSvar.forloebEvalueringId = props.evaluering.forloebEvalueringId;
            newSvar.spoergsmaalNummer = spoergsmaalNummer;

            await PatchSvar(newSvar, false);
            setUpdatingAnswer(false);
        }
    }

    const addComment = async (comment: string | undefined, spoergsmaal: SpoergsmaalModel, spoergsmaalNummer: number, initialComment?: string | undefined) => {
        setPreviousComment(comment)
        if (!comment && !initialComment) {
            //skjul element "text-field" hvis der ikke er noget tekst i kommentaren - kan efterfølgende toggles med "Tilføj kommentar" knappen
            let contentElement = $("#" + spoergsmaal.id);
            contentElement.collapse("hide");
        }
        if (props.evaluering.forloebEvalueringId) {
            setUpdatingAnswer(true)
            let svar = spoergsmaal.svar ?? new SvarModel();
            svar.kommentar = comment;
            svar.spoergsmaalId = spoergsmaal.id;
            svar.forloebEvalueringId = props.evaluering.forloebEvalueringId;
            svar.spoergsmaalNummer = spoergsmaalNummer;
            svar.svarVaerdi = spoergsmaal.svarmuligheder.find(x => x.id === spoergsmaal.svar?.svarmulighedId)?.svarVaerdi;

            await PatchSvar(svar, true, initialComment);
            setUpdatingAnswer(false);
        }
    }

    const spoergsmaalHasComment = (spoergsmaal: SpoergsmaalModel) => {
        return spoergsmaal?.svar?.kommentar !== undefined && spoergsmaal?.svar?.kommentar?.length > 0;
    }

    const addTekstSvar = async (e: React.FocusEvent<HTMLTextAreaElement>, spoergsmaal: SpoergsmaalModel, spoergsmaalNummer: number, previousTekstSvar?: string) => {
        if (props.evaluering.forloebEvalueringId) {
            const tekstSvar = e.target.value;

            let svar = spoergsmaal.svar ?? new SvarModel();
            svar.tekstSvar = tekstSvar;
            svar.spoergsmaalId = spoergsmaal.id;
            svar.forloebEvalueringId = props.evaluering.forloebEvalueringId;
            svar.spoergsmaalNummer = spoergsmaalNummer;

            await PatchSvar(svar, true, previousTekstSvar);
        }
    }

    const toggleExpanded = (id: string, hasComment: boolean) => {
        if (!hasComment) {
            let contentElement = $("#" + id);
            contentElement.collapse("toggle");
        }
    }

    return (
        <div className={`max-w-525 d-flex card-body margin-top-0 wrap collapse show `} id={props.sektion.id}>
            <div className={"d-flex flex-full-width"}>
                <h4 className={`subtle margin-bottom-m`}>
                    {props.sektion.title}
                </h4>
            </div>
            {props.sektion.subTitle &&
                <h4>{props.sektion.subTitle}</h4>
            }
            {props.sektion.tekst &&
                <p className={"pre-line"}>{props.sektion.tekst}</p>
            }
            <div key={props.sektion.id}>
                {props.sektion.spoergsmaal.map(spoergsmaal => (
                    <div className={`${IsMobile ? "" : "min-w-500"}`} key={spoergsmaal.id}>
                        <h5 className="margin-bottom-m">{spoergsmaal?.titel}</h5>
                        {spoergsmaal.tekst &&
                            <p className={"pre-line margin-bottom-m"}>{spoergsmaal.tekst}</p>
                        }
                        <div className="spoergsmaal">
                            {spoergsmaal.spoergsmaalTypeEnum === SpoergsmaalType.Tekst &&
                                <textarea id={"textarea-" + spoergsmaal.id}
                                          className="form-control margin"
                                          placeholder={spoergsmaal.tekst}
                                          onBlur={e => addTekstSvar(e, spoergsmaal, props.sektion.sortOrder, spoergsmaal.svar?.tekstSvar)}
                                          defaultValue={spoergsmaal.svar?.tekstSvar}
                                          disabled={props.evaluering.indsendt}/>
                            }

                            {!props.finishSektion &&
                                <div className="margin-top-m margin-bottom-m">
                                    { !props.evaluering.indsendt ?
                                    <div className="btn btn-default btn-icon"
                                         aria-label="Link"
                                         role="button"
                                         data-toggle="collapse"
                                         aria-controls={spoergsmaal.id}
                                         onClick={() => toggleExpanded(spoergsmaal.id, spoergsmaalHasComment(spoergsmaal))}
                                         aria-expanded={spoergsmaalHasComment(spoergsmaal)}>
                                        <div className="margin-right-s">
                                            <EmblaIcon iconName="plus"/>
                                            {Localizer.global_addComment()}
                                        </div>
                                    </div>
                                        :
                                        <h5 className={"subtle"}>{Localizer.global_comment()}:</h5>
                                    }
                                    <div
                                        className={`margin-top-m ${!spoergsmaalHasComment(spoergsmaal) ? "collapse" : "show"}`}
                                        id={spoergsmaal.id}>
                                            <textarea id={`addComment${spoergsmaal.id}`}
                                                      className="form-control"
                                                      placeholder={Localizer.global_addComment()}
                                                      onChange={e => {
                                                          setComment(e.target.value)
                                                      }}
                                                      defaultValue={spoergsmaal?.svar?.kommentar}
                                                      disabled={props.evaluering.indsendt}/>
                                        { !props.evaluering.indsendt &&
                                            <button className="btn btn-primary margin-top-m"
                                                    disabled={comment === undefined || comment === previousComment}
                                                    onClick={() => addComment(comment ?? spoergsmaal?.svar?.kommentar, spoergsmaal, props.sektion.sortOrder, spoergsmaal.svar?.kommentar)}>
                                                {Localizer.global_saveComment()}
                                            </button>
                                        }
                                    </div>
                                </div>
                            }


                            {spoergsmaal.spoergsmaalTypeEnum === SpoergsmaalType.Multi &&
                                <div>
                                    {spoergsmaal.svarmuligheder.map((svarmulighed) => (
                                        <div className="card"
                                             key={svarmulighed.id + props.sektion.id}
                                             id={props.sektion.id + spoergsmaal.id}>
                                            <input type="radio"
                                                   name={spoergsmaal.id}
                                                   id={svarmulighed.id + props.sektion.id}
                                                   onClick={() => answerChosen(spoergsmaal, props.sektion.sortOrder, svarmulighed.svarVaerdi, svarmulighed.id)}
                                                   defaultChecked={spoergsmaal.svar?.svarmulighedId === svarmulighed.id}
                                                   disabled={props.evaluering.indsendt || updatingAnswer}/>
                                            <label htmlFor={svarmulighed.id + props.sektion.id}>{svarmulighed.tekst}</label>
                                        </div>
                                    ))}

                                    <div className="card"
                                         key={props.sektion.id + spoergsmaal.id}
                                         id={props.sektion.id + spoergsmaal.id}>
                                        <input type="radio"
                                               name={spoergsmaal.id}
                                               id={spoergsmaal.id + props.sektion.id + "ikkerelevant"}
                                               onChange={() => answerChosen(spoergsmaal, props.sektion.sortOrder, undefined, undefined, true)}
                                               defaultChecked={spoergsmaal.svar?.ikkeRelevant}
                                               disabled={props.evaluering.indsendt || updatingAnswer}/>
                                        <label htmlFor={spoergsmaal.id + props.sektion.id + "ikkerelevant"}>{Localizer.global_notRelevant()}</label>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                ))}
            </div>
            {(!props.finishSektion && !props.sektion.includedInProgress) &&
                <button className="btn btn-primary d-flex margin-top-s"
                        onClick={() => props.nextButtonClickedCallback()}>
                    {Localizer.global_next()}
                </button>
            }


            {props.finishSektion &&
                <div>
                    <h4 className={`subtle margin-top-l`}>
                        {Localizer.evalueringPage_uddannelsesansvarlige()}
                    </h4>
                    <div className="margin-top-l">
                        {props.uddannelsesansvarlige && props.uddannelsesansvarlige.length > 0 &&
                            <>
                                {props.uddannelsesansvarlige.map(uddannelsesansvarlig =>
                                    <div key={uddannelsesansvarlig.userId}>
                                        {uddannelsesansvarlig.rolleTitelEnums.map(rt => UserRoleTitleToString(rt)).join(", ")} - {uddannelsesansvarlig.navn}
                                    </div>
                                )
                                }
                            </>
                        }
                    </div>
                    <h5 className={`margin-top-l`}>
                        {Localizer.evalueringPage_uddannelsesansvarligeText()}
                    </h5>
                    <h4 className={"subtle margin-top-l"}>
                        {Localizer.evalueringPage_hovedvejleder()}
                    </h4>
                    <ValidationTextarea
                        model={{
                            label: "",
                            placeholder: Localizer.evalueringPage_indtastHovedvejleder(),
                            htmlName: nameof<HovedvejlederInputModel>(x => x.hovedvejleder)
                        }}
                        inputId={nameof<HovedvejlederInputModel>(x => x.hovedvejleder)}
                        formikProps={props.formik}
                    />
                    <button className="btn btn-primary"
                            key={props.evaluering.evalueringId}
                            disabled={props.evaluering.sektioner.flatMap(x => x.spoergsmaal).some(x => x.isRequired && !x.svar?.svarmulighedId && !x.svar?.ikkeRelevant)}
                            data-toggle={props.formik.values.hovedvejleder ? "modal" : ""}
                            data-target={props.formik.values.hovedvejleder ? `#${godkendModalId}` : ""}>
                        {Localizer.global_indsend()}
                    </button>
                </div>
            }
        </div>
    );
}
