import {useRouteMatch} from "react-router-dom";
import {LogbogLaegekompetenceApi} from "../../../services/api/logbogLaegekompetence/logbogLaegekompetenceApi";
import {HttpClientService} from "../../../services/httpClient/httpClientService";
import {FileDownloadHandler} from "../../../services/api/logbogFile/fileDownloadHandler";
import {LaegekompetenceMeta} from "../../sharedmodels/kompetence/laegekompetenceMeta";
import {useEffect, useState} from "react";
import {LaegekompetenceStatusEnum} from "../../sharedmodels/kompetence/laegekompetenceStatusEnum";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {AccountService} from "../../../services/account/accountService";
import PdfComponent from "core/components/pdf/pdfComponent";
import {PdfKompetenceHeaderComponent} from "./pdfHeaders/pdfKompetenceHeaderComponent";
import {useFetchWithErrorHandlingPdf} from "./useFetchWithErrorHandlingPdf";
import useLogbogApi from "../../hooks/useLogbogApi";

export function KompetencePdf() {
    const routeMatch = useRouteMatch();
    const routeParams = routeMatch.params as { id: string, brugerId: string, brugerMaalbeskrivelseId: string };

    const [laegeKompetence, setLaegeKompetence ] = useState<LaegekompetenceMeta>();
    const setError = useFetchWithErrorHandlingPdf();
    const { logbogLaegeKompetenceApi } = useLogbogApi();

    useEffect(() => {
        const fetch = async () => {
            const kompetence = await logbogLaegeKompetenceApi.getLaegekompetenceById(routeParams.id, routeParams.brugerId);
            setLaegeKompetence(kompetence);
            (document as any).pdfReady = true;
        }
        fetch().catch(err => setError(err));
    }, [logbogLaegeKompetenceApi, routeParams.brugerId, routeParams.id, setError]);

    return (
        <PdfComponent>
            {laegeKompetence &&
                <>
                    <PdfKompetenceHeaderComponent
                        brugerId={routeParams.brugerId}
                        brugerMaalbeskrivelseId={routeParams.brugerMaalbeskrivelseId}
                    />
                    <div className="card shadow-none">
                        <div className="card-header">
                            <h4 className="card-title">{laegeKompetence.fullKompetenceTitleWithNumber}</h4>
                        </div>
                        <div className="card-body">
                            <div className="margin-m">
                                <div className="margin-bottom-m">
                                    <h5>{Localizer.global_kompetenceTitle()}</h5>
                                    <div>{laegeKompetence.fullKompetenceTitle}</div>
                                    <div className={"subtle padding-top-0 margin-top-0"}>{laegeKompetence.underoverskrift}</div>
                                </div>

                                <div className="margin-bottom-m">
                                    <h5>{Localizer.global_kompetenceNummer()}</h5>
                                    <p>{laegeKompetence.kompetenceNummer}</p>
                                </div>

                                <div className="margin-bottom-m">
                                    <h5>{Localizer.global_konkretisering()}</h5>
                                    <p>{laegeKompetence.konkretisering}</p>
                                </div>

                                <div className="margin-bottom-m">
                                    <h5>{Localizer.global_teachingStrategy()}</h5>
                                    <p className="kompetence-full-text">{laegeKompetence.laeringsstrategi}</p>
                                </div>

                                <div className="margin-bottom-m">
                                    <h5>{Localizer.global_kompetenceVurderingsMetoder()}</h5>
                                    <p className="kompetence-full-text">{laegeKompetence.kompetenceVurderingsmetoder}</p>
                                </div>

                                <div className="margin-bottom-m">
                                    <h5>{Localizer.global_laegeroller()}</h5>
                                    {laegeKompetence.laegeroller.split(";").map((rolle) =>
                                        <p>{rolle}</p>
                                    )}
                                </div>

                                <div>
                                    <h5>{Localizer.global_status()}</h5>
                                    {laegeKompetence.status == LaegekompetenceStatusEnum.Godkendt &&
                                        <div className={"flex-container"}>
                                            {Localizer.kompetencePdf_Godkendt((laegeKompetence.godkendtDato as Date).dateWithoutTimeFormat(false), laegeKompetence.godkendtAfNavn)}
                                            {laegeKompetence.godkendtAfAuthId &&
                                                <p>
                                                    &nbsp; ({Localizer.global_authorisationId()}: {laegeKompetence.godkendtAfAuthId})
                                                </p>}
                                        </div>
                                    }
                                    {laegeKompetence.status != LaegekompetenceStatusEnum.Godkendt &&
                                        <p>{LaegekompetenceStatusEnum[laegeKompetence.status]}</p>
                                    }
                                </div>

                                <div className="margin-bottom-m">
                                    <h5>{Localizer.forloebOverviewPage_fase()}</h5>
                                    <p className="kompetence-full-text">{laegeKompetence.fase ?? Localizer.kompetencePage_ingenFaseAngivet()}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </PdfComponent>
    );
}
