import React, {ReactNode, useEffect, useState} from "react";
import {EmblaIcon} from "../emblaIcon/emblaIcon";
import './wizard.scss';
import {WizardChildModel} from "../../sharedmodels/wizard/wizardChildModel";
import {Localizer} from "../../../infrastructure/localization/localizer";
import { ModalAcceptType, ModalSubmitMessage } from "../modalMessage/modalSubmitMessage";
import StpsStep, { toIndexFromStpsStep, toStpsStepFromIndex } from "pages/sendStpsAnsoegning/stpsStepEnum";
import useScreenResolution from "core/hooks/useScreenResolution";
import classNames from "classnames";

type WizardProps = {
    wizardChildren: WizardChildModel[];
    renderHeaderItem?: (childModel: WizardChildModel) => ReactNode;
    finishButtonText: string;
    finishCallback: () => void;
    canFinish: boolean;
    initialStep?: StpsStep;
    newStepCallback?: (step?: StpsStep) => void;
    finishModalDescription: string;
    finishModalAcceptButtonText: string;
}

export const Wizard = ({newStepCallback, initialStep, ...props}: WizardProps) => {
    const modalId = "ansoegSelvstaendigtVirkeModal";
    const { isMobile } = useScreenResolution();

    const [currentStepIndex, setCurrentStepIndex] = useState<number>(toIndexFromStpsStep(initialStep));
    const [showModal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
        if(newStepCallback) {
            newStepCallback(toStpsStepFromIndex(currentStepIndex))
        }
    }, [currentStepIndex, newStepCallback])

    function nextStep() {
        if (currentStepIndex < props.wizardChildren.length-1)
            setCurrentStepIndex(currentStepIndex + 1);
    }

    function previousStep() {
        if (currentStepIndex > 0)
            setCurrentStepIndex(currentStepIndex - 1);
    }

    function finishWizard() {
        props.finishCallback();
    }

    return (
        <>
            <div className={classNames("card top-level-container", isMobile && "wizard-mobile", !isMobile && "wizard")}>
                <div className="card-header justify-content-center sticky-header">
                    <div className="wizard-steps">
                        {props.wizardChildren?.map((child, index) =>
                            <div key={index} className={`wizard-step ${props.wizardChildren.indexOf(child) === currentStepIndex ? "active-step" : ""}`}>
                                <div className="wizard-step-content">
                                    { child.stepComplete &&
                                        <EmblaIcon iconName='success'/>
                                    }
                                    { !child.stepComplete &&
                                        props.wizardChildren.indexOf(child)+1
                                    }
                                </div>
                                <div className="wizard-step-text">{child.stepBeskrivelse}</div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="card-body overflow-auto position-relative">
                    <div className={classNames(!isMobile && "padding-m")}>
                        <h3 className="font-weight-bold margin-bottom-m">
                            {!props.renderHeaderItem && props.wizardChildren[currentStepIndex].stepHeader}
                            {!!props.renderHeaderItem && props.renderHeaderItem(props.wizardChildren[currentStepIndex])}
                        </h3>
                        <div>
                            {props.wizardChildren[currentStepIndex].content}
                        </div>
                    </div>
                </div>
                <div className="card-footer d-flex justify-content-between sticky-footer">
                    <div className="wizard-actions-left">
                    {currentStepIndex > 0 &&
                        <button className="btn btn-default" onClick={() => previousStep()}>{Localizer.global_forrige()}</button>
                    }
                    </div>
                    {currentStepIndex < props.wizardChildren.length-1 ?
                        <div className="wizard-actions-right">
                            <button className="btn btn-primary"
                                    onClick={() => nextStep()}>{Localizer.global_naeste()}</button>
                        </div>
                        :
                        <div className="d-flex">
                            <div className="wizard-actions-right">
                                <button className={"btn btn-primary"}
                                        data-toggle="modal" data-target={`#${modalId}`}
                                        disabled={!props.canFinish}
                                        {... (props.canFinish && {onClick: () => setShowModal(true) })}>{props.finishButtonText}</button>
                            </div>
                        </div>
                    }
                </div>
            </div>

            {showModal &&
                <ModalSubmitMessage modalId={modalId}
                       title={Localizer.areYouSure()}
                       description={<p>{props.finishModalDescription}</p>}
                       cancelAction={() => setShowModal(false)}
                       acceptAction={finishWizard}
                       modalAcceptType={ModalAcceptType.primary}
                       acceptButtonText={props.finishModalAcceptButtonText}
                />
            }
        </>
    );
}
