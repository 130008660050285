import React, {useEffect, useRef, useState} from 'react'
import { Localizer } from 'infrastructure/localization/localizer'
import { HttpClientService } from "../../../../services/httpClient/httpClientService";
import { setSlideinState, SlideInStateEnum } from "../../../../core/components/slideIn/slideInSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { ValidationFormSubmitButton } from "../../../../core/components/validation/components/validationFormButton";
import { AccountService } from "../../../../services/account/accountService";
import { HandleError } from "../../../../core/infrastructure/errors/errorBoundary";
import { LogbogVejledersamtaleApi } from "../../../../services/api/logbogVejledersamtale/logbogVejledersamtaleApi";
import {
    BuildExisting,
    CrudVejledersamtaleInputModel,
    crudVejledersamtaleValidationSchema, PrivateSamtaleKey, VisibleToEveryoneKey
} from "./crudVejledersamtaleInputModel";
import { VejledersamtaleCrudFields } from "./vejledersamtalerCrudFields";
import { ValidationForm } from "../../../../core/components/validation/components/validationForm";
import { VejledersamtaleModel } from "../vejledersamtaleModel";
import { forloebReducer, ForloebSliceState } from "../../../../core/forloebSlice";
import { LogbogNotatApi } from "../../../../services/api/logbogNotat/logbogNotatApi";
import useEffectAsync from "../../../../infrastructure/effect";
import { BasicNotatModel } from "../../../../core/sharedmodels/notat/basicNotatModel";
import { Loading } from "../../../../core/components/loading/loading";
import { NotatType } from "../../../../core/sharedmodels/notat/notatType";
import Tooltip, {TooltipPlacement} from "../../../../core/components/tooltips/Tooltip";

interface EditVejledersamtaleProps {
    modalId: string,
    model: VejledersamtaleModel,
    editedCallBack: (afholdt: boolean, editedVejledersamtale: VejledersamtaleModel) => void,
    deletedCallBack: (deletedVejledersamtale: VejledersamtaleModel) => void,

    confirmModalId: string,
    openConfirmModal: () => void
}

const vejledersamtaleApi = new LogbogVejledersamtaleApi(new HttpClientService(), new AccountService());
const notatApi = new LogbogNotatApi(new HttpClientService(), new AccountService());

export function EditVejledersamtale(props: EditVejledersamtaleProps) {
    const dispatch = useAppDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    const _currentUser = useRef(new AccountService().getUser());
    const notat = useRef<BasicNotatModel | null>(null);

    useEffectAsync(async () => {
        notat.current = await notatApi.getNotatOnContext(NotatType.Vejledersamtale, forloebSliceState.forloebState.brugerId, props.model.id);
        setIsLoading(false);
    }, []);

    const editVejledersamtale = async (inputModel: CrudVejledersamtaleInputModel) => {
        dispatch(setSlideinState({ state: SlideInStateEnum.ActionOngoing, slideInId: props.modalId }))

        const editedVejledersamtale = await vejledersamtaleApi.editVejledersamtale(inputModel);

        if (editedVejledersamtale) {
            dispatch(setSlideinState({ state: SlideInStateEnum.Closing, slideInId: props.modalId }))
            props.editedCallBack(false, VejledersamtaleModel.FromEditVejledersamtaleInputModel(inputModel))
        } else {
            dispatch(setSlideinState({ state: SlideInStateEnum.Opened, slideInId: props.modalId }))
        }
    }

    const deleteVejledersamtale = async (id: string) => {
        dispatch(setSlideinState({ state: SlideInStateEnum.ActionOngoing, slideInId: props.modalId }))
        let deletedVejledersamtale = await vejledersamtaleApi.deleteVejledersamtale(id);

        if (deletedVejledersamtale) {
            dispatch(setSlideinState({ state: SlideInStateEnum.Closing, slideInId: props.modalId }))
            props.deletedCallBack(props.model);
        } else {
            dispatch(setSlideinState({ state: SlideInStateEnum.Opened, slideInId: props.modalId }))
        }
    }

    const godkendVejledersamtale = async (id: string) => {
        dispatch(setSlideinState({ state: SlideInStateEnum.ActionOngoing, slideInId: props.modalId }))

        const uddannelsessted = forloebSliceState.forloebState.afdelingName === null
            ? forloebSliceState.forloebState.laegeInstitutionName
            : forloebSliceState.forloebState.laegeInstitutionName + ", " + forloebSliceState.forloebState.afdelingName

        const godkendtVejledersamtale = await vejledersamtaleApi.godkendVejledersamtale(id, uddannelsessted);
        if (godkendtVejledersamtale) {
            dispatch(setSlideinState({ state: SlideInStateEnum.Closing, slideInId: props.modalId }))

            /* TEMP HACK - Need to get vejledersamtale with new data after patch!! */
            props.model.afholdtTilkendegivetAf = _currentUser.current.Name;
            props.model.afholdtTilkendegivetDato = new Date();
            /*****************************************************************/

            props.editedCallBack(true, props.model)
        } else {
            dispatch(setSlideinState({ state: SlideInStateEnum.Opened, slideInId: props.modalId }))
        }
    }

    const render = (
        <Loading isLoading={isLoading} text={Localizer.global_getData()}>
            <ValidationForm
                initialValues={BuildExisting(props.model.id,
                    forloebSliceState.forloebState.id,
                    forloebSliceState.forloebState.brugerId,
                    props.model.titel,
                    props.model.dato,
                    props.model.vejleder,
                    props.model.referat,
                    props.model.vejledersamtaleType,
                    props.model.publicAccess,
                    props.model.publicAccess ? [VisibleToEveryoneKey] : props.model.isPrivate ? [PrivateSamtaleKey] : props.model.visibleToUserIds,
                    props.model.isPrivate,
                    props.model.starttidspunkt,
                    props.model.sluttidspunkt,
                    props.model.klarmeldtAt)}
                validationSchema={crudVejledersamtaleValidationSchema}
                onSubmit={async (values) => {
                    await editVejledersamtale(values)
                        .catch(err => { // catch is needed to handle unhandeled exception (eg. api offline). Formik onsubmithandler is catching all exception and writing them as warnings
                            HandleError(err);
                        });
                }}>
                {
                    (formik) => {
                        return (
                            <>
                                <VejledersamtaleCrudFields
                                    modalId={props.modalId}
                                    formik={formik}
                                    vejledersamtaleType={props.model.vejledersamtaleType}
                                    selectedVejlederBrugerId={props.model.vejleder}
                                    afholdtDate={props.model.afholdtTilkendegivetDato}
                                    afholdtAfName={props.model.afholdtTilkendegivetAf}
                                    notatInfo={{ contextId: props.model.id, notatType: NotatType.Vejledersamtale, userId: forloebSliceState.forloebState.brugerId }}
                                    notat={notat.current}
                                    createdByName={props.model.createdByName}
                                    klarmeldtAt={props.model.klarmeldtAt}
                                />
                                <div className="modal-actions">
                                    {(_currentUser.current.IsAdminOrVus() && props.model.afholdtTilkendegivetDato) &&
                                        <button className="btn btn-outline-danger mr-2"
                                                onClick={() => props.openConfirmModal()}
                                                data-toggle="modal" data-target={`#${props.confirmModalId}`}
                                                data-dismiss="modal" >
                                            {Localizer.global_ikkeAfholdt()}
                                        </button>
                                    }

                                    {((_currentUser.current.IsUddannelsesansvarlig() || _currentUser.current.IsVejleder() || _currentUser.current.IsAdminOrVus()) && !props.model.afholdtTilkendegivetDato) && //TODO: Check which users should have access to accept/agree with the "uddennelsesplan"
                                        <button type={"button"}
                                                className="btn btn-success mr-2"
                                                onClick={() => godkendVejledersamtale(props.model.id)}>
                                            {Localizer.global_afholdt()}
                                        </button>
                                    }

                                    {(_currentUser.current.IsLaege() || _currentUser.current.IsAdminOrVus()) &&
                                        <button type={"button"}
                                                className="btn btn-danger mr-2"
                                                onClick={() => deleteVejledersamtale(props.model.id)}>{Localizer.global_delete()}
                                        </button>
                                    }

                                    <Tooltip title={props.model.afholdtTilkendegivetDato !== undefined ? Localizer.uddannelsesplanFejlRedigerInfo() : ""} placement={TooltipPlacement.Left}>
                                        <ValidationFormSubmitButton formikProps={formik} disabled={props.model.afholdtTilkendegivetDato !== undefined}>
                                            {Localizer.global_save()}
                                        </ValidationFormSubmitButton>
                                    </Tooltip>

                                </div>
                            </>
                        );

                    }
                }
            </ValidationForm>
        </Loading>
    )

    return <>{render}</>
}
