import {HttpClientService} from "../../../services/httpClient/httpClientService";
import {useAppDispatch} from "../../../app/hooks";
import {AccountService} from "../../../services/account/accountService";
import {ValidationForm} from 'core/components/validation/components/validationForm';
import {ForloebApi} from 'services/api/forloeb/forloebApi';
import {HandleError} from 'core/infrastructure/errors/errorBoundary';
import {setSlideinState, SlideInStateEnum} from 'core/components/slideIn/slideInSlice';
import {
    BuildNew,
    TidsbegraensetAdgangInputModel,
    tidsbegraensetAdgangValidationSchema
} from "./tidsbegraensetAdgangInputModel";
import React from "react";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {ValidationFormSubmitButton} from "../../components/validation/components/validationFormButton";
import {pingRefreshForloeb} from "../../forloebSlice";
import {nameof} from "ts-simple-nameof";
import {ValidationDatepicker} from "../../components/validation/components/validationDatePicker";
import {NotificationModule} from "ditmer-embla";
import {LogbogUserInfoApi} from "../../../services/api/logbogUser/logbogUserInfoApi";
import {ValidationDropdown} from "../../components/validation/components/validationDropdown";
import {toDropdownOptions} from "../../sharedmodels/forSelectModel";

const forloebApi = new ForloebApi(new HttpClientService(), new AccountService());
const userinfoApi = new LogbogUserInfoApi(new HttpClientService(), new AccountService());

type TidsbegraensetAdgangProps = {
    forloebId: string,
    modalId: string,
    attestationForTidModalId: string
    brugerId: string;
}

export function TidsbegraensetAdgang(props: TidsbegraensetAdgangProps) {
    const dispatch = useAppDispatch();

    const godkendTidsbegraensetAdgang = async (input: TidsbegraensetAdgangInputModel) => {
        dispatch(setSlideinState({ state: SlideInStateEnum.ActionOngoing, slideInId: props.modalId }))

        const forloebAdgang = await forloebApi.createForloebAdgang(input);
        if (forloebAdgang) {
            dispatch(setSlideinState({ state: SlideInStateEnum.Closing, slideInId: props.modalId }))
            NotificationModule.showSuccess(Localizer.forloebpage_forloebAdgangGivet(), "");
            dispatch(pingRefreshForloeb());
            dispatch(setSlideinState({ state: SlideInStateEnum.Closing, slideInId:  props.attestationForTidModalId}))
        }
    }

    const fetchBrugerOptions = async (search: string) => {
        const selectModels = await userinfoApi.getBrugerForTidsbegraensetAdgang(search, props.brugerId);
        return toDropdownOptions(selectModels);
    }

    return (
        <ValidationForm
            key={props.forloebId}
            initialValues={BuildNew(props.forloebId, props.brugerId)}
            validationSchema={tidsbegraensetAdgangValidationSchema}
            onSubmit={async (values) => {
                await godkendTidsbegraensetAdgang(values)
                    .catch(err => {
                        HandleError(err);
                        dispatch(setSlideinState({ state: SlideInStateEnum.Opened, slideInId: props.modalId }))
                    });
            }}>
            {
                (formik) => (
                    <>
                        <div className="row">
                            <div className="col-sm-12 margin-bottom-m">
                                {Localizer.forloebpage_tidsbegraensetAdgangBeskrivelse()}
                            </div>
                            <div className="col-sm-12">
                                <ValidationDropdown
                                    model={{
                                        label: Localizer.bruger(),
                                        placeholder: Localizer.vaelgBruger(),
                                        htmlName: nameof<TidsbegraensetAdgangInputModel>(x => x.brugerId),
                                    }}
                                    fetchAsyncOptions={fetchBrugerOptions}
                                    formikProps={formik}
                                    fetchAsyncMinSearchLength={3}
                                />
                            </div>
                            <div className="col-sm-12">
                                <ValidationDatepicker
                                    model={{
                                        label: Localizer.adgangIndtil(),
                                        placeholder: Localizer.datoPlaceholder(),
                                        htmlName: nameof<TidsbegraensetAdgangInputModel>(x => x.dato),
                                    }}
                                    inputId={nameof<TidsbegraensetAdgangInputModel>(x => x.dato) + props.modalId}
                                    formikProps={formik}
                                />
                            </div>
                        </div>

                        <div className="modal-actions">
                            <ValidationFormSubmitButton formikProps={formik}>
                                {Localizer.global_save()}
                            </ValidationFormSubmitButton>
                        </div>
                    </>
                )
            }
        </ValidationForm>
    )
}
