import "./userList.scss";
import {
    UserProfileActions,
    UserProfileAvatar,
    UserProfileAvatarSizeEnum,
    UserProfileLayoutEnum
} from "../userProfileAvatar/userProfileAvatar";
import {UserWithForloebAccessDateModel} from "../../sharedmodels/user/userWithForloebAccessDateModel";
import {UserProfileAvatarModel} from "../userProfileAvatar/userProfileAvatarModel";
import {RolleTitelEnum} from "../../../infrastructure/enums/rolleTitelEnum";

export type UserForloebAccessListProps = UserProfileActions & {
    users: UserWithForloebAccessDateModel[];
}

export function UserForloebAccessList(props: UserForloebAccessListProps) {

    return (
        <div className="card shadow-none">
            <div className="card-body padding-0">
                {props.users.map(user => {
                    return <>
                        <div className="card-list">
                            <UserProfileAvatar size={UserProfileAvatarSizeEnum.small}
                                               userProfileLayoutEnum={UserProfileLayoutEnum.AsTextOnRightSide}
                                               userProfileAvatarModel={new UserProfileAvatarModel(user.navn, user.initialer, false, user.email)}
                                               userRoleTitles={user.rolleTitelEnums.filter(rt => rt === RolleTitelEnum.Vejleder || rt === RolleTitelEnum.Uao || rt === RolleTitelEnum.Tutorlaege)}
                                               forloebAccessDate={user.adgangTil.dateWithoutTimeFormat(true)}
                                               removeUserAction={props.removeUserAction}
                                               userId={user.userId}
                            />

                        </div>
                        <div className={"card-list-border"}/>
                    </>
                })}
            </div>

        </div>
    );
}
