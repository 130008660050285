import {FileMetadata} from "../../sharedmodels/fileMetaData/fileMetaData";
import {FileMetadataList} from "../fileMetadata/fileMetadataList";
import {FileLocationEnum} from "../../sharedmodels/file/fileLocationEnum";


export interface DokumentationListProps {
    tableTitle: string
    documentation: FileMetadata[]
    hideDate?: boolean;
    showTimeInDatetime?: boolean;
    hideCreatedBy?: boolean;
}

export function DocumentationList(props: DokumentationListProps) {

    return (
        <>
            <div>
                <h4>{props.tableTitle}</h4>
                <div>
                    <FileMetadataList
                        fileMetadatas={props.documentation}
                        showDeleteButton={false}
                        fileLocation={FileLocationEnum.Logbog}
                        hideDate={props.hideDate}
                        showTimeInDatetime={props.showTimeInDatetime}
                        hideCreatedBy={props.hideCreatedBy}
                    />
                </div>
            </div>
        </>
    )
}
