import React, {useState} from "react";
import {useAppDispatch} from "app/hooks";
import {Localizer} from "infrastructure/localization/localizer";
import useEffectAsync from "infrastructure/effect";
import {Loading} from "../../core/components/loading/loading";
import {SetTitleBar} from "../../core/layout/titleBar/titleBarApi";
import {ContactInformation} from "./contactInformation";

export function ContactPage() {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const uddannelseslaegeEmail = "uddannelseslaege@regionh.dk";

    SetTitleBar(Localizer.global_kontakt(),
        [{text: Localizer.global_kontakt(), url: ""}]);

    useEffectAsync(async () => {
        setIsLoading(false);
    }, [dispatch])

    return (
        <Loading isLoading={isLoading} text={Localizer.profilepage_getProfil()}>
            <div className="margin-top-l">
                <div className="card">
                    <div className="flex-container-column landing-page-colored-area">
                        <h3 className="padding-l flex-row-center">
                            {Localizer.landingPage_kontakt()}
                        </h3>

                        <div className="flex-container flex-row justify-content-center">
                            <p className={"landing-page-contact-info"}>
                                {Localizer.landingPage_kontaktInfo()}
                            </p>
                        </div>

                        <div className="card-body">
                            <div className="flex-container flex-row flex-space-evenly wrap align-items-start mt-4">
                                <ContactInformation/>
                            </div>
                        </div>
                        <div className="flex-container flex-row justify-content-center mt-5">
                            <p className={"landing-page-contact-info"}>
                                {Localizer.landingPage_kontaktBeskrivelse()}
                                <a href={"mailto:" + uddannelseslaegeEmail}>
                                    {uddannelseslaegeEmail}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Loading>
    );
}
