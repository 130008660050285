import {RolleTitelEnum} from "../../../infrastructure/enums/rolleTitelEnum";

export class StamdataUserSimpleResponseModel{
    public userId: string;
    public navn: string;
    public initialer: string;
    public email: string;
    public rolleTitelEnums: RolleTitelEnum[];
    public active: boolean;
}
