import {Localizer} from "../../../infrastructure/localization/localizer";
import {FilesAndFoldersResponseModel} from "../../../services/api/stamdataMappe/FilesAndFoldersResponseModel";
import {useAppSelector} from "../../../app/hooks";
import {forloebReducer, ForloebSliceState} from "../../forloebSlice";
import FolderlessFiles from "./folderlessFiles";
import CollapseFolderWithFiles from "./collapsableFolderWithFiles";
import { FileData } from "services/api/stamdataMappe/FilesAndFoldersModel";

const getBagdeText = (fileId: string, files: FileData[]) => files.find(x => x.fileMetadata.id === fileId)?.alleRegioner ? Localizer.global_national() : Localizer.global_regional();

export const FolderAndFiles = ({folderAndFiles}: {folderAndFiles: FilesAndFoldersResponseModel}) => {

    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    return (
        <>
            {(folderAndFiles?.filerUdenMappe && folderAndFiles?.filerUdenMappe?.length > 0) &&
                <FolderlessFiles
                    headerText={`${forloebSliceState.forloebState.maalbeskrivelseNavn} (${folderAndFiles.filerUdenMappe.length})`}
                    fileDatas={folderAndFiles.filerUdenMappe}  
                    showCustomBadgeText={(fileId: string) => getBagdeText(fileId, folderAndFiles?.filerUdenMappe ?? [])}              
                />
            }
            
            {folderAndFiles?.mapperMedFiler?.map((mmf, index) =>
                <CollapseFolderWithFiles
                    folder={mmf}
                    index={index}
                    showCustomBadgeText={(fileId: string) => getBagdeText(fileId,  mmf.files ?? [])}              
                    childShowCustomBadgeText={(fileId, files) =>  getBagdeText(fileId,  files ?? [])}
                />
            )}

        </>
    )
}
