import "./changeYourCookiesConsentBox.scss";
import DynamicButton from "core/components/button/dynamicButton";
import { CookieConsentContextType, useCookieConsentContext } from "core/components/cookies/context/CookieConsentContextProvider";
import useCookieConsentConfig from "core/components/cookies/hooks/useCookieConsentConfig";
import { Localizer } from "infrastructure/localization/localizer";

const resolveDescriptionText = (cookieConsent: CookieConsentContextType) => {
  if (!cookieConsent.hasGivenAnswer) {
    return Localizer.cookiesPage_cookiesamtykkeNotAnsweredInfo();
  }

  const userConsentsInfo = cookieConsent.userConsents ? Localizer.cookiesPage_cookiesamtykkeYouHaveAcceptedCookies() : Localizer.cookiesPage_cookiesamtykkeYouHaveDeclinedCookies();
  return `${userConsentsInfo} ${Localizer.cookiesPage_cookiesamtykkeYouCanChangeYourChoiceBelow()}`;

}

const ChangeYourCookieConsentBox = () => {
  const { cookieConsent, setCookieConsent } = useCookieConsentContext();
    const { setCookieConsentCookie } = useCookieConsentConfig();

    const onChangeCookieConsent = (userConsents: boolean) => {
        setCookieConsent(userConsents);
        setCookieConsentCookie(userConsents);
    }

  return (
    <div className="cookie-change-your-consent-box-container">
        <h4>
            {Localizer.cookiesPage_cookiesamtykkeTitle()}
        </h4>
        <div>
            <div>
              {resolveDescriptionText(cookieConsent)}
            </div>
            
              {cookieConsent.hasGivenAnswer &&
                <DynamicButton 
                  onClick={() => onChangeCookieConsent(!cookieConsent.userConsents)}
                  isPrimary
                  additionalClasses="mt-2"
                  disableMobileClasses
                >
                  {cookieConsent.userConsents ? Localizer.cookiesPage_cookiesamtykkeNoToCookies() : Localizer.cookiesPage_cookiesamtykkeYesToCookies()}
                </DynamicButton>
              }
        </div>
    </div>
  )
}

export default ChangeYourCookieConsentBox;
