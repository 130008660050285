import DynamicButton from "core/components/button/dynamicButton";
import {EmblaIcons} from "core/emblaIcons";
import {NotificationModule} from "ditmer-embla";
import {Localizer} from "infrastructure/localization/localizer";
import {AccountService} from "services/account/accountService";
import {LogbogLaegekursusApi} from "services/api/laegekursus/logbogLaegekursusApi";
import {HttpClientService} from "services/httpClient/httpClientService";
import {useRef} from "react";
import classNames from "classnames";
import {forloebReducer, ForloebSliceState} from "core/forloebSlice";
import {useAppSelector} from "app/hooks";

export type KursusButtonProps = {
    additionalClasses?: string;
}

export type GodkendKursusButtonProps = KursusButtonProps & {
    laegekursusId: string;
    godkendPaaAndetGrundlag?: boolean;
    kommentar?: string;
    laegeinstitutionName: string;
    afdelingName: string;
    brugerMaalbeskrivelseId?: string;
    doneCallBack: () => void;
    useIconWithNoText?: boolean;
    renderIcon?: boolean;
}

const laegekursusApi = new LogbogLaegekursusApi(new HttpClientService(), new AccountService())

export const godkendLaegeKursus = async (laegekursusId: string, godkendPaaAndetGrundlag: boolean, brugerMaalbeskrivelseId: string, laegeInstitutionName?: string, afdelingName?: string, kommentar?: string) => {

    //På klarmeldingssiden, kan vi ikke udlede uddannelsesstedet fra forløb-context, da det er kurser på tværs af brugere, hvor kurserne kan være fordelt på flere forløb.
    let uddannelsessted = "";
    if (laegeInstitutionName && afdelingName)
        uddannelsessted = `${laegeInstitutionName}, ${afdelingName}`;
    else if (!afdelingName && laegeInstitutionName)
        uddannelsessted = laegeInstitutionName;

    const godkendtLaegekursusResponse = await laegekursusApi.godkendLaegekursus(laegekursusId, uddannelsessted, godkendPaaAndetGrundlag, brugerMaalbeskrivelseId, kommentar);
    if (godkendtLaegekursusResponse.apiResponseMessage.errorMessage)
        notifyGodkendKursusError(godkendtLaegekursusResponse.apiResponseMessage.errorMessage)
    else
        notifyGodkendKursusSuccess();
    return godkendtLaegekursusResponse;
}

export const notifyGodkendKursusSuccess = () => {
    NotificationModule.showSuccess(Localizer.kursusPage_kursusGodkendt(), "");
}
export const notifyGodkendKursusError = (errorMessage: string) => {
    NotificationModule.showError(Localizer.kursusPage_kursusGodkendt(), errorMessage);
}

export const GodkendKursusButton = ({laegekursusId, additionalClasses, brugerMaalbeskrivelseId, laegeinstitutionName, afdelingName, godkendPaaAndetGrundlag=false, kommentar, doneCallBack, useIconWithNoText=false, renderIcon = true} : GodkendKursusButtonProps) => {

    const currentUser = useRef(new AccountService().getUser());
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    const handleGodkendLaegekursus = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        await godkendLaegeKursus(laegekursusId, godkendPaaAndetGrundlag, brugerMaalbeskrivelseId ?? forloebSliceState.forloebState.brugerMaalbeskrivelseId, laegeinstitutionName, afdelingName, kommentar).then(() => doneCallBack());
    }

    const shouldDisable = () => {
        const hasCorrectRoles = currentUser.current.IsUddannelsesansvarlig() || currentUser.current.IsVejleder();
        return hasCorrectRoles && currentUser.current.AuthorizationId === undefined;
    }

    return (
        <DynamicButton
            additionalClasses={classNames("mr-2 ml-2", additionalClasses)}
            onClick={(event) => handleGodkendLaegekursus(event)}
            disabled={shouldDisable()}
            rounded={useIconWithNoText}>
            <DynamicButton.TextIconItem
                iconName={EmblaIcons.Success}
                renderIcon={renderIcon}
                text={Localizer.global_godkend()}
                renderText={!useIconWithNoText}
            />
        </DynamicButton>
    );
}
