import React from "react";
import './landingPage.scss';
import {Localizer} from "../../infrastructure/localization/localizer";

interface IRegionInfo {
    telefonTekst: string;
    emailTekst: string;
    webTekst: string;
}

export const RegionInfo = (props: IRegionInfo) => {
    return <>
        <div className="flex-container-column card-list-box border-0">
            {props.telefonTekst &&
                <>
                    <h5 className="font-weight-bold">
                        {Localizer.landingPage_telefon()}
                    </h5>
                    <a href={"callto:"+props.telefonTekst} target={"_blank"}>
                        {props.telefonTekst}
                    </a>
                </>
            }

            {props.emailTekst &&
                <>
                    <h5 className="font-weight-bold">
                        {Localizer.landingPage_email()}
                    </h5>
                    <a href={"mailto:"+props.emailTekst} target={"_blank"}>
                        {props.emailTekst}
                    </a>
                </>
            }

            {props.webTekst &&
                <>
                    <h5 className="font-weight-bold">
                        {Localizer.landingPage_web()}
                    </h5>
                    <a href={"https://"+props.webTekst} target={"_blank"}>
                        {props.webTekst}
                    </a>
                </>
            }
        </div>
    </>
}
