import React, {useEffect, useRef, useState} from 'react'
import {Localizer} from 'infrastructure/localization/localizer'
import {HttpClientService} from "../../../../services/httpClient/httpClientService";
import {setSlideinState, SlideInStateEnum} from "../../../../core/components/slideIn/slideInSlice";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {ValidationFormSubmitButton} from "../../../../core/components/validation/components/validationFormButton";
import {AccountService} from "../../../../services/account/accountService";
import {HandleError} from "../../../../core/infrastructure/errors/errorBoundary";
import {ValidationForm} from "../../../../core/components/validation/components/validationForm";
import {forloebReducer, ForloebSliceState} from "../../../../core/forloebSlice";
import {UddannelsesplanModel} from "../uddannelsesplanModel";
import {
    BuildExisting,
    CrudUddannelsesplanInputModel,
    crudUddannelsesplanValidationSchema
} from "./crudUddannelsesplanInputModel";
import {UddannelsesplanCrudFields} from "./uddannelsesplanCrudFields";
import {LogbogUddannelsesplanApi} from "../../../../services/api/logbogUddannelsesplan/logbogUddannelsesplanApi";
import {BasicNotatModel} from "../../../../core/sharedmodels/notat/basicNotatModel";
import useEffectAsync from "../../../../infrastructure/effect";
import {NotatType} from "../../../../core/sharedmodels/notat/notatType";
import {Loading} from "../../../../core/components/loading/loading";
import {LogbogNotatApi} from "../../../../services/api/logbogNotat/logbogNotatApi";
import Tooltip, {TooltipPlacement} from "../../../../core/components/tooltips/Tooltip";

type EdituddannelsesplanProps = {
    modalId: string,
    model: UddannelsesplanModel,
    editedCallBack: (enighedTilkendegivet: boolean, editeduddannelsesplan: UddannelsesplanModel) => void,
    deletedCallBack: (deleteduddannelsesplan: UddannelsesplanModel) => void,

    confirmModalId: string,
    openConfirmModal: () => void
}

const uddannelsesplanApi = new LogbogUddannelsesplanApi(new HttpClientService(), new AccountService())
const notatApi = new LogbogNotatApi(new HttpClientService(), new AccountService());

export function EditUddannelsesplan(props: EdituddannelsesplanProps) {
    const dispatch = useAppDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    const _currentUser = useRef(new AccountService().getUser());
    const notat = useRef<BasicNotatModel | null>(null);
    const uddannelsesplanId = props.model.id;

    useEffect( () => {
        const fetchNotater = async () => {
            notat.current = await notatApi.getNotatOnContext(NotatType.Uddannelsesplan, forloebSliceState.forloebState.brugerId, uddannelsesplanId);
            setIsLoading(false);
        }
        fetchNotater();
    }, [forloebSliceState.forloebState.brugerId, uddannelsesplanId]);

    const edituddannelsesplan = async (inputModel: CrudUddannelsesplanInputModel) => {
        dispatch(setSlideinState({ state: SlideInStateEnum.ActionOngoing, slideInId: props.modalId }))
        const editeduddannelsesplan = await uddannelsesplanApi.editUddannelsesplan(inputModel);

        if (editeduddannelsesplan) {
            dispatch(setSlideinState({ state: SlideInStateEnum.Closing, slideInId: props.modalId }))
            props.editedCallBack(false, UddannelsesplanModel.FromEditUddannelsesplanInputModel(inputModel))
        } else {
            dispatch(setSlideinState({ state: SlideInStateEnum.Opened, slideInId: props.modalId }))
        }
    }

    const deleteUddannelsesplan = async (id: string) => {
        dispatch(setSlideinState({ state: SlideInStateEnum.ActionOngoing, slideInId: props.modalId }))
        let deleteduddannelsesplan = await uddannelsesplanApi.deleteUddannelsesplan(id);
        if (deleteduddannelsesplan) {
            dispatch(setSlideinState({ state: SlideInStateEnum.Closing, slideInId: props.modalId }))
            props.deletedCallBack(props.model);
        } else {
            dispatch(setSlideinState({ state: SlideInStateEnum.Opened, slideInId: props.modalId }))
        }
    }

    const godkendUddannelsesplan = async (id: string) => {
        dispatch(setSlideinState({ state: SlideInStateEnum.ActionOngoing, slideInId: props.modalId }))

        const uddannelsessted = forloebSliceState.forloebState.afdelingName === null
            ? forloebSliceState.forloebState.laegeInstitutionName
            : forloebSliceState.forloebState.laegeInstitutionName + ", " + forloebSliceState.forloebState.afdelingName

        const godkendtUddannelsesplan = await uddannelsesplanApi.godkendUddannelsesplan(id, uddannelsessted);

        if (godkendtUddannelsesplan) {
            dispatch(setSlideinState({ state: SlideInStateEnum.Closing, slideInId: props.modalId }))

            /* TODO TEMP HACK - Need to get uddannelsesplan with new data after patch!! */
            props.model.godkendelseTilkendegivetAf = _currentUser.current.Name;
            props.model.godkendelseTilkendegivetDato = new Date();
            /*****************************************************************/

            props.editedCallBack(true, props.model)
        } else {
            dispatch(setSlideinState({ state: SlideInStateEnum.Opened, slideInId: props.modalId }))
        }
    }

    const render = (
        <Loading isLoading={isLoading} text={Localizer.global_getData()}>
            <ValidationForm
                initialValues={BuildExisting(props.model.id,
                    props.model.titel,
                    props.model.beskrivelse,
                    forloebSliceState.forloebState.id,
                    props.model.oprettelsesDato,
                    props.model.klarmeldtAt)}
                validationSchema={crudUddannelsesplanValidationSchema}
                onSubmit={async (values) => {
                    await edituddannelsesplan(values)
                        .catch(err => { // catch is needed to handle unhandeled exception (eg. api offline). Formik onsubmithandler is catching all exception and writing them as warnings
                            dispatch(setSlideinState({ state: SlideInStateEnum.Closing, slideInId: props.modalId }))
                            HandleError(err);
                        });
                }}>
                {
                    (formik) => {
                        return (
                            <>
                                <UddannelsesplanCrudFields
                                    modalId={props.modalId}
                                    formik={formik}
                                    godkendtDate={props.model.godkendelseTilkendegivetDato}
                                    godkendtAfName={props.model.godkendelseTilkendegivetAf}
                                    notatInfo={{ contextId: props.model.id, notatType: NotatType.Uddannelsesplan, userId: forloebSliceState.forloebState.brugerId }}
                                    notat={notat.current}
                                    klarmeldtAt={props.model.klarmeldtAt}
                                    oprettetAfName={props.model.createdByName}
                                    oprettelsesDato={props.model.oprettelsesDato}
                                />
                                <div className="modal-actions">
                                    {((_currentUser.current.IsAdminOrVus() || _currentUser.current.IsVejleder() || _currentUser.current.IsUddannelsesansvarlig()) && props.model.godkendelseTilkendegivetDato) &&
                                        <button className="btn btn-outline-danger mr-2"
                                                onClick={() => props.openConfirmModal()}
                                                data-toggle="modal" data-target={`#${props.confirmModalId}`}
                                                data-dismiss="modal" >
                                            {Localizer.global_notAgree()}
                                        </button>
                                    }

                                    {((_currentUser.current.IsUddannelsesansvarlig() || _currentUser.current.IsVejleder() || _currentUser.current.IsAdminOrVus()) && !props.model.godkendelseTilkendegivetDato) && //TODO: Check which users should have access to accept/agree with the "uddennelsesplan"
                                        <button type={"button"}
                                                className="btn btn-success mr-2"
                                                onClick={() => godkendUddannelsesplan(props.model.id)}>{Localizer.global_agree()}
                                        </button>
                                    }

                                    {!props.model.godkendelseTilkendegivetDato &&
                                        <button type={"button"}
                                                className="btn btn-danger mr-2"
                                                onClick={() => deleteUddannelsesplan(props.model.id)}>{Localizer.global_delete()}
                                        </button>
                                    }

                                    <Tooltip title={props.model.godkendelseTilkendegivetDato !== undefined ? Localizer.uddannelsesplanFejlRedigerInfo() : ""} placement={TooltipPlacement.Left}>
                                        <ValidationFormSubmitButton formikProps={formik} disabled={props.model.godkendelseTilkendegivetDato !== undefined}>
                                            {Localizer.global_save()}
                                        </ValidationFormSubmitButton>
                                    </Tooltip>

                                </div>
                            </>
                        );

                    }
                }
            </ValidationForm>
        </Loading>
    )

    return <>{render}</>
}
