import {useAppSelector} from "../../../app/hooks";
import React from "react";
import {Score} from "../../../core/components/score/score";
import {
    evalueringsstatistikFiltersReducer,
    EvalueringsstatistikFiltersState
} from "../evalueringsstatistikFiltersSlice";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {ScoreTypeEnum} from "../../../core/componentsPage/score/scoreTypeEnum";

type AverageScoreOverviewProps = {
    avgScores: number[];
}

export function AverageScoreOverview({avgScores}: AverageScoreOverviewProps) {
    const evalueringsstatistikFilterSliceState = useAppSelector(evalueringsstatistikFiltersReducer) as EvalueringsstatistikFiltersState;

    return (
        <div className="card-body border-top">
                <h5 className="margin-bottom-s">{Localizer.evalueringPage_gennemsnit()}</h5>
                <div className="d-flex wrap">
                    {evalueringsstatistikFilterSliceState.spoergsmaalToShow.length > 0 &&
                        <React.Fragment>
                            {evalueringsstatistikFilterSliceState.spoergsmaalToShow.map((spoergsmaalsnummer, index) => {
                                return (
                                    <div className="flex-row"
                                         key={index}>
                                        <p className="margin-0">{spoergsmaalsnummer}</p>
                                        <Score svarVaerdi={avgScores[(+spoergsmaalsnummer) - 1]} withDecimal={true} showText={false} scoreType={ScoreTypeEnum.ForloebEvalueringScore}/>
                                    </div>
                                )
                            })}
                        </React.Fragment>
                    }
                    {evalueringsstatistikFilterSliceState.spoergsmaalToShow.length === 0 &&
                        <React.Fragment>
                            {avgScores.map((avgScore, index) => {
                                return (
                                    <div className="flex-row"
                                         key={index}>
                                        <p className="margin-0">{index + 1}</p>
                                        <Score svarVaerdi={avgScore} withDecimal={true} showText={false} scoreType={ScoreTypeEnum.ForloebEvalueringScore}/>
                                    </div>
                                )
                            })}
                        </React.Fragment>
                    }
                </div>
        </div>
    )
}

