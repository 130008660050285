import React from "react";
import {Score} from "../../../core/components/score/score";
import {ScoreTypeEnum} from "../../../core/componentsPage/score/scoreTypeEnum";


interface QuestionComponentProps {
    questionNumber: number;
    questionTitle: string;
    questionText?: string;
    score?: number;
}

export function SingleQuestionComponent(props: QuestionComponentProps) {

    return (
        <div className={"flex-container margin-top-s padding-bottom-s"}>
            <h5>{props.questionNumber}</h5>
            <div className={"margin-left-m spoergeramme-question"}>
                {props.score
                ?
                    <div className={"flex-space"}>
                        <div>
                            <h5>{props.questionTitle}</h5>
                            {props.questionText}
                        </div>
                        <div className={"align-self-start padding-left-s"}>
                            <Score svarVaerdi={props.score} withDecimal={true} showText={false} scoreType={ScoreTypeEnum.ForloebEvalueringScore}/>
                        </div>
                    </div>
                :
                    <div>
                        <h5>{props.questionTitle}</h5>
                        {props.questionText}
                    </div>
                }

            </div>
        </div>
    )
}
