import {ChangeEvent, useCallback, useState} from "react";
import {EmblaIcon} from "../emblaIcon/emblaIcon";
import {EmblaIcons} from "../../emblaIcons";
import useScreenResolution from "../../hooks/useScreenResolution";
import {debounce} from "lodash";
import {Localizer} from "../../../infrastructure/localization/localizer";
import classNames from "classnames";

type dataAttributes = {
    tooltip?: boolean;
    html?: string;
    originalTitle?: string;
}

type SearchFieldWithInputTimerProps = {
    placeholder?: string;
    disabled?: boolean;
    callback: (search: string) => void;
    timeDelay?: number;
    minimumInput?: number;
    preventDefaultEvent?: boolean;
    additionalClasses?: string;
    data?: dataAttributes;
    initialValue?: string;
}

export const SearchField = ({placeholder=Localizer.global_indtastSoegning(), callback, minimumInput=0, timeDelay=1500, disabled=false, preventDefaultEvent=false, additionalClasses, data, initialValue=""}: SearchFieldWithInputTimerProps) => {
    const { isMobile } = useScreenResolution();
    const [searchText, setSearchText] = useState<string>(initialValue);

    const search = (event: ChangeEvent<HTMLInputElement>) => {
        if(preventDefaultEvent)
            event.preventDefault();
        
        setSearchText(event.target?.value)
        if (event.target?.value?.length >= minimumInput || !event.target?.value?.length)
            callback(event.target?.value)
    }

    return (
        <div 
            className={classNames('d-flex input-icon-container', isMobile && 'flex-grow', additionalClasses)}
            data-tooltip={data?.tooltip}
            data-html={data?.html}
            data-original-title={data?.originalTitle}
        >
            <input
                defaultValue={searchText}
                className="form-control"
                placeholder={`${placeholder} ${minimumInput > 0 ? `(${minimumInput} ${Localizer.global_tegn()})` : ""}`}
                minLength={minimumInput}
                disabled={disabled}
                type={"search"}
                onChange={debounce((event: ChangeEvent<HTMLInputElement>) => search(event), timeDelay)}
            />
            {!searchText &&
                <div className="input-icon">
                    <EmblaIcon iconName={EmblaIcons.Search}/>
                </div>
            }
        </div>
    )
}
