import { PropsWithChildren } from "react";
import classNames from "classnames";

type CardBodyProps = {
    additionalClasses?: string;
}

const CardBody = ({additionalClasses, children} : PropsWithChildren<CardBodyProps>) => {
    return (
        <div className={classNames("card-body", additionalClasses)}>
            {children}
        </div>
    );
}

export default CardBody;