import React, {createContext, ReactNode, useContext, useReducer} from "react";
import {SynkroniseredeForloebModel} from "../../../core/sharedmodels/forloeb/synkroniseredeForloebModel";

export type DataadministrationFilterContextType = {
    searchLaege: string;
    regioner?: string[];
    specialer?: string[];
}

type DataadministrationListContextType = {
    synkroniseredeForloeb: SynkroniseredeForloebModel[];
}

type StatusContextType = {
    isLoading: boolean;
}

const DataadministrationContext = createContext<{
    filterReducer: {
        state: DataadministrationFilterContextType,
        dispatch: React.Dispatch<DataadministrationFilterContextType>
    },
    listReducer: {
        state: DataadministrationListContextType,
        dispatch: React.Dispatch<DataadministrationListContextType>
    },
    statusReducer: {
        state: StatusContextType,
        dispatch: React.Dispatch<StatusContextType>
    },
} | undefined>(undefined);

const filterContextReducer = (state: DataadministrationFilterContextType, action: { searchLaege: string, specialer?: string[], regioner?: string[] }): DataadministrationFilterContextType => {
    return {
        ...state,
        searchLaege: action.searchLaege,
        specialer: action.specialer,
        regioner: action.regioner,
    };
};

const DataadministrationListContextReducer = (state: DataadministrationListContextType, action: { synkroniseredeForloeb: SynkroniseredeForloebModel[] }): DataadministrationListContextType => {
    return {
        ...state,
        synkroniseredeForloeb: action.synkroniseredeForloeb,
    };
};

const statusContextReducer = (state: StatusContextType, action: { isLoading: boolean }): StatusContextType => {
    return {...state, isLoading: action.isLoading};
};

export const useDataadministrationContext = () => {
    const context = useContext(DataadministrationContext);
    if (!context) throw new Error("useDataadministration Context must be used in DataadministrationContextProvider");
    return context;
};

type DataadministrationContextProviderProps = { children?: ReactNode; }

const DataadministrationOverviewContextProvider = ({children}: DataadministrationContextProviderProps) => {

    const [filterState, filterDispatch] = useReducer(filterContextReducer, {searchLaege: ""});
    const [listState, listDispatch] = useReducer(DataadministrationListContextReducer, {synkroniseredeForloeb: []});
    const [statusState, statusDispatch] = useReducer(statusContextReducer, {isLoading: false});

    return (
        <DataadministrationContext.Provider value={{
            filterReducer: {state: filterState, dispatch: filterDispatch},
            listReducer: {state: listState, dispatch: listDispatch},
            statusReducer: {state: statusState, dispatch: statusDispatch}
        }}>
            {children}
        </DataadministrationContext.Provider>
    );
};

export default DataadministrationOverviewContextProvider;
