import { DropdownOption } from "../dropdown";

const formatNumericDropdownOptionsToCustomLabel = (numericOptions: DropdownOption<number>[], formatFunction: (n: number) => string) => {
  return numericOptions.map(dropdownOption => {
    return {
    label: formatFunction(dropdownOption.value),
    value: dropdownOption.value
  }});
}


export default formatNumericDropdownOptionsToCustomLabel;