import { useEffect, useState } from "react";
import { DropdownOption } from "../dropdown";
import { ForSelectModel, toDropdownOptions } from "core/sharedmodels/forSelectModel";
import DropdownPermissions, { filterByDropdownPermissions } from "../dropdownPermissions/DropdownPermissions";

const useForSelectModelDropdownOptions = (apiPromise: () => Promise<ForSelectModel[]>, dropdownPermissions?: DropdownPermissions, initialSelectedOption?: string | string[]) => {
    const [allDropdownOptions, setAllDropdownOptions] = useState<DropdownOption<string>[]>([]);
    const [dropdownOptions, setDropdownOptions] = useState<DropdownOption<string>[]>([]);
    const [selectedOption, setSelectedOption] = useState(initialSelectedOption);
    
    const [triedToFetch, setTriedToFetch] = useState(false);
    const [hasFetched, setHasFetched] = useState(false);

    useEffect(() => {
        const filteredOptions = filterByDropdownPermissions(allDropdownOptions, dropdownPermissions);
        setDropdownOptions(filteredOptions);
    }, [allDropdownOptions, dropdownPermissions]);

    useEffect(() => {
        const fetchOptions = async () => {
            if(triedToFetch || hasFetched) return;
            
            setTriedToFetch(true);
            const forSelectModels = await apiPromise();
            setAllDropdownOptions(prev => [...prev, ...toDropdownOptions(forSelectModels)]);
            setHasFetched(true);
        }

        fetchOptions();
    }, [apiPromise, dropdownPermissions, hasFetched, triedToFetch]);

    return {
        dropdownOptions,
        selectedOption,
        setSelectedOption,
    };
}

export default useForSelectModelDropdownOptions;