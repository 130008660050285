import React, { useEffect, useState } from 'react'
import { useAppDispatch } from "../../../../app/hooks";
import { Localizer } from '../../../../infrastructure/localization/localizer';
import { NotatType } from 'core/sharedmodels/notat/notatType';
import { BuildNew, GodkendTidInputModel, godkendTidValidationSchema } from './godkendTidInputModel'
import { ValidationForm } from 'core/components/validation/components/validationForm';
import { HandleError } from 'core/infrastructure/errors/errorBoundary';
import { setSlideinState, SlideInStateEnum } from 'core/components/slideIn/slideInSlice';
import { ValidationFormSubmitButton } from 'core/components/validation/components/validationFormButton';
import { ValidationTextarea } from 'core/components/validation/components/validationTextarea';
import { nameof } from 'ts-simple-nameof';
import { pingRefreshForloeb } from 'core/forloebSlice';
import { NotificationModule } from 'ditmer-embla';
import { BasicNotatModel } from "../../../sharedmodels/notat/basicNotatModel";
import useUser from 'core/hooks/useUser';
import useLogbogApi from 'core/hooks/useLogbogApi';
import AttestationForTidFileList from '../attestationForTidFileList';
import Title from 'core/components/titels/title';

type GodkendTidProps = {
    modalId: string,
    forloebId: string,
    uddannelsessted: string,
    userId: string,
    attestationForTidInfoTekst?: string,
    brugerMaalbeskrivelseId?: string;
}

export const GodkendTid = ({modalId, forloebId, uddannelsessted, userId, attestationForTidInfoTekst, brugerMaalbeskrivelseId=""}: GodkendTidProps) => {
    const { currentUser } = useUser();
    const { notatApi, forloebApi } = useLogbogApi();

    const dispatch = useAppDispatch();
    const [attestationForTid, setAttestationForTid] = useState<BasicNotatModel>();

    useEffect(() => {
        const fetchAttestatoinForTid = async () => {
            const attestationForTidNotat = await notatApi.getNotatOnContext(NotatType.AttestationForTid, userId, forloebId);
            if (attestationForTidNotat) setAttestationForTid(attestationForTidNotat);
        };

        fetchAttestatoinForTid();
    }, [notatApi, forloebId, userId]);

    const godkendTid = async (input: GodkendTidInputModel) => {
        dispatch(setSlideinState({ state: SlideInStateEnum.ActionOngoing, slideInId: modalId }))
        const forloebGodkendt = await forloebApi.GodkendForloeb(forloebId, uddannelsessted, input.kommentar);

        if (forloebGodkendt) {
            NotificationModule.showSuccessSmall(Localizer.forloebpage_forloebTidGodkendt());
            dispatch(setSlideinState({ state: SlideInStateEnum.Closing, slideInId: modalId }))
            dispatch(pingRefreshForloeb());
        }
    }

    return (
        <>
            <div>
                <div>
                    {Localizer.forloebpage_GodkendtTidTekst()}
                    <ul>
                        <li>{Localizer.forloebpage_GodkendtTidTekstFoerstePunkt()}</li>
                        <li>{Localizer.forloebpage_GodkendtTidTekstAndetPunkt()}</li>
                    </ul>
                </div>

                {attestationForTidInfoTekst &&
                    <div className="row margin-top-m">
                        <div className="col-12">

                            <label>{Localizer.forloebpage_BemaerkningerTilForloeb()}</label>
                            <textarea className="form-control" readOnly={true} rows={5}>
                                {attestationForTidInfoTekst}
                            </textarea>
                        </div>
                    </div>
                }

                <div className={"margin-top-m"}>
                   <ValidationForm

                       key={modalId}
                       initialValues={BuildNew(forloebId, uddannelsessted, attestationForTidInfoTekst)}
                       validationSchema={godkendTidValidationSchema}
                       onSubmit={async (values) => {
                           await godkendTid(values)
                               .catch(err => { // catch is needed to handle unhandeled exception (eg. api offline). Formik onsubmithandler is catching all exception and writing them as warnings
                                   HandleError(err);
                                   dispatch(setSlideinState({ state: SlideInStateEnum.Opened, slideInId: modalId }))
                               });
                       }}>
                       {
                           (formik) => (
                               <>

                                    {attestationForTid &&
                                        <Title title={Localizer.forloebpage_FilerForAttestationForTid()} titleClasses='margin-top-s'>
                                            <AttestationForTidFileList
                                                forloebId={forloebId}
                                                userId={userId}
                                                basicNotatModel={attestationForTid}
                                                brugerMaalbeskrivelseId={brugerMaalbeskrivelseId}
                                                isReadonly={true}
                                            />
                                        </Title>
                                    }

                                   <div className="row">
                                       <div className="col-sm-12">

                                           <ValidationTextarea
                                               model={{
                                                   label: Localizer.global_addCommentGodkend(),
                                                   placeholder: Localizer.global_addCommentGodkendLabel(),
                                                   htmlName: nameof<GodkendTidInputModel>(x => x.kommentar)
                                               }}
                                               inputId={nameof<GodkendTidInputModel>(x => x.kommentar) + modalId}
                                               formikProps={formik}
                                           />
                                       </div>
                                   </div>
                                   {((currentUser.IsUddannelsesansvarlig() || currentUser.IsVejleder()) && currentUser.AuthorizationId === undefined) &&
                                       <div className=" alert alert-warning" role={"alert"}>
                                            {Localizer.forloebpage_godkendTidWarning()}
                                       </div>
                                   }


                                   <div className="modal-actions">
                                       <ValidationFormSubmitButton formikProps={formik} disabled={((currentUser.IsUddannelsesansvarlig() || currentUser.IsVejleder()) && currentUser.AuthorizationId === undefined)}>
                                           {Localizer.forloebpage_godkendTid()}
                                       </ValidationFormSubmitButton>
                                   </div>
                               </>
                           )
                       }
                   </ValidationForm>

               </div>

            </div>
        </>
    )
}
