import {Localizer} from 'infrastructure/localization/localizer'
import {SetTitleBar} from "../../core/layout/titleBar/titleBarApi";
import {ForloebOverview} from "../../core/components/forloebOverview/forloebOverview";

export function ForloebOverviewPage() {
    SetTitleBar(Localizer.global_forloebOverview(),
        [{text: Localizer.global_forloebOverview(), url: ""}]);

    return (
        <>
            <ForloebOverview/>
        </>
    );
}
