import React, {useRef, useState} from 'react'
import {Localizer} from "../../../infrastructure/localization/localizer";
import {UserRoleToString} from "../../../infrastructure/enums/userRole";
import {FileMetadataList} from "../../../core/components/fileMetadata/fileMetadataList";
import {FileLocationEnum} from "../../../core/sharedmodels/file/fileLocationEnum";
import useEffectAsync from "../../../infrastructure/effect";
import {NotatType} from "../../../core/sharedmodels/notat/notatType";
import {BasicNotatModel} from "../../../core/sharedmodels/notat/basicNotatModel";
import {LogbogNotatApi} from "../../../services/api/logbogNotat/logbogNotatApi";
import {HttpClientService} from "../../../services/httpClient/httpClientService";
import {useAppSelector} from "../../../app/hooks";
import {forloebReducer, ForloebSliceState} from "../../../core/forloebSlice";
import {Loading} from "../../../core/components/loading/loading";
import {GodkendelseInfoModel} from "../../../core/sharedmodels/godkendelse/godkendelseInfoModel";
import {AccountService} from "../../../services/account/accountService";
import {
    GetLaegekursusGruppeTranslation,
    LaegekursusGruppeTypeEnum
} from "../../../core/sharedmodels/kursus/laegekursusGruppeTypeEnum";

interface SamletGodkendelseInfoProps {
    areYouSureModalId: string,
    godkendelsesInfo: GodkendelseInfoModel,
    laegekursusGruppeId: string,
    godkendtPaaAndetGrundlag: boolean,
    openAreYouSureModal: () => void,
    laegekursusGruppeKategori: LaegekursusGruppeTypeEnum,
}

const notatApi = new LogbogNotatApi(new HttpClientService(), new AccountService());

export function SamletGodkendelseInfo(props: SamletGodkendelseInfoProps) {
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;
    const notat = useRef<BasicNotatModel | null>(null);
    const [isLoading, setIsLoading] = useState(true)
    const _currentUser = useRef(new AccountService().getUser());

    useEffectAsync(async () => {
        notat.current = await notatApi.getNotatOnContext(NotatType.LaegekursusGruppe, forloebSliceState.forloebState.brugerId, props.laegekursusGruppeId);
        setIsLoading(false);
    }, []);

    const render = (
        <Loading isLoading={isLoading} text={Localizer.kursusPage_createKursus()}>
            <div>
                <div className="pre-line">
                    <div className="alert alert-success left-right-minus-spacing">
                        { props.laegekursusGruppeKategori === LaegekursusGruppeTypeEnum.Forskningstraening &&
                            Localizer.kursusPage_samletGodkendelseGodkendtInfoForskningstraening()
                        }
                        {props.laegekursusGruppeKategori !== LaegekursusGruppeTypeEnum.Forskningstraening &&
                            Localizer.kursusPage_samletGodkendelseGodkendtInfo(GetLaegekursusGruppeTranslation(props.laegekursusGruppeKategori).toLowerCase())
                        }
                        {props.godkendtPaaAndetGrundlag &&
                            Localizer.kursusPage_denneGruppeErGodkendtPaaAndetGrundlag()
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <p className="font-weight-bold">{Localizer.dato()}</p>
                        {props.godkendelsesInfo.godkendtDen.dateWithoutTimeFormat(true)}
                    </div>
                    <div className="col-sm-6">
                        <p className="font-weight-bold">{Localizer.global_godkendtAf()}</p>
                        {props.godkendelsesInfo.godkendtAfNavn}
                    </div>
                    <div className="col-sm-6 margin-top-l">
                        <p className="font-weight-bold">{Localizer.global_authorisationId()}</p>
                        {props.godkendelsesInfo.authorisationsId}
                    </div>
                    <div className="col-sm-6 margin-top-l">
                        <p className="font-weight-bold">{Localizer.rolle()}</p>
                        {props.godkendelsesInfo.rolleTitel}
                    </div>

                    {props.godkendtPaaAndetGrundlag &&
                    <div className="col-sm-12">
                        <FileMetadataList fileMetadatas={notat.current?.fileMetadatas ?? []}
                                          fileLocation={FileLocationEnum.Logbog}
                                          notat={notat.current}
                                          showDeleteButton={false}
                                          withUploader={false}
                        />
                    </div>
                    }
                </div>

                <div className="modal-actions">
                    <div>
                        <button className="btn btn-danger" onClick={() => props.openAreYouSureModal()}
                                data-toggle="modal" data-target={`#${props.areYouSureModalId}`}
                                disabled={!(_currentUser.current.IsAdminOrVus())}
                                data-dismiss="modal">
                            {Localizer.kursusPage_fjernGodkendelse()}
                        </button>
                    </div>
                </div>
            </div>
        </Loading>
    )
    return <>{render}</>
}
