import * as Sentry from "@sentry/react";

const applicationTag = "uddannelseslaege-frontend";

export const buildSentryConfig = (sentryDsn: string, sentryEnvironment: string): Sentry.BrowserOptions => (
  {
    dsn: sentryDsn,
    environment: sentryEnvironment,
    initialScope: {
        tags: { 
            "application": applicationTag
        }
    },
    defaultIntegrations: [new Sentry.Integrations.GlobalHandlers({
        onunhandledrejection: false,
        onerror: false
    })] //TODO: Forsøg på at logge unhandledrejections bedre jvnf: https://docs.sentry.io/platforms/javascript/guides/react/usage/ og https://docs.sentry.io/platforms/javascript/configuration/integrations/default/
  }
)


