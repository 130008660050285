import { forloebOverviewMenuLink, brugereMenuLink, evalueringsstatistikMenuLink, nyhederMenuLink, vejledereMenuLink, fileAdministrationMenuLink } from "core/layout/shared/MenuLinkProps";
import { DynamicMenu } from "../DynamicMenu";
import MenuProps from "./menuProps";

export function SekretaerMenu({ showNotification, nyhederMenuLink }: MenuProps) {

    const menuLinks = [
        forloebOverviewMenuLink,
        vejledereMenuLink,
        brugereMenuLink,
        evalueringsstatistikMenuLink,
        nyhederMenuLink,
        fileAdministrationMenuLink
    ];

    return (
        <DynamicMenu menuLinks={menuLinks} showNotifications={showNotification}/>
    );

}
