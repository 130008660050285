import {ForSelectModel} from "../forSelectModel";

export class UddannelsesstedModel {
    public id: string;
    public navn: string;
    public sygehusnavn?: string;
    public omraadenavn?: string;
    public selected: boolean;
    public speciale: string;

    static FromSelectModel(selectModel: ForSelectModel[]): UddannelsesstedModel[] {
        return selectModel.map(x => ({
            id: x.id,
            navn: x.text,
            selected: false
        })) as UddannelsesstedModel [];
    }
}
