import { SetTitleBar } from "core/layout/titleBar/titleBarApi";
import BrevskabelonPageComponent from "./components/BrevskabelonPageComponent";
import BrevskabelonContextProvider from "./context/brevskabelonContextProvider";
import { Localizer } from "infrastructure/localization/localizer";

const BrevskabelonPage = () => {
    
    SetTitleBar(Localizer.global_letters(), []);
    
    return(
        <BrevskabelonContextProvider>
            <BrevskabelonPageComponent />
        </BrevskabelonContextProvider>
    )
}

export default BrevskabelonPage;