import createValidationSchema from "../../../../core/components/validation/createValidationSchema";
import * as yup from "yup";
import {Localizer} from "../../../../infrastructure/localization/localizer";

export class CrudUddannelsesplanInputModel {
    constructor(forloebId: string, titel?: string, beskrivelse?: string) {
        this.forloebId = forloebId;
        this.titel = titel ?? "";
        this.beskrivelse = beskrivelse ?? "";
        this.klarmeldtAt = undefined;
    }
    public id: string;
    public forloebId: string;
    public titel: string;
    public beskrivelse: string;
    public oprettelsesDato: Date;
    public klarmeldtAt?: string;
}

export const BuildExisting = (id: string, titel:string, beskrivelse: string, forloebId: string, oprettelsesDato: Date,  klarmeldtAt?: string): CrudUddannelsesplanInputModel =>  {

    var inputModel = new CrudUddannelsesplanInputModel(titel, beskrivelse);

    inputModel.id = id;
    inputModel.forloebId = forloebId;
    inputModel.titel = titel;
    inputModel.beskrivelse = beskrivelse;
    inputModel.oprettelsesDato = oprettelsesDato;
    inputModel.klarmeldtAt = klarmeldtAt;

    return inputModel;
}


export const crudUddannelsesplanValidationSchema = createValidationSchema<CrudUddannelsesplanInputModel>({
    id: yup.string().notRequired(),
    forloebId: yup.string().notRequired(),
    titel: yup.string().required(Localizer.validate_titelRequired()),
    beskrivelse: yup.string().required(Localizer.validate_descriptionRequired()),
    oprettelsesDato: yup.string().notRequired(),
});
