enum FilterHierarchicalEnum
{
    Afdeling = 1,
    Sygehus = 2,
    Praksis = 3,
    Omraade = 4,
    Region = 5,
    Speciale = 6,
}

export default FilterHierarchicalEnum;