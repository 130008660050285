import "./nyhederPage.scss";
import {SetTitleBar, SetTitleBarWithActions} from "../../core/layout/titleBar/titleBarApi";
import {Localizer} from "../../infrastructure/localization/localizer";
import {useState} from "react";
import {SlideIn} from "../../core/components/slideIn/slideIn";
import {NyhedModel} from "../../core/sharedmodels/nyheder/nyhedModel";
import {Loading} from "../../core/components/loading/loading";
import {Pagination} from "../../core/components/pagination/pagination";
import {CreateNyheder} from "./components/createNyheder";
import {EditNyheder} from "./components/editNyheder";
import {NotificationModule} from "ditmer-embla";
import {ModalSize} from "../../core/components/slideIn/modalSize";
import useScreenResolution from "core/hooks/useScreenResolution";
import useUser from "core/hooks/useUser";
import useNyhederFiltered from "./hooks/useNyhederFiltered";
import { SearchField } from "core/components/search/searchField";
import classNames from "classnames";
import { Dropdown } from "core/components/dropdown/dropdown";
import useEnumDropdownOptions from "core/components/dropdown/hooks/useEnumDropdownOptions";
import NyhedCard from "./components/nyhedCard";
import useNotifications from "core/components/notifications/hooks/useNotifications";
import { NotifikationEventTypeEnum } from "core/components/notifications/models/notificationModel";
import { RolleTitelEnum, UserRoleTitleToString } from "infrastructure/enums/rolleTitelEnum";
import { RolleTitelToRolle } from "core/rolleMapper";

const createModalTarget = "nyheder-create-modal";
const editModalTarget = "nyheder-edit-modal";

export const NyhederPage = () => {
    const [selectedNyhed, setSelectedNyhed] = useState<NyhedModel>();
    const [showEditModal, setShowEditModal] = useState(false);
    const [ rolleTitelDropdownOptions ] = useEnumDropdownOptions<RolleTitelEnum>(RolleTitelEnum, UserRoleTitleToString);

    const { currentUser } = useUser();
    const { isMobile } = useScreenResolution();

    const {
        nyhederState,
        isLoading,
        paginationModel,
        changeSearch,
        changeRoles,
        handlePagination,
        refetchNyheder
    } = useNyhederFiltered();

    const { notifications } = useNotifications();
    const unseenNewsNotifications = notifications.filter(x => x.type === NotifikationEventTypeEnum.NyhedPubliceret && !x.erSet);

    if(currentUser.IsAdminOrVus()) {
        SetTitleBarWithActions(Localizer.global_nyheder(), [], [{ text: Localizer.nyhederPage_OpretNyhed(), dataTarget: createModalTarget }]);
    } else {
        SetTitleBar(Localizer.global_nyheder(), [{text: Localizer.global_nyheder(), url: ""}]);
    }

    const openEditModal = (nyhed: NyhedModel) => {
        setSelectedNyhed(nyhed);
        setShowEditModal(true);
    }

    const onFilterByRoleTitles = (roleTitels: RolleTitelEnum[]) => {
        const rolesSelected = roleTitels.map(x => RolleTitelToRolle(x));
        changeRoles(rolesSelected);
    }

    return (
            <div>
                <div className={"filter-container sticky-top"}>
                    <div className="max-w-600">
                        <Dropdown
                            placeholder={Localizer.global_filtrer()}
                            options={rolleTitelDropdownOptions.sortBy(x => x.label)}
                            className={classNames(isMobile && "mobile-input-container")}
                            minWidth={!isMobile ? 300 : 180}
                            onChange={(newVal) => onFilterByRoleTitles(newVal.map(x => x.value))}
                            renderMultiValuesAsText
                            renderMultiValuesAsTextAppendTextOverride={`${Localizer.roller().toLowerCase()} ${Localizer.valgt()}`}
                            isClearable
                            isMulti
                        />
                    </div>
                    <SearchField
                        additionalClasses={classNames("margin-bottom-m", isMobile && "mobile-input-container")}
                        placeholder={Localizer.global_indtastSoegning()}
                        callback={(search: string) => changeSearch(search)}
                    />
                </div>
                <Loading isLoading={isLoading} text={Localizer.nyhederPage_gettingNyheder()} emptyText={nyhederState.length === 0 ? Localizer.nyhederPage_noNews() : ""}>
                    {nyhederState.map((nyhed) => (
                        <NyhedCard
                            key={nyhed.id}
                            nyhed={nyhed}
                            showEditButton={currentUser.IsAdminOrVus()}
                            onEditButtonClicked={openEditModal}
                            hasNotification={unseenNewsNotifications.some(x => x.contextId === nyhed.id)}
                        />
                        ))
                    }
                    <div className={`flex-row-center ${isMobile ? 'margin-bottom-xl ' : ''}`}>
                        <Pagination 
                            handlePagination={handlePagination}
                            paginationModel={paginationModel}
                        />
                    </div>
                </Loading>

                <SlideIn
                    id={createModalTarget}
                    title={Localizer.nyhederPage_OpretNyhed()}
                    bodyContent={<CreateNyheder modalTarget={createModalTarget}/>}
                    defaultOpen={false}
                    actionText={Localizer.nyhederPage_CreatingNyhed()}
                    actionFinishedCallback={async () => {
                        await refetchNyheder();
                        NotificationModule.showSuccess(Localizer.nyhederPage_nyhedCreated(), "");
                    }}
                    modalSize={ModalSize.md}
                />

                {(selectedNyhed && showEditModal) &&
                    <SlideIn
                        id={editModalTarget}
                        title={Localizer.nyhederPage_EditNyhed()}
                        bodyContent={<EditNyheder modalTarget={editModalTarget} nyhedModel={selectedNyhed}/>}
                        defaultOpen={true}
                        actionText={Localizer.nyhederPage_EditingNyhed()}
                        actionFinishedCallback={async () => {
                            await refetchNyheder();
                            NotificationModule.showSuccess(Localizer.nyhederPage_nyhedEdited(), "");
                            setShowEditModal(false);
                        }}
                        actionOnCloseCallback={() => setShowEditModal(false)}
                        modalSize={ModalSize.md}
                    />
                }
            </div>
    );
}
