import React, {PropsWithChildren, useState} from 'react'
import {Form, Formik} from "formik";
import {useAppDispatch} from "../../../app/hooks";
import {
    BuildNew,
    CheckIfAllIsChosen,
    crudKlarmeldingValidationSchema,
    KlarmeldingInputModel
} from "./klarmeldingInputModel";
import {setSlideinState, SlideInStateEnum} from "../../../core/components/slideIn/slideInSlice";
import {HandleError} from "../../../core/infrastructure/errors/errorBoundary";
import {KlarmeldingCrudFields} from "./klarmeldingCrudFields";
import {ValidationFormSubmitButton} from "../../../core/components/validation/components/validationFormButton";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {ModalAcceptType} from "../../../core/components/modalMessage/modalSubmitMessage";
import {KlarmeldingTypeEnum} from "../../../infrastructure/enums/klarmeldingTypeEnum";

type CreateKlarmeldingProps = {
    modalId: string,
    isKlarmeldState: boolean,
    klarmeldToDelkursusledere?: boolean,
    updateStatusCallback: (inputModel: KlarmeldingInputModel) => Promise<void>,
    klarmeldingType: KlarmeldingTypeEnum
}

export function CreateOrRemoveKlarmelding({isKlarmeldState, children, klarmeldingType, modalId, updateStatusCallback, klarmeldToDelkursusledere = false}: PropsWithChildren<CreateKlarmeldingProps>) {
    const dispatch = useAppDispatch();
    const [disableSubmit, setDisableSubmit] = useState(false);

    const createKlarmelding = async (inputModel: KlarmeldingInputModel) => {
        dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: modalId}))
        CheckIfAllIsChosen(inputModel);
        await updateStatusCallback(inputModel)
    }

    const render = (
        <Formik
            initialValues={BuildNew(klarmeldingType)}
            validationSchema={isKlarmeldState ? crudKlarmeldingValidationSchema : undefined}
            onSubmit={async (values: KlarmeldingInputModel) => {
                await createKlarmelding(values)
                    .catch(err => { // catch is needed to handle unhandeled exception (eg. api offline). Formik onsubmithandler is catching all exception and writing them as warnings
                        dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: modalId}))
                        HandleError(err);
                    });
            }}>
            {
                (formik) => (
                    <Form>
                        {isKlarmeldState ?
                            <KlarmeldingCrudFields
                                modalId={modalId}
                                formik={formik}
                                setSubmitIsDisabled={setDisableSubmit}
                                klarmeldToDelkursusledere={klarmeldToDelkursusledere}>
                                {children}
                            </KlarmeldingCrudFields>
                            :
                            <div className="row">
                                <div className="col-sm-12 margin-bottom-m">
                                    {children}
                                </div>
                            </div>
                        }
                        <div className="modal-actions">
                            <ValidationFormSubmitButton disabled={disableSubmit}
                                                        formikProps={formik}
                                                        buttonType={isKlarmeldState ? ModalAcceptType.primary : ModalAcceptType.danger}>
                                {isKlarmeldState ? Localizer.global_klarmeld() : Localizer.global_removeKlarmeld()}
                            </ValidationFormSubmitButton>
                        </div>
                    </Form>
                )
            }
        </Formik>
    )
    return <>{render}</>
}
