import { UddannelseslaegeStatusEnum } from "../UddannelseslaegeStatusEnum";
import { ForloebModel } from "core/sharedmodels/forloeb/forloebModel";
import { LaegeWithForloebModel, LaegeWithSimpleForloebModel } from "core/sharedmodels/forloeb/laegeWithForloebModel";
import dayjs from "dayjs";
import { DayUnitType } from "index";
import {VejlederAfdelingerModel} from "../vejlederAfdelingerModel";
import {DropdownOption} from "../../../../core/components/dropdown/dropdown";

export const isWithinPeriod = (date: dayjs.Dayjs, start: dayjs.Dayjs, end: dayjs.Dayjs) : boolean => {
    return end.isSameOrAfter(date) && start.isSameOrBefore(date) && date.isBetween(end, start);
}

export const toDropDownOptionsSpecialer = (forloeb: LaegeWithSimpleForloebModel[]) : DropdownOption<string>[] => {
    return forloeb
        .flatMap(x => x.forloebModels)
        .filter((f, i, list) => list.findIndex(t => t.specialeId === f.specialeId) === i)
        .map((f) : DropdownOption<string> => ({
            label: f.specialeName,
            value: f.specialeId
        })
    )
}

export const toDropDownOptionsVejlederTilknytninger = (list: VejlederAfdelingerModel[]) : DropdownOption<string>[] => {
    return list.map((val) : DropdownOption<string> => ({
            label: val.GetStedInfo(),
            value: !!val.AfdelingId ? val.AfdelingId : val.LaegeInstitutionId,
        }
    ));
}

export const hasSpecialeNameFilter = (forloeb: ForloebModel, selectedSpeciale?: string) => selectedSpeciale && selectedSpeciale !== "" ? forloeb.specialeName.includes(selectedSpeciale) : true;

export const hasUddannelsesstedFilter = (forloeb: ForloebModel, selectedUddannelseSted?: string) => selectedUddannelseSted && selectedUddannelseSted !== "" ? forloeb.GetStedInfo().includes(selectedUddannelseSted) : true;

export const withStatusFilter = (forloebModel: ForloebModel, status: UddannelseslaegeStatusEnum[]) : boolean => {
    const arrLength = status.length;
    const hasAllPossibleEnums = arrLength === Object.keys(UddannelseslaegeStatusEnum).length;
    if(!arrLength || arrLength === 0 || hasAllPossibleEnums) return true;

    const today = dayjs().startOf(DayUnitType);
    const end = dayjs(forloebModel.endDate).startOf(DayUnitType);
    const start = dayjs(forloebModel.startDate).startOf(DayUnitType);

    const withFuture = status.includes(UddannelseslaegeStatusEnum.Fremtidige);
    const withFormer = status.includes(UddannelseslaegeStatusEnum.Tidligere);
    const withCurrent = status.includes(UddannelseslaegeStatusEnum.Nuvaerenede);

    const isFuture = withFuture ? start.isAfter(today) : false;
    const isFormer = withFormer ? end.isBefore(today) : false;
    const isCurrent = withCurrent ? isWithinPeriod(today, start, end) : false;
    return isFuture || isFormer || isCurrent;
}

export const SearchList = (list: LaegeWithForloebModel[], search: string) => {
    const searchLowerCase = search.toLowerCase();
    return list.filter(x => x.laegeNavn.toLowerCase().includes(searchLowerCase)
                        || x.forloebModels.find(y => y.specialeName.toLowerCase().includes(searchLowerCase)|| y.stillingName.toLowerCase().includes(searchLowerCase)))
}
