import {useRef, useState} from 'react'
import {LogbogNotatApi} from "../../../../services/api/logbogNotat/logbogNotatApi"
import {HttpClientService} from '../../../../services/httpClient/httpClientService';
import {ValidationForm} from '../../../components/validation/components/validationForm';
import {ValidationFormSubmitButton} from '../../../components/validation/components/validationFormButton';
import {Localizer} from '../../../../infrastructure/localization/localizer';
import {setSlideinState, SlideInStateEnum} from '../../../components/slideIn/slideInSlice';
import {HandleError} from '../../../infrastructure/errors/errorBoundary';
import {useAppDispatch, useAppSelector} from '../../../../app/hooks';
import {BasicNotatCrudFields} from "../basicNotatCrudFields";
import {toggleLaegekompetenceListRefreshPing} from '../../../../pages/kompetencerPage/cards/kompetenceCardSlice';
import {BasicNotatModel} from "../../../sharedmodels/notat/basicNotatModel";
import {NotatType} from "../../../sharedmodels/notat/notatType";
import {
    BuildExistingNotatCrudModel,
    crudNotatValidationSchema,
    NotatCrudModel
} from "../../../sharedmodels/notat/notatCrudModel";
import {forloebReducer, ForloebSliceState} from "../../../forloebSlice";
import {AccountService} from "../../../../services/account/accountService";

const notatApi = new LogbogNotatApi(new HttpClientService(), new AccountService())

export interface EditLaegekompetenceNotatProps {
    modalId: string;
    notatType: NotatType;
    laegekompetenceNotat: BasicNotatModel;
    laegekompetenceId: string;
    onFilesUpdatedCallback?: () => void;
}

export function EditLaegekompetenceNotat(props: EditLaegekompetenceNotatProps) {
    const dispatch = useAppDispatch();
    
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;
    const [editNotatFailed, setEditNotatFailed] = useState(false);

    const notat = useRef(props.laegekompetenceNotat);

    const editLaegekompetenceNotat = async (inputModel: NotatCrudModel) => {
        dispatch(setSlideinState({ state: SlideInStateEnum.ActionOngoing, slideInId: props.modalId }))
        const editedNotat = await notatApi.editNotatData(inputModel);

        if (editedNotat) {
            setEditNotatFailed(false);
            dispatch(setSlideinState({ state: SlideInStateEnum.Closing, slideInId: props.modalId }))
        }
    }

    const deleteLaegekompetenceNotat = async (inputModel: NotatCrudModel) => {
        dispatch(setSlideinState({ state: SlideInStateEnum.ActionOngoing, slideInId: props.modalId }))
        const deletedNotat = await notatApi.deleteNotat(inputModel);

        if (deletedNotat) {
            setEditNotatFailed(false);
            dispatch(setSlideinState({ state: SlideInStateEnum.Closing, slideInId: props.modalId }))
            dispatch(toggleLaegekompetenceListRefreshPing())
        }
    }

    const render = (
        <ValidationForm
            key={notat.current.notatReleationData.map(lk => lk.contextId).join()}
            initialValues={BuildExistingNotatCrudModel(props.laegekompetenceNotat, [props.laegekompetenceId])}
            validationSchema={crudNotatValidationSchema}
            onSubmit={async (values) => {
                await editLaegekompetenceNotat(values)
                    .catch(err => {
                        HandleError(err);
                        setEditNotatFailed(true);
                        dispatch(setSlideinState({ state: SlideInStateEnum.Opened, slideInId: props.modalId }));
                    });
            }}>
            {(formik) => (
                <>
                    <div className="row mb-2">
                        <div className="col-sm-12">

                            {notat.current.notatReleationData.length > 1 &&
                            <h6>{Localizer.global_tilknyttedeKompetencer()}</h6>
                            }
                            {notat.current.notatReleationData.length === 1 &&
                            <h6>{Localizer.global_tilknyttedeKompetence()}</h6>
                            }
                            <div>
                                {notat.current.notatReleationData.map((context, i) => (
                                    <div className={"badge badge-pill badge-primary"} key={context.contextId + i}>{context.contextTitle}</div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <BasicNotatCrudFields
                        modalId={props.modalId}
                        notatUserId={props.laegekompetenceNotat.brugerId}
                        formik={formik}
                        fileMetadatas={props.laegekompetenceNotat.fileMetadatas}
                        onFilesUpdatedCallback={props.onFilesUpdatedCallback}
                        notatInfo={{contextId: props.laegekompetenceId, notatType: props.notatType, userId: forloebSliceState.forloebState.brugerId}}
                        notatEditModel={props.laegekompetenceNotat}
                    />

                    <div className="modal-actions">
                        {editNotatFailed &&
                        <div className="form-group margin-bottom-m">
                            <div className="field-validation-error">{Localizer.notatPage_notatEditFailed()}</div>
                        </div>}

                        { props.notatType !== NotatType.Merit &&
                        <button className="btn btn-danger margin-right-s" onClick={() => deleteLaegekompetenceNotat(formik.values)}>
                            {Localizer.global_delete()}
                        </button>
                        }
                        <ValidationFormSubmitButton formikProps={formik}>
                            {Localizer.global_save()}
                        </ValidationFormSubmitButton>
                    </div>
                </>
            )}
        </ValidationForm>
    )

    return <>{render}</>
}
