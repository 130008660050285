import {FormikProps} from "formik";
import {CrudLaegekursusInputModel} from "./crudLaegekursusInputModel";
import {NotatType} from "../../../core/sharedmodels/notat/notatType";
import {BasicNotatModel} from "../../../core/sharedmodels/notat/basicNotatModel";
import {ReactFileUploaderFile} from "../../../core/components/fileUpload/reactFileUploader";
import {FileMetadata} from "../../../core/sharedmodels/fileMetaData/fileMetaData";
import React, {useRef, useState} from "react";
import {Loading} from "../../../core/components/loading/loading";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {ValidationInput} from "../../../core/components/validation/components/validationInput";
import {nameof} from "ts-simple-nameof";
import {ValidationDatepicker} from "../../../core/components/validation/components/validationDatePicker";
import {ValidationTextarea} from "../../../core/components/validation/components/validationTextarea";
import {FileMetadataList} from "../../../core/components/fileMetadata/fileMetadataList";
import {FileLocationEnum} from "../../../core/sharedmodels/file/fileLocationEnum";
import {LaegekursusGruppeTypeEnum} from "../../../core/sharedmodels/kursus/laegekursusGruppeTypeEnum";
import {ValidationSwitch} from "../../../core/components/validation/components/ValidationSwitch";
import {AccountService} from "../../../services/account/accountService";
import {STPSDokumentationUploadAllowedFileTypes} from "../../../core/components/fileUpload/allowedFileTypes";

interface KursusCrudFieldsProps {
    modalId: string
    formik: FormikProps<CrudLaegekursusInputModel>,
    notatInfo?: { contextId: string, notatType: NotatType, userId: string };
    notat?: BasicNotatModel | null,
    fileUploadedCallbackOverride?: (result: ReactFileUploaderFile[]) => void,
    fileDeletedCallbackOverride?: (result: FileMetadata) => void,
    filesUpdatedCallback?: () => void;
    titleIsReadOnly?: boolean,
    laegekursusGodkendt?: boolean
    laegekursusKanGodkendes?: boolean
    readonly?:boolean
}

export function LaegekursusCrudFields(props: KursusCrudFieldsProps) {
    const _currentUser = useRef(new AccountService().getUser());
    const [isLoading] = useState(false)

    return (
        <>
            <Loading isLoading={isLoading} text={Localizer.kursusPage_createKursus()}>
                <div className="row">
                    {props.formik.values.gruppeType !== LaegekursusGruppeTypeEnum.GrundkursusIPsykoterapi &&
                    <>
                        <div className="col-sm-12">
                            <ValidationInput
                                model={{
                                    label: Localizer.titel(),
                                    placeholder: Localizer.titelPlaceholder(),
                                    htmlName: nameof<CrudLaegekursusInputModel>(x => x.titel)
                                }}
                                inputId={nameof<CrudLaegekursusInputModel>(x => x.titel) + props.modalId}
                                formikProps={props.formik}
                                readOnly={props.titleIsReadOnly || props.readonly}
                            />
                        </div>
                        <div className="col-sm-12">
                            <ValidationDatepicker
                                model={{
                                    label: Localizer.startdato(),
                                    placeholder: Localizer.startdatoPlaceholder(),
                                    htmlName: nameof<CrudLaegekursusInputModel>(x => x.startDato)
                                }}
                                allowClear={true}
                                inputId={nameof<CrudLaegekursusInputModel>(x => x.startDato) + props.modalId}
                                formikProps={props.formik}
                                readOnly={props?.laegekursusGodkendt || props.readonly}
                            />
                        </div>
                        <div className="col-sm-12">
                            <ValidationDatepicker
                                model={{
                                    label: Localizer.slutdato(),
                                    placeholder: Localizer.slutdatoPlaceholder(),
                                    htmlName: nameof<CrudLaegekursusInputModel>(x => x.slutDato)
                                }}
                                allowClear={true}
                                inputId={nameof<CrudLaegekursusInputModel>(x => x.slutDato) + props.modalId}
                                formikProps={props.formik}
                                readOnly={props?.laegekursusGodkendt || props.readonly}
                            />
                        </div>
                        <div className="col-sm-12">
                            <ValidationTextarea
                                model={{
                                    label: Localizer.kursusPage_eventuelSpecifikationer(),
                                    placeholder: Localizer.eventuelSpecifikationerPlaceholder(),
                                    htmlName: nameof<CrudLaegekursusInputModel>(x => x.eventuelSpecifikation)
                                }}
                                inputId={nameof<CrudLaegekursusInputModel>(x => x.eventuelSpecifikation) + props.modalId}
                                formikProps={props.formik}
                                readOnly={props?.laegekursusGodkendt || props.readonly}
                            />
                        </div>
                    </>
                    }

                    <div className="col-sm-12">
                        <FileMetadataList fileMetadatas={props.notat?.fileMetadatas ?? []}
                                          filesUploadedCallbackOverride={props.fileUploadedCallbackOverride}
                                          fileDeletedCallbackOverride={props.fileDeletedCallbackOverride}
                                          filesUpdatedCallback={props.filesUpdatedCallback}
                                          notatInfo={props.notatInfo}
                                          overrideFileTypes={STPSDokumentationUploadAllowedFileTypes}
                                          notat={props.notat}
                                          showDeleteButton={!props?.laegekursusGodkendt && !props.readonly}
                                          withUploader={!props?.laegekursusGodkendt && !props.readonly}
                                          fileLocation={FileLocationEnum.Logbog}
                        />
                    </div>

                    {(props.laegekursusKanGodkendes || props?.laegekursusGodkendt) &&
                    <>
                        <div className="col-sm-12 margin-top-l">
                            <div className="alert alert-info" role="alert">
                                {Localizer.kursusPage_kommentarTilGodkendelse()}
                            </div>
                            <ValidationTextarea
                                model={{
                                    label: props?.laegekursusGodkendt ? Localizer.global_godkendtKommentar() : Localizer.global_addCommentGodkendOptional(),
                                    placeholder: (props.formik.values.godkendelseKommentar !== undefined && props?.laegekursusGodkendt) ? Localizer.global_noComment() : Localizer.global_addCommentGodkend(),
                                    htmlName: nameof<CrudLaegekursusInputModel>(x => x.godkendelseKommentar)
                                }}
                                inputId={nameof<CrudLaegekursusInputModel>(x => x.godkendelseKommentar) + props.modalId}
                                formikProps={props.formik}
                                readOnly={props?.laegekursusGodkendt || props.readonly}
                            />
                        </div>
                        {_currentUser.current.IsAdminOrVus() &&
                            <div className="col-sm-12">
                                <ValidationSwitch
                                    model={{
                                        label: props?.laegekursusGodkendt ? Localizer.kursusPage_godkendtPaaAndetGrundlag() : Localizer.kursusPage_godkendPaaAndetGrundlag(),
                                        placeholder: "",
                                        htmlName: nameof<CrudLaegekursusInputModel>(x => x.godkendtPaaAndetGrundlag)
                                    }}
                                    inputId={nameof<CrudLaegekursusInputModel>(x => x.godkendtPaaAndetGrundlag) + props.modalId}
                                    formikProps={props.formik}
                                    readOnly={props?.laegekursusGodkendt}
                                />
                            </div>
                        }
                    </>
                    }
                </div>
            </Loading>
        </>
    );
}
