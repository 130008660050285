import classNames from "classnames";
import { PropsWithChildren } from "react";

type PdfItemDefaultProps = {
    className?: string;
}

type NonBreakablePdfEntryWrapperProps = PdfItemDefaultProps &{
    canBreakInside?: boolean;
}

export const NonBreakablePdfItemWrapper = ({canBreakInside=false, className, ...props} : PropsWithChildren<NonBreakablePdfEntryWrapperProps>) => {
    
    return (
        <div className={classNames(!canBreakInside && "pdf-avoid-page-break-inside", className)}>
            {props.children}
        </div>
    );
}

export const BlockPdfItem = ({className, ...props} : PropsWithChildren<PdfItemDefaultProps>) => {
    
    return (
        <div className={`d-block ${className}`}>
            {props.children}
        </div>
    );
}