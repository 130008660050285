import {
    brugereMenuLink,
    evalueringsstatistikMenuLink,
    fileAdministrationMenuLink,
    forloebOverviewMenuLink,
    vejledereMenuLink
} from "core/layout/shared/MenuLinkProps";
import { DynamicMenu } from "../DynamicMenu";
import MenuProps from "./menuProps";

export function VusMenu({ showNotification, nyhederMenuLink }: MenuProps) {

    const menuLinks = [
        forloebOverviewMenuLink,
        vejledereMenuLink,
        evalueringsstatistikMenuLink,
        brugereMenuLink,
        nyhederMenuLink,
        fileAdministrationMenuLink
    ];

    return (
        <DynamicMenu menuLinks={menuLinks} showNotifications={showNotification}/>
    );

}
