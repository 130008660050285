import {RootTypeEnum} from "../../../core/sharedmodels/laegeInstitution/rootTypeEnum";

export class VejlederEditResponseModel {
    public brugerId : string;
    public vejlederTilknytningId : string;
    public sygehusId : string;
    public afdelingId : string;
    public praksisId : string;
    public UddannelseId : string;
    public navnAndEmail : string;
    public laegeinstitutionsType : RootTypeEnum;
    public laegerIds : Array<string>;
    public alleLaeger : boolean;
    public startDatoUtc : string;
    public slutDatoUtc : string;
    public alleKbuLaeger : boolean;
    public alleIntroduktionsLaeger : boolean;
    public alleHoveduddannelsesLaeger : boolean;
}
