import React from "react";
import {TopMenu} from "../../layout/topMenu/topMenu";
import {NotificationModule} from "ditmer-embla";
import {HttpResponseError, HttpUnauthorizedError} from "../../../services/httpClient/httpClientService";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {AccountService} from "../../../services/account/accountService";

import BodyContainer from "core/layout/bodyContainer";
import { captureSentryException } from "./sentry/sentryUtils";

const showErrorToast = (error?: string) => {
    const errorMessage = error ? error : Localizer.global_internalServerError();
    NotificationModule.showError(Localizer.global_error(), errorMessage);
};

const showWarningToast = (warning?: string) => {
    const warningMessage = warning ? warning : Localizer.global_internalServerError();
    NotificationModule.showWarning(Localizer.global_error(), warningMessage);
}

// Async error handler
window.addEventListener('unhandledrejection', async (event) => {
    await HandleError(event);
});

export const HandleError = async (event : any) => {
    if (event instanceof HttpUnauthorizedError){
        const userService = new AccountService();
        await userService.Logout();
    }
    if (event.reason instanceof HttpUnauthorizedError){
        const userService = new AccountService();
        await userService.Logout();
    }
    if (event.reason?.message == "invalid_grant"){
        const userService = new AccountService();
        await userService.Logout();
    }
    else if (event instanceof HttpResponseError || event.reason instanceof HttpResponseError){
        const responseError: HttpResponseError = event instanceof HttpResponseError ? event : event.reason;

        if (responseError.status === 404) {
            // status "Not found":
            showWarningToast(responseError.message);
        } else {
            showErrorToast(responseError.message);
        }
    }
    else {
        showErrorToast();
    }

    console.error(event.reason);

    //TODO: Det her virker ikke helt optimalt... Den logger ikke altid og nogle gange to entries for some reason... Se i App.tsx...
    //TODO: Add some additional context to the captures?
    if (event instanceof PromiseRejectionEvent) {
        captureSentryException(event.reason, "generic-undhandled-rejection-error");
    } else {
        captureSentryException(event.reason, "generic-error");
    }
    
}

export class ErrorBoundary extends React.Component<any, { hasError: boolean, errorEventId?: string }> {
    constructor(props : any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error : Error) {
        if (error instanceof HttpUnauthorizedError){
            const userService = new AccountService();
            userService.Logout();
        }

        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.error(error);
        const sentryEventId = captureSentryException(error, "generic-did-catch-error");

        this.setState((prev) => ({ hasError: true, errorEventId: sentryEventId }))
    }

    render() {
        if (this.state.hasError) {
            return (
                <BodyContainer hideCookieBanner >
                    <TopMenu/>

                    <div className="page-content">
                        <div className="main-content">
                            <div className="body-content body-content-error">
                                <h1>
                                    {Localizer.errorpage_errorOccured()}
                                </h1>
                                {this.state.errorEventId &&
                                    <div className="mt-3">
                                        <b>Fejlkode:</b>
                                        <span className="ml-1">{this.state.errorEventId}</span>
                                    </div>
                                }
                                <button 
                                    className={"btn btn-default mt-3"} 
                                    onClick={() => window.location.replace("/")}
                                >
                                    {Localizer.errorpage_goToFrontPage()}
                                </button>
                                
                            </div>
                        </div>
                    </div>
                </BodyContainer>
            );
        }

        return this.props.children;
    }
}
