import {Localizer} from "../../../infrastructure/localization/localizer";
import {useEffect, useState} from "react";
import {useRouteMatch} from "react-router-dom";
import PdfComponent from "core/components/pdf/pdfComponent";
import {useFetchWithErrorHandlingPdf} from "./useFetchWithErrorHandlingPdf";
import useLogbogApi from "../../hooks/useLogbogApi";
import PdfRow from "./pdfRow";
import PdfHeaderRow from "./pdfHeaderRow";
import { setDocumentPdfAsReady } from "./helpers/pdfHelper";
import UserNameInfoSimpleModel from "../userProfile/userNameInfoSimpleModel";
import NotatSimpleResponseModel from "core/sharedmodels/notat/notatSimpleResponseModel";
import { PdfKompetenceHeaderComponent } from "./pdfHeaders/pdfKompetenceHeaderComponent";
import NoteTextViewer from "core/componentsPage/notat/tekstNotat/noteTextViewer";
import { NotatType } from "core/sharedmodels/notat/notatType";

type UddannelseslaegeNotesPdfParams = { brugerId: string };

type NotePdfModel = {
    title: string;
    description: string;
    visibility: string;
    type: NotatType;
}

type NotePdfContainer = {
    forloebId: string;
    brugerMaalbeskrivelseId: string;
    notes: NotePdfModel[];
}

const shouldFetchUserNames = (note: NotatSimpleResponseModel) => !(note.isPrivate || note.visibleToEveryone) && note.visibleForUserIds.length > 0;

const DetermineVisibility = (note: NotatSimpleResponseModel, userInfos: UserNameInfoSimpleModel[]) => {
    if(note.isPrivate) return Localizer.global_private();
    if(note.visibleToEveryone) return Localizer.global_visibileForAll();
    
    const idSet = new Set<string>(note.visibleForUserIds);
    return userInfos.filter(x => idSet.has(x.userId)).map(x => x.name).join(", ");
} 

const AllUddannelseslaegeNotesPdf = () => {

    const { notatApi, logbogUserInfoApi } = useLogbogApi();
    const { brugerId } = useRouteMatch<UddannelseslaegeNotesPdfParams>().params;
    
    const [noteLists, setNoteLists] = useState<NotePdfContainer[]>([]);
    
    const setError = useFetchWithErrorHandlingPdf();

    useEffect( () => {

        const fetchNotes = async () => {
            const containers = await notatApi.getNotesForPdf(brugerId);
            const userIdsToFetchNamesFor = containers.flatMap(x => x.notats).filter(x => shouldFetchUserNames(x)).flatMap(x => x.visibleForUserIds);
            const userInfos = await logbogUserInfoApi.getUserNamesInfo(Array.from(new Set<string>(userIdsToFetchNamesFor)));
            
            return containers.map(c => ({
                forloebId: c.forloebId,
                brugerMaalbeskrivelseId: c.brugerMaalbeskrivelseId,
                notes: c.notats.map(n => ({
                    title: n.title,
                    description: n.description,
                    visibility: DetermineVisibility(n, userInfos),
                    type: n.type
                }))
            }));
        };

        fetchNotes()
            .then(data => setNoteLists(data))
            .catch(err => setError(err))
            .finally(() => setDocumentPdfAsReady());

    }, [brugerId, logbogUserInfoApi, notatApi, setError]);

    return (
        <PdfComponent>

            {noteLists.map(container => (
                <PdfComponent.PageBreakAfter>
                    <PdfKompetenceHeaderComponent
                        brugerId={brugerId}
                        brugerMaalbeskrivelseId={container.brugerMaalbeskrivelseId}
                        disableUserInfo
                    />
        
                    <PdfComponent.BlockItem className={"card shadow-none"}>
                        <PdfComponent.CardHeader>
                            <PdfHeaderRow asFragment>
                                <PdfHeaderRow.Col>{Localizer.global_title()}</PdfHeaderRow.Col>
                                <PdfHeaderRow.Col width={6}>{Localizer.text()}</PdfHeaderRow.Col>
                                <PdfHeaderRow.Col width={4}>{Localizer.global_visibility()}</PdfHeaderRow.Col>
                            </PdfHeaderRow>
                        </PdfComponent.CardHeader>
                        {container.notes &&
                            container.notes.map((n, i) =>
                                <PdfComponent.NonBreakableItem>
                                    <PdfComponent.CardBody>
                                        <PdfRow className="flex-align-c">
                                            <PdfRow.Col>{n.title}</PdfRow.Col>
                                            <PdfRow.Col width={6}>
                                                <NoteTextViewer 
                                                    id={container.forloebId + i}
                                                    type={n.type}
                                                    text={n.description}
                                                />
                                            </PdfRow.Col>
                                            <PdfRow.Col width={4}>{n.visibility}</PdfRow.Col>
                                        </PdfRow>
                                    </PdfComponent.CardBody>
                                </PdfComponent.NonBreakableItem>
                            )
                        }
                    </PdfComponent.BlockItem>
                </PdfComponent.PageBreakAfter>

            ))}
            
        </PdfComponent>
    );
}

export default AllUddannelseslaegeNotesPdf;