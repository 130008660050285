import {FormikProps} from "formik";
import {CrudNyhedInputModel} from "./crudNyhedInputModel";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {nameof} from "ts-simple-nameof";
import {ValidationInput} from "../validation/components/validationInput";
import {ValidationTextEditor} from "../validation/components/validationTextEditor";
import {ValidationDatepicker} from "../validation/components/validationDatePicker";
import {NyhedModel} from "../../sharedmodels/nyheder/nyhedModel";
import { ValidationDropdown } from "../validation/components/validationDropdown";
import useEnumDropdownOptions from "../dropdown/hooks/useEnumDropdownOptions";
import { RolleEnum, UserRoleToString } from "infrastructure/enums/userRole";
import { LocalizerPageSpecifics } from "infrastructure/localization/localizerPageSpecifics";
import { ValidationSwitch } from "../validation/components/ValidationSwitch";

type NyhedInputFieldsProps = {
    modalId: string,
    formik: FormikProps<CrudNyhedInputModel>,
    model?: NyhedModel;
}

export const NyhedInputFields = (props: NyhedInputFieldsProps) => {
    const [ rolleDropdownOptions ] = useEnumDropdownOptions<RolleEnum>(RolleEnum, UserRoleToString);

    return (
        <>
            <div className="row">
                <div className="col-sm-12">
                    <ValidationDatepicker
                        model={{
                            label: LocalizerPageSpecifics.nyhederPage_publiceringsdato(),
                            placeholder: "",
                            htmlName: nameof<CrudNyhedInputModel>(x => x.publiceringsdato),
                        }}
                        inputId={nameof<CrudNyhedInputModel>(x => x.publiceringsdato) + props.modalId}
                        formikProps={props.formik}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <ValidationSwitch
                        model={{
                            label: LocalizerPageSpecifics.nyhederPage_notificerRoller(),
                            placeholder: "",
                            htmlName: nameof<CrudNyhedInputModel>(x => x.skalNotificere)
                        }}
                        inputId={nameof<CrudNyhedInputModel>(x => x.skalNotificere) + props.modalId}
                        labelInfoTooltipText={LocalizerPageSpecifics.nyhederPage_notificerRollerInfo()}
                        noMarginBottom={props.formik.values.skalNotificere}
                        formikProps={props.formik}
                    />
                </div>
            </div>
            {props.formik.values.skalNotificere &&
                <div className="row">
                    <div className="col-sm-12">
                        <ValidationDropdown
                            model={{
                                label: "",
                                placeholder: Localizer.global_all(),
                                htmlName: nameof<CrudNyhedInputModel>(x => x.maalrettetTilRoller)
                            }}
                            options={rolleDropdownOptions.sortBy(x => x.label)}
                            formikProps={props.formik}
                            isMulti
                        />
                    </div>
                </div>
            }
            <div className="row">
                <div className="col-sm-12">
                    <ValidationInput
                        model={{
                            label: Localizer.titel(),
                            placeholder: "",
                            htmlName: nameof<CrudNyhedInputModel>(x => x.titel)
                        }}
                        inputId={nameof<CrudNyhedInputModel>(x => x.titel) + props.modalId}
                        formikProps={props.formik}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <ValidationTextEditor
                        model={{
                            label: Localizer.createMail_Message(),
                            placeholder: Localizer.createMail_Message(),
                            htmlName: nameof<CrudNyhedInputModel>(x => x.markdown)
                        }}
                        inputId={nameof<CrudNyhedInputModel>(x => x.markdown) + props.modalId}
                        formikProps={props.formik}
                        minHeightPx={400}
                        useConstantHeight
                    />
                </div>
            </div>
        </>
    );
}
