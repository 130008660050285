type ModalMessageProps = {
    title?: string;
    description?: string,
    primaryButtonText: string,
    modalId: string;
    secondaryButtonText?: string;
    secondaryButtonAction?: () => void;
    addBorderTop?: boolean;
}

const ModalMessage = ({title="", description, primaryButtonText, modalId, secondaryButtonText, secondaryButtonAction, addBorderTop=false}: ModalMessageProps) => {
    return (
        <>
            <div className="modal modal-message" id={modalId} role="dialog"
                aria-labelledby="mySmallModalLabel">
                <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <h3>
                                {title}
                            </h3>
                            <p>
                                {description}
                            </p>
                        </div>
                        <div className={`modal-footer ${description ? "border-top" : ""}`}>
                            {secondaryButtonText && secondaryButtonAction &&
                                <button type="button"
                                        onClick={secondaryButtonAction}
                                        className="btn btn-default"
                                        data-dismiss="modal">
                                    {secondaryButtonText}
                                </button>
                            }

                            <button type="button" className="btn btn-primary" data-dismiss="modal">{primaryButtonText}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const showModalMessage = (modalId: string) => { $("#" + modalId).modal("show") };

export {
    ModalMessage,
    showModalMessage
}