import { PropsWithChildren } from "react";
import useApplicationInsightsConfig from "./useApplicationInsightsConfig";
import config from "config/config";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useCookieConsentContext } from "core/components/cookies/context/CookieConsentContextProvider";

type AppInsightsProviderWrapperProps = {};

const AppInsightsProviderWrapper = ({ children }: PropsWithChildren<AppInsightsProviderWrapperProps>) => {
  const { cookieConsent } = useCookieConsentContext();

  const configUseAppInsights = config.useAppInsights ?? false;
  const enableAppInsights = configUseAppInsights && cookieConsent.userConsents;

  const configAppInsightsConnectionString = config.applicationInsightConnectionstring;

  const { reactPlugin } = useApplicationInsightsConfig(enableAppInsights, configAppInsightsConnectionString);
  
  if (!enableAppInsights) {
    return <>{children}</>;
  }

  if(!!configAppInsightsConnectionString) {
    console.info("Using application insights")
  } else {
    console.info("Using application insights (without connectionString)")
  }
  
  return (
      <AppInsightsContext.Provider value={reactPlugin}>
          {children}
      </AppInsightsContext.Provider>
  );
}

export default AppInsightsProviderWrapper;