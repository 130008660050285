import {EvalueringStatistikModel} from "../../services/api/evalueringStatistik/evalueringStatistikReponseModel";
import {UddannelsesstedModel} from "../../core/sharedmodels/laegeInstitution/uddannelsesstedModel";
import {RootTypeEnum} from "../../core/sharedmodels/laegeInstitution/rootTypeEnum";

export class DatasetModel {
    constructor(uddannelsesstederEvalueringstatistik: EvalueringStatistikModel[],
                uddannelsessteder: UddannelsesstedModel[],
                datasetColor: string,
                maxAmountEvalueringer?: number,
                title?: string,
                fromDate?: string,
                toDate?: string,
                uddannelsesspecialer?: string[],
                stillingstyper?: string[],
                stillingsTypeName?: string[],
                specialeIds?: string[],
                avgScoresSingle?: number[],
                avgScoresGroup?: number[],
                includeInactive?: boolean,
                isDefaultDatasetForUser: boolean = false,
                regioner?: string[],
                sygehuse?: string[],
                laegeinstitutionName?: RootTypeEnum,
                isStandardVisning=false,
                antalForloebEvalueretTotal?: number,
    ) {
        this.uddannelsesstederEvalueringstatistik = uddannelsesstederEvalueringstatistik;
        this.uddannelsessteder = uddannelsessteder;
        this.datasetColor = datasetColor;
        this.maxAmountEvalueringer = maxAmountEvalueringer;
        this.title = title;
        this.fromDate = fromDate;
        this.toDate = toDate;
        this.stillingstyper = stillingstyper;
        this.stillingsTypeName = stillingsTypeName;
        this.uddannelsesspecialer = uddannelsesspecialer;
        this.specialeIds = specialeIds;
        this.avgScoresSingle = avgScoresSingle;
        this.avgScoresGroup = avgScoresGroup;
        this.includeInactive = includeInactive;
        this.isDefaultDatasetForUser = isDefaultDatasetForUser;
        this.regioner = regioner;
        this.sygehuse = sygehuse;
        this.laegeinstitutionName = laegeinstitutionName;
        this.isStandardVisning = isStandardVisning;
        this.antalForloebEvalueretTotal = antalForloebEvalueretTotal;
    }

    uddannelsesstederEvalueringstatistik: EvalueringStatistikModel[];
    uddannelsessteder: UddannelsesstedModel[];
    datasetColor: string;
    maxAmountEvalueringer?: number;
    title?: string;
    fromDate?: string;
    toDate?: string;
    stillingstyper?: string[];
    stillingsTypeName?: string[];
    uddannelsesspecialer?: string[];
    specialeIds?: string[];
    avgScoresSingle?: number[];
    avgScoresGroup?: number[];
    includeInactive?: boolean;
    isDefaultDatasetForUser?: boolean;
    regioner?: string[];
    sygehuse?: string[];
    laegeinstitutionName?: RootTypeEnum;
    isStandardVisning?: boolean;
    antalForloebEvalueretTotal?: number;
}
