import classNames from "classnames";
import { HTMLAttributes, PropsWithChildren } from "react";
import Col, { ColProps, MaxColWidth } from "./Col";

const Row = ({className, alignTop=true, ...props}: PropsWithChildren<HTMLAttributes<HTMLDivElement> & { className?: string, alignTop?: boolean}>) => {
    return (
        <div 
            className={classNames("flex-container-row", alignTop && "align-items-start", className)} 
            {...props}
        >
            {props.children}
        </div>
    );
}

export default Object.assign(
    Row, {
        Col: ({width=MaxColWidth, children, ...props}: ColProps) => <Col width={width} {...props}>{children}</Col>
    }
);