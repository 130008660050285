import { EmblaIcons } from "core/emblaIcons";
import { EmblaIcon } from "../emblaIcon/emblaIcon";
import { ModalMessage, showModalMessage } from "./modalMessage";
import { Localizer } from "infrastructure/localization/localizer";

type InfoModalProps = {
    id: string;
    text: string;
}

const InfoModal = ({id="", text=""} : InfoModalProps) => {

    return (
        <>
            <div className={"subtle padding-left-xs tooltip-center clickable"} onClick={() => showModalMessage(id)}>
                <EmblaIcon iconName={EmblaIcons.Info}/>
            </div>
            <ModalMessage
                description={text}
                primaryButtonText={Localizer.global_luk()}
                modalId={id}
            />
        </>
    );
}

export default InfoModal;