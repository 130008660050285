import { DropdownOption } from "core/components/dropdown/dropdown";

const NumberRangeDropdownOptions = (number: number) => {
    let numberOptions: number[] = [];

    for (let i = 1; i <= number; i++)
        numberOptions.push(i)

    numberOptions.sort((a, b) => +a > +b ? 1 : -1)

    return numberOptions.map(x => (
        {
            label: x.toString(),
            value: x
        } as DropdownOption<number>
    ));
}

const getNumberArrayRangeToDropdownOptions = (numbers: number[]) => {
    numbers.sort((a, b) => +a > +b ? 1 : -1)

    return numbers.map(x => (
        {
            label: x.toString(),
            value: x
        } as DropdownOption<number>
    ));
}

export default NumberRangeDropdownOptions;

export {
    getNumberArrayRangeToDropdownOptions
}