import DynamicButton from "core/components/button/dynamicButton";
import { DynamicValidationFormSubmitButton } from "core/components/validation/components/validationFormButton";
import { VejlederEditFormId } from "./vejlederEdit";
import { EmblaIcons } from "core/emblaIcons";
import { Localizer } from "infrastructure/localization/localizer";
import { SlideInStateEnum, setSlideinState } from "core/components/slideIn/slideInSlice";
import { useAppDispatch } from "app/hooks";
import { NotificationModule } from "ditmer-embla";
import { AccountService } from "services/account/accountService";
import { FileDownloadHandler } from "services/api/logbogFile/fileDownloadHandler";
import { StamdataVejlederApi } from "services/api/stamdataVejlederApi/stamdataVejlederApi";
import { HttpClientService } from "services/httpClient/httpClientService";
import { ConfirmButton } from "core/components/button/confirmButton";

type VejlederEditFooterProps = {
    modalId: string;
    vejlederTilknytningId: string;
    disabled?: boolean;
}

const stamdataVejlederApi = new StamdataVejlederApi(new HttpClientService(), new AccountService(), new FileDownloadHandler());

const VejlederEditFooter = ({modalId, vejlederTilknytningId, disabled=false} : VejlederEditFooterProps) => {
    const dispatch = useAppDispatch();
    
    const deleteVejleder = async () => {
        dispatch(setSlideinState({state: SlideInStateEnum.ActionOngoing, slideInId: modalId}));
        await stamdataVejlederApi.removeVejleder(vejlederTilknytningId);
        NotificationModule.showSuccess(Localizer.vejlederpage_vejlederRemoved(), "")
        dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: modalId}));
    }
    
    return (
        <div className={'d-flex'}>
            <ConfirmButton
                icon={EmblaIcons.Delete}
                buttonTekst={Localizer.vejlederpage_removeVejleder()}
                additionalClasses='mr-2'
                confirmCallback={() => deleteVejleder()}
                disabled={disabled}
                useDynamicButton={true}
            />

            <DynamicValidationFormSubmitButton
                formId={VejlederEditFormId}
                disabled={disabled}
                iconName={EmblaIcons.Success}
                text={Localizer.vejlederPage_saveVejleder()}
            />
        </div>
    );
}

export default Object.assign(VejlederEditFooter);