export enum BrugerIndstillingTypeKeyEnum {
  // Page: Forloebsoverblik
  PageForloebsoverblikPageSize = 1,
  PageForloebsoverblikViewOption = 2,
  PageForloebsoverblikUseAdvancedSearch = 3,
  PageForloebsoverblikUseUdvidOverlap = 4,
  PageForloebsoverblikUseHorizontalLines = 5,
  PageForloebsoverblikUseUbesatteStillinger = 6,
  // Page: Uddannelseslaeger
  PageUddannelseslaegerFilterForloebStatus = 7,
}

export const castBrugerIndstillingValueToCorrectType = (typeKey: BrugerIndstillingTypeKeyEnum, settingValue: string) => {
  switch (typeKey)
  {
      case BrugerIndstillingTypeKeyEnum.PageForloebsoverblikPageSize:
      case BrugerIndstillingTypeKeyEnum.PageForloebsoverblikViewOption:
        const settingAsNumber = Number(settingValue);
        return !isNaN(settingAsNumber) ? settingAsNumber : undefined;
      case BrugerIndstillingTypeKeyEnum.PageForloebsoverblikUseAdvancedSearch:
      case BrugerIndstillingTypeKeyEnum.PageForloebsoverblikUseUdvidOverlap:
      case BrugerIndstillingTypeKeyEnum.PageForloebsoverblikUseHorizontalLines:
      case BrugerIndstillingTypeKeyEnum.PageForloebsoverblikUseUbesatteStillinger:
        return settingValue.toLowerCase() === "true" ? true : settingValue.toLowerCase() === "false" ? false : undefined;
      case BrugerIndstillingTypeKeyEnum.PageUddannelseslaegerFilterForloebStatus:
        const settingParsed = JSON.parse(settingValue);
        return Array.isArray(settingParsed) ? settingParsed as number[] : undefined;
      default:
          return settingValue;
  }
}
