import React, {useEffect, useState} from "react";
import {Localizer} from "../../infrastructure/localization/localizer";
import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../../core/layout/responsive";
import {RoutePaths} from "../../infrastructure/routes";
import {useHistory} from "react-router-dom";
import {EvalueringsstatistikPageConstants} from "./components/EvalueringsstatistikPageConstants";
import {AccountService} from "../../services/account/accountService";
import {ReactComponent as CreditCardTimeout} from "../../content/icons/credit-card-timeout.svg";
import {
    clearDataset,
    evalueringsstatistikReducer,
    EvalueringsstatistikState,
    setShouldFetchDefaultVisning, setShowDefaultDatasaet, setTabState
} from "./evalueringsstatistikSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {CardButtonWithIcon} from "../../core/components/button/cardButtonWithIcon";
import {toDate} from "../../index";
import {EvalueringsstatistikTabEnum} from "./evalueringsstatistikTabEnum";

export function Evalueringsstatistik() {

    const IsMobile = useMediaQuery(mobileMaxWidthQuery);
    const history = useHistory();
    const userService = new AccountService();
    const user = userService.getUser();
    const dispatch = useAppDispatch();
    const evalueringsstatistikSliceState = useAppSelector(evalueringsstatistikReducer) as EvalueringsstatistikState;

    const [minutterSidenAendret, setMinutterSidenAendret] = useState<number | undefined>();

    useEffect(() => {
        dispatch(setShowDefaultDatasaet(EvalueringsstatistikPageConstants.getDefaultvisning));
        if (evalueringsstatistikSliceState.lastChangedAt !== undefined)
            setMinutterSidenAendret(Math.abs(new Date().getMinutes() - (toDate(evalueringsstatistikSliceState.lastChangedAt).getMinutes())));
    }, [dispatch, evalueringsstatistikSliceState.lastChangedAt])

    const goToCreateStatisticWithDefaultView = () => {
        dispatch(clearDataset());
        dispatch(setShouldFetchDefaultVisning(true));
        dispatch(setTabState(EvalueringsstatistikTabEnum.Uddannelsessteder.toString()));
        history.push(RoutePaths.CreateEvalueringsstatistik.url);
    }

    const goToCreateStatisticWithDefaultEnkelteEvalueringerView = () => {
        dispatch(clearDataset());
        dispatch(setShouldFetchDefaultVisning(true));
        dispatch(setTabState(EvalueringsstatistikTabEnum.Evalueringer.toString()));
        history.push(RoutePaths.CreateEvalueringsstatistik.url);
    }

    const createCleanStatistikUdtraek = () => {
        dispatch(clearDataset());
        dispatch(setShouldFetchDefaultVisning(false));
        history.push(RoutePaths.CreateEvalueringsstatistik.url);
    }

    const goToCreateLatestDatasets = () => {
        dispatch(setShouldFetchDefaultVisning(false));
        history.push(RoutePaths.CreateEvalueringsstatistik.url);
    }

    return (
        <div>
            <div className="card">
                <div className="card-body wrap d-flex">
                    <h3>{Localizer.evalueringsstatistikPage_header()}</h3>
                </div>
                <div className="card-body">
                    <p>{Localizer.evalueringsstatistikPage_information()}</p>
                    <h4 className="margin-top-l">{Localizer.evalueringsstatistikPage_questions()}</h4>
                    <p>{Localizer.evalueringsstatistikPage_questionsInformation()}</p>
                </div>
                {user.Authenticated && EvalueringsstatistikPageConstants.getComplexView
                    ?
                    <div className="col-sm-12 ">
                        <div className=" alert alert-info" role={"alert"}>
                            {Localizer.evalueringPage_bagLoginInfoTekst()}
                        </div>
                    </div>
                    :
                    <div className="col-sm-12 ">
                        <div className=" alert alert-info" role={"alert"}>
                            {Localizer.evalueringPage_offentligInfoTekst()}
                        </div>
                    </div>
                }
                <div className="card-body">
                    <div className={`flex-column ${IsMobile ? "margin-m" : ""}`}>
                        <div className={`d-flex wrap`}>
                            {evalueringsstatistikSliceState.showDefaultDatasaet &&
                                <CardButtonWithIcon icon={"list"}
                                                    confirmCallback={() => goToCreateStatisticWithDefaultEnkelteEvalueringerView()}
                                                    additionalClasses={`shadow-none ${IsMobile ? "" : "margin-right-m"}`}
                                                    buttonBottomText={Localizer.evalueringsstatistikPage_mineEvalueringer()}
                                                    buttonBottomInfoText={Localizer.evalueringsstatistikPage_mineEvalueringerInfo() + ((evalueringsstatistikSliceState.dataset?.length > 0) ? " " + Localizer.evalueringsstatistikPage_startNytUdtraekInfo() : "")}
                                />
                            }
                            {evalueringsstatistikSliceState.showDefaultDatasaet &&
                                <CardButtonWithIcon icon={"graph"}
                                                    confirmCallback={() => goToCreateStatisticWithDefaultView()}
                                                    additionalClasses={`shadow-none ${IsMobile ? "" : "margin-right-m"}`}
                                                    buttonBottomText={Localizer.evalueringsstatistikPage_mineUddannelsessteder()}
                                                    buttonBottomInfoText={Localizer.evalueringsstatistikPage_mineUddannelsesstederInfo() + ((evalueringsstatistikSliceState.dataset?.length > 0) ? " " + Localizer.evalueringsstatistikPage_startNytUdtraekInfo() : "")}
                                />
                            }
                            {evalueringsstatistikSliceState.dataset.length > 0 &&
                                <CardButtonWithIcon icon={<CreditCardTimeout/>}
                                                    confirmCallback={() => goToCreateLatestDatasets()}
                                                    additionalClasses={`shadow-none ${IsMobile ? "" : "margin-right-m"}`}
                                                    buttonBottomText={Localizer.evalueringsstatistikPage_arbejdVidere()}
                                                    buttonBottomInfoText={`${Localizer.evalueringsstatistikPage_arbejdVidereInfo()}
                                                    ${minutterSidenAendret !== undefined
                                                        ? Localizer.evalueringsstatistikPage_sidstAendret() + " "
                                                        + minutterSidenAendret + " " + ((minutterSidenAendret === 1) ? Localizer.evalueringsstatistikPage_minutSiden() : Localizer.evalueringsstatistikPage_minutterSiden())
                                                        : ""}`}/>
                            }
                            <CardButtonWithIcon icon={"plus"}
                                                confirmCallback={() => createCleanStatistikUdtraek()}
                                                isCreate={true}
                                                additionalClasses={"shadow-none"}
                                                buttonBottomText={Localizer.evalueringsstatistikPage_startNytUdtraek()}
                                                buttonBottomInfoText={Localizer.evalueringsstatistikPage_startNytUdtraekInfo()}/>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

