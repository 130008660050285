export enum RegionEnum {
    Nord = 1,
    Midt = 2,
    Syd = 3,
    Sjaelland = 4,
    Hovedstaden = 5,
}

export enum VidereuddannlesesregionEnum {
    Nord = 1,
    Syd = 2,
    Oest = 3
}
