import { EmblaIcon } from "core/components/emblaIcon/emblaIcon";
import Tooltip from "core/components/tooltips/Tooltip";
import { EmblaIcons } from "core/emblaIcons";
import { LaegekursusGruppeTypeEnum } from "core/sharedmodels/kursus/laegekursusGruppeTypeEnum";
import { Localizer } from "infrastructure/localization/localizer";

const LaegekursusGruppeTypesToShow = [
    LaegekursusGruppeTypeEnum.Specialespecifikke,
    LaegekursusGruppeTypeEnum.Forskningstraening,
    LaegekursusGruppeTypeEnum.Hoveduddannelse,
    LaegekursusGruppeTypeEnum.Introduktionsuddannelsen,
    LaegekursusGruppeTypeEnum.KliniskBasisuddannelse
];

const getTitel = (kursusGruppeType: LaegekursusGruppeTypeEnum) => {
    switch (kursusGruppeType) {
        case LaegekursusGruppeTypeEnum.Specialespecifikke:
            return Localizer.kursusPage_laegekursusSamletGodkendelseHovedkursusleder();

        case LaegekursusGruppeTypeEnum.Forskningstraening:
            return Localizer.kursusPage_SamletGodkendelseIsGivenBy();

        case LaegekursusGruppeTypeEnum.Hoveduddannelse:
        case LaegekursusGruppeTypeEnum.Introduktionsuddannelsen:
        case LaegekursusGruppeTypeEnum.KliniskBasisuddannelse:
            return Localizer.kursusPage_CourseIsApprovedBy();
        
        default:
            return "";
    }
}

const LaegekursusGruppeIcon = ({kursusGruppeType} : {kursusGruppeType: LaegekursusGruppeTypeEnum}) => {

    const show = LaegekursusGruppeTypesToShow.includes(kursusGruppeType);

    return (
        <>
            {show && 
                <Tooltip title={getTitel(kursusGruppeType)}>
                    <EmblaIcon
                        iconName={EmblaIcons.Info}
                        wrapInSpan={false}
                        additionalClasses={"subtle margin-left-s"}
                    />
                </Tooltip>
            }
        </>

    );
}

export default LaegekursusGruppeIcon;
