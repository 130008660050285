import { AccountService } from "services/account/accountService";
import HubConnectionFactory, { HubConnectionType } from "../hubConnectionFactory";
import { useMemo } from "react";

const accountService = new AccountService();

const useHubConnection = (connectionType: HubConnectionType) => {
  
  const connection = useMemo(() => HubConnectionFactory.createHub(connectionType, accountService), [connectionType]);

  return connection;
};

export default useHubConnection;