import {MutableRefObject, useEffect, useRef} from "react";
import Viewer from '@toast-ui/editor/dist/toastui-editor-viewer';

const BrevMarkdownPreviewer = ({id, markdown}: { id : string, markdown: string }) => {
    const viewer = useRef<toastui.Viewer>(null);

    useEffect(() => {
        (viewer as MutableRefObject<toastui.Viewer>).current = new Viewer({ el: $(`#${id}`)[0] });
    }, [id]);

    useEffect(() => {
        if(!viewer.current) return

        viewer.current.setMarkdown(markdown);
    }, [markdown]);

    return (
        <div className="form-group margin-bottom-m">
            <div id={id} className="default-rich-text-editor" />
        </div>
    );
}

export default BrevMarkdownPreviewer;
