import {HttpClientService} from '../../../services/httpClient/httpClientService';
import {FileMetadata} from '../../sharedmodels/fileMetaData/fileMetaData';
import {EmblaIcon} from '../emblaIcon/emblaIcon';
import {LogbogFileApi} from '../../../services/api/logbogFile/logbogFileApi';
import {FileDownloadHandler} from 'services/api/logbogFile/fileDownloadHandler';
import {FileLocationEnum} from "../../sharedmodels/file/fileLocationEnum";
import {StamdataEmailApi} from "../../../services/api/stamdataEmail/stamdataEmailApi";
import {AccountService} from "../../../services/account/accountService";
import {StamdataFileApi} from "../../../services/api/stamdataFileApi/stamdataFileApi";
import React, {ReactNode, useState} from "react";
import {Spinner} from "../spinner/spinner";

const fileApi = new LogbogFileApi(new HttpClientService(), new FileDownloadHandler());
const emailApi = new StamdataEmailApi(new HttpClientService(), new AccountService(), new FileDownloadHandler());
const stamdataApi = new StamdataFileApi(new HttpClientService(), new FileDownloadHandler())

export interface DownloadButtonProps {
    fileMetadata: FileMetadata;
    fileLocation: FileLocationEnum;
    waitText?: string;
    child?: ReactNode;
    buttonText?: string;
}

export function DownloadButton(props: DownloadButtonProps) {
    const [loading, setLoading] = useState(false);

    const downloadFile = async () => {
        if (props.fileLocation === FileLocationEnum.Logbog) {
            await fileApi.downloadFile(props.fileMetadata);
        } else if (props.fileLocation === FileLocationEnum.Email) {
            await emailApi.GetAttachments(props.fileMetadata)
        } else if (props.fileLocation === FileLocationEnum.Stamdata) {
            await stamdataApi.downloadFile(props.fileMetadata);
        }
    }


    const buttonClicked = () => {
        setLoading(true);
        downloadFile().finally(() => {
            setLoading(false);
        })
    }

    const render = (
        <div className={""} >
            {loading &&
                <div className="flex-row flex-align-c justify-content-center" role='button' onClick={() => buttonClicked()}>
                    <Spinner spinnerSize="extra-tiny-small"/>
                    {props.waitText &&
                        <div>{props.waitText}</div>
                    }
                </div>

            }

            {!loading &&
                <div role='button' onClick={() => buttonClicked()}>
                    <EmblaIcon iconName='download'/>
                </div>
            }
        </div>

    )

    return <>{render}</>
}
