import {RoutePaths} from "infrastructure/routes";
import {useHistory} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
    UserProfileAvatar,
    UserProfileAvatarSizeEnum
} from "../../../../core/components/userProfileAvatar/userProfileAvatar";
import {UserProfileAvatarModel} from "../../../../core/components/userProfileAvatar/userProfileAvatarModel";
import {Dropdown, DropdownOption} from "../../../../core/components/dropdown/dropdown";
import {SynkroniseredeForloebModel} from "../../../../core/sharedmodels/forloeb/synkroniseredeForloebModel";
import {Localizer} from "../../../../infrastructure/localization/localizer";
import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../../../../core/layout/responsive";
import {GetUddannelsestypeTranslation} from "../../../../core/sharedmodels/stilling/uddannelseTypeEnum";
import {SingleValue} from "react-select";
import useLogbogApi from "../../../../core/hooks/useLogbogApi";
import {DeaktiveretForloebDataModel} from "../../../../core/sharedmodels/forloeb/deaktiveretForloebDataModel";
import {DeaktiveretForloebData} from "./deaktiveretForloebData";
import {NotificationModule} from "ditmer-embla";
import {Loading} from "../../../../core/components/loading/loading";
import {ModalAcceptType, ModalSubmitMessage} from "../../../../core/components/modalMessage/modalSubmitMessage";

type SynkroniseretForloebProps = {
    synkroniseretForloeb: SynkroniseredeForloebModel
    forloebUpdatedCallabck: () => void
}

export function SynkroniseretForloeb({synkroniseretForloeb, forloebUpdatedCallabck}: SynkroniseretForloebProps) {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const IsMobile = useMediaQuery(mobileMaxWidthQuery);
    const [selectedDeaktiveretForloeb, setSelectedDeaktiveretForloeb] = useState<string>();
    const [deaktiveretForloebData, setDeaktiveretForloebData] = useState<DeaktiveretForloebDataModel>();
    const {forloebApi} = useLogbogApi();
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [markerHaandteretConfirmModalOpen, setMarkerHaandteretConfirmModalOpen] = useState(false);
    const confirmModalId = "dataoverfoersel-confirm-modal";
    const haandteretConfirmModalId = "haandteret-confirm-modal";

    useEffect(() => {
        const fetch = async () => {
            if (selectedDeaktiveretForloeb) {
                setIsLoading(true)
                const forloebData = await forloebApi.getDeaktiveretForloebData(selectedDeaktiveretForloeb);
                setDeaktiveretForloebData(forloebData);
                setIsLoading(false)
            } else {
                setDeaktiveretForloebData(undefined)
            }
        }
        fetch();
    }, [selectedDeaktiveretForloeb, forloebApi])

    const handleOnClickForloeb = () => {
        history.push(RoutePaths.Forloeb([synkroniseretForloeb.uddannelseslaegeId, synkroniseretForloeb.id]).url)
    };

    const overfoerData = async () => {
        if (selectedDeaktiveretForloeb) {
            const forloebId = await forloebApi.transferForloebData(selectedDeaktiveretForloeb, synkroniseretForloeb.id);
            if (forloebId) {
                NotificationModule.showSuccess(Localizer.global_dataOverfoert(), "");
                forloebUpdatedCallabck();
            } else
                NotificationModule.showError(Localizer.global_dataOverfoerselFejlet(), "");
        }
    }

    const markerHaandteret = async () => {
        const forloebId = await forloebApi.setDeaktiveredeForloebHaandteret(synkroniseretForloeb.deaktiveredeForloebInfo.map(x => x.value));
        if (forloebId?.length > 0) {
            NotificationModule.showSuccess(Localizer.global_forloebHaandteret(), "");
            forloebUpdatedCallabck();
        } else
            NotificationModule.showError(Localizer.global_fejlVedMarkeringAfHaandteret(), "");
    }

    const handleDeaktiveretForloebSelected = (selectedValue?: SingleValue<DropdownOption<string>>) => {
        setSelectedDeaktiveretForloeb(selectedValue?.value);
    }

    return (
        <div>
            {synkroniseretForloeb &&
                <>
                    <div className="flex-container-row align-items-center wrap">
                        <div className="flex-align-top flex-fill align-items-center">
                            <UserProfileAvatar size={UserProfileAvatarSizeEnum.medium}
                                               userProfileAvatarModel={new UserProfileAvatarModel(synkroniseretForloeb.uddannelseslaegeNavn, synkroniseretForloeb.uddannelseslaegeInitialer, true)}/>
                            <div className="margin-left-m">
                                <h5>{synkroniseretForloeb.uddannelseslaegeNavn}</h5>
                                <h6>{synkroniseretForloeb.uddannelsesstedName}</h6>
                                <h6>{synkroniseretForloeb.specialeName + ", " + GetUddannelsestypeTranslation(synkroniseretForloeb.uddannelseType)}</h6>
                                {synkroniseretForloeb.sygehus &&
                                    <h6>{synkroniseretForloeb.sygehus}</h6>
                                }
                                <h6 className="subtle">{synkroniseretForloeb.startDate?.dateWithoutTimeFormat(false) + " - " + synkroniseretForloeb.endDate?.dateWithoutTimeFormat(false)}</h6>
                                <button className={"btn btn-default margin-right-m"}
                                        onClick={() => handleOnClickForloeb()}>
                                    {Localizer.forloebpage_gaaTilForloeb()}
                                </button>
                            </div>
                        </div>

                        <div className={"flex-column"}>
                            <h6 className={`margin-right-m ${IsMobile ? "margin-top-s" : ""}`}>{Localizer.forloebOverview_overfoerDataFraDeaktiveretForloeb()}</h6>
                            <Dropdown className={`margin-top-s`}
                                      placeholder={Localizer.vaelgForloeb()}
                                      options={synkroniseretForloeb.deaktiveredeForloebInfo}
                                      value={synkroniseretForloeb.deaktiveredeForloebInfo.filter(x => selectedDeaktiveretForloeb === x.value)}
                                      onChange={(selectedDeaktiveretForloeb) => handleDeaktiveretForloebSelected(selectedDeaktiveretForloeb)}
                                      isClearable
                            />
                            {!selectedDeaktiveretForloeb && confirmModalOpen &&
                                <h6 className={"text-danger margin-right-m"}>{Localizer.vaelgForloeb()}</h6>
                            }
                            <div className={`d-flex ${IsMobile ? "" : "justify-content-end"}`}>
                                <button className={`btn btn-default margin-top-s margin-right-m`}
                                        onClick={() => setConfirmModalOpen(true)}
                                        data-toggle="modal"
                                        data-target={`#${confirmModalId + synkroniseretForloeb.id}`}>
                                    {Localizer.forloebOverview_overfoerData()}
                                </button>

                                <button className={"btn btn-danger margin-top-s"} onClick={() => setMarkerHaandteretConfirmModalOpen(true)}
                                        data-toggle="modal"
                                        data-target={`#${haandteretConfirmModalId + synkroniseretForloeb.id}`}>
                                    {Localizer.forloebpage_markerHaandteret()}
                                </button>
                            </div>
                        </div>
                    </div>

                    <Loading isLoading={isLoading} text={Localizer.henterForloebData()} spinnerClasses={"margin-m"} timeout={0}>
                        <div/>
                        {deaktiveretForloebData &&
                            <>
                                <div className={"border-bottom margin-top-m"}></div>
                                <div className="row flex-row">
                                    <DeaktiveretForloebData deaktiveretForloebData={deaktiveretForloebData}/>
                                </div>
                            </>
                        }
                    </Loading>
                </>
            }
            {confirmModalOpen && selectedDeaktiveretForloeb &&
                <ModalSubmitMessage modalId={confirmModalId + synkroniseretForloeb.id}
                                    title={Localizer.areYouSure()}
                                    description={<p>{Localizer.overfoerDataConfirmMessage()}</p>}
                                    cancelAction={() => setConfirmModalOpen(false)}
                                    acceptAction={() => overfoerData()}
                                    modalAcceptType={ModalAcceptType.primary}
                                    acceptButtonText={Localizer.forloebOverview_overfoerData()}
                />
            }

            {markerHaandteretConfirmModalOpen &&
                <ModalSubmitMessage modalId={haandteretConfirmModalId + synkroniseretForloeb.id}
                                    title={Localizer.areYouSure()}
                                    description={<p>{Localizer.markerHaandteretConfirmMessage()}</p>}
                                    cancelAction={() => setMarkerHaandteretConfirmModalOpen(false)}
                                    acceptAction={() => markerHaandteret()}
                                    modalAcceptType={ModalAcceptType.primary}
                                    acceptButtonText={Localizer.forloebpage_markerHaandteret()}
                />
            }
        </div>
    )
}
