import { FileLocationEnum } from "core/sharedmodels/file/fileLocationEnum";
import { RenderFolderChildrenRecursively, anyChildrenWithFiles, childrenWithFilesCount } from "./renderFolderChildrenRecursively";
import { CollapseTab } from "../Tabs/collapseTab";
import { FileMetadataList } from "../fileMetadata/fileMetadataList";
import { MappestrukturModel } from "services/api/stamdataMappe/MappestrukturModel";
import { FileData } from "services/api/stamdataMappe/FilesAndFoldersModel";
import { FileMetadata } from "core/sharedmodels/fileMetaData/fileMetaData";

type CollapseFolderWithFilesProps = {
    index: number;
    folder: MappestrukturModel;
    showCustomBadgeText?: (fileMetadataId: string) => string;
    childShowCustomBadgeText?: (fileMetadataId: string, files: FileData[]) => string;
    editFileAction?: (fileMetadataId: string) => void;
    editChildFileAction?: (fileMetadataId: string, files: FileData[]) => void;
    deleteFileAction?: (fileMetadata: FileMetadata) => void;
}

const CollapseFolderWithFiles = ({index, folder, showCustomBadgeText, editFileAction, childShowCustomBadgeText, editChildFileAction, deleteFileAction} : CollapseFolderWithFilesProps) => {
    
    const amountFilesInFolder = folder.files?.length ?? 0;
    
    return (
        <>
            {(anyChildrenWithFiles(folder.children) || (folder.files !== undefined && folder.files?.length > 0)) &&
                <CollapseTab 
                    id={folder.id + index}
                    title={amountFilesInFolder > 0 ? folder.mappeNavn.appendCounter(amountFilesInFolder) : folder.mappeNavn}
                    expandedDefault={false}
                    additionalClasses={"folder"}
                    key={folder.id + index + amountFilesInFolder}
                >
                    <div className={"font-weight-bold padding-left-m"}>
                        <FileMetadataList
                            fileMetadatas={folder.files?.map(x => x.fileMetadata) ?? []}
                            fileLocation={FileLocationEnum.Stamdata}
                            showDeleteButton={!!deleteFileAction}
                            withUploader={false}
                            hideBorder={true}
                            showCustomBadgeText={showCustomBadgeText}
                            editFileOnClick={editFileAction}
                            fileDeletedCallbackOverride={deleteFileAction}
                        />
                    </div>
                    <RenderFolderChildrenRecursively
                        folderChildren={folder?.children}
                        showCustomBadgeText={childShowCustomBadgeText}
                        editFileAction={editChildFileAction}
                        deleteAction={deleteFileAction}
                    />
                </CollapseTab>
            }
        </>

    )
}

export default CollapseFolderWithFiles;