import React from "react";
import {Localizer} from "../../infrastructure/localization/localizer";

export type KursusKlarmeldingModalInfoProps = {
    handleKommentarChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const KursusKlarmeldingModalInfo = ({handleKommentarChange}: KursusKlarmeldingModalInfoProps) => {
    return (
        <>
            <p>{Localizer.klarmeldingerPage_kursus_areYouSureRemoveKursus()}</p>
            {/*----------------------------------------------------------------------------------------------------------------------------------------------------*/}
            {/*"Customer doesnt want comments here right now, can be added again later and will work with the following lines, if they want it in the future.".*/}
            {/*----------------------------------------------------------------------------------------------------------------------------------------------------*/}
            {/*<label>{Localizer.global_comment()}: </label>*/}
            {/*<textarea className="form-control"*/}
            {/*          placeholder={Localizer.klarmeldingerPage_describeReasonRemoveKursus()}*/}
            {/*          onChange={handleKommentarChange}*/}
            {/*/>*/}
            {/*----------------------------------------------------------------------------------------------------------------------------------------------------*/}
        </>
    )
}
