export enum SvarGruppeEnum {
    Gruppe1 = 1,
    Gruppe2 = 2,
    Gruppe3 = 3,
    Gruppe4 = 4,
    Gruppe5 = 5,
    Gruppe6 = 6,
    Gruppe7 = 7
}

export function GetQuestionNumbersInGroups(groups: Array<SvarGruppeEnum>): Set<number>
{
    const questionsInGroups = groups.flatMap(g => GetQuestionNumbersInGroup(g));
    return new Set(questionsInGroups);
}

export function GetQuestionNumbersInGroup(group: SvarGruppeEnum): Array<number>
{
    switch(group) { 
        case SvarGruppeEnum.Gruppe1: 
            return [1, 2, 3];
        case SvarGruppeEnum.Gruppe2: 
            return [2, 3, 4, 5, 6, 7];
        case SvarGruppeEnum.Gruppe3: 
            return [8, 9, 10, 11, 12, 13];
        case SvarGruppeEnum.Gruppe4: 
            return [12, 13, 14, 15, 16, 17];
        case SvarGruppeEnum.Gruppe5: 
            return [18, 19, 20];
        case SvarGruppeEnum.Gruppe6: 
            return [21,22,23,24];
        case SvarGruppeEnum.Gruppe7: 
            return [25, 26];
        default: { 
           return [];
        } 
     } 
}