import {Localizer} from "../../../infrastructure/localization/localizer";

export enum KbuRundeEnum {
    Vinter = 1,
    Sommer = 2,
}

export function GetKbuRundeEnumTranslation(value: KbuRundeEnum) {
    switch (value) {
        case KbuRundeEnum.Vinter:
            return Localizer.kbuRundeVinter();
        case KbuRundeEnum.Sommer:
            return Localizer.kbuRundeSommer();
        default:
            return "";
    }
}

export function GetKbuRundeEnum(translation: string) {
    switch (translation) {
        case Localizer.kbuRundeVinter():
            return KbuRundeEnum.Vinter;
        case Localizer.kbuRundeSommer():
            return KbuRundeEnum.Sommer
    }
}
