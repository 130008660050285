import {useEffect, useState} from "react";
import {HttpClientService} from "../../../../services/httpClient/httpClientService";
import {AccountService} from "../../../../services/account/accountService";
import {PdfDetailedUserHeaderComponent} from "./pdfDetailedUserHeaderComponent";
import {Localizer} from "../../../../infrastructure/localization/localizer";
import { StamdataSpecialeApi } from "services/api/stamdataSpeciale/stamdataSpecialeApi";
import { SpecialeModel } from "core/sharedmodels/speciale/specialeModel";
import PdfHeaderRow from "../pdfHeaderRow";
import {useFetchWithErrorHandlingPdf} from "../useFetchWithErrorHandlingPdf";

type PdKursusHeaderComponentProps = {
    brugerId: string;
    brugerMaalbeskrivelseId: string;
}

const specialeApi = new StamdataSpecialeApi(new HttpClientService(), new AccountService());

export function PdKursusHeaderComponent(props: PdKursusHeaderComponentProps) {
    const [speciale, setSpeciale] = useState<SpecialeModel>();

    const setError = useFetchWithErrorHandlingPdf();

    useEffect(() => {
        const fetch = async () => {
            setSpeciale(await specialeApi.getSpecialeByBrugerMaalbeskrivelse(props.brugerMaalbeskrivelseId));
        }
        fetch().catch(err => setError(err));;
    }, [props.brugerMaalbeskrivelseId])

    return (
        <div className={"card shadow-none"}>
            <div className={"card-body flex-container-column flex-space"}>
                <PdfDetailedUserHeaderComponent brugerId={props.brugerId}/>
                <PdfHeaderRow>
                    <PdfHeaderRow.Entry
                        label={Localizer.global_speciale()}
                        value={speciale?.navn}
                    />
                </PdfHeaderRow>
            </div>
        </div>
    );
}
