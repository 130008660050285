import createValidationSchema from "core/components/validation/createValidationSchema";
import * as yup from "yup";

export class GodkendTidInputModel {
    public forloebId: string;
    public uddannelsessted: string;
    public kommentar?: string;
    public infoText?: string;
}

export const BuildNew = (forloebId: string, uddannelsessted: string, infoText?: string): GodkendTidInputModel => {
    let inputModel = new GodkendTidInputModel();

    inputModel.forloebId = forloebId;
    inputModel.uddannelsessted = uddannelsessted;
    inputModel.infoText = infoText;
    inputModel.infoText = "";

    return inputModel;
}

export const godkendTidValidationSchema = createValidationSchema<GodkendTidInputModel>({
    forloebId: yup.string().notRequired(),
    uddannelsessted: yup.string().notRequired(),
    kommentar: yup.string().notRequired(),
    infoText: yup.string().notRequired()
});
