import {PropsWithChildren, ReactFragment} from "react";
import {YearsForOverview} from "./yearsForOverview";
import {VerticalLines} from "./VerticalLinesComponent";
import {HorizontalLineSpacer} from "./horizontalLineSpacer";

const HorizontalLines = ({ children }: PropsWithChildren<ReactFragment>) => (
    <>
        {children}
        {<HorizontalLineSpacer />}
    </>
);

const Overview = ({children}: PropsWithChildren<ReactFragment>) => (<>{children}</>);

export default Object.assign(
    Overview,
    {
        YearsHeader: YearsForOverview,
        HorizontalLines,
        VerticalLines,
    }
);
