import React from "react";
import {AlertType, GetAlertCssClassFromAlertType} from "./alertType";

export interface IAlertProps {
    alertType: AlertType;
    message: string;
}

export function Alert(props: IAlertProps) {
    return (
        <div>
            <div className={"alert " + GetAlertCssClassFromAlertType(props.alertType)} role="alert">
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div>
                    {props.message}
                </div>
            </div>
        </div>
    );
}
