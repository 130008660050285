import KursisterOverviewContextProvider from "./kursisterOverviewContextProvider";
import KursisterList from "./components/kursisterList";

export const KursisterOverviewPage = () => {
    return (
        <KursisterOverviewContextProvider>
            <KursisterList />
        </KursisterOverviewContextProvider>
    );
}

