import {EvalueringsstatistikPageConstants} from "./components/EvalueringsstatistikPageConstants";
import {DatasetModel} from "./datasetModel";
import {EvalueringStatistikModel} from "../../services/api/evalueringStatistik/evalueringStatistikReponseModel";
import {SpoergsmaalTypeEnum} from "../../core/sharedmodels/evaluering/spoergsmaalTypeEnum";
import {EvalueringGruppeScoreModel} from "../../services/api/evalueringStatistik/EvalueringGruppeScoreModel";
import {SvarGruppeEnum} from "../../core/sharedmodels/evaluering/svarGruppeEnum";

export function getColor(index: number, datasets: DatasetModel[]): string {
    const datasetColors = datasets.map(x => x.datasetColor);
    if (index % 10 === 0 && !datasetColors.some(x => x === EvalueringsstatistikPageConstants.color0))
        return EvalueringsstatistikPageConstants.color0;
    if (index % 10 === 1 && !datasetColors.some(x => x === EvalueringsstatistikPageConstants.color1))
        return EvalueringsstatistikPageConstants.color1;
    if (index % 10 === 2 && !datasetColors.some(x => x === EvalueringsstatistikPageConstants.color2))
        return EvalueringsstatistikPageConstants.color2;
    if (index % 10 === 3 && !datasetColors.some(x => x === EvalueringsstatistikPageConstants.color3))
        return EvalueringsstatistikPageConstants.color3;
    if (index % 10 === 4 && !datasetColors.some(x => x === EvalueringsstatistikPageConstants.color4))
        return EvalueringsstatistikPageConstants.color4;
    if (index % 10 === 5 && !datasetColors.some(x => x === EvalueringsstatistikPageConstants.color5))
        return EvalueringsstatistikPageConstants.color5;
    if (index % 10 === 6 && !datasetColors.some(x => x === EvalueringsstatistikPageConstants.color6))
        return EvalueringsstatistikPageConstants.color6;
    if (index % 10 === 7 && !datasetColors.some(x => x === EvalueringsstatistikPageConstants.color7))
        return EvalueringsstatistikPageConstants.color7;
    if (index % 10 === 8 && !datasetColors.some(x => x === EvalueringsstatistikPageConstants.color8))
        return EvalueringsstatistikPageConstants.color8;
    if (index % 10 === 9 && !datasetColors.some(x => x === EvalueringsstatistikPageConstants.color9))
        return EvalueringsstatistikPageConstants.color9;
    if (index % 10 === 10 && !datasetColors.some(x => x === EvalueringsstatistikPageConstants.color10))
        return EvalueringsstatistikPageConstants.color10;
    else return EvalueringsstatistikPageConstants.fallbackColor;
}

export function addTotalAverage(evalueringer: EvalueringStatistikModel[], spoergsmaalTypeEnum: SpoergsmaalTypeEnum): number[] {
    let averageScores: number[] = [];
    if (spoergsmaalTypeEnum === SpoergsmaalTypeEnum.Enkeltvis) {
        averageScores = new Array<number>(EvalueringsstatistikPageConstants.numberOfQuestionsExcludingOptionalTextInput).fill(0);
        evalueringer.forEach(y => y.singleAverageScore.forEach((score, index) => addScoreToQuestion(score, index)));
    }
    else {
        averageScores = new Array<number>(EvalueringsstatistikPageConstants.numberOfGroups).fill(0);
        evalueringer.forEach(y => y.gruppeScore.forEach(score => addScoreToQuestionGroup(score)));
    }

    const dataScores = averageScores.map(gs => {
        const amountWithScores = evalueringer.filter(x => x.antalForloebEvalueret > 0 || x.antalForloebEvalueret === null).length;
        return amountWithScores === 0 ? 0 : gs / amountWithScores;
    });

    return dataScores;

    function addScoreToQuestion(score: number, index: number) {
        averageScores[index] += score;
    }

    function addScoreToQuestionGroup(score: EvalueringGruppeScoreModel) {
        switch (score.svarGruppeEnum) {
            case SvarGruppeEnum.Gruppe1:
                averageScores[0] += score.averageScore;
                break;
            case SvarGruppeEnum.Gruppe2:
                averageScores[1] += score.averageScore;
                break;
            case SvarGruppeEnum.Gruppe3:
                averageScores[2] += score.averageScore;
                break;
            case SvarGruppeEnum.Gruppe4:
                averageScores[3] += score.averageScore;
                break;
            case SvarGruppeEnum.Gruppe5:
                averageScores[4] += score.averageScore;
                break;
            case SvarGruppeEnum.Gruppe6:
                averageScores[5] += score.averageScore;
                break;
            case SvarGruppeEnum.Gruppe7:
                averageScores[6] += score.averageScore;
                break;
            default:
                break;
        }
    }
}
