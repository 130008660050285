import { EmblaIcons } from "core/emblaIcons";
import { EmblaIcon } from "../emblaIcon/emblaIcon";
import classNames from "classnames";

type CalendarProps = {
    dato?: Date;
    startTime?: Date;
    endTime?: Date;
    additionalClasses?: string;
}

const CalendarTimeDisplay = ({dato, startTime, endTime, additionalClasses}: CalendarProps) => {
    return (
        <div className={classNames("flex-align-c subtle margin-right-m wrap", additionalClasses)}>

            <div className="margin-right-xs card-list-calender-icon">
                <EmblaIcon iconName={EmblaIcons.Calendar}/>
            </div>

            {dato &&
                <div>
                    { dato.dateWithoutTimeFormat(false) }
                </div>
            }

            {startTime &&
                <div className="margin-left-s">
                    { startTime.timeFormat(true) }
                </div>
            }
            {(startTime && endTime) &&
                <div>
                    &nbsp; - { endTime.timeFormat(true) }
                </div>
            }

        </div>
    );
}

export default CalendarTimeDisplay;
