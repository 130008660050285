import Container from "core/components/container/container";
import UddannelsesstedFiles from "./uddannelsesstedFilesList";
import { Localizer } from "infrastructure/localization/localizer";
import HtmlHeading from "core/components/titels/HtmlHeadings";
import classNames from "classnames";
import useScreenResolution from "core/hooks/useScreenResolution";
import FileCreateSlideIn from "./uddannelsesstedFileCreateSlideIn";
import useUserFilterPermissions from "core/hooks/useUserFilterPermissions";
import React, {useCallback, useEffect, useState} from "react";
import {useFileContext} from "../context/fileContextProvider";
import useStamdataApi from "core/hooks/useStamdataApi";
import {SearchField} from "../../../../core/components/search/searchField";
import {PaginationModel} from "../../../../core/sharedmodels/filter/PaginationModel";
import {Pagination} from "../../../../core/components/pagination/pagination";
import FileAdministrationFilter from "../filter/FileAdministrationFilter";

const InitialPage = 1;
const InitialPageSize = 25;

type FileFilter = {
    search:string;
}

const FileAdministrationPageComponent = () => {
    const { stamdataFileApi } = useStamdataApi();
    const { isMobile } = useScreenResolution();
    const { permissions } = useUserFilterPermissions();
    const { state, setIsLoading, setFiles } = useFileContext();
    const [filterState, setFilterState] = useState<FileFilter>({search: ""});
    const [pagination, setPagination] = useState(new PaginationModel(InitialPage, InitialPageSize, 0))

    const fetchFiles = useCallback(async (filter: FileFilter, page: number, pageLength: number) => {
        setIsLoading(true);

        const {recordsFiltered, data} = await stamdataFileApi.getUddannelsesstedFiles(filter.search, page, pageLength);

        setFiles(data);
        setPagination(curr => ({
            ...curr,
            recordCount: recordsFiltered
        }));

        setIsLoading(false);
    }, [stamdataFileApi, setFiles, setIsLoading]);


    useEffect(() => {
        const fetch = async () => {
            await fetchFiles(filterState, pagination.page, pagination.pageLength);
        }
        fetch();
    }, [fetchFiles, filterState, pagination.page, pagination.pageLength]);

    const handlePagination = async (page: number) => setPagination(prev => ({ ...prev, page: page }));
    const onPageLengthChangeCallback = (pageLength: number) => setPagination(prev => ({ ...prev, pageLength: pageLength }));
    const handleSearch = async (query: string) => {
        setFilterState((prev) => ({...prev, search: query.trim()}));
    };

    return (
        <Container>

            <Container.Header
                childrenIsHeaderActions
                titleConfig={{
                    heading: HtmlHeading.H3,
                    title: Localizer.FilesFromEducationPlace(),
                    titleInfoText: `${Localizer.filePage_uploadWarningMain()}\n${Localizer.filePage_uploadWarningSub()}`
            }}>
                <div className={classNames(isMobile && "justify-content-end")}>
                    <FileCreateSlideIn permissions={permissions}/>
                </div>
            </Container.Header>

            <Container.Header>
                {!isMobile &&
                    <FileAdministrationFilter
                        additionalNodes={<SearchField additionalClasses='margin-right-s'
                                                      initialValue={filterState.search}
                                                      callback={(search) => handleSearch(search)}/>}
                        pagination={pagination}
                        onPageLengthChangeCallback={onPageLengthChangeCallback}
                    />
                }
            </Container.Header>


            <Container.Body additionalClasses="uddannelseslaeger-list">
                <UddannelsesstedFiles permissions={permissions}/>
            </Container.Body>

            <Container.Footer additionalClasses="flex-row-center">
                <Pagination
                    handlePagination={(page) => handlePagination(page)}
                    paginationModel={pagination}
                />
            </Container.Footer>

        </Container>
    );
}

export default FileAdministrationPageComponent;
