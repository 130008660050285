import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "../../app/store"

export interface VejledereValidate{
    validatedDate: string,
}

const initialState: VejledereValidate = {
    validatedDate: "",
}

export const vejledereDateSlice = createSlice({
    name: "vejledereValidateState",
    initialState,
    reducers: {
        setValidated: (state, action: PayloadAction<string>) => {
            state.validatedDate = action.payload;
        } 
    }
})

export const {setValidated} = vejledereDateSlice.actions;
export const ValidatedDateReducer = (state: RootState) => state.validatedDateReducer;
export default vejledereDateSlice.reducer;
