import {HttpClientService} from "services/httpClient/httpClientService";
import config from "config/config";
import {ForSelectModel} from "../../../core/sharedmodels/forSelectModel";
import {ObjectToQueryString} from "../objectToQueryString";
import {ApiResponse} from "../apiResponse";
import {ForSelectResponseModel} from "../forSelectResponseModel";

export class AutorizationRegisterApi {
    private httpClientService: HttpClientService;

    private authorizationRegisterBaseUrl = () => config.logbogApiUrl + "api/Authorisation/";

    constructor(httpClientService: HttpClientService) {
        this.httpClientService = httpClientService;
    }

    async getLaegerSelectForCreateVejleder(search: string) : Promise<ForSelectModel[]> {
        const queryStringModel = {
            search: search
        }

        const url = this.authorizationRegisterBaseUrl() + ObjectToQueryString(queryStringModel);
        const result = await this.httpClientService.Get<ApiResponse<ForSelectResponseModel[]>>(url);

        return result.data.map(info => {
            return new ForSelectModel(info)
        });
    }
}
