import { CreateAttestationInputModel } from "core/componentsPage/forloeb/createAttestationInputModel";

export class addAttestationForTidTekstPatchModel {
    constructor(inputModel: CreateAttestationInputModel) {
        this.forloebId = inputModel.forloebId;
        this.infoTekst = inputModel.InfoTekst;
    }

    forloebId: string;
    infoTekst?: string;
}