import {useRouteMatch} from "react-router-dom";
import {useEffect, useState} from "react";
import {BeskaeftigelseModel} from "../../../pages/sendStpsAnsoegning/beskaeftigelse/beskaeftigelseModel";
import "./pdfStyling/laegeligBeskaeftigelsePdf.scss"
import {
    BrugerMaalbeskrivelseResponseModel
} from "services/api/stamdataMaalbeskrivelse/brugerMaalbeskrivelseResponseModel";
import {UddannelseTypeEnum} from "../../sharedmodels/stilling/uddannelseTypeEnum";
import {LaegeligBeskaeftigelseText} from "../../../pages/sendStpsAnsoegning/beskaeftigelse/beskaeftigelseText";
import PdfComponent from "core/components/pdf/pdfComponent";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {PdfBeskaeftigelseHeaderComponent} from "./pdfHeaders/pdfBeskaeftigelseHeaderComponent";
import {useFetchWithErrorHandlingPdf} from "./useFetchWithErrorHandlingPdf";
import useLogbogApi from "../../hooks/useLogbogApi";
import useStamdataApi from "../../hooks/useStamdataApi";

export function LaegeligBeskaeftigelsePdf(){

    const routeMatch = useRouteMatch();
    const routeParams = (routeMatch.params as { ansoegningId: string, brugerId: string, brugerMaalbeskrivelseId: string, uddannelseTypeEnum: UddannelseTypeEnum});
    const [beskaeftigelser, setBeskaeftigelser] = useState<BeskaeftigelseModel[]>();
    const [brugerMaalbeskrivelse, setBrugerMaalbeskrivelse] = useState<BrugerMaalbeskrivelseResponseModel>();
    const setError = useFetchWithErrorHandlingPdf();
    const { beskaeftigelseApi } = useLogbogApi();
    const { stamdataMaalbeskrivelseApi } = useStamdataApi();
    useEffect(() => {
        const fetch = async () => {
            setBeskaeftigelser(await beskaeftigelseApi.getBeskaeftigelserByAnsoegningsId(routeParams.ansoegningId));
            setBrugerMaalbeskrivelse(await stamdataMaalbeskrivelseApi.getBrugerMaalbeskrivelse(routeParams.brugerMaalbeskrivelseId));
            (document as any).pdfReady = true;
        }
        fetch().catch(err => setError(err));
    }, [beskaeftigelseApi, routeParams.ansoegningId, routeParams.brugerMaalbeskrivelseId, setError, stamdataMaalbeskrivelseApi])

    return (
        <PdfComponent>
            <h2 className={"margin-bottom-m"}>
                {Localizer.beskaeftigelsePdf_skemaTilOversigt()}
            </h2>
            <PdfBeskaeftigelseHeaderComponent
                brugerId = {routeParams.brugerId}
                brugerMaalbeskrivelseId = {routeParams.brugerMaalbeskrivelseId}
                maalbeskrivelseNavn = {brugerMaalbeskrivelse?.navn}
            />
            <div className={"flex-container-column margin-bottom-xl margin-top-l"}>
                <p>{Localizer.beskaeftigelsePdf_skemaOgMedsend()}</p>
                <p className={"beskaeftigelse-pdf-text-underline"}>{Localizer.beskaeftigelsePdf_skemaUdfylesFoelgende() + ":"}</p>
                {<LaegeligBeskaeftigelseText uddannelseTypeEnum={routeParams.uddannelseTypeEnum}/>}
            </div>
            <PdfComponent.BlockItem className={"card shadow-none"}>
                <div className={"card-header"}>
                    <div className={"col-2 beskaeftigelse-pdf-list-header"}>{Localizer.beskaeftigelse_stillingsbetegnelse()}</div>
                    <div className={"col-2 beskaeftigelse-pdf-list-header"}>{Localizer.beskaeftigelse_ansaettelsessted()}</div>
                    <div className={"col-2 beskaeftigelse-pdf-list-header"}>{Localizer.afdeling()}</div>
                    <div className={"col-2 beskaeftigelse-pdf-list-header"}>{Localizer.startdato()}</div>
                    <div className={"col-2 beskaeftigelse-pdf-list-header"}>{Localizer.slutdato()}</div>
                    <div className={"col-1 beskaeftigelse-pdf-list-header pr-0"}>{Localizer.beskaeftigelse_maaneder()}</div>
                    <div className={"col-1 beskaeftigelse-pdf-list-header"}>{Localizer.beskaeftigelse_Timer()}</div>
                </div>
                    {beskaeftigelser &&
                        beskaeftigelser.map(beskaeftigelse =>
                            <PdfComponent.NonBreakableItem>
                                <div className={"card-body"} >
                                    <div className={"flex-container-row align-content-center"}>
                                        <div className={"col-2 beskaeftigelse-pdf-list-item"}>
                                            <p>{beskaeftigelse.stillingsbetegnelse}</p>
                                            <p>{beskaeftigelse.stillingsnummer}</p>
                                        </div>
                                        <div className={"col-2 beskaeftigelse-pdf-list-item"}>{beskaeftigelse.ansaettelsessted}</div>
                                        <div className={"col-2 beskaeftigelse-pdf-list-item"}>{beskaeftigelse.afdeling}</div>
                                        <div className={"col-2 beskaeftigelse-pdf-list-item"}>{beskaeftigelse.startdato?.dateWithoutTimeFormat(false)}</div>
                                        <div className={"col-2 beskaeftigelse-pdf-list-item"}>{beskaeftigelse.slutdato?.dateWithoutTimeFormat(false)}</div>
                                        <div className={"col-1 beskaeftigelse-pdf-list-item pr-0"}>{beskaeftigelse.maaneder}</div>
                                        <div className={"col-1 beskaeftigelse-pdf-list-item"}>{beskaeftigelse.nedsatTidAntalTimer}</div>
                                    </div>
                                </div>
                            </PdfComponent.NonBreakableItem>
                        )
                    }
            </PdfComponent.BlockItem>
        </PdfComponent>
    );
}
