import {FileMetadata} from "../../sharedmodels/fileMetaData/fileMetaData";
import CreateValidationSchema from "../validation/createValidationSchema";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {array, string} from "yup";
import {EmailConstants} from "./emailConstants";

export class CreateMailInputModel {
    public mailSkabelonId: string;
    public subject: string;
    public message: string;
    public fileMetadata: FileMetadata[];
    public userIds: string[];
}

export const BuildNew = (ids:string[]=[]) : CreateMailInputModel => {
    let inputModel = new CreateMailInputModel();

    inputModel.mailSkabelonId = "";
    inputModel.subject = " ";
    inputModel.message = "";
    inputModel.fileMetadata = [];
    inputModel.userIds = ids;

    return inputModel;
}

export const CreateMailValidationSchema = CreateValidationSchema<CreateMailInputModel>({
    mailSkabelonId: string().notRequired(),
    subject: string().required(Localizer.validate_SubjectRequired()),
    message: string().required(Localizer.validate_MessageRequired()),
    fileMetadata: array().notRequired(),
    userIds: array().of(string().required()).min(1, Localizer.validate_emailSentToRequired()).max(EmailConstants.maxAmount, Localizer.validate_emailBulkAllowanceExeededError(EmailConstants.maxAmount))
})
