import './forloebHeader.scss';
import {Localizer} from 'infrastructure/localization/localizer';
import useEffectAsync from 'infrastructure/effect';
import {RolleEnum} from "../../../infrastructure/enums/userRole";
import {EmblaIllustration} from "../emblaIcon/emblaIllustration";
import {ReactComponent as LeadershipSvg} from "../../../content/images/leadership.svg";
import {VejlederAfdelingerModel} from "../../../pages/uddannelseslaegeOverview/components/vejlederAfdelingerModel";
import {initAllPopovers} from "../../utils";
import './vejlederHeader.scss'
import './headerCards.scss'
import {UserRoleTitleToString} from "../../../infrastructure/enums/rolleTitelEnum";
import useUser from 'core/hooks/useUser';
import useScreenResolution from 'core/hooks/useScreenResolution';

export const VejlederHeader = (props : {VejlederAfdelinger? : VejlederAfdelingerModel[]}) => {
    const {isMobile} = useScreenResolution();
    const {currentUser} = useUser();

    useEffectAsync(async () => {
        initAllPopovers();
    }, [])

    return (
        <div className={`card header-card uddannelseslaege-header top-level-container-margins`}>
            <div className={"flex-container-row"}>
                {(currentUser.CurrentRolle === RolleEnum.Vejleder && !isMobile) &&
                <LeadershipSvg className={"water-mark-svg margin-left-m uddannelseslaege-header-image"}/>
                }
                {(currentUser.CurrentRolle === RolleEnum.Uddannelsesansvarlig ) &&
                <EmblaIllustration illustrationName={"team-leader"}
                                   additionalClasses={"margin-left-m uddannelseslaege-header-image"}/>
                }
                <div className="flex-container-column flex-row-center margin-left-m">
                    <div className="flex-space">
                        {!isMobile &&
                        <h2 className='uddannelseslaege-header-txt'>
                            {UserRoleTitleToString(currentUser.CurrentRolleTitel)}
                        </h2>
                        }
                        {isMobile &&
                        <h3 className='uddannelseslaege-header-txt'>
                            {UserRoleTitleToString(currentUser.CurrentRolleTitel)}
                        </h3>
                        }

                    </div>
                    <div className='uddannelseslaege-header-txt'>
                        {props.VejlederAfdelinger?.length === 1 &&
                        <>
                            {!isMobile &&
                            <>
                                <p className="margin-bottom-xxs large uddannelseslaege-header-txt">
                                    {props.VejlederAfdelinger[0].LaegeInstitutionNavn} {props.VejlederAfdelinger[0].AfdelingNavn && " , " + props.VejlederAfdelinger[0].AfdelingNavn}
                                </p>
                                {props.VejlederAfdelinger[0].PeriodeSlut !== undefined &&
                                  <h5 className="margin-bottom-xxs uddannelseslaege-header-txt">
                                      {Localizer.uddannelseslaegeOverviewPage_VejlederPeriodeEnds() + ": " + props.VejlederAfdelinger[0].PeriodeSlut.dateWithoutTimeFormat(false)}
                                  </h5>
                                }
                            </>
                            }
                            {isMobile &&
                            <>
                                <h5 className="margin-bottom-xxs uddannelseslaege-header-txt">
                                    {props.VejlederAfdelinger[0].LaegeInstitutionNavn} {props.VejlederAfdelinger[0].AfdelingNavn && " , " + props.VejlederAfdelinger[0].AfdelingNavn}
                                </h5>
                                {props.VejlederAfdelinger[0].PeriodeSlut !== undefined &&
                                  <p className="margin-bottom-xxs small uddannelseslaege-header-txt">
                                      {Localizer.uddannelseslaegeOverviewPage_VejlederPeriodeEnds() + ": " + props.VejlederAfdelinger[0].PeriodeSlut.dateWithoutTimeFormat(false)}
                                  </p>
                                }
                            </>
                            }
                        </>
                        }
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div>
                                <span className="badge badge-pill badge-primary padding-xs">
                                </span>
                            <span className="badge badge-pill badge-default padding-xs">
                                </span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}
