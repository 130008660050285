import {SetTitleBar} from "../../core/layout/titleBar/titleBarApi";
import {Localizer} from "infrastructure/localization/localizer";
import {useAppSelector} from "../../app/hooks";
import {forloebReducer, ForloebSliceState} from "../../core/forloebSlice";
import {RoutePaths} from "../../infrastructure/routes";
import {ForloebModel} from "../../core/sharedmodels/forloeb/forloebModel";
import {KompetenceEvaluering} from "./kompetenceEvaluering";
import useKompetenceEvaluering from "./useKompetenceEvaluering";
import React from "react";
import {Loading} from "../../core/components/loading/loading";

export function EditKompetenceEvalueringPage() {
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    const forloebModel = ForloebModel.FromState(forloebSliceState.forloebState);

    SetTitleBar(Localizer.evalueringPage_kompetenceVurdering(), [{ text: Localizer.global_kompetencer(), url: RoutePaths.Kompetencer({uddannelseslaege: forloebModel.brugerId}).url}, { text: forloebModel.GetStedInfo(), url:  ""} ]);

    const { isLoading, kompetenceEvalueringHookValues } = useKompetenceEvaluering();

    return (
        <Loading isLoading={isLoading} text={Localizer.evalueringPage_getEvaluering()}>
            <KompetenceEvaluering hook={kompetenceEvalueringHookValues}/>
        </Loading>

    );
}
