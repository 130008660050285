import {TableAsync} from "../../core/components/table/tableAsync";
import {Localizer} from "../../infrastructure/localization/localizer";
import {nameof} from "ts-simple-nameof";
import {HttpClientService} from "../../services/httpClient/httpClientService";
import {AccountService} from "../../services/account/accountService";
import ReactDOMServer from "react-dom/server";
import {UserProfileAvatar, UserProfileAvatarSizeEnum} from "../../core/components/userProfileAvatar/userProfileAvatar";
import {UserProfileAvatarModel} from "../../core/components/userProfileAvatar/userProfileAvatarModel";
import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../../core/layout/responsive";
import {RoutePaths} from "../../infrastructure/routes";
import {useHistory} from "react-router-dom";
import {LogbogVejledersamtaleApi} from "../../services/api/logbogVejledersamtale/logbogVejledersamtaleApi";
import {
    LogbogKlarmeldtVejledersamtaleResponseModel
} from "../../services/api/logbogVejledersamtale/logbogKlarmeldtVejledersamtaleResponseModel";
import {dateWithoutTimeFormat} from "../../index";
import {setPingReload} from "../bruger/asyncTableSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {forloebReducer, ForloebSliceState} from "../../core/forloebSlice";
import React from "react";
import {NotificationModule} from "ditmer-embla";

const vejledersamtaleApi = new LogbogVejledersamtaleApi(new HttpClientService(), new AccountService())

export const VejledersamtalerKlarmeldt = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const tableId = "vejledersamtalerKlarmeldt";
    const isMobile = useMediaQuery(mobileMaxWidthQuery);
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    const getUserAvatarString = (model: LogbogKlarmeldtVejledersamtaleResponseModel, isMobile: boolean) : string => {
        return ReactDOMServer.renderToString((
            <div className="d-flex">
                { !isMobile &&
                    <UserProfileAvatar
                        userProfileAvatarModel={new UserProfileAvatarModel(model.uddannelseslaegeNavn, model.uddannelseslaegeInitialer, true)}
                        size={UserProfileAvatarSizeEnum.extraSmall}
                    />
                }
                <div className="margin-left-s">
                    {model.uddannelseslaegeNavn}
                </div>
            </div>
        ));
    }

    const renderTableHeaders = () => {
        return (
            <thead>
                <tr>
                    <th data-column-name={nameof<LogbogKlarmeldtVejledersamtaleResponseModel>(x => x.uddannelseslaegeNavn)}
                        data-priority="1">
                        {Localizer.global_uddannelseslaege()}
                    </th>
                    <th data-column-name={nameof<LogbogKlarmeldtVejledersamtaleResponseModel>(x => x.titel)}
                        data-priority="2">
                        {Localizer.global_title()}
                    </th>
                    <th data-column-name={nameof<LogbogKlarmeldtVejledersamtaleResponseModel>(x => x.dato)}
                        data-priority="3">
                        {Localizer.global_dato()}
                    </th>
                    <th data-column-name={nameof<LogbogKlarmeldtVejledersamtaleResponseModel>(x => x.id)}
                        data-priority="1">
                    </th>
                </tr>
            </thead>
        );
    }

    const setupDelagatedOnClickGodkendTid = (cell: Node, model: LogbogKlarmeldtVejledersamtaleResponseModel) => {
        $(cell).on('click', async (event) => {
            event.stopPropagation();

            const uddannelsessted = forloebSliceState.forloebState.afdelingName === null
                ? forloebSliceState.forloebState.laegeInstitutionName
                : forloebSliceState.forloebState.laegeInstitutionName + ", " + forloebSliceState.forloebState.afdelingName

            const godkendtVejledersamtale = await vejledersamtaleApi.godkendVejledersamtale(model.id, uddannelsessted);

            if (godkendtVejledersamtale) {
                dispatch(setPingReload())
                NotificationModule.showSuccess(Localizer.vejledersamtalepage_vejledersamtaleAfholdt(), "");
            }
        });
    }

    const getGodkendTidBtnString = () : string => {
        return ReactDOMServer.renderToString((
            <button type="button"
                    className={"btn btn-success btn-round stop-event "}
                    aria-label="Primary"
            >
                {Localizer.global_afholdt()}
            </button>
        ));
    }

    const getTableColums = (): DataTables.ColumnSettings[] => {
        let columns: DataTables.ColumnSettings[] = [];
        columns = columns.concat([
            {
                data: (data: LogbogKlarmeldtVejledersamtaleResponseModel) => data,
                orderable: true,
                render: (data: LogbogKlarmeldtVejledersamtaleResponseModel) => {
                    return getUserAvatarString(data, isMobile);
                },
            },
            {
                data: (data: LogbogKlarmeldtVejledersamtaleResponseModel) => data.titel,
                orderable: true,
            },
            {
                data: (data: LogbogKlarmeldtVejledersamtaleResponseModel) => data,
                render: (data: LogbogKlarmeldtVejledersamtaleResponseModel) => {
                    return dateWithoutTimeFormat(data.dato);
                },
                orderable: true,
            },
            {
                data: (data: LogbogKlarmeldtVejledersamtaleResponseModel) => data,
                render: () => {
                    return getGodkendTidBtnString();
                },
                orderable: false,
                createdCell: (cell: Node, cellData: LogbogKlarmeldtVejledersamtaleResponseModel, rowData: any, row: number, col: number) => {
                    setupDelagatedOnClickGodkendTid(cell, cellData);
                },
            }
        ]);

        return columns;
    };

    const rowClickAction = (data: LogbogKlarmeldtVejledersamtaleResponseModel) => {
        history.push(RoutePaths.Vejledning({
            uddannelseslaege: data.uddannelseslaegeId
        }).url)
    }

    return (
        <>
            <TableAsync
                tableIdentifier={tableId}
                tableHeader={Localizer.global_vejledersamtaler()}
                renderTableHead={renderTableHeaders}
                asyncUrl={vejledersamtaleApi.getKlarmeldteVejledersamtalerUrl()}
                columns={getTableColums()}
                rowClickFunction={rowClickAction}
            />
        </>
    );
}
