import { DropdownOption } from "core/components/dropdown/dropdown";

const CreateYearDropdownOptionsNYearsFromNow = (yearsFromNow: number = 10) => {
    const year = new Date().getFullYear() - yearsFromNow;
    const years = Array.from(new Array(20), (val, index) => index + year);

    return years.map(x => (
        {
            label: x.toString(),
            value: x.toString()
        } as DropdownOption<string>
    ));
}

export default CreateYearDropdownOptionsNYearsFromNow;