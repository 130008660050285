export class DataTableHeader{
    constructor(title: string, orderable: boolean) {
        this.title = title;
        this.orderable = orderable;
    }
    title: string;
    orderable: boolean;
}


