import {LogbogNotatApi} from "../../../../services/api/logbogNotat/logbogNotatApi";
import {HttpClientService} from "../../../../services/httpClient/httpClientService";
import {NotatType} from "../../../sharedmodels/notat/notatType";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {useState} from "react";
import {ReactFileUploaderFile} from "../../../components/fileUpload/reactFileUploader";
import {setSlideinState, SlideInStateEnum} from "../../../components/slideIn/slideInSlice";
import {ValidationForm} from "../../../components/validation/components/validationForm";
import {HandleError} from "../../../infrastructure/errors/errorBoundary";
import {BasicNotatCrudFields} from "../basicNotatCrudFields";
import {ValidationFormSubmitButton} from "../../../components/validation/components/validationFormButton";
import {Localizer} from "../../../../infrastructure/localization/localizer";
import {forloebReducer, ForloebSliceState} from "../../../forloebSlice";
import {FileMetadata} from "../../../sharedmodels/fileMetaData/fileMetaData";
import {
    BuildNewCrudNotatModel,
    crudNotatValidationSchema,
    NotatCrudModel
} from "../../../sharedmodels/notat/notatCrudModel";
import {AccountService} from "../../../../services/account/accountService";


const notatApi = new LogbogNotatApi(new HttpClientService(), new AccountService())

export interface CreateNotatProps {
    modalId: string;
    notatType: NotatType;
}

export function CreateNotat(props: CreateNotatProps) {
    const dispatch = useAppDispatch();
    const [createTekstNotatFailed, setCreateNotatFailed] = useState(false);
    const [uploadedFilesState, setUploadedFilesState] = useState<ReactFileUploaderFile[]>([]);
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    const createNotat = async (inputModel: NotatCrudModel) => {
        dispatch(setSlideinState({ state: SlideInStateEnum.ActionOngoing, slideInId: props.modalId }))
        const newTekstNotat = await notatApi.createNotat(inputModel, uploadedFilesState.map(tf => tf.fileMetadata.id));

        if (newTekstNotat) {
            setCreateNotatFailed(false);
            dispatch(setSlideinState({ state: SlideInStateEnum.Closing, slideInId: props.modalId }))
        }
    }

    const fileUploadedCallback = async (uploadedFiles: ReactFileUploaderFile[]) => {
        setUploadedFilesState(arr => [...arr, ...uploadedFiles]);
    }

    const fileDeletedCallback = (fileMetaData: FileMetadata) => {
        let updatedFileUploadedState = [...uploadedFilesState];
        let deleteIndex = updatedFileUploadedState.findIndex(file => file.fileMetadata.id === fileMetaData.id);

        updatedFileUploadedState.splice(deleteIndex, 1);
        setUploadedFilesState(updatedFileUploadedState);
    }

    const render = (
        <ValidationForm
            initialValues={BuildNewCrudNotatModel([
                forloebSliceState.forloebState.id],
                props.notatType,
                forloebSliceState.forloebState.brugerId)}
            validationSchema={crudNotatValidationSchema}
            onSubmit={async (values) => {
                await createNotat(values)
                    .catch(err => {
                        HandleError(err);
                        setCreateNotatFailed(true);
                        dispatch(setSlideinState({ state: SlideInStateEnum.Opened, slideInId: props.modalId }));
                    });
            }}>
            {(formik) => (
                <>
                    <BasicNotatCrudFields
                        modalId={props.modalId} formik={formik}
                        notatUserId={forloebSliceState.forloebState.brugerId}
                        fileUploadedCallbackOverride={fileUploadedCallback}
                        fileDeletedCallbackOverride={fileDeletedCallback}
                    />

                    <div className="modal-actions">
                        {createTekstNotatFailed &&
                            <div className="form-group margin-bottom-m">
                                <div className="field-validation-error">{Localizer.notatPage_notatCreateFailed()}</div>
                            </div>}

                        <ValidationFormSubmitButton formikProps={formik}>
                            {Localizer.notatPage_createNotat()}
                        </ValidationFormSubmitButton>
                    </div>
                </>
            )}
        </ValidationForm>
    )

    return <>{render}</>
}
