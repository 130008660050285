import { PropsWithChildren } from "react";

type InformationPageSectionProps = {
  title: string;
  contentText?: string;
}

const InformationPageSection = ({ title, contentText, children }: PropsWithChildren<InformationPageSectionProps>) => {
  return (
      <div className="flex-container-column margin-top-m">
          <h3>
              {title}
          </h3>
          {contentText && 
              <p>
                  {contentText}
              </p>
          }
          {children}
      </div>
  );
}

export default InformationPageSection;
