import React, {useRef} from 'react'
import {LaegekursusCard} from "./laegekursusCard";
import {LaegekursusModel} from "../../../services/api/laegekursus/laegekursusModel";
import {AccountService} from 'services/account/accountService';
import {DeletedLaegekursusCard} from "./deletedKurserComponents/deletedLaegekursusCard";
import {Localizer} from "../../../infrastructure/localization/localizer";
import { useAppSelector } from 'app/hooks';
import { forloebReducer, ForloebSliceState } from 'core/forloebSlice';
import { isUserAllowedToFillApproval } from './editLaegekursus';

type KurserListProps = {
    kurser?: LaegekursusModel[];
    deletedKurser?: LaegekursusModel[];
    kurserUpdatedCallback: () => void;
    gruppeKategoriTekst?: string;
    laegekursusGruppeGodkendt: boolean;
    forloebId?: string;
    brugerMaalbeskrivelseId?: string;
}

export function KurserList(props: KurserListProps) {
    const _currentUser = useRef(new AccountService().getUser());
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    return (
        <>
            <div className="card-body padding-0">
                {props.kurser &&
                    props.kurser?.map((kursus, index) => {
                        return (
                            <React.Fragment key={kursus.id}>
                                <div className="card-list" key={kursus.id}>
                                    <LaegekursusCard laegekursus={kursus}
                                                     forloebId={props.forloebId}
                                                     laegekursusEditCallback={() => props.kurserUpdatedCallback()}
                                                     gruppeKategoriTekst={props.gruppeKategoriTekst?.toLowerCase() ?? Localizer.forloebOverviewPage_redigerFilter()}
                                                     laegekursusGruppeGodkendt={props.laegekursusGruppeGodkendt}
                                                     isReadonly={_currentUser.current.IsSekretaer() || _currentUser.current.IsKonsulentForLaegeligVidereuddannelse()}
                                                     hideUdfyldDetails={_currentUser.current.IsKonsulentForLaegeligVidereuddannelse()}
                                                     renderApproveButton={forloebSliceState.forloebState.brugerId !== _currentUser.current.UserId && isUserAllowedToFillApproval(_currentUser.current, kursus.gruppeType)}
                                                     brugerMaalbeskrivelseId={props.brugerMaalbeskrivelseId}
                                    />
                                </div>
                                <div className={"card-list-border"}/>
                            </React.Fragment>
                        )
                    })
                }
                {props.deletedKurser &&
                    props.deletedKurser?.map((kursus, index) => {
                        return (
                            <React.Fragment key={kursus.id}>
                                <div className="card-list" key={kursus.id}>
                                    <DeletedLaegekursusCard laegekursus={kursus}
                                                            laegekursusRestoredCallback={() => props.kurserUpdatedCallback()}/>
                                </div>
                                <div className={"card-list-border"}/>
                            </React.Fragment>
                        )
                    })
                }
            </div>
        </>
    );
}
