import { CookieInformationData } from "./cookieInformationData";
import { Localizer } from "infrastructure/localization/localizer";

type CookiesInformationRowProps = {
  cookieData: CookieInformationData;
}

const CookiesInformationRow = ({ cookieData }: CookiesInformationRowProps) => {
  return (
    <tr>
      <td>{cookieData.name}</td>
      <td>{cookieData.provider}</td>
      <td>{cookieData.purpose}</td>
      <td>{cookieData.expiry}</td>
    </tr>
  )
}

type CookiesInformationCookieListProps = {
  cookiesData: CookieInformationData[];
}

const CookiesInformationCookieList = ({ cookiesData }: CookiesInformationCookieListProps) => {
  return (
    <table className="table table-hover table-responsive">
      <thead>
        <th>{Localizer.cookiesPage_cookiesListHeader_name()}</th>
        <th>{Localizer.cookiesPage_cookiesListHeader_provider()}</th>
        <th>{Localizer.cookiesPage_cookiesListHeader_purpose()}</th>
        <th>{Localizer.cookiesPage_cookiesListHeader_expiry()}</th>
      </thead>
      <tbody>
        {cookiesData.sortBy(x => x.name).map((cookieEntry) => (
          <CookiesInformationRow 
            cookieData={cookieEntry} 
          />
        ))}
      </tbody>
    </table>
  )
}

export default CookiesInformationCookieList;
