import {Localizer} from "infrastructure/localization/localizer";
import React from "react";
import RegionerMultiDropdown from "core/components/dropdown/components/regionerForSelectDropdown";
import ForSelectDropdown from "core/components/dropdown/components/forSelectDropdown";
import useStamdataApi from "core/hooks/useStamdataApi";
import { useDataadministrationContext } from "./dataadministrationOverviewContextProvider";
import { SlideInStateEnum, setSlideinState } from "core/components/slideIn/slideInSlice";
import { useDispatch } from "react-redux";
import DynamicButton from "core/components/button/dynamicButton";
import { EmblaIcons } from "core/emblaIcons";

type DataadministrationFilterProps = {
    modalId: string;
}

const DataadministrationFilter = ({modalId}: DataadministrationFilterProps) => {
    const { stamdataSpecialeApi } = useStamdataApi();
    const { filterReducer } = useDataadministrationContext();
    const dispatch = useDispatch();

    const specialerSelected = (specialer: string[]) => {
        filterReducer.dispatch({
            ...filterReducer.state,
            specialer: specialer
        });
    }

    const regionerSelected = (regioner: string[]) => {
        filterReducer.dispatch({
            ...filterReducer.state,
            regioner: regioner
        });
    }
       
    const apply = () => {
        dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: modalId}));
    }

    return (
        <div>
            <div className="margin-bottom-m">
                <ForSelectDropdown 
                    onChangeCallback={selectedSpecialer => specialerSelected(selectedSpecialer.map(x => x.value))}
                    apiFetchPromise={() => stamdataSpecialeApi.getSpecialerSelect()}
                    initialSelectedOption={filterReducer.state.specialer}
                    placeholder={Localizer.forloebOverviewPage_chooseSpeciale()}
                    title={Localizer.forloebOverviewPage_speciale()}
                    isMulti/>
            </div>

            <div className="margin-bottom-m">
                <RegionerMultiDropdown
                    onChangeCallback={selectedRegionResult => regionerSelected(selectedRegionResult.regionIds ?? [])}
                    initialSelectedOption={filterReducer.state.regioner}
                    placeholder={Localizer.forloebOverviewPage_region()}
                    title={Localizer.forloebOverviewPage_region()}/>
            </div>

            <div className="modal-actions">
                <div className="margin-right-m">
                    <DynamicButton 
                        disableMobileClasses 
                        isPrimary 
                        onClick={() => apply()}>
                        {Localizer.forloebOverviewPage_anvendFilter()}
                    </DynamicButton>
                </div>
            </div>
        </div>
    )
}

export default DataadministrationFilter;
