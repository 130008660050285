import {Localizer} from "../../../infrastructure/localization/localizer";
import {EmblaIcon} from "../../../core/components/emblaIcon/emblaIcon";
import {Score} from "../../../core/components/score/score";
import React, {useState} from "react";
import {LogbogSpoergsmaalResponseModel} from "../../../services/api/logbogEvaluering/logbogSpoergsmaalResponseModel";
import {EvalueringGruppeScoreModel} from "../../../services/api/evalueringStatistik/EvalueringGruppeScoreModel";
import {SingleQuestionComponent} from "./questionComponent";
import {ScoreTypeEnum} from "../../../core/componentsPage/score/scoreTypeEnum";

interface QuestionGroupComponentProps {
    evalueringGruppeScoreModel?: EvalueringGruppeScoreModel;
    spoergsmaal?: LogbogSpoergsmaalResponseModel[];
}

export function QuestionGroupComponent(props: QuestionGroupComponentProps) {

    const [showContent, setShowContent] = useState(false);


    return (
        <>
            <div className={"padding-bottom-m flex-space padding-top-m clickable-row"}
                 onClick={() => setShowContent(!showContent)}
                 data-toggle="collapse"
                 data-target={"#question"+props.evalueringGruppeScoreModel?.svarGruppeEnum}>
                <h4>{Localizer.evalueringPage_spoergsmaalsgruppe()} {props.evalueringGruppeScoreModel?.svarGruppeEnum ?? 0}</h4>
                <div className={"flex-container"}>
                    <div className={"margin-right-m"}>
                        <Score svarVaerdi={props.evalueringGruppeScoreModel?.averageScore} withDecimal={true} showText={false} scoreType={ScoreTypeEnum.ForloebEvalueringScore}/>
                    </div>

                        {showContent
                            ? <EmblaIcon iconName={"arrow-up"} />
                            : <EmblaIcon iconName={"arrow-down"} />}

                </div>
            </div>
            <div className={"collapse border-top"} id={"question"+props.evalueringGruppeScoreModel?.svarGruppeEnum}>
                {props.spoergsmaal &&
                <>
                    {props.spoergsmaal?.map((s, i) => {
                        return (
                            <div>
                                <SingleQuestionComponent questionNumber={s.sortOrder} questionTitle={s.titel} questionText={s.tekst} />
                            </div>
                        )
                    })}
                </>
                }

            </div>
        </>
    )
}
