import { Loading } from "core/components/loading/loading";
import classNames from "classnames";
import Container from "core/components/container/container";
import { ForloebOverviewEmptyCard } from "pages/forloeb/forloebOverviews/forloebOverviewEmptyCard";
import { Localizer } from "infrastructure/localization/localizer";
import BrevMarkdownPreviewer from "./brevMarkdownPreviwer";
import useBrevMergeFields from "../hooks/useBrevMergeField";
import "./BrevPreview.scss";

const EmptyPreview = () =>  (
    <ForloebOverviewEmptyCard emptyText={Localizer.letterPage_EmptyPreviwerInfo()}>
        <p>{Localizer.letterPage_EmptyPreviwerInfo()}</p>
    </ForloebOverviewEmptyCard>
);

const BrevPreview = ({uddannelseslaegeId, markdown, id="id"} : {uddannelseslaegeId: string, markdown: string, id?: string}) => {

    const {mergedMarkdown, isLoading} = useBrevMergeFields(uddannelseslaegeId, markdown);
    
    return (
        <Container>

            <Container.Header childrenIsHeaderActions titleConfig={{ title: Localizer.preview() }}/>

            <Container.Body additionalClasses="pdf-preview-container">
                    <div className="pdf-preview margin-top-m margin-bot-m styled-tables">
                        <Loading
                            isLoading={isLoading}
                            text={isLoading ? Localizer.letterPage_GeneratingPreview() : undefined}
                            spinnerClasses={classNames(isLoading && "margin-top-l margin-left-l padding-bottom-l")}
                        >
                                <div className="pdf-content">
                                    {mergedMarkdown &&
                                        <BrevMarkdownPreviewer
                                            id={`brev-previewer-${id}`}
                                            markdown={mergedMarkdown}
                                        />
                                    }

                                    {!mergedMarkdown &&
                                        <div className="margin-top-m">
                                            <EmptyPreview />
                                        </div>
                                    }
                                </div>
                        </Loading>
                    </div>

            </Container.Body>

        </Container>
    );
}

export default BrevPreview;
