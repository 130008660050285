import {PdfDetailedUserHeaderComponent} from "./pdfDetailedUserHeaderComponent";
import PdfHeaderRow from "../pdfHeaderRow";
import {Localizer} from "../../../../infrastructure/localization/localizer";

type PdfAttestationHeaderComponentProps = {
    brugerId: string;
    maalbeskrivelse?: string;
}

export function PdfAttestationHeaderComponent(props: PdfAttestationHeaderComponentProps) {

    return (
        <div className={"card shadow-none"}>
            <div className={"card-body flex-container-column flex-space"}>
                <PdfDetailedUserHeaderComponent brugerId={props.brugerId}/>
                <PdfHeaderRow>
                    <PdfHeaderRow.Entry
                        label={Localizer.global_maalbeskrivelse()}
                        value={props.maalbeskrivelse}
                    />
                </PdfHeaderRow>
            </div>
        </div>
    );
}
