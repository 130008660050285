import { DropdownOption } from "core/components/dropdown/dropdown";
import { useState } from "react";
import { Localizer } from "infrastructure/localization/localizer";
import BrevForloebMergeFields, { BrevForloebMergeFieldsType, allBrevForloebMergeFieldArray } from "../shared/brevForloebMergeFieldsType";

const translateBrevMergeField = (type: BrevForloebMergeFieldsType) => {
    switch (type) {
        case BrevForloebMergeFields.Forloeb_All:
            return Localizer.brevMergeFieldsType_forloeb_all();
        case BrevForloebMergeFields.Forloeb_Hu:
            return Localizer.brevMergeFieldsType_forloeb_hu();
        case BrevForloebMergeFields.Forloeb_Kbu:
            return Localizer.brevMergeFieldsType_forloeb_kbu();
        case BrevForloebMergeFields.Forloeb_Intro:
            return Localizer.brevMergeFieldsType_forloeb_intro();
        default:
            return "";
    }
};

const dropdownOptions: DropdownOption<string>[] = allBrevForloebMergeFieldArray.map(x => ({ label: translateBrevMergeField(x), value: x }));

const useBrevForloebMergeFieldDropdownOptions = (initialOption?: BrevForloebMergeFieldsType) => {

    const [selectedOption, setSelectedOption] = useState<BrevForloebMergeFieldsType | undefined>(initialOption);

    return {
        selectedOption,
        setSelectedOption,
        dropdownOptions
    }
}

export default useBrevForloebMergeFieldDropdownOptions;