import {useState} from "react";
import {useAppDispatch} from "app/hooks";
import {Localizer} from "infrastructure/localization/localizer";
import useEffectAsync from "infrastructure/effect";
import {AccountService} from "services/account/accountService";
import {HttpClientService} from "../../services/httpClient/httpClientService";
import {UserProfile} from "../../core/components/userProfile/userProfile";
import {Loading} from "../../core/components/loading/loading";
import {SetTitleBar} from "../../core/layout/titleBar/titleBarApi";
import {LogbogUserInfoApi} from "../../services/api/logbogUser/logbogUserInfoApi";
import {UserProfileModel} from "../../core/components/userProfile/userProfileModel";

const userService = new AccountService();
const userApi = new LogbogUserInfoApi(new HttpClientService(), userService);

export function UserProfilePage() {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(new UserProfileModel());

    SetTitleBar(Localizer.profilepage_myProfile(),
        [{text: Localizer.global_user(), url: ""}]);

    useEffectAsync(async () => {
        const result = await userApi.getUserInfoWithRoles();
        setUser(result);
        setIsLoading(false);
    }, [dispatch])

    return (
        <Loading isLoading={isLoading} text={Localizer.profilepage_getProfil()}>
            <UserProfile user={user}/>
        </Loading>
    );
}
