import {useEffect, useState} from "react";
import {useAppSelector} from "../../../app/hooks";
import {forloebReducer, ForloebSliceState} from "../../../core/forloebSlice";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {Loading} from "../../../core/components/loading/loading";
import {LineChart, LineChartColor, LineChartItem} from "../../../core/components/charts/lineChart";
import {ForloebOverviewCard} from "./forloebOverviewCard";
import {ForloebOverviewEmptyCard} from "./forloebOverviewEmptyCard";
import {useRouteMatch} from "react-router-dom";
import {RoutePaths} from "../../../infrastructure/routes";
import {ActionIcon} from "../../../core/components/actionIcon/actionIcon";
import {LaegekursusGruppeModel} from "../../../services/api/laegekursus/laegekursusGruppeModel";
import {LaegekursusStatusEnum} from "../../../core/sharedmodels/kursus/laegekursusStatusEnum";
import {LaegekursusModel} from "../../../services/api/laegekursus/laegekursusModel";
import useLogbogApi from "core/hooks/useLogbogApi";
import { UddannelseTypeEnum } from "core/sharedmodels/stilling/uddannelseTypeEnum";

export type ForloebKurserOverviewProps = {
    showRightHeaderContent?: boolean
}

export const ForloebKurserOverview = ({showRightHeaderContent}: ForloebKurserOverviewProps) => {

    const { logbogLaegekursusGruppeApi } = useLogbogApi();

    const [kursusGrupper, setKursusGrupper] = useState<LaegekursusGruppeModel[]>([]);
    const [kurser, setKurser] = useState<LaegekursusModel[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const routeMatch = useRouteMatch();

    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;
    const uddannelseslaegeId = (routeMatch.params as { id: string }).id;

    useEffect(() => {
        const fetchKursusGrupper = async () => {
            const kursusGrupper = await logbogLaegekursusGruppeApi.getKursusGrupperByBrugerKursusraekke(forloebSliceState.forloebState.brugerKursusraekkeId)
            setKurser(kursusGrupper.selectMany(kg => kg.kurser));
            setKursusGrupper(kursusGrupper);
            setIsLoading(false);
        }

        fetchKursusGrupper();
    }, [forloebSliceState.forloebState.brugerKursusraekkeId, logbogLaegekursusGruppeApi, forloebSliceState.forloebState.id]);


    const generateChartItems = (): LineChartItem[] => {
        const udfyldtCount = kurser.filter(k => k.status === LaegekursusStatusEnum.Udfyldt).length;
        const klarmeldingCount = kurser.filter(k => k.status === LaegekursusStatusEnum.Klarmeldt).length;
        const godkendtCount = kurser.filter(k => k.status === LaegekursusStatusEnum.Godkendt).length + kursusGrupper.filter(kg => kg.isGroupWithSamletGodkendelse() && kg.harSamletGodkendelse).length;

        return [
            {count: udfyldtCount, color: LineChartColor.info, title: Localizer.kursusPage_udfyldt()},
            {count: klarmeldingCount, color: LineChartColor.warning, title: Localizer.global_klarmeldt()},
            {count: godkendtCount, color: LineChartColor.success, title: Localizer.global_godkendt()},
        ];
    }

    const rightHeaderContent = (
        <div className="d-flex">
            <ActionIcon iconName="arrow-right" routeUrl={RoutePaths.Kurser([uddannelseslaegeId]).url}/>
        </div>
    )

    const overviewEmptyState = (
        <>
            <h4 className="margin-bottom-m">{Localizer.global_kurser()}</h4>

            <ForloebOverviewEmptyCard>
                <p>{Localizer.forloebpage_kurserOverviewEmptyInfo()}</p>
            </ForloebOverviewEmptyCard>
        </>
    )


    const baseTitle = `${Localizer.global_kurser()} (${kursusGrupper.flatMap(k => k.kurser).length})`;
    const fullTitle = () => `${baseTitle}, ${Localizer.kursusPage_samletGodkendelser()} (${kursusGrupper.filter(kg => kg.isGroupWithSamletGodkendelse()).length})`;

    return (
        <ForloebOverviewCard
            title={forloebSliceState.forloebState.uddannelseType === UddannelseTypeEnum.Hoveduddannelse ? fullTitle() : baseTitle}
            rightHeaderContent={showRightHeaderContent ? rightHeaderContent : <></>}>

            <Loading isLoading={isLoading} text={Localizer.kursusPage_getKurser()}>
                {kursusGrupper?.length > 0 &&
                    <LineChart items={generateChartItems()}/>
                }

                {kursusGrupper?.length < 1 &&
                    overviewEmptyState
                }
            </Loading>

        </ForloebOverviewCard>
    );
}
