import React from 'react'
import {KurserList} from "../kurserList";
import {LaegekursusModel} from "../../../../services/api/laegekursus/laegekursusModel";
import {Localizer} from "../../../../infrastructure/localization/localizer";

interface DeletedLaegekurserCardProps {
    kurserUpdatedCallback: () => void;
    deletedLaegekurserForUser: LaegekursusModel[];
    brugerMaalbeskrivelseId: string;
}

export function DeletedLaegekurserCard(props: DeletedLaegekurserCardProps) {
    return (
        <div className="card">
            <div className="card-header">
                <div className="flex-space flex-column flex-grow">
                    <div className="flex-align-c">
                        <h3 className="card-title">{Localizer.kursusPage_slettedeKurser()}</h3>
                    </div>
                </div>
            </div>
            <div className="card-body padding-0">
                {props.deletedLaegekurserForUser?.length > 0
                    ?
                    <KurserList deletedKurser={props.deletedLaegekurserForUser}
                                kurserUpdatedCallback={() => props.kurserUpdatedCallback()}
                                laegekursusGruppeGodkendt={false}
                                brugerMaalbeskrivelseId={props.brugerMaalbeskrivelseId}
                    />
                    :
                    <div className="card-body padding-0">
                        <div className="card-list">
                            <div className="flex-column ">
                                <div className="flex-align-c ">
                                    <h5>{Localizer.kursusPage_ingenKurserOprettet()}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>

    );
}
