import React from 'react'
import {Localizer} from '../../../infrastructure/localization/localizer';
import {ForloebModel} from "../../sharedmodels/forloeb/forloebModel";
import {UddannelseTypeEnum} from "../../sharedmodels/stilling/uddannelseTypeEnum";
import {ForloebCardBadges} from "./forloebCardBadges";

interface ForloebCardInfoProps {
    forloeb : ForloebModel
}

export function ForloebCardInfo(props: ForloebCardInfoProps) {
    return (
        <>
            <div className='forloeb-header-txt'>
                <h5 className="margin-bottom-xxs">{props.forloeb.GetStedInfo()}</h5>
                <div>
                    {props.forloeb.specialeName}, {UddannelseTypeEnum[props.forloeb.uddannelseType]}
                </div>
                <div>{props.forloeb.stillingNummer}</div>
            </div>
            <div className='forloeb-header-txt d-flex' key={props.forloeb.endDate.toString()}>
                {props.forloeb.startDate.dateWithoutTimeFormat(false)} - {props.forloeb.endDate.dateWithoutTimeFormat(false)}
                {props.forloeb.DaysLeft() <= 30 && props.forloeb.DaysLeft() >= 0 &&
                    <div className='margin-left-s text-lowercase'>
                        <p>({props.forloeb.DaysLeft()} {Localizer.global_daysLeft()})</p>
                    </div>
                }
            </div>
            <ForloebCardBadges forloebSkalEvalueres={props.forloeb.skalIndgaaIEvalueringsstatistik}
                               endDate={props.forloeb.endDate}
                               fravaer={props.forloeb.erFravaer}
                               fravaerskodeEnum={props.forloeb.fravaerskodeEnum}
                               manueltAfsluttet={props.forloeb.manueltAfsluttet}
                               startDate={props.forloeb.startDate}
                               irrelevant={props.forloeb.irrelevant}
                               evalueretDato={props.forloeb.evalueretDato}
                               godkendtDato={props.forloeb.godkendtDen}/>
        </>
    )
}
