import illustrationSpriteSvg from "content/illustrations/sprite.symbol.svg";

export function EmblaIllustration({illustrationName, additionalClasses} : {illustrationName: string, additionalClasses?: string}) {
    const renderEmblaIllustration = () => {
        if(illustrationName) {
            return <span>
            <svg className={"embla-illustration " + additionalClasses} aria-hidden="true"
                 xmlns="http://www.w3.org/2000/svg">
                    <use href={illustrationSpriteSvg + "#" + illustrationName}/>
                </svg>
            </span>
        }
        return;
    };

    return (
        <>
            { renderEmblaIllustration() }
        </>
    );
}
