import {ObligatoriskDokumentationStatusType} from "../../../services/api/logbogObligatoriskDokumentation/obligatoriskDokumentationStatusType";
import {ObligatoriskDokumentationResponseModel} from "../../../services/api/logbogObligatoriskDokumentation/obligatoriskDokumentationResponseModel";
import {ObligatoriskDokumentationTypeEnum} from "./obligatoriskDokumentationTypeEnum";

export class ObligatoriskDokumentationModel {
    public uddannelseId: string;
    public obligatoriskDokumentationNavn: string;
    public obligatoriskDokumentationStatus: ObligatoriskDokumentationStatusType;
    public obligatoriskDokumentationType : ObligatoriskDokumentationTypeEnum;
    public laegeKursusgruppeId?: string;
}

export function FromResponseModel(responseModel: ObligatoriskDokumentationResponseModel) : ObligatoriskDokumentationModel {
    const model = new ObligatoriskDokumentationModel();
    model.uddannelseId = responseModel.uddannelseId;
    model.obligatoriskDokumentationNavn = responseModel.obligatoriskDokumentationNavn;
    model.obligatoriskDokumentationStatus = responseModel.obligatoriskDokumentationStatus;
    model.obligatoriskDokumentationType = responseModel.obligatoriskDokumentationType;
    model.laegeKursusgruppeId = responseModel.laegeKursusgruppeId;

    return model;
}
