import {EvalueringsstatistikPageConstants} from "./components/EvalueringsstatistikPageConstants";

export class EvalueringsstatistikPaginationModel {
    constructor( totalCount: number, elementsToSkip?: number, pageLength?: number) {
        this.elementsToSkip = elementsToSkip ?? 0;
        this.pageLength = pageLength ?? EvalueringsstatistikPageConstants.pageSizeSelectOptions[0];
        this.totalCount = totalCount;
    }

    elementsToSkip: number;
    pageLength: number;
    totalCount: number;

}
