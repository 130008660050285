import ContextMenu from "core/components/contextMenu/contextMenu";
import LeftMenuLink from "./leftMenuLink";
import { MenuLinkModel } from "../shared/MenuLinkProps";
import useScreenResolution from "core/hooks/useScreenResolution";

export type DynamicMenuProps = {
    menuLinks: Array<MenuLinkModel>;
    showNotifications?: boolean;
}

export function DynamicMenu({menuLinks, showNotifications}: DynamicMenuProps) {

    const { isMobile } = useScreenResolution();

    const MaxAmountBeforeContextMenu = 4;
    const MaxToShowInBottomMenu = MaxAmountBeforeContextMenu - 1;

    return (
        <>
            {!isMobile &&
                menuLinks.map((e, i) => {
                    return (
                        <LeftMenuLink
                            key={e.MenuLinkProps.linkText + i}
                            emblaIconName={e.MenuLinkProps.emblaIconName}
                            linkText={e.MenuLinkProps.linkText}
                            routePath={e.MenuLinkProps.routePath}
                            routePathsToHighlight={e.MenuLinkProps.routePathsToHighlight ?? [e.MenuLinkProps.routePath]}
                            showNotification={showNotifications && e.MenuLinkProps.enableShowNotification && e.MenuLinkProps.showNotification}
                            showNotificationAmount={e.MenuLinkProps.showNotificationAmount}
                            linkState={e.MenuLinkProps.linkState}
                        />
                    )
                })
            }

            {isMobile &&
                <div className={"flex-container"}>
                    {(menuLinks.length <= MaxAmountBeforeContextMenu ? menuLinks : menuLinks.slice(0, MaxToShowInBottomMenu)).map(e =>
                        <LeftMenuLink
                            emblaIconName={e.MenuLinkProps.emblaIconName}
                            linkText={e.MenuLinkProps.linkText}
                            routePath={e.MenuLinkProps.routePath}
                            routePathsToHighlight={e.MenuLinkProps.routePathsToHighlight ?? [e.MenuLinkProps.routePath]}
                            showNotification={showNotifications && e.MenuLinkProps.enableShowNotification && e.MenuLinkProps.showNotification}
                            showNotificationAmount={e.MenuLinkProps.showNotificationAmount}
                            linkState={e.MenuLinkProps.linkState}
                            disabled={!e.ClickableOnMobile}
                        />
                    )}

                    {menuLinks.length > MaxAmountBeforeContextMenu &&
                        <div className={"padding-right-s bottom-menu-contex-menu-wrapper"}>
                            <ContextMenu border={true} additionalChildrenWrapperClasses="dropdown-menu-padding-0">
                                {menuLinks.slice(MaxToShowInBottomMenu, menuLinks.length).map((e, i) =>
                                    <ContextMenu.Item
                                        key={i}
                                        emblaIconName={e.MenuLinkProps.emblaIconName}
                                        text={e.MenuLinkProps.linkText}
                                        linkRoutePath={e.MenuLinkProps.routePath}
                                        shouldRender={e.ShouldRender ? e.ShouldRender() : undefined}
                                        showNotification={showNotifications && e.MenuLinkProps.enableShowNotification && e.MenuLinkProps.showNotification}
                                        showNotificationAmount={e.MenuLinkProps.showNotificationAmount}
                                        clickableOnMobile={e.ClickableOnMobile}
                                    />
                                )}
                            </ContextMenu>
                        </div>
                    }
                </div>
            }
        </>
    );
}
