import React, {ReactNode, useEffect, useState} from "react";
import {EmblaIcon} from "../emblaIcon/emblaIcon";
import {Score} from "../score/score";
import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../../layout/responsive";
import {ScoreTypeEnum} from "../../componentsPage/score/scoreTypeEnum";
import useScrollTo from "../../hooks/useScrollTo";
import {ScoreModel} from "../../sharedmodels/evaluering/scoreModel";
import {EmblaIcons} from "../../emblaIcons";
import Tooltip from "../tooltips/Tooltip";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {KompetenceSpoergsmaalModel} from "../../sharedmodels/evaluering/kompetenceSpoergsmaalModel";

export type EvalueringCollapseProps = {
    children: ReactNode;
    title: string;
    id: string;
    expanded: boolean;
    showCircle?: boolean;
    toggleCallback?: (id: string, isExpanded: boolean) => void
    additionalClasses?: string;
    score?: ScoreModel,
    scoreType: ScoreTypeEnum;
    feedbackSpoergsmaal?: KompetenceSpoergsmaalModel[];
}

export const EvalueringCollapse = ({score, feedbackSpoergsmaal, ...props}: EvalueringCollapseProps) => {
    const IsMobile = useMediaQuery(mobileMaxWidthQuery);
    const [expand, setExpand] = useState<boolean>();
    const [isExpanded, setIsExpanded] = useState<boolean>();

    const { scrolltoRef } = useScrollTo<HTMLDivElement>(isExpanded ?? false, { behavior: 'smooth', block: 'start' });

    const minScore = 0;

    useEffect(() => {
        setExpand(props.expanded)

        let contentElement = $("#" + props.id);

        //Dette er for at hooke op på event, som triggeres når collapsetab'en er expanded. For at kunne bruge scroll-to efter collapse-tab'en er helt udfoldet...
        contentElement.on("shown.bs.collapse", () => {
            setIsExpanded(!props.expanded);
        });

        if (!props.expanded)
            contentElement.collapse("hide")
        else
            contentElement.collapse("show")
    }, [props.expanded, props.id])

    const toggleExpanded = () => {
        const newIsExpanded = !expand;
        setExpand(newIsExpanded)

        let contentElement = $("#" + props.id);
        contentElement.collapse("toggle");

        if (props.toggleCallback) {
            props.toggleCallback(props.id, newIsExpanded)
        }
    }

    return (
        <React.Fragment>
            <div className={`d-flex flex-row card-header ${props.additionalClasses ? props.additionalClasses : ""}`}
                role="button"
                data-toggle="collapse"
                aria-expanded={expand}
                aria-controls={props.id}
                onClick={toggleExpanded}>
                <h4 style={{ scrollMarginTop: IsMobile ? '125px' : '165px' }} ref={scrolltoRef} className={`card-header-text-padding ${IsMobile && !expand ? "text-truncate" : ""}`}>
                    {props.title}
                </h4>
                <div className="d-flex flex-align-r">
                    <div className="d-flex margin-right-xs">
                        { feedbackSpoergsmaal !== undefined &&
                            <div className="flex-align-c padding-tbr-s">
                                <EmblaIcon iconName={EmblaIcons.Dialog}/>
                                {`${feedbackSpoergsmaal.filter(x => x.evalueringSvar.length > 0)?.length}/${feedbackSpoergsmaal.length}`}
                            </div>
                        }
                        {(score?.scoreValue || score?.notRelevant)
                            ?
                            <Score showText={false} svarVaerdi={score?.notRelevant ? minScore : score?.scoreValue}
                                   scoreType={props.scoreType}/>
                            :
                            score?.allQuestionsHasAtleastOneAnswer &&
                            <div className="flex-align-c padding-tbr-s">
                                <div className={"square questions-answered"}>
                                    <EmblaIcon
                                        additionalClasses={"white"}
                                        iconName={EmblaIcons.Success}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                    <div className={`${props?.showCircle ?? "round"}`}>
                        {expand &&
                            <EmblaIcon iconName="arrow-up"/>
                        }
                        {!expand &&
                            <EmblaIcon iconName="arrow-down"/>
                        }
                    </div>
                </div>

            </div>

            <div id={props.id} className={`align-self-center collapse`} aria-labelledby={"heading" + props.id}>
                {props.children}
            </div>
        </React.Fragment>
    );
}
