import "./notificationListItem.scss";
import classNames from "classnames";
import { NotificationModel } from "../../models/notificationModel";
import dayjs from "dayjs";
import useNotificationMutations from "../../hooks/useNotificationMutations";
import { UserProfileAvatar, UserProfileAvatarSizeEnum } from "core/components/userProfileAvatar/userProfileAvatar";
import { UserProfileAvatarModel } from "core/components/userProfileAvatar/userProfileAvatarModel";
import Tooltip from "core/components/tooltips/Tooltip";
import { EmblaIcon } from "core/components/emblaIcon/emblaIcon";
import useNotification from "../../hooks/useNotification";
import React, {useEffect, useState} from "react";
import { Loading } from "core/components/loading/loading";

const getFormattedTimeSinceDate = (date: Date) => {
  const dateToDiff = dayjs(date);
  const now = dayjs();

  const duration = dayjs.duration(dateToDiff.diff(now));
  return duration.humanize(true);
}

type NotificationListItemProps = {
  notification: NotificationModel;
  onDidRedirectToNotificationContext?: () => void;
  showCheckbox?: boolean;
  onSelectDeselectItem?: (notificationId: string, isSelected: boolean) => void;
  checked?: boolean;
}

const NotificationListItem = ({
  notification,
  onDidRedirectToNotificationContext,
  showCheckbox,
  onSelectDeselectItem,
  checked
}: NotificationListItemProps) => {

  const { notificationTitle, notificationIconName, redirectToContextRoute } = useNotification(notification.type, notification.contextId, notification.afsender.info?.userId);
  const { setNotificationIsSeen } = useNotificationMutations();
  const [ isRedirecting, setIsRedirecting ] = useState(false);
  const formattedTimeSince = getFormattedTimeSinceDate(notification.timestamp);
  const notificationId = notification.id;

  const onNotificationItemClicked = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if(showCheckbox && !!onSelectDeselectItem) {
          event.preventDefault(); //Prevent checkbox-input logic
          onSelectDeselectItem(notification.id, !checked);
          return;
      }

    if(!notification.erSet) {
      await setNotificationIsSeen(notification.id);
    }

    setIsRedirecting(true);
    const didRedirect = await redirectToContextRoute().finally(() => setIsRedirecting(false))

    if (didRedirect && !!onDidRedirectToNotificationContext) {
      onDidRedirectToNotificationContext();
    }
  }

  const avatarModel: UserProfileAvatarModel | undefined = !!notification.afsender.info ? {
    Initials: notification.afsender.info.initialer,
    Name: `${notification.afsender.info.firstName} ${notification.afsender.info.lastName}`,
    ProfileImageId: notification.afsender.info.profileImageId
  } : undefined;

    return (
    <div
      className={
        classNames(
          "notification-list-item mb-0",
          !notification.erSet && "not-seen")
      }
      onClick={onNotificationItemClicked}
      >
      <div className="notification-list-item-body">
        <div className="afsender-badge">
          <Tooltip title={avatarModel?.Name ?? "System"}>
            <div className="position-relative">
              <UserProfileAvatar
                size={UserProfileAvatarSizeEnum.small}
                userRoleTitles={notification.afsender.rolletitelEnum ? [notification.afsender.rolletitelEnum] : undefined}
                userProfileAvatarModel={avatarModel}
                showRedCircle={!notification.erSet}
              />
              <Loading isLoading={isRedirecting} hideContentOnPreload={false} spinnerClasses="notification-type-icon" spinnerSize="extra-tiny-small">
                <EmblaIcon
                  additionalClasses="notification-type-icon medium"
                  iconName={notificationIconName}
                  wrapInSpan={false}
                />
              </Loading>
            </div>
          </Tooltip>
            {showCheckbox && !!onSelectDeselectItem &&
                <div className="checkbox-wrapper margin-left-xs">
                    <input type="checkbox"
                           id={`checkbox${notificationId}`}
                           checked={checked}
                    />
                    <label htmlFor={`checkbox${notificationId}`} />
                </div>
            }
        </div>
        <div className="notification-content">
          <h4>{notificationTitle}</h4>
          <div className="small">{notification.message}</div>
          <span className="subtle small">{formattedTimeSince}</span>
        </div>
      </div>
      <hr className="item-divider"/>
    </div>
  )

}

export default NotificationListItem;
