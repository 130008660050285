import { forloebOverviewMenuLink, brugereMenuLink, evalueringsstatistikMenuLink, uddannelseslaegeOverviewMenuLink } from "core/layout/shared/MenuLinkProps";
import { DynamicMenu } from "../DynamicMenu";
import MenuProps from "./menuProps";

export function ForskningstraeningsvejlederMenu({ showNotification, nyhederMenuLink }: MenuProps) {
    
    const menuLinks = [
        forloebOverviewMenuLink,
        uddannelseslaegeOverviewMenuLink,
        brugereMenuLink,
        evalueringsstatistikMenuLink,
        nyhederMenuLink
    ];

    return (
        <DynamicMenu menuLinks={menuLinks} showNotifications={showNotification}/>
    );
}