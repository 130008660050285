import React, {useState} from 'react'
import {Localizer} from 'infrastructure/localization/localizer'
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {forloebReducer, ForloebSliceState} from "../../../core/forloebSlice";
import {ReactFileUploaderFile} from "../../../core/components/fileUpload/reactFileUploader";
import {crudKursusValidationSchema, CrudLaegekursusInputModel} from "./crudLaegekursusInputModel";
import {setSlideinState, SlideInStateEnum} from "../../../core/components/slideIn/slideInSlice";
import {HttpClientService} from "../../../services/httpClient/httpClientService";
import {AccountService} from "../../../services/account/accountService";
import {BuildNewCrudNotatModel} from "../../../core/sharedmodels/notat/notatCrudModel";
import {NotatType} from "../../../core/sharedmodels/notat/notatType";
import {LogbogNotatApi} from "../../../services/api/logbogNotat/logbogNotatApi";
import {FileMetadata} from "../../../core/sharedmodels/fileMetaData/fileMetaData";
import {HandleError} from "../../../core/infrastructure/errors/errorBoundary";
import {Form, Formik} from "formik";
import {LaegekursusCrudFields} from "./laegekursusCrudFields";
import {ValidationFormSubmitButton} from "../../../core/components/validation/components/validationFormButton";
import {LogbogLaegekursusApi} from "../../../services/api/laegekursus/logbogLaegekursusApi";
import {NotificationModule} from "ditmer-embla";
import {LaegekursusGruppeTypeEnum} from "../../../core/sharedmodels/kursus/laegekursusGruppeTypeEnum";

interface CreateKursusProps {
    modalId: string,
    brugerId: string,
    titel?: string
    kursusGruppeId: string,
    laegekursusGruppeType: LaegekursusGruppeTypeEnum
}
const laegekursusApi = new LogbogLaegekursusApi(new HttpClientService(), new AccountService())
const notatApi = new LogbogNotatApi(new HttpClientService(), new AccountService())

export function CreateLaegekursus(props: CreateKursusProps) {
    const dispatch = useAppDispatch();

    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;
    const [uploadedFilesState, setUploadedFilesState] = useState<ReactFileUploaderFile[]>([]);

    const createKursus = async (inputModel: CrudLaegekursusInputModel) => {
        dispatch(setSlideinState({ state: SlideInStateEnum.ActionOngoing, slideInId: props.modalId }))

        let kursusId = await laegekursusApi.createLaegekursus(inputModel);

        if (uploadedFilesState?.length > 0) {
            let kursusNotat = BuildNewCrudNotatModel([kursusId], NotatType.Laegekursus, forloebSliceState.forloebState.brugerId);
            await notatApi.createNotat(kursusNotat, uploadedFilesState.map(tf => tf.fileMetadata.id));
        }
        if (kursusId)
            NotificationModule.showSuccess(Localizer.kursusPage_kursusCreated(), "");

        dispatch(setSlideinState({ state: SlideInStateEnum.Closing, slideInId: props.modalId }))
    }

    const fileUploadedCallback = async (uploadedFiles: ReactFileUploaderFile[]) => {
        setUploadedFilesState(arr => [...arr, ...uploadedFiles]);
    }

    const fileDeletedCallback = (fileMetaData: FileMetadata) => {
        let updatedFileUploadedState = [...uploadedFilesState];
        let deleteIndex = updatedFileUploadedState.findIndex(file => file.fileMetadata.id === fileMetaData.id);
        updatedFileUploadedState.splice(deleteIndex, 1);
        setUploadedFilesState(updatedFileUploadedState);
    }

    const render = (
        <Formik
            initialValues={new CrudLaegekursusInputModel(forloebSliceState.forloebState.id, props.brugerId, props.kursusGruppeId, forloebSliceState.forloebState.brugerKursusraekkeId, props.laegekursusGruppeType, props.titel)}
            validationSchema={crudKursusValidationSchema}
            onSubmit={async (values: CrudLaegekursusInputModel) => {
                await createKursus(values)
                    .catch(err => { // catch is needed to handle unhandeled exception (eg. api offline). Formik onsubmithandler is catching all exception and writing them as warnings
                        dispatch(setSlideinState({ state: SlideInStateEnum.Opened, slideInId: props.modalId }))
                        HandleError(err);
                    });
            }}>
            {
                (formik) => (
                    <Form>
                        <LaegekursusCrudFields
                            modalId={props.modalId}
                            formik={formik}
                            fileUploadedCallbackOverride={fileUploadedCallback}
                            fileDeletedCallbackOverride={fileDeletedCallback} />

                        <div className="modal-actions">
                            <ValidationFormSubmitButton formikProps={formik}>
                                {Localizer.kursusPage_createKursus()}
                            </ValidationFormSubmitButton>
                        </div>
                    </Form>
                )
            }
        </Formik>
    )
    return <>{render}</>
}
