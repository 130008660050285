import React from "react";
import {SvarInputTypeProps} from "./svarInputTypeCompareMap";
import {Localizer} from "../../../infrastructure/localization/localizer";

export const TekstInputSvar = ({spoergsmaal, svarNummerIndex, kompetenceSvarList, tekstCallback, indsendt}: SvarInputTypeProps) => {
    return (<textarea
        key={spoergsmaal.id + svarNummerIndex}
        name={spoergsmaal.id + svarNummerIndex}
        className="form-control margin-bottom-m"
        placeholder={Localizer.evalueringPage_angivTekstsvar()}
        defaultValue={kompetenceSvarList.find(x => x.svarIndex === svarNummerIndex)?.tekstSvar}
        onBlur={e => tekstCallback(e, svarNummerIndex)}
        disabled={indsendt}/>)
};
