import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../../app/hooks";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {
    evalueringsstatistikFiltersReducer,
    EvalueringsstatistikFiltersState
} from "../evalueringsstatistikFiltersSlice";
import {SpoergsmaalTypeEnum} from "../../../core/sharedmodels/evaluering/spoergsmaalTypeEnum";
import {EvalueringsstatistikTabEnum} from "../evalueringsstatistikTabEnum";
import {EvalueringsstatistikPageConstants} from "./EvalueringsstatistikPageConstants";
import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../../../core/layout/responsive";


interface StickyHeaderComponentProps {
    tab: EvalueringsstatistikTabEnum;
}

export function StickyHeaderComponent(props: StickyHeaderComponentProps) {
    const evalueringsstatistikFilterSliceState = useAppSelector(evalueringsstatistikFiltersReducer) as EvalueringsstatistikFiltersState;

    const [spoergsmaalNumbers, setSpoergsmaalNumbers] = useState<number[]>([]);
    const IsMobile = useMediaQuery(mobileMaxWidthQuery);

    useEffect(() => {
        if (props.tab === EvalueringsstatistikTabEnum.Evalueringer && evalueringsstatistikFilterSliceState.spoergsmaalsType !== SpoergsmaalTypeEnum.Enkeltvis)
            setSpoergsmaalNumbers(Array.from({length: EvalueringsstatistikPageConstants.numberOfQuestionsExcludingOptionalTextInput}, (_, i) => i + 1))
        else if (evalueringsstatistikFilterSliceState.spoergsmaalToShow.length === 0){
            let antalSpoergsmaal = (evalueringsstatistikFilterSliceState.spoergsmaalsType === SpoergsmaalTypeEnum.Grupperet)
                ? EvalueringsstatistikPageConstants.numberOfGroups
                : EvalueringsstatistikPageConstants.numberOfQuestionsExcludingOptionalTextInput;
            let spoergsmaalNummer = [];

            for (let i = 1; i <= antalSpoergsmaal; i++) {
                spoergsmaalNummer.push(i)
            }
            setSpoergsmaalNumbers(spoergsmaalNummer);
        }
        else {
            const numbers = [...evalueringsstatistikFilterSliceState.spoergsmaalToShow.map(s => Number(s))];
            setSpoergsmaalNumbers(numbers.filter(x => x != EvalueringsstatistikPageConstants.numberOfQuestionsIncludingOptionalTextInput));
        }
    }, [evalueringsstatistikFilterSliceState.spoergsmaalToShow, evalueringsstatistikFilterSliceState.spoergsmaalsType])


    const evalueringerView = () => {
        return (
            <div className={"col "}>
                <div className="row align-items-center">
                    <div className="col-sm-1 ">
                        {!IsMobile && <h5>{Localizer.evalueringPage_afleveret()}</h5>}
                    </div>
                    <div className="col-sm-1 ">
                        {!IsMobile && <h5>{Localizer.slutdato()}</h5>}
                    </div>
                    <div className="col-sm-2 ">
                        {!IsMobile && <h5>{Localizer.stilling()}</h5>}
                    </div>
                    <div className="col-sm-7">
                        <h5>{Localizer.evalueringPage_spoergsmaalHeader()}</h5>
                        {spoergsmaalNumbers &&
                            <div className={"d-flex wrap"}>
                                {spoergsmaalNumbers.map(nummer =>
                                    <div key={nummer} className="flex-align-c padding-tbr-s">
                                        <div className={"square flex-align-c"}>{nummer}</div>
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                    <div className={"col-sm-1"}></div>
                </div>
            </div>

        )
    }

    const uddannelsesstederView = () => {
        let IsGrouped = evalueringsstatistikFilterSliceState.spoergsmaalsType === SpoergsmaalTypeEnum.Grupperet;
        return (
            <div className={"col"}>
                <div className="row align-items-center">
                    <div className={IsGrouped ? "col-sm-7" : "col-sm-2"}>
                        {!IsMobile && <h5>{Localizer.afdeling()}</h5>}
                    </div>
                    <div className={IsGrouped ? "col-sm-1" : "col-sm-2"}>
                        {!IsMobile && <h5>{Localizer.global_periode()}</h5>}
                    </div>
                    <div className="col-sm-1">
                        {!IsMobile && <h5>{Localizer.global_antal()}</h5>}
                    </div>
                    <div className={IsGrouped ? "col-sm-3" : "col-sm-7"}>
                        <h5>{IsGrouped ? Localizer.evalueringPage_spoergsmaalsgruppeHeader() : Localizer.evalueringPage_spoergsmaalHeader()}</h5>
                        {spoergsmaalNumbers &&
                            <div className={"d-flex wrap"}>
                                {spoergsmaalNumbers.map(nummer =>
                                    <div key={nummer} className="flex-align-c padding-tbr-s">
                                        <div className={"wide-square"}>{nummer}</div>
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {!IsMobile &&
                <div className={"sticky-header padding-top-s"}>
                    {props.tab === EvalueringsstatistikTabEnum.Evalueringer &&
                        evalueringerView()
                    }
                    {props.tab === EvalueringsstatistikTabEnum.Uddannelsessteder &&
                        uddannelsesstederView()
                    }
                </div>
            }
        </>
    )
}

