import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "../../app/store";
import {DatasetModel} from "./datasetModel";
import {Localizer} from "../../infrastructure/localization/localizer";
import {EvalueringsstatistikPageConstants} from "./components/EvalueringsstatistikPageConstants";

export interface EvalueringsstatistikState {
    stillingstype?: {id : string, navn: string};
    speciale?: string;
    evalueringer?: string[];
    dataset: DatasetModel[];
    uddannelsesstedHasToCheckAll: boolean;
    showMessage: boolean;
    specialeIds?: string[];
    tab?: string;
    includesInactive?: boolean;
    shouldFetchDefaultVisning: boolean;
    showDefaultDatasaet: boolean;
    lastChangedAt?: string;
    showAllDatasetAverageAndFilters?: boolean;
}

const initialState: EvalueringsstatistikState = {
    stillingstype: {id: "0", navn: Localizer.global_all()},
    evalueringer: undefined,
    dataset: [],
    uddannelsesstedHasToCheckAll: false,
    showMessage: false,
    shouldFetchDefaultVisning: EvalueringsstatistikPageConstants.getDefaultvisning,
    specialeIds: [],
    tab: undefined,
    includesInactive: false,
    showDefaultDatasaet: EvalueringsstatistikPageConstants.getDefaultvisning,
    lastChangedAt: undefined,
    showAllDatasetAverageAndFilters: false,
};

export const evalueringsstatistikSlice = createSlice({
    name: 'Evalueringsstatistik',
    initialState,
    reducers: {
        setevalueringer: (state, action: PayloadAction<EvalueringsstatistikState>) => {
            state.evalueringer = action.payload.evalueringer;
        },
        setLastChangedAt: (state, action: PayloadAction<string>) => {
            state.lastChangedAt = action.payload;
        },
        setDataset: (state, action: PayloadAction<EvalueringsstatistikState>) => {
            state.dataset = action.payload.dataset;
        },
        setShowMessage: (state, action: PayloadAction<boolean>) => {
            state.showMessage = action.payload;
        },
        setShouldFetchDefaultVisning: (state, action: PayloadAction<boolean>) => {
            state.shouldFetchDefaultVisning = action.payload;
        },
        setShowDefaultDatasaet: (state, action: PayloadAction<boolean>) => {
            state.showDefaultDatasaet = action.payload;
        },
        setTabState: (state, action: PayloadAction<string>) => {
            state.tab = action.payload;
        },
        clearDataset: (state) => {
            state.dataset = [];
        },
        setShowAllDatasetAverageAndFilters: (state, action: PayloadAction<boolean>) => {
            state.showAllDatasetAverageAndFilters = action.payload;
        }

    }
});

export const {
    setevalueringer, 
    setDataset, 
    setShowMessage, 
    setShouldFetchDefaultVisning, 
    setTabState, 
    clearDataset, 
    setShowDefaultDatasaet, 
    setLastChangedAt,
    setShowAllDatasetAverageAndFilters 
} = evalueringsstatistikSlice.actions;

export const evalueringsstatistikReducer = (state: RootState) => state.evalueringsstatistikReducer;
export default evalueringsstatistikSlice.reducer;
