import {UserProfileAvatarModel} from "core/components/userProfileAvatar/userProfileAvatarModel";
import {VejledersamtaleModel} from "../vejledersamtaleModel";
import {
    UserProfileAvatar,
    UserProfileAvatarSizeEnum,
    UserProfileLayoutEnum
} from "core/components/userProfileAvatar/userProfileAvatar";
import Title from "core/components/titels/title";
import {Localizer} from "infrastructure/localization/localizer";
import Calendar from "core/components/timedisplayers/Calendar";
import React from "react";
import VejledersamtalePills from "./vejledersamtalePills";
import {EmblaIcon} from "../../../../core/components/emblaIcon/emblaIcon";
import {EmblaIcons} from "../../../../core/emblaIcons";

type VejlederAppointmentProps = {
    samtale: VejledersamtaleModel;
}

const VejlederAppointment = ({ samtale }: VejlederAppointmentProps) => {

    return (
        <div className="flex-container-column">

            <Title title={samtale.titel} subTitle={samtale.createdByName ? Localizer.global_createdBy(samtale.createdByName) : ""} />

            <div className="flex-container align-items-center wrap">

                <Calendar
                    dato={samtale.dato}
                    startTime={samtale.starttidspunkt}
                    endTime={samtale.sluttidspunkt}
                />
                <VejledersamtalePills vejledersamtale={samtale}/>

                {samtale.vejlederInitialer &&
                    <UserProfileAvatar
                        userProfileAvatarModel={new UserProfileAvatarModel(samtale.vejlederNavn, samtale.vejlederInitialer, false)}
                        userProfileLayoutEnum={UserProfileLayoutEnum.AsTextOnRightSide}
                        size={UserProfileAvatarSizeEnum.extraSmall}
                    />
                }

                {!samtale.currentUserHasAccess &&
                    <div className={"margin-left-m"}>
                        <span className="badge badge-pill badge-default bg-white border">
                            <EmblaIcon iconName={EmblaIcons.Warning} additionalClasses={"small subtle margin-right-xs"} />
                            {Localizer.noAccessToVejledersamtale()}
                        </span>
                    </div>

                }
            </div>
        </div>
    );
}

export default VejlederAppointment;
