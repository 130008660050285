import Container from "core/components/container/container";
import { Loading } from "core/components/loading/loading";
import { EmblaIcons } from "core/emblaIcons";
import useStamdataApi from "core/hooks/useStamdataApi";
import { SetTitleBar } from "core/layout/titleBar/titleBarApi";
import { Localizer } from "infrastructure/localization/localizer";
import { userBrevskabelonEditQueryParams } from "infrastructure/route/queryParams";
import { RoutePaths } from "infrastructure/routes";
import { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import BrevskabelonDto, { emptyBrev } from "services/api/brevskabelon/models/brevskabelonDto";
import useScreenResolution from "core/hooks/useScreenResolution";
import { MaxColWidth } from "core/components/divSpecializations/Col";
import { AsyncButton } from "core/components/button/asyncButton";
import { EmblaIcon } from "core/components/emblaIcon/emblaIcon";
import { BrevItemWrapper } from "./BrevskabelonCrudFields";
import BrevMergedMarkdownEditor from "./brevMergedMarkdownEditor";
import Title from "core/components/titels/title";
import useBrevMergeFieldPdfGenerator from "../hooks/useBrevMergeFieldPdfGenerator";

const BrevGeneratePdfPage = () => {

    const { isMobile } = useScreenResolution();
    const { brevskabelonApi} = useStamdataApi();
    const { uddannelseslaege, brev: brevId } = useRouteMatch<userBrevskabelonEditQueryParams>().params;

    const [subject, setSubject] = useState<string>("");
    const [htmlString, setHtmlString] = useState<string>("");
    const [brev, setBrev] = useState<BrevskabelonDto>(emptyBrev);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { generatePdf } = useBrevMergeFieldPdfGenerator(subject, htmlString);

    SetTitleBar(
        Localizer.generatingPdf(),
        [{ text: Localizer.global_letters(), url: RoutePaths.Brevskabeloner({uddannelseslaege}).url}]
    );

    useEffect(() => {
        
        brevskabelonApi
            .get(brevId)
            .then((b) => {
                setBrev(b);
                setSubject(b.subject);
            })
            .finally(() => setIsLoading(false));

    }, [brevId, brevskabelonApi]);

    return (
        <Container>
            <Container.Header childrenIsHeaderActions titleConfig={{title: brev.titel}}>
                <AsyncButton
                    overrideClasses="btn btn-primary"
                    clickFunction={generatePdf}
                    buttonText={Localizer.GetPdf()}
                    waitText={Localizer.gettingPdf()}
                    child={<EmblaIcon iconName={EmblaIcons.Download}/>}
                    disabled={!htmlString || isLoading}
                />
            </Container.Header>

            <Container.Body>
                <Loading isLoading={isLoading} text={Localizer.letterPage_fetchingLetter()}>
                    
                    <BrevItemWrapper width={isMobile ? MaxColWidth : 6} >
                        <Title title={Localizer.global_subject()}>
                            <input
                                defaultValue={brev.subject}
                                className="form-control"
                                onChange={(e) => setSubject(e.target.value)}
                            />
                        </Title>
                    </BrevItemWrapper>

                    <BrevItemWrapper className="margin-top-m">
                        <Title title={Localizer.global_content()}>
                            <BrevMergedMarkdownEditor
                                markdown={brev.bodyMarkdown}
                                onChange={(htmlString) => setHtmlString(htmlString)}
                                uddannelseslaegeId={uddannelseslaege}
                                onlyMergeOnce  
                            />
                        </Title>
                    </BrevItemWrapper>

                </Loading>
            </Container.Body>

        </Container>
    );
}

export default BrevGeneratePdfPage;
