import {useState} from "react";
import {BuildNew, CreateMailInputModel, CreateMailValidationSchema} from "./createMailInputModel";
import {ValidationForm} from "../validation/components/validationForm";
import {MailInputFields} from "./mailInputFields";
import {ValidationFormSubmitButton} from "../validation/components/validationFormButton";
import {Localizer} from "../../../infrastructure/localization/localizer";

import {nameof} from "ts-simple-nameof";
import {ValidationDropdown} from "../validation/components/validationDropdown";
import useUserEmail from "../../hooks/useUserEmail";
import {UserSendEmailResponseModel} from "../../../services/api/stamdataUser/UserSendEmailResponseModel";
import { DropdownOption } from "../dropdown/dropdown";
import { UserEmailModel } from "./UserEmailModel";

interface EmailSlideInMultipleUsersProps {
    users: UserSendEmailResponseModel[];
    actionFinishedCallback?: () => void;
}

const modalId = "send-email-modal";

export function EmailSlideInModalMultipleUsers({users, actionFinishedCallback}: EmailSlideInMultipleUsersProps) {
    const emailOptions: DropdownOption<string>[] = users.map(x => ({ label: x.navn, value: x.userId})).sortBy(x => x.label);
    const userEmailModels: UserEmailModel[] = users.map(x => ({ email: x.email, userId: x.userId }));

    const {
        fileDeletedCallback, 
        CreateMail, 
        filesUploadedCallback, 
        setSelectedTemplateType
    } = useUserEmail(userEmailModels, modalId, actionFinishedCallback, false);

    return (
        <>
            <ValidationForm
                key={modalId}
                initialValues={BuildNew(users.map(x => x.userId))}
                validationSchema={CreateMailValidationSchema}
                onSubmit={async (values) => {await CreateMail(values)}}>
                {
                    (formik) => (
                        <>
                            <MailInputFields modalId={modalId}
                                             formik={formik}
                                             templateTypeCallBack={setSelectedTemplateType}
                                             fileUploadedCallback={filesUploadedCallback}
                                             fileDeletedCallback={fileDeletedCallback}/>
                            <ValidationDropdown
                                model={{
                                    label: Localizer.createMail_MailSentTo(),
                                    placeholder: Localizer.createMail_MailSentTo(),
                                    htmlName: nameof<CreateMailInputModel>(x => x.userIds)
                                }}
                                options={emailOptions}
                                renderMultiValuesAsText={emailOptions.length > 10}
                                formikProps={formik}
                                isMulti

                            />
                            <div className="modal-actions">
                                <ValidationFormSubmitButton formikProps={formik}>
                                    {Localizer.createMail_SendMail()}
                                </ValidationFormSubmitButton>
                            </div>
                        </>
                    )
                }
            </ValidationForm>
        </>

    );
}
