import {Localizer} from "../../../infrastructure/localization/localizer";
import React, {useCallback, useState} from "react";
import {ForloebOverviewFilterModel, toState} from "./ForloebOverviewFilterModel";
import {setSlideinState, SlideInStateEnum} from "../slideIn/slideInSlice";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {applyFilters, forloebOverviewFiltersReducer, ForloebOverviewFiltersState} from "./forloebOverviewFiltersSlice";
import {HttpClientService} from "../../../services/httpClient/httpClientService";
import {AccountService} from "../../../services/account/accountService";
import {ValidationForm} from "../validation/components/validationForm";
import {BuildExisting, CreateFilterInputModel, CreateFilterValidationSchema} from "../filter/createFilterInputModel";
import {LogbogFilterApi} from "../../../services/api/logbogFilter/logbogFilterApi";
import {NotificationModule} from "ditmer-embla";
import {CrudFilterInputModel} from "./crudFilterInputModel";
import {ValidationFormSubmitButton} from "../validation/components/validationFormButton";
import {HandleError} from "../../infrastructure/errors/errorBoundary";
import {ForloebOverviewValidationInput} from "./forloebOverviewValidationInput";
import { ForloebOverviewEditFilterProps } from "./forloebOverviewFilterProps";

const logbogFilterApi = new LogbogFilterApi(new HttpClientService(), new AccountService())

export function ForloebOverviewEditFilter({
    filter,
    filterAppliedCallback,
    modalId,
    notCurrentSelectedfilterDeletedOrEdittedCallback,
    paginationModel,
    permissions,
    filterToEditId
}: ForloebOverviewEditFilterProps) {
    const dispatch = useAppDispatch();
    const forloebOverviewFilterSliceState = useAppSelector(forloebOverviewFiltersReducer) as ForloebOverviewFiltersState;

    const [filterModel, setFilterModel] = useState<ForloebOverviewFilterModel>(filter);

    const deleteFilter = async () => {
        if (filterToEditId) {
            dispatch(setSlideinState({state: SlideInStateEnum.ActionOngoing, slideInId: modalId}))
            let deletedFilter = await logbogFilterApi.DeleteFilter(filterToEditId);
            if (deletedFilter) {
                dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: modalId}))
                NotificationModule.showSuccess(Localizer.forloebOverviewPage_deletedFilter(), "");

                if (filterToEditId === forloebOverviewFilterSliceState.filterId) {
                    let emptyFilter = new ForloebOverviewFilterModel();
                    dispatch(applyFilters(toState(emptyFilter, false)))
                    filterAppliedCallback(emptyFilter);
                } else {
                    dispatch(applyFilters(forloebOverviewFilterSliceState))
                    notCurrentSelectedfilterDeletedOrEdittedCallback();
                }
            }
        }
    }

    const editFilter = useCallback(async (inputModel: CreateFilterInputModel) => {
        dispatch(setSlideinState({state: SlideInStateEnum.ActionOngoing, slideInId: modalId}))

        const filterModelPost = {
            ...filterModel,
            titel: inputModel.titel,
            regionRettigheder: inputModel.regionRettigheder,
            filterId: filterToEditId,
            regioner: filterModel.regioner?.filter((r: string) => r !== Localizer.global_all()),
            kbuPeriodYear: filterModel.kbuPeriodYear,
            kbuPeriod: filterModel.kbuPeriod
        } as ForloebOverviewFilterModel;

        let editedFilter = await logbogFilterApi.EditFilter(filterModelPost);
        if (editedFilter) {
            dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: modalId}));
            NotificationModule.showSuccess(Localizer.forloebOverviewPage_edittedFilter(), "");

            if (filterToEditId === forloebOverviewFilterSliceState.filterId) {
                dispatch(applyFilters(toState(filterModelPost, forloebOverviewFilterSliceState.filterSelected)))
                filterAppliedCallback(filterModelPost);
            } else {
                dispatch(applyFilters(forloebOverviewFilterSliceState))
                notCurrentSelectedfilterDeletedOrEdittedCallback();
            }
        }
    }, [dispatch, filterModel, filterAppliedCallback, filterToEditId, forloebOverviewFilterSliceState, modalId, notCurrentSelectedfilterDeletedOrEdittedCallback])

    const onFilterModelChange =  async (filterModel: ForloebOverviewFilterModel) => {
        setFilterModel(filterModel)
        filterAppliedCallback(filterModel);
    }

    const render = (
        <ValidationForm
            key={modalId}
            initialValues={BuildExisting(filter.titel, filter.regionRettigheder, filterToEditId, filter.isPrivate)}
            validationSchema={CreateFilterValidationSchema}
            onSubmit={
                async (values) => {
                    await editFilter(values).catch(err => { // catch is needed to handle unhandeled exception (eg. api offline). Formik onsubmithandler is catching all exception and writing them as warnings
                        dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: modalId}))
                        HandleError(err);
                    });
                }}>
            {
                (formik) => (
                    <>
                        <CrudFilterInputModel onChangeCallback={onFilterModelChange} filter={filter} modalId={modalId} permissions={permissions}/>
                        <div className="margin-top-m">
                            <h5 className="subtle">
                                {Localizer.forloebOverviewPage_gemFilter()}
                            </h5>

                            <ForloebOverviewValidationInput
                                filter={filter}
                                modalId={modalId}
                                formik={formik}
                            />

                        </div>
                        <div className="modal-actions">
                            <button className="btn btn-danger margin-right-s" onClick={() => deleteFilter()}>
                                {Localizer.global_delete()}
                            </button>
                            <ValidationFormSubmitButton formikProps={formik}>
                                {Localizer.global_save()}
                            </ValidationFormSubmitButton>
                        </div>
                    </>
                )
            }
        </ValidationForm>
    )
    return <>{render}</>;
}
