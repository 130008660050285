import {EvalueringsstatistikState} from "./evalueringsstatistikSlice";
import {DatasetModel} from "./datasetModel";
import {UddannelsesstedModel} from "../../core/sharedmodels/laegeInstitution/uddannelsesstedModel";

export class EvalueringsstatistikModel {
    constructor(uddannelsessteder?: Array<UddannelsesstedModel>,
                hospitalsenhed?: string,
                selectedAfdelinger?: Array<UddannelsesstedModel>,
                selectedPraksislist?: Array<UddannelsesstedModel>,
                stillingstype?: {id : string, navn: string},
                speciale?: string,
                evalueringer?: Array<string>,
                dataset?: Array<DatasetModel>,
                hasToCheck?: boolean,
                showMessage?: boolean,
                missingDefaultVisning?: boolean,
                specialeIds?: string[],
                showDefaultDatasaet?: boolean,
                lastChangedAt?: string,
    ) {
        this.uddannelsessteder = uddannelsessteder;
        this.hospitalsenhed = hospitalsenhed;
        this.selectedAfdelinger = selectedAfdelinger;
        this.selectedPraksislist = selectedPraksislist;
        this.stillingstype = stillingstype;
        this.speciale = speciale;
        this.evalueringer = evalueringer;
        this.dataset = dataset;
        this.hasToCheck = hasToCheck;
        this.showMessage = showMessage;
        this.specialeIds = specialeIds;
        this.missingDefaultVisning = missingDefaultVisning;
        this.showDefaultDatasaet = showDefaultDatasaet;
        this.lastChangedAt = lastChangedAt;
    }

    uddannelsessteder?: UddannelsesstedModel[];
    hospitalsenhed?: string;
    selectedAfdelinger?: UddannelsesstedModel[];
    selectedPraksislist?: UddannelsesstedModel[];
    stillingstype?: {id : string, navn: string};
    speciale?: string;
    evalueringer?: string[];
    dataset?: DatasetModel[];
    hasToCheck?: boolean;
    showMessage?: boolean;
    specialeIds?: string[];
    missingDefaultVisning?: boolean;
    showDefaultDatasaet?: boolean;
    lastChangedAt?: string;
}

export function toState(inputModel: EvalueringsstatistikModel) : EvalueringsstatistikState {
    return {
        uddannelsessteder: inputModel?.uddannelsessteder,
        hospitalsenhed: inputModel?.hospitalsenhed,
        selectedAfdelinger: inputModel?.selectedAfdelinger,
        selectedPraksislist: inputModel?.selectedPraksislist,
        stillingstype: inputModel?.stillingstype,
        speciale: inputModel?.speciale,
        evalueringer: inputModel?.evalueringer,
        dataset: inputModel?.dataset,
        uddannelsesstedHasToCheckAll: inputModel?.hasToCheck,
        showMessage: inputModel?.showMessage,
        specialeIds: inputModel?.specialeIds,
        shouldFetchDefaultVisning: inputModel?.missingDefaultVisning,
        showDefaultDatasaet: inputModel?.showDefaultDatasaet,
        lastChangedAt: inputModel?.lastChangedAt,
    } as EvalueringsstatistikState;
}

