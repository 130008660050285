import {HttpClientService} from "../../httpClient/httpClientService";
import config from "../../../config/config";
import {
    FromResponseModel,
    ObligatoriskDokumentationModel
} from "../../../core/sharedmodels/obligatoriskDokumentation/obligatoriskDokumentationModel";
import {ApiResponse} from "../apiResponse";
import {ObligatoriskDokumentationResponseModel} from "./obligatoriskDokumentationResponseModel";
import {ObjectToQueryString} from "../objectToQueryString";
import {ObligatoriskDokumentationGetModel} from "./obligatoriskDokumentationGetModel";
import {ObligatoriskDokumentationIntroGetModel} from "./obligatoriskDokumentationIntroGetModel";

export class LogbogObligatoriskDokumentationApi {
    private httpClientService: HttpClientService;


    private baseUrl = () => config.logbogApiUrl + "api/dokumentation/";
    private getDokumentationForIntroUrl = () => this.baseUrl() + "getDokumentationForIntro";

    constructor(httpClientService: HttpClientService) {
        this.httpClientService = httpClientService;
    }

    async getObligatoriskDokumentation(uddannelseId: string, ansoegningBrugerId: string, brugerKursusraekkeId: string, brugerMaalbeskrivelseId: string): Promise<ObligatoriskDokumentationModel[]> {
        const url = this.baseUrl() + ObjectToQueryString(new ObligatoriskDokumentationGetModel(uddannelseId, ansoegningBrugerId, brugerKursusraekkeId, brugerMaalbeskrivelseId));

        const result = await this.httpClientService.Get<ApiResponse<Array<ObligatoriskDokumentationResponseModel>>>(url);

        if (result.data) {
            return result.data.map(x => FromResponseModel(x));
        } else {
            return [];
        }
    }
    async getObligatoriskDokumentationForIntro(brugerKursusraekkeId: string, brugerMaalbeskrivelseId: string): Promise<ObligatoriskDokumentationModel[]> {
        const url = this.getDokumentationForIntroUrl() + ObjectToQueryString(new ObligatoriskDokumentationIntroGetModel(brugerKursusraekkeId, brugerMaalbeskrivelseId));

        const result = await this.httpClientService.Get<ApiResponse<Array<ObligatoriskDokumentationResponseModel>>>(url);

        if (result.data) {
            return result.data.map(x => FromResponseModel(x));
        } else {
            return [];
        }
    }
}
