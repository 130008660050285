import "./lineChart.scss";
import React from "react";

export enum LineChartColor{
    info, secondary,  danger, warning, success, primary, gray
}

export interface LineChartItem{
    title?: string,
    count: number,
    color: LineChartColor
}

export class LineChartProps {
    items: LineChartItem[];
    hideLabels?: boolean;
}

export function LineChart(props: LineChartProps) {

    const getColorClassFromEnum = (colorEnum: LineChartColor) => {
        return `progressColor-${LineChartColor[colorEnum]}`;
    }

    const getStyleVariables = (chartItem: LineChartItem) => {
        const totalCount = props.items.map(i => i.count).reduce((sum, current) => sum + current);
        let itemPercentage = 0;

        // avoid dividing by zero
        if(chartItem?.count > 0){
            itemPercentage = chartItem.count / totalCount * 100;
        }

        return {
            "--progressPercentage": `${itemPercentage}%`,
        } as React.CSSProperties
    }

    return (
        <>
            <div className="progress">
                {props.items.map((chartItem, index) => (
                    <div className={`progress-bar ${getColorClassFromEnum(chartItem.color)}`} style={getStyleVariables(chartItem)} key={chartItem.title?.trim()+index.toString()}/>
                ))}
            </div>
            {!props.hideLabels &&
                <div>
                    {props.items.map((chartItem) => (
                        <span className="badge badge-pill badge-progressBadge margin-right-s" key={chartItem.title?.trim()+"badge"}>
                        <span className={`progressBadgeStatus margin-right-xs align-middle ${getColorClassFromEnum(chartItem.color)}`}/>
                            {chartItem.title} ({chartItem.count})
                    </span>
                    ))}
                </div>
            }
        </>
    );
}
