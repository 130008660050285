import React from "react";
import {AfdelingView} from "./afdelingView";
import {StillingForloebOverviewModel} from "../../../sharedmodels/stilling/stillingForloebOverviewModel";
import {StillingView} from "./stillingView";
import {ForloebOverviewConstants} from "../forloebOverviewConstants";
import Tooltip, {TooltipPlacement} from "../../tooltips/Tooltip";

export interface UddannelsesstedViewProps {
    uddannelsesstedLookUp: { uddannelsesstedId: string, uddannelsesstedNavn: string, rowIndex: number, collapsed: boolean };
    years: number[];
    afdelinger: { afdelingId: string, afdelingNavn: string, uddannelsesstedId: string, rowIndex: number, collapsed: boolean }[];
    stillinger: { stillingModel: StillingForloebOverviewModel, rowIndex: number, uddannelsesstedId: string, afdelingId?: string, collapsed: boolean }[];
    visHorizontaleLinjerState: boolean;
}

export function UddannelsesstedView(props: UddannelsesstedViewProps) {

    return <React.Fragment>
        <div className={`uddannelsessted-header d-flex flex-space`}
             style={{
                 "--uddannelsessted-row-index": (props.uddannelsesstedLookUp.rowIndex),
                 "--uddannelsessted-row-color": (props.visHorizontaleLinjerState
                     ? ForloebOverviewConstants.uddannelsesstedHorizontalLinesBackgroundColor
                     : ForloebOverviewConstants.defaultBackgroundColor)
             } as React.CSSProperties}>
            <div className="fit-content d-flex ">
                <div className="margin-left-m d-flex left-col-max-width">
                    {props.uddannelsesstedLookUp.uddannelsesstedNavn &&
                        <Tooltip className="text-truncate" title={props.uddannelsesstedLookUp.uddannelsesstedNavn}
                                 placement={TooltipPlacement.Right}>
                            <h5 className="font-weight-bolder text-truncate">
                                {props.uddannelsesstedLookUp.uddannelsesstedNavn}
                            </h5>
                        </Tooltip>
                    }
                </div>
            </div>
        </div>
        {props.afdelinger.map(afdeling =>
            <AfdelingView
                key={afdeling.afdelingId}
                afdelingLookUp={afdeling}
                afdelingStillinger={props.stillinger.filter(s => s.afdelingId)}
                uddannelsesstedCollapsed={props.uddannelsesstedLookUp.collapsed}
                visHorizontaleLinjerState={props.visHorizontaleLinjerState}/>
        )}

        {props.stillinger.filter(s => !s.afdelingId).map(stilling =>
            <StillingView
                key={stilling.stillingModel.stillingId}
                stillingLookUp={stilling}
                visHorizontaleLinjerState={props.visHorizontaleLinjerState}
            />
        )}
    </React.Fragment>
}
