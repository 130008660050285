import {
    StamdataEmailTemplateResponseModel
} from "../../../services/api/stamdataEmailTemplate/stamdataEmailTemplateResponseModel";

export class EmailTemplateModel{
    public id: string;
    public subject: string;
    public culture: string;
    public bodyMarkdown: string;
    public type: number;
    public titel: string;

    static FromResponseModel(responseModel: StamdataEmailTemplateResponseModel) : EmailTemplateModel {
        const model = new EmailTemplateModel();
        model.id = responseModel.id;
        model.culture = responseModel.culture;
        model.titel = responseModel.titel;
        model.type = responseModel.type;
        model.subject = responseModel.subject;
        model.bodyMarkdown = responseModel.bodyMarkdown;

        return model;
    }
}
