import { greedyAllDigitsAndColonRegex, greedyAllDigitsAndSlashesRegex, greedyColonRegex, greedySlashRegex } from "./regex";

const removeSlashes = (input: string) => input.replace(greedySlashRegex, '');
const removeColons = (input: string) => input.replace(greedyColonRegex, '');

const removeNonDigitsKeepSlashes = (input: string) => input.replace(greedyAllDigitsAndSlashesRegex, '');
const removeNonDigitsKeepColon = (input: string) => input.replace(greedyAllDigitsAndColonRegex, '');

const switchCharOnIndexWithTheOneBefore = (str: string, index: number) =>  str.substring(0, index - 1) + str[index] + str[index-1] + str.substring(index + 1);

const toDateString = (day: string, month: string, year: string, emptyFallbackValue="", invalidValues: string[]=[], yearEmptyFallbackValue="") => {
    const dayStr = !!day && !invalidValues.includes(day) ? day : emptyFallbackValue; 
    const monthStr = !!month && !invalidValues.includes(month) ? month : emptyFallbackValue; 
    const yearStr = !!year && !invalidValues.includes(year) ? year : yearEmptyFallbackValue ?? emptyFallbackValue; 
    return `${dayStr}/${monthStr}/${yearStr}`;
};

const toTimeString = (hours: string, minutes: string) => `${hours}:${minutes}`;

const hasValuesAfterIndex = (str: string, index: number) => str.substring(index) !== "";

export {
    removeSlashes,
    removeNonDigitsKeepSlashes,
    switchCharOnIndexWithTheOneBefore,
    toDateString,
    removeNonDigitsKeepColon,
    removeColons,
    toTimeString,
    hasValuesAfterIndex
}