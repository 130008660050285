import {UddannelsesstedModel} from "../../../core/sharedmodels/laegeInstitution/uddannelsesstedModel";

interface UddannelsesstedItemProps {
    uddannelsessted: UddannelsesstedModel;
    itemSelected: (uddannelsessted: UddannelsesstedModel) => void;
    selected: boolean;
}

export function UddannelsesstedItem(props: UddannelsesstedItemProps){

    const uddannelsesstedSelected = () => {
        props.itemSelected(props.uddannelsessted);
    }

    return (
        <div className="padding-top-m padding-bottom-m padding-left-xs border-bottom">
            <input type="checkbox"
                   id={props.uddannelsessted.id}
                   onClick={()=>uddannelsesstedSelected()}
                   checked={props.selected}
                   onChange={() => props.itemSelected}
            />
            <label htmlFor={props.uddannelsessted.id}>{props.uddannelsessted.navn}</label>
        </div>
    )
}
