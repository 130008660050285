import React from "react";
import {Score} from "../../../core/components/score/score";
import {ScoreTypeEnum} from "../../../core/componentsPage/score/scoreTypeEnum";
import {SvarReadTypeProps} from "./svarReadTypeCompareMap";

export const MultiReadSvar = ({ spoergsmaal, evalueringSvar, showText }: SvarReadTypeProps) => {
    if (spoergsmaal.antalSvar === 1) {
        return <Score
            svarmuligheder={spoergsmaal.svarmuligheder}
            svar={evalueringSvar}
            showText={showText ?? true}
            scoreType={ScoreTypeEnum.KompetenceEvalueringScore}/>
    } else {
        return <li>{spoergsmaal.svarmuligheder.find(s => s.id === evalueringSvar.svarmulighedId)?.tekst}</li>
    }
};
