import {Localizer} from "infrastructure/localization/localizer";
import {SearchField} from "../../../../core/components/search/searchField";

type SearchFieldType = {
    callbackLaege: (search: string) => void;
    callbackKursus: (search: string) => void;
}

const SearchFieldsKursister = ({callbackLaege, callbackKursus}: SearchFieldType) => {

    return (
        <div className="flex-container d-flex margin-m">

                <SearchField
                    additionalClasses='margin-right-s'
                    minimumInput={3}
                    placeholder={Localizer.soegLaege()}
                    callback={(search) => callbackLaege(search)}
                />

                <SearchField
                    additionalClasses='margin-right-s'
                    minimumInput={3}
                    placeholder={Localizer.soegKursusTitel()}
                    callback={(search) => callbackKursus(search)}
                />

        </div>
    );
}

export default SearchFieldsKursister;
