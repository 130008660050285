import React, {useCallback, useState} from "react";
import {Localizer} from "infrastructure/localization/localizer";
import {VejlederHeader} from "core/components/headerCards/vejlederHeader";
import useLogbogApi from "core/hooks/useLogbogApi";
import {Loading} from "core/components/loading/loading";
import {Kursist} from "./kursist";
import {useKursisterContext} from "../kursisterOverviewContextProvider";
import Title from "../../uddannelseslaegeOverview/components/uddannelseslaegerTitle";
import SearchFieldsKursister from "./filter/searchFieldsKursister";
import {PaginationModel} from "../../../core/sharedmodels/filter/PaginationModel";
import {Pagination} from "../../../core/components/pagination/pagination";
import Container from "core/components/container/container";

const UddannelseslaegerList = () => {
    const { logbogUserInfoApi } = useLogbogApi();
    const { listReducer, statusReducer, filterReducer} = useKursisterContext();

    const [paginationModelState, setPaginationModelState] = useState(new PaginationModel(1, 10, 0));
    const setIsLoading = useCallback((isLoading: boolean) => statusReducer.dispatch({isLoading: isLoading}), [statusReducer]);

    async function getForloebWithKursusGrupper(searchLaeger: string, searchKurser: string, page?: number) {
        if (searchLaeger !== "" || searchKurser !== "") {
            setIsLoading(true);
            const forloebWithKursusGrupper = await logbogUserInfoApi.getLaegerWithKurser(searchLaeger, searchKurser, page ?? 1);
            setPaginationModelState(current => ({...current, recordCount: forloebWithKursusGrupper.recordsFiltered, page: page ?? 1}))
            listReducer.dispatch({forloebWithKursusGrupper: forloebWithKursusGrupper.data});
            setIsLoading(false);
        } else {
            setPaginationModelState(new PaginationModel(1, 10, 0))
            listReducer.dispatch({forloebWithKursusGrupper: []});
        }
    }

    const handlePagination = async (page: number) => {
        await getForloebWithKursusGrupper(filterReducer.state.searchLaege, filterReducer.state.searchKursus, page);
    };

    async function callbackLaege(search: string){
        filterReducer.dispatch({
            ...filterReducer.state,
            searchLaege: search,
        });
        await getForloebWithKursusGrupper(search, filterReducer.state.searchKursus);
    }

    async function callbackKursus(search: string){
        filterReducer.dispatch({
            ...filterReducer.state,
            searchKursus: search,
        });
        await getForloebWithKursusGrupper(filterReducer.state.searchLaege, search);
    }

    return (
        <>
            <VejlederHeader/>

            <Container>

                <div className="card-header">
                    <Title text={Localizer.global_uddannelseslaeger()} />
                </div>

                <SearchFieldsKursister callbackLaege={(search) => callbackLaege(search)} callbackKursus={(search) => callbackKursus(search)} />

                {listReducer.state.forloebWithKursusGrupper?.length === 0 && !filterReducer.state.searchLaege && !filterReducer.state.searchKursus &&
                    <div className="col-sm-12">
                        <p>{Localizer.startSoegningForAtFremsoegeLaeger()}</p>
                    </div>
                }

                {listReducer.state.forloebWithKursusGrupper.flatMap(x => x.forloebInfo)?.length === 0 && (filterReducer.state.searchLaege || filterReducer.state.searchKursus) && !statusReducer.state.isLoading &&
                    <div className="col-sm-12">
                        <p>{Localizer.noResults()}</p>
                    </div>
                }

                <Loading isLoading={statusReducer.state.isLoading} text={Localizer.global_getData()} timeout={100} spinnerClasses={"margin-bottom-m margin-left-m"}>
                    <div className="card-body padding-0 uddannelseslaeger-list">
                        {listReducer.state.forloebWithKursusGrupper?.length > 0 &&
                            listReducer.state.forloebWithKursusGrupper?.map(forloebWithKursusGrupper => forloebWithKursusGrupper.forloebInfo?.map(forloeb =>
                                    <div className={`card-list card-list-border-bot`}>
                                        <Kursist
                                            key={forloeb.forloebId}
                                            forloeb={forloeb}
                                            forloebWithKursusGrupper={forloebWithKursusGrupper}
                                        />
                                    </div>
                                )
                            )
                        }
                    </div>
                    <div className="card-footer flex-row-center">
                        <Pagination handlePagination={handlePagination}
                                    paginationModel={paginationModelState}/>
                    </div>
                </Loading>
            </Container>
        </>
    );
}

export default UddannelseslaegerList;
