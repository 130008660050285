import { forloebOverviewMenuLink, brugereMenuLink, evalueringsstatistikMenuLink, nyhederMenuLink } from "core/layout/shared/MenuLinkProps";
import { DynamicMenu } from "../DynamicMenu";
import MenuProps from "./menuProps";

export function LedelseMenu({ showNotification, nyhederMenuLink }: MenuProps) {

    const menuLinks = [
        forloebOverviewMenuLink,
        brugereMenuLink,
        evalueringsstatistikMenuLink,
        nyhederMenuLink
    ];

    return (
        <DynamicMenu menuLinks={menuLinks} showNotifications={showNotification}/>
    );

}
