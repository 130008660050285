import * as yup from "yup";
import createValidationSchema from "../../components/validation/createValidationSchema";
import {Localizer} from "../../../infrastructure/localization/localizer";

export class TidsbegraensetAdgangInputModel {
    public forloebId: string;
    public brugerId: string;
    public dato: Date;
}

export const BuildNew = (forloebId: string, brugerId: string): TidsbegraensetAdgangInputModel => {
    let inputModel = new TidsbegraensetAdgangInputModel();

    inputModel.forloebId = forloebId;
    inputModel.brugerId = brugerId;

    return inputModel;
}

export const tidsbegraensetAdgangValidationSchema = createValidationSchema<TidsbegraensetAdgangInputModel>({
    forloebId: yup.string().notRequired(),
    brugerId: yup.string().required(Localizer.validate_vejlederRequired()),
    dato: yup.date().required(Localizer.validate_datoRequired())
});
