import createValidationSchema from "../../../core/components/validation/createValidationSchema";
import * as yup from "yup";
import {Localizer} from "../../../infrastructure/localization/localizer";

export class HovedvejlederInputModel {
    public constructor(init?:Partial<HovedvejlederInputModel>) {
        Object.assign(this, init);
    }
    public hovedvejleder: string;
}

export const BuildExisting = (hovedvejleder: string): HovedvejlederInputModel => {
    return new HovedvejlederInputModel({ hovedvejleder: hovedvejleder });
}

export const crudForloebevalueringHovedvejlederValidationSchema = createValidationSchema<HovedvejlederInputModel>({
    hovedvejleder: yup.string().required(Localizer.validate_HovedvejlederRequired())
});
