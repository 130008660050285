import React, {useCallback, useEffect, useState} from "react";
import {Localizer} from "infrastructure/localization/localizer";
import {SetTitleBar} from "../../core/layout/titleBar/titleBarApi";
import {NotatList} from "./components/notatList";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {forloebReducer, ForloebSliceState} from "../../core/forloebSlice";
import {useRouteMatch} from "react-router-dom";
import {InitForloebState} from "../forloeb/forloebApi";
import {Loading} from "../../core/components/loading/loading";
import {EmblaIcon} from "../../core/components/emblaIcon/emblaIcon";
import {SlideIn} from "../../core/components/slideIn/slideIn";
import {CreateNotat} from "../../core/componentsPage/notat/tekstNotat/createNotat";
import {GetNotatTypeEnumTranslation, NotatType} from "../../core/sharedmodels/notat/notatType";
import {NotificationModule} from "ditmer-embla";
import {Pagination} from "../../core/components/pagination/pagination";
import {PaginationModel} from "../../core/sharedmodels/filter/PaginationModel";
import {NotatFilterModel} from "../../core/componentsPage/notat/notatFilterModel";
import {BasicNotatModel} from "../../core/sharedmodels/notat/basicNotatModel";
import {GetNotatSynlighedTranslation, NotatSynlighedEnum} from "../../core/sharedmodels/notat/notatSynlighedEnum";
import {MobileFilter} from "../../core/components/filter/mobileFilter";
import {FilterReducer, FilterState, setFilerCount} from "../../core/components/filter/filterSlice";
import {debounce} from "lodash";
import {Dropdown, DropdownOption} from "../../core/components/dropdown/dropdown";
import useEnumDropdownOptions from "../../core/components/dropdown/hooks/useEnumDropdownOptions";
import {SingleValue} from "react-select";
import useScreenResolution from "core/hooks/useScreenResolution";
import useLogbogApi from "core/hooks/useLogbogApi";
import PageLengthDropdown, { all, defaultPageSizeDropdownRange } from "core/components/dropdown/components/customDropdowns/pageLengthDropdown";

type NotaterPageProps = {
    hideNoForloebHeader?: boolean
}

export const NotaterPage = (props: NotaterPageProps) => {
    const routeMatch = useRouteMatch();
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;
    const { isMobile } = useScreenResolution();
    const { notatApi } = useLogbogApi();

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingCards, setIsLoadingCards] = useState(false);
    const [notater, setNotater] = useState([] as BasicNotatModel[]);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [synligForSelectOptions] = useEnumDropdownOptions<NotatSynlighedEnum>(NotatSynlighedEnum, GetNotatSynlighedTranslation, [NotatSynlighedEnum.SynligForMig]);
    const [kategoriSelectOptions] = useEnumDropdownOptions<NotatType>(NotatType, GetNotatTypeEnumTranslation, [NotatType.Ansoegning, NotatType.Dokumentation]);

    const defaultPageAfterFiltersApplied = 1;
    const initialPageSize = 5;
    const [filter, setFilter] = useState(new NotatFilterModel(new PaginationModel(1, initialPageSize, 0)));
    const [recordCount, setRecordCount] = useState(0);
    const filterState = useAppSelector(FilterReducer) as FilterState;

    const modalTarget = "tekstnotat-create-modal";
    const notatMobileFilterTarget = "notat-list";

    const routeParams = routeMatch.params as { id: string };
    const uddannelseslaegeId = routeParams.id;

    const isUddannelseslaegeSpecifik = !!uddannelseslaegeId;

    const dispatch = useAppDispatch();
    SetTitleBar(Localizer.global_notater(), [{ text: Localizer.global_notater(), url: "" }]);

    const loadNotatList = useCallback(async () =>  {
        setIsLoadingCards(true);
        if (isUddannelseslaegeSpecifik) {
            const forloeb = await InitForloebState(dispatch, forloebSliceState.forloebState, uddannelseslaegeId);
            if (forloeb.id) {
                const notaterFromApi = await notatApi.getNotaterByForloebId(forloeb.id, filter);
                setNotater(notaterFromApi.alleNotaterModels);
                setRecordCount(notaterFromApi.totalRecords);
            } else {
                setNotater([] as BasicNotatModel[]);
            }
        } else {
            const notaterFromApi = await notatApi.getAllAccessibleNotater(filter);
            setNotater(notaterFromApi.alleNotaterModels);
            setRecordCount(notaterFromApi.totalRecords);
        }
        setIsLoadingCards(false);
    },[dispatch, filter, forloebSliceState.forloebState, isUddannelseslaegeSpecifik, notatApi, uddannelseslaegeId])

    useEffect(() => {
        setIsLoading(true);
        dispatch(setFilerCount(0));
        const loadNotater = async () => {
            await loadNotatList();
        }
        loadNotater();
        setIsLoading(false);
    }, [dispatch, loadNotatList]);

    const tekstNotatOprettet = async () => {
        await loadNotatList();
        NotificationModule.showSuccess(Localizer.notatPage_notatCreated(), "");
    }

    const tekstNotatRedigeret = async () => {
        await loadNotatList();
    }

    const onTekstNotatFilesUpdated = async () => {
        await loadNotatList();
    }

    const tilfoejTekstNotat = () => setCreateModalOpen(true);

    const handleChangeKategori = (newValue: SingleValue<DropdownOption<NotatType>>) => {
        setFilter(current => (
            {
                ...current,
                type: newValue?.value,
                paginationModel: {
                    ...current.paginationModel,
                    page: defaultPageAfterFiltersApplied
                }
            }
        ));
    }

    const handleChangeSyngligFor = (newValue: SingleValue<DropdownOption<NotatSynlighedEnum>>) => {
        setFilter(current => (
            {
                ...current,
                synligfor: newValue?.value,
                paginationModel: {
                    ...current.paginationModel,
                    page: defaultPageAfterFiltersApplied
                }
            }
        ));

    }

    const changeSearch = async (search: string) => {
        setFilter(current => (
            {
                ...current,
                search: search,
                paginationModel: {
                    ...current.paginationModel,
                    page: defaultPageAfterFiltersApplied
                }
            }
        ));
    }

    const handlePagination = async (page: number) => {
        setFilter(current => ({...current, paginationModel: {
            ...current.paginationModel,
            page: page
        }}));
    };

    const setPageLength = (length: number) => {
        setFilter(current => ({...current, paginationModel: {
            ...current.paginationModel,
            page: defaultPageAfterFiltersApplied,
            pageLength: length
        }}));
    }

    const filterContent = () => {
        return (
            <>
                <div className={`margin-right-s min-w-60 ${isMobile ? "margin-bottom-m" : ""}`}>
                    <PageLengthDropdown
                        onChange={(newValue) => setPageLength(newValue!.value)}
                        range={[initialPageSize, 10, 15, 20, 25, all]}
                        showTitle={isMobile}
                    />
                </div>

                <div className="margin-right-s min-w-200">
                    <Dropdown
                        placeholder={Localizer.vaelgKategori()}
                        options={kategoriSelectOptions.sort((a, b) => a.label > b.label ? 1 : -1)}
                        onChange={handleChangeKategori}
                        value={kategoriSelectOptions.filter(x => x.value === filter.type)}
                        isClearable
                    />
                </div>
                {
                    isUddannelseslaegeSpecifik &&
                    <div className="margin-right-s min-w-200">

                        <Dropdown
                            placeholder={Localizer.kanSesAf()}
                            options={synligForSelectOptions}
                            onChange={handleChangeSyngligFor}
                            value={synligForSelectOptions.filter(x => x.value === filter.synligfor)}
                            isClearable
                        />
                    </div>
                }
            </>

        );
    }

    return (
        <Loading isLoading={isLoading} text={Localizer.notatPage_gettingNotater()}>
            {!forloebSliceState.forloebState.id && !props.hideNoForloebHeader &&
                <div className="card header-card forloeb-header">
                    <div className="flex-space">
                        <h3 className='forloeb-header-txt'>
                            {Localizer.forloebpage_NoForloeb()}
                        </h3>

                    </div>
                </div>
            }
            <div className="row top-level-row">
                <div className="col-md-12">
                    <div className="card top-level-container">
                        <div className="card-header">
                            <h3 className="card-title">
                                {Localizer.global_alleNotater(!recordCount ? "0" : recordCount.toString())}
                            </h3>
                            {isMobile &&
                                <div className="margin-right-s">
                                    <MobileFilter id={notatMobileFilterTarget} title={Localizer.global_filtrer()} content={filterContent()} filterCount={filterState.filterCount}/>
                                </div>
                            }
                            {
                                isUddannelseslaegeSpecifik &&
                                <div className="card-actions">
                                    <button type="button" className="btn btn-default rounded-circle btn-icon"
                                            aria-label="Primary"
                                            onClick={() => tilfoejTekstNotat()}>
                                        <EmblaIcon iconName="plus"/>
                                    </button>
                                </div>
                            }
                        </div>
                        <div className="card-body flex-space flex-container">
                            {!isMobile &&
                                <div className="flex-container">
                                    {filterContent()}
                                </div>
                            }
                            <div className={`input-icon-container ${isMobile ? "flex-grow" : "uddannelseslaege-search"}`}>
                                <input className="form-control"
                                       onChange={debounce((event) => changeSearch(event.target.value), 800)}
                                       placeholder={Localizer.global_indtastSoegning()}/>
                                <div className="input-icon">
                                    <EmblaIcon iconName="search"/>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <Loading isLoading={isLoadingCards} text={Localizer.notatPage_gettingNotater()} emptyText={notater?.length < 1 ? Localizer.noNotat() : ""}>
                                <NotatList notater={notater} notesUpdatedCallback={tekstNotatRedigeret} filesUpdatedCallback={onTekstNotatFilesUpdated}/>
                            </Loading>
                        </div>
                        {createModalOpen &&
                            <SlideIn
                                id={modalTarget}
                                title={Localizer.notatPage_opretNotat()}
                                actionText={Localizer.notatPage_creatingNotat()}
                                actionFinishedCallback={() => {
                                    setCreateModalOpen(false);
                                    tekstNotatOprettet();
                                }}
                                actionOnCloseCallback={() => setCreateModalOpen(false)}
                                bodyContent={<CreateNotat modalId={modalTarget} notatType={NotatType.TekstNotat}/>}
                                defaultOpen={true}
                            />
                        }
                        <div className="card-footer flex-row-center">
                            <Pagination
                                handlePagination={handlePagination}
                                paginationModel={new PaginationModel(filter.paginationModel.page, filter.paginationModel.pageLength, recordCount)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Loading>
    )
}



