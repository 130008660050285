import React, {useState} from "react";
import {SetTitleBar} from "../../core/layout/titleBar/titleBarApi";
import {Localizer} from "../../infrastructure/localization/localizer";
import {
    NotifikationEventTypeEnum, NotifikationEventTypeEnumToTitleString
} from "../../core/components/notifications/models/notificationModel";
import {Loading} from "../../core/components/loading/loading";
import NotificationListItem
    from "../../core/components/notifications/notificationOverview/notificationListItem/notificationListItem";
import {SearchField} from "../../core/components/search/searchField";
import {Pagination} from "../../core/components/pagination/pagination";
import useEnumDropdownOptions from "../../core/components/dropdown/hooks/useEnumDropdownOptions";
import {Dropdown, DropdownOption} from "../../core/components/dropdown/dropdown";
import {MultiValue} from "react-select";
import PageLengthDropdown, {all} from "../../core/components/dropdown/components/customDropdowns/pageLengthDropdown";
import useScreenResolution from "../../core/hooks/useScreenResolution";
import useNotificationHistory from "./hooks/useNotificationHistory";
import {NotificationHistoryConstants} from "./NotificationHistoryConstants";

export function NotifikationHistorikPage() {

    const { isMobile } = useScreenResolution();
    const {refetchNotificationHistory, notificationHistory, isLoading, paginationState} = useNotificationHistory();

    const [searchState, setSearchState] = useState<string>();
    const [notifikationEventTypeDropdownOptions] = useEnumDropdownOptions<NotifikationEventTypeEnum>(NotifikationEventTypeEnum, NotifikationEventTypeEnumToTitleString);
    const [selectedNotificationTypeOptions, setSelectedNotificationTypeOptions] = useState<NotifikationEventTypeEnum[]>([]);

    SetTitleBar(Localizer.notifikationer_Notifikationshistorik(), [{ text: "", url: "" }]);

    const handleNotificationTypeSelected = async (selectedOption: MultiValue<DropdownOption<NotifikationEventTypeEnum>> = []) => {
        const selectedValue = selectedOption.map(e => e.value);
        setSelectedNotificationTypeOptions(selectedValue);
        await refetchNotificationHistory(NotificationHistoryConstants.InitialPage, paginationState.pageLength, searchState, selectedValue)
    }

    const callbackSearch = async (search: string) => {
        setSearchState(search);
        await refetchNotificationHistory(NotificationHistoryConstants.InitialPage, paginationState.pageLength, search, selectedNotificationTypeOptions);
    }

    const handlePagination = async (page: number) => {
        await refetchNotificationHistory(page, paginationState.pageLength, searchState, selectedNotificationTypeOptions);
    }

    const pageLengthChanged = async (pageLength: number) => {
        await refetchNotificationHistory(NotificationHistoryConstants.InitialPage, pageLength, searchState, selectedNotificationTypeOptions)
    }

    return (
        <div className="card top-level-container">
            <div className="card-header">
                <h3 className="card-title">{Localizer.global_notifikationer()}</h3>
            </div>
            <div className="flex-container d-flex margin-m ">
                <div className={`margin-right-s min-w-60`}>
                    <PageLengthDropdown
                        onChange={(newValue) => pageLengthChanged(newValue!.value)}
                        range={[NotificationHistoryConstants.InitialPageSize, 15, 20, 25, all]}
                        showTitle={false}
                    />
                </div>
                <SearchField
                    additionalClasses='margin-right-s'
                    minimumInput={3}
                    placeholder={Localizer.global_soeg()}
                    callback={(search) => callbackSearch(search)}/>
                <Dropdown
                    placeholder={isMobile ? Localizer.notifikationer_Notifikationstype() : Localizer.notifikationer_VaelgNotifikationstype()}
                    options={notifikationEventTypeDropdownOptions}
                    onChange={(newVal) => handleNotificationTypeSelected(newVal)}
                    value={notifikationEventTypeDropdownOptions.filter(x => selectedNotificationTypeOptions.includes(x.value))}
                    isClearable
                    isMulti
                />
            </div>

            <Loading isLoading={isLoading} text={Localizer.notifikationer_HenterNotifikationshistorik()} spinnerClasses={"margin-m"}>
                {((searchState || notificationHistory?.recordsTotal === 0) && notificationHistory?.recordsFiltered === 0 && !isLoading) ?
                    <div className="col-sm-12">
                        <p>{Localizer.noResults()}</p>
                    </div>
                    :
                <div className="card-body ">
                    {notificationHistory?.data
                        .map((notification) =>
                            <div key={notification.id}>
                                <NotificationListItem notification={notification}/>
                            </div>
                        )
                    }
                </div>
                }
                <div className="card-footer flex-row-center">
                    <Pagination handlePagination={handlePagination}
                                paginationModel={paginationState}/>
                </div>
            </Loading>
        </div>
    );
}

