import createValidationSchema from "../../../../core/components/validation/createValidationSchema";
import * as yup from "yup";
import {Localizer} from "../../../../infrastructure/localization/localizer";
import {VejledersamtaleEnum} from "../vejledersamtaleModel";
import {nameof} from "ts-simple-nameof";
import dayjs from "dayjs";

export class CrudVejledersamtaleInputModel {
    constructor(forloebId: string, forloebOnUserId: string, vejledersamtaleType: VejledersamtaleEnum, titel?: string) {
        this.forloebId = forloebId;
        this.titel = titel ?? "";
        this.dato = new Date();
        this.vejleder = "";
        this.referat = "";
        this.vejledersamtaleType = vejledersamtaleType;
        this.visibleToUserIds = [];
        this.publicAccess = true;
        this.forloebOnUserId = forloebOnUserId;
        this.isPrivateSamtale = false;
        this.klarmeldtAt = undefined;
        this.shouldSendCalendarInvi = true;
    }

    public id: string;
    public titel: string;
    public dato: Date;
    public starttidspunkt?: Date;
    public sluttidspunkt?: Date;
    public vejleder: string;
    public referat: string;
    public forloebId: string;
    public vejledersamtaleType: VejledersamtaleEnum;
    public publicAccess: boolean;
    public visibleToUserIds: string[];
    public forloebOnUserId: string;
    public shouldSendCalendarInvi: boolean;
    public isPrivateSamtale: boolean;
    public klarmeldtAt?: string;
}

export const BuildExisting = (id: string, forloebId: string, forloebOnUserId: string,  titel:string, dato: Date, vejleder: string, referat: string, vejledersamtaleType: VejledersamtaleEnum, publicAccess: boolean, visibleToUserIds: string[], isPrivate: boolean, starttidspunkt?: Date, sluttidspunkt?: Date, klarmeldtAt?: string): CrudVejledersamtaleInputModel =>  {

    const inputModel = new CrudVejledersamtaleInputModel(forloebId, forloebOnUserId, vejledersamtaleType);

    inputModel.id = id;
    inputModel.titel = titel;
    inputModel.dato = dato;
    inputModel.starttidspunkt = starttidspunkt;
    inputModel.sluttidspunkt = sluttidspunkt;
    inputModel.vejleder = vejleder;
    inputModel.referat = referat;
    inputModel.publicAccess = publicAccess;
    inputModel.isPrivateSamtale = isPrivate
    inputModel.visibleToUserIds = publicAccess ? [VisibleToEveryoneKey] : visibleToUserIds;
    inputModel.klarmeldtAt = klarmeldtAt;

    return inputModel;
}

export const VisibleToEveryoneKey = nameof<CrudVejledersamtaleInputModel>(x => x.publicAccess);
export const VisibleToUsers = nameof<CrudVejledersamtaleInputModel>(x => x.visibleToUserIds);
export const PrivateSamtaleKey = nameof<CrudVejledersamtaleInputModel>(x => x.isPrivateSamtale);

export const crudVejledersamtaleValidationSchema = createValidationSchema<CrudVejledersamtaleInputModel>({
    id: yup.string().notRequired(),
    forloebId: yup.string().notRequired(),
    vejledersamtaleType: yup.number().notRequired(),
    titel: yup.string().required(Localizer.validate_titelRequired()),
    dato: yup.date()
        .required(Localizer.validate_dateRequired()).test(
            "date-validation",
            Localizer.validate_dateInvalid(),
            (value) => {
                value?.setHours(0, 0, 0, 0);
                return dayjs(value).isValid();
            }
        ),
    starttidspunkt: yup.date()
        .when(nameof<CrudVejledersamtaleInputModel>(x => x.shouldSendCalendarInvi), {
            is: true,
            then: yup.date().required(Localizer.validate_starttidspunktRequired()),
            otherwise:  yup.date().notRequired()
        })
        .test(
            "date-validation",
            Localizer.validate_tidspunktInvalid(),
            (value) => dayjs(value).isValid()
        ),
    sluttidspunkt: yup.date()
        .when(nameof<CrudVejledersamtaleInputModel>(x => x.shouldSendCalendarInvi), {
            is: true,
            then: yup.date().required(Localizer.validate_sluttidspunktRequired()),
            otherwise:  yup.date().notRequired()
        })
        .test(
            "date-validation",
            Localizer.validate_tidspunktInvalid(),
            (value) => dayjs(value).isValid()
        )
        .test(
            "date-validation",
            Localizer.validate_sluttidspunktBeforeStarttidspunkt(),
            function (value) {
                const parent = this.parent as CrudVejledersamtaleInputModel;
                if (parent.starttidspunkt?.timeFormat(true) === undefined && parent.sluttidspunkt?.timeFormat(true) === undefined)
                    return true;
                if (parent.starttidspunkt !== undefined)
                    return !value || value?.timeFormat(true) > parent.starttidspunkt?.timeFormat(true);
                return true;
            }
        ),
    vejleder: yup.string().required(Localizer.validate_vejlederRequired()),
    referat: yup.string().notRequired(),
    publicAccess: yup.bool().notRequired(),
    visibleToUserIds: yup.array().min(1, Localizer.validate_visibilityRequired()),
    forloebOnUserId:yup.string().required(),
    isPrivateSamtale: yup.bool().notRequired(),
    shouldSendCalendarInvi: yup.bool().notRequired()
});
