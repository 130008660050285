interface ILandingPageUserCardProps {
    logo: any;
    userRole: string;
    userDescription: string;
}

export const LandingPageUserCard = (props: ILandingPageUserCardProps) => {
    return <>
        <div className="card shadow-none">
            <div className="card-body">
                <div className={"flex-container margin-bottom-s"}>
                    {props.logo}
                    <p className="font-weight-bold margin-top-s margin-left-s">
                        {props.userRole}
                    </p>
                </div>
                <p>
                    {props.userDescription}
                </p>
            </div>
        </div>
    </>
}
