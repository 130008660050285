import "./caption.scss";
import {EmblaIcon} from "../emblaIcon/emblaIcon";
import { EmblaIcons } from "core/emblaIcons";

export type CaptionProps = {
    text: string;
    showIcon?: boolean;
}

export const Caption = ({text, showIcon=true} : CaptionProps) => {
    return (
        <>
            <div className={"caption-wrapper"}>
                { showIcon &&
                    <EmblaIcon iconName={EmblaIcons.Warning} additionalClasses={"small subtle caption"} />
                }
                <span className={`small subtle caption ${showIcon ? "margin-left-xs" : ""}`}>
                    {text}
                </span>
            </div>
        </>
    )
}
