import Container from "core/components/container/container";
import useStamdataApi from "core/hooks/useStamdataApi";
import { SetTitleBar } from "core/layout/titleBar/titleBarApi";
import BrevskabelonVisibilityTypeEnum from "infrastructure/enums/brevskabelonVisibilityTypeEnum";
import { Localizer } from "infrastructure/localization/localizer";
import { userBrevskabelonCreateQueryParams } from "infrastructure/route/queryParams";
import { RoutePaths } from "infrastructure/routes";
import { useHistory, useRouteMatch } from "react-router-dom";
import BrevskabelonDto, { emptyBrev } from "services/api/brevskabelon/models/brevskabelonDto";
import BrevskabelonCrudFields from "./BrevskabelonCrudFields";
import { EmblaIcons } from "core/emblaIcons";
import { NotificationModule } from "ditmer-embla";
import { ValidationForm } from "core/components/validation/components/validationForm";
import createBrevValidationSchema from "./validationSchema/createBrevValidationSchema";
import { HandleError } from "core/infrastructure/errors/errorBoundary";
import { ValidationFormSubmitButton } from "core/components/validation/components/validationFormButton";
import BrevPreview from "./BrevPreview";
import { EmblaIcon } from "core/components/emblaIcon/emblaIcon";

export const validateCrudActionOnBrev = (brev: BrevskabelonDto) => {
    if(brev.visibilityType === BrevskabelonVisibilityTypeEnum.Region && !brev.regionId) 
        return false;

    return brev.titel && brev.subject && brev.bodyMarkdown;
}

const brevCreateModalId = "brevCreateModalId";

const BrevCreatePage = () => {

    const { push } = useHistory();
    const { brevskabelonApi} = useStamdataApi();
    const { uddannelseslaege } = useRouteMatch<userBrevskabelonCreateQueryParams>().params;

    SetTitleBar(
        Localizer.letterPage_CreateTemplate(),
        [{ text: Localizer.global_letters(), url: RoutePaths.Brevskabeloner({uddannelseslaege}).url}]
    );

    const onCreateClick = async (values: BrevskabelonDto) => {
        brevskabelonApi
            .create(values)
            .then((createdBrevId) => {
                NotificationModule.showSuccess(Localizer.letterPage_letterTemplateCreated(), "");
                push(RoutePaths.BrevskabelonerEditPage({
                    uddannelseslaege: uddannelseslaege,
                    brev: createdBrevId
                }).url);
            })
            .catch((err) => {
                NotificationModule.showError(Localizer.letterPage_creationError(), "");
                HandleError(err);
            });
    };

    return (
        <ValidationForm
            initialValues={emptyBrev}
            validationSchema={createBrevValidationSchema}
            onSubmit={async (values) => onCreateClick(values)} 
        >
        {(formik) => (
            <>
                <Container>
                    <Container.Header childrenIsHeaderActions titleConfig={{title: Localizer.letterPage_template()}}>
                        <ValidationFormSubmitButton formikProps={formik}>
                            <EmblaIcon iconName={EmblaIcons.Save} />
                            {Localizer.global_save()}
                        </ValidationFormSubmitButton>
                    </Container.Header>

                    <Container.Body additionalClasses="uddannelseslaeger-list">
                        <BrevskabelonCrudFields
                            modalId={brevCreateModalId}
                            formik={formik}
                        />
                    </Container.Body>
                </Container>

                <div className="margin-top-m">
                    {formik.values &&
                        <BrevPreview uddannelseslaegeId={uddannelseslaege} markdown={formik.values.bodyMarkdown} id={formik.values.id}/>
                    }
                </div>
            </>
        )}
        </ValidationForm>
    );
}

export default BrevCreatePage;
