import { useEffect } from "react";
import { useHistory } from "react-router-dom"

export type LinkHistoryState<TState = unknown> = {
  isLink: boolean;
} & TState;

const HistoryActionTypes = {
  POP: "POP",
  PUSH: "PUSH"
} as const;

// https://stackoverflow.com/questions/52507789/how-to-define-state-when-using-history-goback
/** Use link specific history (used when wanting to react to a link to a component) */
const useLinkHistory = <TState = unknown> () => {
  const history = useHistory<LinkHistoryState<TState>>();

  useEffect(() => {
    const stopListen = history.listen((location, action) => {
      const resetState = action === HistoryActionTypes.POP;

      if (resetState && !!location.state?.isLink) {
          history.replace(location.pathname, { ...location.state, isLink: false });
      }
    });

    return () => {
      stopListen();
    }
  }, [history]);

  const clearLocationState = () => {
    if (!!history.location.state?.isLink) {
      history.location.state.isLink = false;
    }
  }

  return {
    history,
    locationState: history.location.state,
    clearLocationState 
  };
}

export default useLinkHistory;