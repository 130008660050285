import {BuildNew, CreateMailValidationSchema} from "./createMailInputModel";
import {ValidationForm} from "../validation/components/validationForm";
import {MailInputFields} from "./mailInputFields";
import {ValidationFormSubmitButton} from "../validation/components/validationFormButton";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {SlideIn} from "../slideIn/slideIn";
import {ModalSize} from "../slideIn/modalSize";
import Tooltip, {TooltipPlacement} from "../tooltips/Tooltip";
import useUserEmail from "../../hooks/useUserEmail";
import { UserEmailModel } from "./UserEmailModel";

interface EmailSlideInProps {
    modalId: string;
    users: UserEmailModel[];
    actionFinishedCallback?: () => void;
}

export function EmailSlideInModal(props: EmailSlideInProps) {

    const {
        fileDeletedCallback, 
        CreateMail, 
        filesUploadedCallback, 
        setSelectedTemplateType
    } = useUserEmail(props.users, props.modalId, props.actionFinishedCallback, true)

    const userEmails = props.users.map(x => x.email).join(", ");

    const bodyContent = () => {
        return (
            <ValidationForm
                key={props.modalId}
                initialValues={BuildNew(props.users.map(u=>u.userId))}
                validationSchema={CreateMailValidationSchema}
                onSubmit={async (values) => {await CreateMail(values)}}>
                {
                    (formik) => (
                        <>
                            <label>{Localizer.createMail_MailSentTo()}</label>
                            <Tooltip className={"text-truncate user-emails margin-bottom-m flex-align-l"}
                                     title={userEmails}
                                     placement={TooltipPlacement.Bottom}>
                                {userEmails}
                            </Tooltip>
                            <MailInputFields modalId={props.modalId}
                                             formik={formik}
                                             templateTypeCallBack={setSelectedTemplateType}
                                             fileUploadedCallback={filesUploadedCallback}
                                             fileDeletedCallback={fileDeletedCallback}/>
                            <div className="modal-actions">
                                <ValidationFormSubmitButton formikProps={formik}>
                                    {Localizer.createMail_SendMail()}
                                </ValidationFormSubmitButton>
                            </div>
                        </>
                    )
                }
            </ValidationForm>
        );
    }

    return (
        <>
            <SlideIn
                id={props.modalId}
                title={Localizer.createMail_SendMail()}
                actionText={Localizer.createMail_QueuingMail()}
                actionOnCloseCallback={props.actionFinishedCallback}
                bodyContent={bodyContent()}
                modalSize={ModalSize.md}
            />
        </>
    );
}
