import {Score} from "../../../core/components/score/score";
import {DatasetModel} from "../datasetModel";
import {Localizer} from "../../../infrastructure/localization/localizer";
import React, {useState} from "react";
import {useAppSelector} from "../../../app/hooks";
import {
    evalueringsstatistikFiltersReducer,
    EvalueringsstatistikFiltersState
} from "../evalueringsstatistikFiltersSlice";
import {SpoergsmaalTypeEnum} from "../../../core/sharedmodels/evaluering/spoergsmaalTypeEnum";
import {EvalueringStatistikModel} from "../../../services/api/evalueringStatistik/evalueringStatistikReponseModel";
import {SlideIn} from "../../../core/components/slideIn/slideIn";
import {Spoergeramme} from "./spoergeramme";
import {ModalSize} from "../../../core/components/slideIn/modalSize";
import "../evalueringstatistik.scss";
import {NumberOfEvalueringer} from "./numberOfEvalueringer";
import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../../../core/layout/responsive";
import {toDate} from "../../../index";
import {ScoreTypeEnum} from "../../../core/componentsPage/score/scoreTypeEnum";

interface UddannelsesstedGruppeScoreCardComponentProps {
    dataset: DatasetModel;
    evaluering: EvalueringStatistikModel;
}

export function UddannelsesstedGruppeScoreCardComponent(props: UddannelsesstedGruppeScoreCardComponentProps) {
    const [openSpoergerammeModal, setOpenSpoergerammeModal] = useState<boolean>(false);
    const evalueringsstatistikFilterSliceState = useAppSelector(evalueringsstatistikFiltersReducer) as EvalueringsstatistikFiltersState;
    const spoergerammeModalTarget = "spoergeramme-modal-gruppe";
    const IsMobile = useMediaQuery(mobileMaxWidthQuery);

    function getGrupperetScoreData() {
        const spoergsmaalToShow = evalueringsstatistikFilterSliceState.spoergsmaalToShow.length;
        return <>
            {spoergsmaalToShow > 0 &&
                <>
                    {evalueringsstatistikFilterSliceState.spoergsmaalToShow.map((score, index) => {
                        return (
                            <Score key={props.evaluering.uddannelsesstedId + score + index} svarVaerdi={props.evaluering.gruppeScore[(+score) - 1].averageScore} withDecimal={true} showText={false} scoreType={ScoreTypeEnum.ForloebEvalueringScore}/>
                        )
                    })}
                </>
            }
            {spoergsmaalToShow === 0 &&
                <>
                    {props.evaluering.gruppeScore.map((gruppeScore, index) => {
                        return (<Score key={props.evaluering.uddannelsesstedId + gruppeScore.averageScore + index} svarVaerdi={gruppeScore.averageScore} withDecimal={true} showText={false} scoreType={ScoreTypeEnum.ForloebEvalueringScore}/>
                        )
                    })}
                </>
            }
        </>;
    }

    function getSingleQuestionsScoreData() {
        const spoergsmaalToShow = evalueringsstatistikFilterSliceState.spoergsmaalToShow.length;
        return <>
            {spoergsmaalToShow > 0 &&
                <>
                    {evalueringsstatistikFilterSliceState.spoergsmaalToShow.map((singleScore, index) => {
                        return (
                            <Score key={props.evaluering.uddannelsesstedId + singleScore + index} svarVaerdi={props.evaluering.singleAverageScore[+singleScore - 1]} withDecimal={true} showText={false} scoreType={ScoreTypeEnum.ForloebEvalueringScore}/>
                        )
                    })}
                </>
            }
            {spoergsmaalToShow === 0 &&
                <>
                    {props.evaluering.singleAverageScore.map((singleScore, index) => {
                        return (
                            <Score key={props.evaluering.uddannelsesstedId + singleScore + index} svarVaerdi={singleScore} withDecimal={true} showText={false} scoreType={ScoreTypeEnum.ForloebEvalueringScore}/>
                        )
                    })}
                </>
            }
        </>;
    }

    let IsGrouped = evalueringsstatistikFilterSliceState.spoergsmaalsType === SpoergsmaalTypeEnum.Grupperet;

    return (
        <div className="clickable-row" onClick={() => setOpenSpoergerammeModal(true)}>
            <div className="margin-top-s margin-bottom-s col ">
                <div className="row align-items-center">
                    <div className={IsGrouped ? "col-sm-7"  : "col-sm-2"}>
                        <div className="row">
                            <div className={"dataset-color-border"} style={{borderColor: props.dataset.datasetColor}}></div>
                            <div className="truncate max-uddannelsessted-width padding-left-m">
                                <h4 className="truncate">{props.evaluering?.sygehusNavn}</h4>
                                <h4 className="truncate">{props.evaluering?.omraadeNavn}</h4>
                                <p className="margin-0 truncate">{props.evaluering?.uddannelsesstedNavn}</p>
                            </div>
                        </div>

                    </div>
                    <div className={`${IsGrouped ? "col-sm-1" : "col-sm-2"} ${IsMobile ? "margin-left-s" : ""}`}>
                        {props.dataset.fromDate && props.dataset.toDate &&
                            <>
                                {IsMobile && <b>{Localizer.global_periode()}: </b>}{toDate(props.dataset.fromDate).dateWithoutTimeFormat(true)}&nbsp; {Localizer.global_til()} &nbsp;{toDate(props.dataset.toDate).dateWithoutTimeFormat(true)}
                            </>
                        }
                    </div>
                    <div className={`col-sm-1 ${IsMobile ? "margin-left-s" : ""}`}>
                        {IsMobile && <b>{Localizer.global_antal()}: </b>}<NumberOfEvalueringer antalForloeb={props.evaluering.antalForloeb} antalForloebEvalueret={props.evaluering.antalForloebEvalueret}/>
                    </div>
                    <div className={IsGrouped ? "col-sm-3"  : "col-sm-7"}>
                        {props.evaluering.gruppeScore.length > 0 &&
                            <div className={`d-flex wrap ${IsMobile ? "margin-left-s" : ""}`}>
                                {IsGrouped &&
                                    getGrupperetScoreData()
                                }
                                {evalueringsstatistikFilterSliceState.spoergsmaalsType === SpoergsmaalTypeEnum.Enkeltvis &&
                                    getSingleQuestionsScoreData()
                                }
                            </div>
                        }
                    </div>
                </div>
                {openSpoergerammeModal &&
                    <SlideIn
                        id={spoergerammeModalTarget}
                        title={props.evaluering?.uddannelsesstedNavn ?? Localizer.spoergerammeModal_spoergeramme()}
                        actionText={Localizer.spoergerammeModal_spoergeramme()}
                        defaultOpen={true}
                        actionFinishedCallback={() => setOpenSpoergerammeModal(false)}
                        actionOnCloseCallback={() => setOpenSpoergerammeModal(false)}
                        bodyContent={<Spoergeramme modalId={spoergerammeModalTarget}
                                                   spoergsmaalTypeEnum={evalueringsstatistikFilterSliceState.spoergsmaalsType}
                                                   uddannelsesstederEvalueringer={props.evaluering}
                                                   startDate={props.dataset.fromDate ? toDate(props.dataset.fromDate) : undefined}
                                                   endDate={props.dataset.toDate ? toDate(props.dataset.toDate) : undefined}
                        />}
                        modalSize={ModalSize.md}/>
                }

            </div>


        </div>
    )
}
