import "./notificationOverviewSlideIn.scss";
import useScreenResolution from "core/hooks/useScreenResolution";
import useNotificationMutations from "../hooks/useNotificationMutations";
import {createPortal} from "react-dom";
import {closeModal, SlideIn} from "core/components/slideIn/slideIn";
import {Localizer} from "infrastructure/localization/localizer";
import NotificationOverview from "./notificationOverview";
import React, {useState} from "react";
import {EmblaIcons} from "../../../emblaIcons";
import {RoutePaths} from "../../../../infrastructure/routes";
import {useHistory} from "react-router-dom";
import {ContextButtonInfo, ContextButtonMenu} from "../../contextMenu/contextButtonMenu";
import NotificationSettingsSlideIn from "../notificationSettings/notificationSettingsSlideIn";
import CounterCircle from "../counterCircle/counterCircle";
import useNotificationCount from "../hooks/useNotificationCount";

const notifikationerModalTargetId = "notifikation-overview-modal";

type NotificationOverviewSlideInProps = {
    notificationCount: number;
    showSlideIn: boolean;
    onCloseSlideInCallback: () => void;
    showCheckboxState?: boolean | undefined;
}

const NotificationOverviewSlideIn = (props: NotificationOverviewSlideInProps) => {
    const history = useHistory();
    const {isMobile} = useScreenResolution();
    const {markAllUserRoleNotificationsAsSeen} = useNotificationMutations();
    const [settingsModalIsOpen, setSettingsModalIsOpen] = useState(false);
    const {currentUnseenRoleNotificationCount} = useNotificationCount(true);
    const [showCheckboxState, setShowcheckboxState] = useState<boolean>(false);

    const navigateToHistory = async () => {
        closeModal(notifikationerModalTargetId);
        props.onCloseSlideInCallback();
        history.push(RoutePaths.NotifikationHistorik.url);
    }

    function createContextMenuButtons(): ContextButtonInfo[] {
        let buttons = new Array<ContextButtonInfo>()
        buttons.push(new ContextButtonInfo(navigateToHistory, Localizer.global_historik(), EmblaIcons.Calendar));
        buttons.push(new ContextButtonInfo(() => setSettingsModalIsOpen(true), Localizer.global_indstillinger(), EmblaIcons.Settings));
        buttons.push(new ContextButtonInfo(() => setShowcheckboxState(true), Localizer.notifikationer_MarkerSomLaestUlaest(), EmblaIcons.Tasks));
        if (currentUnseenRoleNotificationCount)
            buttons.push(new ContextButtonInfo(markAllUserRoleNotificationsAsSeen, Localizer.notifikationer_MarkerAlleSomSet(), EmblaIcons.Success));
        return buttons;
    }

    const slideInHeaderItems = (
        <>
            <CounterCircle additionalClasses="ml-2" size="large" count={props.notificationCount}/>
            <ContextButtonMenu buttons={createContextMenuButtons()} useButtonIcons={true} border={false} showLine={false} additionalClasses={"hamburger is-active margin-left-s"} />
        </>
    )

    const onDidRedirect = () => {
        if (isMobile) {
            closeModal(notifikationerModalTargetId);
            props.onCloseSlideInCallback();
        }
    }

    return (
        <>
            {props.showSlideIn &&
                createPortal(
                    <SlideIn
                        id={notifikationerModalTargetId}
                        defaultOpen={true}
                        title={Localizer.global_notifikationer()}
                        additionalHeaderItems={slideInHeaderItems}
                        bodyContent={<NotificationOverview setCheckboxStateFalse={() => setShowcheckboxState(false)} onDidRedirectToNotificationContext={onDidRedirect} showCheckbox={showCheckboxState}/>}
                        actionOnCloseCallback={props.onCloseSlideInCallback}
                    />,
                    document.body
                )
            }
            <NotificationSettingsSlideIn
                showSlideIn={settingsModalIsOpen}
                onCloseSlideInCallback={() => setSettingsModalIsOpen(false)}
            />
        </>
    )
}

export default NotificationOverviewSlideIn;
