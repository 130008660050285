import {RootTypeEnum} from "../../../core/sharedmodels/laegeInstitution/rootTypeEnum";

export class CrudUddannelsesstedInputModel {
    public startDato?: Date;
    public slutDato?: Date;
    public laegeinstitutionstype?: RootTypeEnum;
    public sygehusId: string;
    public afdelingId: string;
    public praksisId: string;
    public laeger: string[];
    public alleLaeger : boolean;
    public alleKbuLaeger : boolean;
    public alleIntroduktionsLaeger : boolean;
    public alleHoveduddannelsesLaeger : boolean;
}
