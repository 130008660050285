import {AccountService} from "../../../services/account/accountService";

const userService = new AccountService();
const user = userService.getUser();

export class EvalueringsstatistikPageConstants {
    public static pageSizeSelectOptions = [10, 25, 50, 75];
    public static numberOfQuestionsIncludingOptionalTextInput = 27;
    public static numberOfQuestionsExcludingOptionalTextInput = 26;
    public static numberOfGroups = 7;
    public static minimumAmountOfEvalueringer = 3;
    public static periodLengthInYears = 2;
    public static generelCommentsQuestionNumber = 27;

    //Hvis der skal åbnes op for flere roller - skal disse også tilføjes i access check når datasæt tilføjes
    public static getComplexView = user.IsAdminOrVus() || user.IsUddannelsesansvarlig() || user.IsLedelse() || user.IsSekretaer() || user.IsKonsulentForLaegeligVidereuddannelse();

    public static getDefaultvisning =  user.IsUddannelsesansvarlig() || user.IsLedelse() || user.IsSekretaer() || user.IsKonsulentForLaegeligVidereuddannelse();
    public static antalDefaultVisninger = 2; //inklusiv 0

    public static color0 = "#2791F5";
    public static color1 = "#52CF92";
    public static color2 = "#9D6FCD";
    public static color3 = "#858585";
    public static color4 = "#F1F1B5";
    public static color5 = "#A3E5A9";
    public static color6 = "#98E6E6";
    public static color7 = "#E698D4";
    public static color8 = "#E69898";
    public static color9 = "#E8A74F";
    public static color10 = "#E84F4F";
    public static fallbackColor = "#4F80E8";
}
