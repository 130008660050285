import {HttpClientService} from "../../httpClient/httpClientService";
import {AccountService} from "../../account/accountService";
import config from "../../../config/config";
import {ApiResponse} from "../apiResponse";
import {EmailTemplateModel} from "../../../core/sharedmodels/emailTemplate/emailTemplateModel";
import {StamdataEmailTemplateResponseModel} from "./stamdataEmailTemplateResponseModel";

export class StamdataEmailTemplateApi {
    private httpClientService: HttpClientService;
    private userService: AccountService;

    private baseUrl = () => config.stamdataApiUrl + "api/emailtemplate/";
    private MailTemplatesUrl = () => this.baseUrl() + "templates";

    constructor(httpClientService: HttpClientService, userService: AccountService,) {
        this.httpClientService = httpClientService;
        this.userService = userService;
    }

    async getMailTemplates(): Promise<EmailTemplateModel[]> {
        const result = await this.httpClientService.Get<ApiResponse<StamdataEmailTemplateResponseModel[]>>(this.MailTemplatesUrl());
        return result.data.map(mailTemplate => {
            return EmailTemplateModel.FromResponseModel(mailTemplate)
        })

    }
}
