import PdfComponent from "core/components/pdf/pdfComponent";
import HtmlHeading from "core/components/titels/HtmlHeadings";
import Title from "core/components/titels/title";
import { forwardRef } from "react";
import "./brevskabelonPdf.scss";
import SanitizedHtmlStringDiv from "core/components/sanitizedElements/sanitizedHtmlStringDiv";

type BrevskabelonPdfProps = { subject: string, htmlString: string };

const BrevskabelonPdf = forwardRef<any, BrevskabelonPdfProps>(({subject, htmlString}, forwardedRef) => {
    
    return (
        <div ref={forwardedRef}>
            <PdfComponent>
                <Title
                    title={subject}
                    heading={HtmlHeading.H3}
                    titleClasses="pdf-brevskabelon-header"
                />

                <SanitizedHtmlStringDiv
                    className="pdf-brevskabelon-body"
                    htmlString={htmlString}
                />
            </PdfComponent>
        </div>
    )
});

export default BrevskabelonPdf;
