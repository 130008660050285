import { useLocation } from "react-router"

/**
 * Used for making sure a page-component refreshes completely (refetch, etc.) when its refreshed (e.g. when calling history.push() with the same path)
 * @returns pageKey: Key to use on the page-component which should be refreshable via "history.push()"
 */
const useRefreshablePage = () => {
  const location = useLocation();

  return {
    pageKey: location.key
  }
}

export default useRefreshablePage;
