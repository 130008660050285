import { Dropdown, DropdownOption, DropdownProps } from "core/components/dropdown/dropdown";
import useForSelectModelDropdownOptions from "core/components/dropdown/hooks/useForSelectModelDropdownOptions";
import { ForSelectModel } from "core/sharedmodels/forSelectModel";
import { MultiValue, OnChangeValue, SingleValue } from "react-select";
import DropdownPermissions from "../dropdownPermissions/DropdownPermissions";
import { useEffect } from "react";
import useRestrictedDropdownOptions from "../hooks/useRestrictedDropdownOptions";

export type ForSelectDropdownGenericProps <T> = {
    initialSelectedOption?: T | T[];
    title?: string;
    placeholder?: string;
    permission?: DropdownPermissions;
    restrictedValues?: T[];
    disable?: boolean;
    onMenuCloseCallback?: () => void;
    disableSingleOptionsAutoSelection?: boolean;
    alwaysFilter?: boolean;
}

export type ForSelectDropdownProps <T, IsMulti extends boolean = false> = {
    onChangeCallback: (newValue: OnChangeValue<DropdownOption<T>, IsMulti>) => void;
    /** The fetch method that fetches ForSelectModels - use as follows: `apiFetchPromise{() => stamdataApi.getSelect()}` */
    apiFetchPromise: () => Promise<ForSelectModel[]>;
} & ForSelectDropdownGenericProps<T>
& Pick<DropdownProps<T, DropdownOption<T>, false, IsMulti>, "isMulti">;

/**
 * Generic dropdown that can be used with any endpoint returning ForSelectModel[].
 */
const ForSelectDropdown = <IsMulti extends boolean = false> ({ initialSelectedOption, onChangeCallback, apiFetchPromise, title="", placeholder="", permission, isMulti, restrictedValues=[], disable=false, onMenuCloseCallback, disableSingleOptionsAutoSelection=false, alwaysFilter=false} : ForSelectDropdownProps<string, IsMulti>) => {

    const { selectedOption, setSelectedOption, dropdownOptions } = useForSelectModelDropdownOptions(apiFetchPromise, permission, initialSelectedOption);
    const isSelectedDefined = selectedOption?.length === 0 ?? selectedOption !== undefined;

    const { restrictedDropdownOptions } = useRestrictedDropdownOptions(restrictedValues, dropdownOptions, isSelectedDefined || alwaysFilter);
    const oneOrNoneRestrictedIds = restrictedDropdownOptions.length <= 1;
    const singleOrNoneSelectedOption = oneOrNoneRestrictedIds || (!isSelectedDefined && oneOrNoneRestrictedIds);

    useEffect(() => {
        if(restrictedDropdownOptions.length === 1 && oneOrNoneRestrictedIds && !disableSingleOptionsAutoSelection) {
            setSelectedOption(restrictedDropdownOptions[0].value);
        }
    }, [oneOrNoneRestrictedIds, restrictedDropdownOptions, setSelectedOption, disableSingleOptionsAutoSelection]);

    return (
        <div className="margin-top-s">
            {title &&
                <label>{title}</label>
            }

            <Dropdown
                placeholder={placeholder}
                options={restrictedDropdownOptions}
                onChange={newValue => {

                    const selectedOptionValue = isMulti
                        ? (newValue as MultiValue<DropdownOption<string>>).map(x => x.value)
                        : (newValue as SingleValue<DropdownOption<string>>)?.value;
                    
                    setSelectedOption(selectedOptionValue);
                    onChangeCallback(newValue);
                }}
                value={restrictedDropdownOptions.filter(x => isMulti ? (selectedOption as string[])?.includes(x.value) : (selectedOption as string) === x.value)}
                isClearable
                isDisabled={disable || singleOrNoneSelectedOption}
                isMulti={isMulti}
                onMenuClose={onMenuCloseCallback}
            />

        </div>
    );
}

export default ForSelectDropdown;