import { EmblaIcons } from "core/emblaIcons";
import { Localizer } from "infrastructure/localization/localizer";
import { ButtonComponentProps } from "../../button/buttonComponentProps";
import DynamicButton from "../../button/dynamicButton";

type ApplyFiltrerButtonProps = ButtonComponentProps & {
    Id?: string;
    hasFilters?: boolean;
}

const ApplyFiltrerButton = ({Id, onClick, additionalClasses="", hasFilters=false}: ApplyFiltrerButtonProps) => {

    return (
        <>
            <DynamicButton
                id={Id}
                isPrimary={hasFilters}
                onClick={onClick}
                additionalClasses={additionalClasses}
            >
                <DynamicButton.TextIconItem iconName={EmblaIcons.Filter} text={Localizer.global_filtrer()} />
            </DynamicButton>
        </>
    );
}

export default ApplyFiltrerButton;