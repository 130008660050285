import { Field, ErrorMessage, useField } from "formik";
import React from "react";
import { ValidationInputProps } from "../validationModel";

type ValidationTextareaProps = {
    defaultRows?: number;
} & ValidationInputProps & React.HTMLProps<HTMLInputElement>;

export const ValidationTextarea = (props: ValidationTextareaProps) => {
    const [field, meta] = useField(props.model.htmlName);

    return (
        <>
            <div className="form-group margin-bottom-m">
                <label htmlFor={field.name}>{props.model.label}</label>

                <Field as="textarea"
                    name={field.name}
                    className={`form-control ${meta.error && meta.touched ? "input-validation-error" : ""}`}
                    placeholder={props.model.placeholder}
                    rows={props.defaultRows ?? 5}
                    disabled={props.readOnly ?? false}
                />

                <ErrorMessage name={field.name}>
                    {errMsg => <span className="errorMessage field-validation-error">{errMsg}</span>}
                </ErrorMessage>
            </div>
        </>
    );
}
