import { FileMetadata } from "core/sharedmodels/fileMetaData/fileMetaData";
import { UddannelseTypeEnum } from "core/sharedmodels/stilling/uddannelseTypeEnum";
import { StempelInputModel } from "../stempel/stempelInputModel";

export class logbogEmailStpsRequestModel{
    userId: string;
    uddannelsesLaegeId: string;
    brugerMaalbeskrivelseId: string;
    brugerKursusRaekkeId: string;
    ansoegningsId: string;
    uddannelsesId: string;
    uddannelseType: UddannelseTypeEnum;
    forloebId: string;

    userFiles: FileMetadata[]
    obligatoriskeFiles: string[]
    emailTypeEnum: number;
    stempelInputModel: StempelInputModel;
    eftersendt: boolean;

    constructor(init?: Partial<logbogEmailStpsRequestModel>) {
        Object.assign(this, init);
    }
}
