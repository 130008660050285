import {ForloebOverviewConstants} from "../forloebOverviewConstants";
import React, { PropsWithChildren } from "react";

interface YearsForOverviewProps {
    years: number[];
}

type MonthProps = {
    month: string;
    index: number;
}

type YearProps = {
    year: number;
}

type HeaderProps = {
    yearIndex: number;
}

export function YearsForOverview(props: YearsForOverviewProps) {
    const months = ForloebOverviewConstants.months;
    const timelineColumnStart = ForloebOverviewConstants.timelineColumnStart;

    const Month = ({month, index} : MonthProps) => {
        return (
            <div key={month} className="forloeb-month flex-al" style={{"--month-column-index": index} as React.CSSProperties}>
                <span className="subtle">{month}</span>
            </div>
        );
    }

    const Year = ({year} : YearProps) => {
        const yearStyle = {"--year-column-start": 1, "--year-column-end": 13} as React.CSSProperties
        return (
            <div className="forloeb-year subtle" style={yearStyle}>
                {year}
            </div>
        );
    }

    const Header = ({yearIndex, children}: PropsWithChildren<HeaderProps>) => {
        const yearColumnStart = yearIndex * months.length + timelineColumnStart;
        const yearColumnEnd = yearColumnStart + months.length;
        const headerStyle = {"--header-column-start": yearColumnStart, "--header-column-end": yearColumnEnd} as React.CSSProperties;
        return (
            <div className="forloeb-chart-header subtle" style={headerStyle}>
                {children}
            </div>
        );
    }

    return (
        <>
            {props.years.map((year, yearIndex) => {
                return (
                    <React.Fragment key={year.toString()}>
                        <Header yearIndex={yearIndex}>
                            <Year year={year}/>

                            {months.map((month, monthIndex) =>
                                <Month key={month} month={month} index={monthIndex + 1}/>
                            )}

                        </Header>
                    </React.Fragment>
                )
            })}
        </>
    );
}
