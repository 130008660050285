import Container from "core/components/container/container";
import useStamdataApi from "core/hooks/useStamdataApi";
import { SetTitleBar } from "core/layout/titleBar/titleBarApi";
import { Localizer } from "infrastructure/localization/localizer";
import { userBrevskabelonEditQueryParams } from "infrastructure/route/queryParams";
import { RoutePaths } from "infrastructure/routes";
import { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import BrevskabelonDto from "services/api/brevskabelon/models/brevskabelonDto";
import { EmblaIcons } from "core/emblaIcons";
import { NotificationModule } from "ditmer-embla";
import { Loading } from "core/components/loading/loading";
import { ValidationForm } from "core/components/validation/components/validationForm";
import editBrevValidationSchema from "./validationSchema/editBrevValidationSchema";
import { HandleError } from "core/infrastructure/errors/errorBoundary";
import { ValidationFormSubmitButton } from "core/components/validation/components/validationFormButton";
import { EmblaIcon } from "core/components/emblaIcon/emblaIcon";
import BrevPreview from "./BrevPreview";
import BrevskabelonCrudFields from "./BrevskabelonCrudFields";

const brevEditModalId = "brevEditModalId";

const BrevEditPage = () => {

    const { brevskabelonApi} = useStamdataApi();
    const { uddannelseslaege, brev: brevId } = useRouteMatch<userBrevskabelonEditQueryParams>().params;

    const [brev, setBrev] = useState<BrevskabelonDto |undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    SetTitleBar(
        Localizer.letterPage_EditTemplate(),
        [{ text: Localizer.global_letters(), url: RoutePaths.Brevskabeloner({uddannelseslaege}).url}]
    );

    useEffect(() => {
        setIsLoading(true);

        brevskabelonApi
            .get(brevId)
            .then((b) => setBrev(b))
            .finally(() => setIsLoading(false));

    }, [brevId, brevskabelonApi]);

    const onSaveClick = async (values: BrevskabelonDto) => {
        brevskabelonApi
            .update(values)
            .then(() => NotificationModule.showSuccess(Localizer.letterPage_letterTemplateUpdated(), ""))
            .catch((err) => {
                NotificationModule.showError(Localizer.letterPage_updateError(), "");
                HandleError(err);
            });
    };
    
    return (
        <Loading isLoading={isLoading} text={Localizer.letterPage_fetchingLetter()}>
            {brev &&
                <ValidationForm
                    initialValues={brev}
                    validationSchema={editBrevValidationSchema}
                    onSubmit={async (values) => await onSaveClick(values)} 
                >
                {(formik) => (
                    <>
                        <Container>

                            <Container.Header childrenIsHeaderActions titleConfig={{title: Localizer.letterPage_template()}}>
                                <ValidationFormSubmitButton formikProps={formik}>
                                    <EmblaIcon iconName={EmblaIcons.Save} />
                                    {Localizer.global_save()}
                                </ValidationFormSubmitButton>
                            </Container.Header>

                            <Container.Body additionalClasses="uddannelseslaeger-list">
                                <BrevskabelonCrudFields
                                    formik={formik}
                                    modalId={brevEditModalId}
                                />
                            </Container.Body>

                        </Container>

                        <div className="margin-top-l">
                            {brev &&
                                <BrevPreview
                                    uddannelseslaegeId={uddannelseslaege}
                                    markdown={formik.values.bodyMarkdown} 
                                    id={formik.values.id}
                                />
                            }
                        </div>
                    </>
                )}
                </ValidationForm>
            }
        </Loading>
    );
}

export default BrevEditPage;
