export class SeAlleKompetencerInputModel{
    constructor(id: string, seAlleKompetencer: boolean) {
        this.id = id;
        this.seAlleKompetencer = seAlleKompetencer;
    }

    id: string;
    seAlleKompetencer: boolean;
}

