import {
    forloebMenuLink, kursusMenuLink,
} from "core/layout/shared/MenuLinkProps";
import { DynamicMenu } from "../DynamicMenu";

type KursusudbyderMenuProps = {
    uddannelseslaegeId: string
}

export function KursusudbyderMenu({uddannelseslaegeId} : KursusudbyderMenuProps) {

    const menuLinks = [
        forloebMenuLink(uddannelseslaegeId),
        kursusMenuLink(uddannelseslaegeId)
    ];

    return (
        <DynamicMenu menuLinks={menuLinks} />
    );
}
