import { DatetimePickerModule } from "ditmer-embla";
import {EmblaIcon} from "../emblaIcon/emblaIcon";
import { EmblaIconsType } from "core/emblaIcons";

type DatePickerActionButtonProps = {
  datePickerRef: React.RefObject<DatetimePickerModule>;
  onButtonClickedCallback: (datePickerModule: DatetimePickerModule) => void;
  actionText: string;
  btnIcon?: EmblaIconsType;
} & React.HTMLProps<HTMLButtonElement>;

export const DatePickerActionButton = ({
  datePickerRef,
  onButtonClickedCallback,
  actionText,
  btnIcon,
  ...htmlProps
}: DatePickerActionButtonProps) => {

  const onClick = () => {
    if(htmlProps.disabled || !datePickerRef?.current) return;

    onButtonClickedCallback(datePickerRef?.current);
}

  return (
    <button
        {...htmlProps}
        type="button"
        disabled={htmlProps.disabled}
        className={"btn btn-default"}
        onClick={onClick}
        >
        {actionText}
        {btnIcon &&
            <EmblaIcon iconName={btnIcon}/>
        }
    </button>
  )
}
