import React from 'react';
import {Localizer} from "../../infrastructure/localization/localizer";
import {YesNoEnum} from "../../core/sharedmodels/evaluering/YesNoEnum";

type YesNoRadioProps = {
    id: string;
    yesNo?: boolean
    disabled: boolean;
    addBooleanSvar?: (checked: boolean) => void;
};

export function YesNoRadio({
                               id,
                               yesNo,
                               disabled,
                               addBooleanSvar,
                           }: YesNoRadioProps) {
    return (
        <div className={"flex-container-row"}>
            {Object.values(YesNoEnum).filter(value => typeof value === 'number').map((booleanValue) => {
                const isYes = booleanValue === 1;
                return (
                    <div key={id + booleanValue} className={"margin-right-s"}>
                        <input type={"radio"}
                               id={id + booleanValue}
                               name={id}
                               onClick={(e) => {
                                   const isChecked = e.currentTarget.checked;
                                   const yes = isYes && isChecked;
                                   if (addBooleanSvar)
                                       addBooleanSvar(yes)
                               }}
                               defaultChecked={yesNo === isYes}
                               disabled={disabled}/>
                        <label
                            htmlFor={id + booleanValue}>{booleanValue === 1 ? Localizer.global_ja() : Localizer.global_nej()}</label>
                    </div>
                )
            })}
        </div>
    );
}

