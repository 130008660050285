import React, {useRef, useState} from 'react'
import {FileMetadataList} from "../../../core/components/fileMetadata/fileMetadataList";
import useEffectAsync from "../../../infrastructure/effect";
import {FileMetadata} from "../../../core/sharedmodels/fileMetaData/fileMetaData";
import {FileLocationEnum} from "../../../core/sharedmodels/file/fileLocationEnum";
import {HttpClientService} from "../../../services/httpClient/httpClientService";
import {LogbogNotatApi} from "../../../services/api/logbogNotat/logbogNotatApi";
import {useAppSelector} from "../../../app/hooks";
import {forloebReducer, ForloebSliceState} from "../../../core/forloebSlice";
import {NotatType} from "../../../core/sharedmodels/notat/notatType";
import {LaegekursusGruppeModel} from "../../../services/api/laegekursus/laegekursusGruppeModel";
import {BasicNotatModel} from "../../../core/sharedmodels/notat/basicNotatModel";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {nameof} from "ts-simple-nameof";
import {ValidationTextarea} from "../../../core/components/validation/components/validationTextarea";
import {Form, Formik} from "formik";
import {setSlideinState, SlideInStateEnum} from "../../../core/components/slideIn/slideInSlice";
import {HandleError} from "../../../core/infrastructure/errors/errorBoundary";
import {ValidationFormSubmitButton} from "../../../core/components/validation/components/validationFormButton";
import {
    crudLaegekursusGruppeSamletGodkendelseValidationSchema,
    SamletGodkendelsePaaAndetGrundlagInputModel
} from "./samletGodkendelsePaaAndetGrundlagInputModel";
import {useDispatch} from "react-redux";
import {ReactFileUploaderFile} from "../../../core/components/fileUpload/reactFileUploader";
import {BuildNewCrudNotatModel} from "../../../core/sharedmodels/notat/notatCrudModel";
import {NotificationModule} from "ditmer-embla";
import {AccountService} from "../../../services/account/accountService";
import {LogbogLaegekursusGruppeApi} from "../../../services/api/laegekursusGruppe/logbogLaegekursusGruppeApi";
import {Loading} from "../../../core/components/loading/loading";

interface GivSamletGodkendelseProps {
    modalId: string;
    laegekursusGruppe: LaegekursusGruppeModel;
    kurserUpdatedCallback: () => void
}

const notatApi = new LogbogNotatApi(new HttpClientService(), new AccountService());
const laegekursusGruppeApi = new LogbogLaegekursusGruppeApi(new HttpClientService(), new AccountService());

export function GivSamletGodkendelse(props: GivSamletGodkendelseProps) {
    const dispatch = useDispatch();
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;
    const [uploadedFilesState, setUploadedFilesState] = useState<ReactFileUploaderFile[]>([]);

    const [isLoading, setIsLoading] = useState(true);

    const notat = useRef<BasicNotatModel | null>(null);

    const uddannelsessted = forloebSliceState.forloebState.afdelingName === null
        ? forloebSliceState.forloebState.laegeInstitutionName
        : forloebSliceState.forloebState.laegeInstitutionName + ", " + forloebSliceState.forloebState.afdelingName

    useEffectAsync(async () => {
        notat.current = await notatApi.getNotatOnContext(NotatType.LaegekursusGruppe, forloebSliceState.forloebState.brugerId, props.laegekursusGruppe.id);
        setIsLoading(false);
    }, []);

    const givSamletGodkendelsePaaAndetGrundlagForLaegekursusGruppe = async (inputModel: SamletGodkendelsePaaAndetGrundlagInputModel) => {
        dispatch(setSlideinState({state: SlideInStateEnum.ActionOngoing, slideInId: props.modalId}))

        let laegekursusGruppeId = await laegekursusGruppeApi.givSamletGodkendelsePaaAndetGrundlagForLaegekursusGruppe(inputModel);

        if (uploadedFilesState?.length > 0) {
            let laegekursusGruppeNotat = BuildNewCrudNotatModel([laegekursusGruppeId], NotatType.LaegekursusGruppe, forloebSliceState.forloebState.brugerId);
            await notatApi.createNotat(laegekursusGruppeNotat, uploadedFilesState.map(tf => tf.fileMetadata.id));
        }
        if (laegekursusGruppeId)
            NotificationModule.showSuccess(Localizer.kursusPage_laegekursusGruppeGodkendelse(), "");

        props.kurserUpdatedCallback();
        dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: props.modalId}))
    }

    const fileUploadedCallback = async (uploadedFiles: ReactFileUploaderFile[]) => {
        setUploadedFilesState(arr => [...arr, ...uploadedFiles]);
    }

    const fileDeletedCallback = (fileMetaData: FileMetadata) => {
        let updatedFileUploadedState = [...uploadedFilesState];
        let deleteIndex = updatedFileUploadedState.findIndex(file => file.fileMetadata.id === fileMetaData.id);
        updatedFileUploadedState.splice(deleteIndex, 1);
        setUploadedFilesState(updatedFileUploadedState);
    }

    const render = (
        <Loading isLoading={isLoading} text={Localizer.kursusPage_samletGodkendelsePaaAndetGrundlag()}>
            <Formik
                initialValues={new SamletGodkendelsePaaAndetGrundlagInputModel(props.laegekursusGruppe.id, uddannelsessted)}
                validationSchema={crudLaegekursusGruppeSamletGodkendelseValidationSchema}
                onSubmit={async (values: SamletGodkendelsePaaAndetGrundlagInputModel) => {
                    await givSamletGodkendelsePaaAndetGrundlagForLaegekursusGruppe(values)
                        .catch(err => { // catch is needed to handle unhandeled exception (eg. api offline). Formik onsubmithandler is catching all exception and writing them as warnings
                            dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: props.modalId}))
                            HandleError(err);
                        });
                }}>
                {
                    (formik) => (
                        <Form>
                            <div className="row">
                                <div className="col-sm-12">
                                    <ValidationTextarea
                                        model={{
                                            label: Localizer.global_addCommentGodkendOptional(),
                                            placeholder: Localizer.global_addCommentGodkendLabel(),
                                            htmlName: nameof<SamletGodkendelsePaaAndetGrundlagInputModel>(x => x.godkendelseKommentar)
                                        }}
                                        inputId={nameof<SamletGodkendelsePaaAndetGrundlagInputModel>(x => x.godkendelseKommentar) + props.modalId}
                                        formikProps={formik}
                                    />
                                </div>
                                <div className="col-sm-12">
                                    <FileMetadataList fileMetadatas={notat.current?.fileMetadatas ?? []}
                                                      filesUploadedCallbackOverride={fileUploadedCallback}
                                                      fileDeletedCallbackOverride={fileDeletedCallback}
                                                      fileLocation={FileLocationEnum.Logbog}
                                                      notat={notat.current}
                                                      showDeleteButton={true}
                                                      withUploader={true}
                                    />
                                </div>
                            </div>

                            <div className="modal-actions">
                                <ValidationFormSubmitButton formikProps={formik}>
                                    {Localizer.kursusPage_tildelSamletGodkendelse()}
                                </ValidationFormSubmitButton>
                            </div>
                        </Form>
                    )
                }
            </Formik>
        </Loading>
    )
    return <>{render}</>
}
