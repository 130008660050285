import BrevskabelonVisibilityTypeEnum, { getBrevskabelonVisibilityTypeTranslation } from "infrastructure/enums/brevskabelonVisibilityTypeEnum";
import useUser from "core/hooks/useUser";
import useEnumDropdownOptions from "core/components/dropdown/hooks/useEnumDropdownOptions";

const useBrevskabelonVisibilityTypeDropdownOptions = () => {
    const {currentUser} = useUser();
    const [enumDropdownOptions] = useEnumDropdownOptions(BrevskabelonVisibilityTypeEnum, getBrevskabelonVisibilityTypeTranslation);

    return {
        enumDropdownOptions: currentUser.IsAdmin()
            ? enumDropdownOptions
            : enumDropdownOptions.filter(x => x.value === BrevskabelonVisibilityTypeEnum.Private)
    }

}

export default useBrevskabelonVisibilityTypeDropdownOptions;