import {HttpClientService} from "../../services/httpClient/httpClientService";
import {AccountService} from "../../services/account/accountService";
import {ForloebState, setCurrentForloeb} from "../../core/forloebSlice";
import {AppDispatch} from "../../app/store";
import {ForloebApi} from "../../services/api/forloeb/forloebApi";
import {ForloebModel} from "../../core/sharedmodels/forloeb/forloebModel";

export const InitForloebState = async (dispatch : AppDispatch,
                                       forloebState: ForloebState | null,
                                       uddannelseslaegeId : string,
                                       forloebId?: string,
                                       brugerMaalbeskrivelseId?: string) : Promise<ForloebModel> => {
    
    let forloebModel : ForloebModel | undefined;

    if(forloebId) {
        const forloebResponseModel = await new ForloebApi(new HttpClientService(), new AccountService()).getForloebById(forloebId);
        forloebModel = forloebResponseModel ?? new ForloebModel();

    } else if (!forloebState?.id || forloebState?.brugerId !== uddannelseslaegeId || (!!brugerMaalbeskrivelseId && forloebState.brugerMaalbeskrivelseId !== brugerMaalbeskrivelseId)) {
        const forloebModels = await new ForloebApi(new HttpClientService(), new AccountService()).getForloebByBruger(uddannelseslaegeId)
        forloebModel = GetDefaultSelectedForloebViaForloebList(forloebModels, brugerMaalbeskrivelseId);

    } else {
        forloebModel = ForloebModel.FromState(forloebState);
    }

    if (forloebModel)
        dispatch(setCurrentForloeb(forloebModel.ToState()));

    return forloebModel;
}

export const GetDefaultSelectedForloebViaForloebList = (forloebModels: ForloebModel[], brugerMaalbeskrivelseId?: string) => {
    const laegeForloebIkkeFravaer = forloebModels.filter(f => !f.erFravaer);
    const laegeForloebIkkeFravaerPaaMaalbeskrivelse = laegeForloebIkkeFravaer.filter(x => !brugerMaalbeskrivelseId || x.brugerMaalbeskrivelseId === brugerMaalbeskrivelseId);
    
    const laegeFravaerForloeb = forloebModels.filter(f => f.erFravaer);

    if (laegeForloebIkkeFravaerPaaMaalbeskrivelse.length > 0) {
        return findLaegForloebToInitWith(laegeForloebIkkeFravaerPaaMaalbeskrivelse);
    } else if (laegeForloebIkkeFravaer.length > 0){
        return findLaegForloebToInitWith(laegeForloebIkkeFravaer);
    } else if(laegeFravaerForloeb.length > 0) {
        return findLaegForloebToInitWith(laegeFravaerForloeb);
    } else {
        return new ForloebModel();
    }
}   

const findLaegForloebToInitWith = (laegeForloeb: ForloebModel[]) => {
    const currentForloeb = laegeForloeb.find(fm => fm.IsActiveForloeb());

    if(!currentForloeb) {
        const newestForloeb = laegeForloeb.sort((a, b) => b.endDate.getTime() - a.endDate.getTime())[0]
        return newestForloeb;
    }

    return currentForloeb;
}
