import {ValidationForm} from "../../../core/components/validation/components/validationForm";
import {
    BuildExisting,
    CreateNyhedValidationSchema,
    CrudNyhedInputModel
} from "../../../core/components/nyheder/crudNyhedInputModel";
import {NyhedInputFields} from "../../../core/components/nyheder/nyhedInputFields";
import {ValidationFormSubmitButton} from "../../../core/components/validation/components/validationFormButton";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {NyhedModel} from "../../../core/sharedmodels/nyheder/nyhedModel";
import {setSlideinState, SlideInStateEnum} from "../../../core/components/slideIn/slideInSlice";
import {useDispatch} from "react-redux";
import useLogbogApi from "core/hooks/useLogbogApi";
import { HandleError } from "core/infrastructure/errors/errorBoundary";

type EditNyhederProps = {
    modalTarget: string;
    nyhedModel: NyhedModel;
}

export const EditNyheder = (props: EditNyhederProps) => {
    const dispatch = useDispatch();
    const { logbogNyhederApi } = useLogbogApi();

    const EditNyhed = async (inputModel: CrudNyhedInputModel) => {
        dispatch(setSlideinState({state: SlideInStateEnum.ActionOngoing, slideInId: props.modalTarget}));
        await logbogNyhederApi.EditNyhed(props.nyhedModel.id, inputModel);
        dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: props.modalTarget}));
    };

    const DeleteNyhed = async (nyhedId: string) => {
        dispatch(setSlideinState({state: SlideInStateEnum.ActionOngoing, slideInId: props.modalTarget}));
        await logbogNyhederApi.DeleteNyhed(nyhedId);
        dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: props.modalTarget}));
    };

    return (
        <ValidationForm
            key={props.modalTarget}
            initialValues={BuildExisting(props.nyhedModel)}
            validationSchema={CreateNyhedValidationSchema}
            onSubmit={async (values) => {
                await EditNyhed(values).catch(err => { // catch is needed to handle unhandeled exception (eg. api offline). Formik onsubmithandler is catching all exception and writing them as warnings
                    dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: props.modalTarget}))
                    HandleError(err);
                });
            }}
            >
            {
                (formik) => (
                    <>
                        <NyhedInputFields
                            modalId={props.modalTarget}
                            formik={formik}
                            model={props.nyhedModel}/>

                        <div className="modal-actions">
                            <button 
                                type={"button"} 
                                onClick={() => DeleteNyhed(props.nyhedModel.id)} 
                                className={"btn btn-danger margin-right-s"}
                                >
                                {Localizer.global_delete()}
                            </button>
                            <ValidationFormSubmitButton formikProps={formik}>
                                {Localizer.global_save()}
                            </ValidationFormSubmitButton>
                        </div>
                    </>
                )
            }
        </ValidationForm>
    );
}
