import {ForloebOverviewConstants} from "../forloebOverviewConstants";
import React from "react";
import {calculateForloebColumnNumber} from "../forloebOverviewUtils/forloebOverviewUtils";
import './../forloebOverview.scss';
import MonthColumn from "./monthColumn";
import dayjs from "dayjs";
import { DateFilterType } from "../ForloebOverviewDateFilterModel";
import { DayUnitType } from "index";

type VerticalLinesProps = {
    years: number[];
    rowSize: number;
    startDate: Date;
    endDate: Date;
    dateFilterType: DateFilterType;
};

export const VerticalLines = ({years, rowSize, startDate, endDate, dateFilterType}: VerticalLinesProps) => {
    const months = ForloebOverviewConstants.months;
    const stillingRowStart = ForloebOverviewConstants.laegeRowStart;
    const paginationRowIndex = rowSize + 1 + stillingRowStart;

    const now = dayjs();
    const endDayjs = dayjs(endDate).startOf(DayUnitType);
    const startDayjs = dayjs(startDate).startOf(DayUnitType);

    const currentMonthColumn = calculateForloebColumnNumber(now.month(), now.year(), years);
    const startDateColumn = calculateForloebColumnNumber(startDayjs.month(), startDayjs.year(), years);
    const endDateColumn = calculateForloebColumnNumber(endDayjs.month(), endDayjs.year(), years);

    return (
        <>
            {years.map((year) => {
                return (
                    <React.Fragment key={year.toString()}>
                        {months.map((month, monthIndex) =>
                            (
                                <MonthColumn
                                    key={month}
                                    monthKey={month}
                                    monthIndex={monthIndex}
                                    year={year}
                                    years={years}
                                    paginationRowIndex={paginationRowIndex}
                                    currentMonthColumn={currentMonthColumn}
                                    startDateColumn={startDateColumn}
                                    endDateColumn={endDateColumn}
                                    dateFilterType={dateFilterType}
                                    startDayjs={startDayjs}
                                    endDayjs={endDayjs}
                                    renderOverlay={!(dateFilterType === DateFilterType.Date || startDayjs.isSame(endDayjs))}
                                />
                            )
                        )}
                    </React.Fragment>
                )
            })}
        </>
    );
}
