import classNames from "classnames";
import { EmblaIcons, EmblaIconsType } from "core/emblaIcons";
import { EmblaIcon } from "../emblaIcon/emblaIcon";
import { useCallback, useState } from "react";

type IconProps = {
    onClick: () => void;
    iconName: EmblaIconsType;
    className?: string;
    needConfirmation?: boolean;
}

const ClickableIcon = ({onClick, iconName, className, needConfirmation=false}: IconProps) => {

    const [confirmationActive, setConfirmationActive] = useState(false);

    const handleOnClick = useCallback(() => {
        needConfirmation
            ? setConfirmationActive(true)
            : onClick()
    }, [needConfirmation, onClick]);

    const confirm = async (confirmed: boolean) => {
        if (confirmed)
            onClick();

        setConfirmationActive(false);
    }

    const confirmBtnClasses = "btn btn-icon btn-outline";

    return (
        <>
            {!confirmationActive &&
                <div className={classNames(className)} role='button' onClick={(e) => handleOnClick()}>
                    <EmblaIcon iconName={iconName} />
                </div>
            }

            {confirmationActive &&
                <div className="btn-group confirm" role="group">
                    <button className={classNames(confirmBtnClasses, "btn-danger")} onClick={() => confirm(false)}>
                        <EmblaIcon iconName={EmblaIcons.Danger} />
                    </button>

                    <button className={classNames(confirmBtnClasses, "btn-success")} onClick={() => confirm(true)}>
                        <EmblaIcon iconName={EmblaIcons.Success} />
                    </button>
                </div>
            }
        </>
    );
};

export default ClickableIcon;
