import { ReactNode} from "react";
import useScreenResolution from "core/hooks/useScreenResolution";
import classNames from "classnames";
import { PaginationModel } from "core/sharedmodels/filter/PaginationModel";
import AdministrationFilesPageLengthDropdown from "./AdministrationFilesPageLengthDropdown";


type FileAdministrationFilterProps = {
    additionalNodes?: ReactNode;
    pagination: PaginationModel;
    onPageLengthChangeCallback?: (n: number) => void;
}

const FileAdministrationFilter = ({additionalNodes, onPageLengthChangeCallback} : FileAdministrationFilterProps) => {
    const { isMobile } = useScreenResolution();

    return (
        <div className={classNames(!isMobile && "flex-space")}>

            {isMobile
                ?
                    <>
                        {additionalNodes &&
                            additionalNodes
                        }
                    </>
                :
                    <div  className="flex-container">
                            {onPageLengthChangeCallback &&
                                <AdministrationFilesPageLengthDropdown onChangeCallback={onPageLengthChangeCallback}/>
                            }

                        {additionalNodes &&
                            additionalNodes
                        }
                    </div>
                }

            </div>
    );
}

export default FileAdministrationFilter;
