import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { IHubConnectionManager } from "./IHubConnectionManager";
import { AccountService } from "services/account/accountService";
import config from "config/config";

export abstract class HubConnectionManager implements IHubConnectionManager {
  private globalConnection: HubConnection | undefined;
  private connections: Partial<Record<string, HubConnection>> = {};
  abstract endpointName: string;
  abstract userService: AccountService;
  private groupSubscribe = "GroupSubscribe";
  private groupUnsubscribe = "GroupUnsubscribe";

  private hubUrl = () => `${config.logbogApiUrl}${this.endpointName}`;

  async startGlobalConnection() : Promise<void> {
    if (this.globalConnection) {
      return;
    }

    const connection = new HubConnectionBuilder()
      .withUrl(
        this.hubUrl(),
        {
          withCredentials: false,
          accessTokenFactory: async () => await this.userService.getAccesToken() ?? "",
        },
      )
      .withAutomaticReconnect()
      .build();

    this.globalConnection = connection;

    await connection
      .start()
      .then(() => console.log("Connection to SignalR established"))
      .catch((reason) => console.error(reason));
  }

  onGlobal(methodName: string, listener: (...args: any[]) => void) {
    this.globalConnection?.on(methodName, listener);
  }

  offGlobal(methodName: string, method: (...args: any[]) => void) {
    this.globalConnection?.off(methodName, method);
  }

  async stopConnectionGlobal() {
    const connection = this.globalConnection;
    await connection?.stop();
    this.globalConnection = undefined;
  }

  // Taken from Prolegis (This handles connections to specific groups / subscriptions)
  // Is not used right now

  getSignalRHeader = (groupId: string) => {
    // add signalR connection id to avoid getting back events that were already processed locally
    return { signalRConnectionId: this.getConnectionId(groupId) };
  };  

  async startConnection(groupId: string) : Promise<void> {
    if (this.connections[groupId]) {
      return;
    }

    const connection =  new HubConnectionBuilder()
      .withUrl(
        this.hubUrl(),
        {
          withCredentials: false,
          accessTokenFactory: async () => await this.userService.getAccesToken() ?? "",
        },
      )
      .build();

    this.connections[groupId] = connection;

    await connection.start();
    await connection.invoke(this.groupSubscribe, groupId);
  }

  on(groupId: string, method: string, listener: (...args: any[]) => void) {
    this.connections[groupId]?.on(method, listener);
  }

  off(groupId: string, methodName: string, method: (...args: any[]) => void) {
    this.connections[groupId]?.off(methodName, method);
  }

  getConnectionId(groupId: string) {
    return this.connections[groupId]?.connectionId ?? undefined;
  }

  async stopConnection(groupId: string) {
    const connection = this.connections[groupId];
    await connection?.invoke(this.groupUnsubscribe, groupId);
    await connection?.stop();
    delete this.connections[groupId];
  }

  async unsubscribe(groupId: string) {
    const connection = this.connections[groupId];
    await connection?.invoke(this.groupUnsubscribe, groupId);
  }
}
