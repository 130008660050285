import {Localizer} from '../../../infrastructure/localization/localizer';
import {
    DaysLeftOnForloeb,
    ForloebSimpleInfoModel,
    GetUddannelsesstedInfo
} from "../../sharedmodels/forloeb/forloebSimpleInfoModel";
import {UddannelseTypeEnum} from "../../sharedmodels/stilling/uddannelseTypeEnum";
import React from "react";
import {ForloebCardBadges} from "./forloebCardBadges";

type ForloebSimpleCardInfoProps = {
    forloeb : ForloebSimpleInfoModel;
}

export function ForloebSimpleCardInfo(props: ForloebSimpleCardInfoProps) {
    const daysLeft = DaysLeftOnForloeb(props.forloeb);
    return (
        <>
            <div className='forloeb-header-txt'>
                <h5 className="margin-bottom-xxs">{GetUddannelsesstedInfo(props.forloeb)}</h5>
                <div>
                    {props.forloeb.specialeName}, {UddannelseTypeEnum[props.forloeb.uddannelseType]}
                </div>
                <div>{props.forloeb.stillingNummer}</div>
            </div>
            <div className='forloeb-header-txt d-flex' key={props.forloeb.endDate.toString()}>
                {props.forloeb.startDate.dateWithoutTimeFormat(false)} - {props.forloeb.endDate.dateWithoutTimeFormat(false)}
                {daysLeft <= 30 && daysLeft >= 0 &&
                    <div className='margin-left-s text-lowercase'>
                        <p>({daysLeft} {Localizer.global_daysLeft()})</p>
                    </div>
                }
            </div>
            <ForloebCardBadges forloebSkalEvalueres={props.forloeb.skalIndgaaIEvalueringsstatistik}
                               endDate={props.forloeb.endDate}
                               fravaer={props.forloeb.erFravaer}
                               fravaerskodeEnum={props.forloeb.fravaerskodeEnum}
                               manueltAfsluttet={props.forloeb.manueltAfsluttet}
                               startDate={props.forloeb.startDate}
                               irrelevant={props.forloeb.irrelevant}
                               evalueretDato={props.forloeb.evalueretDato}
                               godkendtDato={props.forloeb.godkendtDato}/>
        </>
    )
}
