import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {setSlideinState, SlideInStateEnum} from "../../../core/components/slideIn/slideInSlice";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {EvalueringStatistikModel} from "../../../services/api/evalueringStatistik/evalueringStatistikReponseModel";
import React, {useEffect, useState} from "react";
import {QuestionGroupComponent} from "./questionGroupComponent";
import {SvarGruppeEnum} from "../../../core/sharedmodels/evaluering/svarGruppeEnum";
import {HttpClientService} from "../../../services/httpClient/httpClientService";
import useEffectAsync from "../../../infrastructure/effect";
import {LogbogSpoergsmaalResponseModel} from "../../../services/api/logbogEvaluering/logbogSpoergsmaalResponseModel";
import {SingleQuestionComponent} from "./questionComponent";
import {
    EvalueringStatistikForloebModel
} from "../../../services/api/evalueringStatistik/evalueringStatistikForloebModel";
import {
    evalueringsstatistikFiltersReducer,
    EvalueringsstatistikFiltersState
} from "../evalueringsstatistikFiltersSlice";
import {SpoergsmaalTypeEnum} from "../../../core/sharedmodels/evaluering/spoergsmaalTypeEnum";
import {EvalueringStatistikApi} from "../../../services/api/evalueringStatistik/evalueringStatistikApi";
import {Loading} from "../../../core/components/loading/loading";
import {NumberOfEvalueringer} from "./numberOfEvalueringer";
import { SpoergsmaalModel } from "core/sharedmodels/evaluering/spoergsmaalModel";
import {FileDownloadHandler} from "../../../services/api/logbogFile/fileDownloadHandler";

const evalueringstatistikApi = new EvalueringStatistikApi(new HttpClientService(), new FileDownloadHandler());


interface SpoergerammeProps {
    modalId: string;
    startDate?: Date;
    endDate?: Date;
    spoergsmaalTypeEnum?: SpoergsmaalTypeEnum;
    forloebEnkeltEvalueringer?: EvalueringStatistikForloebModel;
    uddannelsesstederEvalueringer?: EvalueringStatistikModel;
}

function RemoveUnwantedQuestions(questions: SpoergsmaalModel[]) {
    const question27Index = 26;
    questions.splice(question27Index, 1);
}

export function Spoergeramme(props: SpoergerammeProps) {
    const dispatch = useAppDispatch();
    const [spoergsmaalsGrupper, setSpoergsmaalsGrupper] = useState<LogbogSpoergsmaalResponseModel[][]>([]);
    const [spoergsmaal, setSpoergsmaal] = useState<LogbogSpoergsmaalResponseModel[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const evalueringsstatistikFilterSliceState = useAppSelector(evalueringsstatistikFiltersReducer) as EvalueringsstatistikFiltersState;

    useEffectAsync(async () => {
        setIsLoading(true);
        const forloebEvalueringModel = await evalueringstatistikApi.getForloebEvalueringSpoergsmaal();
        forloebEvalueringModel.sektioner.forEach(s => s.spoergsmaal.forEach(sp => sp.sortOrder = s.sortOrder));
        let questions = forloebEvalueringModel.sektioner.flatMap(s => s.spoergsmaal);
        RemoveUnwantedQuestions(questions)
        setSpoergsmaal(questions);
        setIsLoading(false);
    }, [])

    useEffect(()=>{
        if(props.spoergsmaalTypeEnum === SpoergsmaalTypeEnum.Grupperet && spoergsmaal.length > 0){
            let list:LogbogSpoergsmaalResponseModel[][] = [];
            let svarGroupList = Object.keys(SvarGruppeEnum).filter(x => !Number.isNaN(Number(x)));
            svarGroupList.forEach(s => list.push([]));
            let svarGroupListToShow = (evalueringsstatistikFilterSliceState.spoergsmaalsType === SpoergsmaalTypeEnum.Enkeltvis || evalueringsstatistikFilterSliceState.spoergsmaalToShow.length === 0) ? svarGroupList : svarGroupList.filter(sg => evalueringsstatistikFilterSliceState.spoergsmaalToShow.includes(sg));

            spoergsmaal.map(s => {
                svarGroupListToShow.forEach(sg => {
                        if(s.svarGruppeEnums?.includes(Number(sg))){
                            const index = Number(sg)-1;
                            let group = list[index];
                            group.push(s);
                            list.splice(index, 1, group);

                        }
                    }
                )})

            setSpoergsmaalsGrupper(list);
        }
    }, [spoergsmaal])

    const setLuk = async () => {
        dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: props.modalId}));
    }

    const gruppeQuestionView = () => {
        return (
            <div className="exclude-click-event">
                <div className={"row margin-bottom-l"}>
                    <div className={"col-6"}>
                        <h4>{Localizer.global_periode()}</h4>
                        <p>{props.startDate?.dateWithoutTimeFormat(true)} {Localizer.global_til()} {props.endDate?.dateWithoutTimeFormat(true)}</p>
                    </div>
                    <div className={"col-6"}>
                        <h4>{Localizer.global_antal()}</h4>
                        <p>
                            <NumberOfEvalueringer
                                antalForloebEvalueret={props.uddannelsesstederEvalueringer?.antalForloebEvalueret}
                                antalForloeb={props.uddannelsesstederEvalueringer?.antalForloeb}/>
                        </p>
                    </div>
                </div>
                {introductionSection()}

                    <>
                        {evalueringsstatistikFilterSliceState.spoergsmaalToShow.length > 0 &&
                            <>
                                {evalueringsstatistikFilterSliceState.spoergsmaalToShow.map((spoergsmaalToShow, index) => {
                                    return (
                                        <div className={index + 1 !== evalueringsstatistikFilterSliceState.spoergsmaalToShow.length ? "border-bottom" : ""} key={spoergsmaalToShow}>
                                            <QuestionGroupComponent evalueringGruppeScoreModel={props.uddannelsesstederEvalueringer?.gruppeScore[+spoergsmaalToShow - 1]} spoergsmaal={spoergsmaalsGrupper[+spoergsmaalToShow - 1]}/>
                                        </div>
                                    )
                                })}
                            </>
                        }
                        {evalueringsstatistikFilterSliceState.spoergsmaalToShow.length === 0 && spoergsmaalsGrupper.length > 0 &&
                            <>
                                {props.uddannelsesstederEvalueringer?.gruppeScore.map((gruppeScore, index) => {
                                    return (
                                        <div className={index + 1 !== props.uddannelsesstederEvalueringer?.gruppeScore.length ? "border-bottom" : ""} key={gruppeScore.svarGruppeEnum}>
                                            <QuestionGroupComponent evalueringGruppeScoreModel={gruppeScore} spoergsmaal={spoergsmaalsGrupper[index]}/>
                                        </div>
                                    )
                                })}
                            </>
                        }
                    </>

            </div>
        )
    }

    const singleQuestionsView = () => {
        return (
            <div>
                {props.uddannelsesstederEvalueringer &&
                    <div className={"row margin-bottom-l"}>
                        <div className={"col-6"}>
                            <h4>{Localizer.global_periode()}</h4>
                            <p>{props.startDate?.dateWithoutTimeFormat(true)} {Localizer.global_til()} {props.endDate?.dateWithoutTimeFormat(true)}</p>
                        </div>
                        <div className={"col-6"}>
                            <h4>{Localizer.global_antal()}</h4>
                            <p>
                                <NumberOfEvalueringer
                                    antalForloebEvalueret={props.uddannelsesstederEvalueringer?.antalForloebEvalueret}
                                    antalForloeb={props.uddannelsesstederEvalueringer?.antalForloeb}/>
                            </p>
                        </div>
                    </div>
                }

                {introductionSection()}
                <>
                    {evalueringsstatistikFilterSliceState.spoergsmaalToShow.length > 0 && spoergsmaal.length > 0 &&
                        <div>
                            {evalueringsstatistikFilterSliceState.spoergsmaalToShow.map((score, index) => {
                                return (
                                    <div className={index + 1 !== evalueringsstatistikFilterSliceState.spoergsmaalToShow.length ? "border-bottom " : ""} key={score + index}>

                                        <SingleQuestionComponent questionTitle={spoergsmaal[+score - 1].titel}
                                                                 questionNumber={+score}
                                                                 questionText={spoergsmaal[+score - 1].tekst}
                                                                 score={props.uddannelsesstederEvalueringer?.singleAverageScore[+score - 1]}/>
                                    </div>
                                )
                            })}
                        </div>
                    }
                    {evalueringsstatistikFilterSliceState.spoergsmaalToShow.length === 0 && spoergsmaal.length > 0 &&
                        <>
                            {props.uddannelsesstederEvalueringer?.singleAverageScore.map((fes, index) => {
                                return (
                                    <div className={index + 1 !== props.uddannelsesstederEvalueringer?.singleAverageScore.length ? "border-bottom " : ""} key={fes + index}>
                                        <SingleQuestionComponent questionTitle={spoergsmaal[index].titel}
                                                                 questionText={spoergsmaal[index].tekst}
                                                                 questionNumber={index + 1}
                                                                 score={fes}/>
                                    </div>
                                )
                            })}
                        </>
                    }
                </>
            </div>
        )
    }


    const forloebevalueringerQuestionsView = () => {
        return (
            <div>
                <div className={"row margin-bottom-l"}>
                    <div className={"col-6"}>
                        <h4>{Localizer.global_indsendt()}</h4>
                        {props.forloebEnkeltEvalueringer?.indsendtDato.dateWithoutTimeFormat(true)}
                    </div>

                </div>
                {introductionSection()}
                <>
                    {evalueringsstatistikFilterSliceState.spoergsmaalToShow.length > 0 && spoergsmaal.length > 0 &&
                        <>
                            {evalueringsstatistikFilterSliceState.spoergsmaalToShow.map((score, index) => {
                                return (
                                    <div className={index + 1 !== evalueringsstatistikFilterSliceState.spoergsmaalToShow.length ? "border-bottom " : ""} key={score + index}>
                                        <SingleQuestionComponent questionTitle={spoergsmaal[+score - 1].titel}
                                                                 questionNumber={+score}
                                                                 questionText={spoergsmaal[+score - 1].tekst}
                                                                 score={props.forloebEnkeltEvalueringer?.forloebEvalueringSvar[+score - 1].svarVaerdi}/>
                                    </div>
                                )
                            })}
                        </>
                    }

                    {evalueringsstatistikFilterSliceState.spoergsmaalToShow.length === 0 && spoergsmaal.length > 0 &&
                        <>
                            {spoergsmaal.map((fes, index) => {
                                return (
                                    <div className={index + 1 !== spoergsmaal.length ? "border-bottom " : ""} key={fes.id + index}>
                                        <SingleQuestionComponent questionTitle={spoergsmaal[index].titel}
                                                                 questionText={spoergsmaal[index].tekst}
                                                                 questionNumber={index + 1}
                                                                 score={props.forloebEnkeltEvalueringer?.forloebEvalueringSvar[index].svarVaerdi}/>
                                    </div>
                                )
                            })}
                        </>
                    }

                </>
            </div>
        )
    }

    const allQuestionsView = () => {
        return (
            <div>
                {spoergsmaal?.map((s, index) => {
                    return (
                        <div className={index + 1 !== spoergsmaal.length ? "border-bottom " : ""} key={s.id}>
                            <SingleQuestionComponent questionNumber={s.sortOrder} questionTitle={s.titel} questionText={s.tekst} />
                        </div>
                    )
                })}
            </div>
        )
    }

    const introductionSection = () => {
        return (
            <div>
                <h4>{Localizer.global_introduktion()}</h4>
                <div className={"margin-bottom-l"}>
                    <p>{Localizer.spoergerammeModal_spoergsmaalPreForklaringText()}:</p>
                    <ol>
                        <li>{Localizer.spoergerammeModal_skala1()}</li>
                        <li>{Localizer.spoergerammeModal_skala2()}</li>
                        <li>{Localizer.spoergerammeModal_skala3()}</li>
                        <li>{Localizer.spoergerammeModal_skala4()}</li>
                        <li>{Localizer.spoergerammeModal_skala5()}</li>
                        <li>{Localizer.spoergerammeModal_skala6()}</li>
                    </ol>
                    <p>{Localizer.spoergerammeModal_spoergsmaalPostForklaringText()}.</p>
                </div>
            </div>
        )
    }

    return (
        <div>
            <Loading isLoading={isLoading} text={Localizer.spoergerammeModal_henterSpoergsmaal()} timeout={100}>
                {props.spoergsmaalTypeEnum === SpoergsmaalTypeEnum.Grupperet &&
                    gruppeQuestionView()
                }
                {props.spoergsmaalTypeEnum === SpoergsmaalTypeEnum.Enkeltvis &&
                    singleQuestionsView()
                }
                {!props.spoergsmaalTypeEnum && props.forloebEnkeltEvalueringer &&
                    forloebevalueringerQuestionsView()
                }
                {!props.spoergsmaalTypeEnum && !props.forloebEnkeltEvalueringer &&
                    allQuestionsView()
                }

                <div className="modal-actions">
                    <button className="btn btn-default margin-right-s" data-dismiss="modal" onClick={() => setLuk()} >
                        {Localizer.global_luk()}
                    </button>
                </div>
            </Loading>
        </div>
    )
}
