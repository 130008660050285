export class ObligatoriskDokumentationGetModel {
    uddannelseId : string | null;
    ansoegningBrugerId : string | null;
    brugerKursusraekkeId : string | null;
    brugerMaalbeskrivelseId : string | null;

    constructor(uddannelseId : string | null, ansoegningBrugerId : string | null, brugerKursusraekkeId : string | null, brugerMaalbeskrivelseId : string | null) {
        this.uddannelseId = uddannelseId;
        this.ansoegningBrugerId = ansoegningBrugerId;
        this.brugerKursusraekkeId = brugerKursusraekkeId;
        this.brugerMaalbeskrivelseId = brugerMaalbeskrivelseId;
    }
}
