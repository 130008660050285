import {HttpClientService} from "../../../services/httpClient/httpClientService";
import {EmailModel} from "../../../core/sharedmodels/email/EmailModel";
import {FileMetadataList} from "../../../core/components/fileMetadata/fileMetadataList";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {TableAsync} from "../../../core/components/table/tableAsync";
import {StamdataEmailApi} from "../../../services/api/stamdataEmail/stamdataEmailApi";
import {SlideIn} from "../../../core/components/slideIn/slideIn";
import {dateTimeStringFromApiToDate} from "../../../index";
import {
    StamdataEmailTableColumnsPostModel
} from "../../../services/api/stamdataEmail/stamdataEmailTableColumnsPostModel";
import {stamdataEmailResponseModel} from "../../../services/api/stamdataEmail/stamdataEmailResponseModel";
import {FileLocationEnum} from "../../../core/sharedmodels/file/fileLocationEnum";
import {AccountService} from "../../../services/account/accountService";
import {useState} from "react";
import {nameof} from "ts-simple-nameof";
import {TextEditor} from "../../../core/components/textEditor/textEditor";
import {ModalSize} from "../../../core/components/slideIn/modalSize";

interface ReceivedMailsProps {
    userId: string;
}

const emailApi = new StamdataEmailApi(new HttpClientService(), new AccountService())

export const ReceivedMails = (props: ReceivedMailsProps) => {
    const [tableNumber, setTableNumber] = useState<number>(0);
    const [selectedMail, setSelectedMail] = useState<EmailModel>();

    const modalTarget = "email-view-modal";

    const clickedMail = (email: EmailModel) => {
        setSelectedMail(email);
    }

    const renderTable = () => {
        return <TableAsync key={tableNumber}
                           tableIdentifier={`receivedMailsTable`}
                           tableHeader={Localizer.profilepage_ReceivedEmails()}
                           renderTableHead={renderTableHead}
                           asyncUrl={emailApi.emailDatalist()}
                           columns={emailTableColums()}
                           getCustomPostData={() => {return {userId: props.userId}}}
                           rowClickFunction = {(mail: EmailModel) => {clickedMail(mail)}}
                           showFilteredRecordsInfo
        />
    }

    const emailTableColums = (): DataTables.ColumnSettings[] => {
        let columns: DataTables.ColumnSettings[] = [];

        columns = columns.concat([
            {
                data: (data: stamdataEmailResponseModel) => data.subject,
            },
            {
                data: (data: stamdataEmailResponseModel) => data.fromName,
            },
            {
                data: (data: stamdataEmailResponseModel) => data,
                render: (data: stamdataEmailResponseModel) => {
                    return `<div>${data.sentAt ? dateTimeStringFromApiToDate(data.sentAt).dateWithTimeFormat() : ""}</div>`;
                }
            }
        ]);

        return columns;
    }

    const renderTableHead = () => {
        return (
            <thead>
            <tr>
                <th data-column-name={nameof<StamdataEmailTableColumnsPostModel>(x => x.Subject)}
                    data-priority="1">
                    {Localizer.global_subject()}
                </th>
                <th data-column-name={nameof<StamdataEmailTableColumnsPostModel>(x => x.Sendby)}
                    data-priority="1">
                    {Localizer.createMail_SendBy()}
                </th>
                <th data-column-name={nameof<StamdataEmailTableColumnsPostModel>(x => x.SendAt)}
                    data-priority="1">
                    {Localizer.createMail_Send()}
                </th>
            </tr>
            </thead>
        );
    }

    const renderSlideIn = (modalTarget: string) => {
        return (
            <>
            {selectedMail &&
                <>
                    <div className="flex-container flex-grow-1">
                        <div className="flex-container-row flex-align-b border-bottom padding-bottom-m margin-bottom-m left-right-minus-spacing">
                            <div className="flex-container-column">
                                <p className="font-weight-bolder margin-0">{Localizer.createMail_SendBy() + " " + selectedMail?.fromName}</p>
                                <p className="margin-0">{dateTimeStringFromApiToDate(selectedMail.sentAt.toString()).dateWithTimeFormat() }</p>
                                <p>{Localizer.global_subject() + ":"} {selectedMail.subject}</p>
                            </div>
                        </div>
                      <div className="flex-container-row flex-align-b border-bottom padding-bottom-m margin-bottom-m left-right-minus-spacing">
                        <TextEditor markdown={selectedMail.body} isViewer={true} id={selectedMail.id}/>
                      </div>

                        {selectedMail.fileMetadata.length > 0 &&
                          <>
                            <p className={"font-weight-bolder margin-top-s"}>{Localizer.FileAttachments()} ({selectedMail.fileMetadata.length})</p>
                            <FileMetadataList fileMetadatas={selectedMail.fileMetadata}
                                              withUploader={false}
                                              fileLocation={FileLocationEnum.Email}
                            />
                          </>
                        }
                    </div>
                </>
            }
            </>
        );
    }

    return (
        <>
            {renderTable()}

            {selectedMail &&
                <SlideIn
                    key={tableNumber + 1}
                    id={modalTarget}
                    title={Localizer.global_email()}
                    actionText={Localizer.vejlederPage_creatingVejleder()}
                    actionOnCloseCallback={() => {setSelectedMail(undefined)}}
                    actionFinishedCallback={() => {
                        setTableNumber(tableNumber + 1);
                        setSelectedMail(undefined)
                    }}
                    defaultOpen={true}
                    bodyContent={renderSlideIn(modalTarget)}
                    modalSize={ModalSize.md}
                />
            }
        </>


    );
}
