import * as yup from "yup";
import { CrudUddannelsesstedInputModel} from "./crudUddannelsesstedInputModel";
import createValidationSchema from "../../../core/components/validation/createValidationSchema";
import {Localizer} from "../../../infrastructure/localization/localizer";
import moment from "moment";
import {VejlederEditModel} from "./vejlederEditModel";
import {RootTypeEnum} from "../../../core/sharedmodels/laegeInstitution/rootTypeEnum";

export const BuildExistingCrudVejlederInputModel = (vejlederEditModel: VejlederEditModel): CrudVejlederInputModel => {
    const model = new CrudVejlederInputModel();
    model.brugerId = vejlederEditModel.BrugerId;
    model.vejlederTilknytningId = vejlederEditModel.UddannelsesstedTilknytningId;
    model.createNewUser = vejlederEditModel.CreateNewUser;

    const uddannelsessted = new CrudUddannelsesstedInputModel();
    uddannelsessted.afdelingId = vejlederEditModel.AfdelingId;
    uddannelsessted.sygehusId = vejlederEditModel.SygehusId;
    uddannelsessted.praksisId = vejlederEditModel.PraksisId;
    uddannelsessted.laegeinstitutionstype = vejlederEditModel.LaegeinstitutionsType;
    uddannelsessted.startDato = vejlederEditModel.StartDatoUtc;
    uddannelsessted.slutDato = vejlederEditModel.SlutDatoUtc;
    uddannelsessted.laeger = vejlederEditModel.AlleLaeger ? [] : vejlederEditModel.LaegerIds;
    uddannelsessted.alleLaeger = vejlederEditModel.AlleLaeger;
    uddannelsessted.alleKbuLaeger = vejlederEditModel.alleKbuLaeger;
    uddannelsessted.alleIntroduktionsLaeger = vejlederEditModel.alleIntroduktionsLaeger;
    uddannelsessted.alleHoveduddannelsesLaeger = vejlederEditModel.alleHoveduddannelsesLaeger;

    model.uddannelsessted = uddannelsessted;

    return model;
}

export class CrudVejlederInputModel {
    public createNewUser?: boolean;
    public authId: string;
    public vejlederTilknytningId: string;
    public brugerId?: string;
    public mobilePhone?: string;
    public email?: string;
    public alternativEmail?: string;
    public uddannelsessted: CrudUddannelsesstedInputModel;
}

export const createVejlederValidationSchema = createValidationSchema<CrudVejlederInputModel>({
    createNewUser: yup.boolean().default(false).required(),
    authId: yup.string().notRequired(),
    mobilePhone: yup.string().notRequired(),
    email: yup.string().when('createNewUser', {
        is: true,
        then: yup.string().email(Localizer.validate_emailInvalid()).required(Localizer.validate_email()),
        otherwise: yup.string().notRequired()
    }),
    alternativEmail: yup.string().email(Localizer.validate_emailInvalid()).notRequired(),
    vejlederTilknytningId: yup.string().notRequired(),
    brugerId: yup.string().when('createNewUser', {
        is: false,
        then: yup.string().required(Localizer.validate_vejlederRequired()),
        otherwise: yup.string().notRequired()
    }),
    uddannelsessted:
        createValidationSchema<CrudUddannelsesstedInputModel>({
            startDato: yup.mixed().test(
                "date-validation1",
                Localizer.validate_dateInvalid(),
                (value) => {
                    return !value || moment(value).isValid();
                }
            ),
            slutDato: yup.mixed().test(
                "date-validation2",
                Localizer.validate_dateInvalid(),
                (value) => {
                    return !value || moment(value).isValid();
                }
            ).test(
                "date-validation3",
                Localizer.validate_slutDatoBeforeStartdate(),
                function (value) {
                    const parent = this.parent as CrudUddannelsesstedInputModel;
                    return !value || !parent.startDato || value > parent.startDato
                }
            ),
            laegeinstitutionstype: yup.number().required(Localizer.validate_laegeinstitutionstypeRequired()),
            sygehusId: yup.string().when('laegeinstitutionstype', {
                is: RootTypeEnum.Sygehus,
                then: yup.string().required(Localizer.validate_sygehusRequired),
                otherwise: yup.string().nullable().notRequired()
            }),
            afdelingId: yup.string().when('laegeinstitutionstype', {
                is: RootTypeEnum.Sygehus,
                then: yup.string().required(Localizer.validate_afdelingRequired),
                otherwise: yup.string().nullable().notRequired()
            }),
            praksisId: yup.string().when('laegeinstitutionstype', {
                is: RootTypeEnum.Praksis,
                then: yup.string().required(Localizer.validate_praksisRequired),
                otherwise: yup.string().nullable().notRequired()
            }),
            laeger: yup.mixed().test(
                "laeger-validation",
                Localizer.validate_laegerRequired(),
                function (_) {
                    const parent = this.parent as CrudUddannelsesstedInputModel;
                    return parent.alleLaeger || parent.alleKbuLaeger || parent.alleIntroduktionsLaeger || parent.alleHoveduddannelsesLaeger || parent.laeger.length > 0;
                }
            ),
            alleLaeger: yup.bool(),
            alleKbuLaeger: yup.bool(),
            alleIntroduktionsLaeger: yup.bool(),
            alleHoveduddannelsesLaeger: yup.bool(),
        }
    )
});
