import PageLengthDropdown from "core/components/dropdown/components/customDropdowns/pageLengthDropdown";

const AdministrationFilesPageLengthDropdown = ({onChangeCallback} : {onChangeCallback: (n: number) => void}) => {

    return (
        <div className="uddannelseslaege-filter margin-right-s min-w-60">
            <PageLengthDropdown
                range={[25, 50, 75, 100, 200]}
                onChange={(newValue) => onChangeCallback(newValue!.value)}
                showTitle={false}
            />
        </div>
    )
}

export default AdministrationFilesPageLengthDropdown;
