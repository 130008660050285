import {useRef, useState} from "react";
import {Localizer} from "infrastructure/localization/localizer";
import useEffectAsync from "infrastructure/effect";
import {useRouteMatch} from "react-router-dom";
import {HttpClientService} from "services/httpClient/httpClientService";
import {UserProfile} from "../../core/components/userProfile/userProfile";
import {Loading} from "../../core/components/loading/loading";
import {AccountService} from "../../services/account/accountService";
import {UserProfileModel} from "../../core/components/userProfile/userProfileModel";
import {LogbogUserInfoApi} from "../../services/api/logbogUser/logbogUserInfoApi";
import {Tab, Tabs} from "../../core/components/Tabs/Tabs";
import {ReceivedMails} from "./components/receivedMails";
import useScreenResolution from "../../core/hooks/useScreenResolution";

const userService = new AccountService();
const userApi = new LogbogUserInfoApi(new HttpClientService(), userService);

export function UddannelseslaegePage() {
    const routeMatch = useRouteMatch();
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(new UserProfileModel());
    const _currentUser = useRef(userService.getUser());
    const { isMobile } = useScreenResolution();

    const routeParams = routeMatch.params as { id: string };
    const uddannelseslaegeId = routeParams.id;

    useEffectAsync(async () => {
        const result = await userApi.getUserInfo(uddannelseslaegeId);
        setUser(result);

        setIsLoading(false);
    }, [])

    const renderUserProfile = () => {
        return (
            <UserProfile user={user}/>
        );
    }

    const tabs: Tab[] = [
        { title: Localizer.global_profile(), id: "profile", content: renderUserProfile(), defaultActive: true },
        { title: Localizer.profilepage_ReceivedEmails(), id: "receivedEmails", content: (<ReceivedMails userId={user.userId}/>), defaultActive: false }
    ];

    return (
        <>
            <Loading isLoading={isLoading} text={Localizer.profilepage_getProfil()}>
                {_currentUser.current.IsAdminOrVus() && !isMobile &&
                  <Tabs tabs={tabs}/>
                }
                {(!_currentUser.current.IsAdminOrVus() || isMobile) &&
                    renderUserProfile()
                }
            </Loading>
        </>
    );
}
