import FilterTitle from "./filterTitle";
import { useEffect, useState } from "react";
import { RolleTitelEnum } from "infrastructure/enums/rolleTitelEnum";
import RolleTitelTypeDropdown from "core/components/dropdown/components/customDropdowns/rolleTitelTypeDropdown";
import TypedFilter from "../types/typedFilterProps";
import { Localizer } from "infrastructure/localization/localizer";

export type UserFilterResult = {
    rolleTitels: RolleTitelEnum[],
}

const initialSelectedValues: UserFilterResult = {
    rolleTitels: [],
};

const UserFilter = ({initialSelectedValues: selectedValues=initialSelectedValues, onChange, titled=false} : TypedFilter<UserFilterResult>) => {

    const [filter, setFilter] = useState<UserFilterResult>(selectedValues);

    useEffect(() => {
        onChange(filter);
    }, [filter, onChange])

    return (
        <div>
            {titled &&
                <FilterTitle title={Localizer.global_user()} />
            }

            <RolleTitelTypeDropdown
                initialSelected={filter.rolleTitels}
                onChangeCallback={newValue => setFilter(prev => ({...prev, rolleTitels: newValue}))}
            />

        </div>
    );
}

export default UserFilter;