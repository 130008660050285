import { PropsWithChildren } from "react";
import classNames from "classnames";

type CardFooterProps = {
    additionalClasses?: string;
}

const CardFooter = ({additionalClasses, children} : PropsWithChildren<CardFooterProps>) => {
    return (
        <div className={classNames("card-footer", additionalClasses)}>
            {children}
        </div>
    );
}

export default CardFooter;