import React from "react";
import {StillingForloebOverviewModel} from "../../../sharedmodels/stilling/stillingForloebOverviewModel";
import {StillingView} from "./stillingView";
import './stillingOverview.scss';
import {ForloebOverviewConstants} from "../forloebOverviewConstants";
import Tooltip, {TooltipPlacement} from "../../tooltips/Tooltip";

export interface AfdelingViewProps {
    afdelingLookUp: {afdelingId: string, afdelingNavn: string, uddannelsesstedId: string, rowIndex: number, collapsed: boolean};
    afdelingStillinger: { stillingModel: StillingForloebOverviewModel, rowIndex: number, uddannelsesstedId: string, afdelingId?: string, collapsed: boolean }[];
    uddannelsesstedCollapsed: boolean;
    visHorizontaleLinjerState: boolean;
}

export function AfdelingView(props: AfdelingViewProps) {
    return <React.Fragment key={props.afdelingLookUp.afdelingId}>
        <div
            className={`flex-space afdeling-header ${props.visHorizontaleLinjerState ? "border-top" :""} ${props.afdelingLookUp.collapsed ? "collapse " : ""} ${props.uddannelsesstedCollapsed ? "hide" : "show d-flex"}`}
            style={{
                "--afdeling-rowindex": (props.afdelingLookUp.rowIndex),
                "--afdeling-row-color": (props.visHorizontaleLinjerState
                    ? ForloebOverviewConstants.afdelingHorizontalLinesBackgroundColor
                    : ForloebOverviewConstants.defaultBackgroundColor)
            } as React.CSSProperties}>
            <div className="fit-content d-flex align-items-center">
                <Tooltip className="text-truncate" title={props.afdelingLookUp.afdelingNavn} placement={TooltipPlacement.Right}>
                    <p className="margin-0 margin-left-m text-truncate left-col-max-width">
                        {props.afdelingLookUp.afdelingNavn}
                    </p>
                </Tooltip>
            </div>
        </div>

        {props.afdelingStillinger.map(stilling =>
            <StillingView
                key={stilling.stillingModel.stillingId}
                stillingLookUp={stilling}
                isOnAfdeling={true}
                visHorizontaleLinjerState={props.visHorizontaleLinjerState}
            />
        )}
    </React.Fragment>

}
