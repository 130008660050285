import useScreenResolution from "core/hooks/useScreenResolution";
import { Localizer } from "infrastructure/localization/localizer";
import InformationPageSection from "../components/informationPageSection";
import { RoutePaths } from "infrastructure/routes";
import { Link } from "react-router-dom";

const dpoName = "Region Hovedstaden";
const dpoWebLinkName = "Kontakt-Region-Hovedstadens-databeskyttelsesraadgiver";
const dpoWebUrl = "https://www.regionh.dk/kontakt/henvendelser/Sider/Kontakt-Region-Hovedstadens-databeskyttelsesraadgiver-(DPO).aspx";
const dpoAddress =  "Region Hovedstaden, Att.: Databeskyttelsesrådgiveren, Kongens Vænge 2, 3400 Hillerød";
const dpoPhone = "38 66 50 00";
const dpoEmail = "regionh@regionh.dk";

const minDataMail = "ul@regionh.dk";
const minDataPhoneNumber = "38 66 99 30"; // Videreuddannelsessekretariatet

export const PrivatlivspolitikPage = () => {
    const { isMobile } = useScreenResolution();

    return (
    <>
        <div className="container">
            <div className="flex-container-column">
                <div className="margin-top-m margin-bottom-m">
                    <h2 className={`${isMobile ? "padding-top-m padding-bottom-m" : "flex-row-center padding-m"}`}>
                        {Localizer.privatlivspolitikPage_privatlivspolitikTitle()}
                    </h2>
                    <h3 className={`${isMobile ? "" : "flex-row-center"}`}>
                        {Localizer.privatlivspolitikPage_privatlivspolitikSubTitle()}
                    </h3>
                </div>
            </div>
            <InformationPageSection 
                title={Localizer.privatlivspolitikPage_Logning()}
                contentText={Localizer.privatlivspolitikPage_LogningYouAreNotAnonymousText()}
            >
                <ul>
                    <li>{Localizer.privatlivspolitikPage_LogningListPointBrowserType()}</li>
                    <li>{Localizer.privatlivspolitikPage_LogningListPointOperativSystem ()}</li>
                    <li>{Localizer.privatlivspolitikPage_LogningListPointIpAddress()}</li>
                    <li>{Localizer.privatlivspolitikPage_LogningListPointPageUrl()}</li>
                    <li>{Localizer.privatlivspolitikPage_LogningListPointTimeOfVisit()}</li>
                    <li>{Localizer.privatlivspolitikPage_LogningListPointReferrer()}</li>
                    <li>{Localizer.privatlivspolitikPage_LogningListPointScreenResolution()}</li>
                </ul>
                <p>{Localizer.privatlivspolitikPage_LogningTextNoDataToThirdPartsAndContact()}</p>
            </InformationPageSection>
            <InformationPageSection 
                title={Localizer.privatlivspolitikPage_OpbevaringAfData()}
                contentText={Localizer.privatlivspolitikPage_OpbevaringAfDataText()}
            />
            <InformationPageSection 
                title={Localizer.privatlivspolitikPage_cookiesAndStatisticsTitle()}
            >
                <p>
                    {Localizer.privatlivspolitikPage_cookiesAndStatisticsInfo()}
                    {" "}
                    <Link to={RoutePaths.Cookies.path}>
                        {Localizer.privatlivspolitikPage_cookiesAndStatisticsTitle()}
                    </Link>
                </p>
                
            </InformationPageSection>
            <InformationPageSection 
                title={Localizer.privatlivspolitikPage_DPO()}
            >
                <p>
                 {Localizer.privatlivspolitikPage_DPOText()}
                 {" "}
                 <a href={dpoWebUrl}>{dpoWebLinkName}</a>
                </p>
                <ul>
                    <li><b>{Localizer.global_name()}:</b> {dpoName}</li>
                    <li><b>{Localizer.global_address()}:</b> {dpoAddress}</li>
                    <li><b>{Localizer.landingPage_telefon()}:</b> {dpoPhone}</li>
                    <li><b>{Localizer.global_email()}:</b> <a href={`mailto:${dpoEmail}`}>{dpoEmail}</a></li>
                </ul>
            </InformationPageSection>
            <InformationPageSection 
                title={Localizer.privatlivspolitikPage_MinData()}
                contentText={Localizer.privatlivspolitikPage_MinDataText()}
            >
                <ul>
                    <li><b>{Localizer.global_email()}:</b> <a href={`mailto:${minDataMail}`}>{minDataMail}</a></li>
                    <li><b>{Localizer.landingPage_telefon()}:</b> {minDataPhoneNumber}</li>
                </ul>
            </InformationPageSection>
        </div>
    </>
    );
}
