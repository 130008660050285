const ddMMyyyyRegex = /^(\d{0,2})\/(\d{0,2})\/(\d{0,4})$/;
const digitsBetweenRegex = /(\d+)/g;
const greedyAllDigitsAndSlashesRegex = /[^\d/]/g;
const greedyAllDigitsAndColonRegex = /[^\d:]/g;
const greedySlashRegex = /\//g;
const greedyColonRegex = /:/g;
const hhmmRegex = /^(\d{0,2}):(\d{0,2})$/;

export {
    ddMMyyyyRegex,
    digitsBetweenRegex,
    greedyAllDigitsAndSlashesRegex,
    greedySlashRegex,
    hhmmRegex,
    greedyAllDigitsAndColonRegex,
    greedyColonRegex
}