import { StateSwitchButtonElement, StateSwitchButtonGroup } from "core/components/button/stateSwitchButtonGroup";
import { Localizer } from "infrastructure/localization/localizer";
import { DateFilterType, DateFilterInput, Period, isPeriodValid } from "../ForloebOverviewDateFilterModel";
import { useEffect, useRef, useState } from "react";
import { Datepicker } from "core/components/datePicker/datePicker";
import { DatetimePickerModule, DatetimePickerTypeEnum } from "ditmer-embla";
import { nameof } from "ts-simple-nameof";
import { Dropdown, DropdownOption } from "core/components/dropdown/dropdown";
import CreateYearDropdownOptionsNYearsFromNow from "../forloebOverviewUtils/YearCalculator";

export type DateFilterProps = {
    modalId: string,
    filter: DateFilterInput;
    callback?: (forloebOverviewDateFilterModel: DateFilterInput) => void;
}

export function DateFilter(props: DateFilterProps) {
    const [startYearSelectOptions, setStartYearSelectOptions] = useState<DropdownOption<string>[]>([]);
    const [dateFilterTypeOption, setDateFilterTypeOption] = useState<DateFilterType>(DateFilterType.Year);
    const [selectedFilterDate, setSelectedFilterDate] = useState(props.filter.date);
    const [selectedFilterPeriodFrom, setSelectedFilterPeriodFrom] = useState(props.filter.periodFrom);
    const [selectedFilterPeriodTo, setSelectedFilterPeriodTo] = useState(props.filter.periodTo);

    const periodFromDateTimePickerModuleRef = useRef<DatetimePickerModule>(null);
    const periodToDateTimePickerModuleRef = useRef<DatetimePickerModule>(null);

    useEffect(() => {
        setStartYearSelectOptions(CreateYearDropdownOptionsNYearsFromNow());
        setDateFilterTypeOption(props.filter?.dateFilterType);
        setSelectedFilterDate(props.filter?.date);
        setSelectedFilterPeriodFrom(props.filter?.periodFrom);
        setSelectedFilterPeriodTo(props.filter?.periodTo);
    }, [props.filter]);

    function setForloebOverviewDateFilterModelValues(input: {dateFilterType?: DateFilterType, year?: number, date?: Date, periodFrom?: Date, periodTo?: Date}) {
        const model = props.filter;

        const filterType = input.dateFilterType ?? model.dateFilterType;
        if(filterType !== dateFilterTypeOption) setDateFilterTypeOption(filterType);

        let period: Period = {
            from: input.periodFrom ?? model.periodFrom,
            to: input.periodTo ?? model.periodTo
        };

        const isPeriodeToValidInput = input.periodTo !== undefined;
        const isPeriodFromValidInput = input.periodFrom !== undefined;
        const hasPeriodInput = isPeriodFromValidInput || isPeriodeToValidInput;
        if(hasPeriodInput && !isPeriodValid(period.from, period.to))
        {
            const toChanged = isPeriodeToValidInput && input.periodTo?.valueOf() !== model.periodTo?.valueOf();
            const fromChanged = isPeriodFromValidInput && input.periodFrom?.valueOf() !== model.periodFrom?.valueOf();
            const daysToAddOrSubtract = 2;
            if(toChanged)
            {
                let newDate = new Date(period.to.toDateString());
                newDate.setDate(newDate.getDate() - daysToAddOrSubtract);
                period.from = newDate;
                setSelectedFilterPeriodFrom(period.from);
                periodFromDateTimePickerModuleRef.current?.setValue(newDate, false);
            }
            else if(fromChanged)
            {
                let newDate = new Date(period.from.toDateString());
                newDate.setDate(newDate.getDate() + daysToAddOrSubtract);
                period.to = newDate;
                setSelectedFilterPeriodTo(period.to);
                periodToDateTimePickerModuleRef.current?.setValue(newDate, false);
            }
        }

        let year = input.year ?? model.year;
        const date = input.date ?? model.date;
        const dateFilterType = input.dateFilterType ?? model.dateFilterType;
        const dateHasChanged = input.date !== undefined && input.date !== model.date;
        const yearHasChanged = input.year !== undefined && input.year !== model.year;
        if(dateFilterType === DateFilterType.Period && yearHasChanged) {
            year = period.from.getFullYear();
        } else if (dateFilterType === DateFilterType.Date && dateHasChanged) {
            year = date.getFullYear();
        }

        props.filter.dateFilterType = dateFilterType;
        props.filter.year = year;
        props.filter.date = date;
        props.filter.periodFrom = period.from;
        props.filter.periodTo = period.to;

        if(props.callback)
            props.callback(props.filter);
    }

    const DateFilterStateSwitchButtonElementArray : Array<StateSwitchButtonElement<DateFilterType>> = [
        {
            selectedClassAction: () => dateFilterTypeOption === DateFilterType.Year,
            state: DateFilterType.Year,
            text: Localizer.global_aar()
        }, {
            selectedClassAction: () => dateFilterTypeOption === DateFilterType.Date,
            state: DateFilterType.Date,
            text: Localizer.global_date()
        }, {
            selectedClassAction: () => dateFilterTypeOption === DateFilterType.Period,
            state: DateFilterType.Period,
            text: Localizer.global_period()
        }
    ];

    function DetermineDateFilterInputElements() :JSX.Element {
        if (dateFilterTypeOption === DateFilterType.Date) {
            return(<>
                    <Datepicker
                        id={nameof<DateFilterInput>(x => x.date) + props.modalId}
                        label={Localizer.dato()}
                        placeholderText={Localizer.datoPlaceholder()}
                        datePickerType={DatetimePickerTypeEnum.Date}
                        setDate={ (date: Date) => {
                            if(date !== props.filter.date){
                                setForloebOverviewDateFilterModelValues({date});
                            }
                        }}
                        defaultValue={selectedFilterDate}
                    />
                </>)
        } else if (dateFilterTypeOption === DateFilterType.Period) {
            return(<>
                <div>
                    <Datepicker
                        ref={periodFromDateTimePickerModuleRef}
                        id={nameof<DateFilterInput>(x => x.periodFrom) + props.modalId}
                        label={Localizer.global_from()}
                        placeholderText={Localizer.datoPlaceholder()}
                        datePickerType={DatetimePickerTypeEnum.Date}
                        setDate={ (date: Date) => {
                            if(date !== props.filter.date) {
                                setForloebOverviewDateFilterModelValues({periodFrom: date.getUtcWithoutTime()})
                            }
                        }}
                        defaultValue={selectedFilterPeriodFrom}
                    />
                    <Datepicker
                        ref={periodToDateTimePickerModuleRef}
                        id={nameof<DateFilterInput>(x => x.periodTo) + props.modalId}
                        label={Localizer.global_to()}
                        placeholderText={Localizer.datoPlaceholder()}
                        datePickerType={DatetimePickerTypeEnum.Date}
                        setDate={ (date: Date) => {
                            if(date !== props.filter.date) {
                                setForloebOverviewDateFilterModelValues({periodTo: date.getUtcWithoutTime()})
                            }
                        }}
                        defaultValue={selectedFilterPeriodTo}
                    />
                </div>
            </>)
        } else if (dateFilterTypeOption === DateFilterType.Year) {
            return(
                <div>
                    <label>
                        {Localizer.global_aar()}
                    </label>

                    <Dropdown
                        placeholder={Localizer.forloebOverviewPage_chooseStartAar()}
                        options={startYearSelectOptions}
                        onChange={(selectedOption) => {
                            const selectedYear = selectedOption === null ? undefined : Number(selectedOption.value);
                            if(selectedYear !== props.filter.year) {
                                setForloebOverviewDateFilterModelValues({year: selectedYear})
                            }
                        }}
                        value={startYearSelectOptions.filter(x => Number(x.value) === props.filter.year)}
                    />
                </div>
            )
        } else {
            return(<></>);
        }
    }

    const render = (
        <>
            <section>
                <div className="padding-bottom-s">
                    <div className="d-flex">
                        <h5 className="subtle">
                            {Localizer.timeInterval()}
                        </h5>
                    </div>
                    <StateSwitchButtonGroup buttonInputs={DateFilterStateSwitchButtonElementArray} setStateAction={(dateFilterType) => {
                        setForloebOverviewDateFilterModelValues({dateFilterType})
                    }}/>
                </div>
                { DetermineDateFilterInputElements() }
            </section>
        </>
    )

    return <>{render}</>;
}
