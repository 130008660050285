import { FilterParamsModel } from "core/sharedmodels/filter/FilterParamsModel";
import { PaginationModel } from "core/sharedmodels/filter/PaginationModel";
import { ForloebOverviewConstants } from "../forloebOverviewConstants";
import { useEffect, useState } from "react";
import { DropdownOption } from "core/components/dropdown/dropdown";
import { ForloebOverviewTypeEnum } from "core/sharedmodels/forloeb/forloebOverviewTypeEnum";
import { BrugerIndstillingTypeKeyEnum } from "core/sharedmodels/brugerIndstilling/brugerIndstillingTypeKeyEnum";
import useUserSettings, { useInitializeViaUserSettings } from "core/hooks/useUserSettings";
import { ForloebOverviewFiltersState, forloebOverviewFiltersReducer, setSearch } from "../forloebOverviewFiltersSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { StorageKeys } from "infrastructure/storage/storageKeys";


const initialFilterParamsState = (initialSearch?: string): FilterParamsModel => ({
  paginationModel: new PaginationModel(1, ForloebOverviewConstants.defaultPageSize, 0),
  search: initialSearch ?? "",
  advancedSearch: false,
  showUnoccupiedStillinger: false,
});

const pageSizeDropdownOptions = ForloebOverviewConstants.pageSizeSelectOptions.map(opt => ({ label: `${opt}`, value: opt } as DropdownOption<number>));

/**
 * Incapsulates states/effects regarding generic view options for forloebsOverview (View-switches, pagination, etc.)
 * 
 * Also handles loading in saved options from current/previous session (user-settings)
 */
const useForloebOverviewViewOptions = () => {
  const { userSettingsIsLoading, getUserSettingValue, setUserSettingValue } = useUserSettings();

  const forloebOverviewFilterSliceState = useAppSelector(forloebOverviewFiltersReducer) as ForloebOverviewFiltersState;
  const dispatch = useAppDispatch();

  const [filterParams, setFilterParams] = useState<FilterParamsModel>(initialFilterParamsState(forloebOverviewFilterSliceState.search));
  const [forloebOverviewType, setForloebOverviewType] = useState<ForloebOverviewTypeEnum>(ForloebOverviewTypeEnum.ForloebOverblikStillingerView);
  const [overlappendeVisningState, setOverlappendeVisningState] = useState<boolean>(true);
  const [visHorizontaleLinjerState, setVisHorizontaleLinjerState] = useState<boolean>(true);

  const [isCurrentPageLoadedFromStorage, setIsCurrentPageLoadedFromStorage] = useState<boolean>(false);

  useInitializeViaUserSettings(userSettingsIsLoading, () => {
      const savedPageSize = getUserSettingValue(BrugerIndstillingTypeKeyEnum.PageForloebsoverblikPageSize, ForloebOverviewConstants.defaultPageSize);
      const savedViewOption = getUserSettingValue(BrugerIndstillingTypeKeyEnum.PageForloebsoverblikViewOption, ForloebOverviewTypeEnum.ForloebOverblikStillingerView);
      const advancedSearch = getUserSettingValue(BrugerIndstillingTypeKeyEnum.PageForloebsoverblikUseAdvancedSearch, false);
      
      const showUnoccupiedStillinger = getUserSettingValue(BrugerIndstillingTypeKeyEnum.PageForloebsoverblikUseUbesatteStillinger, false);
      const showHorizontalLines = getUserSettingValue<boolean>(BrugerIndstillingTypeKeyEnum.PageForloebsoverblikUseHorizontalLines, true);
      const showOverlap = getUserSettingValue<boolean>(BrugerIndstillingTypeKeyEnum.PageForloebsoverblikUseUdvidOverlap, true);

      const pageNumberFromStorage = localStorage.getItem(StorageKeys.forloebsoverblikPageNumber);
      const pageNumberIsInStorage = !!pageNumberFromStorage;
      const pageNumber = pageNumberIsInStorage ? Number(pageNumberFromStorage) : ForloebOverviewConstants.defaultPageNumber;
      setIsCurrentPageLoadedFromStorage(pageNumberIsInStorage);

      setForloebOverviewType(savedViewOption);
      setVisHorizontaleLinjerState(showHorizontalLines);
      setOverlappendeVisningState(showOverlap);

      setFilterParams(prev => (
        { 
          ...prev, 
          showUnoccupiedStillinger: showUnoccupiedStillinger,
          advancedSearch: advancedSearch,
          paginationModel: {
            page: pageNumber,
            pageLength: savedPageSize,
            recordCount: 0
          }
        }
      ));
  });

  const onUseAdvancedSearchChanged = (useAdvancedSearch: boolean) => {
    setFilterParams(prev => ({ ...prev, advancedSearch: useAdvancedSearch, paginationModel: { ...prev.paginationModel, page: 1 }}));
    setUserSettingValue(BrugerIndstillingTypeKeyEnum.PageForloebsoverblikUseAdvancedSearch, useAdvancedSearch);
  }

  const onUseOverlappendeVisningChanged = (checked: boolean) => {
    setOverlappendeVisningState(checked);
    setUserSettingValue(BrugerIndstillingTypeKeyEnum.PageForloebsoverblikUseUdvidOverlap, checked);
  }

  const onShowHorizontaleLinjerChanged = (checked: boolean) => {
    setVisHorizontaleLinjerState(checked);
    setUserSettingValue(BrugerIndstillingTypeKeyEnum.PageForloebsoverblikUseHorizontalLines, checked);
  }

  const onShowUnoccupiedStillingerChanged = (checked: boolean) => {
    setFilterParams(prev => ({ ...prev, showUnoccupiedStillinger: checked }));
    setUserSettingValue(BrugerIndstillingTypeKeyEnum.PageForloebsoverblikUseUbesatteStillinger, checked);
  }

  const onPageSizeChange = (pageLength?: number) => {
    if (!pageLength) return;
    setFilterParams(prev => ({ ...prev, paginationModel: { ...prev.paginationModel, pageLength: pageLength, page: 1 }}));
    setUserSettingValue(BrugerIndstillingTypeKeyEnum.PageForloebsoverblikPageSize, pageLength);
  }

  const onSearchChanged = (searchQuery: string) => {
    setFilterParams(prev => ({ ...prev, search: searchQuery, paginationModel: { ...prev.paginationModel, page: 1 }}));
    dispatch(setSearch(searchQuery));
  }

  const onPageChanged = async (page: number) => {
    setFilterParams(prev => ({ ...prev, paginationModel: { ...prev.paginationModel, page: page }}));
    localStorage.setItem(StorageKeys.forloebsoverblikPageNumber, page.toString()); //Only stored in current session
    setIsCurrentPageLoadedFromStorage(false); //User interaction - no longer loaded via saved options
  };

  const onViewOptionChange = (viewOptionType: ForloebOverviewTypeEnum) => {
    setForloebOverviewType(viewOptionType);
    setFilterParams((prev) => ({...prev, paginationModel: { ...prev.paginationModel, page: 1 }}));
    setUserSettingValue(BrugerIndstillingTypeKeyEnum.PageForloebsoverblikViewOption, viewOptionType)
  }

  return {
    filterParams,
    forloebOverviewType,
    overlappendeVisningState,
    visHorizontaleLinjerState,
    pageSizeDropdownOptions,
    isCurrentPageLoadedFromStorage,
    setIsCurrentPageLoadedFromStorage,
    onUseAdvancedSearchChanged,
    onUseOverlappendeVisningChanged,
    onShowHorizontaleLinjerChanged,
    onShowUnoccupiedStillingerChanged,
    onPageSizeChange,
    onSearchChanged,
    onPageChanged,
    onViewOptionChange
  }
}

export default useForloebOverviewViewOptions;