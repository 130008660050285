import { useEffect } from "react";
import {AccountService} from "../../services/account/accountService";
import {useAppDispatch} from "../../app/hooks";

const userManager = new AccountService();

export const ChangeRole = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const handleChangeRole = () => {
            localStorage.clear();
            dispatch({type: 'USER_CHANGE_ROLE', payload: 'USER_CHANGE_ROLE'});
            userManager.SignInRedirect();
        }
        handleChangeRole();
    }, [dispatch]);

    return <></>;
}
