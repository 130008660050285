import { Localizer } from "infrastructure/localization/localizer";
import BrevskabelonDto from "services/api/brevskabelon/models/brevskabelonDto";
import yup from "types/yup-extended";
import CreateValidationSchema from "../../../../core/components/validation/createValidationSchema";
import { nameof } from "ts-simple-nameof";
import BrevskabelonVisibilityTypeEnum from "infrastructure/enums/brevskabelonVisibilityTypeEnum";

const editBrevValidationSchema = CreateValidationSchema<BrevskabelonDto>({
    id: yup.string().required(),
    titel: yup.string().required(Localizer.validate_titelRequired()),
    subject: yup.string().required(Localizer.validate_SubjectRequired()),
    bodyMarkdown: yup.string().required(Localizer.validate_ContentRequired()),
    visibilityType: yup.number().required(),
    regionId: yup.string().nullable().when(nameof<BrevskabelonDto>(x => x.visibilityType), {
        is: BrevskabelonVisibilityTypeEnum.Region,
        then: yup.string().nullable().required(),
        otherwise: yup.string().nullable().notRequired(),
    })
});

export default editBrevValidationSchema;
