import {ForloebOverviewConstants} from "../forloebOverviewConstants";
import React from "react";
import {PaginationModel} from "../../../sharedmodels/filter/PaginationModel";
import {FilterParamsModel} from "../../../sharedmodels/filter/FilterParamsModel";
import {Pagination} from "../../pagination/pagination";

type PaginationForOverviewProps = {
    filterParams: FilterParamsModel;
    filteredRecords: number;
    maxRowIndex: number;
    handlePaginationCallback: (page: number) => void;
}

export const PaginationForOverview = (props: PaginationForOverviewProps) => {
    const laegeRowStart = ForloebOverviewConstants.laegeRowStart;

    const paginationRowIndex = props.maxRowIndex + 1 + laegeRowStart;

    return (
        <React.Fragment>
            <div className="pagination"
                 style={{"--laege-row-index": paginationRowIndex} as React.CSSProperties}>
                <div>
                    <Pagination handlePagination={props.handlePaginationCallback}
                                paginationModel={new PaginationModel(props.filterParams.paginationModel.page, props.filterParams.paginationModel.pageLength, props.filteredRecords)}/>
                </div>
            </div>
        </React.Fragment>
    );
}
