import { EmblaIcon } from "core/components/emblaIcon/emblaIcon"
import { EmblaIcons } from "core/emblaIcons"

const InfoText = ({text} : {text:string}) => {
    
    return (
        <div className="flex-container subtle margin-right-m">
            <div className="margin-right-xs flex-align-t card-list-calender-icon">
                <EmblaIcon iconName={EmblaIcons.Info}/>
            </div>
            <div className="flex-container margin-top-xs">
                <p className="small subtle">{text}</p>
            </div>
        </div>
    )
}

export default InfoText;