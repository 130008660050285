const setDocumentPdfAsReady = () => {
    (document as any).pdfReady = true;
}

const getDateDisplayString = (date?: Date) => date?.dateWithoutTimeFormat(false) ?? "";

const getTimeDisplayString = (startTime?: Date, endTime?: Date) => {
    const startTimeStr = startTime?.timeFormat(true) ?? "";
    const endTimeStr = !endTime ? "" : ` - ${endTime.timeFormat(true)}`;
    return `${startTimeStr}${endTimeStr}`;
}

const getDateTimeDisplayString = (date?: Date, startTime?: Date, endTime?: Date) => `${getDateDisplayString(date)} ${getTimeDisplayString(startTime, endTime)}`;

export {
    setDocumentPdfAsReady,
    getDateTimeDisplayString,
    getDateDisplayString,
    getTimeDisplayString
}