import { EmblaIcons } from "core/emblaIcons";
import { Localizer } from "infrastructure/localization/localizer";
import { EmblaIcon } from "../../emblaIcon/emblaIcon";
import { ButtonComponentProps } from "../../button/buttonComponentProps";
import ReactDOMServer from "react-dom/server";
import { setFilterButtonClasses } from "../../table/tableAsync";

export type RemoveFiltrerButtonProps = ButtonComponentProps & {
    Id?: string;
}

export const RemoveFiltrerButton = ({Id, onClick, additionalClasses, shouldRender=true}: RemoveFiltrerButtonProps) => {
    
    const handleOnClick = () => {
        if(!onClick) return;
        onClick();
    }
    
    return (
        <>
            {shouldRender &&
                <button 
                    id={Id}
                    className={`margin-left-s btn btn-default ${additionalClasses ?? ""}`}
                    onClick={() => handleOnClick()}
                >
                    <EmblaIcon iconName={EmblaIcons.Remove}/>
                    {Localizer.global_clearFilters()}
                </button>
            }
        </>                           
    );
}

const filterBtnSelector = '.filter-modal-show';
const filterAppliedPillId = 'filters-applied-pill';
const filterAppliedPillSelector = `#${filterAppliedPillId}`;

const insertFiltersAppliedButton = ($filterBtn: JQuery<HTMLElement>, resetFilter: () => void) => {
    $(filterAppliedPillSelector).remove();
    
    const htmlString = ReactDOMServer.renderToString(<RemoveFiltrerButton Id={filterAppliedPillId} />);
    
    $filterBtn.parent().append(htmlString);
    
    $(filterAppliedPillSelector).off("click").on("click", () => {
        resetFilter();
    });
}

const handleFiltersAppliedMarker = (hasFilter: boolean, reloadtableFunction: () => void, filterSelector: string, resetFilter?: () => void) => {
    
    if(!hasFilter) {
        $(filterAppliedPillSelector).remove();
    } else if(resetFilter) {
        const $filterBtn = $(filterBtnSelector);
        insertFiltersAppliedButton($filterBtn, resetFilter);
    }
    
    reloadtableFunction();
    setFilterButtonClasses(filterSelector);
}

export {
    handleFiltersAppliedMarker
}