import {useEffect, useState} from "react";
import {PdfDetailedUserHeaderComponent} from "./pdfDetailedUserHeaderComponent";
import {Localizer} from "../../../../infrastructure/localization/localizer";
import {SpecialeModel} from "../../../sharedmodels/speciale/specialeModel";
import { BrugerMaalbeskrivelseResponseModel } from "services/api/stamdataMaalbeskrivelse/brugerMaalbeskrivelseResponseModel";
import { dateTimeStringFromApiToDate } from "index";
import PdfHeaderRow from "../pdfHeaderRow";
import { GetUddannelsestypeTranslation } from "core/sharedmodels/stilling/uddannelseTypeEnum";
import {useFetchWithErrorHandlingPdf} from "../useFetchWithErrorHandlingPdf";
import useStamdataApi from "core/hooks/useStamdataApi";
import StillingDisplayInfo, { getStillingDurationDisplay } from "services/api/logbogStilling/stillingInfo";

type PdfKompetenceHeaderComponentProps = {
    brugerId: string,
    brugerMaalbeskrivelseId: string,
    stillingInfo?: {stillingId: string, forloebId: string},
    disableUserInfo?: boolean,
}

export function PdfKompetenceHeaderComponent({disableUserInfo=false, stillingInfo, brugerMaalbeskrivelseId, brugerId}: PdfKompetenceHeaderComponentProps) {
    const {stamdataMaalbeskrivelseApi, stamdataSpecialeApi, stamdataStillingApi} = useStamdataApi();

    const [brugerMaalbeskrivelse, setBrugerMaalbeskrivelse] = useState<BrugerMaalbeskrivelseResponseModel>();
    const [speciale, setSpeciale] = useState<SpecialeModel>();
    const [stilling, setStilling] = useState<StillingDisplayInfo | undefined>(undefined);

    const setError = useFetchWithErrorHandlingPdf();

    useEffect(() => {
        const fetch = async () => {
            setBrugerMaalbeskrivelse(await stamdataMaalbeskrivelseApi.getBrugerMaalbeskrivelse(brugerMaalbeskrivelseId))
            setSpeciale(await stamdataSpecialeApi.getSpecialeByBrugerMaalbeskrivelse(brugerMaalbeskrivelseId));
            
            if(!stillingInfo) return;
            setStilling(await stamdataStillingApi.getStillingDisplayInfo(stillingInfo.stillingId, stillingInfo.forloebId));
        }
        fetch().catch(err => setError(err));
    }, [brugerMaalbeskrivelseId, setError, stamdataMaalbeskrivelseApi, stamdataSpecialeApi, stamdataStillingApi, stillingInfo])

    return (
        <>
            {brugerMaalbeskrivelse && speciale &&
                <div className={"card shadow-none"}>
                    <div className={"card-body flex-container-column flex-space"}>
                        {!disableUserInfo &&
                            <PdfDetailedUserHeaderComponent brugerId={brugerId}/>
                        }
                        <PdfHeaderRow>
                            <PdfHeaderRow.Entry
                                label={Localizer.global_speciale()}
                                value={speciale?.navn}
                            />
                            <PdfHeaderRow.Entry
                                label={Localizer.global_uddannelsestrin()}
                                value={GetUddannelsestypeTranslation(brugerMaalbeskrivelse?.uddannelseType)}
                            />
                        </PdfHeaderRow>
                        <PdfHeaderRow>
                            <PdfHeaderRow.Entry
                                isColumn
                                label={Localizer.global_maalbeskrivelse()}
                                value={[
                                    brugerMaalbeskrivelse?.navn,
                                    !!brugerMaalbeskrivelse ? dateTimeStringFromApiToDate(brugerMaalbeskrivelse.versionsdato).dateWithoutTimeFormat(false) : ""
                                ]}
                            />
                            {stilling &&
                                <PdfHeaderRow.Entry
                                    isColumn
                                    label={Localizer.stilling()}
                                    value={[
                                        stilling.stillingNr,
                                        stilling.uddannelsessted,
                                        getStillingDurationDisplay(stilling)
                                    ]}
                                />
                            }
                        </PdfHeaderRow>
                    </div>
                </div>
            }
        </>

    );
}
