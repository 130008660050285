import classNames from "classnames";
import { BrevForloeb } from "core/sharedmodels/forloeb/brevForloebDto";
import { GenericGetUddannelsesstedInfo } from "core/sharedmodels/forloeb/forloebSimpleInfoModel";
import { GetFravaerskodeTranslation } from "core/sharedmodels/forloeb/fravaerskoderEnum";
import { UddannelseTypeEnum } from "core/sharedmodels/stilling/uddannelseTypeEnum";
import { Localizer } from "infrastructure/localization/localizer";
import { ForloebOverviewEmptyCard } from "pages/forloeb/forloebOverviews/forloebOverviewEmptyCard";

const EmptyList = () => (
    <ForloebOverviewEmptyCard emptyText={Localizer.letterPage_letterListEmptyInfo()}>
        <p>{Localizer.forloebpage_NoForloeb()}</p>
    </ForloebOverviewEmptyCard>
);

const headers = [
    Localizer.global_periode(),
    Localizer.global_forloeb()
];

const getFravaersDisplay = (f: BrevForloeb) =>  f.isFravaer && f.fravaerskodeEnum ? `${f.fravaerskodeEnum} - ${GetFravaerskodeTranslation(f.fravaerskodeEnum)},` : "";
const getUddannelsestrinDisplay = (f: BrevForloeb) => f.isFravaer ? "" : `${UddannelseTypeEnum[f.uddannelseType]},`;
const getUddannelsesInfo = (f: BrevForloeb) => GenericGetUddannelsesstedInfo(!!f.afdelingName, f.laegeInstitutionName, f.afdelingName);

const getForloebInfo = (f: BrevForloeb) => `${getUddannelsesInfo(f)}, (${f.stillingNummer}), ${getFravaersDisplay(f)} ${getUddannelsestrinDisplay(f)} ${f.specialeName}`;

const ForloebListBrevskabelonMergefield = ({forloebs} : {forloebs: BrevForloeb[]}) => {

    const hasForloeb = forloebs.length > 0;

    return (
        <table>
            <thead>
                <tr>
                    {headers.map(txt => <th>{txt}</th>)}
                </tr>
            </thead>
            <tbody>
                {hasForloeb &&  forloebs.map((f, index) => (
                    <tr key={index} className={classNames(f.isFravaer && "bg-fravaer", (f.isManueltAfsluttet && !f.isFravaer) && "bg-warning")}>
                        <td>{`${f.startDate.dateWithoutTimeFormat(false)} - ${f.endDate.dateWithoutTimeFormat(false)}`}</td>
                        <td>
                            {getForloebInfo(f)}
                            {f.attestationForTidInfotekst && 
                                <>
                                    <br/><br/>
                                    <em>{f.attestationForTidInfotekst}</em>
                                </>
                            }
                        </td>
                    </tr>
                ))}

                {!hasForloeb &&
                    <tr>
                        <td colSpan={headers.length}>
                            <div className="margin-top-s">
                                <EmptyList />
                            </div>
                        </td>
                    </tr>
                }
            </tbody>
        </table>
    )
}

export default ForloebListBrevskabelonMergefield;