import "./userList.scss";
import React from "react";
import {
    UserProfileAvatar,
    UserProfileAvatarSizeEnum,
    UserProfileLayoutEnum
} from "../userProfileAvatar/userProfileAvatar";
import {UserProfileAvatarModel} from "../userProfileAvatar/userProfileAvatarModel";
import {RolleTitelEnum} from "../../../infrastructure/enums/rolleTitelEnum";
import { PersonaleUserSimpleModel } from "core/sharedmodels/user/userPersonaleModel";

export type UserPersonaleListProps = {
    users: PersonaleUserSimpleModel[];
    favoriserCallback?: (favoritPersonaleId: string, isFavorite: boolean) => void;
}

export function UserPersonaleList({favoriserCallback, ...props}: UserPersonaleListProps) {
    
    return (
        <div className="card shadow-none">
            <div className="card-body padding-0">
                {props.users.map(user => {
                    return <React.Fragment key={user.userId}>
                        <div className="card-list">
                            <UserProfileAvatar size={UserProfileAvatarSizeEnum.small}
                                               userProfileLayoutEnum={UserProfileLayoutEnum.AsTextOnRightSide}
                                               userRoleTitles={user.rolleTitelEnums.filter(rt => rt === RolleTitelEnum.Vejleder || rt === RolleTitelEnum.Uao || rt === RolleTitelEnum.Tutorlaege || rt === RolleTitelEnum.Cheflaege || rt === RolleTitelEnum.LedendeOverlaege)}
                                               userProfileAvatarModel={new UserProfileAvatarModel(user.navn, user.initialer, false, user.email)}
                                               showFavoriteIcon
                                               favoriserCallback={favoriserCallback}
                                               userId={user.userId}
                                               isFavorite={user.isFavorite}
                            />
                        </div>
                        <div className={"card-list-border"}/>
                    </React.Fragment>
                })}
            </div>

        </div>
    );
}
