import DataadministrationOverviewContextProvider from "./dataadministrationOverviewContextProvider";
import SynkroniseredeForloebList from "./components/synkroniseredeForloebList";

export function DataadministrationOverviewPage() {
    return (
        <DataadministrationOverviewContextProvider>
            <SynkroniseredeForloebList />
        </DataadministrationOverviewContextProvider>
    )
}
