type BagdeProps = {
    text?: string,
    key?: React.Key | null | undefined,
}

const Badge = ({key, text = ""} : BagdeProps) => {
    return (                        
        <span key={key} className="badge badge-pill badge-primary padding-xs margin-right-s">
            {text}
        </span>
    )
}

export default Badge;