import {HttpClientService} from '../../../services/httpClient/httpClientService';
import {EmblaIcon} from '../emblaIcon/emblaIcon';
import {LogbogFileApi} from '../../../services/api/logbogFile/logbogFileApi';
import {FileDownloadHandler} from '../../../services/api/logbogFile/fileDownloadHandler';
import {useState} from 'react';
import {NotificationModule} from "ditmer-embla";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {FileLocationEnum} from "../../sharedmodels/file/fileLocationEnum";
import {StamdataEmailApi} from "../../../services/api/stamdataEmail/stamdataEmailApi";
import {AccountService} from "../../../services/account/accountService";
import {FileMetadata} from "../../sharedmodels/fileMetaData/fileMetaData";

const fileApi = new LogbogFileApi(new HttpClientService(), new FileDownloadHandler());
const emailApi = new StamdataEmailApi(new HttpClientService(), new AccountService());


export interface DeleteButtonProps {
    fileMetadata: FileMetadata;
    fileLocation: FileLocationEnum;
    fileDeletedCallback?: (fileMetadata: FileMetadata) => void;
}

export function DeleteButton(props: DeleteButtonProps) {
    let deleteResult: string;
    const [confirmationActive, setConfirmationActive] = useState(false);

    const deleteFile = async (fileMetadata: FileMetadata) => {
        if(props.fileLocation === FileLocationEnum.Logbog){
            deleteResult = await fileApi.deleteFileWithRelations(fileMetadata.id);
        } else if(props.fileLocation === FileLocationEnum.Email){
            deleteResult = await emailApi.deleteFile(fileMetadata.id)
        } else if (props.fileLocation === FileLocationEnum.Stamdata) {
            // This is to avoid issues when deleting stamdata files from the FileMetaDatalist.
            // As of now, the uploader used in FileMetaDatalistcomponent does not upload files to the Stamdata backend.
            deleteResult = "";
        }

        if (deleteResult === undefined) {
            NotificationModule.showError(Localizer.global_deleteFileError(), "");
        }

        if (props.fileDeletedCallback) {
            props.fileDeletedCallback(fileMetadata);
        }
    }

    const confirmDelete = async (confirmed: boolean) => {
        if (confirmed) {
            await deleteFile(props.fileMetadata);
        }

        setConfirmationActive(false);
    }

    const render = (
        <>
            {!confirmationActive &&
                <div role='button' onClick={() => setConfirmationActive(true)}>
                    <EmblaIcon iconName='delete' />
                </div>
            }

            {confirmationActive &&
                <div className="btn-group confirm" role="group">
                    <button type="button" className="btn btn-danger btn-icon btn-outline"
                        onClick={() => confirmDelete(false)}>
                        <EmblaIcon iconName='danger' />
                    </button>
                    <button type="button" className="btn btn-success btn-icon btn-outline"
                        onClick={() => confirmDelete(true)}>
                        <EmblaIcon iconName='success' />
                    </button>
                </div>
            }
        </>
    )

    return <>{render}</>
}
