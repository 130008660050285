import {
    CrudForloebInputModel
} from "./crudForloebInputModel";
import {Localizer} from "../../../infrastructure/localization/localizer";
import React, {useRef, useState} from "react";
import {nameof} from "ts-simple-nameof";
import {ValidationDatepicker} from "../../components/validation/components/validationDatePicker";
import {FormikProps} from "formik";
import {FravaerskodeEnum, GetFravaerskodeTranslation} from "../../sharedmodels/forloeb/fravaerskoderEnum";
import { FormIsValidIgnoreWarnings } from "core/components/validation/ValidationInputHelper";
import { DatePickerActionButton } from "core/components/datePicker/datePickerActionButton";
import { DatetimePickerModule } from "ditmer-embla";
import { calculateEndDate } from "./forloebHelpers";
import {ValidationDropdown} from "../../components/validation/components/validationDropdown";
import useEnumDropdownOptions from "../../components/dropdown/hooks/useEnumDropdownOptions";

type FravaerFieldsProps = {
    modalId: string;
    formik: FormikProps<CrudForloebInputModel>;
    createFravaer: (inputModel: CrudForloebInputModel) => void;
    forloebFraVU?: boolean,
    forloebHasEnded?: boolean
}

export function FravaerFields(props: FravaerFieldsProps) {
    const startDate = useRef<Date | undefined>();
    const [selectedEndDate, setselectedEndDate] = useState<Date | undefined>();

    const slutDateTimePickerModuleRef = useRef<DatetimePickerModule>(null);

    const [fravaerskoderTypeSelectOptions] = useEnumDropdownOptions<FravaerskodeEnum>(FravaerskodeEnum, GetFravaerskodeTranslation);
    const [selectedFravaerskode, setSelectedFravaerskode] = useState<FravaerskodeEnum>();

    const endDateSelected = (endDate: Date) => {
        if (startDate.current)
            setselectedEndDate(endDate);
    }

    const startDateSelected = (start: Date) => {
        if (!start) return;

        startDate.current = start;

        const sixMonthsMinusADayFromDate = start.addMonths(6).subtractDays(1);
        slutDateTimePickerModuleRef.current?.setValue(sixMonthsMinusADayFromDate);
    }

    const onSaveClicked = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        props.createFravaer(props.formik.values);
    }

    return (
        <div className="row border-top ">
            <div className="col-sm-12 margin-top-m">
                <h5>{Localizer.Fravaer()}</h5>
            </div>
            <div className="col-sm-12">
                <ValidationDatepicker
                    model={{
                        label: Localizer.startdato(),
                        placeholder: Localizer.startdatoPlaceholder(),
                        htmlName: nameof<CrudForloebInputModel>(x => x.fravaerStartDato),
                    }}
                    inputId={nameof<CrudForloebInputModel>(x => x.fravaerStartDato) + props.modalId}
                    formikProps={props.formik}
                    allowClear
                    dateSelected={startDateSelected}
                    readOnly={props.forloebHasEnded}
                    minimumDate={props.formik.values.startDato}
                />
            </div>
            <div className="col-sm-12">
                <ValidationDatepicker
                    ref={slutDateTimePickerModuleRef}
                    model={{
                        label: Localizer.slutdato(),
                        placeholder: Localizer.slutdatoPlaceholder(),
                        htmlName: nameof<CrudForloebInputModel>(x => x.fravaerSlutDato)
                    }}
                    inputId={nameof<CrudForloebInputModel>(x => x.fravaerSlutDato) + props.modalId}
                    formikProps={props.formik}
                    dateSelected={endDateSelected}
                    readOnly={props.forloebHasEnded}
                    allowClear
                    minimumDate={props.formik.values.startDato}
                    appendToInputGroup={
                        <DatePickerActionButton
                            datePickerRef={slutDateTimePickerModuleRef}
                            disabled={!selectedEndDate}
                            actionText={Localizer.forloebEdit_AddMonths(6)}
                            onButtonClickedCallback={(module) => selectedEndDate && module.setValue(calculateEndDate(selectedEndDate), true)}
                        />
                    }
                />
            </div>
            <div className="col-sm-12">
                <ValidationDropdown
                    model={{
                        label: Localizer.Fravaerskoder(),
                        placeholder: Localizer.SelectFravaerskoder(),
                        htmlName: nameof<CrudForloebInputModel>(x => x.fravaerskode)
                    }}
                    options={fravaerskoderTypeSelectOptions}
                    formikProps={props.formik}
                    itemSelected={(selectedOption) => setSelectedFravaerskode(selectedOption?.value)}
                    readOnly={props.forloebHasEnded}
                />
            </div>
            { (!props.forloebHasEnded) &&
                <div className="col-sm-12 flex-container justify-content-end">
                    <button className={"btn btn-primary"}
                            onClick={onSaveClicked}
                            disabled={
                                !FormIsValidIgnoreWarnings(props.formik.errors) ||
                                (selectedFravaerskode === undefined) ||
                                selectedEndDate === undefined ||
                                startDate.current === undefined
                            }>
                        {Localizer.SaveFravaer()}
                    </button>
                </div>
            }
        </div>
    )
}
