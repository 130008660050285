import {Localizer} from "../../../infrastructure/localization/localizer";

export function NotFoundPage() {
    return (
        <div className="container">
            <div className="flex margin-top-l padding-l">
                <div className="padding-l flex-row-center">
                    <h1>{Localizer.notFoundpage_notFound()}</h1>
                </div>
            </div>
        </div>
    );
}
