import React from "react";
import {
    BuildExisting,
    CrudBeskaeftigelseInputModel,
    crudBeskaeftigelseValidationSchema
} from "./beskaeftigelse/crudBeskaeftigelseInputModel";
import {setSlideinState, SlideInStateEnum} from "../../core/components/slideIn/slideInSlice";
import {useAppDispatch} from "../../app/hooks";
import {HttpClientService} from "../../services/httpClient/httpClientService";
import {AccountService} from "../../services/account/accountService";
import {HandleError} from "../../core/infrastructure/errors/errorBoundary";
import {ValidationForm} from "../../core/components/validation/components/validationForm";
import {Localizer} from "../../infrastructure/localization/localizer";
import {ValidationFormSubmitButton} from "../../core/components/validation/components/validationFormButton";
import {BeskaeftigelseCrudFields} from "./beskaeftigelse/beskaeftigelseCrudFields";
import {LogbogBeskaeftigelseApi} from "../../services/api/logbogBeskaeftigelse/logbogBeskaeftigelseApi";
import {BeskaeftigelseModel} from "./beskaeftigelse/beskaeftigelseModel";
import {FileDownloadHandler} from "../../services/api/logbogFile/fileDownloadHandler";
import { NotificationModule } from "ditmer-embla";

export interface AnsoegningEditBeskaeftigelseProps {
    modalId: string;
    model: BeskaeftigelseModel;
}

const beskaeftigelseApi = new LogbogBeskaeftigelseApi(new HttpClientService(), new AccountService(), new FileDownloadHandler())

export function AnsoegningEditBeskaeftigelse(props: AnsoegningEditBeskaeftigelseProps) {
    const dispatch = useAppDispatch();

    const editBeskaeftigelse = async (inputModel: CrudBeskaeftigelseInputModel) => {
        dispatch(setSlideinState({state: SlideInStateEnum.ActionOngoing, slideInId: props.modalId}))
        let beskaeftigelseId = await beskaeftigelseApi.editBeskaeftigelse(inputModel);
        if (beskaeftigelseId)
            dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: props.modalId}))
    }

    const deleteBeskaeftigelse = async (beskaeftigelseId: string, ansoegningId: string) => {
        dispatch(setSlideinState({state: SlideInStateEnum.ActionOngoing, slideInId: props.modalId}))
        let deletedBeskaeftigelse = await beskaeftigelseApi.deleteBeskaeftigelse(beskaeftigelseId, ansoegningId);
        NotificationModule.showSuccess(Localizer.ansoegOmSelvstaendigVirke_beskaeftigelseSlettet(), "");
        if (deletedBeskaeftigelse) {
            dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: props.modalId}))
        } else {
            dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: props.modalId}))
        }
    }

    const render = (

        <ValidationForm
            key={props.modalId}
            initialValues={BuildExisting(props.model)}
            validationSchema={crudBeskaeftigelseValidationSchema}
            onSubmit={
                async (values) => {
                    await editBeskaeftigelse(values).catch(err => { // catch is needed to handle unhandeled exception (eg. api offline). Formik onsubmithandler is catching all exception and writing them as warnings
                        dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: props.modalId}))
                        HandleError(err);
                    });
                }}>
            {
                (formik) => (
                    <>
                        <BeskaeftigelseCrudFields modalId={props.modalId} formik={formik}/>

                        <div className="modal-actions ">
                            <button type={"button"}
                                    className="btn btn-danger mr-2"
                                    onClick={() => deleteBeskaeftigelse(props.model.id, props.model.ansoegningId)}>{Localizer.global_delete()}
                            </button>
                            <ValidationFormSubmitButton formikProps={formik}>
                                {Localizer.global_save()}
                            </ValidationFormSubmitButton>
                        </div>
                    </>
                )
            }
        </ValidationForm>
    )
    return <>{render}</>;
}
