import {ValidationForm} from "../../../core/components/validation/components/validationForm";
import {
    BuildNew,
    CreateNyhedValidationSchema,
    CrudNyhedInputModel
} from "../../../core/components/nyheder/crudNyhedInputModel";
import {NyhedInputFields} from "../../../core/components/nyheder/nyhedInputFields";
import {ValidationFormSubmitButton} from "../../../core/components/validation/components/validationFormButton";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {LogbogNyhederApi} from "../../../services/api/logbogNyheder/logbogNyhederApi";
import {HttpClientService} from "../../../services/httpClient/httpClientService";
import {setSlideinState, SlideInStateEnum} from "../../../core/components/slideIn/slideInSlice";
import {useDispatch} from "react-redux";
import { HandleError } from "core/infrastructure/errors/errorBoundary";

interface CreateNyhederProps{
    modalTarget: string;
}

const nyhederApi = new LogbogNyhederApi(new HttpClientService());

export function CreateNyheder(props: CreateNyhederProps){
    const dispatch = useDispatch();

    const CreateNyhed = async (inputModel: CrudNyhedInputModel) => {
        dispatch(setSlideinState({ state: SlideInStateEnum.ActionOngoing, slideInId: props.modalTarget }));
        await nyhederApi.CreateNyhed(inputModel);
        dispatch(setSlideinState({ state: SlideInStateEnum.Closing, slideInId: props.modalTarget }));
    };

    return (
        <ValidationForm
            key={props.modalTarget}
            initialValues={BuildNew()}
            validationSchema={CreateNyhedValidationSchema}
            onSubmit={async (values) => {
                await CreateNyhed(values).catch(err => { // catch is needed to handle unhandeled exception (eg. api offline). Formik onsubmithandler is catching all exception and writing them as warnings
                    dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: props.modalTarget}))
                    HandleError(err);
                });
            }}
            >
            {
                (formik) => (
                    <>
                        <NyhedInputFields
                            modalId={props.modalTarget}
                            formik={formik}/>

                        <div className="modal-actions">
                            <ValidationFormSubmitButton formikProps={formik}>
                                {Localizer.nyhederPage_OpretNyhed()}
                            </ValidationFormSubmitButton>
                        </div>
                    </>
                )
            }
        </ValidationForm>
    );
}
