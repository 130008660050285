import React, {useState} from 'react';
import {Spinner} from "../spinner/spinner";
import {EmblaIcon} from "../emblaIcon/emblaIcon";
import './asyncInput.scss';
import {ApiResponse} from "../../../services/api/apiResponse";

interface AsyncInputProps {
    id: string;
    name: string,
    value: string;
    isDisabled?: boolean;
    maxValueLenght?: number;
    actionExecute: (value: string) => Promise<ApiResponse<boolean>>;
}

export function AsyncInput({isDisabled = false, ...props}: AsyncInputProps) {
    const [asyncProcessing, setAsyncProcessing] = useState(false);
    const [successState, setSuccessState] = useState<boolean | undefined>();
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [stateValue, setStateValue] = useState<string>(props.value);

    const actionExecute = async (value: string) => {
        if (stateValue !== value) {
            setSuccessState(undefined);
            setAsyncProcessing(true)
            const success = await props.actionExecute(value)
            setSuccessState(success.data);
            setErrorMessage(success.apiResponseMessage.errorMessage);
            if (success)
                setStateValue(value)
            setAsyncProcessing(false)
        }
    }

    const succcesClass = () => {
        if (successState == undefined) {
            return "form-control";
        }

        if (successState) {
            return "form-control input-success"
        }

        if (!successState) {
            return "form-control input-danger"
        }
    }

    return (
        <>
            <div className="input-icon-container">
                <input id={props.id} 
                       name={props.name} 
                       className={succcesClass()} 
                       disabled = {isDisabled}
                       defaultValue={props.value} 
                       maxLength={props.maxValueLenght}
                       onBlur={async (event) => {
                            await actionExecute(event.currentTarget.value)
                        }}>
                </input>

                {successState !== undefined && successState &&
                <div className="input-icon success">
                    <EmblaIcon iconName="success"/>
                </div>
                }
                {successState != undefined && !successState &&
                <>
                    <div className="input-icon warning">
                        <EmblaIcon iconName="warning"/>
                    </div>
                    <div className="field-validation-error" data-valmsg-for="workPhone" data-valmsg-replace="true">
                        <span id="FirstName-error" className="">{errorMessage}</span>
                    </div>
                </>

                }

                {asyncProcessing &&
                <div className="input-icon">
                    <Spinner spinnerSize="exstra-small"/>
                </div>
                }
            </div>


        </>
    );
}
