import DynamicButton from "core/components/button/dynamicButton";
import Container from "core/components/container/container";
import { EmblaIcons } from "core/emblaIcons";
import { Localizer } from "infrastructure/localization/localizer";
import BrevskabelonVisibilityTypeEnum from "infrastructure/enums/brevskabelonVisibilityTypeEnum";
import { useCallback, useEffect, useState } from "react";
import { useBrevskabelonContext } from "../context/brevskabelonContextProvider";
import useStamdataApi from "core/hooks/useStamdataApi";
import useUser from "core/hooks/useUser";
import { useHistory } from "react-router-dom";
import { RoutePaths } from "infrastructure/routes";
import BreveList from "./BreveList";
import { Loading } from "core/components/loading/loading";
import PageLengthDropdown from "core/components/dropdown/components/customDropdowns/pageLengthDropdown";
import { PaginationModel } from "core/sharedmodels/filter/PaginationModel";
import { Pagination } from "core/components/pagination/pagination";
import { NotificationModule } from "ditmer-embla";
import classNames from "classnames";

const InitialPage = 1;
const InitialPageSize = 10;

const BreveOverview = ({visibility} : {visibility: BrevskabelonVisibilityTypeEnum}) => {
    const { push } = useHistory();
    const { currentUser } = useUser();
    const { brevskabelonApi } = useStamdataApi();
    const { state, setBreve, setIsLoading } = useBrevskabelonContext();

    const [pagination, setPagination] = useState(new PaginationModel(InitialPage, InitialPageSize, 0));
    const [isAscOrder, setIsAscOrder] = useState<boolean>(true);

    const fetchBreve = useCallback(async (visibility: BrevskabelonVisibilityTypeEnum, page: number, pageLength: number) => {
        setIsLoading(true);

        if(visibility === BrevskabelonVisibilityTypeEnum.Region && !state.regionId) {
            setIsLoading(false);
            return;
        };

        const { recordsFiltered, data } = await brevskabelonApi.getList({
            visibilityType: visibility,
            regionId: state.regionId,
            oprettetAf: currentUser.UserId,
            queryParams: { Page: page, PageLength: pageLength, Search: "" },
            isAscOrder: isAscOrder
        });

        setBreve(data);

        setPagination((prev) => ({...prev, recordCount: recordsFiltered}));

        setIsLoading(false);
    }, [brevskabelonApi, currentUser.UserId, setBreve, setIsLoading, state.regionId, isAscOrder]);

    useEffect(() => {
        fetchBreve(visibility, pagination.page, pagination.pageLength);
    }, [fetchBreve, pagination.page, pagination.pageLength, visibility]);

    const goToCreationPage = () => push(RoutePaths.BrevskabelonerCreatePage({
        uddannelseslaege: state.laegeId
    }).url);

    const goToEditPage = (brevId: string) => push(RoutePaths.BrevskabelonerEditPage({
        uddannelseslaege: state.laegeId,
        brev: brevId
    }).url);

    const goToGeneratePdfPage = (brevId: string) => push(RoutePaths.BrevskabelonerGeneratePdfPage({
        uddannelseslaege: state.laegeId,
        brev: brevId
    }).url);

    const handlePagination = async (page: number) => setPagination(prev => ({ ...prev, page: page }));
    const onPageLengthChangeCallback = (pageLength: number) => setPagination(prev => ({ ...prev, pageLength: pageLength }));

    const deleteBrevskabelon = useCallback((brevId: string) => {
        brevskabelonApi
            .delete(brevId)
            .then(() => NotificationModule.showSuccess(Localizer.letterPage_DeleteBrevSuccess(), ""))
            .catch(() => NotificationModule.showError(Localizer.letterPage_DeleteBrevError(), ""))
            .finally(() => fetchBreve(visibility, pagination.page, pagination.pageLength));
    }, [brevskabelonApi, fetchBreve, pagination.page, pagination.pageLength, visibility]);

    const canEditAndDelete = currentUser.IsAdmin() || visibility === BrevskabelonVisibilityTypeEnum.Private;

    return (
        <Container>

            <Container.Header titleConfig={{ title: Localizer.letterPage_ChooseTemplate() }} >
                <div className="d-flex justify-content-between">
                    <PageLengthDropdown
                        range={[InitialPageSize, 25, 50]}
                        showTitle={false}
                        onChange={(length) => onPageLengthChangeCallback(length?.value ?? InitialPageSize)}
                    />

                    <DynamicButton isPrimary onClick={goToCreationPage} >
                        <DynamicButton.TextIconItem
                            text={Localizer.letterPage_CreateTemplate()}
                            iconName={EmblaIcons.Plus}
                        />
                    </DynamicButton>
                </div>
            </Container.Header>

            <Container.Body additionalClasses={classNames(!state.isLoading && "padding-0 ", "uddannelseslaeger-list")}>
                <Loading isLoading={state.isLoading} text={Localizer.letterPage_fetchingLetters()}>
                    <BreveList
                        isAscOrder={isAscOrder}
                        breve={state.breve}
                        editOnClick={canEditAndDelete ? goToEditPage : undefined}
                        downloadOnClick={goToGeneratePdfPage}
                        deleteOnClick={canEditAndDelete ? deleteBrevskabelon : undefined}
                        onSortorderChange={setIsAscOrder}
                    />
                </Loading>
            </Container.Body>

            <Container.Footer additionalClasses="flex-row-center">
                <Pagination
                    handlePagination={handlePagination}
                    paginationModel={pagination}
                />
            </Container.Footer>

        </Container>
    )
}

export default BreveOverview;
