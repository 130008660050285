import {useEffect, useState} from "react";
import {LogbogSpoergsmaalResponseModel} from "../../../services/api/logbogEvaluering/logbogSpoergsmaalResponseModel";
import {EvalueringStatistikApi} from "../../../services/api/evalueringStatistik/evalueringStatistikApi";
import {HttpClientService} from "../../../services/httpClient/httpClientService";
import {Localizer} from "infrastructure/localization/localizer";
import PdfComponent from "core/components/pdf/pdfComponent";
import {FileDownloadHandler} from "../../../services/api/logbogFile/fileDownloadHandler";
import {useFetchWithErrorHandlingPdf} from "./useFetchWithErrorHandlingPdf";
import useLogbogApi from "../../hooks/useLogbogApi";

export function EvalueringSpoergerammePdf() {
    const [spoergsmaal, setSpoergsmaal] = useState<LogbogSpoergsmaalResponseModel[]>([]);

    const setError = useFetchWithErrorHandlingPdf();

    const { evalueringstatistikApi } = useLogbogApi();

    useEffect(() => {
        const fetch = async () => {
            const forloebEvalueringModel = await evalueringstatistikApi.getForloebEvalueringSpoergsmaal();
            forloebEvalueringModel.sektioner.forEach(s => s.spoergsmaal.forEach(sp => sp.sortOrder = s.sortOrder));
            let questions = forloebEvalueringModel.sektioner.flatMap(s => s.spoergsmaal);
            setSpoergsmaal(questions);
            (document as any).pdfReady = true;
        }
        fetch().catch(err => setError(err));
    }, [evalueringstatistikApi, setError])

    return (
        <PdfComponent>
            <p className="pdf-header">
                {Localizer.evalueringPage_spoergerammePdfHeaderText()}
            </p>
            <PdfComponent.BlockItem className={"card"}>
                {spoergsmaal &&
                    spoergsmaal.map((s, i) =>
                        <PdfComponent.NonBreakableItem className={"card-body"}>
                            <div>
                                <div className={"flex-container margin-top-s padding-bottom-s"}>
                                    <h5>{i+1}</h5>
                                    <div className={"margin-left-m spoergeramme-question"}>
                                        <h5>{s.titel}</h5>
                                        {s.tekst}
                                    </div>
                                </div>
                            </div>
                        </PdfComponent.NonBreakableItem>
                    )
                }
            </PdfComponent.BlockItem>
        </PdfComponent>
    );
}
