import React, {useState} from 'react'
import {HttpClientService} from '../../../services/httpClient/httpClientService';
import {Localizer} from '../../../infrastructure/localization/localizer';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {ModalAcceptType, ModalSubmitMessage} from "../../components/modalMessage/modalSubmitMessage";
import {AccountService} from "../../../services/account/accountService";
import {forloebReducer, ForloebSliceState} from "../../forloebSlice";
import {setSlideinState, SlideInStateEnum} from "../../components/slideIn/slideInSlice";
import {LogbogLaegekursusGruppeApi} from "../../../services/api/laegekursusGruppe/logbogLaegekursusGruppeApi";
import {NotificationModule} from "ditmer-embla";
import {LaegekursusGruppeTypeEnum} from "../../sharedmodels/kursus/laegekursusGruppeTypeEnum";

const laegekursusGruppeApi = new LogbogLaegekursusGruppeApi(new HttpClientService(), new AccountService())

interface TildelSamletGodkendelseButtonProps {
    laegekursusKategori: string;
    kursusGruppeId: string;
    kursusOrKursusGruppeUpdatedCallback: () => void;
    isDisabled?: boolean;
}

export function TildelSamletGodkendelseButton(props: TildelSamletGodkendelseButtonProps) {
    const dispatch = useAppDispatch();
    const modalId = "tildelSamletGodkendelseModal";
    const [showModal, setShowModal] = useState<boolean>(false);

    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    const tildelSamletGodkendelseTilKursusGruppe = async () => {
        const uddannelsessted = forloebSliceState.forloebState.afdelingName === null
            ? forloebSliceState.forloebState.laegeInstitutionName
            : forloebSliceState.forloebState.laegeInstitutionName + ", " + forloebSliceState.forloebState.afdelingName
        const godkendtLaegekursusGruppeId = await laegekursusGruppeApi.tildelSamletGodkendelse(props.kursusGruppeId, uddannelsessted);
        if (godkendtLaegekursusGruppeId) {
            dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: modalId}))
            NotificationModule.showSuccess(Localizer.kursusPage_samletGodkendelseTildelt(), "");
            props.kursusOrKursusGruppeUpdatedCallback();
        }
        else
            dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: modalId}))
    }

    const render = (
        <>
            <button className="btn btn-default" onClick={() => setShowModal(true)}
                    data-toggle="modal" data-target={`#${modalId}`}
                    disabled={props.isDisabled}>
                {Localizer.kursusPage_tildelSamletGodkendelse()}
            </button>

            {showModal &&
            <ModalSubmitMessage modalId={modalId}
                                title={Localizer.kursusPage_samletGodkendelse()}
                                description={<p>{Localizer.kursusPage_areYourSureTildelSamletGodkendelse(props.laegekursusKategori.toLowerCase())}</p>}
                                cancelAction={() => setShowModal(false)}
                                acceptAction={() => tildelSamletGodkendelseTilKursusGruppe()}
                                modalAcceptType={ModalAcceptType.success}
                                acceptButtonText={Localizer.kursusPage_tildelSamletGodkendelse()}
            />
            }
        </>
    )

    return <>{render}</>
}
