import {useRef, useState} from "react";
import {LogbogLaegekompetenceApi} from "../../../services/api/logbogLaegekompetence/logbogLaegekompetenceApi";
import {HttpClientService} from "../../../services/httpClient/httpClientService";
import {useAppSelector} from "../../../app/hooks";
import {forloebReducer, ForloebSliceState, setCurrentForloeb} from "../../../core/forloebSlice";
import {LaegekompetenceMeta} from "../../../core/sharedmodels/kompetence/laegekompetenceMeta";
import useEffectAsync from "../../../infrastructure/effect";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {ActionIcon} from "../../../core/components/actionIcon/actionIcon";
import {RoutePaths} from "../../../infrastructure/routes";
import {useHistory, useRouteMatch} from "react-router-dom";
import {Loading} from "../../../core/components/loading/loading";
import {LineChart, LineChartColor, LineChartItem} from "../../../core/components/charts/lineChart";
import {LaegekompetenceStatusEnum, LaegekompetenceStatusEnumTranslator} from "../../../core/sharedmodels/kompetence/laegekompetenceStatusEnum";
import {ForloebOverviewCard} from "./forloebOverviewCard";
import {ForloebOverviewEmptyCard} from "./forloebOverviewEmptyCard";
import {ForloebApi} from "../../../services/api/forloeb/forloebApi";
import {AccountService} from "../../../services/account/accountService";
import {ForloebModel} from "../../../core/sharedmodels/forloeb/forloebModel";
import {useDispatch} from "react-redux";
import {FileDownloadHandler} from "../../../services/api/logbogFile/fileDownloadHandler";
import {StamdataMaalbeskrivelseApi} from "../../../services/api/stamdataMaalbeskrivelse/stamdataMaalbeskrivelseApi";
import {kompetenceCardReducer, KompetenceCardState} from "../../kompetencerPage/cards/kompetenceCardSlice";
import {SeAlleKompetencerInputModel} from "../../../core/componentsPage/forloeb/SeAlleKompetencerInputModel";

export interface ForloebKompetencerOverviewProps{
    additionalClasses?: string
}

const kompetencerApi = new LogbogLaegekompetenceApi(new HttpClientService(), new FileDownloadHandler(), new AccountService());
const forloebApi = new ForloebApi(new HttpClientService(), new AccountService());
const stamdataMaalbeskrivelseApi = new StamdataMaalbeskrivelseApi(new HttpClientService(), new AccountService());

export function ForloebKompetencerOverview(props: ForloebKompetencerOverviewProps) {
    const routeMatch = useRouteMatch();
    const history = useHistory();
    const dispatch = useDispatch();

    const [brugerLaegekompetenceMetas, setBrugerLaegekompetenceMetas] = useState<LaegekompetenceMeta[]>([]);
    const [forloebLaegekompetenceMetas, setForloebLaegekompetenceMetas] = useState<LaegekompetenceMeta[]>([]);
    const [overviewInForloebState, setOverviewInForloebState] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;
    const kompetenceCardState = useAppSelector(kompetenceCardReducer) as KompetenceCardState;

    const uddannelseslaegeId = (routeMatch.params as { id: string }).id;
    const _currentUser = useRef(new AccountService().getUser());

    useEffectAsync(async () => {
        const brugerMaalbeskrivelse = await stamdataMaalbeskrivelseApi.getBrugerMaalbeskrivelse(forloebSliceState.forloebState.brugerMaalbeskrivelseId);
        let laegekompetencer = await kompetencerApi.getLaegekompetencerByBrugerId(uddannelseslaegeId);
        const kompetencerPaaAktivMaalbeskrivelse = laegekompetencer.filter(k => k.brugerMaalbeskrivelseId === brugerMaalbeskrivelse.id);

        let forloebKompetencer = kompetencerPaaAktivMaalbeskrivelse.filter(lk => lk.forloebIds.includes(forloebSliceState.forloebState.id));

        setForloebLaegekompetenceMetas(forloebKompetencer);
        setBrugerLaegekompetenceMetas(kompetencerPaaAktivMaalbeskrivelse);

        if(forloebKompetencer?.length > 0){
            setOverviewInForloebState(true);
        }else{
            setOverviewInForloebState(false);
        }

        setIsLoading(false);
    }, [forloebSliceState.forloebState.id, kompetenceCardState.laegekompetenceListRefreshPing, forloebSliceState.refreshForloebPing]);

    const navigateToPlanlaegKompetencer = () => {
        history.push(RoutePaths.Kompetencer({uddannelseslaege: uddannelseslaegeId}).url, {planlaeg: true})
    }

    const rightHeaderContent = (
        <div className="d-flex">
            {_currentUser.current.CanPlanlaeggeForloeb() && !forloebSliceState.forloebState.hasEnded &&
                <ActionIcon iconName="edit" addtionalClassNames="margin-right-s" action={navigateToPlanlaegKompetencer}/>
            }
            <ActionIcon iconName="arrow-right" routeUrl={RoutePaths.Kompetencer({uddannelseslaege: uddannelseslaegeId}).url}/>
        </div>
    )

    const getCountByStatus = (laegekompetencer: LaegekompetenceMeta[], status: LaegekompetenceStatusEnum) => {
        return laegekompetencer.filter(lk => lk.status === status).length;
    }

    const generateChartItems = (): LineChartItem[] => {
        let kompetenceMetas = overviewInForloebState ? forloebLaegekompetenceMetas : brugerLaegekompetenceMetas;

        let planlagtCount = getCountByStatus(kompetenceMetas, LaegekompetenceStatusEnum.Planlagt);
        let klarmeldtCount = getCountByStatus(kompetenceMetas, LaegekompetenceStatusEnum.Klarmeldt);
        let godkendtCount = getCountByStatus(kompetenceMetas, LaegekompetenceStatusEnum.Godkendt);

        return [
            {count: planlagtCount, color: LineChartColor.info, title: LaegekompetenceStatusEnumTranslator(LaegekompetenceStatusEnum.Planlagt)},
            {count: klarmeldtCount, color: LineChartColor.warning, title: LaegekompetenceStatusEnumTranslator(LaegekompetenceStatusEnum.Klarmeldt)},
            {count: godkendtCount, color: LineChartColor.success, title: LaegekompetenceStatusEnumTranslator(LaegekompetenceStatusEnum.Godkendt)}
        ];
    }

    const generateOverviewInfoText = () => {
        if(overviewInForloebState){
            let remaining = brugerLaegekompetenceMetas.filter(lk => !lk.forloebIds || lk.forloebIds.length === 0).length.toString();
            let forloebKompetencer = forloebLaegekompetenceMetas.length.toString();

            return Localizer.forloebpage_kompetencerOverviewInfo(forloebKompetencer, remaining);
        }else{
            return Localizer.forloebpage_kompetencerOverviewOnBrugerInfo();
        }
    }

    const patchSeAlleKompetencer = async () => {
        let forloeb = ForloebModel.FromState(forloebSliceState.forloebState);
        forloeb.seAlleKompetencer = true;

        let edit = await forloebApi.setSeAlleKompetencer(new SeAlleKompetencerInputModel(forloeb.id, forloeb.seAlleKompetencer));

        if(edit){
            dispatch(setCurrentForloeb(forloeb.ToState()))
        }
    }

    const overviewContent = (
        <>
            <LineChart items={generateChartItems()}/>
            <p className="subtle margin-top-m">{generateOverviewInfoText()}</p>
        </>
    )

    const overviewEmptyState = (
        <>
            <h4 className="margin-bottom-m">{Localizer.global_kompetencer()}</h4>

            <ForloebOverviewEmptyCard>
                <p>{Localizer.forloebpage_kompetencerOverviewEmptyInfo()}</p>

                <div>
                    <button type="button" className="btn btn-default margin-right-m" onClick={patchSeAlleKompetencer}>{Localizer.global_seAlle()}</button>
                    {!forloebSliceState.forloebState.hasEnded &&
                        <button type="button" className="btn btn-success" onClick={navigateToPlanlaegKompetencer}>{Localizer.global_add()}</button>
                    }
                </div>
            </ForloebOverviewEmptyCard>
        </>
    )

    const generateTitle = () => {
        let title;

        if (overviewInForloebState){
            title = `${Localizer.global_kompetencer()} (${forloebLaegekompetenceMetas.length})`;
        }else {
            title = `${Localizer.global_kompetencer()} (${brugerLaegekompetenceMetas.length})`;
        }

        return title;
    }

    return (
        <div className={props.additionalClasses}>
            <ForloebOverviewCard title={generateTitle()} rightHeaderContent={rightHeaderContent}>

                <Loading isLoading={isLoading} text={Localizer.kompetencePage_getKompetencer()}>
                    {!forloebSliceState.forloebState.seAlleKompetencer && forloebLaegekompetenceMetas?.length == 0 &&
                        overviewEmptyState
                    }

                    {(forloebLaegekompetenceMetas?.length > 0 || forloebSliceState.forloebState.seAlleKompetencer) &&
                        overviewContent
                    }
                </Loading>

            </ForloebOverviewCard>
        </div>
    );
}
