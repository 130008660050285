enum StpsStep {
    Beskaeftigelse = "1",
    AnsaettelseUddannelsesaftaler = "2",
    KlargoorDokumentation = "3",
    Afsendelse = "4",
}

export default StpsStep;

export const toStpsStep = (val: number) => {
    switch (val) {
        case StpsStep.Beskaeftigelse.toInt():
            return StpsStep.Beskaeftigelse;

        case StpsStep.AnsaettelseUddannelsesaftaler.toInt():
            return StpsStep.AnsaettelseUddannelsesaftaler;

        case StpsStep.KlargoorDokumentation.toInt():
            return StpsStep.KlargoorDokumentation;

        case StpsStep.Afsendelse.toInt():
            return StpsStep.Afsendelse;

        default:
            return undefined;
    }
}

const toIndex = (step: StpsStep) => {
    return step.toInt() - 1;
}

export const toIndexFromStpsStep = (step?: StpsStep, fallbackIndex=0) => {
    return step ? toIndex(step) : fallbackIndex;
}

export const toStpsStepFromIndex = (index: number) => {
    return toStpsStep(index + 1);
}