export enum EmailTypeEnum {
    Custom = 0,
    ResetPassword = 1,
    ActivateUser = 2,
    FailedLoginAttempt = 3,
    ForloebEndsIn30Days = 4,
    TwoFactorToken = 5,
    StpsSelvstaendigtVirke = 6,
    StpsSpeciallaege = 7,
    StpsSupplerendeSelvstaendigtVirke = 8,
    StpsSupplerendeSpeciallaege = 9,
    ForloebEvaluationReminder = 10,
    ForloebEvaluationLastReminder = 11,
    ForloebEvaluationOneTimeReminder = 12,
    AfsendelseAfDokumentationSuccess = 13,
    AfsendelseAfDokumentationFailed = 14,
    StandardEmailTemplate = 16,
    Kalenderinvitation = 17,
    Notifikation = 18,
    BrugerUdeladForloeb = 19,
}
