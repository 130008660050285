import {ReactNode} from "react";
import { NonBreakablePdfItemWrapper, BlockPdfItem } from "./nonBreakablePdfEntryWrapper";
import CardBody from "../card/CardBody";
import Title from "../titels/title";
import HtmlHeading from "../titels/HtmlHeadings";

type PdfComponentProps = {
    children?: ReactNode;
    heading?: HtmlHeading;
}

const PdfComponent = (props: PdfComponentProps) => {
    return (
        <>
            <div className={"pdf-content"}>
                {props.children}
            </div>
        </>
    );
}

const PdfCardHeader = (props: PdfComponentProps) => <div className="card-header">{props.children}</div>

const PdfTitle = ({title, heading, ...props}: PdfComponentProps & {title: string} ) => {
    return (
        <Title
            heading={heading ?? HtmlHeading.H2}
            title={title}
            titleClasses="font-weight-bolder margin-bottom-l"
        >
            {props.children}
        </Title>
    )
}

// These will only show when the PDF is generated.
const PdfPageBreak = () => <div className="pdf-page-break"/>;
const PdfPageBreakAfter = ({children} : PdfComponentProps) => <div className="pdf-page-break-after">{children}</div>;

export default Object.assign(
    PdfComponent,
    {
        NonBreakableItem: NonBreakablePdfItemWrapper,
        BlockItem: BlockPdfItem,
        CardHeader: PdfCardHeader,
        CardBody,
        Title: PdfTitle,
        PageBreak: PdfPageBreak,
        PageBreakAfter: PdfPageBreakAfter
    }
)
