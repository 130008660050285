import { Localizer } from "infrastructure/localization/localizer";

export enum UddannelseslaegeStatusEnum {
    Nuvaerenede = 1,
    Fremtidige = 2,
    Tidligere = 3,
}

export const UddannelseslaegeStatusEnumToString = (status: UddannelseslaegeStatusEnum): string => {
    switch (status) {
        case UddannelseslaegeStatusEnum.Nuvaerenede:
            return Localizer.global_current();
        case UddannelseslaegeStatusEnum.Fremtidige:
            return Localizer.global_future();
        case UddannelseslaegeStatusEnum.Tidligere:
            return Localizer.global_former();
        default:
            throw Error("UddannelseslaegeStatusEnum could not be mapped (input:" + status + ")");
    }
}

export const toUddannelseslaegeStatusEnum = (n: number): UddannelseslaegeStatusEnum => {
    switch (n) {
        case UddannelseslaegeStatusEnum.Nuvaerenede:
            return UddannelseslaegeStatusEnum.Nuvaerenede;
        case UddannelseslaegeStatusEnum.Fremtidige:
            return UddannelseslaegeStatusEnum.Fremtidige;
        case UddannelseslaegeStatusEnum.Tidligere:
            return UddannelseslaegeStatusEnum.Tidligere;
        default:
            throw Error("Number could not be mapped (input:" + n + ")");
    }
}