import React, {useEffect, useState} from 'react'
import {Localizer} from "../../../infrastructure/localization/localizer";
import {CrudVejlederInputModel} from "./crudVejlederInputModel";
import {FormikProps} from "formik";
import {nameof} from "ts-simple-nameof";
import './vejlederCrudFields.scss';
import {Loading} from "../../../core/components/loading/loading";
import {ValidationInput} from "../../../core/components/validation/components/validationInput";

type VejlederNewUserCrudFieldsProps = {
    modalId: string;
    formik: FormikProps<CrudVejlederInputModel>;
}

export function VejlederNewUserCrudFields(props: VejlederNewUserCrudFieldsProps) {
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(false);
    }, []);

    const render = (
        <Loading isLoading={isLoading} text={Localizer.global_getChoices()}>
            <div className="col-sm-12 margin-top-m">
                <div className="alert alert-warning" role={"alert"}>
                    {Localizer.vejlederpage_moreInfoNeededToCreateUser()}
                </div>
            </div>
            <div className="col-sm-6">
                <ValidationInput
                    model={{
                        label: Localizer.global_mobilePhone(),
                        placeholder: Localizer.global_mobilePhone(),
                        htmlName: nameof<CrudVejlederInputModel>(x => x.mobilePhone)
                    }}
                    inputId={nameof<CrudVejlederInputModel>(x => x.mobilePhone) + props.modalId}
                    formikProps={props.formik}/>
            </div>
            <div className="col-sm-6">
                <ValidationInput
                    model={{
                        label: Localizer.global_email(),
                        placeholder: Localizer.global_email(),
                        htmlName: nameof<CrudVejlederInputModel>(x => x.email)
                    }}
                    inputId={nameof<CrudVejlederInputModel>(x => x.email) + props.modalId}
                    formikProps={props.formik}/>
            </div>
        </Loading>
    );
    return <>{render}</>
}
