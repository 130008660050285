import {AccountService} from "../../../services/account/accountService";
import './bottom-menu.scss';
import {RootPaths} from "../../../infrastructure/routes";
import {useLocation, useRouteMatch} from "react-router-dom";
import {setrootMenuActive, setTopmenuTitle} from "../leftMenu/leftMenuSlice";
import {useDispatch} from "react-redux";
import {LogbogUserInfoApi} from "../../../services/api/logbogUser/logbogUserInfoApi";
import {HttpClientService} from "../../../services/httpClient/httpClientService";
import useEffectAsync from "../../../infrastructure/effect";
import { RoleDynamicMenu } from "../leftMenu/RoleDynamicMenu";

export function BottomMenu() {
    const dispatch = useDispatch();
    const location = useLocation();
    const routeMatch = useRouteMatch();

    const userService = new AccountService();
    const userApi = new LogbogUserInfoApi(new HttpClientService(), userService);
    const user = userService.getUser();

    const currentUrl = location.pathname;
    let rootPaths = RootPaths.getRootPaths(user);
    dispatch(setrootMenuActive(rootPaths.includes(currentUrl)));

    useEffectAsync(async () => {
        const routeUserId = (routeMatch.params as { id: string }).id ?? user.UserId;

        if (routeUserId !== user.UserId){
            const routeUser = await userApi.getUserInfo(routeUserId);

            if(routeUser.userId){
                dispatch(setTopmenuTitle({title: routeUser.firstName + " " + routeUser.lastName, subtitle: ""}));
            }
        }
        else{
            dispatch(setTopmenuTitle({title: "", subtitle: ""}));
        }
    }, undefined);

    return (<RoleDynamicMenu wrapperDivClass="bottom-menu" user={user} showNotification={false}/>);
}
