import {Localizer} from "../../../infrastructure/localization/localizer";
import React, {ReactFragment, useEffect, useState} from "react";
import {HttpClientService} from "../../../services/httpClient/httpClientService";
import {AccountService} from "../../../services/account/accountService";
import {Loading} from "../loading/loading";
import {ForloebOverviewConstants} from "./forloebOverviewConstants";
import {LaegeinstitutionWithStillingerModel} from "../../sharedmodels/forloeb/laegeinstitutionWithStillingerModel";
import {StillingForloebOverviewModel} from "../../sharedmodels/stilling/stillingForloebOverviewModel";
import {LaegeStillingView} from "./stillingViewComponents/laegeStillingView";
import {UddannelsesstedView} from "./stillingViewComponents/uddannelsesstedView";
import {ForloebApi} from "../../../services/api/forloeb/forloebApi";
import {ForloebOverviewFilterModel} from "./ForloebOverviewFilterModel";
import {FilterParamsModel} from "../../sharedmodels/filter/FilterParamsModel";
import {PaginationForOverview} from "./stillingViewComponents/paginationForOverview";
import {useAppSelector} from "../../../app/hooks";
import {forloebOverviewFiltersReducer, ForloebOverviewFiltersState} from "./forloebOverviewFiltersSlice";
import {
    calculateAndSetYearsForStillinger,
    cloneFilterFromState,
    overlappingCount
} from "./forloebOverviewUtils/forloebOverviewUtils";
import {resetScrollToYearInForloebOverviewChart, scrollToYearInForloebOverviewChart} from "./scrollHelper";
import {ForloebOverviewNoFilterResults} from "./forloebOverviewNoFilterResults";
import {
    getDateFilterInput,
    getFilterEndDate,
    getFilterStartDate,
    getScrollForloebDateChartInput
} from "./ForloebOverviewDateFilterModel";
import Overview from "./OverviewComponent/Overview";
import ForloebOverviewcontainer from "./forloebOverviewContainer";
import {LaegeStillingModel} from "../../sharedmodels/stilling/laegeStillingModel";
import {HorizontalLineSpacer} from "./OverviewComponent/horizontalLineSpacer";
import {toDate} from "../../../index";
import {HorizontalLine} from "./OverviewComponent/horizontalLine";
import {HoverLine} from "./OverviewComponent/hoverLine";
import useScreenResolution from "core/hooks/useScreenResolution";

type BaseLookupStateModel = {
    rowIndex: number;
    collapsed: boolean;
}

type UddannelsesstedLookupStateModel = {
    uddannelsesstedId: string;
    uddannelsesstedNavn: string;
} & BaseLookupStateModel;

type AfdelingLookupStateModel = {
    afdelingId: string;
    afdelingNavn: string;
    uddannelsesstedId: string;
} & BaseLookupStateModel;

type StillingLookupStateModel = {
    stillingModel: StillingForloebOverviewModel;
    uddannelsesstedId: string;
    afdelingId?: string;
} & BaseLookupStateModel;

export interface ForloebOverviewStillingerViewProps {
    overviewFilter: ForloebOverviewFilterModel;
    filterParams: FilterParamsModel;
    handlePaginationCallback: (page: number) => void;
    setPaginationCountCallback: (filteredRecords: number, recordsTotal: number, xOfYisLoading: boolean) => ReactFragment;
    handleForloebClicked: (forloebId: string) => void;
    scrollContainerRef: React.RefObject<HTMLDivElement>;
    overlappendeVisningState?: boolean;
    visHorizontaleLinjerState?: boolean;
}

const forloebApi = new ForloebApi(new HttpClientService(), new AccountService());

export const ForloebOverviewStillingerView = ({
    overviewFilter,
    filterParams,
    handlePaginationCallback,
    handleForloebClicked,
    setPaginationCountCallback,
    scrollContainerRef,
    overlappendeVisningState = true,
    visHorizontaleLinjerState = true
}: ForloebOverviewStillingerViewProps) => {

    const forloebOverviewFilterSliceState = useAppSelector(forloebOverviewFiltersReducer) as ForloebOverviewFiltersState;

    //constants
    const months = ForloebOverviewConstants.months;
    const offset = 2;

    //states
    const [isLoading, setIsLoading] = useState(true);
    const [uddannelsesstederWithStillinger, setUddannelsesstederWithStillinger] = useState([] as LaegeinstitutionWithStillingerModel[]);
    const [uddannelsesstederLookupState, setUddannelsesstederLookupState] = useState<UddannelsesstedLookupStateModel[]>([]);
    const [afdelingerLookupState, setAfdelingerLookupState] = useState<AfdelingLookupStateModel[]>([]);
    const [stillingerLookupState, setStillingerLookupState] = useState<StillingLookupStateModel[]>([]);
    const [rowCountState, setRowCountState] = useState<number>(1)
    const [filteredRecords, setFilteredRecords] = useState<number>(0);
    const [recordsTotal, setRecordsTotal] = useState<number>(0);
    const [missingIndexesState, setMissingIndexesState] = useState<number[]>([]);
    const [years, setYears] = useState(ForloebOverviewConstants.years as number[]);
    const [xOfYisLoading, setXOfYisLoading] = useState(true)

    const { isMobile } = useScreenResolution();

    useEffect(() => {
        const dateFilterInput = getDateFilterInput(forloebOverviewFilterSliceState);
        const inputModel = getScrollForloebDateChartInput(dateFilterInput, years[0], scrollContainerRef);

        if(!isLoading) {
            scrollToYearInForloebOverviewChart(inputModel);
        } else {
            resetScrollToYearInForloebOverviewChart(inputModel);
        }

    }, [isMobile, forloebOverviewFilterSliceState, isLoading, scrollContainerRef, years]);

    useEffect(() => {
        setIsLoading(true);
        setRowCountState(1);
        const filterClone = cloneFilterFromState(forloebOverviewFilterSliceState, overviewFilter);
        const fetchStillingerAsync = async () => {
            const result = await forloebApi.getLaegeinstitutionerWithStillingerAsyncWithCancellation(filterClone, filterParams);
            setUddannelsesstederWithStillinger(result.data);

            const startYear = filterClone.year;

            if (startYear !== 0) {
                const calculatedYears = await calculateAndSetYearsForStillinger(Number(startYear), result.data);
                setYears(calculatedYears);
            }

            setIsLoading(false);
        }
        fetchStillingerAsync();

        const fetchXofY = async () => {
            setXOfYisLoading(true);
            const XofY = await forloebApi.getXofYLaegeinstitutionerWithStillinger(filterClone, filterParams);
            setFilteredRecords(XofY.recordsFiltered);
            setRecordsTotal(XofY.recordsTotal);
        }
        fetchXofY().then(() => setXOfYisLoading(false));

        return () => {
            forloebApi.cancelPendingRequests();
        }
    }, [forloebOverviewFilterSliceState, overviewFilter, filterParams]);

    function overlappingDates(current: LaegeStillingModel, prev: LaegeStillingModel): boolean {
        return current.startDate <= prev.endDate && current.stillingId === prev.stillingId;
    }

    useEffect(() => {
        const getUddannelsesstederWithStillingerToRender = () => {
            let missingIndexes: number[] = [];

            let uddannelsesstederLookup: UddannelsesstedLookupStateModel[] = [];
            let afdelingerLookup: AfdelingLookupStateModel[] = [];
            let stillingerLookup: StillingLookupStateModel[] = [];

            let rowCount = offset; //offset på 2
            function setMissingIndexesAndReturnAntalOverlap(stilling: StillingForloebOverviewModel, index: number) {
                const antalOverlap = overlappingCount(stilling.laegeStillinger)
                if (antalOverlap > 0) {
                    for (let i = 1; i <= antalOverlap; i++) {
                        missingIndexes.push(index + i);
                    }
                }
                return antalOverlap;
            }

            uddannelsesstederWithStillinger.forEach((uddannelsesstedWithStillinger, index) => {
                ++rowCount;
                uddannelsesstederLookup.push({
                    uddannelsesstedId: uddannelsesstedWithStillinger.uddannelsesstedId,
                    uddannelsesstedNavn: uddannelsesstedWithStillinger.uddannelsesstedNavn,
                    rowIndex: rowCount,
                    collapsed: false
                });
                uddannelsesstedWithStillinger.afdelingerMedStillinger.forEach((afdelingStilling) => {
                    ++rowCount;
                    afdelingerLookup.push({
                        afdelingId: afdelingStilling.afdelingId,
                        afdelingNavn: afdelingStilling.afdelingNavn,
                        uddannelsesstedId: uddannelsesstedWithStillinger.uddannelsesstedId,
                        rowIndex: rowCount,
                        collapsed: false
                    });

                    afdelingStilling.stillinger.forEach((stilling) => {
                        ++rowCount;
                        stillingerLookup.push({
                            stillingModel: stilling,
                            rowIndex: rowCount,
                            uddannelsesstedId: uddannelsesstedWithStillinger.uddannelsesstedId,
                            afdelingId: afdelingStilling.afdelingId,
                            collapsed: false
                        });
                        if (overlappendeVisningState) {
                            rowCount += setMissingIndexesAndReturnAntalOverlap(stilling, rowCount);
                        }
                    });
                });

                uddannelsesstedWithStillinger.praksisStillinger.forEach((stilling) => {
                    ++rowCount;
                    stillingerLookup.push({
                        stillingModel: stilling,
                        rowIndex: rowCount,
                        uddannelsesstedId: uddannelsesstedWithStillinger.uddannelsesstedId,
                        collapsed: false
                    });
                    if (overlappendeVisningState) {
                        rowCount += setMissingIndexesAndReturnAntalOverlap(stilling, rowCount);
                    }
                })
            });

            setUddannelsesstederLookupState(uddannelsesstederLookup);
            setAfdelingerLookupState(afdelingerLookup);
            setStillingerLookupState(stillingerLookup);
            setMissingIndexesState(missingIndexes)
        }


        getUddannelsesstederWithStillingerToRender();
    }, [overlappendeVisningState, uddannelsesstederWithStillinger])

    const renderEmptyStillingBackground = () => {
        const rows: JSX.Element[] = [];
        for (let missingIndex of missingIndexesState) {
            rows.push(
                <div key={missingIndex} className={`stilling-header`}
                     style={{"--uddannelsessted-row-index": missingIndex} as React.CSSProperties}>
                </div>
            );
        }
        return rows;
    }

    useEffect(() => {

        let rowCount = (uddannelsesstederLookupState.length
            + afdelingerLookupState.length
            + stillingerLookupState.length) + 1; // Vi +'er 1, for at rendere hvid background

        if (overlappendeVisningState) {
            const praksisLaegeStillinger = uddannelsesstederWithStillinger.flatMap(x => x.praksisStillinger).flatMap(x => x.laegeStillinger);
            const afdelingLaegeStillinger = uddannelsesstederWithStillinger.flatMap(x => x.afdelingerMedStillinger).flatMap(x => x.stillinger).flatMap(x => x.laegeStillinger);
            const laegestillinger = praksisLaegeStillinger.concat(afdelingLaegeStillinger);

            const amountOfOverlappingForloeb = overlappingCount(laegestillinger);
            rowCount += amountOfOverlappingForloeb;
        }

        setRowCountState(rowCount)
    }, [afdelingerLookupState, overlappendeVisningState, stillingerLookupState, uddannelsesstederLookupState, uddannelsesstederWithStillinger])

    return (
        <ForloebOverviewcontainer>
            <div style={
                {
                    "--total-month-count": years.length * months.length,
                    "--month-count": months.length,
                    "--total-row-count": rowCountState,
                } as React.CSSProperties}>
                <div className="forloeb-chart" ref={scrollContainerRef}>
                    <div className="laege-header flex-column align-items-start">
                        <h5>{Localizer.global_stillinger()}</h5>
                        <div>{setPaginationCountCallback(filteredRecords, recordsTotal, xOfYisLoading)}</div>
                    </div>
                    {isLoading &&
                        <>
                            <div className={"forloeb-list-empty-state"}>
                                <Loading isLoading={isLoading} text={Localizer.forloebOverviewPage_getStillinger()}>
                                </Loading>
                            </div>
                            <HorizontalLineSpacer />
                            <PaginationForOverview
                                filterParams={filterParams}
                                maxRowIndex={rowCountState}
                                filteredRecords={filteredRecords}
                                handlePaginationCallback={(page: number) => handlePaginationCallback(page)}/>
                        </>
                    }

                    {!isLoading &&
                        <>
                            {uddannelsesstederWithStillinger.length > 0 ?
                                <Overview>
                                    <Overview.YearsHeader years={years}/>

                                    <Overview.VerticalLines
                                        years={years}
                                        rowSize={rowCountState}
                                        startDate={getFilterStartDate(getDateFilterInput(forloebOverviewFilterSliceState))}
                                        endDate={getFilterEndDate(getDateFilterInput(forloebOverviewFilterSliceState))}
                                        dateFilterType={forloebOverviewFilterSliceState.dateFilterType}
                                    />

                                    {overlappendeVisningState &&
                                        renderEmptyStillingBackground()
                                    }

                                    <Overview.HorizontalLines>
                                        {uddannelsesstederLookupState.length > 0 &&
                                            uddannelsesstederLookupState.map(uddannelsessted =>
                                                <UddannelsesstedView
                                                    key={`view-${uddannelsessted.rowIndex}-${uddannelsessted.uddannelsesstedId}`}
                                                    uddannelsesstedLookUp={uddannelsessted}
                                                    afdelinger={afdelingerLookupState.filter(a => a.uddannelsesstedId === uddannelsessted.uddannelsesstedId)}
                                                    stillinger={stillingerLookupState.filter(s => s.uddannelsesstedId === uddannelsessted.uddannelsesstedId)}
                                                    years={years}
                                                    visHorizontaleLinjerState={visHorizontaleLinjerState}
                                                />
                                            )
                                        }

                                        {stillingerLookupState.length > 0 && visHorizontaleLinjerState &&
                                            stillingerLookupState.filter(a => !a.collapsed).map((stilling) => {
                                                return <HorizontalLine
                                                    key={`stilling-${stilling.rowIndex}-${stilling.stillingModel.stillingId}`}
                                                    rowIndex={stilling.rowIndex}
                                                    columnStart={ForloebOverviewConstants.timelineColumnStart}
                                                    columnEnd={ForloebOverviewConstants.months.length * toDate(forloebOverviewFilterSliceState.periodTo).getFullYear()}/>
                                                }
                                            )
                                        }

                                        {afdelingerLookupState.length > 0 && visHorizontaleLinjerState &&
                                            afdelingerLookupState.map((afdeling) => {
                                                return <HorizontalLine
                                                    key={`afdeling-${afdeling.rowIndex}-${afdeling.afdelingId}`}
                                                    rowIndex={afdeling.rowIndex}
                                                    columnStart={ForloebOverviewConstants.timelineColumnStart}
                                                    columnEnd={ForloebOverviewConstants.months.length * toDate(forloebOverviewFilterSliceState.periodTo).getFullYear()}
                                                    rowColor={ForloebOverviewConstants.afdelingHorizontalLinesBackgroundColor}
                                                />
                                                }
                                            )
                                        }

                                        {uddannelsesstederLookupState.length > 0 && visHorizontaleLinjerState &&
                                            uddannelsesstederLookupState.map((uddannelsessted) => {
                                                return <HorizontalLine
                                                    key={`horizontalline-${uddannelsessted.rowIndex}-${uddannelsessted.uddannelsesstedId}`}
                                                    rowIndex={uddannelsessted.rowIndex}
                                                    columnStart={ForloebOverviewConstants.timelineColumnStart}
                                                    columnEnd={ForloebOverviewConstants.months.length * toDate(forloebOverviewFilterSliceState.periodTo).getFullYear()}
                                                    rowColor={ForloebOverviewConstants.uddannelsesstedHorizontalLinesBackgroundColor}
                                                />
                                                }
                                            )
                                        }


                                        {stillingerLookupState.length > 0 &&
                                            stillingerLookupState.filter(a => !a.collapsed).map((stilling) => {
                                                let rowindex = stilling.rowIndex;
                                                return (
                                                    stilling.stillingModel.laegeStillinger
                                                        .sort((a, b) => a.endDate > b.endDate ? 1 : -1)
                                                        .sort((a, b) => a.stillingId.localeCompare(b.stillingId))
                                                        .map((ls, index) => {
                                                            const anyOverlappingDates = index > 0 && overlappingDates(ls, stilling.stillingModel.laegeStillinger[index - 1]);
                                                            const rowindexCurrent =overlappendeVisningState
                                                                ? anyOverlappingDates ? ++rowindex : rowindex
                                                                : stilling.rowIndex
                                                            return (
                                                                <React.Fragment key={`${stilling.stillingModel.stillingId}-${ls.userId}-${ls.forloebId}`}>
                                                                    <LaegeStillingView
                                                                        rowIndex={rowindexCurrent}
                                                                        laegestilling={ls}
                                                                        collapsed={stilling.collapsed}
                                                                        years={years}
                                                                        handleForloebClicked={handleForloebClicked}
                                                                    />
                                                                    {visHorizontaleLinjerState &&
                                                                        <HoverLine
                                                                            columnStart={ForloebOverviewConstants.timelineColumnStart}
                                                                            columnEnd={ForloebOverviewConstants.months.length * toDate(forloebOverviewFilterSliceState.periodTo).getFullYear()}
                                                                            rowColor={ForloebOverviewConstants.uddannelsesstedHorizontalLinesBackgroundColor}
                                                                            rowIndex={rowindexCurrent}
                                                                        />
                                                                    }
                                                                </React.Fragment>
                                                            )
                                                        }
                                                )
                                            )}
                                            )
                                        }
                                    </Overview.HorizontalLines>
                                </Overview>
                                :
                                <>
                                    <HorizontalLineSpacer />
                                    <ForloebOverviewNoFilterResults />
                                </>
                                
                            }
                            <PaginationForOverview
                                filterParams={filterParams}
                                maxRowIndex={rowCountState}
                                filteredRecords={filteredRecords}
                                handlePaginationCallback={(page: number) => handlePaginationCallback(page)}/>
                        </>
                    }
                </div>
            </div>
        </ForloebOverviewcontainer>
    )
}
