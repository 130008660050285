
const BrevForloebMergeFields = {
    Forloeb_All: "[Forløbsliste Alle]",
    Forloeb_Hu: "[Forløbsliste Hoveduddannelse]",
    Forloeb_Intro: "[Forløbsliste Intro]",
    Forloeb_Kbu: "[Forløbsliste KBU]",
}

export type BrevForloebMergeFieldsType = typeof BrevForloebMergeFields[keyof typeof BrevForloebMergeFields];

const allBrevForloebMergeFieldArray: string[] = Object.keys(BrevForloebMergeFields).map((key) => Object.getOwnPropertyDescriptor(BrevForloebMergeFields, key)?.value).filter(x => !!x);

export default BrevForloebMergeFields;

export {
    allBrevForloebMergeFieldArray
}
