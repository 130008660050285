import React, {useState} from "react";
import {BeskaeftigelseModel} from "./beskaeftigelseModel";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {ActionIcon} from "../../../core/components/actionIcon/actionIcon";
import {SlideIn} from "../../../core/components/slideIn/slideIn";
import {AnsoegningEditBeskaeftigelse} from "../ansoegningEditBeskaeftigelse";
import { EmblaIcons } from "core/emblaIcons";
import { MenuLinkModel } from "core/layout/shared/MenuLinkProps";

export interface BeskaeftigelseCardProps {
    beskaeftigelse: BeskaeftigelseModel
    beskaeftigelseEditCallback: () => void;
}


export function BeskaeftigelseCard({beskaeftigelse, beskaeftigelseEditCallback}: BeskaeftigelseCardProps) {
    const beskaeftigelseModalTarget = "beskaeftigelse-modal";
    const [beskaeftigelseModalOpen, setBeskaeftigelseModalOpen] = useState(false);

    function editBeskaeftigelse() {
        setBeskaeftigelseModalOpen(true)
    }

    const renderBeskaeftigelseEdit = (model: BeskaeftigelseModel) => {
        return (
            <AnsoegningEditBeskaeftigelse modalId={beskaeftigelseModalTarget} model={model}/>
        )
    }

    const handleMaanedOrMaaneder = () => {
        if (beskaeftigelse.maaneder.toInt() === 1)
            return Localizer.beskaeftigelse_maaned().toLocaleLowerCase()
        else
            return Localizer.beskaeftigelse_maaneder().toLocaleLowerCase()
    }

    return <>
        <div className='notatCardContainer' key={beskaeftigelse.id}>
            <div className="card shadow-none">
                <div className="card-body d-flex flex-column">
                    <div className="flex-space">
                        <div className="flex-row">
                            <p className="font-weight-bold margin-bottom-0">{beskaeftigelse.stillingsbetegnelse} - {beskaeftigelse.ansaettelsessted}</p>
                            <p className="margin-bottom-0">{beskaeftigelse.stillingsnummer}</p>
                            <p className="margin-bottom-0">{beskaeftigelse.afdeling}</p>
                            {beskaeftigelse.startdato && beskaeftigelse.slutdato &&
                                <p className="margin-bottom-0">
                                    {beskaeftigelse.startdato.dateWithoutTimeFormat(true)} - {beskaeftigelse.slutdato.dateWithoutTimeFormat(true)} ({beskaeftigelse.maaneder} {handleMaanedOrMaaneder()})
                                </p>
                            }
                            {beskaeftigelse.nedsatTidAntalTimer &&
                                <p>{beskaeftigelse.nedsatTidAntalTimer}</p>
                            }
                        </div>
                        <div className="flex-row align-self-center">
                            <ActionIcon addtionalClassNames="flex-align-r" iconName={EmblaIcons.Edit} action={editBeskaeftigelse}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {beskaeftigelseModalOpen &&
        <SlideIn id={beskaeftigelseModalTarget}
                 title={Localizer.global_beskaeftigelse()}
                 bodyContent={renderBeskaeftigelseEdit(beskaeftigelse)}
                 defaultOpen={true}
                 actionOnCloseCallback={() => setBeskaeftigelseModalOpen(false)}
                 actionFinishedCallback={() => beskaeftigelseEditCallback()}
        />
        }
    </>;
}
