import {Localizer} from "../../../infrastructure/localization/localizer";

export enum NotatSynlighedEnum {
    Privat = 1,
    SynligForMig = 2,
    SynligForUdvalgte = 3,
    SynligForAlle = 4
}

export function GetNotatSynlighedTranslation(type?: NotatSynlighedEnum) {
    switch (type) {
        case NotatSynlighedEnum.Privat:
            return Localizer.notatPage_synligPrivate();
        case NotatSynlighedEnum.SynligForMig:
            return Localizer.notatPage_synligForMig();
        case NotatSynlighedEnum.SynligForAlle:
            return Localizer.notatPage_synligForAlle();
        case NotatSynlighedEnum.SynligForUdvalgte:
            return Localizer.notatPage_synligForUdvalgte();
        default:
            return "";
    }
}
