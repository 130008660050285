import React from 'react'
import {useAppSelector} from "../../../app/hooks";
import {kompetenceCardReducer, KompetenceCardState} from "../../kompetencerPage/cards/kompetenceCardSlice";

type ActiveAndSelectedKompetencePillsProps = {
    singlePoint?: boolean;
}

export function ActiveAndSelectedKompetenceList(props: ActiveAndSelectedKompetencePillsProps) {
    const kompetenceCardState = useAppSelector(kompetenceCardReducer) as KompetenceCardState;
    const anySelectedLaegekompetencerFromList = kompetenceCardState.selectedLaegekompetenceMetas?.length > 0;

    return <React.Fragment>
        {(anySelectedLaegekompetencerFromList && !props.singlePoint)
            ?
            kompetenceCardState.selectedLaegekompetenceMetas.map(k =>
                <p key={k.laegekompetenceId}><li className={"small text-truncate"}>{k.kompetenceNummer} {k.titel}</li></p>)
            :
            <p><li className={"small text-truncate"}>{kompetenceCardState.activeLaegekompetenceMeta.kompetenceNummer} {kompetenceCardState.activeLaegekompetenceMeta.titel}</li></p>
        }
    </React.Fragment>
}

