import {EmblaIcon} from '../emblaIcon/emblaIcon';
import {DownloadButton} from './downloadButton';
import {DeleteButton} from './deleteButton';
import React, {ReactNode, useEffect, useRef, useState} from "react";
import {BasicNotatModel, BuildNewNotat} from "../../sharedmodels/notat/basicNotatModel";
import {ReactFileUploader, ReactFileUploaderFile} from "../fileUpload/reactFileUploader";
import {NotatType} from "../../sharedmodels/notat/notatType";
import {FileUploader} from "../fileUpload/fileUploader";
import {FileArchiveEnum} from "../../sharedmodels/fileMetaData/fileArchiveEnum";
import {BuildExistingNotatCrudModel, BuildNewCrudNotatModel} from "../../sharedmodels/notat/notatCrudModel";
import {FileLocationEnum} from "../../sharedmodels/file/fileLocationEnum";
import {FileMetadata, getNotatTypeText} from "../../sharedmodels/fileMetaData/fileMetaData";
import {Table} from "../table/table";
import {DataTableHeader} from "../table/DataTableHeader";
import {Localizer} from "../../../infrastructure/localization/localizer";
import './fileMetadataList.scss';
import {NotatInfoModel} from 'core/sharedmodels/notat/notatInfoModel';
import FileMetadataListComponent from './fileMetadataListComponent';
import useUser from 'core/hooks/useUser';
import useLogbogApi from 'core/hooks/useLogbogApi';
import dayjs from "dayjs";
import {DayUnitType} from "../../../index";

export interface FileMetadataListProps {
    fileMetadatas: FileMetadata[];
    fileLocation: FileLocationEnum;

    uploaderTitle?: string;
    filesUpdatedCallback?: () => void;
    showDeleteButton?: boolean;
    withUploader?: boolean;
    uploadNotatType?: NotatType;
    showAsTableIdentifier?: string;
    showFileType?: boolean;
    hideFileIcon?: boolean;
    hideDate?: boolean;
    showTimeInDatetime?: boolean;
    hideCreatedBy?: boolean;
    withDownload?: boolean;
    showAsCards?: boolean;
    selectedRows?: string[];
    initialSelectedRows?: string[]
    columnDefaultOrder?: Array<(number | string)> | Array<Array<(number | string)>> | undefined;
    tableDisplayTitleHeaderAsChooseAll?: boolean;

    showCustomBadgeText?: (fileMetadataId: string) => string;

    // Used on notes/files that should be updated immediately in backend.
    notatInfo?: NotatInfoModel | NotatInfoModel[];
    notat?: BasicNotatModel | BasicNotatModel[] | null;
    deleteExistingNotatIfNoFiles?: boolean;

    // Used on notes/files that should only be updated in frontend.
    fileDeletedCallback?: (fileMetadata: FileMetadata) => void;
    fileDeletedCallbackOverride?: (fileMetadata: FileMetadata) => void;
    filesUploadedCallbackOverride?: (result: ReactFileUploaderFile[]) => void;
    fileSelectedCallback?: (ids: string[]) => void;
    editFileOnClick?: (fileMetadataId: string) => void;
    conditionalShowDeleteButton?: (fileMetadata: FileMetadata) => boolean;
    conditionalShowEditButton?: (fileMetadata: FileMetadata) => boolean;

    overrideFileTypes?: string[];
    hideBorder?: boolean;
    customButton?: ReactNode;
}

const sortFiles = (files: FileMetadata[]) => files.complexSortByThenBy(x => dayjs(x.createdAt).startOf(DayUnitType).toDate(), true, x => x.fileName, false);

/** FileMetadata List which displays files and optionally can upload/delete files 
 * 
 * As of now, is pretty hardcoded to work with upload/deletion of notat-files (`FileArchiveEnum.NoteAttachment`). 
 * Note: Will also work with email-attachments (via `fileLocation = Email`).
 * 
 * If wanting to use with other file-types, you can use without uploader (`withUploader = false`)
*/
export function FileMetadataList({
    notat,
    fileMetadatas,
    showAsTableIdentifier = "filemetadata-list",
    showAsCards = true,
    deleteExistingNotatIfNoFiles = true,
    tableDisplayTitleHeaderAsChooseAll = false,
    ...props
}: FileMetadataListProps) {

    const [fileMetadataState, setFileMetadataState] = useState<FileMetadata[]>(sortFiles(fileMetadatas));
    const notatRef = useRef<BasicNotatModel[] | null>(notat ? (notat as BasicNotatModel[]).length > 0 ? (notat as BasicNotatModel[]) : [(notat as BasicNotatModel)] : null); // TODO: Refactor this check for better readability
    const fileUploaderRef = useRef<ReactFileUploader>(null);

    const { currentUser } = useUser();
    const { notatApi } = useLogbogApi();

    useEffect(() => {
      notatRef.current = notat ? (notat as BasicNotatModel[]).length > 0 ? (notat as BasicNotatModel[]) : [(notat as BasicNotatModel)] : null; // TODO: Refactor this check for better readability
    }, [notat])

    const fileDeletedCallback = async (fileMetadata: FileMetadata) => {
        let updatedFileList = [...fileMetadataState];
        let deleteIndex = updatedFileList.findIndex(f => f.id === fileMetadata.id);

        updatedFileList.splice(deleteIndex, 1);
        setFileMetadataState(sortFiles(updatedFileList));

        fileUploaderRef.current?.removeUploadedFileWithFileName(fileMetadata.fileName);

        if (props.fileDeletedCallbackOverride) {
            props.fileDeletedCallbackOverride(fileMetadata);
        } else if (deleteExistingNotatIfNoFiles) {
            if (updatedFileList.length === 0 && notatRef.current && props.notatInfo && fileMetadata.notatType) {
                // All files has been removed from notat + notat exists and is not "TekstNotat" (notatType !== 0):
                const notatInfo = getNotatInfo(props.notatInfo, fileMetadata.notatType)
                const model = BuildExistingNotatCrudModel(getNotat(notatRef.current, fileMetadata.notatType), [notatInfo?.contextId]);
                await notatApi.deleteNotat(model);
                notatRef.current = null;
            }
        }

        props.fileDeletedCallback?.(fileMetadata);

        //Anvendes til tab-vindue eller wizard, hvor listen skal opdateres efter sideskift
        if (props.filesUpdatedCallback) {
            props.filesUpdatedCallback()
        }
    }

    const filesUploadedCallback = async (uploadedFiles: ReactFileUploaderFile[]) => {
        const newFileMetadatas = uploadedFiles.map(rfu =>{
            rfu.fileMetadata.createdAt = new Date();
            rfu.fileMetadata.createdByName = currentUser.Name;
            rfu.fileMetadata.notatType = props.uploadNotatType;
            return rfu.fileMetadata
        } );
        setFileMetadataState(arr => sortFiles([...arr, ...newFileMetadatas]));

        if (props.filesUploadedCallbackOverride) {
            props.filesUploadedCallbackOverride(uploadedFiles);
        } else if (props.uploadNotatType || props.notatInfo) {
            let newFileMetadataIds = newFileMetadatas.map(m => m.id);

            if (props.notatInfo) {
                const notatType = props.uploadNotatType ?? getUploadNotatType(props.notatInfo)
                const notatInfo = getNotatInfo(props.notatInfo, notatType)

                if (notatRef.current && getNotat(notatRef.current, notatType)) {
                    const model = BuildExistingNotatCrudModel(getNotat(notatRef.current, notatType), [notatInfo.contextId]);
                    await notatApi.editNotatFiles(model, newFileMetadataIds);
                } else {
                    let notat = BuildNewCrudNotatModel([notatInfo.contextId], notatInfo.notatType, notatInfo.userId, notatInfo.title, notatInfo.description, notatInfo.isPrivate, notatInfo.isVisibleToEveryone);
                    notat.id = await notatApi.createNotat(notat ,newFileMetadataIds);
                    notatRef.current = [BuildNewNotat(notat, newFileMetadatas)];
                }
            }
        }
        //Anvendes til tab-vindue eller wizard, hvor listen skal opdateres efter sideskift
        if (props.filesUpdatedCallback) {
            props.filesUpdatedCallback()
        }
    }

    const renderAsTable = () => {
        const renderTableBody = () => {
            return (
                <tbody>
                {
                    fileMetadataState.map((file, index) => {
                        return (
                            <tr key={file.id} data-toggle="modal" data-id={file.id} onClick={() => {
                            }}>
                                <td>
                                    <div className='d-flex flex-row align-baseline'>
                                        {!props.hideFileIcon &&
                                            <EmblaIcon iconName='image'/>
                                        }
                                        <p className='margin-left-s'>{file.fileName}</p>
                                    </div>
                                </td>
                                {props.showFileType &&
                                    <td> {file.notatType !== undefined ? getNotatTypeText(file.notatType) : ""}</td>
                                }
                                {!props.hideDate &&
                                    <td>
                                        {props.showTimeInDatetime
                                            ? file.createdAt?.dateWithTimeFormat()
                                            : file.createdAt?.dateWithoutTimeFormat(false)
                                        }
                                    </td>
                                }
                                {!props.hideCreatedBy &&
                                    <td>{file.createdByName}</td>
                                }

                                {props.withDownload &&
                                    <td>
                                        <DownloadButton fileMetadata={file} fileLocation={props.fileLocation}/>
                                    </td>
                                }
                                {props.showDeleteButton &&
                                    <td>
                                        <DeleteButton fileMetadata={file}
                                                    fileDeletedCallback={(file) => fileDeletedCallback(file)}
                                                    fileLocation={FileLocationEnum.Logbog}/>
                                    </td>
                                }
                            </tr>
                        );
                    })
                }
                </tbody>
            );
        }

        const columnHeaders = [
            new DataTableHeader(!tableDisplayTitleHeaderAsChooseAll ? Localizer.global_title() : Localizer.global_vaelgAlle(), true),
            ...props.showFileType ? [new DataTableHeader(Localizer.global_fileType(), true)] : [],
            ...!props.hideDate ? [new DataTableHeader(Localizer.global_dato(), true)] : [],
            ...!props.hideCreatedBy ? [new DataTableHeader(Localizer.global_tilfoejetAf(), true)] : [],
            ...props.withDownload ? [new DataTableHeader("", false)] : [],
            ...props.showDeleteButton ? [new DataTableHeader("", false)] : [],
        ]

        return <div className="files-table">
            {/* NOTE:
                The React.Fragment causes the entire table to be re-rendered whenever the filemetadata changes. This is not best practice and does not perform well.
                It is however necessary in cases where Actions are enabled as the checkbox will otherwise not be rendered whenever a file is added to the list (uploaded)
            */}
            <React.Fragment key={`filemetadata-table-${fileMetadataState.length}`}>
                <Table
                    tableIdentifier={`table-${showAsTableIdentifier}`}
                    columnHeaders={columnHeaders}
                    renderTableBody={() => renderTableBody()}
                    useSelectActions={!!props.fileSelectedCallback}
                    actionsSelectedTextSingle={Localizer.filValgt()}
                    actionsSelectedTextPlural={Localizer.filerValgt()}
                    showLengthChange={false}
                    showSearching={false}
                    showPaging={false}
                    hideHeaderBodies={true}
                    objectSelectedCallback={props.fileSelectedCallback}
                    initialSelectedRows={props.selectedRows ?? props.initialSelectedRows}
                    columnDefaultOrder={props.columnDefaultOrder}
                />
            </React.Fragment>

        </div>
    }

     // TODO: Refactor this check for better readability
    const getNotatInfo = (notatInfo: NotatInfoModel | NotatInfoModel[], notatType: NotatType) => {
        return (notatInfo as NotatInfoModel[]).length > 0
        ? (notatInfo as NotatInfoModel[]).find(n => n.notatType === notatType)!
        : (notatInfo as NotatInfoModel);
    }

    const getNotat = (notater: BasicNotatModel[], notatType: NotatType) => {
        return notater.find(n => n.type === notatType)!;
    }

    // Gets the first NotatType from the list of NotatInfoModels
    const getUploadNotatType = (notatInfo: NotatInfoModel | NotatInfoModel[]) => {
        return (notatInfo as NotatInfoModel[]).length > 0
        ? (notatInfo as NotatInfoModel[])[0].notatType
        : (notatInfo as NotatInfoModel).notatType
    }

    const onFileSelected = (fileId: string, checked: boolean) => {
        if(!!props.fileSelectedCallback) {
            const prevSelectedRowIds = props.selectedRows ?? props.initialSelectedRows;

            const updatedSelectedItems = checked
                ? [...prevSelectedRowIds ?? [], fileId]
                : [...prevSelectedRowIds?.filter(x => x !== fileId) ?? []];

            props.fileSelectedCallback(updatedSelectedItems)
        }
    }

    const render = (
        <>
            {props.withUploader && (props.uploadNotatType || props.notatInfo || props.filesUploadedCallbackOverride) &&
                <div className="margin-bottom-m">
                    {props.uploaderTitle &&
                    <h5 className="margin-top-l">
                        {props?.uploaderTitle}
                    </h5>
                    }

                    <FileUploader
                        ref={fileUploaderRef}
                        defaultUploadedFileMetadatas={fileMetadataState}
                        fileType={FileArchiveEnum.NoteAttachment} // FileMetadataList only uploads files which relates to "notater" right now
                        filesUploadedCallback={filesUploadedCallback}
                        fileLocation={props.fileLocation}
                        overrideFileTypes={props.overrideFileTypes}
                    />
                </div>
            }

            {fileMetadataState?.length > 0 &&
            <>
                {showAsCards &&
                    <FileMetadataListComponent
                        fileMetaDataList={fileMetadataState}
                        fileLocation={props.fileLocation}
                        withDownload={props.withDownload}
                        showDeleteButton={props.showDeleteButton}
                        hideCreatedBy={props.hideCreatedBy}
                        showTimeInDatetime={props.showTimeInDatetime}
                        hideDate={props.hideDate}
                        showCustomBadgeText={props.showCustomBadgeText}
                        fileDeletedCallback={fileDeletedCallback}
                        fileSelectedCallback={!!props.fileSelectedCallback ? onFileSelected : undefined}
                        showNotatFileType={props.showFileType}
                        selectedFileIds={props.selectedRows}
                        initialSelectedFileIds={props.initialSelectedRows}
                        hideBorder={props.hideBorder}
                        editFileOnClick={props.editFileOnClick}
                        conditionalShowDeleteButton={props.conditionalShowDeleteButton}
                        conditionalShowEditButton={props.conditionalShowEditButton}
                    />
                }

                {!showAsCards &&
                    renderAsTable()
                }
            </>
            }
        </>
    )

    return <>{render}</>
}
