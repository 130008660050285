import UserFilterPermissions from "core/sharedmodels/userPermissions/userFilterPermissions";
import {createContext, ReactNode, useCallback, useContext, useReducer} from "react";
import { FileData } from "services/api/stamdataMappe/FilesAndFoldersModel";

type FileContextType = {
    files: FileData[];
    isLoading: boolean;
    permissions: UserFilterPermissions;
}

const FileContext = createContext<{
    state: FileContextType,
    setFiles: (payload: FileData[]) => void,
    setIsLoading: (payload: boolean) => void,
    setPermissions: (payload: UserFilterPermissions) => void,
} | undefined>(undefined);

enum FilterReducerActionType {
    setFiles,
    SetIsLoading,
    SetPermissions,
}

const fileReducer = (
    state: FileContextType,
    action: {type: FilterReducerActionType, payload: Partial<FileContextType>}
): FileContextType => {
    switch(action.type) {
        case FilterReducerActionType.setFiles:
            return { ...state,
                files: action.payload.files ?? state.files
            }
        case FilterReducerActionType.SetIsLoading:
            return {
                ...state,
                isLoading: action.payload.isLoading ?? state.isLoading,
            }
        case FilterReducerActionType.SetPermissions:
            return {
                ...state,
                permissions: action.payload.permissions ?? state.permissions,
            }
    }
}

export const useFileContext = () => {
    const context = useContext(FileContext);
    if (!context) throw new Error("useFileContext must be used in FileContextProvider");
    return context;
};

type FileContextProviderProps = { children?: ReactNode; }

const FileContextProvider = ({ children }: FileContextProviderProps) => {

    const [state, dispatch] = useReducer(fileReducer, {
        files: [],
        isLoading: true,
        permissions: {
            adgangTilAlleForloeb: false,
            afdelingIds: [],
            omraadeIds: [],
            praksisIds: [],
            regionIds: [],
            sygehusIds: [],
            specialeIds: []
        },
    });


    const setFiles = useCallback((payload: FileData[]) => {
        dispatch({
            type: FilterReducerActionType.setFiles,
            payload: { files: payload }
        });
    }, []);

    const setIsLoading = useCallback((payload: boolean) => {
        dispatch({
            type: FilterReducerActionType.SetIsLoading,
            payload: { isLoading: payload }
        });
    }, []);

    const setPermissions = useCallback((payload: UserFilterPermissions) => {
        dispatch({
            type: FilterReducerActionType.SetPermissions,
            payload: { permissions: payload }
        });
    }, []);

    return (
        <FileContext.Provider
            value={{
                state,
                setFiles,
                setIsLoading,
                setPermissions,
            }}
        >
            {children}
        </FileContext.Provider>
    );
};

export default FileContextProvider;
