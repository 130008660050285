import React from 'react';
import {Localizer} from "../../../infrastructure/localization/localizer";
import {AccountService} from "../../../services/account/accountService";
import './publicTopMenu.scss';
import './topMenu.scss';
import {ReactComponent as Logo} from "../../../content/icons/logo-icon.svg";
import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../responsive";
import {useHistory} from "react-router-dom";
import {RoutePaths} from "../../../infrastructure/routes";
import config from "../../../config/config";

interface IPublicTopMenuProps {
    isAuthenticated: boolean;
}

export function PublicTopMenu(props: IPublicTopMenuProps) {
    const userService = new AccountService();
    const IsMobile = useMediaQuery(mobileMaxWidthQuery);
    const history = useHistory();
    const showLogin = config.showLogin;

    async function login() {
        if (props.isAuthenticated)
            await userService.Logout()
        else
            await userService.SignInRedirect();
    }

    function gaaTilEvalueringsstatistik() {
        history.push(RoutePaths.Evalueringsstatistik.url);
    }

    function goToFrontPage() {
        history.push(RoutePaths.Index.url);
    }

    return (
        <>
            <nav className="navbar navbar-default bg-primary fixed-top public-top-menu padding-0">
                <div className="container padding-0 max-container">
                    <div className="flex-container-row flex-align-c flex-align-l" onClick={() => goToFrontPage()} style={{cursor: "pointer"}}>
                        <Logo/>
                        {!IsMobile &&
                            <h5>
                                {Localizer.global_uddannelseslaegedk()}
                            </h5>
                        }
                    </div>
                    <div className="flex-container-row flex-align-c flex-align-r">
                        <h6 className="margin-right-m" role="button" onClick={() => gaaTilEvalueringsstatistik()}>
                            {Localizer.global_evalueringsstatistik()}
                        </h6>
                        {showLogin &&
                            <button className="btn btn-secondary rounded-pill" onClick={() => login()}>
                                {props.isAuthenticated ? Localizer.global_logout() : Localizer.global_login()}
                            </button>
                        }
                    </div>
                </div>
            </nav>
        </>
    );
}


