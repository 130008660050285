import { MenuLinkModel, brugereMenuLink, evalueringsstatistikMenuLink, klarmeldingerMenuLink, nyhederMenuLink, uddannelseslaegeOverviewMenuLink, vejlederNotaterMenuLink } from "core/layout/shared/MenuLinkProps";
import { DynamicMenu } from "../DynamicMenu";
import MenuProps from "./menuProps";

export function VejlederMenu({ showNotification, nyhederMenuLink }: MenuProps) {

    const menuLinks: Array<MenuLinkModel> = [
        uddannelseslaegeOverviewMenuLink,
        klarmeldingerMenuLink,
        vejlederNotaterMenuLink,
        brugereMenuLink,
        evalueringsstatistikMenuLink,
        nyhederMenuLink
    ];

    return (
        <DynamicMenu menuLinks={menuLinks} showNotifications={showNotification}/>
    );
}
