import { forloebOverviewMenuLink, evalueringsstatistikMenuLink, brugereMenuLink, nyhederMenuLink, fileAdministrationMenuLink } from "core/layout/shared/MenuLinkProps";
import { DynamicMenu } from "../DynamicMenu";
import MenuProps from "./menuProps";

export function KonsulentMenu({ showNotification, nyhederMenuLink }: MenuProps) {

    const menuLinks = [
        forloebOverviewMenuLink,
        evalueringsstatistikMenuLink,
        brugereMenuLink,
        nyhederMenuLink,
        fileAdministrationMenuLink
    ];

    return (
        <DynamicMenu menuLinks={menuLinks} showNotifications={showNotification}/>
    );

}