import {TableModule} from "ditmer-embla";
import {PrimaryModalFooterButtonType, tableActionsId, TableAsyncProps, tableFilterId, tableTitleId, tableWrapperId} from "./tableProps";
import {HttpClientService} from "../../../services/httpClient/httpClientService";
import useEffectAsync from "../../../infrastructure/effect";
import {ApiResponse} from "../../../services/api/apiResponse";
import {
    AsyncTableReducer,
    AsyncTableState,
    setSearch,
    setTableInitialized,
    setOrder
} from "../../../pages/bruger/asyncTableSlice";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../../app/hooks";
import {Dispatch, forwardRef, MutableRefObject, useEffect, useRef} from "react";
import {TableOutputInfo, UseUpdateTableOutputInfo} from "./useUpdateTableOutputInfo";
import { handleFiltersAppliedMarker } from "../filter/components/removeFiltrerButton";
import useForwardedRef from "core/hooks/useForwardedRef";
import { AccountService } from "services/account/accountService";
import { useHeaderActions } from "./useHeaderActions";
import { initTooltip } from "core/utils";
import { overflowPillTooltilClass } from "./tablePillOverflow";
import useScreenResolution from "core/hooks/useScreenResolution";

const recordOutputInfoId = "record-output-info";
const primaryModalFooterButtonId = 'primaryModalFooterButton';


const reloadTable = (tableModule: TableModule | undefined | null) => {
    tableModule?.getDatatablesApi().ajax.reload();
}

export const setFilterButtonClasses = (filterSelector: string) => {
    const filterBtnSelector = '.filter-modal-show';

    const $filterBtn = $(filterBtnSelector);
    if(!$filterBtn) return;

    isFilterApplied(filterSelector)
        ? $filterBtn.removeClass("btn-default").addClass("btn-primary")
        : $filterBtn.removeClass("btn-primary").addClass("btn-default")
}

export const isFilterApplied = (filterSelector: string) : boolean => {
    const selector = `${filterSelector} select`;
    let filterIsApplied = false;
    $(selector).each((index: number, select: HTMLElement) => {
        const value = $(select).val();
        const hasValues = !Array.isArray(value) || value.length !== 0;
        if(value && hasValues) {
            filterIsApplied = true;
            return;
        }
    });
    return filterIsApplied;
}

export const drawPrimaryModalFooterButton = (tableIdentifier: string, primaryModalFooterButton?: PrimaryModalFooterButtonType) => {
    if(!primaryModalFooterButton) return;

    if(!!document.getElementById(primaryModalFooterButtonId)) return;

    const btn = $('<button>')
                .text(primaryModalFooterButton.name)
                .addClass('btn btn-primary')
                .attr('id', primaryModalFooterButtonId)
                .on('click', () => primaryModalFooterButton!.onClick());

    $(`#${tableWrapperId(tableIdentifier)} .modal-footer`).append(btn);
}

const initTooltips = (isMobile: boolean) => $(`.${overflowPillTooltilClass}`).each((_, e) => initTooltip(`#${e.id}`, isMobile));

export const TableAsync = forwardRef<TableModule, TableAsyncProps>(({isResponsive = true, ...props }, ref) => {
    const httpClient = new HttpClientService();
    const dispatch = useDispatch();
    const asyncTableState = useAppSelector(AsyncTableReducer) as AsyncTableState;
    useHeaderActions(props.renderHeaderActions?.actions, asyncTableState.tableInitialized);

    const {isMobile} = useScreenResolution();

    const _tableModule = useForwardedRef<TableModule>(ref);
    const _filterData = useRef<any>();

    const filterSelector = `#${tableFilterId(props.tableIdentifier)}`;

    const { setOutputInfo } = UseUpdateTableOutputInfo(
        tableTitleId(props.tableIdentifier),
        recordOutputInfoId,
    );

    useEffectAsync(async () => {
        await initTable();
        setFilterButtonClasses(filterSelector);
    }, []);

    useEffect(() => {
        _filterData.current = asyncTableState.filter;

        handleFiltersAppliedMarker(
            isFilterApplied(filterSelector),
            () => reloadTable(_tableModule.current),
            filterSelector,
            props.clearFilterFuntion
        );
    }, [asyncTableState.filter])

    useEffect(() => {
        _tableModule.current?.getDatatablesApi().ajax.reload();
    }, [asyncTableState.pingReload]);

    const showLoadingInHeaderAction = (show: boolean, htmlElement: HTMLElement, hideContentOnLoad: boolean) => {
        const $htmlElement = $(htmlElement);
        const loadingDiv = $htmlElement.find(".loading-container");
        const otherContent = loadingDiv.parent().children(":not(.loading-container)");

        if (loadingDiv.length > 0 && show) {
            loadingDiv.prop("hidden", false);
            otherContent.prop("hidden", hideContentOnLoad);

            $(htmlElement).attr("disabled", "disabled");
        }

        if (loadingDiv.length > 0 && !show) {
            loadingDiv.prop("hidden", true);
            otherContent.prop("hidden", false);

            $(htmlElement).removeAttr("disabled");
        }
    }

    const afterRowCreation = (row: Node, response: any) => {
        const $row = $(row);

        if (isResponsive) {
            const expandArrowButtonElement = "<button class=\"expand-arrow hide-arrow\" tabindex=\"-1\" aria-label=\"Vis/skjul detaljer\" aria-expanded=\"false\"></button>";
            $row.children(".details-button").addClass("d-flex").prepend(expandArrowButtonElement);
        }

        if (props.rowClickFunction) {
            $row.addClass("clickable-row");

            $row.children("td").on("click", (event) => {
                const $target = $(event.target);
                if ($target.hasClass("expand-arrow") || $target.hasClass("stop-event")) return;

                props.rowClickFunction?.(response);
            });
        }
    }

    useEffect(() => {
        if(!props.primaryModalFooterButton) return;

        const $btn = $('#' + primaryModalFooterButtonId);
        if(!$btn) return;

        $btn.off('click').on('click', () => props.primaryModalFooterButton!.onClick());
    }, [props.primaryModalFooterButton]);


    const onDraw = () => {
        if (props.onDraw)
            props.onDraw();

        drawPrimaryModalFooterButton(props.tableIdentifier, props.primaryModalFooterButton);

        initTooltips(isMobile);
    };

    const onRowCallback = (row: Node, data: (any[] | object), index: number) => {
        if (props.rowCallback)
            props.rowCallback(row, data, index);
    }

    const initTable = async () => {
        dispatch(setTableInitialized(false));

        (_tableModule as MutableRefObject<TableModule>).current = new TableModule({
            tableSelector: `#${props.tableIdentifier}`,
            titleSelector: `#${tableTitleId(props.tableIdentifier)}`,
            filterSelector: filterSelector,
            responsive: isResponsive,
            additionalDatatableSettings: {
                rowCallback: onRowCallback,
                createdRow: afterRowCreation,
                drawCallback: onDraw,
                ajax: {
                    type: "POST",
                    contentType: "application/json",
                    url: props.asyncUrl,
                    processData: true,
                    data: (data: DataTables.AjaxDataRequest & IColumnOrderRequestModel, settings: DataTables.Settings & DataTables.SettingsLegacy) => {
                        let requestData = {
                            ...data
                        };

                        dispatch(setSearch(requestData.search.value));
                        if(_filterData.current) {
                            requestData = $.extend(true, {}, requestData, _filterData.current);
                        }

                        if (props.getCustomPostData) {
                            const filterPostData = props.getCustomPostData();
                            requestData = $.extend(true, {}, requestData, filterPostData);
                        }

                        addColumnNamesToDataTablesOrder(requestData, settings.nTable as HTMLElement, dispatch);
                        return JSON.stringify(requestData);
                    },
                    dataFilter(data: string, type: string): any {
                        let apiResponse = JSON.parse(data) as ApiResponse<any>;

                        if (apiResponse.apiResponseMessage) {
                            setOutputInfo(apiResponse.data);

                            if (props.filteredCountCallback)
                                props.filteredCountCallback(apiResponse.data);

                            return JSON.stringify(apiResponse.data);
                        }

                        try {
                            const dataObject = JSON.parse(data);

                            if (props.filteredCountCallback)
                                props.filteredCountCallback(apiResponse.data);

                            setOutputInfo(dataObject);
                        } catch (error) {
                            console.warn("TableAsync -> dataFilter() -> Couldn't parse data to object");
                        }

                        return data;
                    },
                    //https://medium.com/cloudnimble/async-token-requests-in-ajax-calls-b44ee7809b0b
                    beforeSend: (request: JQueryXHR, settings: JQueryAjaxSettings) => {
                        if (settings.headers != null && settings.headers.Authorization !== undefined) {
                            // Authorization-header was set in previous send-request => continue request
                            return;
                        }

                        // Abort request before it is sent (because we dont have a current access-token)
                        request.abort();

                        // Apply current access-token to Authorization-header (when ready) and send request (will re-call "onBeforeSend"):
                        new AccountService().getAccesToken().then((token) => {
                            const authorizationHeaderValue = `bearer ${token}`;
                            settings.headers = $.extend(settings.headers, {
                                "Authorization": authorizationHeaderValue
                            });
                            $.ajax(settings);

                        });
                    },
                    headers: await httpClient.GetPlainDefaultHeaders()
                },
                language: {
                    searchPlaceholder: props.searchPlaceholder ?? undefined,
                    "processing": '<div class="spinner margin-auto"><div class="spinner-primary"></div></div>',
                },
                stateSave: false, //doesnt work on async table right now
                columns: props.columns,
                order: props.initOrder ? [props.initOrder] : props.renderTableActions ? [[1, "asc"]] : [[0, "asc"]],
                serverSide: true,
                searchDelay: 500,
                processing: true,
                initComplete: () => {
                    dispatch(setTableInitialized(true));

                    if(props.initCompleteCallback) {
                        props.initCompleteCallback();
                    }
                },
            },
            lengthChange: true,
            paging: true,
            filtersAsModal: {
                filtersInModal: props.filtersInModal ?? "never" //NOTE PKM: "auto" setting virker ikke pt. med async tabeller, da dropdownen skal geninitialiseres. Det kan godt fixes, men er nedprioriteret pt.
            }
        });

        return _tableModule;
    }

    return (
        <div className="datatable-pretty-init">
            <div id={tableTitleId(props.tableIdentifier)} className="child-pretty-init flex-space">
                <div className="d-flex flex-column">
                    <h3>
                        {props.tableHeader}
                    </h3>
                    {props.showFilteredRecordsInfo && <div id={recordOutputInfoId} className="subtle small"></div>}
                </div>

                {props.renderHeaderActions &&
                    <>
                        {props.renderHeaderActions.renderMethod()}
                    </>
                }
            </div>

            {props.renderTableFilters &&
                <div id={tableFilterId(props.tableIdentifier)} className="child-pretty-init">
                    {props.renderTableFilters()}
                    <div id={recordOutputInfoId} className="subtle small"></div>
                </div>
            }

            {props.renderTableActions &&
                <div id={tableActionsId(props.tableIdentifier)} className="child-pretty-init">
                    {props.renderTableActions()}
                </div>
            }

            <table className="table" id={props.tableIdentifier}>
                {props.renderTableHead()}
                <tbody>
                {/*populated async*/}
                </tbody>
            </table>
        </div>
    );
});

export interface IColumnOrderRequestModel {
    order: {
        column: number;
        dir: string;
        columnName: string
    }[]
}


export const addColumnNamesToDataTablesOrder = (d: IColumnOrderRequestModel, table: HTMLElement, dispatch: Dispatch<any>): void => {
    const columnNames = getColumnNames(table);

    if (d.order && d.order.length > 0) {
        for (const columnWithOrder of d.order) {
            const columnWithName = columnNames.filter((c) => c.order === columnWithOrder.column)[0];
            columnWithOrder.columnName = columnWithName.name;
        }
        dispatch(setOrder(JSON.stringify(d.order)));
    }
};

export const getColumnNames = (table: HTMLElement) => {
    const $table = $(table);

    const $tableHeaders = $table.find("thead > tr > th");

    const columns: { order: number; name: string }[] = [];

    let order = 0;
    for (const tableHeader of $tableHeaders.toArray()) {
        const $tableHeader = $(tableHeader);

        let columnName = $tableHeader.data("column-name");
        if (columnName === undefined) {
            columnName = $tableHeader.text();
        }

        columns.push({
            order,
            name: columnName
        });

        order++;
    }

    return columns;
};
