import React, {useEffect, useState} from "react";
import {Pagination} from "../../../core/components/pagination/pagination";
import {PaginationModel} from "../../../core/sharedmodels/filter/PaginationModel";
import {EvalueringsstatistikPageConstants} from "./EvalueringsstatistikPageConstants";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {Dropdown, DropdownOption} from "../../../core/components/dropdown/dropdown";
import {SingleValue} from "react-select";
import NumberArrayDropdownOptions from "../../../core/components/dropdown/dropdownOptions/numberArrayDropdownOptions";

interface PaginationListComponentProps {
   totalCount: number;
   currentPage: number;
   pageSize: number;
   arrayItemsChangedCallback: (page: number, pageLength: number) => void;
   bodyContent : any;
}

export function PaginationListComponent({ arrayItemsChangedCallback, ...props }: PaginationListComponentProps) {
    const [filter, setFilter] = useState(new PaginationModel(1, props.pageSize, props.totalCount));
    const [pageSize, setPageSize] = useState<number>(EvalueringsstatistikPageConstants.pageSizeSelectOptions[0]);
    const [pageSizeSelectOptions] = useState<DropdownOption<number>[]>(NumberArrayDropdownOptions(EvalueringsstatistikPageConstants.pageSizeSelectOptions));

    useEffect(() => {
        setFilter((prevFilter) => ({ ...prevFilter, recordCount: props.totalCount, pageLength: pageSize }))
        arrayItemsChangedCallback(1, pageSize);
    }, [arrayItemsChangedCallback, pageSize, props.totalCount])

    const handlePagination = async (page: number) => {
        setFilter((prevFilter) => ({ ...prevFilter, page: page }))
        arrayItemsChangedCallback(page, pageSize);
    };

    const handlePageAmountChange = (newValue: SingleValue<DropdownOption<number>>) => {
        if (newValue)
            setPageSize(newValue.value);
    }
    return (
        <div className="margin-bottom-m">
            <div className={"flex-container align-items-center margin-left-m margin-bottom-m"}>
                <h5 >{Localizer.uddannelsessteder()}</h5>
                <div className={'margin-left-s'}>
                    <Dropdown
                        placeholder={""}
                        options={pageSizeSelectOptions}
                        value={pageSizeSelectOptions.filter(x => x.value === pageSize)}
                        onChange={handlePageAmountChange}
                        isClearable={false}/>
                </div>
            </div>
            <div>
                {props.bodyContent}
            </div>
            <div className={"flex-row-center margin-top-m"}>
                <Pagination handlePagination={handlePagination}
                            paginationModel={new PaginationModel(filter.page, filter.pageLength, filter.recordCount)}/>
            </div>
        </div>
    )
}
