import { ModalAcceptType } from "core/components/modalMessage/modalSubmitMessage";
import { DynamicValidationFormSubmitButton } from "core/components/validation/components/validationFormButton";
import { EmblaIcons } from "core/emblaIcons";
import { Localizer } from "infrastructure/localization/localizer";
import { VejlederCrudFormId } from "./vejlederCrud";

const VejlederCrudFooter = () => {
    return (
        <div className='d-flex'>

            <DynamicValidationFormSubmitButton
                formId={VejlederCrudFormId}
                iconName={EmblaIcons.Plus}
                text={Localizer.vejlederPage_createVejleder()}
                buttonType={ModalAcceptType.primary}
            />

        </div>
    );
}

export default Object.assign(VejlederCrudFooter);