import {Localizer} from "../../../infrastructure/localization/localizer";
import {useEffect, useState} from "react";
import {useRouteMatch} from "react-router-dom";
import PdfComponent from "core/components/pdf/pdfComponent";
import {useFetchWithErrorHandlingPdf} from "./useFetchWithErrorHandlingPdf";
import useLogbogApi from "../../hooks/useLogbogApi";
import {UddannelsesplanModel} from "../../../pages/vejledning/uddannelsesplan/uddannelsesplanModel";
import {PdfKompetenceHeaderComponent} from "./pdfHeaders/pdfKompetenceHeaderComponent";
import PdfRow from "./pdfRow";
import PdfHeaderRow from "./pdfHeaderRow";
import { setDocumentPdfAsReady } from "./helpers/pdfHelper";
import { NotatType } from "core/sharedmodels/notat/notatType";
import NoteTextViewer from "core/componentsPage/notat/tekstNotat/noteTextViewer";
import {dateTimeStringFromApiToDate} from "../../../index";
import HtmlHeading from "../titels/HtmlHeadings";

type UddannelsesplanerPdfParams = { brugerId: string, brugerMaalbeskrivelseId: string, forloebId: string, stillingId: string };

export const UddannelsesplanerPdf = () => {
    const { forloebApi, uddannelsesplanApi } = useLogbogApi();

    const [uddannelsesplaner, setUddannelsesplaner] = useState<UddannelsesplanModel[]>()

    const {brugerId, brugerMaalbeskrivelseId, forloebId, stillingId} = useRouteMatch<UddannelsesplanerPdfParams>().params;
    const setError = useFetchWithErrorHandlingPdf();

    useEffect( () => {
        const fetchUddannelsesplaner = async () => await uddannelsesplanApi.getUddannelsesplanerByForloeb(forloebId);

        fetchUddannelsesplaner()
            .then(data => setUddannelsesplaner(data))
            .catch(err => setError(err))
            .finally(() => setDocumentPdfAsReady());
    }, [forloebApi, uddannelsesplanApi, setError, brugerMaalbeskrivelseId, forloebId])

    return (
        <PdfComponent>
            <PdfComponent.Title title={Localizer.global_uddannelsesplaner()} heading={HtmlHeading.H3}/>

            <PdfKompetenceHeaderComponent
                brugerId={brugerId}
                brugerMaalbeskrivelseId={brugerMaalbeskrivelseId}
                stillingInfo={{
                    stillingId: stillingId,
                    forloebId: forloebId
                }}
            />

            <PdfComponent.BlockItem className={"card shadow-none"}>
                <PdfComponent.CardHeader>
                    <PdfHeaderRow asFragment>
                        <PdfHeaderRow.Col>{Localizer.global_title()}</PdfHeaderRow.Col>
                        <PdfHeaderRow.Col width={5}>{Localizer.global_description()}</PdfHeaderRow.Col>
                        <PdfHeaderRow.Col>{Localizer.global_klarmeldtden()}</PdfHeaderRow.Col>
                        <PdfHeaderRow.Col width={3}>{Localizer.global_godkendt()}</PdfHeaderRow.Col>
                    </PdfHeaderRow>
                </PdfComponent.CardHeader>
                {uddannelsesplaner &&
                    uddannelsesplaner.map((u, i) =>
                        <PdfComponent.NonBreakableItem>
                            <PdfComponent.CardBody>
                                <PdfRow className="flex-align-c">
                                    <PdfRow.Col>{u.titel}</PdfRow.Col>
                                    <PdfRow.Col width={5}>
                                        <NoteTextViewer
                                            id={"vejledersamtale-" + i}
                                            text={u.beskrivelse}
                                            type={NotatType.Uddannelsesplan}
                                        />
                                    </PdfRow.Col>
                                    <PdfRow.Col>{u.klarmeldtAt ? dateTimeStringFromApiToDate(u.klarmeldtAt).dateWithoutTimeFormat(true) : ""}</PdfRow.Col>
                                    <PdfRow.Col width={3}>
                                        {u.godkendelseTilkendegivetDato &&
                                            `${u.godkendelseTilkendegivetAf} d.${u.godkendelseTilkendegivetDato.dateWithoutTimeFormat(true)}`
                                        }
                                    </PdfRow.Col>
                                </PdfRow>
                            </PdfComponent.CardBody>
                        </PdfComponent.NonBreakableItem>
                    )
                }
            </PdfComponent.BlockItem>
        </PdfComponent>
    );
}
