import {TableAsync} from "../../core/components/table/tableAsync";
import {Localizer} from "../../infrastructure/localization/localizer";
import {nameof} from "ts-simple-nameof";
import {HttpClientService} from "../../services/httpClient/httpClientService";
import {AccountService} from "../../services/account/accountService";
import ReactDOMServer from "react-dom/server";
import {UserProfileAvatar, UserProfileAvatarSizeEnum} from "../../core/components/userProfileAvatar/userProfileAvatar";
import {UserProfileAvatarModel} from "../../core/components/userProfileAvatar/userProfileAvatarModel";
import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../../core/layout/responsive";
import {RoutePaths} from "../../infrastructure/routes";
import {useHistory} from "react-router-dom";
import {dateWithoutTimeFormat} from "../../index";
import {setPingReload} from "../bruger/asyncTableSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {forloebReducer, ForloebSliceState} from "../../core/forloebSlice";
import React from "react";
import {LogbogUddannelsesplanApi} from "../../services/api/logbogUddannelsesplan/logbogUddannelsesplanApi";
import {
    LogbogKlarmeldtUddannelsesplanResponseModel
} from "../../services/api/logbogUddannelsesplan/LogbogKlarmeldtUddannelsesplanResponseModel";
import {NotificationModule} from "ditmer-embla";

const uddannelsesplanApi = new LogbogUddannelsesplanApi(new HttpClientService(), new AccountService())

export const UddannelsesplanerKlarmeldt = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const tableId = "vejledersamtalerKlarmeldt";
    const isMobile = useMediaQuery(mobileMaxWidthQuery);
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    const getUserAvatarString = (model: LogbogKlarmeldtUddannelsesplanResponseModel, isMobile: boolean) : string => {
        return ReactDOMServer.renderToString((
            <div className="d-flex">
                { !isMobile &&
                    <UserProfileAvatar
                        userProfileAvatarModel={new UserProfileAvatarModel(model.uddannelseslaegeNavn, model.uddannelseslaegeInitialer, true)}
                        size={UserProfileAvatarSizeEnum.extraSmall}
                    />
                }
                <div className="margin-left-s">
                    {model.uddannelseslaegeNavn}
                </div>
            </div>
        ));
    }

    const renderTableHeaders = () => {
        return (
            <thead>
            <tr>
                <th data-column-name={nameof<LogbogKlarmeldtUddannelsesplanResponseModel>(x => x.uddannelseslaegeNavn)}
                    data-priority="1">
                    {Localizer.global_uddannelseslaege()}
                </th>
                <th data-column-name={nameof<LogbogKlarmeldtUddannelsesplanResponseModel>(x => x.titel)}
                    data-priority="2">
                    {Localizer.global_title()}
                </th>
                <th data-column-name={nameof<LogbogKlarmeldtUddannelsesplanResponseModel>(x => x.dato)}
                    data-priority="3">
                    {Localizer.global_dato()}
                </th>
                <th data-column-name={nameof<LogbogKlarmeldtUddannelsesplanResponseModel>(x => x.id)}
                    data-priority="1">
                </th>
            </tr>
            </thead>
        );
    }

    const setupDelagatedOnClickGodkendTid = (cell: Node, model: LogbogKlarmeldtUddannelsesplanResponseModel) => {
        $(cell).on('click', async (event) => {
            event.stopPropagation();

            const uddannelsessted = forloebSliceState.forloebState.afdelingName === null
                ? forloebSliceState.forloebState.laegeInstitutionName
                : forloebSliceState.forloebState.laegeInstitutionName + ", " + forloebSliceState.forloebState.afdelingName

            const godkendtUddannelsesplan = await uddannelsesplanApi.godkendUddannelsesplan(model.id, uddannelsessted);

            if (godkendtUddannelsesplan) {
                dispatch(setPingReload())
                NotificationModule.showSuccess(Localizer.vejledningPage_uddannelsesplanEnighedTilkendegivet(), "");
            }
        });
    }

    const getGodkendTidBtnString = () : string => {
        return ReactDOMServer.renderToString((
            <button type="button"
                    className={"btn btn-success btn-round stop-event "}
                    aria-label="Primary"
            >
                {Localizer.global_agree()}
            </button>
        ));
    }

    const getTableColums = (): DataTables.ColumnSettings[] => {
        let columns: DataTables.ColumnSettings[] = [];
        columns = columns.concat([
            {
                data: (data: LogbogKlarmeldtUddannelsesplanResponseModel) => data,
                orderable: true,
                render: (data: LogbogKlarmeldtUddannelsesplanResponseModel) => {
                    return getUserAvatarString(data, isMobile);
                },
            },
            {
                data: (data: LogbogKlarmeldtUddannelsesplanResponseModel) => data.titel,
                orderable: true,
            },
            {
                data: (data: LogbogKlarmeldtUddannelsesplanResponseModel) => data,
                render: (data: LogbogKlarmeldtUddannelsesplanResponseModel) => {
                    return dateWithoutTimeFormat(data.dato);
                },
                orderable: true,
            },
            {
                data: (data: LogbogKlarmeldtUddannelsesplanResponseModel) => data,
                render: () => {
                    return getGodkendTidBtnString();
                },
                orderable: false,
                createdCell: (cell: Node, cellData: LogbogKlarmeldtUddannelsesplanResponseModel, rowData: any, row: number, col: number) => {
                    setupDelagatedOnClickGodkendTid(cell, cellData);
                },
            }
        ]);

        return columns;
    };

    const rowClickAction = (data: LogbogKlarmeldtUddannelsesplanResponseModel) => {
        history.push(RoutePaths.Vejledning({
            uddannelseslaege: data.uddannelseslaegeId
        }).url)
    }

    return (
        <>
            <TableAsync
                tableIdentifier={tableId}
                tableHeader={Localizer.global_uddannelsesplaner()}
                renderTableHead={renderTableHeaders}
                asyncUrl={uddannelsesplanApi.getKlarmeldteUddannelsesplanerUrl()}
                columns={getTableColums()}
                rowClickFunction={rowClickAction}
            />
        </>
    );
}
