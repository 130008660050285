import { ActionIcon } from "core/components/actionIcon/actionIcon";
import CounterCircle from "core/components/notifications/counterCircle/counterCircle";
import { TextEditor } from "core/components/textEditor/textEditor";
import { NyhedModel } from "core/sharedmodels/nyheder/nyhedModel";

type NyhedCardProps = {
  nyhed: NyhedModel;
  showEditButton?: boolean;
  onEditButtonClicked?: (nyhed: NyhedModel) => void;
  hasNotification?: boolean;
}

const NyhedCard = ({ 
  nyhed, 
  showEditButton = false, 
  onEditButtonClicked,
  hasNotification = false
}: NyhedCardProps) => {
  
  return (
    <div key={nyhed.id} className={"card"}>
        <div className={"card-header flex-container-row flex-space"}>
            <div className={"card-title flex-container-column"}>
                <div className="d-flex align-items-center">
                  <h4>{nyhed.titel}</h4>
                  {hasNotification && <CounterCircle size="extra-small" color="color-primary" additionalClasses="margin-left-s" />}
                </div>
                <h5 className={"subtle"}>{new Date(nyhed.publiceringsdato).dateWithoutTimeFormat(false)}</h5>
            </div>
            {(showEditButton && !!onEditButtonClicked) &&
                <div className={"card-actions"}>
                    <ActionIcon iconName={"edit"} action={() => onEditButtonClicked(nyhed)}/>
                </div>
            }
        </div>
        <div className={"card-body"}>
            <TextEditor isViewer={true} markdown={nyhed.markdown} id={nyhed.id} />
        </div>
    </div>
  )
}

export default NyhedCard;
