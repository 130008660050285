import DropdownPermissions from "core/components/dropdown/dropdownPermissions/DropdownPermissions";

type UserFilterPermissions = {
    adgangTilAlleForloeb: boolean;
    afdelingIds: Array<string>,
    omraadeIds: Array<string>,
    praksisIds: Array<string>,
    regionIds: Array<string>,
    sygehusIds: Array<string>,
    specialeIds: Array<string>,
}

export default UserFilterPermissions;

export const mapUserPermissionsToDropdownPermissions = (permissions: UserFilterPermissions, idArraySelector: (permissions: UserFilterPermissions) => Array<string>): DropdownPermissions => {
    return {
        ids: new Set(idArraySelector(permissions) ?? []),
        accessToEverything: permissions.adgangTilAlleForloeb,
    };
}