import classNames from "classnames";
import { ReactNode } from "react";

export type ColProps = {
    className?: string,
    width?: number,
    children: ReactNode,
    bold?:boolean
};

export const MaxColWidth = 12;

const Col = ({ className, width=2, children, bold=false } : ColProps ) => {
    return ( 
        <div className={classNames(`col-${width > MaxColWidth ? MaxColWidth : width}`, bold && "font-weight-bolder", className)}>
            {children}
        </div>
    )
}

export default Col;