import Tooltip from "../tooltips/Tooltip";
import classNames from "classnames";

type TablePillOverflowProps = {
    numberOfRecords: string;
    records: string;
}

export const overflowPillTooltilClass = "overflowed-pill-tooltip";

export const TablePillOverflow = ({numberOfRecords, records}: TablePillOverflowProps) => {

    return (
        <div className="badge badge-pill badge-primary-light">
            <Tooltip className={classNames("text-truncate", overflowPillTooltilClass)} title={records}>
                {"+" + numberOfRecords}
            </Tooltip>
        </div>
    );
}
