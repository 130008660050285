import { AccountService } from "services/account/accountService";
import { HubConnectionManager } from "./hubConnectionManager";

export enum ExtractDraftExportHubEventType {
  ExtractDraftExportEvent = "ExtractDraftExportEvent",
}

export default class NotificationHubConnectionManager extends HubConnectionManager {
  endpointName: string;
  userService: AccountService;

  constructor(endpointName: string,
    userService: AccountService) {
    super();

    this.endpointName = endpointName;
    this.userService = userService;
  }
}
