

// The order and naming matches the one in the email mergefields in stamdata.

const BrevTekstMergeFields = {
    UserName: "[UserName]",
    UserEmail: "[UserEmail]",
    Addresse: "[Addresse]",
    Cpr: "[Cpr]",
    DagsDato: "[DagsDato]",
    FuldeNavn: "[FuldeNavn]",
    TelefonNr: "[TelefonNr]",
    DitNavn: "[DitNavn]",
    ArbejdsMail: "[ArbejdsMail]",
    PrivatMail: "[PrivatMail]",
    SidsteForloeb: "[SidsteForloeb]",
    SenderEmail: "[SenderEmail]",
}

export type BrevTekstMergeFieldsType = typeof BrevTekstMergeFields[keyof typeof BrevTekstMergeFields];

const allBrevTekstMergeFieldArray: string[] = Object.keys(BrevTekstMergeFields).map((key) => Object.getOwnPropertyDescriptor(BrevTekstMergeFields, key)?.value).filter(x => !!x);

export default BrevTekstMergeFields;

export {
    allBrevTekstMergeFieldArray
}
