import EducationFilter from "core/components/filter/components/educationFilter";
import { Loading } from "core/components/loading/loading";
import useUserFilterPermissions from "core/hooks/useUserFilterPermissions";
import { useEffect, useState } from "react";

const VejlederBySpecialeFilterDropdown = ({onChange, restrictedSpecialeIds, disable=false} : { onChange: (specialer: string[]) => void, restrictedSpecialeIds: string[], disable?: boolean }) => {

    const { permissions, isLoadingPermissions } = useUserFilterPermissions();

    const [restrictedIds, setRestrictedIds] = useState<string[]>([]);

    useEffect(() => setRestrictedIds(restrictedSpecialeIds), [restrictedSpecialeIds]);

    return (
        <Loading isLoading={isLoadingPermissions}>
            <EducationFilter 
                onChange={(result) => onChange(result.specialer)} 
                visualizationConfig={{ showSpecialer: true }}
                restrictModel={{ specialer: restrictedIds, uddannelser: [] }}  
                permissions={permissions}  
                alwaysFilter
                disable={disable}
            />
        </Loading>
    );
};

export default VejlederBySpecialeFilterDropdown;
