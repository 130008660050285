import {ReactComponent as YellowCircle} from "../../../content/icons/yellowCircle.svg";
import {ReactComponent as GreenCircle} from "../../../content/icons/greenCircle.svg";
import {
    addSelectedLaegekompetenceMeta,
    kompetenceCardReducer,
    KompetenceCardState,
    removeSelectedLaegekompetenceMeta,
    setActiveLaegekompetenceMeta,
    toggleLaegekompetenceListRefreshPing
} from "./kompetenceCardSlice";
import {LaegekompetenceStatusEnum} from "../../../core/sharedmodels/kompetence/laegekompetenceStatusEnum";
import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../../app/hooks";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {LaegekompetenceMeta} from "../../../core/sharedmodels/kompetence/laegekompetenceMeta";
import {EmblaIcon} from "../../../core/components/emblaIcon/emblaIcon";
import {useDispatch} from "react-redux";
import {KompetenceCardListType, KompetenceCatagory} from "./kompetenceCardList";
import {
    UserProfileAvatar,
    UserProfileAvatarSizeEnum
} from "../../../core/components/userProfileAvatar/userProfileAvatar";
import "./kompetenceCard.scss"
import {Spinner} from "../../../core/components/spinner/spinner";
import {UserProfileAvatarModel} from "../../../core/components/userProfileAvatar/userProfileAvatarModel";
import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../../../core/layout/responsive";
import {useHistory} from "react-router-dom";
import {RoutePaths} from "../../../infrastructure/routes";
import useScrollTo from "core/hooks/useScrollTo";
import { kvTabIcon, NotatTabIcon, SkemaTabIcon } from "../details/kompetenceOverview";
import useUser from "core/hooks/useUser";
import useLogbogApi from "core/hooks/useLogbogApi";

export type KompetenceCardProps = {
    laegekompetence: LaegekompetenceMeta;
    kompetenceListType: KompetenceCardListType
    kompetenceCategory: KompetenceCatagory
    onFavoriteToggleCallback?: (laegekompetence: LaegekompetenceMeta) => void;
    index?: string;
    showSpinner?: boolean;
    regionId:string;
    amountFiles?: number;
}

export function KompetenceCard({amountFiles=0, ...props}: KompetenceCardProps) {
    const { currentUser } = useUser()
    const IsMobile = useMediaQuery(mobileMaxWidthQuery);
    const history = useHistory();

    const { logbogLaegeKompetenceApi } = useLogbogApi();

    const dispatch = useDispatch();
    const kompetenceCardState = useAppSelector(kompetenceCardReducer) as KompetenceCardState;

    const [showCheckboxState, setShowcheckboxState] = useState<boolean>(false);
    const [isActive, setIsActive] = useState<boolean>(false);
    const [isFavoriteState, setIsFavoriteState] = useState<boolean>(props.laegekompetence.isFavorite);
    const [checkboxCheckedState, setCheckboxCheckedState] = useState<boolean>(false);

    const { scrolltoRef } = useScrollTo<HTMLDivElement>(isActive && kompetenceCardState.scrollToActiveKompetence);

    useEffect(() => {
        const showCheckbox = kompetenceCardState.showCheckboxOnIds.filter(id => id === props.laegekompetence.laegekompetenceId).length > 0;
        setShowcheckboxState(showCheckbox);
    }, [kompetenceCardState.showCheckboxOnIds, props.laegekompetence.laegekompetenceId, props.laegekompetence.status])

    useEffect(() => {
        if (kompetenceCardState?.activeLaegekompetenceMeta?.laegekompetenceId === props.laegekompetence.laegekompetenceId) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
    }, [kompetenceCardState?.activeLaegekompetenceMeta?.laegekompetenceId, props.laegekompetence.laegekompetenceId])

    useEffect(() => {
        let thisCardIsChecked = kompetenceCardState.selectedLaegekompetenceMetas.findIndex(lk => lk.laegekompetenceId === props.laegekompetence.laegekompetenceId) > -1;
        setCheckboxCheckedState(thisCardIsChecked);
    }, [kompetenceCardState.selectedLaegekompetenceMetas, props.laegekompetence.laegekompetenceId])

    const cardClicked = (event: React.MouseEvent<HTMLDivElement>) => {
        if (showCheckboxState) {
            if (checkboxCheckedState) {
                dispatch(removeSelectedLaegekompetenceMeta(props.laegekompetence.ToState()))
            } else {
                dispatch(addSelectedLaegekompetenceMeta(props.laegekompetence.ToState()))
            }

            let newState = !checkboxCheckedState;
            setCheckboxCheckedState(newState);

        } else {

            if (IsMobile && props.laegekompetence.kompetenceId) {
                let routeParameters = [props.laegekompetence.userId, props.laegekompetence.laegekompetenceId, "true"];
                history.push(RoutePaths.KompetencerOverview(routeParameters).url);
            } else {

                dispatch(setActiveLaegekompetenceMeta(props.laegekompetence.ToState()));

                // Resets location-state (if there is any) - without refreshing:
                history.location.state = undefined;
            }
        }
    }

    let isPatching = false;
    const setIsFavorite = async (isFavorite: boolean, event: React.MouseEvent<HTMLDivElement>) => {
        if (currentUser.IsLaege()) {
            event.stopPropagation();

            //prevent spamming
            if (!isPatching && props.laegekompetence) {
                setIsFavoriteState(isFavorite);
                props.laegekompetence.isFavorite = isFavorite;

                if (props.onFavoriteToggleCallback) {
                    props.onFavoriteToggleCallback(props.laegekompetence);
                }
                isPatching = true;
                await logbogLaegeKompetenceApi.patchFavorite(props.laegekompetence);
                isPatching = false;

                //TODO pkm: HACK! Vi vil gerne udngå at opdatere listen hver gang, men dette forhindrer en hård fejl i første omgang.
                dispatch(toggleLaegekompetenceListRefreshPing())
            }
        }
    }

    const hasAnyNotater = props.laegekompetence.notatCount > 0;
    const hasAnyFiles = amountFiles > 0;
    const hasAnyEvals = props.laegekompetence.evalueringCount > 0;

    return (
        <>
            <div ref={scrolltoRef} role="button" className={`card-list ${isActive ? "card-list-active" : ""}`} onClick={(event) => cardClicked(event)}>
                <div className={`flex-container`}>
                    {showCheckboxState && props.kompetenceCategory !== KompetenceCatagory.Foraeldede &&

                        <div className="checkbox-wrapper margin-right-xs">
                            <input type="checkbox"
                                   id={`checkbox${props.index}`}
                                   checked={checkboxCheckedState}
                                   onChange={() => { }}
                            />
                            <label htmlFor={`checkbox${props.index}`} onClick={(event) => { event.stopPropagation() }} />
                        </div>
                    }
                    <div className="flex-container-column flex-grow">
                        <div className={"flex-container justify-content-between"}>
                            <p className="subtle small">
                                {props.laegekompetence.underoverskrift}
                            </p>
                            {props.showSpinner && checkboxCheckedState &&
                                <div>
                                    <Spinner spinnerSize="extra-tiny-small"/>
                                </div>
                            }
                        </div>

                        <div className="margin-bottom-s">
                            <h5 className="font-weight-bold"
                                title={props.laegekompetence.fullKompetenceTitleWithNumber}>
                                {props.laegekompetence.fullKompetenceTitleWithNumber}
                            </h5>
                        </div>
                        <div className="flex-container">
                            {props.kompetenceListType === KompetenceCardListType.KompetencerByBruger &&
                                <>
                                    <div className="flex-container">
                                        {props.laegekompetence.status === LaegekompetenceStatusEnum.Klarmeldt &&
                                            <div className="margin-right-m">
                                                <span className="badge badge-pill badge-default padding-xs"> <YellowCircle /> {Localizer.global_klarmeldt()}</span>
                                            </div>
                                        }
                                        {props.laegekompetence.status === LaegekompetenceStatusEnum.Godkendt &&
                                            <div className="margin-right-m">
                                                <span className="badge badge-pill badge-default padding-xs"> <GreenCircle /> {Localizer.global_godkendt()}</span>
                                            </div>
                                        }

                                        {props.laegekompetence.hasActiveKvSkema &&
                                            <div className={"margin-right-m card-list-icon " + (hasAnyEvals ? "" : "subtle")}>
                                                <EmblaIcon iconName={kvTabIcon} />
                                                <span className={"margin-left-xs " + (hasAnyEvals ? "" : "subtle")}>
                                                    {props.laegekompetence.evalueringCount}
                                                </span>
                                            </div>
                                        }

                                        <div className={"margin-right-m card-list-icon " + (hasAnyNotater ? "" : "subtle")}>
                                            <EmblaIcon iconName={NotatTabIcon} />
                                            <span className={"margin-left-xs " + (hasAnyNotater ? "" : "subtle")}>
                                                {props.laegekompetence.notatCount}
                                            </span>
                                        </div>

                                        <div className={"margin-right-xs card-list-icon " + (hasAnyFiles ? "" : "subtle")}>
                                            <EmblaIcon iconName={SkemaTabIcon} />
                                            <span className={"margin-left-xs " + (hasAnyFiles ? "" : "subtle")}>
                                                {amountFiles}
                                            </span>
                                        </div>
                                    </div>

                                    {props.laegekompetence.status === LaegekompetenceStatusEnum.Klarmeldt && !props.laegekompetence.klarmeldtAt &&
                                        <div className="margin-left-m">{Localizer.klarmeldingerPage_klarmeldtUdenDato()}</div>
                                    }

                                    {isFavoriteState &&
                                        <div className="ml-auto subtle" role="button" onClick={async (event) => { await setIsFavorite(false, event) }}>
                                            <EmblaIcon iconName="star-filled" />
                                        </div>
                                    }

                                    {!isFavoriteState &&
                                        <div className="ml-auto subtle" role="button" onClick={async (event) => { await setIsFavorite(true, event) }}>
                                            <EmblaIcon iconName="star" />
                                        </div>
                                    }
                                </>
                            }

                            {props.kompetenceListType === KompetenceCardListType.KlarmeldteKompetencerByVejleder &&
                                <div className="d-flex" role="button" onClick={async (event) => { await setIsFavorite(false, event) }}>
                                    <UserProfileAvatar size={UserProfileAvatarSizeEnum.extraSmall}
                                        userProfileAvatarModel={new UserProfileAvatarModel(props.laegekompetence.userNavn, props.laegekompetence.userInitialer, true)} />
                                    <div className="margin-left-s">{props.laegekompetence.userNavn}</div>
                                </div>
                            }
                        </div>

                        {props.laegekompetence.status === LaegekompetenceStatusEnum.Klarmeldt && props.laegekompetence.klarmeldtAt !== undefined &&
                            <div
                                className="margin-top-s flex-row flex-container-row wrap align-items-center">
                                <h6 className={"margin-right-s font-weight-bold d-flex"}>
                                    {Localizer.global_klarmeldtden() + props.laegekompetence.klarmeldtAt} {Localizer.global_to().toLowerCase()}:
                                </h6>
                                {props.laegekompetence.klarmeldtTilNavn?.map(navn =>
                                    <span className="badge badge-pill badge-default padding-xs margin-right-s"> {navn}</span>
                                )}
                            </div>
                        }

                    </div>
                </div>
            </div>
            <div className={"card-list-border"} />
        </>
    );
}
