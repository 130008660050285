import {Localizer} from "../../../infrastructure/localization/localizer";
import React, {useRef} from "react";
import {ForloebOverviewFilterModel, toState} from "./ForloebOverviewFilterModel";
import {setSlideinState, SlideInStateEnum} from "../slideIn/slideInSlice";
import {useAppDispatch} from "../../../app/hooks";
import {applyFilters} from "./forloebOverviewFiltersSlice";
import {HttpClientService} from "../../../services/httpClient/httpClientService";
import {AccountService} from "../../../services/account/accountService";
import {ValidationForm} from "../validation/components/validationForm";
import {BuildNew, CreateFilterInputModel, CreateFilterValidationSchema} from "../filter/createFilterInputModel";
import {LogbogFilterApi} from "../../../services/api/logbogFilter/logbogFilterApi";
import {NotificationModule} from "ditmer-embla";
import {CrudFilterInputModel} from "./crudFilterInputModel";
import {ValidationFormSubmitButton} from "../validation/components/validationFormButton";
import {HandleError} from "../../infrastructure/errors/errorBoundary";
import {ForloebOverviewValidationInput} from "./forloebOverviewValidationInput";
import { ForloebOverviewCreateFilterProps } from "./forloebOverviewFilterProps";
import {ModalAcceptType} from "../modalMessage/modalSubmitMessage";

const logbogFilterApi = new LogbogFilterApi(new HttpClientService(), new AccountService())

export function ForloebOverviewCreateFilter(props: ForloebOverviewCreateFilterProps) {
    const dispatch = useAppDispatch();
    const forloebOverviewFilterModel = useRef<ForloebOverviewFilterModel>(props.filter);

    const setForloebOverviewFilters = async () => {
        dispatch(applyFilters(toState(forloebOverviewFilterModel.current)))
        dispatch(setSlideinState({state: SlideInStateEnum.ActionOngoing, slideInId: props.modalId}))
        const clone = Object.assign({}, forloebOverviewFilterModel);
        clone.current.titel = Localizer.forloebOverviewPage_anvendFilter();
        props.filterAppliedCallback(forloebOverviewFilterModel.current);
        NotificationModule.showSuccess(Localizer.forloebOverviewPage_filterApplied(), "");
        dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: props.modalId}))
    }

    const CreateForloebOverviewFilter = async (inputModel: CreateFilterInputModel) => {
        dispatch(setSlideinState({state: SlideInStateEnum.ActionOngoing, slideInId: props.modalId}));
        const clone = Object.assign({}, forloebOverviewFilterModel);
        clone.current.titel = inputModel.titel;
        clone.current.regionRettigheder = inputModel.regionRettigheder;
        clone.current.regioner = clone.current.regioner?.filter(r => r != Localizer.global_all());
        clone.current.filterId = await logbogFilterApi.createFilter(clone.current);
        dispatch(applyFilters(toState(forloebOverviewFilterModel.current)))
        props.filterAppliedCallback(forloebOverviewFilterModel.current);
        NotificationModule.showSuccess(Localizer.forloebOverviewPage_filterApplied(), "");
        dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: props.modalId}));
    };

    const render = (
        <div className="row">
            <div className="col-sm-12 flex-container-column">
                <ValidationForm
                    key={props.modalId}
                    initialValues={BuildNew()}
                    validationSchema={CreateFilterValidationSchema}
                    onSubmit={
                        async (values) => {
                            await CreateForloebOverviewFilter(values).catch(err => { // catch is needed to handle unhandeled exception (eg. api offline). Formik onsubmithandler is catching all exception and writing them as warnings
                                dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: props.modalId}))
                                HandleError(err);
                            });
                        }}>
                    {
                        (formik) => (
                            <>
                                <CrudFilterInputModel filter={props.filter} modalId={props.modalId} permissions={props.permissions}/>
                                <div className="margin-top-m">
                                    <h5 className="subtle">
                                        {Localizer.forloebOverviewPage_gemFilter()}
                                    </h5>

                                    <ForloebOverviewValidationInput
                                        filter={props.filter}
                                        modalId={props.modalId}
                                        formik={formik}
                                    />

                                    <div className="margin-bottom-m float-right">
                                        <ValidationFormSubmitButton formikProps={formik} buttonType={ModalAcceptType.default}>
                                            {Localizer.forloebOverviewPage_opretFilter()}
                                        </ValidationFormSubmitButton>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </ValidationForm>

                <div className="modal-actions">
                    <button className="btn btn-primary margin-right-m" onClick={() => setForloebOverviewFilters()}>
                        {Localizer.forloebOverviewPage_anvendFilter()}
                    </button>
                </div>
            </div>
        </div>
    )
    return <>{render}</>;
}
