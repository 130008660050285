import React, { useState } from "react";
import { Localizer } from "infrastructure/localization/localizer";
import useEffectAsync from "infrastructure/effect";
import { Loading } from "../../core/components/loading/loading";
import { SetTitleBar } from "../../core/layout/titleBar/titleBarApi";
import {useHistory, useRouteMatch} from "react-router-dom";
import { HttpClientService } from "services/httpClient/httpClientService";
import { AccountService } from "../../services/account/accountService";
import {  useAppDispatch, useAppSelector } from "../../app/hooks";
import { LogbogVejledersamtaleApi } from "../../services/api/logbogVejledersamtale/logbogVejledersamtaleApi";
import { forloebReducer, ForloebSliceState } from "../../core/forloebSlice";
import { InitForloebState } from "../forloeb/forloebApi";
import { VejledersamtaleEnum, VejledersamtaleModel } from "./vejledersamtaler/vejledersamtaleModel";
import { VejledersamtaleList } from "./vejledersamtaler/components/vejledersamtaleList";
import { UddannelsesplanList } from "./uddannelsesplan/components/uddannelsesplanList";
import { UddannelsesplanModel } from "./uddannelsesplan/uddannelsesplanModel";
import { LogbogUddannelsesplanApi } from "../../services/api/logbogUddannelsesplan/logbogUddannelsesplanApi";
import { Tab, Tabs } from "../../core/components/Tabs/Tabs";
import {ForloebHeader} from "../../core/components/headerCards/forloebHeader";
import ForloebSpecificPageWrapper from "core/componentsPage/forloeb/forloebSpecificPageWrapper";
import useScreenResolution from "core/hooks/useScreenResolution";
import {userVejledningQueryParams} from "../../infrastructure/route/queryParams";
import {RoutePaths} from "../../infrastructure/routes";

const vejledersamtaleApi = new LogbogVejledersamtaleApi(new HttpClientService(), new AccountService())
const uddannelsesplanApi = new LogbogUddannelsesplanApi(new HttpClientService(), new AccountService())

export function VejledningPage() {
    const { params } = useRouteMatch<userVejledningQueryParams>();
    const dispatch = useAppDispatch();
    const { isMobile } = useScreenResolution();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(true);
    const [activeTab, setActiveTab] = useState<string>();
    const [vejledersamtaler, setVejledersamtaler] = useState<VejledersamtaleModel[]>([])
    const [uddannelsesplaner, setUddannelsesplaner] = useState<UddannelsesplanModel[]>([])

    const uddannelseslaegeId = params.uddannelseslaege;
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    SetTitleBar(Localizer.global_vejledning(), [{text: Localizer.global_vejledning(), url: ""}]);

    async function loadUddannelsesplanList() {
        const forloeb = await InitForloebState(dispatch, forloebSliceState.forloebState, uddannelseslaegeId);
        if (forloeb.id) {
            let uddannelsesplanerFromApi = await uddannelsesplanApi.getUddannelsesplanerByForloeb(forloeb.id);
            setUddannelsesplaner(uddannelsesplanerFromApi);
        } else {
            setUddannelsesplaner([])
        }
    }

    async function loadVejledersamtaleList() {
        const forloeb = await InitForloebState(dispatch, forloebSliceState.forloebState, uddannelseslaegeId);
        if (forloeb.id) {
            let samtalerFromApi = await vejledersamtaleApi.getVejledersamtalerByForloeb(forloeb.id);

            samtalerFromApi.sort((a, b) => b.dato.getTime() - a.dato.getTime());

            let placeholderVejledersamtaler = new Array<VejledersamtaleModel>();

            if (!samtalerFromApi.some(s => s.vejledersamtaleType === VejledersamtaleEnum.Introduktion)) {
                const introduktionplaceholder = VejledersamtaleModel.CreatePlaceholder(forloeb.id, VejledersamtaleEnum.Introduktion, Localizer.global_boerAfholdesIStartenAfForloebet());
                placeholderVejledersamtaler.push(introduktionplaceholder);
            }

            if (!samtalerFromApi.some(s => s.vejledersamtaleType === VejledersamtaleEnum.Justering)) {
                const justeringplaceholder = VejledersamtaleModel.CreatePlaceholder(forloeb.id, VejledersamtaleEnum.Justering, Localizer.global_boerAfholdesIndenTreMaaneder());
                placeholderVejledersamtaler.push(justeringplaceholder);
            }

            if (!samtalerFromApi.some(s => s.vejledersamtaleType === VejledersamtaleEnum.Afsluttende)) {
                const afsluttendeplaceholder = VejledersamtaleModel.CreatePlaceholder(forloeb.id, VejledersamtaleEnum.Afsluttende, Localizer.global_boerAfholdesIndenForloebetErAfsluttet());
                placeholderVejledersamtaler.push(afsluttendeplaceholder);
            }

            //ordering by type: 0: Introduktion, 1: Justerende, 2: Afsluttende, efterfulgt af 3: "Anden"
            let orderedVejledersamtaler = samtalerFromApi.concat(placeholderVejledersamtaler).sort((a, b) => a.vejledersamtaleType.toString().localeCompare(b.vejledersamtaleType.toString()))
            setVejledersamtaler(orderedVejledersamtaler);

        } else {
            setVejledersamtaler([]);
        }
    }

    useEffectAsync(async () => {
        setIsLoading(true);

        await loadVejledersamtaleList();
        await loadUddannelsesplanList();

        setActiveTab(activeTab ?? params.uddannelsesplan
            ? Localizer.uddannelsesplan()
            : Localizer.global_vejledersamtale());

        setIsLoading(false);

    }, [dispatch]);

    const setTabAndHistory = (id: string) => {
        setActiveTab(id);

        history.replace(RoutePaths.Vejledning({
            uddannelseslaege: uddannelseslaegeId,
            uddannelsesplan: undefined,
            vejledersamtale: undefined
        }).url);
    }

    const tabs: Tab[] = [
        {
            title: Localizer.global_vejledersamtale(),
            id: Localizer.global_vejledersamtale(),
            defaultActive: activeTab === Localizer.global_vejledersamtale(),
            content: <VejledersamtaleList vejledersamtaler={vejledersamtaler} vejledersamtalerUpdatedCallback={loadVejledersamtaleList} />
        },
        {
            title: Localizer.uddannelsesplan(),
            id: Localizer.uddannelsesplan(),
            defaultActive: activeTab === Localizer.uddannelsesplan(),
            content: <UddannelsesplanList uddannelsesplaner={uddannelsesplaner} uddannelsesplanerUpdatedCallback={loadUddannelsesplanList} />
        },
    ];

    return (
        <>
            <Loading isLoading={isLoading} text={Localizer.profilepage_getVejledersamtaler()}>
                <ForloebSpecificPageWrapper>
                    {!isMobile &&
                        <div>
                            <ForloebHeader hideSeUddannelseAndForloebDetails={true} />
                            <div className="row top-level-row">
                                <div className="col-md-6 col-12">
                                    <VejledersamtaleList vejledersamtaler={vejledersamtaler} vejledersamtalerUpdatedCallback={loadVejledersamtaleList} />
                                </div>
                                <div className="col-md-6 col-12">
                                    <UddannelsesplanList uddannelsesplaner={uddannelsesplaner} uddannelsesplanerUpdatedCallback={loadUddannelsesplanList} />
                                </div>
                            </div>
                        </div>
                    }

                    {isMobile &&
                        <div className="top-level-row">
                            <Tabs tabs={tabs} tabClickedCallback={x => setTabAndHistory(x.id)}/>
                        </div>
                    }
                </ForloebSpecificPageWrapper>
            </Loading>
        </>
    );
}
