import './pagination.scss';
import {EmblaIcon} from "../emblaIcon/emblaIcon";
import {PaginationModel} from "../../sharedmodels/filter/PaginationModel";

interface PaginationProps {
    paginationModel: PaginationModel;
    handlePagination: (page: number) => void;
}

export function Pagination(props: PaginationProps) {

    const renderSeparator = () => {
        return (
            <div className={"btn btn-default separator-btn"}>...</div>
        );
    }

    const renderPageItem = (page: number, active: boolean = false) => {
        return (
            <button type="button" className={"btn btn-default pagination-item " + (active ? "active-pagination-btn" : "")}
                    onClick={() => props.handlePagination(page)} aria-label={page.toString()}>
                {page}
            </button>
        );
    }

    const renderLeft = (active: boolean = false) => {
        return (
            <button type="button" className={"left-btn btn btn-default btn-icon"} disabled={active} aria-label="Left"
                    onClick={() => props.handlePagination(props.paginationModel.page - 1)}>
                <EmblaIcon iconName={"arrow-left"}/>
            </button>
        );
    }

    const renderRight = (active: boolean = false) => {
        return (
            <button type="button" className="right-btn btn btn-default btn-icon" aria-label="Right" disabled={active}
                    onClick={() => props.handlePagination(props.paginationModel.page + 1)}>
                <EmblaIcon iconName={"arrow-right"}/>
            </button>
        );
    }

    const totalPages = (paginationModel: PaginationModel) : number => {
        return Math.ceil(paginationModel.recordCount / paginationModel.pageLength);
    }
    
    function determineIfToIncrement(page: number) {
        const isTotalPagesEqualFour = totalPages(props.paginationModel) === 4;
        return isTotalPagesEqualFour ? page + 1 : page;
    }

    //Note: Implementation inspired by: https://javascript.plainenglish.io/building-a-pagination-component-in-react-with-typescript-2e7f7b62b35d

    return (
        <div className="btn-group" role="group" aria-label="Pagination">

            <>{renderLeft(props.paginationModel.page === 1)}</>

            <>{renderPageItem(1, (props.paginationModel.page === 1))}</>

            {props.paginationModel.page > determineIfToIncrement(3) &&
            <>{renderSeparator()}</>
            }

            {props.paginationModel.page === totalPages(props.paginationModel) && totalPages(props.paginationModel) > 3 && (
                <>{renderPageItem(props.paginationModel.page - 2)}</>
            )}

            {props.paginationModel.page > 2 && (
                <>{renderPageItem(props.paginationModel.page - 1)}</>
            )}

            {props.paginationModel.page !== 1 && props.paginationModel.page !== totalPages(props.paginationModel) && (
                <>{renderPageItem(props.paginationModel.page, true)}</>
            )}

            {props.paginationModel.page < totalPages(props.paginationModel) - 1 && (
                <>{renderPageItem(props.paginationModel.page + 1)}</>
            )}

            {props.paginationModel.page === 1 && totalPages(props.paginationModel) > 3 && (
                <>{renderPageItem(props.paginationModel.page + 2)}</>
            )}

            {props.paginationModel.page < totalPages(props.paginationModel) - determineIfToIncrement(2) &&
            <>{renderSeparator()}</>
            }

            {totalPages(props.paginationModel) > 1 &&
            <>
                {renderPageItem(totalPages(props.paginationModel), (props.paginationModel.page === totalPages(props.paginationModel)))}
            </>
            }

            <>{renderRight(props.paginationModel.page >= totalPages(props.paginationModel))}</>
        </div>
    );
}
