import { DropdownOption } from "core/components/dropdown/dropdown";

const NumberArrayDropdownOptions = (number: number[]) => {
    return number.map(x => (
        {
            label: x.toString(),
            value: x
        } as DropdownOption<number>
    ));
}

export default NumberArrayDropdownOptions;
