import React, {useEffect, useState} from "react";
import landingPageDevicesImage from "../../content/images/landing-page-devices.png";
import {ReactComponent as Uddannelseslaege} from "../../content/icons/uddannelseslaege.svg";
import {ReactComponent as Vejleder} from "../../content/icons/vejleder.svg";
import {ReactComponent as Overlaege} from "../../content/icons/overlaege.svg";
import {Localizer} from "../../infrastructure/localization/localizer";
import './landingPage.scss';
import {ContactInformation} from "./contactInformation";
import {ReactComponent as Logo} from "../../content/icons/logo-icon.svg";
import {LandingPageUserCard} from "./landingPageUserCard";
import {landingPageInfoUrl, tilgaengelighedsErklaeringUrl} from "../../infrastructure/redirectConstant";
import {EmblaIcon} from "../../core/components/emblaIcon/emblaIcon";
import {Link} from "react-router-dom";
import {RoutePaths} from "../../infrastructure/routes";
import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../../core/layout/responsive";
import useStamdataApi from "../../core/hooks/useStamdataApi";
import {EmblaIcons} from "../../core/emblaIcons";
import { DriftforstyrrelseModel} from "../../services/api/driftsforstyrrelser/stamdataDriftsforstyrrelserResponseModel";

export const LandingPage = () => {
    const uddannelseslaegeEmail = "uddannelseslaege@regionh.dk";
    const IsMobile = useMediaQuery(mobileMaxWidthQuery);
    const { stamdataDriftsforstyrrelserApi } = useStamdataApi();
    const [driftsforstyrrelserState, setDriftsforstyrrelserState] = useState<DriftforstyrrelseModel[]>([]);

    useEffect(() => {
        const fetchDriftsforstyrrelser = async () => {
            const driftsforstyrrelser = await stamdataDriftsforstyrrelserApi.getDriftsforstyrrelser();
            setDriftsforstyrrelserState(driftsforstyrrelser);
        };
        fetchDriftsforstyrrelser();
    },[stamdataDriftsforstyrrelserApi])

    return (<>
            <div className="container">
                <div className="wrap flex-row-center align-items-center landing-page-colored-area">
                    <div className="landing-page-welcome">
                        <h2>
                            {Localizer.landingPage_velkommen()}
                        </h2>
                        <div className="margin-top-m margin-bottom-m">
                            {Localizer.landingPage_velkommenBeskrivelse()}
                            <div className={"mt-3"}>
                                <a className="btn btn-secondary rounded-pill" target={"_blank"}
                                   href={landingPageInfoUrl} rel="noreferrer">
                                    {Localizer.landingPage_supportHjemmeside()}
                                    <EmblaIcon iconName={"arrow-right"}/>
                                </a>
                            </div>
                        </div>
                    </div>

                    <img className="landing-page-image-container" alt={"landing-page-devices"} src={landingPageDevicesImage}/>

                    {driftsforstyrrelserState.length > 0 &&
                        <div className="container card shadow-none driftsforstyrrelse-container">
                            <div className="padding-m margin-top-m flex-row-center align-items-center">
                                {<EmblaIcon iconName={EmblaIcons.Warning}/>}
                                <h3 className="flex-row-center margin-left-s">
                                    {Localizer.landingPage_driftsforstyrrelser()}
                                </h3>
                            </div>
                            <div className="padding-m">
                                {driftsforstyrrelserState.map((driftForstyrrelse) => (
                                    <div className="d-flex flex-column align-items-center margin-bottom-s" key={driftForstyrrelse.id}>
                                        <b>{driftForstyrrelse.startDato.dateWithTimeFormat()}</b>
                                        <p>{driftForstyrrelse.tekst}</p>
                                    </div>
                                    
                                ))}
                                
                            </div>
                        </div>
                    }
                </div>
            </div>

        <div className="container-fluid">
            <div className="flex-container-column landing-page-white-area ">
                <div className="container landing-page-user-cards landing-page-user-cards">
                    <h3 className="flex-row-center margin-l">
                        {Localizer.landingPage_hvemErDetTilFor()}
                    </h3>
                    <div className="wrap flex-space-evenly margin-bottom-l">
                        <LandingPageUserCard logo={<Uddannelseslaege/>}
                                             userRole={Localizer.landingPage_uddannelseslaeger()}
                                             userDescription={Localizer.landingPage_uddannelseslaegerBeskrivelse()}/>
                        <LandingPageUserCard logo={<Vejleder/>}
                                             userRole={Localizer.landingPage_uddannelsesgivende()}
                                             userDescription={Localizer.landingPage_uddannelsesgivendeBeskrivelse()}/>
                        <LandingPageUserCard logo={<Overlaege/>}
                                             userRole={Localizer.landingPage_andre()}
                                             userDescription={Localizer.landingPage_andreBeskrivelse()}/>
                    </div>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="flex-container-column landing-page-colored-area">
                <h3 className="padding-l flex-row-center">
                    {Localizer.landingPage_kontakt()}
                </h3>

                <div className="flex-container flex-row justify-content-center">
                    <p className={"landing-page-contact-info"}>
                        {Localizer.landingPage_kontaktInfo()}
                    </p>
                </div>

                <div className="flex-container flex-row flex-space-evenly wrap align-items-start mt-4">
                    <ContactInformation/>
                </div>

                <div className="flex-container flex-row justify-content-center mt-5">
                    <p className={"landing-page-contact-info"}>
                        {Localizer.landingPage_kontaktBeskrivelse()}
                        <a href={"mailto:" + uddannelseslaegeEmail}>
                            {uddannelseslaegeEmail}
                        </a>
                    </p>
                </div>
            </div>
        </div>

        <div className="container-fluid">
            <div className="landing-page-white-area border-top">
                <div className="container">
                    <div className="wrap flex-row-center flex-space landing-page-bottom">

                        <div className="flex-container-row flex-align-c flex-align-l landing-page-bottom-brand">
                            <Logo/>
                            <h5 className="text-primary margin-right-l">
                                {Localizer.global_uddannelseslaegedk()}
                            </h5>
                        </div>

                        <div className={`flex-container-row flex-space-evenly mt-3 ${IsMobile ? "flex-align-l" : "flex-align-r"}`}>
                            <div className="landing-page-bottom-margin">
                                <h5 className="margin-top-m margin-bottom-s">
                                    {Localizer.landingPage_webtilgaengelighedOgCookies()}
                                </h5>
                                <div>
                                    <a href={tilgaengelighedsErklaeringUrl}>
                                        {Localizer.landingPage_tilgaengelighedserklaering()}
                                    </a>
                                </div>
                                <div>
                                    <Link to={RoutePaths.Cookies.path}>
                                        {Localizer.landingPage_cookies()}
                                    </Link>
                                </div>
                                <div>
                                    <Link to={RoutePaths.Privatlivspolitik.path}>
                                        {Localizer.landingPage_privatlivspolitik()}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
    )
}
