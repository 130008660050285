import React, {useEffect, useState} from 'react'
import {Localizer} from 'infrastructure/localization/localizer'
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {forloebReducer, ForloebSliceState} from "../../../core/forloebSlice";
import {BuildExisting, crudKursusValidationSchema, CrudLaegekursusInputModel} from "./crudLaegekursusInputModel";
import {setSlideinState, SlideInStateEnum} from "../../../core/components/slideIn/slideInSlice";
import {NotatType} from "../../../core/sharedmodels/notat/notatType";
import {HandleError} from "../../../core/infrastructure/errors/errorBoundary";
import {LaegekursusCrudFields} from "./laegekursusCrudFields";
import {LaegekursusModel} from "../../../services/api/laegekursus/laegekursusModel";
import {BasicNotatModel} from "../../../core/sharedmodels/notat/basicNotatModel";
import {ValidationForm} from "../../../core/components/validation/components/validationForm";
import {Loading} from "../../../core/components/loading/loading";
import {NotificationModule} from "ditmer-embla";
import {LaegekursusStatusEnum} from "../../../core/sharedmodels/kursus/laegekursusStatusEnum";
import {EmblaIcons} from 'core/emblaIcons';
import {UserModel} from 'services/account/userModel';
import {godkendLaegeKursus} from "./godkendKursusButton";
import DynamicButton from "../../../core/components/button/dynamicButton";
import {LaegekursusGruppeTypeEnum} from "../../../core/sharedmodels/kursus/laegekursusGruppeTypeEnum";
import {RolleTitelEnum} from 'infrastructure/enums/rolleTitelEnum';
import useUser from 'core/hooks/useUser';
import useLogbogApi from 'core/hooks/useLogbogApi';
import classNames from "classnames";
import {ActionIcon} from "../../../core/components/actionIcon/actionIcon";
import useScreenResolution from "../../../core/hooks/useScreenResolution";
import Tooltip from "../../../core/components/tooltips/Tooltip";

type EditKursusProps =  {
    modalId: string,
    laegekursus: LaegekursusModel,
    laegekursusGruppeGodkendt: boolean,
    areYouSureFjernGodkendelseModalId: string,
    areYouSureDeleteModalId: string,
    openAreYouSureFjernGodkendelseModal: () => void,
    openAreYouSureDeleteModal: () => void,
    openShowKlarmeldingModal: () => void,
    editKlarmeldingModelOpen: () => void,
    forloebId?: string,
    brugerMaalbeskrivelseId?: string,
    laegekursusStatusEditCallback: () => void;
}

enum ButtonSubmitActionEnum {
    Save = 1,
    SaveAndApprove = 2,
    SaveAndMarkReady = 3,
}


export const isUserAllowedToFillApproval = (user: UserModel, laegekursusGruppeType: LaegekursusGruppeTypeEnum) => {

    if (laegekursusGruppeType === LaegekursusGruppeTypeEnum.KliniskBasisuddannelse ||
        laegekursusGruppeType === LaegekursusGruppeTypeEnum.Introduktionsuddannelsen ||
        laegekursusGruppeType === LaegekursusGruppeTypeEnum.Hoveduddannelse) {
        return user.IsAdminOrVus() || user.IsKursusudbyder()
    }

    return user.IsUddannelsesansvarlig()
        || user.IsVejleder()
        || user.IsAdminOrVus()
        || user.IsKursusleder()
        || user.IsKursusudbyder()
        || user.IsForskningstraeningsvejleder()
}

export const EditLaegekursus = ({laegekursus, laegekursusGruppeGodkendt, openShowKlarmeldingModal, editKlarmeldingModelOpen, ...props}: EditKursusProps) => {
    const dispatch = useAppDispatch();
    const {isMobile} = useScreenResolution();

    const { currentUser } = useUser();
    const { logbogLaegekursusApi, notatApi } = useLogbogApi();

    const isGodkendt = laegekursus.status === LaegekursusStatusEnum.Godkendt;
    const isHovedkursusleder = currentUser.IsKursusleder() && currentUser.hasRolleTitel(RolleTitelEnum.Hovedkursusleder);
    const showDeleteBtn = !isGodkendt && (currentUser.IsAdminOrVus() || isHovedkursusleder);

    const [isLoading, setIsLoading] = useState(true);
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    const [notat, setNotat] = useState<BasicNotatModel | null>(null);

    const readonly = currentUser.IsSekretaer();

    const kursusKlarmeldt = laegekursus.status === LaegekursusStatusEnum.Klarmeldt && laegekursus.klarmeldtAt !== undefined;
    const kursusIsSpecialeSpecifikt = (laegekursus.gruppeType === LaegekursusGruppeTypeEnum.Specialespecifikke || laegekursus.gruppeType === LaegekursusGruppeTypeEnum.SpecialespecifikkeIntrouddannelsen);
    const showKlarmeldingButton = !laegekursusGruppeGodkendt
        && laegekursus.status !== LaegekursusStatusEnum.Godkendt
        && kursusIsSpecialeSpecifikt;

    let submitAction: ButtonSubmitActionEnum = ButtonSubmitActionEnum.Save;

    const isNotCurrentUsersKursus = () => {
        return forloebSliceState.forloebState.brugerId !== currentUser.UserId;
    }

    const isCurrentUserAllowedToFillApproval = () => {
        return isUserAllowedToFillApproval(currentUser, laegekursus.gruppeType) && isNotCurrentUsersKursus();
    }

    useEffect(() => {
        const getNotat = async () => {
            const notat = await notatApi.getNotatOnContext(NotatType.Laegekursus, forloebSliceState.forloebState.brugerId, laegekursus.id);
            setNotat(notat);
        };
        getNotat().then(() => setIsLoading(false));
        dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: props.modalId}))
    }, [dispatch, forloebSliceState.forloebState.brugerId, notatApi, props.modalId, laegekursus.id]);

    const editLaegekursus = async (inputModel: CrudLaegekursusInputModel) => {
        dispatch(setSlideinState({state: SlideInStateEnum.ActionOngoing, slideInId: props.modalId}))
        const editedLaegekursusId = await logbogLaegekursusApi.editLaegekursus(inputModel);
        if (editedLaegekursusId) {
            dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: props.modalId}))
            NotificationModule.showSuccess(Localizer.kursusPage_kursusEdited(), "");
        } else {
            dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: props.modalId}))
        }
    }

    const notatFilesChangedCallbackOverride = async () => {
        const notat = await notatApi.getNotatOnContext(NotatType.Laegekursus, forloebSliceState.forloebState.brugerId, laegekursus.id);
        setNotat(notat);
    }

    async function handleStatusChange(values: CrudLaegekursusInputModel) {
        switch (submitAction) {
            case ButtonSubmitActionEnum.Save:
                return;
            case ButtonSubmitActionEnum.SaveAndApprove:
                const godkendtKursusId = await godkendLaegeKursus(laegekursus.id, values.godkendtPaaAndetGrundlag, props.brugerMaalbeskrivelseId ?? forloebSliceState.forloebState.brugerMaalbeskrivelseId, forloebSliceState.forloebState.laegeInstitutionName, forloebSliceState.forloebState.afdelingName, values.godkendelseKommentar);
                if (godkendtKursusId.data)
                    props.laegekursusStatusEditCallback();
                break;
            case ButtonSubmitActionEnum.SaveAndMarkReady:
                openShowKlarmeldingModal()
                break;
            default:
                return;
        }
    }

    const render = (
        <Loading isLoading={isLoading} text={Localizer.global_getData()}>
            <ValidationForm
                initialValues={BuildExisting(laegekursus.id,
                    props.forloebId ?? forloebSliceState.forloebState.id,
                    laegekursus.brugerId,
                    laegekursus.laegekursusGruppeId,
                    laegekursus.brugerKursusraekkeId,
                    laegekursus.titel,
                    laegekursus.status,
                    laegekursus.aendretEllerOprettetAfLaege,
                    laegekursus.gruppeType,
                    laegekursus.godkendPaaAndetGrundlag,
                    laegekursus.sortOrder,
                    laegekursus.eventuelSpecifikation,
                    laegekursus.startDato,
                    laegekursus.slutDato,
                    laegekursus.godkendelsesInfo?.kommentar,
                )}
                validationSchema={crudKursusValidationSchema}
                onSubmit={async (values: CrudLaegekursusInputModel) => {
                    await editLaegekursus(values)
                        .then(async () =>
                            await handleStatusChange(values)
                        )
                        .catch(err => { // catch is needed to handle unhandeled exception (eg. api offline). Formik onsubmithandler is catching all exception and writing them as warnings
                            dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: props.modalId}))
                            HandleError(err);
                        });
                }}>
                {
                    (formik) => (
                        <>
                            {isGodkendt && laegekursus.godkendelsesInfo !== null && laegekursus.godkendelsesInfo !== undefined &&
                                <div className="alert alert-success left-right-minus-spacing">
                                    {Localizer.kursusPage_laegekursusGodkendtAf(laegekursus.godkendelsesInfo.godkendtDen.dateWithoutTimeFormat(true), laegekursus.godkendelsesInfo.godkendtAfNavn, laegekursus.godkendelsesInfo.rolleTitel, laegekursus.godkendelsesInfo.authorisationsId ?? "")}
                                </div>
                            }
                            <LaegekursusCrudFields
                                modalId={props.modalId}
                                formik={formik}
                                notatInfo={{
                                    contextId: laegekursus.id,
                                    notatType: NotatType.Laegekursus,
                                    userId: forloebSliceState.forloebState.brugerId
                                }}
                                notat={notat}
                                readonly={readonly}
                                titleIsReadOnly={laegekursus.kursusId !== null || laegekursus.godkendelsesInfo !== undefined}
                                laegekursusKanGodkendes={isCurrentUserAllowedToFillApproval()}
                                laegekursusGodkendt={isGodkendt}
                                filesUpdatedCallback={() => notatFilesChangedCallbackOverride()}
                            />
                            {kursusKlarmeldt && showKlarmeldingButton &&
                                <div className={classNames("margin-bottom-m", isMobile && "godkend-info-mobile")}>
                                    <div className={classNames("alert alert-warning flex-row flex-space align-items-center")}
                                        role="alert">
                                        {Localizer.global_klarmeldtden() + laegekursus.klarmeldtAt}
                                        {(currentUser.IsLaege() || currentUser.IsAdminOrVus()) &&
                                            <div className="align-self-center">
                                                <ActionIcon
                                                    addtionalClassNames="flex-align-r"
                                                    iconName="edit"
                                                    action={editKlarmeldingModelOpen}/>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            <div className="modal-actions">

                                {(currentUser.IsAdminOrVus() && isGodkendt) &&
                                    <DynamicButton
                                        additionalClasses='mr-2'
                                        onClick={() => props.openAreYouSureFjernGodkendelseModal()}
                                        disabled={laegekursusGruppeGodkendt}
                                        modal={{
                                            dataTarget: `#${props.areYouSureFjernGodkendelseModalId}`,
                                        }}
                                    >
                                        <DynamicButton.TextIconItem iconName={EmblaIcons.Remove} text={Localizer.kursusPage_fjernGodkendelse()}/>
                                    </DynamicButton>
                                }

                                {!isGodkendt && isCurrentUserAllowedToFillApproval() &&
                                    <DynamicButton
                                        additionalClasses='mr-2 ml-2'
                                        onClick={() => {
                                            submitAction = ButtonSubmitActionEnum.SaveAndApprove;
                                            formik.handleSubmit()
                                        }}
                                        disabled={readonly}>
                                        <DynamicButton.TextIconItem
                                            iconName={EmblaIcons.Success}
                                            renderIcon={isMobile}
                                            text={Localizer.global_SaveAndApprove()}
                                            renderText={!isMobile}
                                        />
                                    </DynamicButton>
                                }

                                {(showKlarmeldingButton && (currentUser.IsLaege() || currentUser.IsAdminOrVus())) &&
                                    <Tooltip title={Localizer.kursusPage_udfyldForAtKlarmelde()} show={laegekursus.status !== LaegekursusStatusEnum.Udfyldt && laegekursus.status !== LaegekursusStatusEnum.Klarmeldt && (!formik.values.startDato || !formik.values.slutDato)}>
                                        <DynamicButton
                                            additionalClasses='mr-2'
                                            onClick={() => {
                                                if (laegekursus.klarmeldtAt)
                                                    openShowKlarmeldingModal()
                                                else {
                                                    submitAction = ButtonSubmitActionEnum.SaveAndMarkReady;
                                                    formik.handleSubmit();
                                                }
                                            }}>
                                            <DynamicButton.TextIconItem
                                                iconName={laegekursus.klarmeldtAt ? EmblaIcons.Remove : EmblaIcons.Edit}
                                                renderIcon={isMobile}
                                                text={laegekursus.klarmeldtAt ? Localizer.global_removeKlarmeld() : Localizer.global_saveAndKlarmeld()}
                                                renderText={!isMobile}
                                            />
                                        </DynamicButton>
                                    </Tooltip>
                                }

                                {showDeleteBtn &&
                                    <button type={"button"}
                                            className="btn btn-default mr-2"
                                            disabled={readonly}
                                            data-toggle="modal"
                                            data-target={`#${props.areYouSureDeleteModalId}`}
                                            data-dismiss="modal"
                                            onClick={() => props.openAreYouSureDeleteModal()}>
                                        {Localizer.global_delete()}
                                    </button>
                                }

                                {!isGodkendt &&
                                    <button className="btn btn-primary mr-2"
                                            type="button"
                                            disabled={readonly}
                                            onClick={() => {
                                                submitAction = ButtonSubmitActionEnum.Save;
                                                formik.handleSubmit()
                                            }}>
                                        {Localizer.global_save()}
                                    </button>
                                }

                            </div>
                        </>
                    )
                }
            </ValidationForm>
        </Loading>

    )
    return <>{render}</>
}
