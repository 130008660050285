import { HTMLAttributes, ReactNode } from "react";
import Col, { ColProps } from "../divSpecializations/Col";

interface PdfHeaderRowEntryProps {
  label: string;
  value?: string | (string | undefined)[];
  isColumn?: boolean;
  useRowRightAlignedValues?: boolean;
}

const Entry = ({
  label,
  value,
  isColumn = false,
  useRowRightAlignedValues = false,
}: PdfHeaderRowEntryProps) => {

  const shouldDisplayRightAlignedValues = !isColumn && Array.isArray(value) && useRowRightAlignedValues;
  const entryAdditionalClassNames = isColumn ? "flex-container-column" : !useRowRightAlignedValues ? "flex-wrap" : "";

  const valueNodes = 
    Array.isArray(value) ?
      value?.map((v, i) => (
        <p key={i} className={`large ${!isColumn && "margin-right-s"}`}>{v}</p>
      )) : <p className={"large"}>{value}</p>;

  return (
    <div className={`col-sm-6 d-flex ${entryAdditionalClassNames}`}>
          <p className={"font-weight-bolder text-nowrap margin-right-s large"}>{label + ":"}</p>
          {shouldDisplayRightAlignedValues 
            ? <div>{valueNodes}</div>
            : valueNodes
          }
    </div>
  );
}

interface WithRequiredChildren extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  asFragment?: boolean;
  overrideClasses?: string;
}

const PdfHeaderRow = ({asFragment=false, overrideClasses, ...props}: WithRequiredChildren) => {
  return asFragment
    ? <>{props.children}</>
    : <div className={"flex-container-row margin-s"} {...props} > {props.children} </div>
}

export default Object.assign(
  PdfHeaderRow,
  {
    Entry,
    Col: ({width, bold=true, ...props}: ColProps) => <Col width={width} bold={bold} {...props}></Col>
  },
);
