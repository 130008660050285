import {useCallback, useEffect, useState} from 'react'
import {HttpClientService} from "../../../services/httpClient/httpClientService";
import {Localizer} from '../../../infrastructure/localization/localizer';
import {AccountService} from "../../../services/account/accountService";
import {FileMetadataList} from '../../components/fileMetadata/fileMetadataList';
import useEffectAsync from 'infrastructure/effect';
import {LogbogNotatApi} from 'services/api/logbogNotat/logbogNotatApi';
import {NotatType} from 'core/sharedmodels/notat/notatType';
import {Tab, Tabs} from 'core/components/Tabs/Tabs';
import {BasicNotatModel} from "../../sharedmodels/notat/basicNotatModel";
import {Loading} from "../../components/loading/loading";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {forloebReducer, ForloebSliceState, pingRefreshForloeb, setAttestationForTidInfoTekst} from "../../forloebSlice";
import {ForloebModel} from "../../sharedmodels/forloeb/forloebModel";
import {UddannelseTypeEnum} from "../../sharedmodels/stilling/uddannelseTypeEnum";
import {FileLocationEnum} from "../../sharedmodels/file/fileLocationEnum";
import {UserPersonaleList} from "../../components/userPersonaleList/userPersonaleList";
import {RoutePaths} from "../../../infrastructure/routes";
import {useHistory} from "react-router-dom";
import {closeModal} from "../../components/slideIn/slideIn";
import {LogbogUserInfoApi} from "../../../services/api/logbogUser/logbogUserInfoApi";
import {UserWithForloebAccessDateModel} from "../../sharedmodels/user/userWithForloebAccessDateModel";
import {UserForloebAccessList} from "../../components/userPersonaleList/userForloebAccessList";
import {ContextButtonInfo, ContextButtonMenu} from "../../components/contextMenu/contextButtonMenu";
import {ValidationForm} from 'core/components/validation/components/validationForm';
import {BuildNew, CreateAttestationInputModel, CreateAttestationValidationSchema} from "./createAttestationInputModel";
import {HandleError} from 'core/infrastructure/errors/errorBoundary';
import {ValidationTextarea} from 'core/components/validation/components/validationTextarea';
import {nameof} from 'ts-simple-nameof';
import {ValidationFormSubmitButton} from 'core/components/validation/components/validationFormButton';
import {ForloebApi} from 'services/api/forloeb/forloebApi';
import {addAttestationForTidTekstPatchModel} from 'services/api/forloeb/addAttestationForTidTekstPatchModel';
import {NotificationModule} from 'ditmer-embla';
import {AsyncButton} from 'core/components/button/asyncButton';
import {EmblaIcon} from 'core/components/emblaIcon/emblaIcon';
import {LogbogExportApi} from 'services/api/logbogExport/logbogExportApi';
import {FileDownloadHandler} from 'services/api/logbogFile/fileDownloadHandler';
import {LogbogForloebEvalueringApi} from 'services/api/logbogEvaluering/logbogForloebEvalueringApi';
import {
    LogbogSpecialespecifikkeFilerApi
} from 'services/api/logbogSpecialespecifikkeFiler/logbogSpecialespecifikkeFilerApi';
import {
    LogbogSpecialespecifikFileMetadataResponseModel
} from 'services/api/logbogSpecialespecifikkeFiler/LogbogSpecialespecifikFileMetadataResponseModel';
import {EmblaIcons} from "../../emblaIcons";
import AttestationForTidFileList from './attestationForTidFileList';
import useUser from "../../hooks/useUser";
import useStamdataApi from 'core/hooks/useStamdataApi';
import { PersonaleUserSimpleModel } from 'core/sharedmodels/user/userPersonaleModel';

const notatApi = new LogbogNotatApi(new HttpClientService(), new AccountService());
const userinfoApi = new LogbogUserInfoApi(new HttpClientService(), new AccountService());
const forloebApi = new ForloebApi(new HttpClientService(), new AccountService());
const specialespecifikkeFilerApi = new LogbogSpecialespecifikkeFilerApi(new HttpClientService());

type ForloebDetailsProps = {
    modalId: string,
    forloebId: string,
    userId: string
    openTildelTidsbegraensetAdgangModal: () => void,
    openSkiftMaalbeskrivelseModal: () => void,
    initialActiveTabId?: string;
}

const tidsbegraensetAdgangModalId = "tildelTidsbegraensetAdgang";
const skiftMaalbeskrivelseModalId = "skiftMaalbeskrivelse";
const forloebDetaljerId = "forloebDetaljer";
const forloebFilerId = "forloebFiler";
export const attestationForTidFilerId = "attestationForTidFiler";

export function ForloebDetails({initialActiveTabId="", ...props}: ForloebDetailsProps) {
    const history = useHistory();
    const { currentUser } = useUser();
    const dispatch = useAppDispatch();

    const {stamdataUserApi} = useStamdataApi();

    const [tabs, setTabs] = useState<Tab[]>([])
    const [activeTabId, setActiveTabId] = useState<string>(initialActiveTabId)

    const [isLoading, setIsLoading] = useState(true);
    const [currentForloeb, setCurrentForloeb] = useState<ForloebModel | null>(null);
    const [personaleState, setPersonaleState] = useState<PersonaleUserSimpleModel[]>([]);
    const [usersWithAccess, setUsersWithAccess] = useState<UserWithForloebAccessDateModel[]>([]);
    const [attestationForTidFiles, setAttestationForTidFiles] = useState<BasicNotatModel | undefined>(undefined);
    const [specialespecifikkeFiler, setSpecialespecifikkeFiler] = useState<LogbogSpecialespecifikFileMetadataResponseModel[]>([]);

    const exportApi = new LogbogExportApi(new HttpClientService(), new AccountService(), new FileDownloadHandler());
    const evalueringApi = new LogbogForloebEvalueringApi(new HttpClientService());

    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    useEffectAsync(async () => {
        const attestationForTidNotat = await notatApi.getNotatOnContext(NotatType.AttestationForTid, props.userId, props.forloebId);
        const currentForloeb = ForloebModel.FromState(forloebSliceState.forloebState);
        const personale = await userinfoApi.getLaegerPersonaleOnForloeb(currentForloeb.id);
        const usersWithAccess = await userinfoApi.getUsersWithAccessToForloeb(currentForloeb.id, true);

        const specialespecifikkeFiler = await specialespecifikkeFilerApi.getSpecialespecifikkeFilerByRegionAndBrugerMaalbeskrivelse(currentForloeb.regionId, currentForloeb.brugerMaalbeskrivelseId);
        specialespecifikkeFiler.filter(f => f.fileMetadata.createdAt = new Date(f.fileMetadata.createdAt));

        if (attestationForTidNotat) {
            setAttestationForTidFiles(attestationForTidNotat);
        }

        setPersonaleState(personale);
        setUsersWithAccess(usersWithAccess);
        setCurrentForloeb(currentForloeb);
        setSpecialespecifikkeFiler(specialespecifikkeFiler);
        setIsLoading(false);

    }, [forloebSliceState.refreshForloebPing]);

    useEffect(() => {

        const CreateAttestationInfoTekst = async (inputModel: CreateAttestationInputModel) => {
            let patchedForloeb = await forloebApi.addAttestationForTidTekst(new addAttestationForTidTekstPatchModel(inputModel));
            if (patchedForloeb) {
                NotificationModule.showSuccess(Localizer.forloebpage_BemaerkningTilForloebAdded(), "");
                dispatch(setAttestationForTidInfoTekst(inputModel.InfoTekst!));
            } else {
                NotificationModule.showError(Localizer.forloebpage_BemaerkningTilForloebError(), "");
            }
        };

        const deleteUserAcessToForloeb = async (userId: string) => {
            if(!currentForloeb?.id) return;

            const isDeleted = await userinfoApi.deleteLaegePersonaleFromForloeb(userId, currentForloeb.id);
            if(!isDeleted) {
                NotificationModule.showError(Localizer.forloebEdit_DeleteForloebAdgangError(), "");
                return;
            };

            const allButDeleted = usersWithAccess.filter(x => x.userId !== userId);
            setUsersWithAccess(allButDeleted)

            NotificationModule.showSuccess(Localizer.forloebEdit_DeleteForloebAdgangSuccess(), "");
        }

        const detaljerTabContent = (
            <>
                {currentForloeb &&
                    <div>
                        <div className="overflow-hidden">
                            <div className="row margin-bottom-m">
                                <div className="col-6">
                                    <h5>{Localizer.stillingsnummer()}</h5>
                                    <p>{currentForloeb.stillingNummer}</p>
                                </div>
                            </div>
                            <div className="row margin-bottom-m">
                                <div className="col-6">
                                    <h5>{Localizer.global_maalbeskrivelse()}</h5>
                                    <p>{currentForloeb.maalbeskrivelseNavn} {currentForloeb.maalbeskrivelseVersionsdato?.dateWithoutTimeFormat(true)}</p>
                                </div>
                                <div className="col-6">
                                    <h5>{Localizer.global_uddannelsestype()}</h5>
                                    <p>{UddannelseTypeEnum[currentForloeb?.uddannelseType]}</p>
                                </div>
                            </div>

                            <div className="row margin-bottom-m">
                                <div className="col-6">
                                    <h5>{Localizer.global_region()}</h5>
                                    <p>{currentForloeb.regionNavn}</p>
                                </div>
                                <div className="col-6">
                                    <h5>{Localizer.global_videreuddannelsesregion()}</h5>
                                    <p>{currentForloeb.videreuddannelsesRegionNavn}</p>
                                </div>
                            </div>

                            <div className="row margin-bottom-m">
                                <div className="col-6">
                                    <h5>{Localizer.startdato()}</h5>
                                    <p>{currentForloeb.startDate.dateWithoutTimeFormat(true)}</p>
                                </div>
                                <div className="col-6">
                                    <h5>{Localizer.slutdato()}</h5>
                                    <p>{currentForloeb.endDate.dateWithoutTimeFormat(true)}</p>
                                </div>
                            </div>

                            {(currentUser.IsLaege()) &&
                                <div className="row margin-bottom-l">
                                    <div className="col-12">
                                        <h5>{Localizer.forloebpage_BemaerkningerTilForloeb()}</h5>
                                        <p>{forloebSliceState.forloebState.attestationForTidInfoTekst ? forloebSliceState.forloebState.attestationForTidInfoTekst : Localizer.forloebpage_IngenBemaerkningerTilForloeb()}</p>
                                    </div>
                                </div>
                            }

                        </div>
                        <div>
                            {(currentUser.IsAdmin() || currentUser.IsVusMedarbejeder() || currentUser.IsHrMedarbejder()) &&

                                <div className="row margin-bottom-m">
                                    <div className="col-12">
                                        <ValidationForm
                                            initialValues={BuildNew(forloebSliceState.forloebState.id, forloebSliceState.forloebState.attestationForTidInfoTekst)}
                                            validationSchema={CreateAttestationValidationSchema}
                                            onSubmit={
                                                async (values) => {
                                                    await CreateAttestationInfoTekst(values).catch(err => {
                                                        HandleError(err);
                                                    });
                                                }
                                            }>
                                            {
                                                (formik) => (
                                                    <>
                                                        <ValidationTextarea
                                                            model={{
                                                                label: Localizer.forloebpage_BemaerkningerTilForloeb(),
                                                                placeholder: !!currentForloeb.godkendtDen ? "" : Localizer.forloebpage_BemaerkningerTilForloebLabel(),
                                                                htmlName: nameof<CreateAttestationInputModel>(x => x.InfoTekst)
                                                            }}
                                                            defaultRows={!!currentForloeb.godkendtDen ? 3 : undefined}
                                                            readOnly={!!currentForloeb.godkendtDen}
                                                            inputId={nameof<CreateAttestationInputModel>(x => x.InfoTekst)}
                                                            formikProps={formik}

                                                        />

                                                        <div className="modal-actions">
                                                            <ValidationFormSubmitButton formikProps={formik} disabled={!!currentForloeb.godkendtDen}>
                                                                {Localizer.forloebpage_GemBemaerkningTilForloeb()}
                                                            </ValidationFormSubmitButton>
                                                        </div>
                                                    </>
                                                )
                                            }

                                        </ValidationForm>
                                    </div>
                                </div>
                            }
                        </div>

                        {(personaleState && personaleState.length > 0) &&
                            <div>
                                <h5 className="margin-bottom-s">{Localizer.global_brugereMedAdgang()}</h5>
                                <UserPersonaleList users={personaleState} favoriserCallback={favoriserCallback}/>
                            </div>
                        }
                        {(usersWithAccess && usersWithAccess.length > 0) &&
                            <div>
                                <h5 className="margin-bottom-s">{Localizer.global_brugereMedTidsbegraensetAdgang()}</h5>
                                <UserForloebAccessList
                                    users={usersWithAccess}
                                    removeUserAction={currentUser.IsAdmin() ? async (userId: string) => await deleteUserAcessToForloeb(userId) : undefined}
                                />
                            </div>
                        }
                    </div>
                }
            </>
        )

        const filterTabContent = (
            <>
                <h5 className="margin-bottom-m">
                    {Localizer.forloebpage_specialespecifikkeFiler()}
                </h5>
                <div>
                    <FileMetadataList
                        fileMetadatas={specialespecifikkeFiler.map(x => x.fileMetadata) ?? []}
                        fileLocation={FileLocationEnum.Stamdata}
                        showDeleteButton={false}
                        withUploader={false}
                        showCustomBadgeText={(fileId: string) => specialespecifikkeFiler.find(x => x.fileMetadata.id === fileId)?.alleRegioner ? Localizer.global_national() : Localizer.global_regional()}
                    />
                </div>
            </>
        )

        if(!isLoading) {
            setTabs([
                {
                    title: Localizer.global_detaljer(),
                    id: forloebDetaljerId,
                    content: detaljerTabContent,
                    defaultActive: activeTabId === forloebDetaljerId,
                },{
                    title: Localizer.forloebpage_AttestationForTid(),
                    id: attestationForTidFilerId,
                    content: <AttestationForTidFileList
                                forloebId={props.forloebId}
                                userId={forloebSliceState.forloebState.brugerId}
                                basicNotatModel={attestationForTidFiles}
                                filesUpdatedCallback={(attestationForTidNotat) => setAttestationForTidFiles(attestationForTidNotat)}
                                brugerMaalbeskrivelseId={currentForloeb?.brugerMaalbeskrivelseId ?? ""}
                                isGodkendt={currentForloeb?.godkendelseId !== undefined}
                            />,
                    defaultActive: activeTabId === attestationForTidFilerId,
                },{
                    title: Localizer.global_files(),
                    id: forloebFilerId,
                    content: filterTabContent,
                    defaultActive: activeTabId === forloebFilerId,
                }
            ]);
        }

    }, [isLoading, attestationForTidFiles, activeTabId, props.forloebId, forloebSliceState.forloebState.brugerId, forloebSliceState.forloebState.id, forloebSliceState.forloebState.attestationForTidInfoTekst, currentForloeb, personaleState, usersWithAccess, specialespecifikkeFiler, props.userId, props.modalId, dispatch])

    function createContextMenuButtons(): ContextButtonInfo[] {
        let buttons = new Array<ContextButtonInfo>()
        buttons.push(new ContextButtonInfo(props.openTildelTidsbegraensetAdgangModal, Localizer.forloebpage_tildelTidsbegraensetAdgang(), undefined, tidsbegraensetAdgangModalId));
        buttons.push(new ContextButtonInfo(props.openSkiftMaalbeskrivelseModal, Localizer.forloebpage_skiftMaalbeskrivelse(), undefined, skiftMaalbeskrivelseModalId));
        return buttons;
    }

    const renderDropdownMenu = () => {
        return <>
            {(currentUser.IsAdmin() || currentUser.IsVusMedarbejeder()) && currentForloeb &&
                <ContextButtonMenu buttons={createContextMenuButtons()} border={true} />
            }
        </>
    }

    const deleteEvaluering = async () => {
        await forloebApi.deleteEvalueringOnForloeb(forloebSliceState.forloebState.id);
        NotificationModule.showSuccess(Localizer.evalueringPage_evalueringDeleted(), "")
        dispatch(pingRefreshForloeb());
        closeModal(props.modalId)
    }

    function GoToEvaluationButton(styleClasses: string) : JSX.Element {
        return( <>
            <button className={styleClasses}
                    onClick={() => {
                        history.push(RoutePaths.Evaluering(forloebSliceState.forloebState.brugerId).url)
                        closeModal(props.modalId);
                    }}>
                {Localizer.goToEvaluering()}
            </button>
        </>);
    }

    function DownloadPdfButton(styleClasses: string) : JSX.Element {
        return(<>
             <AsyncButton overrideClasses={styleClasses}
                clickFunction={async () => getForloebEvalueringPdf(props.forloebId)}
                child={<EmblaIcon iconName={EmblaIcons.Download}/>}
            />
        </>
       );
    }

    async function getForloebEvalueringPdf(forloebId: string) {
        var evalId = await evalueringApi.getForloebEvalueringIdByForloebId(forloebId);
        exportApi.getEvalueringStatistikPdf(evalId);
    }

    function DetermineButtonToShow(styleClass: string) : JSX.Element {
        const isEvaluated = currentForloeb?.evalueretDato != null;
        const shouldShowDownloadPdfButton = isEvaluated;
        return shouldShowDownloadPdfButton ? DownloadPdfButton(styleClass) : GoToEvaluationButton(styleClass);
    }

    const favoriserCallback = useCallback(async (favoritPersonaleId: string, isFavorite: boolean) => {
        if (!currentForloeb) return;
        const updateResult = await stamdataUserApi.updateFavoritPersonale(favoritPersonaleId, forloebSliceState.forloebState.brugerId, forloebSliceState.forloebState.id, isFavorite);

        if (!!updateResult) {
            setPersonaleState(prev => prev.map(x => x.userId !== favoritPersonaleId ? x : { ...x, isFavorite: isFavorite }));
        }

    },[currentForloeb, forloebSliceState.forloebState.brugerId, forloebSliceState.forloebState.id, stamdataUserApi])

    return (
        <Loading isLoading={isLoading} text={Localizer.global_getData()}>
            <div>
                {currentForloeb?.godkendtAfNavn && currentForloeb?.godkendtDen &&
                    <>
                    <div className="alert alert-success ">
                        {Localizer.forloebpage_attestationForTidGodkendtAf(currentForloeb?.godkendtDen?.dateWithoutTimeFormat(true), currentForloeb.godkendtAfNavn)}
                    </div>

                    <div className="row margin-bottom-m">
                        <div className="col-sm-12">
                            {Localizer.global_kommentarTilGodkendelse()}
                            <textarea id="textarea"
                                      className="form-control"
                                      defaultValue={currentForloeb?.godkendtKommentar}
                                      placeholder={Localizer.global_kommentarTilGodkendelse()}
                                      disabled={true}
                            />
                        </div>
                    </div>
                    </>
                }

                {currentForloeb?.evalueretDato &&
                    <div className="alert alert-success flex-container-column">
                        <div>
                            {Localizer.global_evalueret()} {currentForloeb.evalueretDato.dateWithoutTimeFormat(true)}
                        </div>

                        {(currentUser.IsAdmin() || currentUser.IsVusMedarbejeder() || currentUser.IsLaege()) &&
                            <div className="d-flex flex-space-around">

                                {DetermineButtonToShow("btn btn-success btn-round")}

                                {(currentUser.IsAdmin() || currentUser.IsVusMedarbejeder()) &&
                                    <button className={"btn btn-danger btn-round"}
                                            onClick={() => deleteEvaluering()}>
                                        {Localizer.global_delete()}
                                    </button>
                                }
                            </div>
                        }

                    </div>
                }

                {tabs.length > 0 &&
                    <Tabs
                        tabs={tabs}
                        navTabRightSideContent={renderDropdownMenu()}
                        setActiveCallback={(id: string) =>  setActiveTabId(id)}
                    />
                }
            </div>
        </Loading>
    )
}
