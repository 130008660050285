import React, {useEffect, useState} from "react";
import "./score.scss"
import {Localizer} from "../../../infrastructure/localization/localizer";
import {SvarmulighedModel} from "../../sharedmodels/evaluering/svarmulighedModel";
import {KompetenceSvarModel} from "../../sharedmodels/evaluering/KompetencesvarModel";
import Tooltip from "../tooltips/Tooltip";
import useScreenResolution from "core/hooks/useScreenResolution";
import {ScoreTypeEnum} from "../../componentsPage/score/scoreTypeEnum";

export class ScoreValues {
    constructor(scoreColor: string, scoreText?: string) {
        this.scoreColor = scoreColor;
        this.scoreText = scoreText;
    }
    scoreColor: string;
    scoreText?: string;
}

export class ScoreProps {
    svarmuligheder?: SvarmulighedModel[];
    svar?: KompetenceSvarModel;
    showText: boolean;
    hasComment?: string;
    svarVaerdi?: number;
    withDecimal?: boolean;
    scoreType: ScoreTypeEnum;
}

export const Score = (props: ScoreProps) => {
    const [scoreValue, setScoreValue] = useState<ScoreValues | undefined>();
    const [scoreState, setScoreState] = useState<number>();

    const { isMobile } = useScreenResolution();

    const showToolTip = props.hasComment !== null;

    useEffect(() => {
        const getScoreValues = (score: number): ScoreValues | undefined => {
            switch (props.scoreType) {
                case ScoreTypeEnum.ForloebEvalueringScore:
                    if (score === 0)
                        return new ScoreValues("#dcdcdc", Localizer.kompetenceEvalueringPage_IkkeBedoemt());
                    else if (score <= 1)
                        return new ScoreValues("#ee4e2f", Localizer.kompetenceEvalueringPage_UnderForventetNiveau());
                    else if (score > 1 && score <= 2)
                        return new ScoreValues("#f19b37", Localizer.kompetenceEvalueringPage_UnderForventetNiveau());
                    else if (score > 2 && score <= 3)
                        return new ScoreValues("#f3ea3f", Localizer.kompetenceEvalueringPage_ForventetNiveau());
                    else if (score > 3 && score <= 4)
                        return new ScoreValues("#c4de38", Localizer.kompetenceEvalueringPage_OverForventetNiveau());
                    else if (score > 4 && score <= 5)
                        return new ScoreValues("#91cf30", Localizer.kompetenceEvalueringPage_OverForventetNiveau());
                    else if (score > 5 && score <= 6)
                        return new ScoreValues("#27b01f", Localizer.kompetenceEvalueringPage_OverForventetNiveau());
                    return new ScoreValues("#27b01f", Localizer.kompetenceEvalueringPage_OverForventetNiveau());
                case ScoreTypeEnum.KompetenceEvalueringScore:
                    if (score === 0)
                        return new ScoreValues("#dcdcdc", Localizer.kompetenceEvalueringPage_IkkeBedoemt());
                    else if (score <= 1)
                        return new ScoreValues("#ee4e2f", Localizer.kompetenceEvalueringPage_UnderForventetNiveau());
                    else if (score > 1 && score <= 2)
                        return new ScoreValues("#f19b37", Localizer.kompetenceEvalueringPage_UnderForventetNiveau());
                    else if (score > 2 && score <= 3)
                        return new ScoreValues("#c4de38", Localizer.kompetenceEvalueringPage_ForventetNiveau());
                    else if (score > 3 && score <= 4)
                        return new ScoreValues("#61c128", Localizer.kompetenceEvalueringPage_OverForventetNiveau());
                    else if (score > 4 && score <= 5)
                        return new ScoreValues("#27b01f", Localizer.kompetenceEvalueringPage_OverForventetNiveau());
                    return new ScoreValues("#27b01f", Localizer.kompetenceEvalueringPage_OverForventetNiveau());
            }
        }


        if (props.svarVaerdi !== undefined) {
            setScoreValue(getScoreValues(props.svarVaerdi));
            setScoreState(props.svarVaerdi);
        }
        else if (props.svarmuligheder) {
            const svarVaerdi = props.svarmuligheder.find(x => x.id === props.svar?.svarmulighedId)?.svarVaerdi;
            setScoreValue(getScoreValues(svarVaerdi ?? 0));
            setScoreState(svarVaerdi);
        }
    }, [isMobile, props.scoreType, props.svar?.svarmulighedId, props.svarVaerdi, props.svarmuligheder])


    return (
        <>
            {scoreValue &&
                <div className="flex-align-c padding-tbr-s">
                    <Tooltip
                        customStyle={{backgroundColor: scoreValue.scoreColor}}
                        className={`${props.withDecimal ? "wide-square" : "square"} ${props.hasComment ? "with-comment" : ""}`}
                        title={showToolTip ? props.hasComment : ""}
                        dataHtml={showToolTip ? "true" : "false"}
                    >
                        {props.withDecimal ? scoreState?.toFixed(1) : scoreState}
                    </Tooltip>

                    {props.showText &&
                        <p className="subtle small">
                            {scoreValue.scoreText}
                        </p>
                    }
                </div>
            }
        </>
    );
}
