import React from "react";
import {SvarInputTypeProps} from "./svarInputTypeCompareMap";
import {Datepicker} from "../../../core/components/datePicker/datePicker";
import {Localizer} from "../../../infrastructure/localization/localizer";

export function DatoInputSvar ({spoergsmaal, svarNummerIndex, kompetenceSvarList, indsendt, dateCallback}: SvarInputTypeProps) {
    return (
        <Datepicker
            id={spoergsmaal.id + svarNummerIndex}
            key={spoergsmaal.id + svarNummerIndex}
            additionalInputClasses={"margin-bottom-m"}
            label={Localizer.evalueringPage_angivDatoFelt()}
            defaultValue={kompetenceSvarList.find(x => x.svarIndex === svarNummerIndex)?.dateSvar}
            allowClear
            disabled={indsendt}
            setDate={(date: Date) => dateCallback(svarNummerIndex, date)}
        />
    )
}
