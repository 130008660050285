import {Localizer} from "../../../infrastructure/localization/localizer";
import React from "react";
import {EvalueringsstatistikPageConstants} from "./EvalueringsstatistikPageConstants";

interface NumberOfEvalueringerProps {
    antalForloeb: number | undefined | null;
    antalForloebEvalueret: number | undefined | null;
}


export function NumberOfEvalueringer(props: NumberOfEvalueringerProps){

    const antalForloebEvalueringerErOverMinimum = props.antalForloebEvalueret !== undefined && props.antalForloebEvalueret !== null && props.antalForloebEvalueret >= EvalueringsstatistikPageConstants.minimumAmountOfEvalueringer;
    const antalForloebErOverMinimum = props.antalForloeb !== undefined && props.antalForloeb !== null && props.antalForloeb >= EvalueringsstatistikPageConstants.minimumAmountOfEvalueringer;
    if (antalForloebErOverMinimum && !antalForloebEvalueringerErOverMinimum) {
        return <>
            {Localizer.evalueringPage_underTreEvalueringer()}{Localizer.evalueringPage_udAf()} {props.antalForloeb} {Localizer.evalueringPage_mulige()}
        </>
    }
    else if (antalForloebEvalueringerErOverMinimum && antalForloebErOverMinimum) {
        return <>
            {props.antalForloebEvalueret}&nbsp; {Localizer.global_af()} &nbsp;{props.antalForloeb}
        </>
    } else if (props.antalForloebEvalueret === null) {
        return <>
            {Localizer.evalueringPage_underTreEvalueringer()}
        </>
    } else {
        return <>
            {Localizer.evalueringPage_ingenEvalueringer()}
        </>
    }
}
