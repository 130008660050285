import React, { useEffect } from "react";
import {AccountService} from "../../services/account/accountService";

const userManager = new AccountService();

export const Impersonate = () => {
    
    useEffect(() => {
        const handleImpersonate = () => {
            localStorage.clear();
            userManager.Renew();
            userManager.SignInRedirect();
        }
        handleImpersonate();
    }, []);

    return <></>
}
