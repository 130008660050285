import React, {useState} from "react";
import useEffectAsync from "../../../infrastructure/effect";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {Loading} from "../../../core/components/loading/loading";
import {ValidationInput} from "../../../core/components/validation/components/validationInput";
import {nameof} from "ts-simple-nameof";
import {CrudBeskaeftigelseInputModel} from "./crudBeskaeftigelseInputModel";
import {ValidationDatepicker} from "../../../core/components/validation/components/validationDatePicker";
import {FormikProps} from "formik";

export interface BeskaeftigelseCrudFieldsProps {
    modalId: string;
    formik: FormikProps<CrudBeskaeftigelseInputModel>;
}

export function BeskaeftigelseCrudFields(props: BeskaeftigelseCrudFieldsProps) {
    const [isLoading, setIsLoading] = useState(true)

    useEffectAsync(async () => {
        setIsLoading(false);
    }, [])

    const render = (
        <Loading isLoading={isLoading} text={Localizer.global_beskaeftigelse()}>
                <ValidationInput
                    model={{
                        label: Localizer.beskaeftigelse_stillingsbetegnelse(),
                        placeholder: Localizer.beskaeftigelse_angivStillingsbetegnelse(),
                        htmlName: nameof<CrudBeskaeftigelseInputModel>(x => x.stillingsbetegnelse)
                    }}
                    inputId={nameof<CrudBeskaeftigelseInputModel>(x => x.stillingsbetegnelse) + props.modalId}
                    formikProps={props.formik}
                />
                <ValidationInput
                    model={{
                        label: Localizer.stillingsnummer(),
                        placeholder: Localizer.beskaeftigelse_angivStillingsnummer(),
                        htmlName: nameof<CrudBeskaeftigelseInputModel>(x => x.stillingsnummer)
                    }}
                    inputId={nameof<CrudBeskaeftigelseInputModel>(x => x.stillingsnummer) + props.modalId}
                    formikProps={props.formik}
                />
                <ValidationInput
                    model={{
                        label: Localizer.beskaeftigelse_ansaettelsessted(),
                        placeholder: Localizer.beskaeftigelse_angivAnsaettelsessted(),
                        htmlName: nameof<CrudBeskaeftigelseInputModel>(x => x.ansaettelsessted)
                    }}
                    inputId={nameof<CrudBeskaeftigelseInputModel>(x => x.ansaettelsessted) + props.modalId}
                    formikProps={props.formik}
                />
                <ValidationInput
                    model={{
                        label: Localizer.afdeling(),
                        placeholder: Localizer.afdelingPlaceholder(),
                        htmlName: nameof<CrudBeskaeftigelseInputModel>(x => x.afdeling)
                    }}
                    inputId={nameof<CrudBeskaeftigelseInputModel>(x => x.afdeling) + props.modalId}
                    formikProps={props.formik}
                />
                <ValidationDatepicker
                    model={{
                        label: Localizer.startdato(),
                        placeholder: Localizer.startdatoPlaceholder(),
                        htmlName: nameof<CrudBeskaeftigelseInputModel>(x => x.startdato)
                    }}
                    allowClear={true}
                    inputId={nameof<CrudBeskaeftigelseInputModel>(x => x.startdato) + props.modalId}
                    formikProps={props.formik}
                />
                <ValidationDatepicker
                    model={{
                        label: Localizer.slutdato(),
                        placeholder: Localizer.slutdatoPlaceholder(),
                        htmlName: nameof<CrudBeskaeftigelseInputModel>(x => x.slutdato)
                    }}
                    allowClear={true}
                    inputId={nameof<CrudBeskaeftigelseInputModel>(x => x.slutdato) + props.modalId}
                    formikProps={props.formik}
                />
                <ValidationInput
                    model={{
                        label: Localizer.beskaeftigelse_maaneder(),
                        placeholder: Localizer.beskaeftigelse_angivMaaneder(),
                        htmlName: nameof<CrudBeskaeftigelseInputModel>(x => x.maaneder)
                    }}
                    inputId={nameof<CrudBeskaeftigelseInputModel>(x => x.maaneder) + props.modalId}
                    formikProps={props.formik}
                />
                <ValidationInput
                    model={{
                        label: Localizer.beskaeftigelse_ugentligtTimetal(),
                        placeholder: Localizer.beskaeftigelse_AngivUgentligtTimetal(),
                        htmlName: nameof<CrudBeskaeftigelseInputModel>(x => x.nedsatTidAntalTimer)
                    }}
                    inputId={nameof<CrudBeskaeftigelseInputModel>(x => x.nedsatTidAntalTimer) + props.modalId}
                    formikProps={props.formik}
                    customValueTransform={(value) => value.toString().replace(",", ".")}
                />
        </Loading>

    )
    return <>{render}</>;
}
