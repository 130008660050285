import { DatetimePickerTypeEnum } from 'ditmer-embla';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DefaultDatePickerType, ToggleDatePickerFocus } from '../datePicker';
import JQueryDatePickerHiddenInputField from './JqueryDatePickerInputFields';
import { switchCharOnIndexWithTheOneBefore, toDateString, removeNonDigitsKeepSlashes, removeSlashes, removeNonDigitsKeepColon, removeColons, toTimeString, hasValuesAfterIndex } from '../helpers/dateInputHelpers';
import { ddMMyyyyRegex, digitsBetweenRegex, hhmmRegex } from '../helpers/regex';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import { ProcessValidationInput } from 'core/components/validation/ValidationInputHelper';
import { todayDayjs } from 'index';

type DateInputState = {
    value: string
    cursorPosition: number
}

export type DateFormikField = {
    field: FieldInputProps<Date>;
    meta: FieldMetaProps<Date>;
    helpers: FieldHelperProps<Date>;
};

export type DateInputProps = {
    id: string;
    defaultValue?: string;
    formName?: string;
    htmlName?: string;
    placeholderText?: string;
    className?: string;
    disabled?: boolean;
    datePickerType?: DatetimePickerTypeEnum;
    formikField?: DateFormikField;
    allowClear?: boolean;
    clearAction: () => void;
}

const defaultCursorPosition = 0;
const slashPositionIndexes = [2, 5];
const colonPositionIndexes = [2];
const maxAmountCharsInDateString = 8;
const maxAmountCharsInDateStringEdgeCase = 11;
const maxAmountCharsInTimeStringEdgeCase = 7;
const maxAmountCharsInTimeString = 4;
const amountExpectedMatchOutputs = 3;

const getCursorPosition = (cursorPosition: number, shouldIncrement:boolean=false, excludeSlashPosIndexes:boolean=false) => {
    const slashPosIncremention = slashPositionIndexes.includes(cursorPosition) && !excludeSlashPosIndexes ? 1 : 0;
    return cursorPosition + slashPosIncremention + (shouldIncrement ? 1 : 0);
}

const getFormMetaClasses = (formMeta: FieldMetaProps<Date>) => {
    return formMeta.error && formMeta.touched ? ProcessValidationInput(formMeta.error).IsWarning ? "input-warning" : "input-validation-error" : "";
}

const DateInput = ({id, clearAction, placeholderText="", defaultValue="", className="", formName, htmlName, formikField, disabled=false, datePickerType=DefaultDatePickerType, allowClear=false}: DateInputProps) => {
    const [isKeyPressedBackspace, setIsKeyPressedBackspace] = useState(false);
    const [inputState, setInputState] = useState<DateInputState>({cursorPosition: defaultCursorPosition, value: defaultValue});
    const inputRef = useRef<HTMLInputElement>(null);

    const hiddenSelector = `#${id}`;
    const shouldUseFormikField = !!formName;
    const formMetaClasses = shouldUseFormikField && !!formikField?.meta ? getFormMetaClasses(formikField.meta) : "";
    const isDateType = datePickerType === DatetimePickerTypeEnum.Date;

    const formatNonMatchingDateInput = useCallback((str: string, cursorPosition: number, isSlashRemoved: boolean) => {

        if(!str || str.length < maxAmountCharsInDateString) {
            setInputState((_) => {
                const amountChars = str.length;
                const shouldAddSlash = slashPositionIndexes.includes(amountChars) && !isSlashRemoved;
                return {
                    cursorPosition: cursorPosition + (shouldAddSlash ? 1 : 0),
                    value: shouldAddSlash
                        ? `${str.slice(0, amountChars)}/${str.slice(amountChars)}`
                        : str,
                };
            });
            return;
        }

        // When the string is empty and a date is picked from the calendar,
        // the datapickermodule returns 2 times, once with the format, and once with a date().ToString().
        const isDatePickerNonFormattedDate = str.length > maxAmountCharsInDateStringEdgeCase;
        if(isDatePickerNonFormattedDate) return;

        const isNextCharSlash = str[cursorPosition] === '/';
        const stringToMatch = isNextCharSlash ? switchCharOnIndexWithTheOneBefore(str, cursorPosition) : str;

        const match = stringToMatch.match(digitsBetweenRegex);
        if (!match) return;
        if (match.length < amountExpectedMatchOutputs) {
            setInputState((prev) => ({...prev, cursorPosition: getCursorPosition(cursorPosition)}));
            return;
        }

        const [day, month, year] = match;
        const dd = day.slice(0, 2);
        const mm = month.slice(0, 2);
        const yyyy = year.slice(0, 4);

        setInputState({
            cursorPosition: getCursorPosition(cursorPosition, isNextCharSlash),
            value: toDateString(dd, mm, yyyy)
        });
    }, []);

    const formatNonMatchingTimeInput = useCallback((str: string, cursorPosition: number, isColonRemoved: boolean) => {
        if(!str || str.length < maxAmountCharsInTimeString) {
            setInputState((_) => {
                const amountChars = str.length;
                const shouldAddColon = colonPositionIndexes.includes(amountChars) && !isColonRemoved;
                return {
                    cursorPosition: cursorPosition + (shouldAddColon ? 1 : 0),
                    value: shouldAddColon ? toTimeString(str.slice(0, amountChars), str.slice(amountChars)) : str,
                };
            });
            return;
        }

        // When the string is empty and a date is picked from the calendar,
        // the datapickermodule returns 2 times, once with the format, and once with a date().ToString().
        const isTimePickerNonFormattedDate = str.length > maxAmountCharsInTimeStringEdgeCase;
        if(isTimePickerNonFormattedDate) return;

        const isNextCharColon = str[cursorPosition] === ':';
        const stringToMatch = isNextCharColon ? switchCharOnIndexWithTheOneBefore(str, cursorPosition) : str;

        const match = stringToMatch.match(digitsBetweenRegex);
        if (!match) return;

        const [hours, minutes] = match;
        const hh = hours.slice(0, 2);
        const mm = minutes.slice(0, 2);

        setInputState({
            cursorPosition: getCursorPosition(cursorPosition, isNextCharColon),
            value: toTimeString(hh, mm)
        });
    }, []);

    const handleTimeInput = useCallback((timeString: string) => {
        const cleanedInput = removeNonDigitsKeepColon(timeString);
        if(!removeColons(cleanedInput)) {
            setInputState((prev) => ({...prev, value: ""}));
            return;
        }

        const pos = inputRef.current?.selectionStart ?? defaultCursorPosition;

        const isColonRemoved = removeColons(inputState.value) === cleanedInput;
        if(isColonRemoved && !isKeyPressedBackspace) {
            setInputState((prev) => ({...prev, cursorPosition: pos}));
            return;
        }

        const cursorPosition = pos - Math.abs(timeString.length - cleanedInput.length);

        const match = cleanedInput.match(hhmmRegex);
        if(!match) {
            formatNonMatchingTimeInput(cleanedInput, cursorPosition, isColonRemoved);
        } else {
            const [, hours, minutes] = match;
            setInputState({
                cursorPosition: getCursorPosition(cursorPosition),
                value: toTimeString(hours, minutes)
            });
        }
    }, [formatNonMatchingTimeInput, inputState.value, isKeyPressedBackspace]);

    const handleDateInput = useCallback((dateString: string) => {
        const cleanedInput = removeNonDigitsKeepSlashes(dateString);
        const cleanedInputWithoutSlashes = removeSlashes(cleanedInput);
        if(!cleanedInputWithoutSlashes) {
            setInputState((prev) => ({...prev, value: ""}));
            return;
        }

        const pos = inputRef.current?.selectionStart ?? defaultCursorPosition;
        const cursorPosition = pos - Math.abs(dateString.length - cleanedInput.length);

        const isSlashRemoved = removeSlashes(inputState.value) === cleanedInputWithoutSlashes;
        if(isSlashRemoved) {
            if(!isKeyPressedBackspace) {
                setInputState((prev) => ({...prev, cursorPosition: pos}));
                return;
            }
            if (hasValuesAfterIndex(cleanedInput, cursorPosition)) {
                setInputState((prev) => ({...prev, cursorPosition: getCursorPosition(cursorPosition, true, true)}));
                return;
            }
        }

        const match = cleanedInput.match(ddMMyyyyRegex);
        if(!match) {
            formatNonMatchingDateInput(cleanedInput, cursorPosition, isSlashRemoved);
        } else {
            const [, day, month, year] = match;
            setInputState({
                cursorPosition: getCursorPosition(cursorPosition, false, cleanedInput?.slice(0, 2) === "//"),
                value: toDateString(day, month, year)
            });
        }
    }, [formatNonMatchingDateInput, inputState.value, isKeyPressedBackspace]);

    const handleInputChange = useCallback((input: string) => {
        if(isDateType) {
            handleDateInput(input)
        } else {
            handleTimeInput(input)
        }
    }, [handleDateInput, handleTimeInput, isDateType]);

    useEffect(() => {
        const handleTouched = () => {
            if(shouldUseFormikField) {
                if(!formikField?.meta.touched && !disabled && !!formikField?.field.value) {
                    // The Dateinput field features an overlaying input, resulting in the Formik touched state remaining unaltered, as the standard datepicker popover functionality is not employed.
                    formikField?.helpers.setTouched(true);
                }
            }
        }
        handleTouched()
    }, [disabled, formikField?.field.value, formikField?.helpers, formikField?.meta.touched, shouldUseFormikField])

    useEffect(() => {
        const $picker = $(hiddenSelector);
        
        const onChangeAction = (e: JQuery.ChangeEvent) => {
            handleInputChange(e.currentTarget.value)
        }

        $picker.on("change", onChangeAction);
        
        return () => {
            $picker.off("change", onChangeAction);
        }
    }, [handleInputChange, hiddenSelector]);

    useEffect(() => {
        inputRef.current?.setSelectionRange(inputState.cursorPosition, inputState.cursorPosition);
    }, [inputState.cursorPosition]); // IMPORTANT: Causes infinite loop on safari, when dependency is "inputState" (possible because "setSelectionRange" triggers "onBlur" on safari)

    return (
        <>
            <input
                ref={inputRef}
                id="date-input"
                type="text"
                className={`form-control ${className} ${formMetaClasses}`}
                placeholder={placeholderText}
                value={inputState.value}
                onKeyDown={(e) => setIsKeyPressedBackspace(e.key === "Backspace")}
                onChange={(e) => handleInputChange(e.target.value)}
                onMouseDown={() => ToggleDatePickerFocus(id, true)}
                onBlur={() => {
                    let newValue = inputState.value;
                    const hasInput = !!inputState.value;
                    if(hasInput) {
                        if(isDateType) {
                            const match = inputState.value.match(ddMMyyyyRegex)!;
                            if(!match) {
                                newValue = defaultValue;
                            } else {
                                const [, day, month, year] = match;
                                newValue = toDateString(day, month, year, "01", ["00"], todayDayjs().year().toString());
                            }
                        } else {
                            const match = inputState.value.match(hhmmRegex)!;
                            if(!match) {
                                newValue = defaultValue;
                            } else {
                                const [, hours, min] = match;
                                newValue = toTimeString(hours, min);
                            }
                        }
                    }

                    if(!allowClear && !hasInput) {
                        newValue = defaultValue;
                    }

                    if(allowClear && !hasInput) {
                        clearAction();
                    }

                    setInputState((prev) => ({...prev, value: newValue}));

                    // Triggering the underlaying JQuery Mobiscroll DatePickerModule input.
                    $(hiddenSelector).val(newValue).trigger("change");
                    ToggleDatePickerFocus(id, false);
                }}
                disabled={disabled}
            />

            <JQueryDatePickerHiddenInputField
                id={id}
                defaultValue={defaultValue}
                formName={formName}
                useFormik={shouldUseFormikField}
                disabled={disabled}
            />
        </>
    );
};

export default DateInput;
