import React, {useState} from 'react'
import {setSlideinState, SlideInStateEnum} from "../../../core/components/slideIn/slideInSlice";
import {useDispatch} from "react-redux";
import {SlideIn} from "../../../core/components/slideIn/slideIn";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {EditNotat} from "../../../core/componentsPage/notat/tekstNotat/editNotat";
import {NotatType} from "../../../core/sharedmodels/notat/notatType";
import {BasicNotatModel} from "../../../core/sharedmodels/notat/basicNotatModel";
import {NotatCard} from "../../../core/componentsPage/notat/notatCard";

type NotatListProps = {
    notater: BasicNotatModel[];
    notesUpdatedCallback: () => void;
    filesUpdatedCallback: () => void;
}

export const NotatList = ({notater, notesUpdatedCallback, filesUpdatedCallback}: NotatListProps) => {

    const dispatch = useDispatch();

    const [editNotatFilesHasChanged, setEditNotatFilesHasChanged] = useState(false);
    const [selectedNotat, setSelectedNotat] = useState<BasicNotatModel | undefined>(undefined);
    const editNotatModalTarget = "notatListEditNotat";
    const [editModalOpen, setEditModalOpen] = useState(false);

    const clickNotat = (notat: BasicNotatModel) => {
        setEditModalOpen(true);
        setSelectedNotat(notat);
        dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: editNotatModalTarget}))
    }

    const onCloseModal = () => {
        if (editNotatFilesHasChanged) {
            filesUpdatedCallback();
            setEditNotatFilesHasChanged(false);
        }

        setEditModalOpen(false);
    }

    return (
        <div className="row">
            <div className="offset-lg-3 col-lg-6" >
                {notater &&
                    notater?.map((notat, i) => {
                        return (
                            <React.Fragment key={notat.id}>
                                <div className="notat-card-container">
                                    <div className="card">
                                        <NotatCard
                                            editType={NotatType.TekstNotat}
                                            notat={notat}
                                            clickEditNotatCallBack={() => clickNotat(notat)}
                                            enableGoToKompetenceOnPillClick
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    })
                }

                {selectedNotat && editModalOpen && selectedNotat.type === NotatType.TekstNotat &&
                    <SlideIn
                        key={selectedNotat.id}
                        id={editNotatModalTarget}
                        title={Localizer.notatPage_editNotat()}
                        actionText={Localizer.notatPage_editingNotat()}
                        bodyContent={<EditNotat modalId={editNotatModalTarget} notat={selectedNotat} key={selectedNotat.id} onFilesUpdated={() => setEditNotatFilesHasChanged(true)}/>}
                        actionFinishedCallback={notesUpdatedCallback}
                        actionOnCloseCallback={onCloseModal}
                        defaultOpen={true}
                    />
                }
            </div>
        </div>
    )
}
