import {Localizer} from "infrastructure/localization/localizer";
import React from "react";
import {SearchField} from "../../../../../core/components/search/searchField";

type SearchFieldType = {
    callbackLaege: (search: string) => void;
}

const SearchFieldsSynkroniseredeForloeb = ({callbackLaege}: SearchFieldType) => {
    return (
        <div className="flex-container margin-right-s">
            <SearchField
                minimumInput={3}
                placeholder={Localizer.soegLaege()}
                callback={(search) => callbackLaege(search)}
            />
        </div>
    );
}

export default SearchFieldsSynkroniseredeForloeb;
