import { DropdownOption } from "core/components/dropdown/dropdown";
import { useState } from "react";
import { Localizer } from "infrastructure/localization/localizer";
import BrevTekstMergeFields, { BrevTekstMergeFieldsType, allBrevTekstMergeFieldArray } from "../shared/brevTekstMergeFieldsType";

const translateBrevMergeField = (type: BrevTekstMergeFieldsType) => {
    switch (type) {
        case BrevTekstMergeFields.UserName:
            return Localizer.brevMergeField_tekst_UserName();
        case BrevTekstMergeFields.UserEmail:
            return Localizer.brevMergeField_tekst_UserEmail();
        case BrevTekstMergeFields.Addresse:
            return Localizer.brevMergeField_tekst_Addresse();
        case BrevTekstMergeFields.Cpr:
            return Localizer.brevMergeField_tekst_Cpr();
        case BrevTekstMergeFields.DagsDato:
            return Localizer.brevMergeField_tekst_DagsDato();
        case BrevTekstMergeFields.FuldeNavn:
            return Localizer.brevMergeField_tekst_FuldeNavn();
        case BrevTekstMergeFields.TelefonNr:
            return Localizer.brevMergeField_tekst_TelefonNr();
        case BrevTekstMergeFields.DitNavn:
            return Localizer.brevMergeField_tekst_DitNavn();
        case BrevTekstMergeFields.ArbejdsMail:
            return Localizer.brevMergeField_tekst_ArbejdsMail();
        case BrevTekstMergeFields.PrivatMail:
            return Localizer.brevMergeField_tekst_PrivatMail();
        case BrevTekstMergeFields.SidsteForloeb:
            return Localizer.brevMergeField_tekst_SidsteForloeb();
        case BrevTekstMergeFields.SenderEmail:
            return Localizer.brevMergeField_tekst_SenderEmail();
        default:
            return "";
    }
};

const dropdownOptions: DropdownOption<string>[] = allBrevTekstMergeFieldArray.map(x => ({ label: translateBrevMergeField(x), value: x }));

const useBrevTekstMergeFieldDropdownOptions = (initialOption?: BrevTekstMergeFieldsType) => {

    const [selectedOption, setSelectedOption] = useState<BrevTekstMergeFieldsType | undefined>(initialOption);

    return {
        selectedOption,
        setSelectedOption,
        dropdownOptions
    }
}

export default useBrevTekstMergeFieldDropdownOptions;