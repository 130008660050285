import {Localizer} from "../../../infrastructure/localization/localizer";
import React, {useEffect, useState} from "react";
import {setSlideinState, SlideInStateEnum} from "../../../core/components/slideIn/slideInSlice";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {HttpClientService} from "../../../services/httpClient/httpClientService";
import {
    evalueringsstatistikReducer,
    EvalueringsstatistikState,
    setDataset, setLastChangedAt,
    setShowMessage
} from "../evalueringsstatistikSlice";
import {toState} from "../evalueringsstatistikModel";
import {EvalueringStatistikApi} from "../../../services/api/evalueringStatistik/evalueringStatistikApi";
import {DatasetModel} from "../datasetModel";
import {
    applyFilters,
    evalueringsstatistikFiltersReducer,
    EvalueringsstatistikFiltersState
} from "../evalueringsstatistikFiltersSlice";
import {EvalueringsstatistikFilterModel, filterToState} from "../evalueringsstatistikFilterModel";
import {
    GetUddannelsestypeTranslation,
    UddannelseTypeEnum
} from "../../../core/sharedmodels/stilling/uddannelseTypeEnum";
import {ValidationDatepicker} from "../../../core/components/validation/components/validationDatePicker";
import {nameof} from "ts-simple-nameof";
import {HandleError} from "../../../core/infrastructure/errors/errorBoundary";
import {ValidationForm} from "../../../core/components/validation/components/validationForm";
import {
    BuildInitial,
    BuildNew,
    EvalueringsstatistikInputModel,
    evalueringsstatistikValidationSchema
} from "../../../core/componentsPage/evalueringsstatistik/evalueringsstatistikInputModel";
import {EvalueringsstatistikPageConstants} from "./EvalueringsstatistikPageConstants";
import {EmblaIcon} from "../../../core/components/emblaIcon/emblaIcon";
import {SpoergsmaalTypeEnum} from "../../../core/sharedmodels/evaluering/spoergsmaalTypeEnum";
import {EvalueringStatistikModel} from "../../../services/api/evalueringStatistik/evalueringStatistikReponseModel";
import {addTotalAverage, getColor} from "../evalueringsstatistikUtils";
import {toReduxIsoDateString} from "../../../core/helpers/reduxHelper";
import {FileDownloadHandler} from "../../../services/api/logbogFile/fileDownloadHandler";
import Tooltip from "core/components/tooltips/Tooltip";
import useEnumDropdownOptions from "core/components/dropdown/hooks/useEnumDropdownOptions";
import { Dropdown, DropdownOption } from "core/components/dropdown/dropdown";
import useUser from "core/hooks/useUser";
import {IdAndNameModel} from "../../../core/sharedmodels/IdAndNameModel";

type AddFilteringDatasetProps = {
    modalId: string;
    fortryd: () => void;
    setIsLoading: (loading: boolean) => void;
}

const evalueringStatistikApi = new EvalueringStatistikApi(new HttpClientService(), new FileDownloadHandler());

const AddFilteringDatasetFormId = 'AddFilteringDatasetForm';

export function AddFilteringDataset(props: AddFilteringDatasetProps) {
    const [specialerDropdownOptions, setSpecialerDropdownOptions] = useState<DropdownOption<string>[]>([]);
    const [uddannelsestypeDropdownOptions] = useEnumDropdownOptions<UddannelseTypeEnum>(UddannelseTypeEnum, GetUddannelsestypeTranslation);

    const [datasetTitle, setDatasetTitle] = useState("");
    const dispatch = useAppDispatch();
    const evalueringsstatistikSliceState = useAppSelector(evalueringsstatistikReducer) as EvalueringsstatistikState;
    const evalueringsstatistikFilterSliceState = useAppSelector(evalueringsstatistikFiltersReducer) as EvalueringsstatistikFiltersState;
    const { currentUser } = useUser();

    const uddannelsesStederValgtAmount = evalueringsstatistikFilterSliceState.uddannelsessteder?.filter(x => x.selected)?.length ?? 0;

    useEffect(() => {
        const getDropdownOptions = async () => {
            const specialerSelectModels = await evalueringStatistikApi.getSpecialerSelect();
            setSpecialerDropdownOptions(specialerSelectModels.map((s) => ({ label: s.text, value: s.id })));
        }
        getDropdownOptions();
    }, []);


    const addDataset = async (values: EvalueringsstatistikInputModel) => {
        props.setIsLoading(true);
        dispatch(applyFilters(filterToState({startDato: values.startDato})));
        dispatch(applyFilters(filterToState({slutDato: values.slutDato})));

        const requestModel = BuildNew(evalueringsstatistikFilterSliceState, values.startDato, values.slutDato);
        const evalueringStatistik = (currentUser.Authenticated && EvalueringsstatistikPageConstants.getComplexView)
            ? await evalueringStatistikApi.getStatistik(requestModel)
            : await evalueringStatistikApi.getStatistikPublic(requestModel);

        let uddannelsessteder = evalueringsstatistikFilterSliceState.uddannelsessteder ? evalueringsstatistikFilterSliceState.uddannelsessteder.filter(x => x.selected) : [];

        const firstEvalueringStatistik = evalueringStatistik.first<EvalueringStatistikModel>();

        let dataset = new DatasetModel(
            evalueringStatistik,
            uddannelsessteder,
            getColor([...evalueringsstatistikSliceState.dataset].length, evalueringsstatistikSliceState.dataset),
            firstEvalueringStatistik?.maxAntalEvalueringer,
            datasetTitle,
            values.startDato?.toIsoDateTimeString(),
            values.slutDato?.toIsoDateTimeString(),
            evalueringsstatistikFilterSliceState.specialer?.map(x => x.name).sort((a, b) => a.localeCompare(b)),
            evalueringsstatistikFilterSliceState.uddannelsestyper?.map(x => x.id),
            evalueringsstatistikFilterSliceState.uddannelsestyper?.map(x => x.name),
            evalueringsstatistikFilterSliceState.specialer?.map(x => x.id) ?? undefined,
            addTotalAverage(evalueringStatistik, SpoergsmaalTypeEnum.Enkeltvis),
            addTotalAverage(evalueringStatistik, SpoergsmaalTypeEnum.Grupperet),
            values.includeInactive,
            false,
            evalueringsstatistikFilterSliceState.regioner?.map(x => x.name).sort((a, b) => a.localeCompare(b)),
            evalueringsstatistikFilterSliceState.sygehuse?.map(x => x.name).sort((a, b) => a.localeCompare(b)),
            evalueringsstatistikFilterSliceState?.laegeinstitutionstype,
            false,
            firstEvalueringStatistik?.antalForloebEvalueretTotal,
        );

        dispatch(setLastChangedAt(toReduxIsoDateString(new Date())));
        let datasets: DatasetModel[] = [...evalueringsstatistikSliceState.dataset];
        datasets.push(dataset);
        dispatch(setShowMessage(true));
        dispatch(setDataset(toState({dataset: datasets})));
        props.setIsLoading(false);
        dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: props.modalId}));
    }

    const uddannelsestypeSelected = (uddannelsestyper?: UddannelseTypeEnum[]) => {
        dispatch(applyFilters({uddannelsestyper: uddannelsestypeDropdownOptions.map(x => ({ id: x.value.toString(), name: GetUddannelsestypeTranslation(x.value) } as IdAndNameModel)).filter(x => uddannelsestyper?.includes(x.id.toInt()))}));
    }

    const specialeSelected = (specialeIds?: string[]) => {
        dispatch(applyFilters({specialer: specialerDropdownOptions.map(x => ({ id: x.value, name: x.label } as IdAndNameModel)).filter(x => specialeIds?.includes(x.id)),}));
    }

    return <>
        <ValidationForm
            id={AddFilteringDatasetFormId}
            key={props.modalId}
            initialValues={BuildInitial(evalueringsstatistikFilterSliceState)}
            validationSchema={evalueringsstatistikValidationSchema}
            onSubmit={async (values) => {
                await addDataset(values)
                    .catch(err => {
                        HandleError(err);
                        dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: props.modalId}))
                    });
            }}>
            {
                (formik) => (
                <>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card padding-m font-weight-bolder ">
                                {uddannelsesStederValgtAmount} {Localizer.uddannelsesstedCountText(uddannelsesStederValgtAmount).toLowerCase()}
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="d-flex flex-row">
                                <h5>{Localizer.createEvalueringsstatistikPage_Tidsperiode()}</h5>
                                <Tooltip className="text-truncate" title={Localizer.createEvalueringsstatistikPage_TidsperiodeBeskrivelse()}>
                                    <EmblaIcon additionalClasses="margin-left-xs subtle small" iconName={"info"}/>
                                </Tooltip>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <ValidationDatepicker
                                model={{
                                    label: Localizer.startdato(),
                                    placeholder: Localizer.startdatoPlaceholder(),
                                    htmlName: nameof<EvalueringsstatistikFilterModel>(x => x.startDato)
                                }}
                                allowClear={true}
                                inputId={nameof<EvalueringsstatistikFilterModel>(x => x.startDato) + props.modalId}
                                formikProps={formik}
                            />
                        </div>
                        <div className="col-sm-6">
                            <ValidationDatepicker
                                model={{
                                    label: Localizer.slutdato(),
                                    placeholder: Localizer.slutdatoPlaceholder(),
                                    htmlName: nameof<EvalueringsstatistikFilterModel>(x => x.slutDato)
                                }}
                                allowClear={true}
                                inputId={nameof<EvalueringsstatistikFilterModel>(x => x.slutDato) + props.modalId}
                                formikProps={formik}
                            />
                        </div>
                        <div className="col-sm-6">
                            <label>{Localizer.global_uddannelsetype()}</label>
                            <Dropdown
                                placeholder={Localizer.global_all()}
                                options={uddannelsestypeDropdownOptions}
                                onChange={(newVal) => uddannelsestypeSelected(newVal!.map((option) => option.value))}
                                value={uddannelsestypeDropdownOptions.filter(x => evalueringsstatistikFilterSliceState.uddannelsestyper?.map(x => x.id)?.includes(x.value.toString()))}
                                isMulti
                            />
                        </div>
                        <div className="col-sm-6">
                            <label>{Localizer.global_speciale()}</label>
                            <Dropdown
                                placeholder={Localizer.global_vaelgspecialer()}
                                options={specialerDropdownOptions}
                                onChange={(newVal) => specialeSelected(newVal.map((option) => option.value))}
                                value={specialerDropdownOptions.filter(x => evalueringsstatistikFilterSliceState?.specialer?.map(x => x.id)?.includes(x.value))}
                                isMulti
                            />
                        </div>

                        <div className="col-sm-12 margin-top-m margin-bottom-m">
                            <h5>{Localizer.createEvalueringsstatistikPage_TitelPaaDataset()}</h5>
                            <label className="font-weight-bolder"
                                   htmlFor="datasetTitle">{Localizer.global_title()}</label>
                            <input type="text"
                                   name="datasetTitle"
                                   className="form-control"
                                   value={datasetTitle}
                                   onChange={(e) => setDatasetTitle(e.target.value)}
                                   placeholder="Angiv titel"/>
                        </div>
                    </div>
                </>
                )
            }
        </ValidationForm>
    </>
}

type AddDatasetFilteringActionsProps = {
    modalId: string;
    back: () => void;
}

export const AddDatasetFilteringActions = ({back} : AddDatasetFilteringActionsProps) => {
    return (
        <>
            <button className="btn btn-default margin-right-s" onClick={back}>
                {Localizer.global_back()}
            </button>
            <button className="btn btn-primary margin-right-s" form={AddFilteringDatasetFormId} type="submit">
                {Localizer.global_add()}
            </button>
        </>
    );
}
