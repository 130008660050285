import React, {useCallback, useEffect, useState} from "react";
import {useAppSelector} from "../../../app/hooks";
import {forloebReducer, ForloebSliceState} from "../../../core/forloebSlice";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {Loading} from "../../../core/components/loading/loading";
import {ForloebOverviewCard} from "./forloebOverviewCard";
import {useRouteMatch} from "react-router-dom";
import useLogbogApi from "../../../core/hooks/useLogbogApi";
import {NotatType} from "../../../core/sharedmodels/notat/notatType";
import {BasicNotatModel} from "../../../core/sharedmodels/notat/basicNotatModel";
import {FileMetadataList} from "../../../core/components/fileMetadata/fileMetadataList";
import {FileLocationEnum} from "../../../core/sharedmodels/file/fileLocationEnum";
import useUser from "../../../core/hooks/useUser";
import {FileMetadata} from "../../../core/sharedmodels/fileMetaData/fileMetaData";

type UddannelsesprogrammerOverviewProps = {
    showRightHeaderContent?: boolean
}

export function UddannelsesprogrammerOverview({showRightHeaderContent = false}: UddannelsesprogrammerOverviewProps) {
    const {notatApi} = useLogbogApi();
    const [isLoading, setIsLoading] = useState(true);
    const [uddannelsesprogramNotater, setUddannelsesprogramNotater] = useState<BasicNotatModel[]>();
    const [fileMetaData, setFileMetaData] = useState<FileMetadata[]>();

    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;
    const routeMatch = useRouteMatch();
    const uddannelseslaegeId = (routeMatch.params as { id: string }).id;

    const {currentUser} = useUser();
    const rolesWithAccess = currentUser.IsAdminOrVus() || currentUser.IsKonsulentForLaegeligVidereuddannelse() || currentUser.IsUddannelsesansvarlig() || currentUser.IsLaege();

    const infoModalId = "ForloebOverviewUddannelsesprogramCardInfoModal";

    const loadUddannelsesprogrammer = useCallback(async () => {
        const uddannelsesprogrammer = await notatApi.getNotaterOnContext(NotatType.Uddannelsesprogram, uddannelseslaegeId, forloebSliceState.forloebState.brugerMaalbeskrivelseId);
        setUddannelsesprogramNotater(uddannelsesprogrammer);
        setFileMetaData([...uddannelsesprogrammer?.flatMap(x => x.fileMetadatas)]);
    }, [forloebSliceState.forloebState.brugerMaalbeskrivelseId, notatApi, uddannelseslaegeId]);

    useEffect(() => {
        setIsLoading(true);
        const fetch = async () => {
            await loadUddannelsesprogrammer();
        }
        fetch().then(() => setIsLoading(false));
    }, [loadUddannelsesprogrammer, forloebSliceState.forloebState]);


    return (
        <ForloebOverviewCard
            paddingNone={true}
            title={Localizer.uddannelsesprogram()}
            infoModal={{text: Localizer.forloebpage_uddannelsesprogramInfo(), id: infoModalId}}>
            <Loading isLoading={isLoading} text={Localizer.forloebpage_getUddannelsesprogrammer()}
                     spinnerClasses={"padding-l"}>
                <div className="margin-m">
                    <FileMetadataList
                        showAsTableIdentifier={"uddannelsesprogrammerFileTable"}
                        filesUpdatedCallback={loadUddannelsesprogrammer}
                        fileMetadatas={fileMetaData ?? []}
                        showDeleteButton={rolesWithAccess}
                        notatInfo={[{
                            contextId: forloebSliceState.forloebState.brugerMaalbeskrivelseId,
                            notatType: NotatType.Uddannelsesprogram,
                            userId: uddannelseslaegeId,
                            isVisibleToEveryone: true
                        }]}
                        notat={uddannelsesprogramNotater}
                        withUploader={rolesWithAccess}
                        fileLocation={FileLocationEnum.Logbog}
                        hideCreatedBy={false}
                        withDownload
                        showFileType
                        showAsCards
                    />
                </div>
            </Loading>
        </ForloebOverviewCard>
    );
}
