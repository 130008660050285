import {Field, ErrorMessage, useField } from "formik";
import React from "react";
import {ValidationInputProps} from "../validationModel";
import { Caption } from "core/components/caption/caption";

type GenericValidationInputProps = {
    /** function for transforming input-value (both whats shown and whats in formik-state) */
    customValueTransform?: (value: any) => any;
} & ValidationInputProps & React.HTMLProps<HTMLInputElement>;

export const ValidationInput = ({
    customValueTransform, 
    ...props
}: GenericValidationInputProps) => {
    const [field, meta] = useField(props.model.htmlName);

    const onChange = (e: React.ChangeEvent<any>) => {
        e.target.value = (!!customValueTransform && !!e.target.value) ? customValueTransform(e.target.value) : e.target.value;
        field.onChange(e);
    }

    return (
        <>
            <div className="form-group margin-bottom-m">
                <label htmlFor={field.name}>{props.model.label}</label>

                <Field
                    name={field.name}
                    value={field.value}
                    className={`form-control ${meta.error && meta.touched ? "input-validation-error" : ""}`}
                    placeholder={props.model.placeholder}
                    disabled={props.readOnly}
                    type={props.type}
                    onChange={onChange}
                />

                {props.captionText &&
                    <Caption text={props.captionText} showIcon={false}/>
                }

                <ErrorMessage name={field.name}>
                    {errMsg => <span className="errorMessage field-validation-error">{errMsg}</span>}
                </ErrorMessage>
            </div>
        </>
    );
}
