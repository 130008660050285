import React from "react";
import {
    UserProfileAvatar,
    UserProfileAvatarSizeEnum,
    UserProfileLayoutEnum
} from "../../../core/components/userProfileAvatar/userProfileAvatar";
import {KompetenceEvalueringModel} from "../../../core/sharedmodels/evaluering/kompetenceEvalueringModel";
import {UserProfileAvatarModel} from "../../../core/components/userProfileAvatar/userProfileAvatarModel";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {ActionIcon} from "../../../core/components/actionIcon/actionIcon";
import {RoutePaths} from "../../../infrastructure/routes";
import {
    kompetenceCardReducer,
    KompetenceCardState,
    setLaegekompetenceEvalueringCount
} from "../cards/kompetenceCardSlice";
import {useHistory} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../../../core/layout/responsive";
import useUser from "../../../core/hooks/useUser";
import {EmblaIcons} from "../../../core/emblaIcons";

export class CompletedEvalueringCardProps {
    kompetenceEvaluering: KompetenceEvalueringModel;
    kompetenceId: string;
    deleteKompetenceEvalueringCallback: (laegekompetenceEvalueringId: string) => void;
}
export const spoergsmaalCount = (evalueringModel: KompetenceEvalueringModel) => {
    const sektionerIncludedInProgress = evalueringModel.sektioner.filter(s => s.includedInProgress);
    const result = sektionerIncludedInProgress.reduce((counts, sektion) => {
        counts.spoergsmaalCount += sektion.spoergsmaal.length;
        counts.svarCount += sektion.spoergsmaal.filter(spoergsmaal => spoergsmaal.evalueringSvar.length > 0).length;
        return counts;
    }, { spoergsmaalCount: 0, svarCount: 0 });

    return `${result.svarCount}/${result.spoergsmaalCount}`;
}

export function KladdeKompetenceEvalueringCard({kompetenceEvaluering, kompetenceId, deleteKompetenceEvalueringCallback}: CompletedEvalueringCardProps) {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const isMobile = useMediaQuery(mobileMaxWidthQuery);
    const kompetenceCardState = useAppSelector(kompetenceCardReducer) as KompetenceCardState;
    const { currentUser } = useUser();

    const deleteKompetenceEvaluering = async (kompetenceEvalueringRelationId: string) => {
        deleteKompetenceEvalueringCallback(kompetenceEvalueringRelationId);
        dispatch(setLaegekompetenceEvalueringCount(kompetenceCardState.laegekompetenceEvalueringCount - 1))
    }

    return (
        <div className="margin-top-m margin-bottom-m flex-container wrap">
            <div className="flex-column">
                <h5 className="subtle">{Localizer.KompetenceVurderingKladde()}</h5>
                <p className="subtle">{`${spoergsmaalCount(kompetenceEvaluering)} ${Localizer.evalueringPage_questionsAnswered()}`}</p>
                {(kompetenceEvaluering.createdByUser) &&
                    <div className="d-flex wrap">
                        <h5 className="subtle">{Localizer.kompetencePage_evalueringOprettetAf(kompetenceEvaluering.createdByUser?.firstName + " " + kompetenceEvaluering.createdByUser?.lastName)} &nbsp;</h5>
                        <UserProfileAvatar
                            size={UserProfileAvatarSizeEnum.extraSmall}
                            subtle={true}
                            userProfileLayoutEnum={UserProfileLayoutEnum.AsTextOnRightSide}
                            userProfileAvatarModel={new UserProfileAvatarModel(kompetenceEvaluering.createdByUser?.firstName + " " + kompetenceEvaluering.createdByUser?.lastName, kompetenceEvaluering.createdByUser.initialer)}
                        />
                    </div>
                }
            </div>
            {(currentUser.IsVejleder() || currentUser.IsUddannelsesansvarlig() || currentUser.IsAdminOrVus()) &&
                <div className={`${isMobile ? "margin-top-m" : "flex-align-r"} d-flex flex-align-c`}>
                    {kompetenceEvaluering.createdBy === currentUser.UserId &&
                        <ActionIcon addtionalClassNames="margin-right-m"
                                    iconName={EmblaIcons.Delete}
                                    action={() => deleteKompetenceEvaluering(kompetenceEvaluering.laegekompetenceEvalueringId)}/>
                    }
                    <button className={"btn btn-default "}
                            disabled={kompetenceEvaluering.createdBy !== currentUser.UserId}
                            onClick={() => history.push(RoutePaths.EditKompetenceEvaluering([kompetenceEvaluering.laegekompetenceEvalueringId, kompetenceId]).url)}>
                        {Localizer.global_fortsaet()}
                    </button>
                </div>
            }
        </div>
    );
}
