import React, {useCallback, useEffect, useState} from 'react'
import {Localizer} from "../../../infrastructure/localization/localizer";
import {UddannelsesstedModel} from "../../../core/sharedmodels/laegeInstitution/uddannelsesstedModel";
import {UddannelsesstedItem} from "./uddannelsesstedItem";
import {setSlideinState, SlideInStateEnum} from "../../../core/components/slideIn/slideInSlice";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {filterToState} from "../evalueringsstatistikFilterModel";
import {EmblaIcon} from "../../../core/components/emblaIcon/emblaIcon";
import "../evalueringstatistik.scss";
import {
    applyFilters,
    clearFilters,
    clearLaegeinstitutionFilter,
    evalueringsstatistikFiltersReducer,
    EvalueringsstatistikFiltersState,
    handleUddannelsesstederSelectOptionChanged,
    setAllToggled,
    uddannelsesstedToggle,
} from "../evalueringsstatistikFiltersSlice";
import {evalueringsstatistikReducer, EvalueringsstatistikState, setShowMessage} from "../evalueringsstatistikSlice";
import {RootTypeEnum} from "../../../core/sharedmodels/laegeInstitution/rootTypeEnum";
import {CheckboxSwitch} from "../../../core/components/checkbox/checkboxSwitch";
import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../../../core/layout/responsive";
import {Loading} from "../../../core/components/loading/loading";
import Tooltip from 'core/components/tooltips/Tooltip';
import {SearchField} from "../../../core/components/search/searchField";
import {Dropdown, DropdownOption} from 'core/components/dropdown/dropdown';
import useEnumDropdownOptions from 'core/components/dropdown/hooks/useEnumDropdownOptions';
import useRegionerDropdownOptions, {
    allDanishRegionsKey,
    allRegionsKey
} from 'core/components/dropdown/hooks/useRegionerDropdownOptions';
import {IdAndNameModel} from "../../../core/sharedmodels/IdAndNameModel";
import {toDropdownOptions} from "../../../core/sharedmodels/forSelectModel";
import useLogbogApi from 'core/hooks/useLogbogApi';
import { MultiValue } from 'react-select';

type AddDatasetUddannelsesstederProps = {
    modalId: string;
}

export function AddDatasetUddannelsessteder({modalId}: AddDatasetUddannelsesstederProps) {
    const [isLoading, setIsLoading] = useState(true);
    const { regionerDropdownOptions, transformSelectedOptionsToRegionIds, allRegionIds} = useRegionerDropdownOptions();
    const [sygehuseDropdownOptions, setSygehuseDropdownOptions] = useState<DropdownOption<string>[]>([]);
    const [laegeinstitutionsTypeSelectOptions] = useEnumDropdownOptions<RootTypeEnum>(RootTypeEnum);
    const [search, setSearch] = useState<string | undefined>();
    const evalueringsstatistikFilterSliceState = useAppSelector(evalueringsstatistikFiltersReducer) as EvalueringsstatistikFiltersState;
    const evalueringsstatistikSliceState = useAppSelector(evalueringsstatistikReducer) as EvalueringsstatistikState;
    const dispatch = useAppDispatch();
    const IsMobile = useMediaQuery(mobileMaxWidthQuery);

    const [omraadeDropdownOptions, setOmraadeDropdownOptions] = useState<DropdownOption<string>[]>([]);

    const { evalueringstatistikApi } = useLogbogApi();

    const getSygehuseDropdownOptions = useCallback(async (): Promise<void> => {
        if (evalueringsstatistikFilterSliceState.laegeinstitutionstype !== RootTypeEnum.Sygehus) return;

        const sygehuse = await evalueringstatistikApi.getSygehuseSelect(evalueringsstatistikFilterSliceState.regioner?.map(x => x.id) ?? [], evalueringsstatistikFilterSliceState.includeInactive);
        setSygehuseDropdownOptions(toDropdownOptions(sygehuse));
    }, [evalueringsstatistikFilterSliceState.includeInactive, evalueringsstatistikFilterSliceState.laegeinstitutionstype, evalueringsstatistikFilterSliceState.regioner, evalueringstatistikApi]);
    
    const getOmraadeDropdownOptions = useCallback(async () => {
        if (evalueringsstatistikFilterSliceState.laegeinstitutionstype !== RootTypeEnum.Praksis) return [];
        
        const omraader = await evalueringstatistikApi.getOmraaderForSelect(evalueringsstatistikFilterSliceState.regioner?.map(x => x.id) ?? [], evalueringsstatistikFilterSliceState.includeInactive);
        setOmraadeDropdownOptions(toDropdownOptions(omraader));
    }, [evalueringsstatistikFilterSliceState.includeInactive, evalueringsstatistikFilterSliceState.laegeinstitutionstype, evalueringsstatistikFilterSliceState.regioner, evalueringstatistikApi]);

    useEffect(() => {
        const initializeDropdownOptions = async () => {
            await getSygehuseDropdownOptions();
            await getOmraadeDropdownOptions();

            setIsLoading(false);
        };

        initializeDropdownOptions();
    }, [IsMobile, getOmraadeDropdownOptions, getSygehuseDropdownOptions])


    useEffect(() => {
        // Uddannelsessteder
        const getUddannelsessteder = async (regioner?: string[], omraader?: string[], sygehuse?: string[]): Promise<void> => {
            let afdelingerselect = []
            let afdelingerList: UddannelsesstedModel[] = [];

            if (evalueringsstatistikFilterSliceState.laegeinstitutionstype === RootTypeEnum.Sygehus || evalueringsstatistikFilterSliceState.laegeinstitutionstype === undefined) {
                afdelingerselect = await evalueringstatistikApi.getAfdelingerSelect(regioner ?? [], sygehuse ?? [], evalueringsstatistikFilterSliceState.includeInactive);
                afdelingerList = UddannelsesstedModel.FromSelectModel(afdelingerselect);
            }

            let praksisSelect = [];
            let praksisList: UddannelsesstedModel[] = [];
            if (evalueringsstatistikFilterSliceState.laegeinstitutionstype === RootTypeEnum.Praksis || evalueringsstatistikFilterSliceState.laegeinstitutionstype === undefined) {
                praksisSelect = await evalueringstatistikApi.getPraksisSelect(regioner ?? [], omraader ?? [], evalueringsstatistikFilterSliceState.includeInactive);
                praksisList = UddannelsesstedModel.FromSelectModel(praksisSelect);
            }

            let uddannelsessteder = [...afdelingerList, ...praksisList];
            let uddannelserSelectOptions: UddannelsesstedModel[] | undefined;

            if (search !== undefined && search !== "")
                uddannelserSelectOptions = uddannelsessteder.filter(x => x.navn.toLowerCase().includes(search.toLowerCase()));

            dispatch(handleUddannelsesstederSelectOptionChanged(uddannelserSelectOptions ?? uddannelsessteder));
        }

        (async () => {
            await getUddannelsessteder(
                evalueringsstatistikFilterSliceState?.regioner?.map(x => x.id),
                evalueringsstatistikFilterSliceState?.omraader?.map(x => x.id),
                evalueringsstatistikFilterSliceState?.sygehuse?.map(x => x.id)
            );
        })()
    }, [dispatch, evalueringsstatistikFilterSliceState.includeInactive, evalueringsstatistikFilterSliceState.laegeinstitutionstype, evalueringsstatistikFilterSliceState?.omraader, evalueringsstatistikFilterSliceState?.regioner, evalueringsstatistikFilterSliceState?.sygehuse, evalueringstatistikApi, search])

    // On Change Handlers:
    const handleRegionerSelected = async (selectedOptionKeys?: string[]) => {
        const selectedRegionIdsResult = transformSelectedOptionsToRegionIds(selectedOptionKeys);
        dispatch(applyFilters(
                {
                    alleRegioner: selectedRegionIdsResult.allRegions,
                    regioner: regionerDropdownOptions.map(x => ({ id: x.value, name: x.label } as IdAndNameModel)).filter(x => selectedRegionIdsResult.regionIds?.includes(x.id)),
                }
        ));

    }

    const handleLaegeinstitutionSelected = async (selectedOption?: RootTypeEnum) => {
        if (selectedOption === RootTypeEnum.Sygehus) {
            dispatch(applyFilters(filterToState({laegeinstitutionstype: RootTypeEnum.Sygehus})));
            await getSygehuseDropdownOptions();
        } else if (selectedOption === RootTypeEnum.Praksis) {
            dispatch(applyFilters(filterToState({laegeinstitutionstype: RootTypeEnum.Praksis})));
            setSygehuseDropdownOptions([]);
        } else {
            dispatch(clearLaegeinstitutionFilter());
            setSygehuseDropdownOptions([]);
        }
    }

    const handleSygehuseSelected = (sygehusIds?: string[]) => {
        dispatch(applyFilters({sygehuse: sygehuseDropdownOptions.map(x => ({id: x.value, name: x.label} as IdAndNameModel)).filter(x => sygehusIds?.includes(x.id))}));
    }

    const handleOmraadeSelected = (newValue: MultiValue<DropdownOption<string>>) => {
        dispatch(applyFilters({
            omraader: newValue.map(x => ({id: x.value, name: x.label}))
        }));
    }

    const handleIncludeInactiveSwitched = () => {
        dispatch(applyFilters(filterToState({includeInactive: !evalueringsstatistikFilterSliceState.includeInactive})));
    }

    const setSelectedUddannelsesstedItems = (toggleUddannelsessted: UddannelsesstedModel) => {
        dispatch(uddannelsesstedToggle(toggleUddannelsessted))
    }

    const clearFilter = async () => {
        dispatch(setShowMessage(false));
        dispatch(clearFilters());
    }

    const allUddannelsesstederSelected = () => {
        dispatch(setAllToggled());
    }

    const showExistingFilteringDialog = () => {
        if (evalueringsstatistikSliceState.showMessage) {
            return (
                <div className="alert alert-info" role="alert">
                    <div className="flex-space">
                        <div className="padding-m">
                            {Localizer.createEvalueringsstatistikPage_searchSavedInfo()}. <br/>
                            <button className="btn btn-primary shadow-none small margin-top-m"
                                    onClick={() => clearFilter()}>
                                {Localizer.createEvalueringsstatistikPage_startOver()}
                            </button>
                        </div>
                        <div className="margin-top-s">
                            <button className="btn" onClick={() => dispatch(setShowMessage(false))}>
                                <EmblaIcon iconName={"close"}/>
                            </button>
                        </div>
                    </div>
                </div>
            )
        }
    }

    const isRegionerSelected = (!!evalueringsstatistikFilterSliceState.regioner && evalueringsstatistikFilterSliceState.regioner.length > 0);
    const isSelectedLaegeInstitutionSygehus = evalueringsstatistikFilterSliceState.laegeinstitutionstype === RootTypeEnum.Sygehus;
    const isSygehusDropdownEnabled = isRegionerSelected && isSelectedLaegeInstitutionSygehus;
    const isOmraadeDropdownEnabled = isRegionerSelected && evalueringsstatistikFilterSliceState.laegeinstitutionstype === RootTypeEnum.Praksis;
    
    return (
        <div>
            <Loading isLoading={isLoading} text={Localizer.global_getFilters()}>
                {showExistingFilteringDialog()}
                <div className="margin-bottom-l">
                    <div className="row margin-bottom-m">
                        <div className="col-sm-12">
                            <label className="font-weight-bolder">{Localizer.global_regioner()}</label>
                            <Dropdown
                                placeholder={Localizer.forloebOverviewPage_chooseRegions()}
                                options={regionerDropdownOptions}
                                onChange={(newVal) => handleRegionerSelected(newVal!.map((option) => option.value))}
                                value={regionerDropdownOptions.filter((r) => evalueringsstatistikFilterSliceState.regioner?.map(x => x.id).includes(r.value))}
                                closeMenuOnOptions={[allDanishRegionsKey, allRegionsKey]}
                                isMulti
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <label className="font-weight-bolder">{Localizer.laegeinstitution()}</label>
                            <Dropdown
                                placeholder={Localizer.vaelgLaegeinstitution()}
                                options={laegeinstitutionsTypeSelectOptions}
                                onChange={(newVal) => handleLaegeinstitutionSelected(newVal?.value)}
                                value={laegeinstitutionsTypeSelectOptions.filter((r) => evalueringsstatistikFilterSliceState.laegeinstitutionstype === r.value)}
                                isDisabled={!isRegionerSelected}
                                isClearable
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-sm-6">
                            <label className="font-weight-bolder">{Localizer.sygehuse()}</label>
                            <Dropdown
                                placeholder={Localizer.createEvalueringsstatistikPage_chooseSygehuse()}
                                options={sygehuseDropdownOptions}
                                onChange={(newVal) => handleSygehuseSelected(newVal!.map((option) => option.value))}
                                value={sygehuseDropdownOptions.filter(x => isSygehusDropdownEnabled).filter((r) => evalueringsstatistikFilterSliceState.sygehuse?.map(x => x.id).includes(r.value))}
                                isDisabled={!isSygehusDropdownEnabled}
                                isMulti
                            />

                        </div>
                        <div className="col-sm-6">
                            <label className="font-weight-bolder">{Localizer.forloebOverviewPage_omraade()}</label>
                            <Dropdown 
                                options={omraadeDropdownOptions}
                                onChange={handleOmraadeSelected} 
                                value={omraadeDropdownOptions.filter(x => isOmraadeDropdownEnabled).filter(x => evalueringsstatistikFilterSliceState.omraader?.map(y => y.id).includes(x.value))}
                                placeholder={Localizer.forloebOverviewPage_chooseOmraade()}
                                isDisabled={!isOmraadeDropdownEnabled}
                                isMulti
                            />
                        </div>
                    </div>

                </div>
                <div className="flex-align-c margin-bottom-m">
                    <div className="input-icon-container flex-fill ">
                        <SearchField
                            additionalClasses='margin-right-s'
                            callback={(e) => setSearch(e)}
                            placeholder={Localizer.createEvalueringsstatistikPage_searchUddannelsessted()}
                            disabled={evalueringsstatistikFilterSliceState.regioner === undefined || evalueringsstatistikFilterSliceState.regioner.length === 0}
                        />
                    </div>
                    <Tooltip className="margin-left-m" title={Localizer.evalueringsstatistikPage_includeInactive()}>
                        <CheckboxSwitch
                            id={"forloeboverview-advancedsearch-switch"}
                            label={Localizer.global_inactive()}
                            onChange={() => handleIncludeInactiveSwitched()}
                            value={evalueringsstatistikFilterSliceState.includeInactive}/>
                    </Tooltip>
                </div>
                <div className="border-top border-bottom padding-top-m padding-bottom-m padding-left-xs">
                    <input type="checkbox"
                           id="uddannelsesStederCheckbox"
                           checked={evalueringsstatistikFilterSliceState.allChecked}
                           onChange={() => allUddannelsesstederSelected()}/>
                    <label
                        className="font-weight-bolder"
                        htmlFor="uddannelsesStederCheckbox">
                            {`${Localizer.uddannelsessteder()} (${evalueringsstatistikFilterSliceState.uddannelsessteder?.length})`}
                         
                    </label>
                </div>
                {evalueringsstatistikFilterSliceState.uddannelsessteder &&
                    <div className={`hide-overflow overflow-auto ${IsMobile && evalueringsstatistikFilterSliceState.uddannelsessteder.length > 0 ? "uddannelsessteder-min-height-mobile" : ""}`}>
                        <div>
                            {
                                evalueringsstatistikFilterSliceState.uddannelsessteder?.map((uddannelsessted, index) => {
                                    return (
                                        <React.Fragment key={uddannelsessted.id + index}>
                                            <UddannelsesstedItem
                                                uddannelsessted={uddannelsessted}
                                                itemSelected={(uddannelsessted) => setSelectedUddannelsesstedItems(uddannelsessted)}
                                                selected={uddannelsessted.selected}
                                            />
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    </div>
                }
            </Loading>
        </div>
    )
}

type AddDatasetUddannelsesstederActionsProps = {
    modalId: string;
    next: () => void;
};

export const AddDatasetUddannelsesstederActions = ({modalId, next} : AddDatasetUddannelsesstederActionsProps) => {
    const dispatch = useAppDispatch();
    const evalueringsstatistikFilterSliceState = useAppSelector(evalueringsstatistikFiltersReducer) as EvalueringsstatistikFiltersState;

    const closeModal = () => dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: modalId}));

    const uddannelsesStederValgtAmount = evalueringsstatistikFilterSliceState.uddannelsessteder?.filter(x => x.selected)?.length ?? 0;

    return (
        <>
            <div className="margin-left-m flex-container">
                {`${uddannelsesStederValgtAmount} ${Localizer.createEvalueringsstatistikPage_StedValgtText(uddannelsesStederValgtAmount)}`}  
            </div>
            <div>
                <button className="btn btn-default margin-right-s" onClick={closeModal}>
                    {Localizer.global_fortryd()}
                </button>
                <button className="btn btn-primary margin-right-s" onClick={next}
                        disabled={uddannelsesStederValgtAmount === 0}>
                    {Localizer.global_next()}
                </button>
            </div>
        </>
    );
};
