type HeaderProps = {
    titleText: string;
    boldTitleText?: string;
}

const Header = ({titleText, boldTitleText} : HeaderProps) : JSX.Element => {
    return (
        <p className={"custom-tooltip-title-question"}>
            <span className={"custom-tooltip-title-number"}>
                {boldTitleText ?? ""}
            </span>
            {titleText}
        </p>
    );
}

type ItemProps = {
    index: number;
    color: string;
    text:string;
}

const Item = ({index, color, text} : ItemProps) : JSX.Element => {
    return (
        <p key={index} className={"custom-tooltip-dataset"} style={{"--dataset-color": color} as React.CSSProperties}>
            {text}
        </p>
    );
}

type CustomTooltipContentDefaultProps = {

}

type CustomTooltipContentProps = React.PropsWithChildren<CustomTooltipContentDefaultProps>;

const CustomTooltipContent = (props: CustomTooltipContentProps) => {
    return (
        <div className={"custom-tooltip"}>
            {props.children}
        </div>
    );
}

export default Object.assign(
    CustomTooltipContent,
    {
        Header,
        Item
    },
);
