import {HttpClientService} from "services/httpClient/httpClientService";
import config from "config/config";
import {ApiResponse} from "services/api/apiResponse"
import {AccountService} from "../../account/accountService";
import {SkiftMaalbeskrivelseInputModel} from "../../../core/componentsPage/forloeb/skiftMaalbeskrivelseInputModel";
import {SkiftMaalbeskrivelsePostModel} from "./skiftMaalbeskrivelsePostModel";

export class MaalbeskrivelseApi {
    private httpClientService: HttpClientService;
    private userService: AccountService;

    private baseUrl = () => config.logbogApiUrl + "api/maalbeskrivelse/";
    private skiftMaalbeskrivelseUrl = () => this.baseUrl() + "skiftMaalbeskrivelse/";

    constructor(httpClientService: HttpClientService, userService: AccountService) {
        this.httpClientService = httpClientService;
        this.userService = userService;
    }

    //creates brugermaalbeskrivelse
    async skiftMaalbeskrivelse(inputModel: SkiftMaalbeskrivelseInputModel): Promise<string> {
        const postModel = new SkiftMaalbeskrivelsePostModel(inputModel);
        const url = this.skiftMaalbeskrivelseUrl();
        const result = await this.httpClientService.Post<ApiResponse<string>, SkiftMaalbeskrivelsePostModel>(url, postModel);
        return result.data;
    }
}
