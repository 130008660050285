import jsPDF from "jspdf";
import { renderToString } from "react-dom/server";

// https://artskydj.github.io/jsPDF/docs/index.html
// Per incremented 20 in targetWidthForPdfDocument increment windowWidthInCssPixels by 25.
const targetWidthForPdfDocument = 210;
const windowWidthInCssPixels = 700;
const margin = { top: 10, bot: 10, left: 0, right: 0}
const splitOnTextAutoPaging = "text";

const generatePdfFromJsxElement = async (filename: string, element: JSX.Element) => {
    const pdf = new jsPDF();
    const templateContainingElement = document.createElement('div');
    templateContainingElement.innerHTML = renderToString(element);

    pdf.html(templateContainingElement, {
        callback: () => pdf.save(`${filename}.pdf`, {returnPromise: true}),
        width: targetWidthForPdfDocument,
        windowWidth: windowWidthInCssPixels,
        margin: [margin.top, margin.left, margin.bot, margin.right],
        autoPaging: splitOnTextAutoPaging, 
    });
}

export default generatePdfFromJsxElement;