import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {LogbogForloebEvalueringApi} from "../../services/api/logbogEvaluering/logbogForloebEvalueringApi";
import {HttpClientService} from "../../services/httpClient/httpClientService";
import {EvalueringModel} from "../../core/sharedmodels/evaluering/evalueringModel";
import {LineChart, LineChartColor, LineChartItem} from "../../core/components/charts/lineChart";
import {EvalueringSektion} from "./evaluering/evalueringSektion";
import {Localizer} from "../../infrastructure/localization/localizer";
import {SpoergsmaalModel} from "../../core/sharedmodels/evaluering/spoergsmaalModel";
import {RoutePaths} from "../../infrastructure/routes";
import {NotificationModule} from "ditmer-embla";
import {useAppSelector} from "../../app/hooks";
import {forloebReducer, ForloebSliceState, pingRefreshForloeb} from "../../core/forloebSlice";
import {useDispatch} from "react-redux";
import {ForloebEvalueringModel} from "../../core/sharedmodels/evaluering/forloebEvalueringModel";
import {ModalAcceptType, ModalSubmitMessage} from "../../core/components/modalMessage/modalSubmitMessage";
import {UserSimpleModel} from "../../core/sharedmodels/user/userSimpleModel";
import {Form, Formik} from "formik";
import {
    crudForloebevalueringHovedvejlederValidationSchema,
    HovedvejlederInputModel
} from "./evaluering/HovedvejlederInputModel";
import {SvarModel} from "../../core/sharedmodels/evaluering/svarModel";
import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../../core/layout/responsive";
import {ScoreTypeEnum} from "../../core/componentsPage/score/scoreTypeEnum";
import {EvalueringCollapse} from "../../core/components/Tabs/evalueringCollapse";
import {ScoreModel} from "../../core/sharedmodels/evaluering/scoreModel";

const evalueringApi = new LogbogForloebEvalueringApi(new HttpClientService());

export interface EvalueringProps {
    forloebEvaluering: ForloebEvalueringModel;
    uddannelsesansvarlige?: UserSimpleModel[];
}

export function Evaluering(props: EvalueringProps) {
    const godkendModalId = "afdelingEvalueringGodkend";
    const IsMobile = useMediaQuery(mobileMaxWidthQuery);

    const dispatch = useDispatch();
    const history = useHistory();
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    const [forloebEvaluering] = useState<ForloebEvalueringModel>(props.forloebEvaluering);
    const [sektionIndexState, setSektionIndexState] = useState<number>();

    const [totalQuestions, setTotalQuestions] = useState(0);
    const [hovedvejleder, setHovedvejleder] = useState<string>();

    const [expandedTabId, setExpandedTabId] = useState<string>();

    useEffect(() => {
        const updateProgress = () => {
            let questions = extractQuestions(forloebEvaluering.evaluering);
            setTotalQuestions(questions.length);
        }

        updateProgress();

        const firstUnansweredSektionIndex = forloebEvaluering.evaluering.sektioner.findIndex(s => s.spoergsmaal.find(sp => !questionIsAnswered(sp)));
        const isThereAnyAnsweredQuestions = forloebEvaluering.evaluering.sektioner.findIndex(s => s.spoergsmaal.find(sp => questionIsAnswered(sp))) > 0;

        if (isThereAnyAnsweredQuestions) {
            if (firstUnansweredSektionIndex >= 0)
                setSektionIndexState(firstUnansweredSektionIndex)
            else
                setSektionIndexState(forloebEvaluering.evaluering.sektioner.length - 1);
        } else {
            setSektionIndexState(0)
        }
    }, [forloebEvaluering])

    const extractQuestions = (evaluering: EvalueringModel) => {
        return evaluering?.sektioner?.flatMap(s => s.spoergsmaal.filter(x => !x.isFeedback));
    }

    const questionIsAnswered = (question: SpoergsmaalModel) => {
        return question.svar?.svarmulighedId !== undefined || question.svar?.ikkeRelevant || question.svar?.tekstSvar !== undefined;
    }

    const generateChartItems = (): LineChartItem[] => {
        let sektionCount = forloebEvaluering.evaluering.sektioner.filter(x => x.spoergsmaal.length > 0).length;

        return [
            {count: sektionIndexState ?? 0, color: LineChartColor.success},
            {count: sektionCount - (sektionIndexState ?? 0), color: LineChartColor.gray},
        ];
    }

    const indsendEvaluering = async () => {
        forloebEvaluering.indsendt = true;
        let indsendt = await evalueringApi.patchForloebEvalueringSvar(forloebEvaluering.forloebEvalueringId, forloebEvaluering.indsendt, [], hovedvejleder, props.uddannelsesansvarlige);

        if (indsendt) {
            NotificationModule.showSuccess(Localizer.evalueringPage_forloebEvalueringIndsendt(forloebSliceState.forloebState.laegeInstitutionName, forloebSliceState.forloebState.afdelingName), "");
            history.push(RoutePaths.Forloeb([forloebSliceState.forloebState.brugerId]).url)
            dispatch(pingRefreshForloeb);
        }
    }

    const setHovedvejlederState = (inputModel: HovedvejlederInputModel) => {
        setHovedvejleder(inputModel.hovedvejleder);
    }

    const handleOpenSection = (sektionIndexState: number, id: string) => {
        setSektionIndexState(sektionIndexState);
        setExpandedTabId(id)
    }

    const GetSpoergsmaalScore = (spoergsmaal: SpoergsmaalModel) => {
        const newObject: ScoreModel = {
            scoreValue: spoergsmaal?.svarmuligheder?.find(s => s.id === spoergsmaal?.svar?.svarmulighedId)?.svarVaerdi,
            notRelevant: spoergsmaal?.svar?.ikkeRelevant ?? false,
            allQuestionsHasAtleastOneAnswer: false
        };
        return newObject;
    }

    const render = (
        <Formik initialValues={new HovedvejlederInputModel()}
                validationSchema={crudForloebevalueringHovedvejlederValidationSchema}
                onSubmit={(values: HovedvejlederInputModel) => {
                    setHovedvejlederState(values)
                }}>
            {
                (formik) => (
                    <Form>
                        <div key={forloebEvaluering.evaluering.evalueringId}>
                            <div className="card shadow-none sticky-top">
                                <div className="card-header justify-content-center">
                                    <div className={`${IsMobile ? "padding-m" : "padding-l"} w-75 text-center`}
                                         key={forloebEvaluering.evaluering.sektioner?.flatMap(s => s.spoergsmaal).map(s => s.svar?.id).join()}>
                                        <LineChart items={generateChartItems()} hideLabels={true}/>
                                        {forloebEvaluering.evaluering.sektioner[sektionIndexState ?? 0].spoergsmaal.flatMap(x => x.isFeedback).filter(f => f).length === 0 &&
                                            <p>{sektionIndexState}/{totalQuestions} {Localizer.global_questions()}</p>
                                        }
                                    </div>
                                </div>
                            </div>

                            {props.forloebEvaluering.evaluering.sektioner.map((sektion, index) => (
                                <div className={"card card-small-margin margin-bottom-s shadow-sm"} key={index}>
                                    <EvalueringCollapse additionalClasses={"flex-row"}
                                                        id={sektion.id}
                                                        key={sektion.id}
                                                        title={sektion.spoergsmaal.length === 1 ? sektion.spoergsmaal[0].titel : sektion.title}
                                                        expanded={expandedTabId === sektion.id || (!expandedTabId && index === sektionIndexState)}
                                                        toggleCallback={(id: string) => handleOpenSection(index, id)}
                                                        score={GetSpoergsmaalScore(sektion.spoergsmaal[0])}
                                                        scoreType={ScoreTypeEnum.ForloebEvalueringScore}
                                                        >
                                        <EvalueringSektion
                                            sektion={sektion}
                                            evaluering={props.forloebEvaluering.evaluering}
                                            answerChosenCallback={(svar: SvarModel) => {
                                                //Dette er lidt et hack efter refakturering af evalueringsflowet - hvor der er forsøgt, at genbruge så meget som muligt fra det tidligere flow.
                                                //Her sætter vi spørgsmål svar, for at opdatere scoreværdien i collapsetab ovenover.
                                                sektion.spoergsmaal[0].svar = svar;
                                                if (props.forloebEvaluering.evaluering.sektioner[index + 1]?.id)
                                                    handleOpenSection(index + 1, props.forloebEvaluering.evaluering.sektioner[index + 1]?.id)
                                            }}
                                            finishSektion={index === forloebEvaluering.evaluering.sektioner.length - 1}
                                            nextButtonClickedCallback={() => {
                                                handleOpenSection(index + 1, props.forloebEvaluering.evaluering.sektioner[index + 1].id)
                                            }}
                                            uddannelsesansvarlige={props.uddannelsesansvarlige}
                                            formik={formik}
                                            setShowIndsendModalCallback={indsendEvaluering}
                                        />
                                    </EvalueringCollapse>
                                </div>
                            ))}
                        </div>
                        <ModalSubmitMessage
                            title={Localizer.evalueringPage_afdelingIndsend()}
                            description={<p>{Localizer.evalueringPage_forloebEvalueringIndsendApprove(forloebSliceState.forloebState.laegeInstitutionName, forloebSliceState.forloebState.afdelingName)}</p>}
                            modalAcceptType={ModalAcceptType.primary}
                            modalId={godkendModalId}
                            acceptAction={indsendEvaluering}
                            acceptButtonText={Localizer.global_indsend()}
                        />
                    </Form>
                )
            }
        </Formik>
    )

    return (
        <>
            {render}
        </>
    );
}
