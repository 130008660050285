import React from "react";
import {
    BuildNew,
    CrudBeskaeftigelseInputModel,
    crudBeskaeftigelseValidationSchema
} from "./beskaeftigelse/crudBeskaeftigelseInputModel";
import {setSlideinState, SlideInStateEnum} from "../../core/components/slideIn/slideInSlice";
import {useAppDispatch} from "../../app/hooks";
import {HttpClientService} from "../../services/httpClient/httpClientService";
import {AccountService} from "../../services/account/accountService";
import {HandleError} from "../../core/infrastructure/errors/errorBoundary";
import {ValidationForm} from "../../core/components/validation/components/validationForm";
import {Localizer} from "../../infrastructure/localization/localizer";
import {ValidationFormSubmitButton} from "../../core/components/validation/components/validationFormButton";
import {BeskaeftigelseCrudFields} from "./beskaeftigelse/beskaeftigelseCrudFields";
import {LogbogBeskaeftigelseApi} from "../../services/api/logbogBeskaeftigelse/logbogBeskaeftigelseApi";
import {useRouteMatch} from "react-router-dom";
import {FileDownloadHandler} from "../../services/api/logbogFile/fileDownloadHandler";
import { userStpsAnsoegnignQueryParams } from "infrastructure/route/queryParams";

export interface AnsoegningCreateBeskaeftigelseProps {
    modalId: string;
}

const beskaeftigelseApi = new LogbogBeskaeftigelseApi(new HttpClientService(), new AccountService(), new FileDownloadHandler())

export function AnsoegningCreateBeskaeftigelse({modalId}: AnsoegningCreateBeskaeftigelseProps) {
    const dispatch = useAppDispatch();
    const routeMatch = useRouteMatch<userStpsAnsoegnignQueryParams>();
   
    const ansoegningId = routeMatch.params.ansoegning;

    const createBeskaeftigelse = async (inputModel: CrudBeskaeftigelseInputModel) => {
        dispatch(setSlideinState({state: SlideInStateEnum.ActionOngoing, slideInId: modalId}))
        inputModel.id = ansoegningId;
        let beskaeftigelseId = await beskaeftigelseApi.createBeskaeftigelse(inputModel);
        if (beskaeftigelseId)
            dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: modalId}))
    }

    const render = (

        <ValidationForm
            key={modalId}
            initialValues={BuildNew(ansoegningId)}
            validationSchema={crudBeskaeftigelseValidationSchema}
            onSubmit={
                async (values) => {
                    await createBeskaeftigelse(values).catch(err => { // catch is needed to handle unhandeled exception (eg. api offline). Formik onsubmithandler is catching all exception and writing them as warnings
                        dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: modalId}))
                        HandleError(err);
                    });
                }}>
            {
                (formik) => (
                    <>
                        <BeskaeftigelseCrudFields modalId={modalId} formik={formik}/>

                        <div className="modal-actions">
                            <ValidationFormSubmitButton formikProps={formik}>
                                {Localizer.tilfoejBeskaeftigelse()}
                            </ValidationFormSubmitButton>
                        </div>
                    </>
                )
            }
        </ValidationForm>
    )
    return <>{render}</>;
}
