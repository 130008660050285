import React, {PropsWithChildren, useEffect, useState} from 'react'
import {FormikProps} from "formik";
import {AlleMedAdgangKey, KlarmeldingInputModel} from "./klarmeldingInputModel";
import {useAppSelector} from "../../../app/hooks";
import {forloebReducer, ForloebSliceState} from "../../../core/forloebSlice";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {nameof} from "ts-simple-nameof";
import {Loading} from "../../../core/components/loading/loading";
import {KlarmeldingModel, KlarmeldingTilBrugerModel} from "../klarmeldingModel";
import useLogbogApi from 'core/hooks/useLogbogApi';
import { ValidationDropdown } from 'core/components/validation/components/validationDropdown';
import { DropdownOption } from 'core/components/dropdown/dropdown';
import { filterDistinctDropdownOptions } from 'core/components/dropdown/dropdownOptions/helpers';
import { RolleTitelEnum } from 'infrastructure/enums/rolleTitelEnum';
import useStamdataApi from "../../../core/hooks/useStamdataApi";

type KlarmeldingCrudFieldsProps = {
    modalId: string,
    formik: FormikProps<KlarmeldingInputModel>,
    model?: KlarmeldingModel,
    editView?: boolean,
    klarmeldToDelkursusledere?: boolean,
    setSubmitIsDisabled?: (isDisabled: boolean) => void;
}

const alleBrugereDropdownOption: DropdownOption<KlarmeldingTilBrugerModel> = {
    label: Localizer.global_all(),
    value: { UserId: AlleMedAdgangKey, RolleTitelEnums: []},
}

const mapToKlarmeldBrugerDropdownOption = (brugerId: string, navn: string, email: string, rolleTitelEnums: RolleTitelEnum[], isFavorite?: boolean): DropdownOption<KlarmeldingTilBrugerModel> => {
    return {
        label: `${navn} - ${email}`,
        value: { UserId: brugerId, RolleTitelEnums: rolleTitelEnums, isFavorite: isFavorite }
    } as DropdownOption<KlarmeldingTilBrugerModel>;
}

export function KlarmeldingCrudFields({
    setSubmitIsDisabled,
    klarmeldToDelkursusledere,
    children,
    ...props
}: PropsWithChildren<KlarmeldingCrudFieldsProps>) {
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    const [klarmeldTilBrugerDropdownOptions, setKlarmeldTilBrugerDropdownOptions] = useState<DropdownOption<KlarmeldingTilBrugerModel>[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const { logbogUserInfoApi } = useLogbogApi();
    const { stamdataUserApi } = useStamdataApi();

    useEffect(() => {
        const setDropdownOptions = (brugereDerKanKlarmeldesTilOptions: DropdownOption<KlarmeldingTilBrugerModel>[]) => {
            const anyBrugereDerKanKlarmeldesTil = brugereDerKanKlarmeldesTilOptions.length > 0;
            const klarmeldTilBrugerOptions = anyBrugereDerKanKlarmeldesTil
                ? [...brugereDerKanKlarmeldesTilOptions, alleBrugereDropdownOption]
                : [];
            if (setSubmitIsDisabled) {
                setSubmitIsDisabled(!anyBrugereDerKanKlarmeldesTil);
            }
            setKlarmeldTilBrugerDropdownOptions(klarmeldTilBrugerOptions);
        }
        const fetchKursusledere = async () => {
            const delkursuslederePaaSpeciale = await stamdataUserApi.getDelkursuslederePaaSpeciale(forloebSliceState.forloebState.specialeId);
            const dropdownOptions = delkursuslederePaaSpeciale.map(x => mapToKlarmeldBrugerDropdownOption(x.userId, x.navn, x.email, x.rolleTitelEnums));
            const brugereDerKanKlarmeldesTilOptions = dropdownOptions.filter(x => x.value.UserId !== forloebSliceState.forloebState.brugerId);
            setDropdownOptions(brugereDerKanKlarmeldesTilOptions)
        }

        const fetchPersonale = async () => {
            //forloebPersonale er brugere som har adgang til det aktuelle forløb, igennem roller og forløbadgang
            const forloebPersonale = await logbogUserInfoApi.getLaegerPersonaleOnForloeb(forloebSliceState.forloebState.id);
            const forloebPersonaleDropdownOptions = forloebPersonale.map(x => mapToKlarmeldBrugerDropdownOption(x.userId, x.navn, x.email, x.rolleTitelEnums, x.isFavorite));

            //Brugere som har tidsbegrænset adgang til forløbet
            const brugereMedTidsbegraensetAdgang = await logbogUserInfoApi.getUsersWithAccessToForloeb(forloebSliceState.forloebState.id, false);
            const brugereMedTidsbegraensetAdgangDropdownOptions = brugereMedTidsbegraensetAdgang.map(x => mapToKlarmeldBrugerDropdownOption(x.userId, x.navn, x.email, x.rolleTitelEnums));

            //Make dropdown-options
            const alleBrugereMedAdgangTilForloeb = [...forloebPersonaleDropdownOptions, ...brugereMedTidsbegraensetAdgangDropdownOptions];
            const brugereDerKanKlarmeldesTilOptions = alleBrugereMedAdgangTilForloeb
                .filter(x => x.value.UserId !== forloebSliceState.forloebState.brugerId)
                // Merge all rolleTitelEnums from duplicate options together
                .map((option, _, allOptions) => {
                    const duplicateOptions = allOptions.filter(x => x.value.UserId === option.value.UserId);
                    const allRolesOnOption = duplicateOptions.flatMap(x => x.value.RolleTitelEnums).filterDistinct();
                    return ({ ...option, value: { ...option.value, RolleTitelEnums: allRolesOnOption } }) as DropdownOption<KlarmeldingTilBrugerModel>;
                })
                // Remove duplicates - Because "personale" and "tidsbegraensetAdgangBrugere" can return the same user:
                .filter((x, y, z) => filterDistinctDropdownOptions(x, y, z, (optionValue) => optionValue.UserId));
            setDropdownOptions(brugereDerKanKlarmeldesTilOptions);
        }
        if (klarmeldToDelkursusledere) {
            fetchKursusledere().then(x => setIsLoading(false));
        } else {
            fetchPersonale().then(x => setIsLoading(false));
        }

    }, [forloebSliceState.forloebState.brugerId, forloebSliceState.forloebState.id, forloebSliceState.forloebState.specialeId, klarmeldToDelkursusledere, logbogUserInfoApi, setSubmitIsDisabled, stamdataUserApi]);

    const onKlarmeldTilBrugereOptionsSelected = (values: KlarmeldingTilBrugerModel[]) => {
        if(values.map(x => x.UserId).includes(AlleMedAdgangKey)) {
            const allBrugerIds = klarmeldTilBrugerDropdownOptions.filter(x => x.value.UserId !== AlleMedAdgangKey).map(x => x.value);
            props.formik.setFieldValue(nameof<KlarmeldingInputModel>(x => x.klarmeldBrugere), allBrugerIds);
        }
    }

    const derErBrugereDerKanKlarmeldesTil = klarmeldTilBrugerDropdownOptions.length > 0;

    return (
        <Loading isLoading={isLoading} text={Localizer.global_getData()} spinnerClasses={"margin-bottom-m"}>
            <div className="row">
                <div className="col-sm-12 margin-bottom-m">
                    {children}
                </div>

                {!derErBrugereDerKanKlarmeldesTil &&
                    <div className="col-sm-12 ">
                        <div className="alert alert-warning">
                            {klarmeldToDelkursusledere ? Localizer.global_klarmeldingtilBrugereIngenBrugereMedAdgangTilSpeciale() : Localizer.global_klarmeldingtilBrugereIngenBrugereMedAdgang()}
                        </div>
                    </div>
                }

                <div className="col-sm-10 margin-bottom-m">
                    <ValidationDropdown
                        id={`klarmelding-${props.modalId}-choose-doctors`}
                        model={{
                            label: Localizer.global_klarmeldTil(),
                            placeholder: Localizer.vaelgBrugere(),
                            htmlName: nameof<KlarmeldingInputModel>(x => x.klarmeldBrugere)
                        }}
                        options={klarmeldTilBrugerDropdownOptions}
                        readOnly={!derErBrugereDerKanKlarmeldesTil}
                        formikProps={props.formik}
                        itemSelected={(newValue) => onKlarmeldTilBrugereOptionsSelected(newValue.map(x => x.value))}
                        valueToKeyOverride={(value) => value.UserId}
                        isMulti
                    />
                </div>
            </div>
        </Loading>
    )
}
