import {Action, AnyAction, combineReducers, configureStore, ThunkAction} from '@reduxjs/toolkit';
import titlebarReducer from "core/layout/titleBar/titlebarSlice";
import leftMenuReducer from "core/layout/leftMenu/leftMenuSlice";
import forloebReducer from "core/forloebSlice";
import forloebTableReducer from "pages/forloebList/forloebTableSlice";
import slideInReducer from "core/components/slideIn/slideInSlice";
import kompetenceCardReducer from "pages/kompetencerPage/cards/kompetenceCardSlice";
import validatedDateReducer from "pages/vejledere/vejledereValidateDateSlice";
import forloebOverviewFiltersSlice from "../core/components/forloebOverview/forloebOverviewFiltersSlice";
import evalueringsstatistikFiltersReducer from "../pages/evalueringsstatistik/evalueringsstatistikFiltersSlice";
import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {Persistor} from "redux-persist/es/types";
import {FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE} from "redux-persist/es/constants";
import filterSlice from "../core/components/filter/filterSlice";
import asyncTableSlice from "../pages/bruger/asyncTableSlice";
import evalueringsstatistikReducer from "../pages/evalueringsstatistik/evalueringsstatistikSlice";

const persistConfig = {
    key: 'root',
    storage,
}

export const persistor = () : Persistor => {
    return persistStore(store);
}
const persistForloebReducer = persistReducer(persistConfig, forloebReducer);
const persistEvalueringsstatistikReducer = persistReducer(persistConfig, evalueringsstatistikReducer);
const persistEvalueringsstatistikFilterReducer = persistReducer(persistConfig, evalueringsstatistikFiltersReducer);

const appReducer = combineReducers(
    {
        titlebarReducer: titlebarReducer,
        leftMenu: leftMenuReducer,
        forloebReducer: persistForloebReducer,
        forloebTableReducer: forloebTableReducer,
        slideInReducer: slideInReducer,
        kompetenceCardReducer: kompetenceCardReducer,
        validatedDateReducer: validatedDateReducer,
        filterReducer: filterSlice,
        forloebOverviewFiltersReducer: forloebOverviewFiltersSlice,
        evalueringsstatistikFiltersReducer: persistEvalueringsstatistikFilterReducer,
        evalueringsstatistikReducer: persistEvalueringsstatistikReducer,
        asyncTableReducer: asyncTableSlice
    }
)

const rootReducer = (state: any, action: AnyAction) => {
    if (action.type === 'USER_CHANGE_ROLE') {
        storage.removeItem('persist:root')
        storage.removeItem('root')
        localStorage.removeItem('persist:root')
        return appReducer(undefined, action);
    }
    return appReducer(state, action)
}

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;
