import { Localizer } from "infrastructure/localization/localizer";

export type CookieInformationData = {
  name: string;
  provider: string;
  purpose: string;
  expiry: string;
}

const expirySessionString = "Session*";

export const getRequiredCookiesData = (cookieConsentName: string, cookieConsentExpiryInDays: number): CookieInformationData[] => {
  
  const cookieConsentExpiryInYears = Math.floor(cookieConsentExpiryInDays / 365);
  const cookieConsentExpiryInYearsOrDefault = cookieConsentExpiryInYears > 0 ? cookieConsentExpiryInYears : 1
  
  return [
    {
      name: ".AspNetCore.Antiforgery.*",
      provider: Localizer.global_uddannelseslaegedk(),
      purpose: Localizer.cookiesPage_requiredCookiesList_purpose_antiForgery(),
      expiry: expirySessionString,
    },
    {
      name: "ARRAffinity",
      provider: Localizer.global_uddannelseslaegedk(),
      purpose: Localizer.cookiesPage_requiredCookiesList_purpose_arraffinity(),
      expiry: expirySessionString,
    },
    {
      name: "ARRAffinitySameSite",
      provider: Localizer.global_uddannelseslaegedk(),
      purpose: Localizer.cookiesPage_requiredCookiesList_purpose_arraffinity(),
      expiry: expirySessionString,
    },
    {
      name: "idsrv",
      provider: Localizer.global_uddannelseslaegedk(),
      purpose: Localizer.cookiesPage_requiredCookiesList_purpose_idsrv(),
      expiry: expirySessionString,
    },
    {
      name: "idsrv.session",
      provider: Localizer.global_uddannelseslaegedk(),
      purpose: Localizer.cookiesPage_requiredCookiesList_purpose_idsrvSession(),
      expiry: expirySessionString,
    },
    {
      name: "ValidSessionCreatedAt",
      provider: Localizer.global_uddannelseslaegedk(),
      purpose: Localizer.cookiesPage_requiredCookiesList_purpose_validSessionCreatedAt(),
      expiry: expirySessionString,
    },
    {
      name: cookieConsentName,
      provider: Localizer.global_uddannelseslaegedk(),
      purpose: Localizer.cookiesPage_requiredCookiesList_purpose_cookieConsent(),
      expiry: `${cookieConsentExpiryInYearsOrDefault} ${Localizer.global_aar().toLocaleLowerCase()}`,
    },
  ]
};

export const getStatisticsCookiesData = (): CookieInformationData[] => {
  return [
    {
      name: "ai_session",
      provider: Localizer.global_uddannelseslaegedk(),
      purpose: Localizer.cookiesPage_statisticsCookiesList_purpose_aiSession(),
      expiry: `30 ${Localizer.global_minutter().toLowerCase()}`,
    },
    {
      name: "ai_user",
      provider: Localizer.global_uddannelseslaegedk(),
      purpose: Localizer.cookiesPage_statisticsCookiesList_purpose_aiUser(),
      expiry: `1 ${Localizer.global_aar().toLowerCase()}`,
    },
  ]
};
