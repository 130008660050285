import CreateValidationSchema from "../validation/createValidationSchema";
import {string} from "yup";
import {Localizer} from "../../../infrastructure/localization/localizer";
import { RolleEnum } from "infrastructure/enums/userRole";
import yup from "types/yup-extended";
import { NyhedModel } from "core/sharedmodels/nyheder/nyhedModel";

export type CrudNyhedInputModel = {
    titel: string;
    publiceringsdato: Date;
    markdown: string;
    skalNotificere: boolean;
    maalrettetTilRoller: RolleEnum[];
}

export const BuildNew = (): CrudNyhedInputModel => {
    return {
        titel: "",
        publiceringsdato: new Date(),
        markdown: "",
        skalNotificere: true,
        maalrettetTilRoller: [],
    } as CrudNyhedInputModel;
}

export const BuildExisting = (model: NyhedModel) : CrudNyhedInputModel => {
    return {
        titel: model.titel,
        publiceringsdato: model.publiceringsdato,
        markdown: model.markdown,
        skalNotificere: model.skalNotificere,
        maalrettetTilRoller: model.maalrettetTilRoller,
    } as CrudNyhedInputModel;
}

export const CreateNyhedValidationSchema = CreateValidationSchema<CrudNyhedInputModel>({
    id: string().notRequired(),
    titel: string().required(Localizer.validate_titelRequired()),
    publiceringsdato: string().required(Localizer.validate_publiceringsdatoMessageRequired()),
    markdown: string().required(Localizer.validate_MessageRequired()),
    skalNotificere: yup.boolean().required(),
    maalrettetTilRoller: yup.array(),
})
