import {Localizer} from "../../../infrastructure/localization/localizer";
import React from "react";
import {ForloebOverviewFilterModel} from "./ForloebOverviewFilterModel";
import {ValidationInput} from "../validation/components/validationInput";
import {nameof} from "ts-simple-nameof";
import {CreateFilterInputModel} from "../filter/createFilterInputModel";
import {FormikProps} from "formik";

export interface IForloebOverviewValidationInput {
    modalId: string,
    formik: FormikProps<CreateFilterInputModel>,
    filter: ForloebOverviewFilterModel;
}

export function ForloebOverviewValidationInput(props: IForloebOverviewValidationInput) {
    const render = (
        <>
            <ValidationInput
                model={{
                    label: Localizer.titel(),
                    placeholder: Localizer.titelPlaceholder(),
                    htmlName: nameof<CreateFilterInputModel>(x => x.titel)
                }}
                inputId={nameof<CreateFilterInputModel>(x => x.titel) + props.modalId}
                formikProps={props.formik}
            />

            <span className={"small subtle caption margin-left-xs"}>
                {Localizer.forloebOverviewPage_alleFiltreOprettesPrivat()}
            </span>
        </>
    )
    return <>{render}</>;
}
