import NotificationListItem from "./notificationListItem/notificationListItem";
import useNotifications from "../hooks/useNotifications";
import {Loading} from "core/components/loading/loading";
import {Localizer} from "infrastructure/localization/localizer";
import React, {useEffect, useState} from "react";
import {NotificationModel} from "../models/notificationModel";
import {EmblaIcon} from "../../emblaIcon/emblaIcon";
import {EmblaIcons} from "../../../emblaIcons";
import {ModalAcceptType, ModalSubmitMessage} from "../../modalMessage/modalSubmitMessage";
import {NotificationModule} from "ditmer-embla";
import {createPortal} from "react-dom";
import useNotificationMutations from "../hooks/useNotificationMutations";
import {closeModal} from "../../slideIn/slideIn";
import DynamicButton from "../../button/dynamicButton";
import Tooltip, {TooltipPlacement} from "../../tooltips/Tooltip";
import useScreenResolution from "core/hooks/useScreenResolution";

type NotificationOverviewProps = {
    onDidRedirectToNotificationContext?: () => void;
    showCheckbox: boolean;
    setCheckboxStateFalse?: () => void;
}

const incrementAmount = 10;

const NotificationOverview = (props: NotificationOverviewProps) => {
    const {notifications, isLoading} = useNotifications();
    const { isMobile } = useScreenResolution();
    const [loadedNotifications, setLoadedNotifications] = useState<NotificationModel[]>([]);
    const [notificationsLoadedAmount, setNotificationsLoadedAmount] = useState<number>(incrementAmount);
    const [selectedNotifications, setSelectedNotifications] = useState<NotificationModel[]>([]);
    const [showFaerdiggoerModal, setShowFaerdiggoerModal] = useState(false);
    const { setIsSeenStatusOnNotificationIds } = useNotificationMutations();
    const faerdiggoerModalId = "mark-notifikationer-faerdiggoer-modal";

    const onSelectDeselectItem = (notificationId: string, isSelected: boolean) => {
        const notification = notifications.find(x => x.id === notificationId);
        if(!notification) return;

        setSelectedNotifications((prev) => [...prev.filter(x => x.id !== notificationId), ...(isSelected ? [notification] : [])])
    }

    const onFaerddiggoerClicked = () => {
        $(`#${faerdiggoerModalId}`).modal('show');
        setShowFaerdiggoerModal(true);
    }

    const onAnnullerClicked = () => {
        setSelectedNotifications([]);
        setShowFaerdiggoerModal(false);
        closeModal(faerdiggoerModalId);
        if(props.setCheckboxStateFalse){
            props.setCheckboxStateFalse();
        }
    }

    const markerNotifikationer = async (markAsSeen : boolean) => {
        await setIsSeenStatusOnNotificationIds(selectedNotifications.map(n => n.id), markAsSeen);
        NotificationModule.showSuccess(Localizer.notifikationer_OpdaterStatusSuccess(selectedNotifications.length,  markAsSeen ? "læst(e)" : "ulæst(e)"), "");
        onAnnullerClicked();
    }

    useEffect(() => {
        setLoadedNotifications(notifications.slice(0, notificationsLoadedAmount));
    }, [notifications, notificationsLoadedAmount])

    const loadMoreNotifications = () => {
        // Increase the number of notifications to show
        const notYetLoadedNotificationAmount = notifications.length - loadedNotifications.length;
        if (notYetLoadedNotificationAmount > 0) {
            const toIncrement = loadedNotifications.length + incrementAmount < notifications.length
                ? incrementAmount
                : notYetLoadedNotificationAmount;
            setNotificationsLoadedAmount(loadedNotifications.length + toIncrement)
        }
    }

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        const target = e.currentTarget;
        // Check if the user has scrolled to the bottom
        if (target.scrollTop + target.clientHeight === target.scrollHeight) {
            loadMoreNotifications();
        }
    };

    return (
        <>
            <Loading timeout={100} isLoading={isLoading} spinnerClasses={"padding-top-l"}>
                <div onScroll={handleScroll}
                     style={{maxHeight: window.innerHeight, overflowY: 'auto'}}>
                    {notifications.length <= 0 &&
                        <div className="d-flex justify-content-center mt-3">
                            <p className="subtle">{Localizer.notifikationer_IngenNotifikationer()}</p>
                        </div>
                    }
                    {props.showCheckbox &&
                        <div className="flex-container flex-align-center sticky-top-notifikationer">
                            <button id="notification-update-seen-status-btn" className="btn btn-primary medium  margin-right-m" onClick={onFaerddiggoerClicked} disabled={selectedNotifications.length <= 0}>
                                {Localizer.notifikationer_Faerdiggoer()}
                            </button>
                            <button id="notification-annuller-btn" className="btn btn-danger " onClick={onAnnullerClicked}>
                                {Localizer.global_cancel()}
                            </button>
                        </div>
                    }
                    {loadedNotifications
                        .map((notification) =>
                            <div key={notification.id} onScroll={e => handleScroll(e)}>
                                <NotificationListItem
                                    notification={notification}
                                    onDidRedirectToNotificationContext={props.onDidRedirectToNotificationContext}
                                    showCheckbox={props.showCheckbox}
                                    checked={!!selectedNotifications.find(n => n.id === notification.id)}
                                    onSelectDeselectItem={onSelectDeselectItem}
                                />
                            </div>
                    )}
                    {/*Edgecase, hvor der loades 10 notifikationer som ikke fylder hele højden på en stor skærm.
                    Så skal brugeren manuelt klikke "Vis flere" for at hente yderligere 10.*/}
                    { loadedNotifications.length !== notifications.length &&
                        <div className="align-self-center subtle margin-m">
                            <h5 className="a-style" onClick={() => loadMoreNotifications()}>
                                <EmblaIcon iconName={EmblaIcons.Plus}/>
                                {Localizer.notifikationer_VisFlere()}
                            </h5>
                        </div>
                    }
                </div>
                {showFaerdiggoerModal &&
                    createPortal(
                        <ModalSubmitMessage
                            title={Localizer.notifikationer_FaerdiggoerMarkering()}
                            modalAcceptType={ModalAcceptType.primary}
                            modalId={faerdiggoerModalId}
                            defaultOpen={true}
                            description={
                                (
                                    <>
                                        <p>{Localizer.notifikationer_MarkeringSummaryTitel()}</p>
                                            <ul>
                                                {selectedNotifications.map(x => {
                                                    return (
                                                        <Tooltip title={x.message}>
                                                            <li key={x.id}>
                                                                <p className={"truncate text-truncate"}>
                                                                    {x.message}
                                                                </p>
                                                            </li>
                                                        </Tooltip>
                                                    )
                                                })
                                                }
                                            </ul>
                                    </>
                                )
                            }
                            overrideModalFooter={
                                (
                                    <div className={'modal-footer border-top d-flex'}>
                                        <DynamicButton modal={{dataTarget: faerdiggoerModalId}} onClick={() => markerNotifikationer(false)} isPrimary disableMobileClasses>
                                            {isMobile ? Localizer.notifikationer_Ulaest() : Localizer.notifikationer_MarkerSomUlaest()}
                                        </DynamicButton>

                                        <DynamicButton modal={{dataTarget: faerdiggoerModalId}} onClick={() => markerNotifikationer(true)} disableMobileClasses>
                                            {isMobile ? Localizer.notifikationer_Laest() : Localizer.notifikationer_MarkerSomLaest()}
                                        </DynamicButton>

                                        <DynamicButton modal={{dataTarget: faerdiggoerModalId}} onClick={() => setShowFaerdiggoerModal(false)} isDanger disableMobileClasses>
                                            {Localizer.global_cancel()}
                                        </DynamicButton>
                                    </div>
                                )
                            }
                        />
                    , document.body)
                }
            </Loading>
        </>
    );
}
export default NotificationOverview;
