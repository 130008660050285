import {useEffect, useState} from 'react'
import {FormikProps} from "formik";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {nameof} from "ts-simple-nameof";
import {ReactFileUploaderFile} from "../../components/fileUpload/reactFileUploader";
import {FileMetadata} from 'core/sharedmodels/fileMetaData/fileMetaData';
import {FileMetadataList} from 'core/components/fileMetadata/fileMetadataList';
import {useAppSelector} from "../../../app/hooks";
import {forloebReducer, ForloebSliceState} from "../../forloebSlice";
import {BasicNotatModel} from "../../sharedmodels/notat/basicNotatModel";
import {
    ForSelectModel,
    toDropdownOptions,
} from "../../sharedmodels/forSelectModel";
import {NotatType} from "../../sharedmodels/notat/notatType";
import {NotatCrudModel, PrivateNoteKey, VisibleToEveryoneKey} from "../../sharedmodels/notat/notatCrudModel";
import {FileLocationEnum} from "../../sharedmodels/file/fileLocationEnum";
import {kompetenceCardReducer, KompetenceCardState} from "../../../pages/kompetencerPage/cards/kompetenceCardSlice";
import {ValidationInput} from "../../components/validation/components/validationInput";
import {useLocation} from "react-router-dom";
import {RoutePaths} from "../../../infrastructure/routes";
import {DropdownOption} from "../../components/dropdown/dropdown";
import {ValidationDropdown} from "../../components/validation/components/validationDropdown";
import useUser from 'core/hooks/useUser';
import useLogbogApi from 'core/hooks/useLogbogApi';
import { ValidationTextEditor } from 'core/components/validation/components/validationTextEditor';

type BasicNotatCrudFieldsProps = {
    modalId: string;
    formik: FormikProps<any>;
    notatUserId: string;
    notatType?: NotatType;
    notatInfo?: { contextId: string, notatType: NotatType, userId: string };
    notatEditModel?: BasicNotatModel;
    fileMetadatas?: FileMetadata[];
    fileUploadedCallbackOverride?: (result: ReactFileUploaderFile[]) => void;
    fileDeletedCallbackOverride?: (result: FileMetadata) => void;
    onFilesUpdatedCallback?: () => void;
}

export function BasicNotatCrudFields(props: BasicNotatCrudFieldsProps) {
    const { currentUser } = useUser();
    const route = useLocation();
    const { logbogUserInfoApi } = useLogbogApi();

    const [visibleForUsersSelectOptions, setVisibleForUsersSelectOptions] = useState<DropdownOption<string>[]>([]);

    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;
    const kompetenceCardState = useAppSelector(kompetenceCardReducer) as KompetenceCardState;

    const isMeritNotat = props.notatType === NotatType.Merit || props.notatInfo?.notatType === NotatType.Merit;
    const userIsLaege = currentUser.IsLaege();

    useEffect(() => {
        const fetchVisibilityOptions = async () => {
            if (props.notatUserId) {
                let laegerWithAccessToUser: ForSelectModel[] = [];

                if (route.pathname !== RoutePaths.Klarmeldinger.path && forloebSliceState.forloebState.id) {
                    const personaleSelect = await logbogUserInfoApi.getPersonaleSelectOnForloeb(forloebSliceState.forloebState.id);
                    laegerWithAccessToUser = personaleSelect;
                } else {
                    if (kompetenceCardState?.activeLaegekompetenceMeta?.forloebIds?.length > 0) {
                        const personaleSelectMulti = await logbogUserInfoApi.getPersonaleSelectOnMultipleForloeb(kompetenceCardState?.activeLaegekompetenceMeta.forloebIds);
                        laegerWithAccessToUser = personaleSelectMulti;
                    }
                    else if (kompetenceCardState?.activeLaegekompetenceMeta?.UserId)
                        laegerWithAccessToUser = await logbogUserInfoApi.getPersonaleSelectByBruger(kompetenceCardState?.activeLaegekompetenceMeta.UserId);
                }

                const synligForSelectOptions = [
                    {
                        label: Localizer.visibleToEveryoneWithAccessToLaege(),
                        value: VisibleToEveryoneKey,
                    },
                    ...(userIsLaege ? [{
                        label: Localizer.visibleToJustMe(),
                        value: PrivateNoteKey,
                    }] : []),
                    ...toDropdownOptions(laegerWithAccessToUser.filter(x => x.id !== props.notatUserId))
                ] as DropdownOption<string>[];

                setVisibleForUsersSelectOptions(synligForSelectOptions);
            }
        }
        fetchVisibilityOptions();

    }, [forloebSliceState.forloebState.id, kompetenceCardState?.activeLaegekompetenceMeta.UserId, kompetenceCardState?.activeLaegekompetenceMeta.forloebIds, logbogUserInfoApi, props.notatUserId, route.pathname, userIsLaege]);

    const render = (
        <>
            <div className="row">
                <div className="col-sm-12">
                    <ValidationDropdown
                        hidden={isMeritNotat}
                        model={{
                            label: Localizer.global_visibilityAlwaysForDoctor(),
                            placeholder: Localizer.global_chooseVisibility(),
                            htmlName: nameof<NotatCrudModel>(x => x.visibleForUserIds)
                        }}
                        options={visibleForUsersSelectOptions}
                        formikProps={props.formik}
                        id={"custom-tags-filter" + props.modalId}
                        standAloneValues={[VisibleToEveryoneKey, PrivateNoteKey]}
                        isMulti
                    >
                    </ValidationDropdown>
                </div>

                <div className="col-sm-12">
                    <ValidationInput
                        model={{
                            label: Localizer.global_title(),
                            placeholder: Localizer.global_title(),
                            htmlName: nameof<NotatCrudModel>(x => x.title)
                        }}
                        inputId={nameof<NotatCrudModel>(x => x.title) + props.modalId}
                        formikProps={props.formik}
                        readOnly={isMeritNotat}
                    />
                </div>

                <div className="col-sm-12">
                    <ValidationTextEditor
                        model={{
                            label: Localizer.global_description(),
                            placeholder: Localizer.global_description(),
                            htmlName: nameof<NotatCrudModel>(x => x.description)
                        }}
                        inputId={nameof<NotatCrudModel>(x => x.description) + props.modalId}
                        minHeightPx={400}
                        useConstantHeight
                        formikProps={props.formik}
                    />
                </div>

            </div>

            <FileMetadataList
                fileMetadatas={props.fileMetadatas ?? []}
                filesUploadedCallbackOverride={props.fileUploadedCallbackOverride}
                fileDeletedCallbackOverride={props.fileDeletedCallbackOverride}
                notatInfo={props.notatInfo}
                filesUpdatedCallback={props.onFilesUpdatedCallback}
                notat={props.notatEditModel}
                // Never automatically delete notat when removing all files
                deleteExistingNotatIfNoFiles={false}
                showDeleteButton={true}
                withUploader={true}
                fileLocation={FileLocationEnum.Logbog}
            />
        </>
    );
    return <>{render}</>
}
