import CreateValidationSchema from "../validation/createValidationSchema";
import * as yup from "yup";
import {Localizer} from "../../../infrastructure/localization/localizer";

export class CreateFilterInputModel {
    public titel: string;
    public regionRettigheder?: string[];
    public isPrivate?: boolean;
    public filterId?: string;
}

export const BuildNew = () : CreateFilterInputModel => {
    let inputModel = new CreateFilterInputModel();
    inputModel.titel = "";
    inputModel.regionRettigheder = [];
    inputModel.isPrivate = true;
    inputModel.filterId = "";
    return inputModel;
}

export const BuildExisting = (titel: string, regionRettigheder?: string[], filterId?: string, isPrivate?: boolean) : CreateFilterInputModel => {
    let inputModel = new CreateFilterInputModel();
    inputModel.titel = titel;
    inputModel.regionRettigheder = regionRettigheder;
    inputModel.isPrivate = isPrivate;
    inputModel.filterId = filterId;
    return inputModel;
}

export const CreateFilterValidationSchema = CreateValidationSchema<CreateFilterInputModel>({
    titel: yup.string().required(Localizer.validate_titelRequired()),
    regionRettigheder: yup.array().notRequired(),
    filterId: yup.string().notRequired(),
})
