import FileMetadataListComponent from "core/components/fileMetadata/fileMetadataListComponent";
import { FileUploader } from "core/components/fileUpload/fileUploader";
import { ReactFileUploader, ReactFileUploaderFile } from "core/components/fileUpload/reactFileUploader";
import Title from "core/components/titels/title";
import { FileLocationEnum } from "core/sharedmodels/file/fileLocationEnum";
import { FileArchiveEnum } from "core/sharedmodels/fileMetaData/fileArchiveEnum";
import { FileMetadata } from "core/sharedmodels/fileMetaData/fileMetaData";
import { Localizer } from "infrastructure/localization/localizer";
import { useCallback, useRef } from "react";

type GenericFileListProps = {
    files: FileMetadata[];
    titleText?: string;
    emptyListText?: string;
    allwaysShowTitle?: boolean;
    disableSubTitle?: boolean;
    fileUploadedCallback?: (result: ReactFileUploaderFile[]) => void;
    fileDeletedCallback?: (fileMetadata: FileMetadata) => void;
    showDeleteButton?: boolean;
    fileLocation?: FileLocationEnum;
    fileTypeEnum?: FileArchiveEnum;
    skipAutomaticFileUpload?: boolean;
}

const GenericFileList = ({
        files=[],
        titleText = Localizer.global_files(),
        emptyListText = Localizer.global_noFiles(),
        allwaysShowTitle = false,
        fileUploadedCallback,
        fileDeletedCallback,
        fileLocation = FileLocationEnum.Logbog,
        disableSubTitle = false,
        fileTypeEnum = FileArchiveEnum.NoteAttachment,
        skipAutomaticFileUpload = false,
    }: GenericFileListProps) => {

    const fileUploaderRef = useRef<ReactFileUploader>(null);
    const hasFiles = files.length > 0;
    const showDeleteButton = !!fileDeletedCallback;

    const onFileDeleted = useCallback((fileMetadata: FileMetadata) => {
        if (fileDeletedCallback) {
            fileUploaderRef.current?.removeUploadedFileWithFileName(fileMetadata.fileName);
            fileDeletedCallback(fileMetadata);
        }
    }, [fileDeletedCallback]);
    

    return (
        <Title
            title={hasFiles || allwaysShowTitle ? titleText : undefined}
            subTitle={hasFiles || disableSubTitle ? undefined : emptyListText}
            largeSubTitle
        >
            {fileUploadedCallback &&
                <FileUploader 
                    ref={fileUploaderRef}
                    filesUploadedCallback={fileUploadedCallback}
                    fileType={fileTypeEnum}
                    fileLocation={fileLocation}
                    skipAutomaticUpload={skipAutomaticFileUpload}
                />
            }

            {hasFiles &&
                <FileMetadataListComponent
                    fileMetaDataList={files}
                    fileLocation={fileLocation}
                    fileDeletedCallback={onFileDeleted}
                    showDeleteButton={showDeleteButton}
                    withDownload={false}
                />
            }

        </Title>
    );
}

export default GenericFileList;