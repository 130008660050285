type HorizontalLineProps = {
    rowIndex: number;
    columnStart: number;
    columnEnd: number;
    rowColor?: string;
}
export const HoverLine = ({rowIndex, columnStart, columnEnd}: HorizontalLineProps) => {
    const linestyle = {
        "--horizontal-line-row-index": rowIndex,
        "--column-start": columnStart,
        "--column-end": columnEnd,
    } as React.CSSProperties;
    return (
        <div className={`hover-line`} style={linestyle}/>
    )
}
