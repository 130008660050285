import React, {HTMLAttributes, PropsWithChildren} from "react";
import useScreenResolution from "../../hooks/useScreenResolution";
import CardHeader from "../card/CardHeader";
import CardBody from "../card/CardBody";
import CardFooter from "../card/CardFooter";

const Container = ({children} : PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
    const { isMobile } = useScreenResolution();

    return (
        <>
            {!isMobile &&
                <div className="row top-level-row top-level-default-margins">
                    <div className="col-12">
                        <div className="card top-level-container">
                            {children}
                        </div>
                    </div>
                </div>
            }

            {isMobile &&
                <div className="top-level-row ">
                    <div className="card top-level-container">
                        {children}
                    </div>
                </div>
            }
        </>
    )
}

export default Object.assign(
    Container,
    {
        Header: CardHeader,
        Body: CardBody,
        Footer: CardFooter,
    },
);

