import React, {createContext, ReactNode, useContext, useReducer} from "react";
import {LaegeForloebWithKursusGrupperModel} from "../../core/sharedmodels/user/laegeForloebWithKursusGrupperModel";

export type KursisterFilterContextType = {
    searchLaege: string;
    searchKursus: string;
}

type KursisterListContextType = {
    forloebWithKursusGrupper: LaegeForloebWithKursusGrupperModel[];
}

type StatusContextType = {
    isLoading: boolean;
}

const KursisterContext = createContext<{
    filterReducer: {
        state: KursisterFilterContextType,
        dispatch: React.Dispatch<KursisterFilterContextType>
    },
    listReducer: {
        state: KursisterListContextType,
        dispatch: React.Dispatch<KursisterListContextType>
    },
    statusReducer: {
        state: StatusContextType,
        dispatch: React.Dispatch<StatusContextType>
    },
} | undefined>(undefined);

const filterContextReducer = (state: KursisterFilterContextType, action: { searchLaege: string, searchKursus: string }): KursisterFilterContextType => {
    return {
        ...state,
        searchLaege: action.searchLaege,
        searchKursus: action.searchKursus
    };
};

const kursisterListContextReducer = (state: KursisterListContextType, action: { forloebWithKursusGrupper: LaegeForloebWithKursusGrupperModel[] }): KursisterListContextType => {
    return {
        ...state,
        forloebWithKursusGrupper: action.forloebWithKursusGrupper,
    };
};

const statusContextReducer = (state: StatusContextType, action: { isLoading: boolean }): StatusContextType => {
    return {...state, isLoading: action.isLoading};
};

export const useKursisterContext = () => {
    const context = useContext(KursisterContext);
    if (!context) throw new Error("useKursisterContext must be used in KursisterContextProvider");
    return context;
};

type KursisterContextProviderProps = { children?: ReactNode; }

const KursisterOverviewContextProvider = ({children}: KursisterContextProviderProps) => {

    const [filterState, filterDispatch] = useReducer(filterContextReducer, {searchLaege: "", searchKursus: ""});
    const [listState, listDispatch] = useReducer(kursisterListContextReducer, {forloebWithKursusGrupper: []});
    const [statusState, statusDispatch] = useReducer(statusContextReducer, {isLoading: false});

    return (
        <KursisterContext.Provider value={{
            filterReducer: {state: filterState, dispatch: filterDispatch},
            listReducer: {state: listState, dispatch: listDispatch},
            statusReducer: {state: statusState, dispatch: statusDispatch}
        }}>
            {children}
        </KursisterContext.Provider>
    );
};

export default KursisterOverviewContextProvider;
