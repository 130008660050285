import { Localizer } from "infrastructure/localization/localizer";
import useStamdataApi from "core/hooks/useStamdataApi";
import { useCallback, useState } from "react";
import DynamicButton from "core/components/button/dynamicButton";
import { EmblaIcons } from "core/emblaIcons";
import { createPortal } from "react-dom";
import { SlideIn } from "core/components/slideIn/slideIn";
import { EducationFilterResult } from "core/components/filter/components/educationFilter";
import { PlaceFilterResult } from "core/components/filter/components/placeFilter";
import { NotificationModule } from "ditmer-embla";
import { SlideInStateEnum, setSlideinState } from "core/components/slideIn/slideInSlice";
import { useAppDispatch } from "app/hooks";
import GenericFileList from "./fileList";
import FileRelations, { filterResultsToFileRelations, validateFileUploadeOrEdit } from "core/sharedmodels/file/fileRelations";
import { FileLocationEnum } from "core/sharedmodels/file/fileLocationEnum";
import { useFileContext } from "../context/fileContextProvider";
import { FileData } from "services/api/stamdataMappe/FilesAndFoldersModel";
import FileFilters, { emptyEducationFilterValues, emptyPlaceFilterValues } from "./fileFilters";
import UserFilterPermissions from "core/sharedmodels/userPermissions/userFilterPermissions";
import { FileArchiveEnum } from "core/sharedmodels/fileMetaData/fileArchiveEnum";

const fileDataToEducationResult = (file: FileData) : EducationFilterResult => {
    return {
        ...emptyEducationFilterValues,
        uddannelser: file.uddannelseIds,
        specialer: file.specialeIds
    }
}

const fileDataToPlaceResult = (file: FileData) : PlaceFilterResult => {
    return {
        ...emptyPlaceFilterValues,
        regioner: file.regionIds,
        omraader: file.omraadeIds,
        praksiser: file.praksisIds,
        sygehuse: file.sygehusIds,
        afdelinger: file.afdelingIds,
    }
}

const filterPermissionedValues = (selectedValues: string[], permissionedValues: string[], originalVaules: string[]) => {
    return [...selectedValues, ...originalVaules.filter(id => !permissionedValues.includes(id))]
};

type FileEditSlideInProps = {
    fileToEdit: FileData,
    permissions: UserFilterPermissions,
    onClose: () => void;
}


const handleEditPermissionedValues = (srcFile: FileData, newRelations: FileRelations, permissions: UserFilterPermissions) : FileRelations => {
    if(permissions.adgangTilAlleForloeb) return newRelations;

    return {
        ...newRelations,
        specialeIds: filterPermissionedValues(newRelations.specialeIds, permissions.specialeIds, srcFile.specialeIds),
        regionIds: filterPermissionedValues(newRelations.regionIds, permissions.regionIds, srcFile.regionIds),
        omraadeIds: filterPermissionedValues(newRelations.omraadeIds, permissions.omraadeIds, srcFile.omraadeIds),
        praksisIds: filterPermissionedValues(newRelations.praksisIds, permissions.praksisIds, srcFile.praksisIds),
        sygehusIds: filterPermissionedValues(newRelations.sygehusIds, permissions.sygehusIds, srcFile.sygehusIds),
        afdelingIds: filterPermissionedValues(newRelations.afdelingIds, permissions.afdelingIds, srcFile.afdelingIds),
    }
}

const UddannelsesstedFileEditSlideIn = ({fileToEdit, permissions, onClose} : FileEditSlideInProps) => {

    const filterModalTarget = "fileEditSlideIn";
    const dispatch = useAppDispatch();

    const { stamdataFileApi } = useStamdataApi();
    const { state, setFiles } = useFileContext();

    const [placeFilterResult, setPlaceFilterResult] = useState<PlaceFilterResult>(emptyPlaceFilterValues);
    const [educationFilterResult, setEducationFilterResult] = useState<EducationFilterResult>(emptyEducationFilterValues);

    const closeSlideIn = useCallback(() => {
        dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: filterModalTarget}))
    }, [dispatch]);

    const getFileRelations = useCallback(() : FileRelations => {
        const fileRelations = filterResultsToFileRelations(educationFilterResult, placeFilterResult);
        return handleEditPermissionedValues(fileToEdit, fileRelations, permissions);
    }, [educationFilterResult, fileToEdit, permissions, placeFilterResult]);

    const onSaveClicked = useCallback(() => {
        const fileRelations = getFileRelations();
        const isValid = validateFileUploadeOrEdit(fileRelations);
        if(!isValid) {
            NotificationModule.showError(Localizer.forloebEdit_FileUploadError(), "");
            return;
        }

        stamdataFileApi.editFile(fileToEdit.fileMetadata.id, fileRelations).then((editedFileData) => {
            setFiles(state.files.map(f => f.fileMetadata.id === editedFileData.fileMetadata.id ? editedFileData : f ));
            NotificationModule.showSuccess(Localizer.global_updateFileSuccess(), "");
            closeSlideIn();
        })
        .catch(() => NotificationModule.showError(Localizer.global_updateFileError(), ""));

    }, [closeSlideIn, fileToEdit.fileMetadata.id, getFileRelations, setFiles, stamdataFileApi, state.files]);

    return (
        <>
            {createPortal(
                <SlideIn
                    id={filterModalTarget}
                    title={Localizer.global_filtrer()}
                    actionFinishedCallback={onClose}
                    actionOnCloseCallback={onClose}
                    bodyContent={
                        <div>
                            <GenericFileList
                                titleText={Localizer.global_filesFromEducationPlace()}
                                files={[fileToEdit.fileMetadata]}
                                fileLocation={FileLocationEnum.Stamdata}
                                fileTypeEnum={FileArchiveEnum.UddannelsesstedFil}
                                skipAutomaticFileUpload
                                allwaysShowTitle
                                disableSubTitle
                            />

                            <div className="margin-top-m">
                                <FileFilters
                                    permissions={permissions}
                                    initialSelectedPlaceValues={fileDataToPlaceResult(fileToEdit)}
                                    initialSelectedEducationValues={fileDataToEducationResult(fileToEdit)}
                                    onPlaceFilterResult={(result) => setPlaceFilterResult(result)}
                                    onEducationFilterResult={(result) => setEducationFilterResult(result)}
                                />
                            </div>

                        </div>
                    }
                    footerContent={
                        <>
                            <DynamicButton onClick={() => closeSlideIn()}>
                                <DynamicButton.TextIconItem
                                    iconName={EmblaIcons.Close}
                                    text={Localizer.global_cancel()}
                                />
                            </DynamicButton>

                            <DynamicButton isPrimary onClick={onSaveClicked} >
                                <DynamicButton.TextIconItem
                                    iconName={EmblaIcons.Save}
                                    text={Localizer.global_save()}
                                />
                            </DynamicButton>
                        </>

                    }
                    defaultOpen={true}
                />
                , document.body
            )}
        </>
    );
}

export default UddannelsesstedFileEditSlideIn;
