import {ApplicationInsights, ITelemetryItem} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';

const useApplicationInsightsConfig = (enable: boolean, connectionString: string) => {
    const reactPlugin = new ReactPlugin();

    if(!enable || !connectionString) {
        // Dont configure applicationInsights, if we shouldn't use it:
        return { reactPlugin }
    }   

    const appInsights = new ApplicationInsights({
        config: {
            connectionString: connectionString,
            extensions: [reactPlugin],
            enableAutoRouteTracking: true,
            disableAjaxTracking: false,
            disableFetchTracking: false,
            autoTrackPageVisitTime: true,
            enableCorsCorrelation: true,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
        }
    });

    appInsights.loadAppInsights();
    
    appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
        env.tags = env.tags || [];
        env.tags["ai.cloud.role"] = "uddannelseslaege-frontend";
    });

    return { reactPlugin }
}

export default useApplicationInsightsConfig;
