import {LaegeWithSimpleForloebModel} from "core/sharedmodels/forloeb/laegeWithForloebModel";
import {RoutePaths} from "infrastructure/routes";
import {useHistory} from "react-router-dom";
import "./uddannelseslaege.scss";
import {Laegekompetence} from "../../../core/sharedmodels/kompetence/laegekompetence";
import {setCurrentForloeb} from "../../../core/forloebSlice";
import {useAppDispatch} from "../../../app/hooks";
import {
    UserProfileAvatar,
    UserProfileAvatarSizeEnum
} from "../../../core/components/userProfileAvatar/userProfileAvatar";
import {UserProfileAvatarModel} from "../../../core/components/userProfileAvatar/userProfileAvatarModel";
import {GetFravaerskodeTranslation} from "../../../core/sharedmodels/forloeb/fravaerskoderEnum";
import useLogbogApi from "core/hooks/useLogbogApi";
import useUser from "core/hooks/useUser";
import {ForloebSimpleInfoModel} from "core/sharedmodels/forloeb/forloebSimpleInfoModel";

type UddannelseslaegeProps = {
    uddannelseslaege: LaegeWithSimpleForloebModel
    forloeb : ForloebSimpleInfoModel,
    klarmeldtKompetencer?: Laegekompetence[]
}

export function Uddannelseslaege(props: UddannelseslaegeProps) {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { currentUser } = useUser()
    const { forloebApi } = useLogbogApi()

    const handleOnClickUddannelseslaege = async (uddannelseslaegeId: string) => {
        if(!props.forloeb.erFravaer){

            const forloebModel = await forloebApi.getForloebById(props.forloeb.id);
            dispatch(setCurrentForloeb(forloebModel.ToState()));

            if (currentUser.IsKursusBruger() || currentUser.IsForskningstraeningsvejleder())
                history.push(RoutePaths.Kurser([uddannelseslaegeId]).url)
            else if (currentUser.IsHrMedarbejder())
                history.push(RoutePaths.UserProfile.url)
            else
                history.push(RoutePaths.Forloeb([uddannelseslaegeId]).url)
        }
    };


    return (
        <div role={"button"} className="flex-container  flex-space" onClick={() => handleOnClickUddannelseslaege(props.uddannelseslaege.laegeId)}>
            {props.uddannelseslaege.laegeId &&
                <>
                    <div className="flex-container-row">
                        <div className="flex-align-c">
                            <UserProfileAvatar size={UserProfileAvatarSizeEnum.small}
                                               userProfileAvatarModel={new UserProfileAvatarModel(props.uddannelseslaege.laegeNavn, props.uddannelseslaege.laegeInitialer, true, props.uddannelseslaege.laegeEmail, props.uddannelseslaege.profileImageId)}
                            />
                            <div className="margin-left-m">
                                <h5>{props.forloeb.erFravaer && props.forloeb.fravaerskodeEnum ? `${props.uddannelseslaege.laegeNavn} - ${GetFravaerskodeTranslation(props.forloeb.fravaerskodeEnum)}` : props.uddannelseslaege.laegeNavn}</h5>
                                <h6>{props.forloeb.uddannelsesName + ": " + props.forloeb.specialeName}</h6>
                                <h6>{props.forloeb.afdelingName === null ? props.forloeb.laegeInstitutionName : props.forloeb.laegeInstitutionName + ", " + props.forloeb.afdelingName}</h6>
                                <h6 className="subtle">{props.forloeb.startDate.dateWithoutTimeFormat(false) + " - " + props.forloeb.endDate.dateWithoutTimeFormat(false)}</h6>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}
