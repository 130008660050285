import * as yup from "yup";
import createValidationSchema from "../../components/validation/createValidationSchema";
import {Localizer} from "../../../infrastructure/localization/localizer";

export class SkiftMaalbeskrivelseInputModel {
    public brugerId: string;
    public forloebId: string;
    public uddannelseId: string;
    public nyMaalbeskrivelseId: string;
    public gammelMaalbeskrivelseId: string;
}

export const BuildNew = (brugerId: string, uddannelseId: string, forloebId: string, gammelMaalbeskrivelseId: string): SkiftMaalbeskrivelseInputModel => {
    let inputModel = new SkiftMaalbeskrivelseInputModel();

    inputModel.forloebId = forloebId;
    inputModel.brugerId = brugerId;
    inputModel.uddannelseId = uddannelseId;
    inputModel.gammelMaalbeskrivelseId = gammelMaalbeskrivelseId;

    return inputModel;
}

export const skiftMaalbeskrivelseValidationSchema = createValidationSchema<SkiftMaalbeskrivelseInputModel>({
    nyMaalbeskrivelseId: yup.string().required(Localizer.validate_newMaalbeskrivelseRequired()),
    gammelMaalbeskrivelseId: yup.string().notRequired(),
    brugerId: yup.string().notRequired(),
    uddannelseId: yup.string().notRequired(),
    forloebId: yup.string().notRequired(),
});
