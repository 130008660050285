import { ReactElement } from 'react';
import './cardButtonWithIcon.scss';
import {EmblaIcon} from "../emblaIcon/emblaIcon";
import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../../layout/responsive";
import Tooltip from '../tooltips/Tooltip';
import { EmblaIconsType } from 'core/emblaIcons';

type CardButtonWithIconProps = {
    // Either an iconName for an EmblaIcon or a SVG (ReactElement):
    icon?: EmblaIconsType | ReactElement;
    isCreate?: boolean;
    additionalClasses?: string;
    buttonBottomText?: string;
    buttonBottomInfoText?: string;
    confirmCallback: () => void;
}

export function CardButtonWithIcon({isCreate = false, ...props}: CardButtonWithIconProps) {
    const IsMobile = useMediaQuery(mobileMaxWidthQuery);

    return (
        <div className={`card button-card ${IsMobile ? "flex-fill" : "button-card-width"} ${props.additionalClasses ?? ""}`} onClick={() => props.confirmCallback()}>
            <div className="margin-s flex-container-column">
                <div className={`card-body d-flex button-card-cube ${isCreate ? "button-card-new" : "button-card-skabelon"}`}>
                    <div className="large">
                        {(typeof props.icon === "string")
                            ? <EmblaIcon additionalClasses={`large`} iconName={props.icon as EmblaIconsType}/>
                            : props.icon
                        }
                    </div>
                </div>
                {props.buttonBottomText &&
                    <Tooltip className={"d-flex margin-top-xs"} title={props.buttonBottomText}>
                        <h5 className={"text-truncate"}>{props.buttonBottomText} </h5>
                    </Tooltip>
                }
                {props.buttonBottomInfoText &&
                    <div className={"d-flex margin-top-xs"}>
                        <p className={"small"}>{props.buttonBottomInfoText} </p>
                    </div>
                }
            </div>
        </div>
    )
}
