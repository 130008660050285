import React, {useCallback, useEffect, useState} from "react";
import {DatasetCard} from "./datasetCardComponent";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {UddannelsesstedGruppeScoreCardComponent} from "./uddannelsesstedGruppeScoreCardComponent";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {evalueringsstatistikReducer, EvalueringsstatistikState, setTabState} from "../evalueringsstatistikSlice";
import {Tab, Tabs} from "../../../core/components/Tabs/Tabs";
import {Loading} from "../../../core/components/loading/loading";
import {UddannelsesstedSingleScoreCardComponent} from "./uddannelsesstedSingleScoreCardComponent";
import {DatasetGraph} from "./datasetGraph";
import {CollapseTab} from "../../../core/components/Tabs/collapseTab";
import {PaginationListComponent} from "./paginationListComponent";
import {EvalueringStatistikModel} from "../../../services/api/evalueringStatistik/evalueringStatistikReponseModel";
import {DatasetModel} from "../datasetModel";
import {KommentarerComponent} from "./kommentarerComponent";
import {EvalueringsstatistikPaginationModel} from "../evalueringsstatistikPaginationModel";
import {EvalueringsstatistikTabEnum} from "../evalueringsstatistikTabEnum";
import {AccountService} from "../../../services/account/accountService";
import {StickyHeaderComponent} from "./stickyHeaderComponent";
import {EvalueringsstatistikPageConstants} from "./EvalueringsstatistikPageConstants";
import {EvalueringStatistikApi} from "../../../services/api/evalueringStatistik/evalueringStatistikApi";
import {HttpClientService} from "../../../services/httpClient/httpClientService";
import {BuildNewFromDataset} from "../../../core/componentsPage/evalueringsstatistik/evalueringsstatistikInputModel";
import {genericGroupByAndMap} from "../../../core/utils";
import {
    EvalueringStatistikForloebModel
} from "../../../services/api/evalueringStatistik/evalueringStatistikForloebModel";
import _ from "lodash";
import {FileDownloadHandler} from "../../../services/api/logbogFile/fileDownloadHandler";

const evalueringStatistikApi = new EvalueringStatistikApi(new HttpClientService(), new FileDownloadHandler());

export function UddannelsesEvalueringCardComponent() {

    const evalueringsstatistikSliceState = useAppSelector(evalueringsstatistikReducer) as EvalueringsstatistikState;
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [tabs, setTabs] = useState<Tab[] | undefined>(undefined);
    const [datasetState, setDatasetState] = useState<DatasetModel[]>([]);
    const [datasetPaginated, setDatasetPaginated] = useState<DatasetModel[]>([]);
    const [evalueringerPaginated, setEvalueringerPaginated] = useState<DatasetModel[]>([]);
    const [kommentarerPaginated, setKommentarerPaginated] = useState<DatasetModel[]>([]);
    const [datasetPaginationModel] = useState<EvalueringsstatistikPaginationModel>(new EvalueringsstatistikPaginationModel(0));
    const [evalueringerPaginationModel, setEvalueringerPaginationModel] = useState<EvalueringsstatistikPaginationModel>(new EvalueringsstatistikPaginationModel(0));
    const [kommentarerPaginationModel, setKommentarerPaginationModel] = useState<EvalueringsstatistikPaginationModel>(new EvalueringsstatistikPaginationModel(0));
    const [evalueringerMedKommentarer, setEvalueringerMedKommentarer] = useState<{ evalueringId: string, datasetIndex: number }[]>([]);
    const [uddannelsesStederMedDataset, setUddannelsesStederMedDataset] = useState<{ uddanelsesstedId: string, datasetIndex: number }[]>([]);
    const [antalUddannelsessteder, setAntalUddannelsessteder] = useState(0);
    const [antalEvalueringer, setAntalEvalueringer] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [antalKommentarerState, setAntalKommentarerState] = useState<number>(0);
    const [antalUddannelsesstederMedAdgangTil, setAntalUddannelsesstederMedAdgangTil] = useState<number>(0);

    const dispatch = useAppDispatch();
    const userService = new AccountService();
    const user = userService.getUser();
    const shouldGetDefaultVisning = user.Authenticated && evalueringsstatistikSliceState.shouldFetchDefaultVisning && evalueringsstatistikSliceState.showDefaultDatasaet;

    useEffect(() => {
        setDatasetState(evalueringsstatistikSliceState.dataset)

        let antalKommentarer = 0;
        let antalEvalueringer = 0;
        let antalUddannelsesstederMedAdgangTil = 0;

        const uddannelsessteder = evalueringsstatistikSliceState.dataset
            .flatMap((d, dIndex) => d.uddannelsesstederEvalueringstatistik.filter(x => x.antalEvalueringerMedAdgangTil > 0)
                .map(u => {
                    antalEvalueringer += u.antalEvalueringerMedAdgangTil;
                    antalKommentarer += u.antalKommentarer;
                    antalUddannelsesstederMedAdgangTil += (u.hasAccessToUddannelsessted ? 1 : 0);
                    return {
                        uddanelsesstedId: u.uddannelsesstedId,
                        datasetIndex: dIndex
                    }
                }));

        const evalueringerMedKommentarer = evalueringsstatistikSliceState.dataset
            .flatMap((d, dIndex) => d.uddannelsesstederEvalueringstatistik
                .flatMap(u => u.evalueringMedKommentarerIdsMedAdgangTil
                    .map(e => {
                        return {
                            evalueringId: e,
                            datasetIndex: dIndex
                        }
                    })));

        setUddannelsesStederMedDataset(uddannelsessteder);
        setEvalueringerMedKommentarer(evalueringerMedKommentarer);
        setAntalEvalueringer(antalEvalueringer);
        setAntalKommentarerState(antalKommentarer);
        setAntalUddannelsessteder(evalueringsstatistikSliceState.dataset.flatMap(d => d.uddannelsesstederEvalueringstatistik).length);
        setAntalUddannelsesstederMedAdgangTil(antalUddannelsesstederMedAdgangTil);
    }, [evalueringsstatistikSliceState.dataset]);

    // Dependencies isn't correct, it breaks if you try to auto import dependencies.
    useEffect(() => {
        setIsLoadingData(true);

        setTabs([
            {
                title: `${Localizer.uddannelsessteder()} (${antalUddannelsessteder})`,
                id: EvalueringsstatistikTabEnum.Uddannelsessteder.toString(),
                content: uddannelsesstederView(),
                defaultActive: evalueringsstatistikSliceState.tab === EvalueringsstatistikTabEnum.Uddannelsessteder.toString(),
            },
            {
                title: `${Localizer.evalueringPage_evalueringer()} (${antalEvalueringer})`,
                id: EvalueringsstatistikTabEnum.Evalueringer.toString(),
                content: evalueringerView(),
                defaultActive: evalueringsstatistikSliceState.tab === EvalueringsstatistikTabEnum.Evalueringer.toString(),
                hide: !(user.Authenticated && EvalueringsstatistikPageConstants.getComplexView)
            },
            {
                title: `${Localizer.evalueringPage_kommentarer()} (${antalKommentarerState})`,
                id: EvalueringsstatistikTabEnum.Kommentarer.toString(),
                content: kommentarerView(),
                defaultActive: evalueringsstatistikSliceState.tab === EvalueringsstatistikTabEnum.Kommentarer.toString(),
                hide: !(user.Authenticated && EvalueringsstatistikPageConstants.getComplexView)
            },
        ]);

        setIsLoadingData(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datasetPaginated, evalueringerPaginated, kommentarerPaginated, evalueringsstatistikSliceState.tab, evalueringerPaginationModel, kommentarerPaginationModel, antalEvalueringer, antalKommentarerState, uddannelsesStederMedDataset])

    const handlePagination = useCallback(async (page: number, pageLength: number) => {
        let count: number = 0;
        let datasetList: DatasetModel[] = [];

        let datasetIndex: number = 0;
        let allSpoergsmaal: EvalueringStatistikModel[];

        if(datasetState && datasetState.length > 0) {
            while ((count < pageLength) && (datasetState.length > datasetIndex)){

                const datasetIndexModel: DatasetModel = _.cloneDeep(datasetState[datasetIndex])

                allSpoergsmaal = datasetState.flatMap(d => d.uddannelsesstederEvalueringstatistik.flatMap(e => e ));

                const evalueringerAfterSkip = allSpoergsmaal.slice(((page - 1) * pageLength), allSpoergsmaal.length);

                datasetIndexModel.uddannelsesstederEvalueringstatistik = datasetState[datasetIndex].uddannelsesstederEvalueringstatistik.filter(e => evalueringerAfterSkip.includes(e))

                if(datasetIndexModel.uddannelsesstederEvalueringstatistik.length > pageLength - count){
                    datasetIndexModel.uddannelsesstederEvalueringstatistik = datasetIndexModel.uddannelsesstederEvalueringstatistik.slice(0, pageLength - count);
                    datasetList.push(datasetIndexModel);
                }
                else {
                    datasetList.push(datasetIndexModel);
                }

                count += datasetIndexModel.uddannelsesstederEvalueringstatistik.length;

                datasetIndex++;
            }
        }
        return datasetList;
    }, [datasetState]);

    const handlePaginationOfUddannelsessteder = useCallback(async (elementsToSkip: number, pageLength: number) => {
        setDatasetPaginated(await handlePagination(elementsToSkip, pageLength));
    }, [handlePagination]);

    const handlePaginationEnkelte = useCallback(async (page: number, pageLength: number) => {

        let datasetList: DatasetModel[] = [];

        const firstIndex = (page - 1) * pageLength;

        let uddannelsesstedIdsMedAdgang = datasetState
            .flatMap(e => e.uddannelsesstederEvalueringstatistik)
            .filter(u => u.hasAccessToUddannelsessted && u.antalEvalueringerMedAdgangTil > 0)
            .map(u => u.uddannelsesstedId);

        const uddanelsesstederOnPage = uddannelsesStederMedDataset.filter(x => uddannelsesstedIdsMedAdgang.some(y => y === x.uddanelsesstedId)).slice(firstIndex, firstIndex + pageLength);

        const groupedByDataset = genericGroupByAndMap(uddanelsesstederOnPage, "datasetIndex");

        for (const item of Array.from(groupedByDataset)) {
            const datasetIndex = item[0];
            const uddannelsesstedList = item[1];

            const datasetIndexModel: DatasetModel = _.cloneDeep(datasetState[datasetIndex])
            const uddannelsesstederMedEvalueringer = await evalueringStatistikApi.getUddannelsesstederMedEnkelteEvalueringer(BuildNewFromDataset(datasetIndexModel), uddannelsesstedList.map(u => u.uddanelsesstedId));

            const uddannelsessteder = datasetIndexModel.uddannelsesstederEvalueringstatistik.filter((e) => uddannelsesstederMedEvalueringer.some(e2 => e2.uddannelsesstedId === e.uddannelsesstedId));

            uddannelsesstederMedEvalueringer.forEach(u => {
                const uddannelsessted = uddannelsessteder.find(u2 => u2.uddannelsesstedId === u.uddannelsesstedId);
                if (!uddannelsessted)
                    throw Error("Couldn't find uddannelsessted with id: " + u.uddannelsesstedId + ", navn: " + u.uddannelsesstedNavn);
                uddannelsessted.forloebEvalueringer = u.forloebEvalueringer;
            })

            datasetIndexModel.uddannelsesstederEvalueringstatistik = datasetIndexModel.uddannelsesstederEvalueringstatistik.filter(e => e.forloebEvalueringer.length > 0);

            datasetList.push(datasetIndexModel);
        }

        return datasetList;

    }, [datasetState, uddannelsesStederMedDataset])

    const handlePaginationOfEvalueringer = useCallback(async (page: number, pageLength: number) => {
        setCurrentPage(page);

        const response = await handlePaginationEnkelte(page, pageLength)

        setEvalueringerPaginated(response);

        let uddannelsesstedIdsMedAdgangAntal = datasetState
            .flatMap(e => e.uddannelsesstederEvalueringstatistik
                .find(u => u.hasAccessToUddannelsessted )?.uddannelsesstedId).length;

        setEvalueringerPaginationModel({elementsToSkip: page, pageLength: pageLength, totalCount: uddannelsesstedIdsMedAdgangAntal});


    }, [datasetState, handlePaginationEnkelte]);

    const handlePaginationOfKommentarer = useCallback(async (page: number, pageLength: number) => {
        let datasetList: DatasetModel[] = [];

        const firstIndex = (page - 1) * pageLength;

        const evalueringerOnPage = evalueringerMedKommentarer.slice(firstIndex, firstIndex + pageLength);

        const groupedByDataset = genericGroupByAndMap(evalueringerOnPage, "datasetIndex");

        for (const item of Array.from(groupedByDataset)) {
            const datasetIndex = item[0];
            const evalueringList = item[1];

            const datasetIndexModel: DatasetModel = _.cloneDeep(datasetState[datasetIndex])

            const evalueringerMedKommentarerResponse = await evalueringStatistikApi.getEvalueringerMedKommentarer(BuildNewFromDataset(datasetIndexModel), evalueringList.map(u => u.evalueringId));

            const uddannelsesstedEvaluering = datasetIndexModel.uddannelsesstederEvalueringstatistik
                .filter((e) => evalueringerMedKommentarerResponse.some(e2 => e2.uddannelsesstedId === e.uddannelsesstedId))
                .filter((value, index, array) => array.indexOf(value) === index);

            if (!uddannelsesstedEvaluering)
                throw Error("Couldn't find uddannelsessteder with any of the following ids: " + evalueringerMedKommentarerResponse.map(x => x.uddannelsesstedId).join(", "));

            for (const evalueringStatistikModel of uddannelsesstedEvaluering) {
                const forloebevalueringer :EvalueringStatistikForloebModel[] = [];

                evalueringerMedKommentarerResponse.filter(emk => emk.uddannelsesstedId === evalueringStatistikModel.uddannelsesstedId).forEach(u => {
                    forloebevalueringer.push(u);
                });

                if (evalueringStatistikModel)
                    evalueringStatistikModel.forloebEvalueringer = forloebevalueringer;
            }

            datasetList.push(datasetIndexModel);
        }
        return datasetList;
    }, [datasetState, evalueringerMedKommentarer])

    const handlePaginationOfEvalueringerMedKommentarer = useCallback(async (elementsToSkip: number, pageLength: number) => {
        setKommentarerPaginated(await handlePaginationOfKommentarer(elementsToSkip, pageLength));
        setKommentarerPaginationModel({elementsToSkip: elementsToSkip, pageLength: pageLength, totalCount: evalueringerMedKommentarer.length});
    }, [evalueringerMedKommentarer.length, handlePaginationOfKommentarer]);


    function uddannelsesstederView() {
        return (
            <PaginationListComponent
                key={"uddannelse-" + datasetState.length}
                totalCount={antalUddannelsessteder}
                currentPage={1}
                pageSize={datasetPaginationModel.pageLength}
                bodyContent={renderUddannelsessteder()}
                arrayItemsChangedCallback={handlePaginationOfUddannelsessteder}/>
        )
    }

    function evalueringerView() {
        return (
                <PaginationListComponent
                    key={"evalueringer-" + datasetState.length}
                    totalCount={antalUddannelsesstederMedAdgangTil}
                    currentPage={1}
                    bodyContent={renderEvalueringer()}
                    pageSize={evalueringerPaginationModel.pageLength}
                    arrayItemsChangedCallback={handlePaginationOfEvalueringer}/>
        )
    }

    function kommentarerView() {
        return (
            <PaginationListComponent
                key={"kommentarer-" + datasetState.length}
                totalCount={kommentarerPaginationModel.totalCount}
                currentPage={1}
                bodyContent={renderKommentarer()}
                pageSize={kommentarerPaginationModel.pageLength}
                arrayItemsChangedCallback={handlePaginationOfEvalueringerMedKommentarer}/>
        )
    }

    const renderKommentarer = () => {
        return (
            <div className="margin-top-m">
                <Loading isLoading={isLoadingData} text={Localizer.evalueringPage_henterData()} timeout={100} spinnerClasses={"margin-left-m"}>
                    <div>
                        {kommentarerPaginated.map((dataset, index) =>
                            <div key={dataset.datasetColor + index}>
                                {dataset.uddannelsesstederEvalueringstatistik.map((evaluering, evalueringIndex) =>
                                    <div key={evaluering.uddannelsesstedId + evalueringIndex}>
                                        {evaluering && evaluering.forloebEvalueringer.length > 0 &&
                                            <div>
                                                {evaluering.forloebEvalueringer.map(forloebevaluering =>
                                                    <div key={forloebevaluering.id}>
                                                        <KommentarerComponent
                                                            dataset={dataset}
                                                            forloebEvaluering={forloebevaluering}
                                                            uddannelsesstedNavn={evaluering.uddannelsesstedNavn}
                                                            sygehusNavn={evaluering.sygehusNavn}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        }
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </Loading>
            </div>
        )
    }

    const renderUddannelsessteder = () => {
        return (
            <div className="margin-top-s sticky-header-container">
                <StickyHeaderComponent tab={EvalueringsstatistikTabEnum.Uddannelsessteder}/>
                <Loading isLoading={isLoadingData} text={Localizer.evalueringPage_henterData()} timeout={100} spinnerClasses={"margin-left-m"}>
                    <div>
                        {datasetPaginated.map((data, index) =>
                            <div key={data.datasetColor + index}>
                                {data.uddannelsesstederEvalueringstatistik.map((evaluering, evalIndex) =>
                                    <div key={evaluering.uddannelsesstedId + evalIndex} className="dataset-list-item">
                                        <UddannelsesstedGruppeScoreCardComponent key={evaluering.uddannelsesstedId + index}
                                                                                 evaluering={evaluering}
                                                                                 dataset={data}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </Loading>
            </div>
        )
    }

    const isFirstEvaluationInFirstDatasetOnFirstPage = (evalIndex: number, dataIndex: number) => evalIndex === 0 && dataIndex === 0 && currentPage === 1;

    const renderEvalueringer = () => {
        return (
            <div className="margin-top-s sticky-header-container">
                <StickyHeaderComponent tab={EvalueringsstatistikTabEnum.Evalueringer}/>
                <Loading isLoading={isLoadingData} text={Localizer.evalueringPage_henterData()} timeout={100} spinnerClasses={"margin-left-m"}>
                    <div>
                        {evalueringerPaginated.map((dataset, dataIndex) =>
                            <div key={dataset.datasetColor + dataIndex}>
                                {dataset.uddannelsesstederEvalueringstatistik.map((evaluering, evalIndex) =>
                                    <div key={dataset.datasetColor + evaluering.uddannelsesstedId + dataIndex + evalIndex}>
                                        {evaluering.forloebEvalueringer.length > 0 &&
                                            <CollapseTab
                                                id={evaluering.uddannelsesstedId + evalIndex + dataIndex}
                                                additionalClasses={"evaluering-card-padding"}
                                                title={evaluering.sygehusNavn}
                                                subTitle={`${evaluering.uddannelsesstedNavn} (${evaluering.forloebEvalueringer.length})`}
                                                expandedDefault={isFirstEvaluationInFirstDatasetOnFirstPage(evalIndex, dataIndex)}
                                                key={dataset.datasetColor + evaluering.uddannelsesstedId + evalIndex}
                                                leftBorderColor={dataset.datasetColor}>
                                                {evaluering.forloebEvalueringer.sort((a, b) => a.indsendtDato < b.indsendtDato ? 1 : -1).map((forloebEvaluering, forloebevalueringIndex) =>
                                                    <UddannelsesstedSingleScoreCardComponent
                                                        key={forloebEvaluering.evalueringId + evaluering.uddannelsesstedId + forloebevalueringIndex}
                                                        forloebEnkeltEvaluering={forloebEvaluering}
                                                        dataset={dataset}
                                                    />
                                                )}
                                            </CollapseTab>
                                        }
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </Loading>
            </div>
        )
    }

    const setTab = (tab: Tab) => {
        dispatch(setTabState(tab.id));
    }

    function getPeriodeDateStringForDefaultEvalueringsstatistik(index: number) {
        const startDate = new Date(new Date().getUTCFullYear() - (index + 1), 0, 1).dateWithoutTimeFormat(true);
        const endDate = new Date(new Date().getUTCFullYear() - (index + 1), 11, 31).dateWithoutTimeFormat(true);
        return ` ${Localizer.global_iPerioden()}: ${startDate} - ${endDate}`;
    }

    const getDatasetCardsForPrint = (datasets: DatasetModel[]) => (
        datasets.map((dataset, index) => (
            <DatasetCard
                key={dataset.datasetColor + index}
                dataset={dataset}
                datasetIndex={index}
                hideFilters
                additionalClassNames="dataset-print"
            />
        ))
    )

    const getCardContent = () => {
        let periode = "";
        if (datasetState && datasetState.length > 0) {
            return (
                <>
                    <DatasetGraph/>
                    <div className="card-body">
                        {getDatasetCardsForPrint(datasetState)}
                        {datasetState.map((dataset, index) => {
                                if (shouldGetDefaultVisning)
                                    periode = getPeriodeDateStringForDefaultEvalueringsstatistik(index);

                                return <DatasetCard key={dataset.datasetColor + index}
                                                    dataset={dataset}
                                                    datasetIndex={index}/>
                            }
                        )}
                        {shouldGetDefaultVisning &&
                            <div className="margin-top-m margin-left-s">
                                <Loading isLoading={user.Authenticated && evalueringsstatistikSliceState.shouldFetchDefaultVisning} text={Localizer.global_getDefaultEvalueringsstatistik() + periode}>
                                    <div/>
                                </Loading>
                            </div>
                        }
                    </div>
                    <Loading isLoading={isLoadingData} text={Localizer.global_getEvalueringsstatistik()}>
                        {tabs &&
                            <Tabs tabs={tabs} tabClickedCallback={setTab}/>
                        }
                    </Loading>
                </>
            )
        } else {
            return (
                <div className="no-results-message padding-bottom-xl">
                    <h3 className="subtle flex-row-center">{Localizer.createEvalueringsstatistikPage_intetIndhold()}</h3>
                    <p className="subtle flex-row-center">{Localizer.createEvalueringsstatistikPage_noDataset()}</p>
                    {shouldGetDefaultVisning &&
                        <div className={"margin-top-l"}>
                            <Loading spinnerClasses={"justify-content-center"} isLoading={user.Authenticated && evalueringsstatistikSliceState.shouldFetchDefaultVisning} text={Localizer.global_getDefaultEvalueringsstatistik()}><div/></Loading>
                        </div>
                    }
                </div>
            )
        }
    }
    return (
        <div className="uddannelsessteder-evaluring-card">
            {getCardContent()}
        </div>
    )
}
