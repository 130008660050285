import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../../app/hooks";
import {evalueringsstatistikReducer, EvalueringsstatistikState} from "../evalueringsstatistikSlice";
import "../evalueringstatistik.scss";
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import useEffectAsync, {useEffectOnUpdate} from "../../../infrastructure/effect";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {
    evalueringsstatistikFiltersReducer,
    EvalueringsstatistikFiltersState
} from "../evalueringsstatistikFiltersSlice";
import {SpoergsmaalTypeEnum} from "../../../core/sharedmodels/evaluering/spoergsmaalTypeEnum";
import {EvalueringsstatistikPageConstants} from "./EvalueringsstatistikPageConstants";
import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../../../core/layout/responsive";
import {EvalueringStatistikApi} from "../../../services/api/evalueringStatistik/evalueringStatistikApi";
import {HttpClientService} from "../../../services/httpClient/httpClientService";
import {LogbogSpoergsmaalResponseModel} from "../../../services/api/logbogEvaluering/logbogSpoergsmaalResponseModel";
import './datasetGraph.scss';
import {DatasetModel} from "../datasetModel";
import { GetQuestionNumbersInGroup, SvarGruppeEnum } from "core/sharedmodels/evaluering/svarGruppeEnum";
import CustomTooltipContent from "core/components/tooltips/CustomTooltipContent";
import {FileDownloadHandler} from "../../../services/api/logbogFile/fileDownloadHandler";

const evalueringstatistikApi = new EvalueringStatistikApi(new HttpClientService(), new FileDownloadHandler());

export function DatasetGraph() {
    const evalueringsstatistikSliceState = useAppSelector(evalueringsstatistikReducer) as EvalueringsstatistikState;
    const evalueringsstatistikFilterSliceState = useAppSelector(evalueringsstatistikFiltersReducer) as EvalueringsstatistikFiltersState;
    const [averageScoreForEachGroupInEachDataSet, setAverageScoreForEachGroupInEachDataSet] = useState<number[][]>([])
    const [dataToGraphState, setDataToGraphState] = useState<any[]>([])
    const [spoergsmaalTekster, setSpoergsmaalTekster] = useState<LogbogSpoergsmaalResponseModel[]>([]);
    const [datasetState, setDatasetState] = useState<DatasetModel[]>([])
    const [isLoading, setIsLoading] = useState(false);

    const IsMobile = useMediaQuery(mobileMaxWidthQuery);

    useEffect(() => {
        setDatasetState(evalueringsstatistikSliceState.dataset);
    },[evalueringsstatistikSliceState.dataset, evalueringsstatistikSliceState.showDefaultDatasaet])

    useEffect( () => {
        const fetchSpoergsmaal = async () => {
            setIsLoading(true);
            const forloebEvalueringModel = await evalueringstatistikApi.getForloebEvalueringSpoergsmaal();
            forloebEvalueringModel.sektioner.forEach(s => s.spoergsmaal.forEach(sp => sp.sortOrder = s.sortOrder));
            let questions = forloebEvalueringModel.sektioner.flatMap(s => s.spoergsmaal);
            setSpoergsmaalTekster(questions);
            setIsLoading(false);
        }
        fetchSpoergsmaal();
    }, [])

    useEffectAsync(() => {
        setAverageScoreForEachGroupInEachDataSet([]);
        evalueringsstatistikSliceState.dataset.forEach(x => {
            setAverageScoreForEachGroupInEachDataSet(arr => [...arr,
                (evalueringsstatistikFilterSliceState.spoergsmaalsType === SpoergsmaalTypeEnum.Grupperet
                    ? x.avgScoresGroup
                    : x.avgScoresSingle) ?? []]);
        });
    }, [evalueringsstatistikSliceState.dataset, evalueringsstatistikFilterSliceState.spoergsmaalsType, evalueringsstatistikFilterSliceState.spoergsmaalToShow])

    const mapDatasetsToTooltipItems = (payload: any[]) =>
        (
            <React.Fragment>
                {evalueringsstatistikSliceState.dataset.map((dataset, i) => {
                    if (payload[i] === undefined)
                        return <React.Fragment key={i}></React.Fragment>

                    const color = payload[i]["fill"];
                    const value = payload[i]["value"];
                    const text = payload[i]["dataKey"];

                    return (
                        <CustomTooltipContent.Item
                            key={i}
                            index={i}
                            color={color}
                            text={`${text} : ${value}`}
                        />
                    )
                })
                }
            </React.Fragment>
        );

    useEffect(() => {
        function dataFromSelectedQuestions(dataToGraphFormat: any) {
            evalueringsstatistikFilterSliceState.spoergsmaalToShow?.forEach((spoergsmaal, index) => {
                if (+spoergsmaal < EvalueringsstatistikPageConstants.numberOfQuestionsIncludingOptionalTextInput) {
                    let groupGraphObject = {
                        name: spoergsmaal,
                    }
                    for (let j = 0; j < averageScoreForEachGroupInEachDataSet.length; j++) {
                        averageScoreForEachGroupInEachDataSet.forEach(score => {
                            const titel = datasetState[j]?.title ? ` ${datasetState[j]?.title} ` : " ";
                            const datakey = Localizer.evalueringPage_gennemsnit() + titel + `(${Localizer.evalueringPage_dataset() + (++j)})`;
                            if (!isNaN(score[(+spoergsmaal) - 1])) {
                                Object.defineProperty(groupGraphObject, datakey, {
                                    value: score[(+spoergsmaal) - 1]?.toFixed(1)
                                });
                            }
                        });
                    }
                    dataToGraphFormat.push(groupGraphObject)
                }
            })
        }

        function dataFromAllQuestions(spoergsmaalAmount: number, dataToGraphFormat: any) {
            for (let i = 1; i <= spoergsmaalAmount; i++) {
                let groupGraphObject = {
                    name: i.toString(),
                }
                for (let j = 0; j < averageScoreForEachGroupInEachDataSet.length; j++) {

                    averageScoreForEachGroupInEachDataSet.forEach(groupScore => {
                        const titel = datasetState[j]?.title ? ` ${datasetState[j]?.title} ` : " ";
                        const datakey =  Localizer.evalueringPage_gennemsnit() + titel + `(${Localizer.evalueringPage_dataset() + (++j)})`;
                        if (!isNaN(groupScore[i - 1])) {
                            Object.defineProperty(groupGraphObject, datakey, {
                                value: groupScore[i - 1]?.toFixed(1)
                            });
                        }
                    });
                }
                dataToGraphFormat.push(groupGraphObject)
            }
        }

        const spoergsmaalAmount = evalueringsstatistikFilterSliceState.spoergsmaalsType === SpoergsmaalTypeEnum.Grupperet
            ? EvalueringsstatistikPageConstants.numberOfGroups
            : EvalueringsstatistikPageConstants.numberOfQuestionsExcludingOptionalTextInput;

        const dataToGraphFormat: any = [];

        const showSpecificQuestions = evalueringsstatistikFilterSliceState.spoergsmaalToShow.length > 0;

        if (showSpecificQuestions) {
            dataFromSelectedQuestions(dataToGraphFormat);
        } else {
            dataFromAllQuestions(spoergsmaalAmount, dataToGraphFormat);
        }
        setDataToGraphState(dataToGraphFormat)
    },[averageScoreForEachGroupInEachDataSet, evalueringsstatistikFilterSliceState.spoergsmaalsType, evalueringsstatistikFilterSliceState.spoergsmaalToShow, evalueringsstatistikSliceState.dataset, datasetState])

    function getDataSetToShow(): JSX.Element[] {
        let bars: JSX.Element[] = [];
        for (let i = 0; i < datasetState.length; i++) {
            const titel = datasetState[i]?.title ? ` ${datasetState[i]?.title} ` : " ";
            const datakey = Localizer.evalueringPage_gennemsnit() + titel + `(${Localizer.evalueringPage_dataset() + (i+1)})`;
            bars.push( <React.Fragment key={datakey + i}>
                <Bar dataKey={datakey} fill={datasetState[i].datasetColor} maxBarSize={12.4} radius={20}/>
            </React.Fragment>)
        }
        return bars
    }

    const isPayloadValid = (payload: any) => {
        return payload !== null && typeof payload[0] !== 'undefined';
    }

    // https://recharts.org/en-US/examples/CustomContentOfTooltip
    const CustomRechartTooltipContent = ({payload, label}: any): JSX.Element => {
        const shouldRender = isPayloadValid(payload) && !isLoading;
        return (
            <React.Fragment>
                {shouldRender &&
                    <CustomTooltipContent>
                        <CustomTooltipContent.Header
                            boldTitleText={`${label} `}
                            titleText={spoergsmaalTekster[label-1].titel}
                        />
                        { mapDatasetsToTooltipItems(payload) }
                    </CustomTooltipContent>
                }
            </React.Fragment>
        );
    }

    const CustomRechartGroupTooltipContent = ({payload, label}: any): JSX.Element => {
        const answerNumbersInGroup = GetQuestionNumbersInGroup(Number(label) as SvarGruppeEnum);
        const shouldRender = isPayloadValid(payload) && !isLoading;
        return (
            <>
                {shouldRender &&
                    <CustomTooltipContent>
                        <CustomTooltipContent.Header
                            boldTitleText={`${Localizer.evalueringPage_spoergsmaalsgruppe()} ${label}`}
                            titleText={` (${Localizer.evalueringPage_spoergsmaalHeader()}: ${Math.min(...answerNumbersInGroup)}-${Math.max(...answerNumbersInGroup)})`}
                        />
                        { mapDatasetsToTooltipItems(payload) }
                    </CustomTooltipContent>
                }
            </>
        );
    }

    return (
        <React.Fragment>
            <div className={`${IsMobile ? "graph-mobile-container" : "graph-container"}`}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={800}
                        height={300}
                        data={dataToGraphState}
                        margin={{
                            top: 5,
                            right: 20,
                            left: 5,
                            bottom: 5
                        }}
                        barCategoryGap="30%"
                    >
                        <CartesianGrid strokeDasharray="3 5" vertical={false}/>
                        <XAxis
                            dataKey="name"
                            axisLine={false}
                            tickLine={false}
                            tickMargin={12}
                        />
                        <YAxis
                            type="number"
                            axisLine={false}
                            tickLine={false}
                            tickMargin={32}
                            tickCount={7}
                            domain={[0, 6]}
                        />
                        {evalueringsstatistikFilterSliceState.spoergsmaalsType === SpoergsmaalTypeEnum.Enkeltvis &&
                            <Tooltip content={<CustomRechartTooltipContent/>} />
                        }

                        {evalueringsstatistikFilterSliceState.spoergsmaalsType === SpoergsmaalTypeEnum.Grupperet &&
                            <Tooltip content={<CustomRechartGroupTooltipContent/>} />
                        }
                        {getDataSetToShow()}
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </React.Fragment>
    );
}
