import { useUserContext } from "core/components/user/userContextProvider";
import { BrugerIndstillingTypeKeyEnum, castBrugerIndstillingValueToCorrectType } from "core/sharedmodels/brugerIndstilling/brugerIndstillingTypeKeyEnum";
import useStamdataApi from "./useStamdataApi";
import { useCallback, useEffect, useState } from "react";

export type UserSettingValueType = number | boolean | string | number[];

const useUserSettings = () => {
  const { currentUser, setUserSetting, settingsLoading } = useUserContext();
  const { stamdataBrugerIndstillingApi } = useStamdataApi();

  const getSettingValue = useCallback(<TValueType extends UserSettingValueType>(typeKey: BrugerIndstillingTypeKeyEnum, fallbackValue: TValueType) => {
    const setting = currentUser.settings.find(x => x.typeKey === typeKey);

    const settingValue = !!setting 
      ? castBrugerIndstillingValueToCorrectType(typeKey, setting.value) ?? fallbackValue 
      : fallbackValue;

    return settingValue as TValueType;
  }, [currentUser.settings])

  const setSettingValue = useCallback(async <TValueType extends UserSettingValueType>(typeKey: BrugerIndstillingTypeKeyEnum, value: TValueType) => {
    const valueString = JSON.stringify(value);
    setUserSetting(typeKey, valueString);

    await stamdataBrugerIndstillingApi.SetBrugerIndstilling(typeKey, valueString);
  }, [setUserSetting, stamdataBrugerIndstillingApi]);

  return {
      userSettings: currentUser.settings,
      userSettingsIsLoading: settingsLoading,
      getUserSettingValue: getSettingValue,
      setUserSettingValue: setSettingValue,
  }
};

export const useInitializeViaUserSettings = (settingsIsLoading: boolean, onSettingsReady: () => void) => {
  const [hasLoadedSavedOptions, setHasLoadedSavedOptions] = useState<boolean>(false);

  useEffect(() => {
    const onOptionsLoaded = () => {
      onSettingsReady()
      setHasLoadedSavedOptions(true);
    }

    if (!settingsIsLoading && !hasLoadedSavedOptions) {
      // Only load saved options when they are ready + restrict to one-time load:
      onOptionsLoaded();
    }
  }, [hasLoadedSavedOptions, onSettingsReady, settingsIsLoading]);

}

export default useUserSettings;
