import {
    addStateToMenuLink, filerMenuLink,
    forloebMenuLink,
    kompetencerMenuLink,
    kursusMenuLink,
    notaterMenuLink,
    vejledningMenuLink
} from "core/layout/shared/MenuLinkProps";
import { DynamicMenu } from "../DynamicMenu";
import useUser from "core/hooks/useUser";
import { useNyhederMenuLink } from "../hooks/useNyhederMenuLink";

type UddannelsesLaegeMenuProps = {
    uddannelseslaegeId: string;
    showNotifications?: boolean;
};

export const UddannelsesLaegeMenu = ({uddannelseslaegeId, showNotifications } : UddannelsesLaegeMenuProps) => {

    const { currentUser } = useUser();
    const nyhederMenuLink = useNyhederMenuLink();

    const forloebLink = forloebMenuLink(uddannelseslaegeId);
    const kursusLink = kursusMenuLink(uddannelseslaegeId);
    const kompetencerink = kompetencerMenuLink(uddannelseslaegeId);
    const vejledningLink = vejledningMenuLink(uddannelseslaegeId);
    const filerLink = filerMenuLink(uddannelseslaegeId);
    const notaterLink = notaterMenuLink(uddannelseslaegeId);

    const isHrMedarbejder = currentUser.IsHrMedarbejder();
    const customNyhedMenuLink = currentUser.IsLaege() ? nyhederMenuLink : addStateToMenuLink(nyhederMenuLink, { userId: uddannelseslaegeId });

    const allForloebSpecificLinks = [
        forloebLink,
        kompetencerink,
        kursusLink,
        vejledningLink,
    ];

    const allMenuLinks = [
        ...allForloebSpecificLinks,
        notaterLink,
        customNyhedMenuLink,
        filerLink,
    ];

    const generateMenuLinks = () => {

        const showOnlyEssentialMenuLinks = currentUser.IsSekretaer() || currentUser.IsKonsulentForLaegeligVidereuddannelse() || isHrMedarbejder || currentUser.IsLedelse();
        if (showOnlyEssentialMenuLinks)  {
            return allMenuLinks.filter(x => [forloebLink, customNyhedMenuLink].includes(x));
        }

        const showEssentialMenuLinksAndKursus = currentUser.IsKursusleder() || currentUser.IsKursusudbyder() || currentUser.IsForskningstraeningsvejleder();
        if(showEssentialMenuLinksAndKursus) {
            return allMenuLinks.filter(x => [forloebLink, kursusLink, customNyhedMenuLink].includes(x))
        };

        return allMenuLinks;
    }

    return <DynamicMenu menuLinks={generateMenuLinks()} showNotifications={showNotifications} />;
}
