import React, {useEffect, useState} from 'react'
import {Localizer} from "../../../../infrastructure/localization/localizer";
import {EmblaIcon} from "../../../../core/components/emblaIcon/emblaIcon";
import {ReactComponent as GreenCircle} from "../../../../content/icons/greenCircle.svg";
import {NotificationModule} from "ditmer-embla";
import {SlideIn} from "../../../../core/components/slideIn/slideIn";
import {CreateUddannelsesplan} from "./createUddannelsesplan";
import {UddannelsesplanModel} from "../uddannelsesplanModel";
import {EditUddannelsesplan} from "./editUddannelsesplan";
import {LogbogUddannelsesplanApi} from 'services/api/logbogUddannelsesplan/logbogUddannelsesplanApi';
import {HttpClientService} from 'services/httpClient/httpClientService';
import {AccountService} from 'services/account/accountService';
import {ModalAcceptType, ModalSubmitMessage} from 'core/components/modalMessage/modalSubmitMessage';
import {ReactComponent as BlueCircle} from "../../../../content/icons/blueCircle.svg";
import {ReactComponent as YellowCircle} from "../../../../content/icons/yellowCircle.svg";
import useUser from "../../../../core/hooks/useUser";
import {useRouteMatch} from "react-router-dom";
import {userVejledningQueryParams} from "../../../../infrastructure/route/queryParams";
import Title from 'core/components/titels/title';
import CalendarTimeDisplay from 'core/components/timedisplayers/Calendar';

type UddannelsesplanProps = {
    uddannelsesplan: UddannelsesplanModel
    lastIndex: boolean;
    uddannelsesplanerUpdatedCallback?: () => void;
    readOnly?: boolean;
}

const uddannelsesplanApi = new LogbogUddannelsesplanApi(new HttpClientService(), new AccountService())

export function Uddannelsesplan({
    uddannelsesplan,
    lastIndex,
    uddannelsesplanerUpdatedCallback,
    readOnly = false
}: UddannelsesplanProps) {
    const { currentUser } = useUser();
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const editmodalId = "uddannelsesplan-edit-modal";
    const createmodalId = "uddannelsesplan-create-modal";
    const confirmModalId = "uddannelsesplan-confirm-modal";

    const { params } = useRouteMatch<userVejledningQueryParams>();

    useEffect(() => {
        if(!params.uddannelsesplan) return;
        setEditModalOpen(uddannelsesplan.id === params.uddannelsesplan);
    }, [params.uddannelsesplan, uddannelsesplan.id]);

    async function editUddannelsesplan() {
        if (uddannelsesplan.id) {
            setEditModalOpen(true);
        }
    }

    const onUddannelsesplanerUpdated = () => {
        if (uddannelsesplanerUpdatedCallback) {
            uddannelsesplanerUpdatedCallback();
        }
    }

    const UddannelsesplanOprettet = async () => {
        onUddannelsesplanerUpdated();
        NotificationModule.showSuccess(Localizer.vejledningPage_uddannelsesplanCreated(), "");
    }

    const uddannelsesplanListUpdated = async () => {
        setEditModalOpen(false)
        onUddannelsesplanerUpdated();
    }

    const uddannelsesplanSlettet = async () => {
        onUddannelsesplanerUpdated();
        NotificationModule.showSuccess(Localizer.vejledningPage_uddannelsesplanDeleted(), "");
    }
    const uddannelsesplanRedigeret = async (enighedTilkendegivet: boolean) => {
        onUddannelsesplanerUpdated();
        NotificationModule.showSuccess(enighedTilkendegivet ? Localizer.vejledningPage_uddannelsesplanEnighedTilkendegivet() : Localizer.vejledningPage_uddannelsesplanEditted(), "");
    }

    const fjernGodkendelse = async () => {
        const godkendelseFjernet = await uddannelsesplanApi.fjernGodkendelseUddannelsesplan(uddannelsesplan.id);
        if (godkendelseFjernet) {
            NotificationModule.showSuccess(Localizer.uddannelsesplan_deleteGodkendelseNotification(), "");
        }
        onUddannelsesplanerUpdated();
    }

    const toggleKlarmeld = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        await uddannelsesplanApi.toggleKlarmeldtAt(uddannelsesplan.id)
        onUddannelsesplanerUpdated();
    }

    return (
        <>
            <div className="flex-container" onClick={() => editUddannelsesplan()}>
                {uddannelsesplan.id &&

                    <div className="flex-container-column">
                        {lastIndex &&
                            <div>
                                <p className="subtle margin-0">{Localizer.forloebpage_currentUddannelsesplan()}</p>
                            </div>
                        }

                        <Title
                            title={uddannelsesplan.titel}
                            subTitle={Localizer.global_createdBy(uddannelsesplan.createdByName)}
                        />

                        <CalendarTimeDisplay
                            dato={uddannelsesplan.oprettelsesDato}
                            startTime={uddannelsesplan.oprettelsesDato}
                        />

                        {uddannelsesplan.klarmeldtAt && !uddannelsesplan.godkendelseTilkendegivetAf && !uddannelsesplan.godkendelseTilkendegivetDato &&
                            <div className="margin-top-s ">
                                <span className="badge badge-pill badge-default padding-xs bg-white border ">
                                    <YellowCircle className="margin-right-xs"/>
                                    {Localizer.global_klarmeldtden()}
                                </span>
                            </div>
                        }

                        {!uddannelsesplan.klarmeldtAt && !uddannelsesplan.godkendelseTilkendegivetAf && !uddannelsesplan.godkendelseTilkendegivetDato &&
                            <div className="margin-top-s ">
                                <span className="badge badge-pill badge-default padding-xs bg-white border ">
                                    <BlueCircle className="margin-right-xs"/>
                                    {Localizer.kursusPage_udfyldt()}
                                </span>
                            </div>
                        }

                        {uddannelsesplan.godkendelseTilkendegivetAf && uddannelsesplan.godkendelseTilkendegivetDato &&
                            <div className="margin-top-s">
                                <span className="badge badge-pill badge-default padding-xs">
                                    <GreenCircle className="margin-right-xs"/>
                                    {Localizer.vejlederPage_uddannelsesplanGodkendtAf(uddannelsesplan.godkendelseTilkendegivetDato.dateWithoutTimeFormat(true), uddannelsesplan.godkendelseTilkendegivetAf)}
                                </span>
                            </div>
                        }
                    </div>
                }

                {currentUser.IsLaege() && !uddannelsesplan.godkendelseTilkendegivetDato && !uddannelsesplan.godkendelseTilkendegivetAf &&
                    <button className={"btn btn-default rounded-circle btn-icon flex-align-r align-self-center"}
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => toggleKlarmeld(e)}>
                        <EmblaIcon iconName={!!uddannelsesplan.klarmeldtAt ? "remove" : "success"}/>
                    </button>
                }
            </div>

            {!readOnly && createModalOpen &&
                <SlideIn
                    id={createmodalId}
                    title={Localizer.uddannelsesplan_createUddannelsesplan()}
                    actionText={Localizer.global_creating()}
                    actionFinishedCallback={() => UddannelsesplanOprettet()}
                    actionOnCloseCallback={() => setCreateModalOpen(false)}
                    defaultOpen={true}
                    bodyContent={<CreateUddannelsesplan modalId={createmodalId}/>}
                />
            }
            {!readOnly && editModalOpen &&
                <SlideIn
                    id={editmodalId}
                    title={Localizer.uddannelsesplan_editUddannelsesplan()}
                    actionText={Localizer.global_editing()}
                    actionFinishedCallback={() => uddannelsesplanListUpdated()}
                    actionOnCloseCallback={() => setEditModalOpen(false)}
                    defaultOpen={true}
                    bodyContent={<EditUddannelsesplan
                        modalId={editmodalId}
                        model={uddannelsesplan}
                        editedCallBack={(enighedTilkendegivet: boolean) => uddannelsesplanRedigeret(enighedTilkendegivet)}
                        deletedCallBack={() => uddannelsesplanSlettet()}
                        confirmModalId={confirmModalId + uddannelsesplan.id}
                        openConfirmModal={() => setConfirmModalOpen(true)}
                    />}
                />
            }

            {!readOnly && confirmModalOpen &&
                <ModalSubmitMessage modalId={confirmModalId + uddannelsesplan.id}
                                    title={Localizer.areYouSure()}
                                    description={<p>{Localizer.uddannelsesplan_deleteGodkendelseDescription()}</p>}
                                    cancelAction={() => setEditModalOpen(true)}
                                    acceptAction={() => fjernGodkendelse()}
                                    modalAcceptType={ModalAcceptType.danger}
                                    acceptButtonText={Localizer.global_notAgree()}
                />
            }
        </>
    )
}
