type HorizontalLineProps = {
    rowIndex: number;
    columnStart: number;
    columnEnd: number;
    rowColor?: string;
}
export const HorizontalLine = ({rowIndex, columnStart, columnEnd, rowColor}: HorizontalLineProps) => {
    const linestyle = {
        "--horizontal-line-row-index": rowIndex,
        "--column-start": columnStart,
        "--column-end": columnEnd,
        "--line-row-color": rowColor
    } as React.CSSProperties;
    return (
        <div className={`${rowColor ? "horizontal-line-fat" : "horizontal-line"}`} style={linestyle}/>
    )
}
