import React from "react";
import {EmblaIllustration} from "../emblaIcon/emblaIllustration";
import {Localizer} from "../../../infrastructure/localization/localizer";

export const NoResults = () => {
    return (
        <div className={"text-center"}>
            <EmblaIllustration illustrationName={"failure-mark"}
                               additionalClasses={"subtle"}/>
            <h4 className={"subtle"}> {Localizer.noResults()} </h4>
        </div>
    )
}
