import InfoModal from "core/components/modalMessage/infoModal";
import {ReactNode} from "react";
import classNames from "classnames";

export type ForloebOverviewCardProps = {
    children?: ReactNode;
    rightHeaderContent?: ReactNode,
    title?: string
    paddingNone?: boolean
    infoModal?: {text: string, id: string}
}

export function ForloebOverviewCard({children, rightHeaderContent, title, infoModal, paddingNone = false}: ForloebOverviewCardProps) {
    return (
        <div className="card">
            {title &&
                <div className={`card-header d-flex justify-content-between ${!children ? "border-0" : ""}`}>
                    <div className="d-flex">
                        <h4 className="card-title">
                            {title}
                        </h4>

                        {infoModal &&
                            <InfoModal id={infoModal.id} text={infoModal.text}/>
                        }
                    </div>

                    {rightHeaderContent &&
                        rightHeaderContent
                    }
                </div>
            }
            {children &&
                <div className={classNames("card-body", paddingNone && "padding-0")}>
                    {children}
                </div>
            }

        </div>
    );
}
