import {KompetenceSpoergsmaalModel} from "../../../core/sharedmodels/evaluering/kompetenceSpoergsmaalModel";
import React from "react";
import {KompetenceSvarModel} from "../../../core/sharedmodels/evaluering/KompetencesvarModel";
import {DeleteEvalueringSvarModel} from "../../../services/api/logbogEvaluering/deleteEvalueringSvarModel";
import {KompetenceEvalueringSvar} from "../kompetenceEvalueringSvar";

type KompetenceEvalueringSpoergsmaalProps = {
    spoergsmaal: KompetenceSpoergsmaalModel;
    updateSvarCallback: (svar: KompetenceSvarModel) => void;
    deleteSvarCallback: (svar: DeleteEvalueringSvarModel) => void;
    kompetenceEvalueringId: string;
    indsendt: boolean;
    sektionId: string;
}
export function KompetenceEvalueringSpoergsmaal({spoergsmaal, indsendt, kompetenceEvalueringId, sektionId, updateSvarCallback, deleteSvarCallback}: KompetenceEvalueringSpoergsmaalProps) {
    return (
        <div className={"border padding-m datacard-border-radius"}>
            <h5 className="margin-bottom-s">{spoergsmaal?.titel}</h5>
            {spoergsmaal.tekst &&
                <p className={"pre-line margin-bottom-m"}>{spoergsmaal.tekst}</p>
            }
            <KompetenceEvalueringSvar kompetenceEvalueringId={kompetenceEvalueringId}
                                      spoergsmaal={spoergsmaal}
                                      updateSvarCallback={updateSvarCallback}
                                      deleteSvarCallback={deleteSvarCallback}
                                      indsendt={indsendt}
                                      sektionId={sektionId}
            />
        </div>
    );
}
