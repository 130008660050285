import * as yup from "yup";
import { Message } from "yup/lib/types";

// Yup extensions : add new methods/schemas to yup
// - Documentation: https://github.com/jquense/yup/blob/master/docs/extending.md
// - In Typescript discussion: https://github.com/jquense/yup/issues/312)

declare module "yup" {
  interface NumberSchema {
    /** Custom method: Allow user to use comma "," as seperator (for decimals or just integers with ",000") */
    allowComma(): this;
    /** Custom method: Validate on decimal max scale (if maxScale = 2, the following is allowed "XX.XX")  */
    decimalMaxScale(maxScale: number, errMsg: Message): this;
  }
}

// This isn't used right now, this can be used as an example for creating new methods:
yup.addMethod<yup.NumberSchema>(yup.number, "allowComma", function() {
  return this.transform((value, originalValue) => {
    const result = !!originalValue ? Number(originalValue.toString().replace(',', '.')) : originalValue;
    return result;
  })
});

yup.addMethod<yup.NumberSchema>(yup.number, "decimalMaxScale", function(maxScale: number, errMsg: Message) {
  const maxDecimalScaleRegex = new RegExp(`^\\d+(\\.\\d{1,${maxScale}})?$`);

  return this.test(
    "maxDigitsAfterDecimal",
    errMsg,
    (number) => !number || maxDecimalScaleRegex.test(number.toString())
  )
});

export default yup;
