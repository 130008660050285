import classNames from "classnames";
import { HTMLAttributes, PropsWithChildren } from "react";
import Col, { ColProps } from "../divSpecializations/Col";

const PdfRow = ({className, alignTop=true, ...props}: PropsWithChildren<HTMLAttributes<HTMLDivElement> & { className?: string, alignTop?: boolean}>) => {
    return (
        <div 
            className={classNames("flex-container-row", alignTop && "align-items-start", className)} 
            {...props}
        >
            {props.children}
        </div>
    );
}

export default Object.assign(
    PdfRow, {
        Col: ({width=2, ...props}: ColProps) => <Col width={width} {...props}>{props.children}</Col>
    }
);