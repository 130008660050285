import { RichTextEditorModule } from "ditmer-embla";
import { MutableRefObject, Ref, useImperativeHandle } from "react";

export type TextEditorActions = {
    insertMergeField: (value: string) => void;
    getHtmlString: () => string;
}

const useForwardedTextEditorActionsRef = (forwardedRef: Ref<TextEditorActions>, editor: MutableRefObject<RichTextEditorModule | null>) => {
    useImperativeHandle(forwardedRef, () => ({
        insertMergeField(value: string) {
            if (!editor.current || !value) return;

            const range = editor.current.editor.getRange() as Range;
            const textObj = editor.current.editor.getTextObject(range) as toastui.WwTextObject;
            textObj.setEndBeforeRange(range);
            textObj.replaceContent(value);
        },
        getHtmlString() : string {
            return editor.current?.getHtml() ?? "";
        },
    }), [editor]);
}

export default useForwardedTextEditorActionsRef;
