import React, {useState} from "react";
import {HttpClientService} from "../../../services/httpClient/httpClientService";
import {useAppSelector} from "../../../app/hooks";
import {forloebReducer, ForloebSliceState} from "../../../core/forloebSlice";
import useEffectAsync from "../../../infrastructure/effect";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {ActionIcon} from "../../../core/components/actionIcon/actionIcon";
import {RoutePaths} from "../../../infrastructure/routes";
import {useHistory, useRouteMatch} from "react-router-dom";
import {Loading} from "../../../core/components/loading/loading";
import {LogbogNotatApi} from "../../../services/api/logbogNotat/logbogNotatApi";
import {ForloebOverviewCard} from "./forloebOverviewCard";
import {AccountService} from "../../../services/account/accountService";
import {ForloebOverviewEmptyCard} from "./forloebOverviewEmptyCard";

const notatApi = new LogbogNotatApi(new HttpClientService(), new AccountService());

export interface ForloebNotaterOverviewProps{
    showRightHeaderContent?: boolean
}

export function ForloebNotaterOverview(props: ForloebNotaterOverviewProps) {

    const [notaterCount, setNotaterCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const routeMatch = useRouteMatch();
    const history = useHistory();

    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;
    const uddannelseslaegeId = (routeMatch.params as { id: string }).id;

    useEffectAsync(async () => {
        const notater = await notatApi.getNotaterByForloebId(forloebSliceState.forloebState.id);

        setNotaterCount(notater.totalRecords);
        setIsLoading(false);
    }, [forloebSliceState.forloebState.id]);

    const rightHeaderContent = (
        <div className="d-flex">
            <ActionIcon iconName="arrow-right" routeUrl={RoutePaths.Notater(uddannelseslaegeId).url}/>
        </div>
    )


    const navigateToNotater = () => {
        history.push(RoutePaths.Notater(uddannelseslaegeId).url)
    }

    const overviewContent = (
        <>
            <h4 className="margin-bottom-m">{Localizer.global_notater()}</h4>

            <ForloebOverviewEmptyCard>
                <p>{Localizer.forloebpage_NotaterOverviewInfo(notaterCount)}</p>

                {!forloebSliceState.forloebState.hasEnded &&
                    <button type="button" className="btn btn-success"
                            onClick={navigateToNotater}>{Localizer.forloebpage_gaaTilNotater()}</button>
                }
            </ForloebOverviewEmptyCard>
        </>
    )

    const overviewEmptyState = (
        <>
            <ForloebOverviewEmptyCard>
                <p>{Localizer.forloebpage_notaterOverviewEmptyInfo()}</p>
            </ForloebOverviewEmptyCard>
        </>
    )

    return (
        <ForloebOverviewCard title={`${Localizer.global_notater()} (${notaterCount})`} rightHeaderContent={props.showRightHeaderContent ? rightHeaderContent : <></>}>
            <Loading isLoading={isLoading} text={Localizer.notatPage_gettingNotater()}>
                {notaterCount > 0 &&
                    overviewContent
                }
                {notaterCount < 1 &&
                    overviewEmptyState
                }
            </Loading>
        </ForloebOverviewCard>
    );
}
