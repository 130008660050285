import React, {useState} from "react";
import {KompetenceEvalueringModel} from "../../../core/sharedmodels/evaluering/kompetenceEvalueringModel";
import {SlideIn} from "../../../core/components/slideIn/slideIn";
import {KompetenceEvalueringDetails} from "./kompetenceEvalueringDetails";
import {ModalSize} from "../../../core/components/slideIn/modalSize";
import {CompletedKompetenceEvalueringCard} from "./completedKompetenceEvalueringCard";
import {KladdeKompetenceEvalueringCard} from "./kladdeKompetenceEvalueringCard";

export class kompetenceEvalueringCardProps {
    kompetenceEvaluering: KompetenceEvalueringModel;
    kompetenceId: string;
    indsendt: boolean;
    deleteKompetenceEvaluering: (laegekompetenceEvalueringId: string) => void;
}

const kompetenceEvalueringDetailModal = "kompetence-evaluering-slideIn";

export function KompetenceEvalueringCard({kompetenceEvaluering, kompetenceId, indsendt, deleteKompetenceEvaluering}: kompetenceEvalueringCardProps) {

    const [showKompetenceModal, setShowKompetenceModal] = useState(false);
    const allQuestionsAnswered = kompetenceEvaluering.sektioner.every(x => x.AllQuestionsAnswered());

    return (
        <>
            {(!allQuestionsAnswered || !indsendt) &&
                <KladdeKompetenceEvalueringCard kompetenceEvaluering={kompetenceEvaluering}
                                                kompetenceId={kompetenceId}
                                                deleteKompetenceEvalueringCallback={deleteKompetenceEvaluering}/>
            }

            {(allQuestionsAnswered && indsendt) &&
                <CompletedKompetenceEvalueringCard kompetenceEvaluering={kompetenceEvaluering}
                                                   showKompetenceEvalueringModalCallback={x => setShowKompetenceModal(x)}/>
            }

            <div className={"border"}/>
            {showKompetenceModal &&
                <SlideIn
                    id={kompetenceEvalueringDetailModal}
                    title={kompetenceEvaluering.titel}
                    bodyContent={<KompetenceEvalueringDetails
                        modalTarget={kompetenceEvalueringDetailModal}
                        evalueringModel={kompetenceEvaluering}/>
                    }
                    defaultOpen={true}
                    actionOnCloseCallback={() => {
                        setShowKompetenceModal(false)
                    }}
                    modalSize={ModalSize.lg}
                />
            }
        </>
    );
}
