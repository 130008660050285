import {HttpClientService} from "../../../services/httpClient/httpClientService";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {AccountService} from "../../../services/account/accountService";
import {ValidationForm} from 'core/components/validation/components/validationForm';
import {HandleError} from 'core/infrastructure/errors/errorBoundary';
import {setSlideinState, SlideInStateEnum} from 'core/components/slideIn/slideInSlice';
import React, {useEffect, useState} from "react";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {ValidationFormSubmitButton} from "../../components/validation/components/validationFormButton";
import {forloebReducer, ForloebSliceState, setBrugerMaalbeskrivelse} from "../../forloebSlice";
import {nameof} from "ts-simple-nameof";
import {StamdataMaalbeskrivelseApi} from "../../../services/api/stamdataMaalbeskrivelse/stamdataMaalbeskrivelseApi";
import {
    BuildNew,
    SkiftMaalbeskrivelseInputModel,
    skiftMaalbeskrivelseValidationSchema
} from "./skiftMaalbeskrivelseInputModel";
import {StamdataMaalbeskrivelseModel} from "../../../services/api/stamdataMaalbeskrivelse/stamdataMaalbeskrivelseModel";
import {UddannelseTypeEnum} from "../../sharedmodels/stilling/uddannelseTypeEnum";
import {toDropdownOptions} from "../../sharedmodels/forSelectModel";
import {NotificationModule} from "ditmer-embla";
import {MaalbeskrivelseApi} from "../../../services/api/stamdataMaalbeskrivelse/maalbeskrivelseApi";
import {toggleLaegekompetenceListRefreshPing} from "../../../pages/kompetencerPage/cards/kompetenceCardSlice";
import {ValidationDropdown} from "../../components/validation/components/validationDropdown";
import {DropdownOption} from "../../components/dropdown/dropdown";

const maalbeskrivelseApi = new MaalbeskrivelseApi(new HttpClientService(), new AccountService());
const stamdataMaalbeskrivelseApi = new StamdataMaalbeskrivelseApi(new HttpClientService(), new AccountService());

type SkiftMaalbeskrivelseProps = {
    modalId: string,
    attestationForTidModalId: string
    brugerId: string
}

export function SkiftMaalbeskrivelse(props: SkiftMaalbeskrivelseProps) {
    const dispatch = useAppDispatch();
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    const [maalbeskrivelseSelectOptions, setMaalbeskrivelseSelectOptions] = useState<DropdownOption<string>[]>([]);
    const [selectedNyMaalbeskrivelse, setSelectedNyMaalbeskrivelse] = useState<string>();
    const [aktivMaalbeskrivelse, setCurrentMaalbeskrivelse] = useState<StamdataMaalbeskrivelseModel>();
    const [preSelectedNyMaalbeskrivelse, setPreSelectedNyMaalbeskrivelse] = useState<string>();

    useEffect(() => {
        const fetchMaalbeskrivelse = async () => {
            const currentMaalbeskrivelse = await stamdataMaalbeskrivelseApi.getMaalbeskrivelse(forloebSliceState.forloebState.brugerMaalbeskrivelseId);
            setCurrentMaalbeskrivelse(currentMaalbeskrivelse)

            const maalbeskrivelserForSelect = await stamdataMaalbeskrivelseApi.getMaalbeskrivelseSelect(forloebSliceState.forloebState.stillingId);
            const preSelectedNyMaalbeskrivelse = maalbeskrivelserForSelect.reduce((max, m) => (max.versionsdato > m.versionsdato) ? max : m);
            setPreSelectedNyMaalbeskrivelse(preSelectedNyMaalbeskrivelse.id);

            const maalbeskrivelserUdenAktive = maalbeskrivelserForSelect.filter(x => x.id !== currentMaalbeskrivelse.id);
            const maalbeskrivelserSelectOptions = toDropdownOptions(maalbeskrivelserUdenAktive);

            setSelectedNyMaalbeskrivelse(maalbeskrivelserUdenAktive.find(m => m.id === preSelectedNyMaalbeskrivelse.id)?.id ?? undefined)
            setMaalbeskrivelseSelectOptions(maalbeskrivelserSelectOptions);
        }

        fetchMaalbeskrivelse();
    }, [forloebSliceState.forloebState.brugerMaalbeskrivelseId, forloebSliceState.forloebState.stillingId]);

    const godkendSkiftAfMaalbeskrivelse = async (input: SkiftMaalbeskrivelseInputModel) => {
        dispatch(setSlideinState({state: SlideInStateEnum.ActionOngoing, slideInId: props.attestationForTidModalId}))
        dispatch(setSlideinState({state: SlideInStateEnum.ActionOngoing, slideInId: props.modalId}))
        const brugerMaalbeskrivelseId = await maalbeskrivelseApi.skiftMaalbeskrivelse(input);

        if (brugerMaalbeskrivelseId) {
            dispatch(setBrugerMaalbeskrivelse(brugerMaalbeskrivelseId));
            NotificationModule.showSuccess(Localizer.forloebpage_maalbeskrivelseSkiftet(), "");
        }
        dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: props.modalId}))
        dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: props.attestationForTidModalId}))
        dispatch(toggleLaegekompetenceListRefreshPing())
    }

    return (
        <>
            {aktivMaalbeskrivelse &&
            <ValidationForm
                key={props.modalId}
                initialValues={BuildNew(props.brugerId, forloebSliceState.forloebState.uddannelseId, forloebSliceState.forloebState.id, aktivMaalbeskrivelse.id)}
                validationSchema={skiftMaalbeskrivelseValidationSchema}
                onSubmit={async (values) => {
                    await godkendSkiftAfMaalbeskrivelse(values)
                        .catch(err => {
                            HandleError(err);
                            dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: props.modalId}))
                        });
                }}>
                {
                    (formik) => {
                        const currentMaalbeskrivelseText = `${aktivMaalbeskrivelse?.navn} - ${UddannelseTypeEnum[aktivMaalbeskrivelse?.uddannelseType]} - ${aktivMaalbeskrivelse?.versionsdato.dateWithoutTimeFormat(false)}`;
                        return (
                            <>
                                <div className="row">
                                    {preSelectedNyMaalbeskrivelse === aktivMaalbeskrivelse?.id &&
                                      <div className="col-sm-12 ">
                                        <div className="alert alert-warning">
                                            {Localizer.forloebpage_brugerenErPaaNyesteMaalbeskrivelse()}
                                        </div>
                                      </div>
                                    }
                                    <div className="col-sm-12">
                                        <div className="margin-bottom-m">
                                            <label
                                                htmlFor={nameof<SkiftMaalbeskrivelseInputModel>(x => x.gammelMaalbeskrivelseId)}>
                                                {Localizer.forloebpage_nuvaerendeMaalbeskrivelse()}
                                            </label>
                                            <input className="form-control"
                                                   readOnly={true}
                                                   placeholder={Localizer.forloebpage_nuvaerendeMaalbeskrivelse()}
                                                   value={currentMaalbeskrivelseText}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        {maalbeskrivelseSelectOptions.length > 0 &&
                                          <ValidationDropdown
                                            model={{
                                                label: Localizer.forloebpage_nyMaalbeskrivelse(),
                                                placeholder: Localizer.forloebpage_nyMaalbeskrivelse(),
                                                htmlName: nameof<SkiftMaalbeskrivelseInputModel>(x => x.nyMaalbeskrivelseId),
                                            }}
                                            options={maalbeskrivelseSelectOptions}
                                            formikProps={formik}
                                            itemSelected={(newValue) => setSelectedNyMaalbeskrivelse(newValue?.value)}
                                          />
                                        }
                                        {maalbeskrivelseSelectOptions.length === 0 &&
                                          <div className="alert alert-warning">
                                              {Localizer.forloebpage_derErIkkeAndreTilgaengeligeMaalbeskrivelser()}
                                          </div>
                                        }
                                    </div>
                                    {selectedNyMaalbeskrivelse !== undefined &&
                                      <div className="col-sm-12 ">
                                        <div className="alert alert-info">
                                          <b>{Localizer.forloebpage_huskAtGiveMerit()}</b> <br/>
                                            {Localizer.forloebpage_huskMeritBeskrivelse()}
                                        </div>
                                      </div>
                                    }
                                </div>
                                {maalbeskrivelseSelectOptions.length > 0 &&
                                  <div className="modal-actions">
                                    <ValidationFormSubmitButton formikProps={formik}>
                                        {Localizer.global_save()}
                                    </ValidationFormSubmitButton>
                                  </div>
                                }
                            </>
                        );
                    }
                }
            </ValidationForm>
            }
        </>
    )
}
