import {useState} from "react";
import {useAppSelector} from "../../../app/hooks";
import {forloebReducer, ForloebSliceState} from "../../../core/forloebSlice";
import useEffectAsync from "../../../infrastructure/effect";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {ActionIcon} from "../../../core/components/actionIcon/actionIcon";
import {RoutePaths} from "../../../infrastructure/routes";
import {useHistory, useRouteMatch} from "react-router-dom";
import {Loading} from "../../../core/components/loading/loading";
import {LineChart, LineChartColor, LineChartItem} from "../../../core/components/charts/lineChart";
import {ForloebOverviewCard} from "./forloebOverviewCard";
import {ForloebOverviewEmptyCard} from "./forloebOverviewEmptyCard";
import { VejledersamtaleModel } from "pages/vejledning/vejledersamtaler/vejledersamtaleModel";
import VejlederAppointment from "pages/vejledning/vejledersamtaler/components/vejlederAppointment";
import useLogbogApi from "core/hooks/useLogbogApi";
import dayjs from "dayjs";
import { todayDayjs } from "index";

export type ForloebVejledersamtalerOverviewProps = {
    additionalClasses?: string
    showRightHeaderContent?: boolean
}

export const ForloebVejledersamtalerOverview = (props: ForloebVejledersamtalerOverviewProps) => {

    const { vejledersamtaleApi } = useLogbogApi();

    const [vejledersamtaler, setVejledersamtaler] = useState<VejledersamtaleModel[]>([]);
    const [nextVejledersamtale, setNextVejledersamtale] = useState<VejledersamtaleModel | null>(null);
    const [acceptedVejledersamtalerCount, setAcceptedVejledersamtalerCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const routeMatch = useRouteMatch();
    const history = useHistory();

    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;
    const uddannelseslaegeId = (routeMatch.params as { id: string }).id;

    useEffectAsync(async () => {
        const vejledersamtaler = await vejledersamtaleApi.getVejledersamtalerByForloeb(forloebSliceState.forloebState.id);

        const today = todayDayjs();
        const now = dayjs().toDate();

        const futurevejledersamtaler = vejledersamtaler
            .filter(v => !!v.dato && dayjs(v.dato).isAfter(today))
            .sort((a, b) => (a.starttidspunkt?.getTime() ?? now) < (b.starttidspunkt?.getTime() ?? now) ? 1 : -1)
            .sort((a, b) => a.dato > b.dato ? 1 : -1);

        const nextSamtale = futurevejledersamtaler[0];

        const acceptedVejledersamtalerCount = vejledersamtaler.filter(vs => vs.afholdtTilkendegivetAf).length;

        setAcceptedVejledersamtalerCount(acceptedVejledersamtalerCount);
        setVejledersamtaler(vejledersamtaler);

        if(nextSamtale) {
            setNextVejledersamtale(nextSamtale);
        }

        setIsLoading(false);
    }, [forloebSliceState.forloebState.id]);

    const rightHeaderContent = (
        <div className="d-flex">
            <ActionIcon iconName="arrow-right" routeUrl={RoutePaths.Vejledning({
                uddannelseslaege: uddannelseslaegeId,
            }).url}/>
        </div>
    )

    const navigateToVejledersamtaler = () => {
        const routePath = RoutePaths.Vejledning({
            uddannelseslaege: uddannelseslaegeId,
        });
        history.push({pathname: routePath.url});
    }

    const generateChartItems = (): LineChartItem[] => {
        const planlagtCount = vejledersamtaler.filter(vs => !vs.afholdtTilkendegivetAf && !vs.klarmeldtAt).length;
        const klarmeldtCount = vejledersamtaler.filter(vs => !vs.afholdtTilkendegivetAf && vs.klarmeldtAt).length;

        return [
            {count: planlagtCount, color: LineChartColor.info, title: Localizer.global_planlagt()},
            {count: klarmeldtCount, color: LineChartColor.warning, title: Localizer.global_klarmeldt()},
            {count: acceptedVejledersamtalerCount, color: LineChartColor.success, title: Localizer.global_afholdt()},
        ];
    }

    const navigateToCurrentVejledersamtale = (vejledersamtalerId: string) => {
        history.push(RoutePaths.Vejledning({
            uddannelseslaege: uddannelseslaegeId,
            vejledersamtale: vejledersamtalerId
        }).url)
    }

    const overviewContent = (
        <>
            <LineChart items={generateChartItems()}/>

            <p className="subtle margin-top-m">{Localizer.forloebpage_vejlederOverviewInfo(acceptedVejledersamtalerCount.toString(), vejledersamtaler.length.toString())}</p>

            {nextVejledersamtale &&
                <div className="card" role={"button"} onClick={() => navigateToCurrentVejledersamtale(nextVejledersamtale?.id)}>
                    <div className="card-body">
                        <p className="subtle margin-bottom-0">{Localizer.NyesteVejledersamtale()}</p>
                        <VejlederAppointment samtale={nextVejledersamtale}/>

                    </div>
                </div>
            }
        </>
    )

    const overviewEmptyState = (
        <>
            <h4 className="margin-bottom-m">{Localizer.global_vejledersamtaler()}</h4>

            <ForloebOverviewEmptyCard emptyText={Localizer.forloebpage_vejlederOverviewEmptyInfo()}>
                <p>{Localizer.forloebpage_vejlederOverviewEmptyInfo()}</p>

                {!forloebSliceState.forloebState.hasEnded &&
                    <button type="button" className="btn btn-success"
                            onClick={navigateToVejledersamtaler}>{Localizer.global_planlaeg()}</button>
                }
            </ForloebOverviewEmptyCard>
        </>
    )

    return (
        <div className={props.additionalClasses}>
            <ForloebOverviewCard
                title={`${Localizer.global_vejledersamtaler()} (${vejledersamtaler?.length})`}
                rightHeaderContent={props.showRightHeaderContent ? rightHeaderContent : <></>}>

                <Loading isLoading={isLoading} text={Localizer.profilepage_getVejledersamtaler()}>
                    {vejledersamtaler?.length > 0 &&
                        overviewContent
                    }

                    {vejledersamtaler?.length < 1 &&
                        overviewEmptyState
                    }
                </Loading>

            </ForloebOverviewCard>
        </div>
    );
}
