import { AccountService } from "services/account/accountService";
import { IHubConnectionManager } from "./IHubConnectionManager";
import NotificationHubConnectionManager from "./notificationHubConnectionManager";


export enum HubConnectionType {
  Notifications
}

const notificationHubEndpointName = "notificationHub";

export default class HubConnectionFactory {

  static createHub(type: HubConnectionType, userService: AccountService): IHubConnectionManager {
    switch (type) {
      case HubConnectionType.Notifications:
        return new NotificationHubConnectionManager(notificationHubEndpointName, userService);
      default:
        throw new Error("Type has not been implemented");
    }
  }
}
