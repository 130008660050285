import {DatasetModel} from "../datasetModel";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {evalueringsstatistikReducer, EvalueringsstatistikState, setDataset} from "../evalueringsstatistikSlice";
import {toState} from "../evalueringsstatistikModel";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {DatasetCardSubheaderComponent} from "./datasetCardSubheaderComponent";
import {ContextButtonInfo, ContextButtonMenu} from "core/components/contextMenu/contextButtonMenu";
import {HttpClientService} from "services/httpClient/httpClientService";
import {FileDownloadHandler} from "services/api/logbogFile/fileDownloadHandler";
import {LogbogExportApi} from "services/api/logbogExport/logbogExportApi";
import {AccountService} from "services/account/accountService";
import {EvalueringsstatistikInputModel} from "core/componentsPage/evalueringsstatistik/evalueringsstatistikInputModel";
import {Spinner} from "core/components/spinner/spinner";
import React, {useState} from "react";
import {EvalueringsstatistikPageConstants} from "./EvalueringsstatistikPageConstants";
import {
    evalueringsstatistikFiltersReducer,
    EvalueringsstatistikFiltersState
} from "../evalueringsstatistikFiltersSlice";
import {SpoergsmaalTypeEnum} from "../../../core/sharedmodels/evaluering/spoergsmaalTypeEnum";
import {CheckboxSwitch} from "../../../core/components/checkbox/checkboxSwitch";
import {useEffectOnUpdate} from "../../../infrastructure/effect";
import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../../../core/layout/responsive";
import {ConfirmButton} from "../../../core/components/button/confirmButton";
import {toDate} from "../../../index";
import {AverageScoreOverview} from "./averageScoreOverview";
import {EvalueringsstatistikFilterDetails} from "./evalueringsstatistikFilterDetails";
import classNames from "classnames";

type DatasetCardProps = {
    dataset: DatasetModel;
    datasetIndex: number;
    hideFilters?: boolean;
    additionalClassNames?: string;
}

export function DatasetCard({
    hideFilters = false,
    ...props
}: DatasetCardProps) {
    const logbogExportApi = new LogbogExportApi(new HttpClientService(), new AccountService(), new FileDownloadHandler());
    const evalueringsstatistikSliceState = useAppSelector(evalueringsstatistikReducer) as EvalueringsstatistikState;
    const evalueringsstatistikFilterSliceState = useAppSelector(evalueringsstatistikFiltersReducer) as EvalueringsstatistikFiltersState;
    const dispatch = useAppDispatch();

    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [showAverageAndFilters, setShowAverageAndFilters] = useState<boolean>(false);
    const [avgScores, setAvgScores] = useState<number[]>([]);
    const IsMobile = useMediaQuery(mobileMaxWidthQuery);

    useEffectOnUpdate(() => {
        if (evalueringsstatistikFilterSliceState.spoergsmaalsType === SpoergsmaalTypeEnum.Grupperet)
            setAvgScores(props.dataset?.avgScoresGroup ?? [])
        else if (evalueringsstatistikFilterSliceState.spoergsmaalsType === SpoergsmaalTypeEnum.Enkeltvis)
            setAvgScores(props.dataset?.avgScoresSingle ?? [])
    },[evalueringsstatistikFilterSliceState.spoergsmaalsType, showAverageAndFilters, evalueringsstatistikSliceState.showAllDatasetAverageAndFilters])

    const removeDataset = () => {
        let dataset = evalueringsstatistikSliceState.dataset;
        let datasetInIndex = dataset.findIndex(x => x === props.dataset);

        if(datasetInIndex > -1){
            dataset = [...dataset.slice(0, datasetInIndex), ...dataset.slice(datasetInIndex + 1)];
        }
        dispatch(setDataset(toState({dataset: dataset})));
    }

    const createContextMenuButtons = (): ContextButtonInfo[] => {
        let buttons = new Array<ContextButtonInfo>()
        if (EvalueringsstatistikPageConstants.getComplexView)
            buttons.push(new ContextButtonInfo(exportToExcel, Localizer.evalueringPage_exporterEnkelteEvalueringerTilExcel()));
        return buttons;
    }

    const exportToExcel = async () => {
        setShowSpinner(true);
        const uddannelsesstedIds = props.dataset.uddannelsessteder.map(u => u.id);
        const inputModel = new EvalueringsstatistikInputModel({
            uddannelsesstedIds: uddannelsesstedIds,
            startDato: props.dataset.fromDate ? toDate(props.dataset.fromDate) : undefined,
            slutDato: props.dataset.toDate ? toDate(props.dataset.toDate) : undefined,
            specialer: props.dataset.specialeIds ?? [],
            uddannelsestyper: props.dataset.stillingstyper?.map(str => parseInt(str))
        })
        await logbogExportApi.getEvalueringStatistikExcel(inputModel)

        setShowSpinner(false);
    }

    const datasetTitle = `${Localizer.evalueringPage_dataset()} ${(props.datasetIndex + 1)}`;

    const showAverageCheckboxLabelForMobile = !hideFilters ? Localizer.evalueringPage_visGennemsnitOgFiltreForkortet() : Localizer.evalueringPage_gennemsnit();
    const showAverageCheckboxLabelForDesktop = !hideFilters ? Localizer.evalueringPage_visGennemsnitOgFiltre() : Localizer.evalueringPage_visGennemsnit();

    const anyEvalueringer = props.dataset.uddannelsesstederEvalueringstatistik.length > 0;

    return (
        <div className={classNames("dataset-card card shadow-none datacard-border-radius", props.additionalClassNames)}>
            <div className="flex-space align-items-center">
                <div className="flex-container ">
                    <div style={{
                        backgroundColor: props.dataset.datasetColor,
                        width: "10px",
                        borderTopLeftRadius: "3px",
                        borderBottomLeftRadius: showAverageAndFilters ? "0px" : "3px"
                    }}>
                    </div>
                    <div className="padding-s">
                        {props.dataset.title
                            ? <h5>{`${props.dataset.title} (${datasetTitle})`}</h5>
                            : <h5>{`${datasetTitle}`}</h5>
                        }
                        <DatasetCardSubheaderComponent dataset={props.dataset}/>
                    </div>
                </div>
                <div className={`dataset-actions d-flex flex-row mr-2 wrap ${IsMobile ? "min-w-140 flex-end flex-column" : "justify-content-center"}`}>
                    {IsMobile &&
                        <label className="margin-right-s align-self-end margin-right-m">{showAverageCheckboxLabelForMobile}</label>
                    }

                    <div className={`margin-top-xs margin-right-m ${IsMobile ? "align-self-end": " align-self-center"}`}>
                        { anyEvalueringer &&
                            <CheckboxSwitch
                                id={"show-average-score"}
                                label={!IsMobile ? showAverageCheckboxLabelForDesktop : ""}
                                onChange={() => setShowAverageAndFilters(!showAverageAndFilters)}
                                value={showAverageAndFilters}/>
                        }
                    </div>

                    <ConfirmButton
                        key={props.dataset.datasetColor + props.datasetIndex}
                        icon={"delete"}
                        asButton={false}
                        confirmCallback={() => removeDataset()}
                        additionalClasses={`btn btn-icon ${IsMobile ? "margin-top-m margin-right-s align-self-end": ""}`}
                    />

                    {showSpinner &&
                        <div className="d-flex ml-3">
                            <Spinner spinnerSize="extra-tiny-small"/>
                        </div>
                    }
                    {props.dataset.uddannelsesstederEvalueringstatistik.some(x => x.antalEvalueringerMedAdgangTil > 0) && //TODO: AFVENTER extra udtræk - kommer til at have tidligere tjek her - på evalueringer, når vi laver exceludtræk til offentlige side.
                        <ContextButtonMenu buttons={createContextMenuButtons()} border={false} additionalClasses={"align-self-end"}/>
                    }
                </div>
            </div>

            {anyEvalueringer && (showAverageAndFilters || evalueringsstatistikSliceState.showAllDatasetAverageAndFilters) &&
               <React.Fragment>
                   <AverageScoreOverview avgScores={avgScores}/>
                   {!hideFilters && <EvalueringsstatistikFilterDetails dataset={props.dataset}/>}
               </React.Fragment>
            }
        </div>
    )
}

