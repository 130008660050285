import {ForloebOverviewConstants} from "../forloebOverviewConstants";
import {ForloebOverviewFilterModel} from "../ForloebOverviewFilterModel";
import {ForloebOverviewFiltersState} from "../forloebOverviewFiltersSlice";
import {OverlapCompareModel} from "../../../sharedmodels/OverlapCompareModel";
import {LaegeWithForloebModel} from "../../../sharedmodels/forloeb/laegeWithForloebModel";
import {LaegeinstitutionWithStillingerModel} from "../../../sharedmodels/forloeb/laegeinstitutionWithStillingerModel";

export function calculateForloebColumnNumber (month: number, year: number, years: number[], monthsToAdd: number = 0) {
    const firstYear = years[0];
    const yearDiff = year - firstYear;
    return ForloebOverviewConstants.timelineColumnStart + month + monthsToAdd + ForloebOverviewConstants.months.length * yearDiff;
}

export function cloneFilterFromState(forloebOverviewFilterSliceState: ForloebOverviewFiltersState, filter: ForloebOverviewFilterModel): ForloebOverviewFilterModel {
    const clone = Object.assign({}, filter);
    clone.filterId = forloebOverviewFilterSliceState.filterId;
    clone.afdelinger = forloebOverviewFilterSliceState.afdelinger;
    clone.regioner = forloebOverviewFilterSliceState.regioner;
    clone.specialer = forloebOverviewFilterSliceState.specialer;
    clone.praksis = forloebOverviewFilterSliceState.praksis;
    clone.hospitaler = forloebOverviewFilterSliceState.hospitaler;
    clone.uddannelsetyper = forloebOverviewFilterSliceState.uddannelsetyper;
    clone.omraader = forloebOverviewFilterSliceState.omraader;
    clone.regionRettigheder = forloebOverviewFilterSliceState.regionRettigheder;
    clone.isPrivate = forloebOverviewFilterSliceState.isPrivate;
    clone.alleRegionerValgt = forloebOverviewFilterSliceState.alleRegionerValgt;
    clone.dateFilterType = forloebOverviewFilterSliceState.dateFilterType;
    clone.year = forloebOverviewFilterSliceState.year;
    clone.date = forloebOverviewFilterSliceState.date;
    clone.periodFrom = forloebOverviewFilterSliceState.periodFrom;
    clone.periodTo = forloebOverviewFilterSliceState.periodTo;
    clone.kbuPeriod = forloebOverviewFilterSliceState.kbuPeriod;
    clone.kbuPeriodYear = forloebOverviewFilterSliceState.kbuPeriodYear;

    if (forloebOverviewFilterSliceState.titel)
        clone.titel = forloebOverviewFilterSliceState.titel;
    return clone;
}

export function overlappingCount<T extends OverlapCompareModel>(items: T[]): number {
    let count = 0;

    items.sort((a, b) => a.endDate.getTime() - b.endDate.getTime());
    items.sort((a, b) => a.compareId.localeCompare(b.compareId));

    for (let i = 1; i < items.length; i++) {
        const previousItem = items[i - 1];
        const currentItem = items[i];
        if (currentItem.startDate <= previousItem.endDate && currentItem.compareId === previousItem.compareId) {
            count++;
        }
    }

    return count;
}


export const calculateAndSetYears = async (startYear: number, laegerWithForloeb: LaegeWithForloebModel[]) => {
    const minShownYears = 4;
    let endYear = 0;

    laegerWithForloeb.map((laege) =>
        laege.forloebModels.forEach((forloeb) => {
            const tempStartYear = forloeb.startDate.getUTCFullYear() as number;
            if (tempStartYear < startYear)
                startYear = tempStartYear;

            const tempEndYear = forloeb.endDate.getUTCFullYear() as number;
            if (tempEndYear > endYear)
                endYear = tempEndYear;
        }));

    let yearDiff = endYear - startYear;

    if(laegerWithForloeb.length > 0 && yearDiff < minShownYears)
        yearDiff = minShownYears;

    let years = [startYear] as number[];
    for (let i = 1; i <= yearDiff; i++) {
        const newYear = startYear + i;
        years.push(newYear);
    }

    return years;
}

export const calculateAndSetYearsForStillinger = async (startYear: number, laegeinstitutionWithStillingerModels: LaegeinstitutionWithStillingerModel[]) => {
    const minShownYears = 4;
    let endYear = 0;

    laegeinstitutionWithStillingerModels.map((laegeInst) => {
            laegeInst.praksisStillinger.map(x => x.laegeStillinger.map(forloeb => {
                const tempStartYear = forloeb.startDate.getFullYear() as number;
                if (tempStartYear < startYear)
                    startYear = tempStartYear;

                const tempEndYear = forloeb.endDate.getFullYear() as number;
                if (tempEndYear > endYear)
                    endYear = tempEndYear;
            }))

            laegeInst.afdelingerMedStillinger.map(x => x.stillinger.map(stilling => stilling.laegeStillinger.map(forloeb => {
                    const tempStartYear = forloeb.startDate.getFullYear() as number;
                    if (tempStartYear < startYear)
                        startYear = tempStartYear;

                    const tempEndYear = forloeb.endDate.getFullYear() as number;
                    if (tempEndYear > endYear)
                        endYear = tempEndYear;
                }))
            )
        }
    );

    let yearDiff = endYear - startYear;

    if(laegeinstitutionWithStillingerModels.length > 0 && yearDiff < minShownYears)
        yearDiff = minShownYears;

    let years = [startYear] as number[];
    for (let i = 1; i <= yearDiff; i++) {
        const newYear = startYear + i;
        years.push(newYear);
    }

    return years;
}

