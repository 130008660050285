
import PlaceFilter, { PlaceFilterResult } from "core/components/filter/components/placeFilter";
import { useCallback, useState } from "react";
import { BrugerListFilter } from "./brugerListFilter";
import EducationFilter, { EducationFilterResult } from "core/components/filter/components/educationFilter";
import UserFilter, { UserFilterResult } from "core/components/filter/components/userFilter";
import FitlerActionButtonGroup from "core/components/filter/components/filterActionButtonGroup";
import UserFilterPermissions from "core/sharedmodels/userPermissions/userFilterPermissions";

type BrugerListeFilterProps = {
    filter: BrugerListFilter;
    filterCallback: (filter: BrugerListFilter) => void;
    closeModal: () => void;
    permissions: UserFilterPermissions
}

const BrugerListeFilter = ({filter: initialFilter, permissions, filterCallback, closeModal}: BrugerListeFilterProps) => {
    
    const [filter, setFilter] = useState<BrugerListFilter>(initialFilter);

    const onPlaceFilterChange = useCallback((result: PlaceFilterResult) => {
        setFilter(prev => ({...prev, ...{
            regionIds: result.regioner,
            omraadeIds: result.omraader,
            afdelingIds: result.afdelinger,
            praksisIds: result.praksiser,
            sygehusIds: result.sygehuse,
        }}));
    }, []);

    const onEducationFilterChange = useCallback((result: EducationFilterResult) => {
        setFilter(prev => ({...prev, ...{
            specialeIds: result.specialer,
            uddannelseIds: result.uddannelser,
        }}));
    }, []);

    const onUserFilterChange = useCallback((result: UserFilterResult) => {
        setFilter(prev => ({...prev, ...{
            rolleTitels: result.rolleTitels,
        }}));
    }, []);

    const applyFilter = useCallback(() => {
        closeModal();
        filterCallback(filter);
    }, [closeModal, filter, filterCallback]);

    return (
        <div className="row">
            <div className="col-sm-12 flex-container-column">

                <UserFilter 
                    onChange={onUserFilterChange} 
                    initialSelectedValues={{
                        rolleTitels: filter.rolleTitels
                    }}
                />

                <EducationFilter 
                    onChange={onEducationFilterChange} 
                    permissions={permissions} 
                    initialSelectedValues={{
                        specialer: filter.specialeIds,
                        uddannelser: filter.uddannelseIds,
                    }}
                />
                
                <PlaceFilter 
                    onChange={onPlaceFilterChange} 
                    permissions={permissions} 
                    initialSelectedValues={{
                        regioner: filter.regionIds,
                        omraader: filter.omraadeIds,
                        afdelinger: filter.afdelingIds,
                        praksiser: filter.praksisIds,
                        sygehuse: filter.sygehusIds
                    }}
                />
                
                <FitlerActionButtonGroup 
                    onClose={closeModal} 
                    onApplyFilter={applyFilter}
                />
            </div>
        </div>
    )
}

export default BrugerListeFilter;