import { FileMetadataList } from "../fileMetadata/fileMetadataList";
import { FileLocationEnum } from "core/sharedmodels/file/fileLocationEnum";
import { Localizer } from "infrastructure/localization/localizer";
import { FileData } from "services/api/stamdataMappe/FilesAndFoldersModel";
import { CollapseTab } from "../Tabs/collapseTab";
import { UUID } from "index";
import { FileMetadata } from "core/sharedmodels/fileMetaData/fileMetaData";

type FolderlessFilesProps = {
    headerText?: string;
    fileDatas: FileData[];
    showCustomBadgeText?: (fileMetadataId: string) => string;
    editFileAction?: (fileMetadataId: string) => void;
    deleteAction?: (fileMetadata: FileMetadata) => Promise<void>;
}

const FolderlessFiles = ({
    fileDatas,
    showCustomBadgeText,
    headerText,
    editFileAction,
    deleteAction
} : FolderlessFilesProps) => {
    
    return (
        <CollapseTab
            id={UUID()}
            key={fileDatas.length + "-folderless-files-collapse-tab"}
            title={headerText ?? Localizer.global_filesWithoutFolder().appendCounter(fileDatas.length)}
            expandedDefault={false}
            additionalClasses={"folder"}
        >
            <ul className="list-group font-weight-bold padding-left-m">
                <FileMetadataList
                    fileMetadatas={fileDatas.map(x => x.fileMetadata)}
                    fileLocation={FileLocationEnum.Stamdata}
                    showDeleteButton={!!deleteAction}
                    withUploader={false}
                    hideBorder={true}
                    showCustomBadgeText={showCustomBadgeText}
                    editFileOnClick={editFileAction}
                    fileDeletedCallbackOverride={deleteAction}
                />
            </ul>
        </CollapseTab>
    )
}

export default FolderlessFiles;