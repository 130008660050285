import classNames from "classnames"

export const AlertTypes = {
    Primary: "primary",
    Secondary: "secondary",
    Succes: "success",
    Danger: "danger",
    Warning: "warning",
    Info: "info",
    Light: "light",
    Dark: "dark",
} as const;

export type AlerColortTypes = typeof AlertTypes[keyof typeof AlertTypes]

const SlideInAlert = ({text, alertType}: {text: string, alertType: AlerColortTypes}) => {
    return (
        <div className="col-sm-12 padding-left-0 padding-right-0">
            <div className={classNames("alert", `alert-${alertType}`)} role="alert">
                {text}
            </div>
        </div>
    )
}

export default SlideInAlert