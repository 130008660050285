import React, {useEffect, useState} from "react";
import './landingPage.scss';
import {RegionInfo} from "./regionInfo";
import {Localizer} from "../../infrastructure/localization/localizer";
import {VidereuddannlesesregionEnum} from "./regionEnum";
import {Accordion} from "../../core/components/accordion/accordion";
import {IAccordionItem} from "../../core/components/accordion/accordionCard";

export const ContactInformation = () => {
    const [regionClickedState, setRegionClickedState] = useState<VidereuddannlesesregionEnum>();
    const [accordionItems, setAccordionItems] = useState<IAccordionItem[]>([] as any);

    useEffect(() => {
        let newAccordionItems: IAccordionItem[] = getAccordionItems();
        setAccordionItems(newAccordionItems);
    }, [regionClickedState])

    function getAccordionItems(): IAccordionItem[] {
        return [
            {
                id: VidereuddannlesesregionEnum.Nord.toString(),
                header: Localizer.landingPage_videreuddannelsesregionNord(),
                content: <RegionInfo emailTekst={Localizer.landingPage_videreuddannelsesregionNordEmail()}
                                     webTekst={Localizer.landingPage_videreuddannelsesregionNordWeb()}
                                     telefonTekst={Localizer.landingPage_videreuddannelsesregionNordTelefon()}/>,
                isExpanded: regionClickedState === VidereuddannlesesregionEnum.Nord
            },
            {
                id: VidereuddannlesesregionEnum.Syd.toString(),
                header: Localizer.landingPage_videreuddannelsesregionSyd(),
                content: <RegionInfo emailTekst={Localizer.landingPage_videreuddannelsesregionSydEmail()}
                                     webTekst={Localizer.landingPage_videreuddannelsesregionSydWeb()}
                                     telefonTekst={Localizer.landingPage_videreuddannelsesregionSydTelefon()}/>,
                isExpanded: regionClickedState === VidereuddannlesesregionEnum.Syd
            },
            {
                id: VidereuddannlesesregionEnum.Oest.toString(),
                header: Localizer.landingPage_videreuddannelsesregionOest(),
                content: <RegionInfo emailTekst={Localizer.landingPage_videreuddannelsesregionOestEmail()}
                                     webTekst={Localizer.landingPage_videreuddannelsesregionOestWeb()}
                                     telefonTekst={Localizer.landingPage_videreuddannelsesregionOestTelefon()}/>,
                isExpanded: regionClickedState === VidereuddannlesesregionEnum.Oest
            }
        ] as IAccordionItem[];
    }

    function handleAccordionItemClick(regionTabId?: string) {
        setAccordionItems(prevState => {
            return prevState.map(obj => {
                if (obj.id === regionTabId) {
                    updateRegionFromAccordionItem(obj.isExpanded, regionTabId);
                    return {...obj, isExpanded: !obj.isExpanded};
                }
                return obj; // otherwise return object as is
            });
        });
    }

    function updateRegionFromAccordionItem(isExpanded: boolean, regionTabId?: string) {
        if(isExpanded)
            setRegionClickedState(undefined);
        else if(regionTabId)
            setRegionClickedState(parseInt(regionTabId));
    }

    return <>
        <div className={"region-container"}>
            <Accordion elements={accordionItems} useCallbackForToggling={(regionTabId) => handleAccordionItemClick(regionTabId)} />
        </div>
    </>
}
