import config from "config/config";
import { useEffect, useState } from "react";
import { HubConnectionType } from "../hubConnectionFactory";
import useHubConnection from "./useHubConnection";
import { SignalRConstants } from "services/signalRClient/signalRConstants";

const useNotificationSignal = (onNewNotification: () => void) => {
  const [notificationHubConnection] = useState(useHubConnection(HubConnectionType.Notifications));

  useEffect(() => {
    if(!config.showNotifications) {
      return;
    }

    // Make sure the connection for this specific hub has started:
    notificationHubConnection.startGlobalConnection();

    // Listen for notificationResults and do something when a signal is received:
    notificationHubConnection.onGlobal(SignalRConstants.NotificationEventResult, onNewNotification);

    return () => {
      // Remove listener
      notificationHubConnection.offGlobal(SignalRConstants.NotificationEventResult, onNewNotification);
    }

  }, [notificationHubConnection, onNewNotification]);
}

export default useNotificationSignal;