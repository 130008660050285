import { Localizer } from "infrastructure/localization/localizer";

export enum ObligatoriskDokumentationTypeEnum{
    KompetencerGodkendt = 1,
    Attestation = 2,
    AnsaettelseOgUddannelse = 3,
    LaegeligBeskaeftigelse = 4,
    KurserGodkendt = 5,

    // These keys are from LaegekursusGruppeTypeEnum. If you delete or add one here, do the same in LaegekursusGruppeTypeEnum.ts
    KurserHoveduddannelse = 21,
    KurserForskningstraening = 22,
    KurserFaellesInternMedicin = 23,
    KurserSpecialespecifikke = 24,
    KurserGrundkursusIPsykoterapi = 25,
    KurserIntroduktionsuddannelsen = 26,
    KurserKliniskBasisuddannelse = 27,
    KurserSpecialespecifikkeIntrouddannelsen = 28,
    AlmenMedicin = 29
}

export function IsKursusTypeEnum(obligatoriskDokumentationType: ObligatoriskDokumentationTypeEnum): boolean {
    const kursusTypeEnums = [
        ObligatoriskDokumentationTypeEnum.KurserHoveduddannelse,
        ObligatoriskDokumentationTypeEnum.KurserForskningstraening,
        ObligatoriskDokumentationTypeEnum.KurserFaellesInternMedicin,
        ObligatoriskDokumentationTypeEnum.KurserSpecialespecifikke,
        ObligatoriskDokumentationTypeEnum.KurserGrundkursusIPsykoterapi,
        ObligatoriskDokumentationTypeEnum.KurserIntroduktionsuddannelsen,
        ObligatoriskDokumentationTypeEnum.KurserKliniskBasisuddannelse,
        ObligatoriskDokumentationTypeEnum.KurserSpecialespecifikkeIntrouddannelsen,
        ObligatoriskDokumentationTypeEnum.AlmenMedicin
    ]

    return kursusTypeEnums.includes(obligatoriskDokumentationType);
}
