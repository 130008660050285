import { Localizer } from "infrastructure/localization/localizer";
import { useEffect } from "react";
import { useBrevskabelonContext } from "../context/brevskabelonContextProvider";
import { MineTabId, RegionTabId, WholeDenmarkTabId } from "../shared/brevskabelonTabId";
import { Tab, Tabs } from "core/components/Tabs/Tabs";
import BreveOverview from "./BreveOverview";
import BrevskabelonVisibilityTypeEnum from "infrastructure/enums/brevskabelonVisibilityTypeEnum";
import { useRouteMatch } from "react-router-dom";
import { userBrevskabelonQueryParams } from "infrastructure/route/queryParams";
import { ForloebSliceState, forloebReducer } from "core/forloebSlice";
import { useAppSelector } from "app/hooks";

const BrevskabelonPageComponent = () => {

    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;
    const { uddannelseslaege } = useRouteMatch<userBrevskabelonQueryParams>().params;

    const { setLaegeId, setRegionId, state, setActiveTabId } = useBrevskabelonContext();

    const isActiveTab = (id: string) => state.activeTabId === id;

    useEffect(() => {
        setLaegeId(uddannelseslaege);
    }, [setLaegeId, uddannelseslaege]);

    useEffect(() => {
        setRegionId(forloebSliceState.forloebState.regionId)
    }, [forloebSliceState.forloebState.regionId, setRegionId]);

    const tabs: Tab[] = [
        {
            title: Localizer.global_wholeDenmark(),
            id: WholeDenmarkTabId,
            content: <BreveOverview visibility={BrevskabelonVisibilityTypeEnum.Global} />,
            defaultActive: isActiveTab(WholeDenmarkTabId),
        }, {
            title: forloebSliceState.forloebState.regionNavn,
            id: RegionTabId,
            content: <BreveOverview visibility={BrevskabelonVisibilityTypeEnum.Region} />,
            defaultActive: isActiveTab(RegionTabId),
        }, {
            title: Localizer.global_mine(),
            id: MineTabId,
            content: <BreveOverview visibility={BrevskabelonVisibilityTypeEnum.Private} />,
            defaultActive: isActiveTab(MineTabId),
        }
    ];

    return (
        <Tabs
            tabs={tabs}
            setActiveCallback={(id: string) => setActiveTabId(id)}
        />
    );
}

export default BrevskabelonPageComponent;
