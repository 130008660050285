import {Localizer} from "../../../infrastructure/localization/localizer";
import React, {useCallback, useEffect, useState} from "react";
import {ForloebOverviewFilterModel} from "./ForloebOverviewFilterModel";
import { UddannelseTypeEnum } from "../../sharedmodels/stilling/uddannelseTypeEnum";
import {Loading} from "../loading/loading";
import {IForloebOverviewFilterProps} from "./forloebOverviewFilterProps";
import { DateFilterInput, getDateFilterInput } from "./ForloebOverviewDateFilterModel";
import { DateFilter } from "./filterComponents/DateFilter";
import { Dropdown, DropdownOption } from "../dropdown/dropdown";
import useEnumDropdownOptions from "../dropdown/hooks/useEnumDropdownOptions";
import { nameof } from "ts-simple-nameof";
import {ActionMeta, SingleValue} from "react-select";
import {GetKbuRundeEnumTranslation, KbuRundeEnum} from "../../sharedmodels/user/kbuRundeEnum";
import useStamdataApi from "../../hooks/useStamdataApi";
import EducationFilter, { EducationFilterResult } from "../filter/components/educationFilter";
import PlaceFilter, { PlaceFilterResult } from "../filter/components/placeFilter";
import useUserFilterPermissions from "core/hooks/useUserFilterPermissions";
import useUddannelseModels from "../dropdown/hooks/useUddannelseModels";
import ForloebCrudFilterRestrictModel, { EmptyForloebCrudFilterRestrictModel } from "core/sharedmodels/restrictModel/ForloebCrudFilterRestrictModel";
import { hasAnyDefinedProperties } from "index";

const hasFiltersToRestrict = (filterModel: ForloebOverviewFilterModel) => {
    return hasAnyDefinedProperties({
        specialer: filterModel.specialer,
        uddannelser: filterModel.uddannelser,
        regioner: filterModel.regioner,
        omraader: filterModel.omraader,
        afdelinger: filterModel.afdelinger,
        praksiser: filterModel.praksis,
        sygehuse: filterModel.hospitaler,
    });
}

export const CrudFilterInputModel = (props: IForloebOverviewFilterProps) => {
    const { permissions } = useUserFilterPermissions();
    const { stamdataKbuRundeApi, stamdataRestrictApi } = useStamdataApi();
    const { toIdsFromUddannelsesTyper, toUddannelsesTyperFromId, isLoading: isFetching } = useUddannelseModels();

    const [filterModel, setFilterModel] = useState<ForloebOverviewFilterModel>(props.filter);
    const [isLoading, setIsLoading] = useState(true);
    const [kbuPeriodOptions] = useEnumDropdownOptions<KbuRundeEnum>(KbuRundeEnum,GetKbuRundeEnumTranslation)
    const [kbuRundesSpan, setKbuRundesSpan] = useState<DropdownOption<string>[]>([]);
    const [showKbuInputFields, setShowKbuInputFields] = useState<boolean | undefined>(false);

    const [filterRestrictModel, setFilterRestrictModel] = useState<ForloebCrudFilterRestrictModel>(EmptyForloebCrudFilterRestrictModel);

    useEffect(() => {
        if (props.onChangeCallback) {
            props.onChangeCallback(filterModel);
        }
    }, [filterModel, props.onChangeCallback]);

    useEffect(() => {
        const fetchFilterRestrictModel = async () => {
            const hasFilterToRestrict = hasFiltersToRestrict(filterModel);
            setFilterRestrictModel(hasFilterToRestrict
                ? await stamdataRestrictApi.getRestrictionForloebCrudFilter(filterModel)
                : EmptyForloebCrudFilterRestrictModel);
        }
        fetchFilterRestrictModel();
    }, [filterModel, stamdataRestrictApi])

    const onEducationFilterChange = useCallback((result: EducationFilterResult) => {
        const uddannelsesTyper = toUddannelsesTyperFromId(result.uddannelser);

        setFilterModel(prev => (
            {
                ...prev,
                specialer: result.specialer,
                uddannelsetyper: uddannelsesTyper,
            }
        ));

        //Hack to make it work with "useRef" and "clone" in forloebOverviewFilter (Create/Edit)
        //TODO: Refactor forloebOverviewFilter-logic to use state and make a onChangeFilter callback
        props.filter.specialer = result.specialer;
        props.filter.uddannelsetyper = uddannelsesTyper;
    }, [props.filter, toUddannelsesTyperFromId]);

    const onPlaceFilterChange = useCallback((result: PlaceFilterResult) => {
        setFilterModel(prev => ({...prev, ...{
            regioner: result.regioner,
            omraader: result.omraader,
            afdelinger: result.afdelinger,
            praksis: result.praksiser,
            hospitaler: result.sygehuse,
            alleRegionerValgt: result.allRegionsSelected
        }}));

        //Hack to make it work with "useRef" and "clone" in forloebOverviewFilter (Create/Edit)
        //TODO: Refactor forloebOverviewFilter-logic to use state and make a onChangeFilter callback
        props.filter.regioner = result.regioner;
        props.filter.omraader = result.omraader;
        props.filter.afdelinger = result.afdelinger;
        props.filter.praksis = result.praksiser;
        props.filter.hospitaler = result.sygehuse;
    }, [props.filter]);

    useEffect(() => {
        const getUddannelsesTypeAndKbuPeriod = async () => {
            const kbuRundesSpan = await stamdataKbuRundeApi.getKbuPeriodSpan();
            setKbuRundesSpan(kbuRundesSpan.map(sub => ({label: sub.toString(), value: sub.toString()})))
            setIsLoading(false);
        }
        getUddannelsesTypeAndKbuPeriod();
    }, [stamdataKbuRundeApi]);

    useEffect(()=> {
        const showKbuFields = filterModel?.uddannelsetyper?.includes(UddannelseTypeEnum.KBU);

        setShowKbuInputFields(showKbuFields)
        if(!showKbuFields) {
            setFilterModel(prev => ({...prev,
                kbuPeriod: undefined,
                kbuPeriodYear: undefined
            }));
            props.filter.kbuPeriod = undefined;
            props.filter.kbuPeriodYear = undefined;
        }
    },[filterModel?.uddannelsetyper, props.filter])

    const handleKbuPeriodSelected = (newValue: SingleValue<DropdownOption<KbuRundeEnum>>, actionMeta: ActionMeta<DropdownOption<KbuRundeEnum>>) => {
        setFilterModel(prev => ({...prev,
            kbuPeriod: String(newValue?.value!!),
        }));

        props.filter[actionMeta.name as string] = newValue?.value;
    }

    const handleKbuPeriodSpanSelected = (newValue:  SingleValue<DropdownOption<string>>) => {
        setFilterModel((prev) => ({...prev,
            kbuPeriodYear: newValue?.value,
        }));
        props.filter.kbuPeriodYear = newValue?.value;
    }

    const onDateFilterInputChanged = useCallback((forloebOverviewDateFilterModel: DateFilterInput) => {
        setFilterModel((prev) => ({...prev,
            dateFilterType: forloebOverviewDateFilterModel.dateFilterType,
            year: forloebOverviewDateFilterModel.year,
            date: forloebOverviewDateFilterModel.date.toIsoDateTimeString(),
            periodFrom: forloebOverviewDateFilterModel.periodFrom.toIsoDateTimeUtcString(),
            periodTo: forloebOverviewDateFilterModel.periodTo.toIsoDateTimeUtcString()
        }));

        //Hack to make it work with "useRef" and "clone" in forloebOverviewFilter (Create/Edit)
        //TODO: Refactor forloebOverviewFilter-logic to use state and make a onChangeFilter callback
        props.filter.dateFilterType = forloebOverviewDateFilterModel.dateFilterType;
        props.filter.year = forloebOverviewDateFilterModel.year;
        props.filter.date = forloebOverviewDateFilterModel.date.toIsoDateTimeString();
        props.filter.periodFrom = forloebOverviewDateFilterModel.periodFrom.toIsoDateTimeUtcString();
        props.filter.periodTo = forloebOverviewDateFilterModel.periodTo.toIsoDateTimeUtcString();
    }, [props.filter]);

    return (
    <>
        <DateFilter
            modalId={props.modalId}
            filter={getDateFilterInput(props.filter)}
            callback={onDateFilterInputChanged}
        />

        <Loading isLoading={isLoading || isFetching} text={Localizer.global_loadingFilters()} spinnerClasses={isLoading ? "padding-top-l" : ""}>

            <EducationFilter onChange={onEducationFilterChange} permissions={permissions} titled
                additionalFilterTitleClasses={"padding-top-m"}
                initialSelectedValues={{
                    specialer: filterModel?.specialer ?? [],
                    uddannelser: toIdsFromUddannelsesTyper(filterModel?.uddannelsetyper),
                }}
                restrictModel={filterRestrictModel}
            />

            {showKbuInputFields &&
                <>
                    <label>{Localizer.global_kburunde()}</label>
                    <div className={"d-flex justify-content-between"}>
                        <div className="margin-bottom-m mr-3 w-50">
                            <Dropdown
                                name={nameof<ForloebOverviewFilterModel>(model => model.kbuPeriod)}
                                placeholder={Localizer.global_period()}
                                options={kbuPeriodOptions}
                                onChange={handleKbuPeriodSelected}
                                value={kbuPeriodOptions[parseInt(filterModel?.kbuPeriod as string)-1]}
                                closeMenuOnSelect={false}
                                isClearable
                            />
                        </div>

                        <div className="margin-bottom-m ml-3 w-50">
                            <Dropdown
                                name={nameof<ForloebOverviewFilterModel>(model => model.uddannelsetyper)}
                                placeholder={Localizer.global_aar()}
                                options={kbuRundesSpan}
                                onChange={(newVal) => handleKbuPeriodSpanSelected(newVal)}
                                closeMenuOnSelect={false}
                                value={kbuRundesSpan.filter(x=>x.value === filterModel?.kbuPeriodYear!!)}
                                isClearable
                            />
                        </div>
                    </div>
                </>
            }

            <PlaceFilter onChange={onPlaceFilterChange} permissions={permissions} titled
                additionalFilterTitleClasses={"padding-top-m"}
                initialSelectedValues={{
                    regioner: filterModel.regioner ?? [],
                    omraader: filterModel.omraader ?? [],
                    afdelinger: filterModel.afdelinger ?? [],
                    praksiser: filterModel.praksis ?? [],
                    sygehuse: filterModel.hospitaler ?? [],
                }}
                restrictModel={filterRestrictModel}
            />

            <div className="border-bottom">&nbsp;</div>
        </Loading>
    </>
    )
}
