import { Localizer } from "infrastructure/localization/localizer"
import { ForloebOverviewEmptyCard } from "pages/forloeb/forloebOverviews/forloebOverviewEmptyCard"

const NoUddannelsestedFilesCard = () => {
    return (
        <ForloebOverviewEmptyCard emptyText={Localizer.forloebpage_UddannelsefilerOverviewEmptyInfo()}>
            <p>{Localizer.forloebpage_UddannelsefilerOverviewEmptyInfo()}</p>
        </ForloebOverviewEmptyCard>
    )
}

export default NoUddannelsestedFilesCard;