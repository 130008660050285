import { useEffect, useState } from "react";

export type TableOutputInfo = {
  recordsFiltered: number;
  recordsTotal: number;
};

const defaultOutputInfoFormat = (filteredCount: number, totalCount: number) => {
  return `${filteredCount} / ${totalCount}`;
}

export const UseUpdateTableOutputInfo = (
  uniqueParentContainerId: string, 
  outputInfoContainerId?: string,
  overrideFormat?: (filteredCount: number, totalCount: number) => string,
  ) => {
  const [outputInfo, setOutputInfo] = useState<TableOutputInfo>({ recordsFiltered: 0, recordsTotal: 0 });

  useEffect(() => {
    const formatOutputInfo = (filteredCount: number, totalCount: number) => {
      return overrideFormat 
        ? overrideFormat(filteredCount, totalCount) 
        : defaultOutputInfoFormat(filteredCount, totalCount);
    }

    if (outputInfoContainerId) {
        const $tableParentContainer = $(`#${uniqueParentContainerId}`);
        const recordOutputInfoElement = $tableParentContainer.find(`#${outputInfoContainerId}`);

        if (recordOutputInfoElement.length === 0) { return; }

        const outputInfoFormatted = formatOutputInfo(outputInfo.recordsFiltered, outputInfo.recordsTotal);
        recordOutputInfoElement.text(outputInfoFormatted);
    }
  }, [outputInfo, outputInfoContainerId, overrideFormat, uniqueParentContainerId]);

  return {
    setOutputInfo
  }
}