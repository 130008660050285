import { PropsWithChildren } from "react";
import HtmlHeading from "./HtmlHeadings";
import classNames from "classnames";
import { EmblaIcon } from "../emblaIcon/emblaIcon";
import { EmblaIcons } from "core/emblaIcons";
import Tooltip from "../tooltips/Tooltip";

export type TitleProps = {
    title?: string;
    titleContainerClasses?: string;
    titleClasses?: string;
    titleInfoText?: string
    titleInfoClasses?: string;
    titleInfoIconClasses?: string;
    subTitle?: string;
    subTitleClasses?: string;
    largeSubTitle?: boolean;
    heading?: HtmlHeading;
    nonSubtleSubTitle?: boolean;
}

const Title = ({
    title, 
    subTitle, 
    subTitleClasses = "", 
    titleContainerClasses = "",
    titleClasses = "", 
    heading = HtmlHeading.H5, 
    largeSubTitle = false, 
    children, 
    nonSubtleSubTitle = false, 
    titleInfoText, 
    titleInfoClasses, 
    titleInfoIconClasses
} : PropsWithChildren<TitleProps>) => {

    const HeadingComponent = `h${heading}` as keyof JSX.IntrinsicElements;

    return (
        <>
            {title &&
                <div className={classNames(titleContainerClasses, titleInfoText && "d-flex align-items-center")}>
                    <HeadingComponent className={titleClasses}>
                        {title}
                    </HeadingComponent>
                    {titleInfoText &&
                        <Tooltip title={titleInfoText} className={classNames(!titleInfoClasses && "margin-left-xs subtle align-content-end justify-content-center", titleInfoClasses)}>
                            <EmblaIcon additionalClasses={classNames(titleInfoIconClasses)} iconName={EmblaIcons.Info}/>
                        </Tooltip>
                    }
                </div>
            }

            {children &&
                children
            }

            {subTitle &&
                <p className={classNames(!nonSubtleSubTitle && 'subtle', !largeSubTitle && 'small', subTitleClasses)}>
                    {subTitle}
                </p>
            }
        </>
    );
}
export default Title;
