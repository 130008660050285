import {EmblaIcon} from "../emblaIcon/emblaIcon";
import {EmblaIconsType} from "../../emblaIcons";
import classNames from "classnames";

type BadgeType = "badge-default" | "badge-warning" | "badge-primary-light" | "badge-danger";

type InfoBadgePillInputProps = {
    badgeType: BadgeType
    badgeText: string;
    emblaIconName?: EmblaIconsType;
    additionalClasses?: string;
}

const InfoBadgePill = ({badgeType, badgeText, emblaIconName, additionalClasses}: InfoBadgePillInputProps) => {

    return (
        <>
          <span className={classNames("badge badge-pill padding-xs padding-right-s padding-left-s margin-right-s", badgeType, additionalClasses)}>
              <div>
                  {emblaIconName &&
                    <EmblaIcon iconName={emblaIconName}></EmblaIcon>
                  }
                  {badgeText}
              </div>
          </span>
        </>
    )
};

export default InfoBadgePill;
