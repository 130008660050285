import FileAdministrationPageComponent from "./components/FileAdministrationPageComponent";
import FileContextProvider from "./context/fileContextProvider";

const FileAdministrationPage = () => {
    
    return(
        <FileContextProvider>
            <FileAdministrationPageComponent />
        </FileContextProvider>
    )
}

export default FileAdministrationPage;