import { Dropdown } from "core/components/dropdown/dropdown";
import Title from "core/components/titels/title";
import { Localizer } from "infrastructure/localization/localizer";
import { createPortal } from "react-dom";
import { SlideIn, closeModal } from "core/components/slideIn/slideIn";
import { EmblaIcons } from "core/emblaIcons";
import DynamicButton from "core/components/button/dynamicButton";
import { BrevForloebMergeFieldsType } from "../shared/brevForloebMergeFieldsType";
import { useCallback } from "react";
import useBrevForloebMergeFieldDropdownOptions from "../hooks/useBrevForloebMergeFieldDropdownOption";
import useBrevTekstMergeFieldDropdownOptions from "../hooks/useBrevTekstMergeFieldDropdownOptions";
import { BrevTekstMergeFieldsType } from "../shared/brevTekstMergeFieldsType";

const brevMergeFieldSlideInId = "brev-mergefield-slidein";

type BrevMergeFieldSlideInProps = {
    show: boolean;
    onClose: () => void;
    onSubmit: (forloebMergeFieldsType?: BrevForloebMergeFieldsType, tekstMergeFieldsType?: BrevTekstMergeFieldsType) => void;
}

const BrevMergeFieldSlideIn = ({show, onClose, onSubmit} : BrevMergeFieldSlideInProps) => {

    const {
        dropdownOptions: forloebMergeFieldOptions,
        selectedOption: selectedForloebMergeFieldOption,
        setSelectedOption: setForloebSelectedOption
    } = useBrevForloebMergeFieldDropdownOptions();

    const {
        dropdownOptions: tekstMergeFieldOptions,
        selectedOption: selectedTekstMergeFieldOption,
        setSelectedOption: setTextSelectedOption
    } = useBrevTekstMergeFieldDropdownOptions();

    const close = useCallback(() => {
        onClose();
        closeModal(brevMergeFieldSlideInId);
    }, [onClose]);

    return (
        <>
            {show &&
                createPortal(
                    <SlideIn
                        id={brevMergeFieldSlideInId}
                        defaultOpen
                        title={Localizer.letterPage_MergeFields()}
                        actionOnCloseCallback={close}
                        actionFinishedCallback={close}
                        bodyContent={
                            <div>
                                <Title title={Localizer.global_forloebMergeField()}>
                                    <Dropdown
                                        options={forloebMergeFieldOptions}
                                        placeholder={Localizer.global_chooseMergeField()}
                                        onChange={(newVal) => setForloebSelectedOption(newVal?.value)}  
                                        isMulti={false}
                                        isDisabled={!!selectedTekstMergeFieldOption}
                                    />
                                </Title>

                                <Title title={Localizer.global_tekstMergeField()} titleClasses="margin-top-m">
                                    <Dropdown
                                        options={tekstMergeFieldOptions}
                                        placeholder={Localizer.global_chooseMergeField()}
                                        onChange={(newVal) => setTextSelectedOption(newVal?.value)}  
                                        isMulti={false}
                                        isDisabled={!!selectedForloebMergeFieldOption}
                                    />
                                </Title>
                            </div>
                        }
                        footerContent={
                            <>
                                <DynamicButton onClick={close}>
                                    <DynamicButton.TextIconItem 
                                        iconName={EmblaIcons.Close}
                                        text={Localizer.global_cancel()}
                                    />
                                </DynamicButton>

                                <DynamicButton isPrimary
                                    onClick={() => {
                                        onSubmit(selectedForloebMergeFieldOption, selectedTekstMergeFieldOption);
                                        close();
                                    }}
                                >
                                    <DynamicButton.TextIconItem 
                                        iconName={EmblaIcons.Plus}
                                        text={Localizer.global_add()}
                                    />
                                </DynamicButton>
                            </>
                        }
                    />,
                    document.body
                )
            }
        </>
    )
}

export default BrevMergeFieldSlideIn;
