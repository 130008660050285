import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../../app/hooks";
import {forloebReducer, ForloebSliceState} from "../../../core/forloebSlice";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {Loading} from "../../../core/components/loading/loading";
import {ForloebOverviewCard} from "./forloebOverviewCard";
import {ForloebOverviewEmptyCard} from "./forloebOverviewEmptyCard";
import useStamdataApi from "../../../core/hooks/useStamdataApi";
import {FolderAndFiles} from "../../../core/components/mapper/folderAndFiles";
import {getAmountOfFilesTotal} from "../../../core/components/mapper/renderFolderChildrenRecursively";
import {FilesAndFoldersModel} from "../../../services/api/stamdataMappe/FilesAndFoldersModel";
import {ActionIcon} from "../../../core/components/actionIcon/actionIcon";
import {EmblaIcons} from "../../../core/emblaIcons";
import {RoutePaths} from "../../../infrastructure/routes";
import {useRouteMatch} from "react-router-dom";

type SpecialespecifikkeFilerOverviewProps = {
    showRightHeaderContent?: boolean
}
export function SpecialespecifikkeFilerOverview({showRightHeaderContent = false}: SpecialespecifikkeFilerOverviewProps) {
    const {stamdataMappeApi} = useStamdataApi();
    const [isLoading, setIsLoading] = useState(true);
    const [filesAndFolders, setFilesAndFolders] = useState<FilesAndFoldersModel>();
    const [fileCount, setFileCount] = useState<number>(0);
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;
    const routeMatch = useRouteMatch();
    const uddannelseslaegeId = (routeMatch.params as { id: string }).id;

    useEffect(() => {
        const fetch = async () => {
            const mapperWithFiles = await stamdataMappeApi.getMapperAndFilesByRegionAndMaalbeskrivelse(forloebSliceState.forloebState.regionId, forloebSliceState.forloebState.brugerMaalbeskrivelseId);
            const filesInChildrenRecursive = getAmountOfFilesTotal(mapperWithFiles);
            setFileCount(filesInChildrenRecursive);
            setFilesAndFolders(mapperWithFiles);
        }
        fetch().finally(() => setIsLoading(false));
    }, [forloebSliceState.forloebState.brugerMaalbeskrivelseId, forloebSliceState.forloebState.regionId, stamdataMappeApi]);

    return (
        <ForloebOverviewCard
            paddingNone={fileCount > 0}
            title={`${Localizer.Files()}`}
            rightHeaderContent={showRightHeaderContent &&
                <div className="d-flex">
                    <ActionIcon iconName={EmblaIcons.ArrowRight} routeUrl={RoutePaths.Files(uddannelseslaegeId).url}/>
                </div>
            }>
            <Loading isLoading={isLoading} text={Localizer.forloebpage_getSpecialeSpecifikkeFiler()} spinnerClasses={"padding-l"}>
                {filesAndFolders &&
                    <FolderAndFiles folderAndFiles={filesAndFolders}/>
                }
                {fileCount < 1 &&
                    <>
                        <ForloebOverviewEmptyCard
                            emptyText={Localizer.forloebpage_SpecialespecifikkefilerOverviewEmptyInfo()}>
                            <p>{Localizer.forloebpage_SpecialespecifikkefilerOverviewEmptyInfo()}</p>
                        </ForloebOverviewEmptyCard>
                    </>
                }
            </Loading>

        </ForloebOverviewCard>
    );
}
