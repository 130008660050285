import { useEffect, useState } from "react";
import { DropdownOption } from "../dropdown";

const filterDropdownOptionsByRestrictedValues = <T>(restrictedTValues: T[], options: DropdownOption<T>[], shouldFilter=true) => {
    return restrictedTValues.length > 0 && shouldFilter
        ? options.filter(o => restrictedTValues.includes(o.value))
        : options;
}

const useRestrictedDropdownOptions = <T> (restrictedValues: T[], originalDropdownOptions: DropdownOption<T>[], shouldFilter=true)  => {
    
    const [restrictedDropdownOptions, setRestrictedDropdownOptions] = useState<DropdownOption<T>[]>([]);
    
    useEffect(() => {
        const optionFilteredByRestriction = filterDropdownOptionsByRestrictedValues(restrictedValues, originalDropdownOptions, shouldFilter);
        setRestrictedDropdownOptions(optionFilteredByRestriction);
    }, [originalDropdownOptions, restrictedValues, shouldFilter]);

    return {
        restrictedDropdownOptions,
    }
}

export default useRestrictedDropdownOptions;