import useScreenResolution from "core/hooks/useScreenResolution";

type TitleProps = {
    text: string;
    className?: string;
}

const Title = ({text, className="card-title"}: TitleProps) => {
    const { isMobile } = useScreenResolution();
    
    return isMobile 
        ? <h5 className={className}>{text}</h5>
        : <h3 className={className}>{text}</h3>
};

export default Title;