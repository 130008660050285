import {createContext, ReactNode, useCallback, useContext, useReducer} from "react";
import BrevskabelonDto from "services/api/brevskabelon/models/brevskabelonDto";
import { WholeDenmarkTabId } from "../shared/brevskabelonTabId";

type BrevskabelonContextType = {
    breve: BrevskabelonDto[];
    isLoading: boolean;
    activeTabId: string;
    laegeId: string,
    regionId?: string,
}

const BrevskabelonContext = createContext<{
    state: BrevskabelonContextType,
    setBreve: (payload: BrevskabelonDto[]) => void,
    setIsLoading: (payload: boolean) => void,
    setActiveTabId: (payload: string) => void,
    setLaegeId: (payload: string) => void,
    setRegionId: (payload: string) => void,
} | undefined>(undefined);

enum BrevskabelonReducerActionType {
    SetBreve,
    SetIsLoading,
    SetActiveTabId,
    SetLaegeId,
    SetRegionId
}

const BrevskabelonReducer = (
    state: BrevskabelonContextType,
    action: {type: BrevskabelonReducerActionType, payload: Partial<BrevskabelonContextType>}
): BrevskabelonContextType => {
    switch(action.type) {
        case BrevskabelonReducerActionType.SetBreve:
            return {
                ...state,
                breve: action.payload.breve ?? state.breve,
            }
        case BrevskabelonReducerActionType.SetIsLoading:
            return {
                ...state,
                isLoading: action.payload.isLoading ?? state.isLoading,
            }
        case BrevskabelonReducerActionType.SetActiveTabId:
            return {
                ...state,
                activeTabId: action.payload.activeTabId ?? state.activeTabId,
            }
        case BrevskabelonReducerActionType.SetLaegeId:
            return {
                ...state,
                laegeId: action.payload.laegeId ?? state.laegeId,
            }
        case BrevskabelonReducerActionType.SetRegionId:
            return {
                ...state,
                regionId: action.payload.regionId ?? state.regionId,
            }
    }
}

export const useBrevskabelonContext = () => {
    const context = useContext(BrevskabelonContext);
    if (!context) throw new Error("useBrevskabelonContext must be used in BrevskabelonContextProvider");
    return context;
};

type BrevskabelonContextProviderProps = { children?: ReactNode; }

const BrevskabelonContextProvider = ({ children }: BrevskabelonContextProviderProps) => {

    const [state, dispatch] = useReducer(BrevskabelonReducer, { 
        breve: [],
        isLoading: true,
        activeTabId: WholeDenmarkTabId,
        laegeId: "",
        regionId: undefined
    });

    const setBreve = useCallback((payload: BrevskabelonDto[]) => {
        dispatch({
            type: BrevskabelonReducerActionType.SetBreve,
            payload: { breve: payload }
        });
    }, []);

    const setIsLoading = useCallback((payload: boolean) => {
        dispatch({
            type: BrevskabelonReducerActionType.SetIsLoading,
            payload: { isLoading: payload }
        });
    }, []);

    const setActiveTabId = useCallback((payload: string) => {
        dispatch({
            type: BrevskabelonReducerActionType.SetActiveTabId,
            payload: { activeTabId: payload }
        });
    }, []);

    const setLaegeId = useCallback((payload: string) => {
        dispatch({
            type: BrevskabelonReducerActionType.SetLaegeId,
            payload: { laegeId: payload }
        });
    }, []);

    const setRegionId = useCallback((payload: string) => {
        dispatch({
            type: BrevskabelonReducerActionType.SetRegionId,
            payload: { regionId: payload }
        });
    }, []);


    return (
        <BrevskabelonContext.Provider
            value={{
                state,
                setBreve,
                setIsLoading,
                setActiveTabId,
                setLaegeId,
                setRegionId
            }}
        >
            {children}
        </BrevskabelonContext.Provider>
    );
};

export default BrevskabelonContextProvider;
