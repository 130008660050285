import { DropdownOption } from "../dropdown";

type DropdownPermissions = {
    ids: Set<string>,
    accessToEverything: boolean
}

const filterByDropdownPermissions = (dropdownOptions: DropdownOption<string>[], permissions?: DropdownPermissions) => {
    return !permissions || permissions.accessToEverything
        ? dropdownOptions
        : dropdownOptions.filter(x => permissions.ids.has(x.value));
}

export default DropdownPermissions;

export {
    filterByDropdownPermissions
}
