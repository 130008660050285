
import { sanitize } from 'dompurify';
import { HtmlHTMLAttributes } from 'react';

// https://github.com/cure53/DOMPurify

const SanitizedHtmlStringDiv = ({htmlString, ...props} : {htmlString: string} & HtmlHTMLAttributes<HTMLDivElement>) => {
    return (
        <div {...props} dangerouslySetInnerHTML={{__html: sanitize(htmlString)}} />
    )
}

export default SanitizedHtmlStringDiv;
