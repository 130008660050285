import React, { useState } from 'react'
import { Localizer } from 'infrastructure/localization/localizer'
import { Form, Formik } from "formik";
import { setSlideinState, SlideInStateEnum } from "../../../../core/components/slideIn/slideInSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { ValidationFormSubmitButton } from "../../../../core/components/validation/components/validationFormButton";
import { HandleError } from "../../../../core/infrastructure/errors/errorBoundary";
import { CrudVejledersamtaleInputModel, crudVejledersamtaleValidationSchema } from "./crudVejledersamtaleInputModel";
import { VejledersamtaleCrudFields } from "./vejledersamtalerCrudFields";
import { forloebReducer, ForloebSliceState } from "../../../../core/forloebSlice";
import { VejledersamtaleEnum } from "../vejledersamtaleModel";
import { ReactFileUploaderFile } from "../../../../core/components/fileUpload/reactFileUploader";
import { FileMetadata } from "../../../../core/sharedmodels/fileMetaData/fileMetaData";
import { NotatType } from "../../../../core/sharedmodels/notat/notatType";
import {BuildNewCrudNotatModel} from "../../../../core/sharedmodels/notat/notatCrudModel";
import { ModalAcceptType } from 'core/components/modalMessage/modalSubmitMessage';
import { nameof } from 'ts-simple-nameof';
import useUser from 'core/hooks/useUser';
import useLogbogApi from 'core/hooks/useLogbogApi';

type CreateVejledersamtaleProps = {
    modalId: string,
    vejledersamtaleType: VejledersamtaleEnum
    titel?: string
}

export const CreateVejledersamtale = (props: CreateVejledersamtaleProps) => {
    
    const { currentUser } = useUser();
    const {vejledersamtaleApi, notatApi} = useLogbogApi();
    
    const dispatch = useAppDispatch();
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;
    
    const [uploadedFilesState, setUploadedFilesState] = useState<ReactFileUploaderFile[]>([]);
    const [disableSubmit, setDisableSubmit] = useState(false);
    
    const showOpretOgKlarmeldButton = currentUser.IsAdmin() || currentUser.IsLaege();

    const createVejledersamtale = async (inputModel: CrudVejledersamtaleInputModel) => {
        dispatch(setSlideinState({ state: SlideInStateEnum.ActionOngoing, slideInId: props.modalId }))

        let vejledersamtaleId = await vejledersamtaleApi.createVejledersamtale(inputModel);

        if (uploadedFilesState?.length > 0){
            let vejledersamtaleNotat = BuildNewCrudNotatModel([vejledersamtaleId], NotatType.Vejledersamtale, forloebSliceState.forloebState.brugerId);
            await notatApi.createNotat(vejledersamtaleNotat, uploadedFilesState.map(tf => tf.fileMetadata.id));
        }

        dispatch(setSlideinState({ state: SlideInStateEnum.Closing, slideInId: props.modalId }))
    }

    const fileUploadedCallback = async (uploadedFiles: ReactFileUploaderFile[]) => {
        setUploadedFilesState(arr => [...arr, ...uploadedFiles]);
    }

    const fileDeletedCallback = (fileMetaData: FileMetadata) => {
        let updatedFileUploadedState = [...uploadedFilesState];
        let deleteIndex = updatedFileUploadedState.findIndex(file => file.fileMetadata.id === fileMetaData.id);
        updatedFileUploadedState.splice(deleteIndex, 1);
        setUploadedFilesState(updatedFileUploadedState);
    }

    const render = (
        <Formik
            initialValues={new CrudVejledersamtaleInputModel(forloebSliceState.forloebState.id, forloebSliceState.forloebState.brugerId, props.vejledersamtaleType, props.titel)}
            validationSchema={crudVejledersamtaleValidationSchema}
            onSubmit={async (values: CrudVejledersamtaleInputModel) => {
                await createVejledersamtale(values)
                    .catch(err => { // catch is needed to handle unhandeled exception (eg. api offline). Formik onsubmithandler is catching all exception and writing them as warnings
                        dispatch(setSlideinState({ state: SlideInStateEnum.Opened, slideInId: props.modalId }))
                        HandleError(err);
                    });
            }}>
            {
                (formik) => (
                    <Form>
                        <VejledersamtaleCrudFields
                            modalId={props.modalId}
                            formik={formik}
                            vejledersamtaleType={props.vejledersamtaleType}
                            fileUploadedCallbackOverride={fileUploadedCallback}
                            fileDeletedCallbackOverride={fileDeletedCallback}
                            setSubmitIsDisabled={setDisableSubmit}
                        />

                        <div className="modal-actions d-flex wrap">
                            {showOpretOgKlarmeldButton &&
                                <ValidationFormSubmitButton
                                    formikProps={formik}
                                    disabled={disableSubmit}
                                    buttonType={ModalAcceptType.default}
                                    onClickSubmitOverride={() => {
                                        formik.setFieldValue(nameof<CrudVejledersamtaleInputModel>(x => x.klarmeldtAt), new Date());
                                        formik.handleSubmit()
                                    }}>
                                    {Localizer.vejledersamtalePage_createAndKlarmeldVejledersamtale()}
                                </ValidationFormSubmitButton>
                           }
                            <ValidationFormSubmitButton formikProps={formik} disabled={disableSubmit}>
                                {Localizer.vejledersamtalePage_createVejledersamtale()}
                            </ValidationFormSubmitButton>
                        </div>
                    </Form>
                )
            }
        </Formik>
    )
    return <>{render}</>
}
