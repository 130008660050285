import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState,} from 'app/store';

export interface AsyncTableState {
    tableInitialized: boolean;
    pingReload: boolean;
    filter?: any
    search?: string;
    order?: string;
}

export const initialState: AsyncTableState = {
    tableInitialized: false,
    pingReload: false,
    filter: undefined,
    search: undefined,
    order: undefined,
};

export const AsyncTableSlice = createSlice({
    name: 'AsyncTableSlice',
    initialState,
    reducers: {
        setTableInitialized: (state, action: PayloadAction<boolean>) => {
            state.tableInitialized = action.payload;
        },
        setFilter: (state, action: PayloadAction<any>) => {
            state.filter = action.payload;
        },
        setSearch: (state, action: PayloadAction<any>) => {
            state.search = action.payload;
        },
        clearFilter: (state) => {
            state.filter = initialState.filter;
        },
        setPingReload: (state) => {
            state.pingReload = !state.pingReload;
        },
        setOrder: (state, action: PayloadAction<any>) => {
            state.order = action.payload;
        }
    }
});

export const {setTableInitialized, setFilter, setSearch, clearFilter, setPingReload, setOrder} = AsyncTableSlice.actions;
export const AsyncTableReducer = (state: RootState) => state.asyncTableReducer;
export default AsyncTableSlice.reducer;
