import {Localizer} from "../../../../infrastructure/localization/localizer";
import PdfHeaderRow from "../pdfHeaderRow";
import { useEffect, useState } from "react";
import { UserProfileModel } from "../../userProfile/userProfileModel";
import { LogbogUserInfoApi } from "services/api/logbogUser/logbogUserInfoApi";
import { HttpClientService } from "services/httpClient/httpClientService";
import { AccountService } from "services/account/accountService";
import {useFetchWithErrorHandlingPdf} from "../useFetchWithErrorHandlingPdf";

const logbogUserInfoApi = new LogbogUserInfoApi(new HttpClientService(), new AccountService());

export function PdfDetailedUserHeaderComponent(props: { brugerId: string }) {
    const [bruger, setBruger] = useState<UserProfileModel>();
    const setError = useFetchWithErrorHandlingPdf();

    useEffect(() => {
        const fetchUserInfo = async () => {
            setBruger(await logbogUserInfoApi.getUserInfoById(props.brugerId));
        }
        fetchUserInfo().catch(err => setError(err));
    }, [props.brugerId, setError]);

    return (
        <>
            <PdfHeaderRow>
                <PdfHeaderRow.Entry
                    label={Localizer.global_name()}
                    value={bruger?.getFullName()}
                />
                <PdfHeaderRow.Entry
                    label={Localizer.global_foedselsdato()}
                    value={bruger?.foedselsdato}
                />
            </PdfHeaderRow>
            <PdfHeaderRow>
                <PdfHeaderRow.Entry
                    label={Localizer.global_mobilePhone()}
                    value={bruger?.phone}
                />
                <PdfHeaderRow.Entry
                    label={Localizer.global_email()}
                    value={bruger?.email}
                />
            </PdfHeaderRow>
            <PdfHeaderRow>
                <PdfHeaderRow.Entry
                    label={Localizer.global_authorisationId()}
                    value={bruger?.authorizationId}
                />
            </PdfHeaderRow>
        </>
    );
}
