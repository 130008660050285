import React, { Component } from 'react';
import { loadConfig } from "./config";
import {loadOidcConfig} from "./oidcConfig";

interface ConfigLoaderProps {
  ready: (config: any) => React.ReactNode;
  loading?: () => React.ReactNode;
}

interface ConfigLoaderState {
  isLoaded: boolean;
  config?: any;
}

export default class ConfigLoader extends Component<ConfigLoaderProps, ConfigLoaderState> {
  constructor(props: ConfigLoaderProps) {
    super(props);
    this.state = {
      isLoaded: false
    };
  }

  componentDidMount() {
    // Once the configuration is loaded set `isLoaded` to true so we know to render our component
    loadConfig()
      .then(config => {
          loadOidcConfig();

          this.setState({
          isLoaded: true,
          config})
      });
  }

  render() {
    // If we haven't yet loaded the config, show either a "splash" component provided via a `loading` props or return nothing.
    if (!this.state.isLoaded) {
      return this.props.loading ? this.props.loading() : null;
    }

    // The config is loaded so show the component set on the `ready()` props
    return this.props.ready(this.state.config);
  }
}
