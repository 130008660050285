import useNotificationCount from "core/components/notifications/hooks/useNotificationCount";
import { nyhederMenuLink } from "core/layout/shared/MenuLinkProps";
import { useMemo } from "react";

export const useNyhederMenuLink = () => {
  const { currentUnseenRoleNewsNotificationCount } = useNotificationCount(false);

  const nyhederMenuLinkState = useMemo(() => 
    nyhederMenuLink(!!currentUnseenRoleNewsNotificationCount, currentUnseenRoleNewsNotificationCount), 
  [currentUnseenRoleNewsNotificationCount])
  
  return nyhederMenuLinkState;
}
