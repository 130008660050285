import { Localizer } from "infrastructure/localization/localizer";
import { useMemo, useRef, useState } from "react";
import BrevskabelonDto from "services/api/brevskabelon/models/brevskabelonDto";
import useUserFilterPermissions from "core/hooks/useUserFilterPermissions";
import { mapUserPermissionsToDropdownPermissions } from "core/sharedmodels/userPermissions/userFilterPermissions";
import BrevMergeFieldSlideIn from "./brevMergeFieldSlideIn";
import { TextEditorActions } from "core/components/textEditor/useForwardedTextEditorActionsRef";
import Row from "core/components/divSpecializations/Row";
import { ColProps, MaxColWidth } from "core/components/divSpecializations/Col";
import useScreenResolution from "core/hooks/useScreenResolution";
import BrevskabelonVisibilityTypeEnum from "infrastructure/enums/brevskabelonVisibilityTypeEnum";
import { FormikProps } from "formik";
import { ValidationDropdown } from "core/components/validation/components/validationDropdown";
import { nameof } from "ts-simple-nameof";
import useBrevskabelonVisibilityTypeDropdownOptions from "../hooks/useBrevskabelonVisibilityTypeDropdownOptions";
import { ValidationInput } from "core/components/validation/components/validationInput";
import useRegionerDropdownOptions from "core/components/dropdown/hooks/useRegionerDropdownOptions";
import mergeFieldToolbarButton from "core/components/textEditor/toolbarButtons/mergeFieldToolbarButton";
import { ValidationTextEditor } from "core/components/validation/components/validationTextEditor";

type BrevskabelonCrudFieldsProps = {
    formik: FormikProps<BrevskabelonDto>,
    modalId: string
}

export const BrevItemWrapper = ({children, ...props}: ColProps) => (
    <Row>
        <Row.Col {...props}>
            {children}
        </Row.Col>
    </Row>
)

const BrevskabelonCrudFields = ({formik, modalId} : BrevskabelonCrudFieldsProps) => {

    const {isMobile} = useScreenResolution();
    const {permissions} = useUserFilterPermissions();
    const {enumDropdownOptions} = useBrevskabelonVisibilityTypeDropdownOptions();

    const dropdownPermissions = useMemo(() => mapUserPermissionsToDropdownPermissions(permissions, p => p.regionIds), [permissions]);

    const { regionerDropdownOptions } = useRegionerDropdownOptions(dropdownPermissions, false);

    const [showSlideIn, setShowSlideIn] = useState<boolean>(false);

    const textEditorActionsRef = useRef<TextEditorActions>(null);
    
    const nonContentItemWidth = isMobile ? MaxColWidth : 6;
    const dropdownItemWidth = isMobile ? MaxColWidth : 6;

    return (
        <>
            <BrevItemWrapper width={dropdownItemWidth}>
                <ValidationDropdown
                    model={{
                        label: Localizer.global_visibility(),
                        placeholder: Localizer.global_chooseVisibility(),
                        htmlName: nameof<BrevskabelonDto>(x => x.visibilityType)
                    }}
                    options={enumDropdownOptions}
                    isClearable={false}
                    inputId={nameof<BrevskabelonDto>(x => x.visibilityType) + modalId}
                />
            </BrevItemWrapper>

            {formik.values.visibilityType === BrevskabelonVisibilityTypeEnum.Region &&
                <BrevItemWrapper width={dropdownItemWidth}>
                    <ValidationDropdown
                        model={{
                            label: Localizer.global_region(),
                            placeholder: Localizer.forloebOverviewPage_chooseRegion(),
                            htmlName: nameof<BrevskabelonDto>(x => x.regionId),
                        }}
                        options={regionerDropdownOptions}
                        isClearable={false}
                        isMulti={false}
                        formikProps={formik}
                    />
                </BrevItemWrapper>
            }

            <BrevItemWrapper width={nonContentItemWidth}>
                <ValidationInput
                    model={{
                        label: Localizer.global_title(),
                        placeholder: Localizer.titelPlaceholder(),
                        htmlName: nameof<BrevskabelonDto>(x => x.titel)
                    }} 
                    inputId={nameof<BrevskabelonDto>(x => x.titel) + modalId}
                />
            </BrevItemWrapper>

            <BrevItemWrapper width={nonContentItemWidth}>
                <ValidationInput
                    model={{
                        label: Localizer.global_subject(),
                        placeholder: Localizer.subjectPlaceholder(),
                        htmlName: nameof<BrevskabelonDto>(x => x.subject)
                    }} 
                    inputId={nameof<BrevskabelonDto>(x => x.subject) + modalId}
                />
            </BrevItemWrapper>

            <BrevItemWrapper>
                <ValidationTextEditor 
                    ref={textEditorActionsRef}
                    inputId={nameof<BrevskabelonDto>(x => x.bodyMarkdown) + modalId}
                    model={{
                        label: Localizer.global_content(),
                        placeholder: "",
                        htmlName: nameof<BrevskabelonDto>(x => x.bodyMarkdown)
                    }}
                    customButtons={[{
                        onClick: () => setShowSlideIn(true),
                        options: mergeFieldToolbarButton
                    }]}
                    showTableItem
                    showImageItem
                    useDebounce
                    minHeightPx={500}
                    useConstantHeight
                />
            </BrevItemWrapper>
            
            <BrevMergeFieldSlideIn
                show={showSlideIn}
                onSubmit={(forloebMergeField, tekstMergeField) => {
                    if(!forloebMergeField && !tekstMergeField) return;
                    textEditorActionsRef.current?.insertMergeField(forloebMergeField ?? tekstMergeField ?? "");
                }}
                onClose={() => setShowSlideIn(false)}                  
            />
        </>
    )

}

export default BrevskabelonCrudFields;
