import { useCallback, useEffect, useState } from "react";
import { DropdownOption } from "../dropdown";
import { Localizer } from "infrastructure/localization/localizer";
import useStamdataApi from "core/hooks/useStamdataApi";
import DropdownPermissions, { filterByDropdownPermissions } from "../dropdownPermissions/DropdownPermissions";

export const allRegionsKey = "optionAll";
export const allDanishRegionsKey = "optionAllInDenmark";

const allRegionDropdownOption: DropdownOption<string> = { label: Localizer.global_all(), value: allRegionsKey };
const regionsInDkDropdownOption: DropdownOption<string> = { label: Localizer.global_regionerInDenmark(), value: allDanishRegionsKey };
const defaultRegionerDropdownOptions: DropdownOption<string>[] = [];

export type SelectedRegionIdsResult = {
  allRegions: boolean;
  regionIds?: string[];
}

/**
 * Hook that fetches regioner and creates/manages region-dropdown-options
 * @returns object that contains dropdownOptions-state and a transformer-function to map selectedOptions to actually selected region-ids
 *
 * @param object that defines what regions the current user is permissioned to view.
 *
 * @example
 *
 * const { regionerDropdownOptions, transformSelectedOptionsToRegionIds } = useRegionerDropdownOptions();
 */
const useRegionerDropdownOptions = (dropdownPermissions?: DropdownPermissions, useMultipleKeys=true) => {

  const [regionerDropdownOptions, setRegionerDropdownOptions] = useState<DropdownOption<string>[]>(defaultRegionerDropdownOptions);
  const [regionIdsInDropdown, setRegionIdsInDropdown] = useState<string[]>([]);
  const [danishRegionIds, setDanishRegionIds] = useState<string[]>([]);
  const [allRegionIds, setAllRegionIds] = useState<string[]>([]);

  const { stamdataRegionApi } = useStamdataApi();

  useEffect(() => {
    const fetchRegions = async () => {
      const allRegionsInDenmarkIds = await stamdataRegionApi.getRegionerInDenmark();
      const regions = await stamdataRegionApi.getRegionerSelect();
      const allIds = regions.map(x => x.id);
      const filteredOptions = filterByDropdownPermissions(regions?.map(r => ({ label: r.text, value: r.id })), dropdownPermissions)
          .filter(x => x.label !== Localizer.global_ikkeAngivet()); //Temp filtering for dropdown. We might consider removing "ikke angivet" from database - but it's currently used for 64 laegeinstitutioner.
      const filteredRegionIds = filteredOptions.map(o => o.value);

      const hasAll = allIds.every(id => filteredRegionIds.includes(id));
      const hasAllInDk = allRegionsInDenmarkIds.every(id => filteredRegionIds.includes(id));
      if ((hasAll || hasAllInDk) && useMultipleKeys) {
        filteredOptions.unshift(regionsInDkDropdownOption);
        if (hasAll)
          filteredOptions.unshift(allRegionDropdownOption);
      }

      setAllRegionIds(allIds);
      setDanishRegionIds(allRegionsInDenmarkIds);
      setRegionIdsInDropdown(filteredRegionIds);
      setRegionerDropdownOptions(filteredOptions);
    }

    fetchRegions();
  }, [dropdownPermissions, stamdataRegionApi, useMultipleKeys]);

  const transformSelectedOptionsToRegionIds = useCallback((selectedOptionKeys?: string[]): SelectedRegionIdsResult => {
    const allRegionsKeySelected = selectedOptionKeys?.includes(allRegionsKey);
    const allRegionsInDenmarkKeySelected = selectedOptionKeys?.includes(allDanishRegionsKey);

    if (allRegionsKeySelected) {
      const amountPermissionedIds = (dropdownPermissions?.ids ?? []).values.length;
      return {
        allRegions: amountPermissionedIds === allRegionIds.length || !!dropdownPermissions?.accessToEverything,
        regionIds: regionIdsInDropdown
      };

    } else if (allRegionsInDenmarkKeySelected) {

      const allDanishRegionIdsInDropdown = regionIdsInDropdown.filter((regionId) => danishRegionIds.includes(regionId));
      const allNoneDanishRegionsIdsInDropdown = regionIdsInDropdown.filter((regionId) => !danishRegionIds.includes(regionId));

      const allDanishRegionIdsInDropdownAndSelectedOptions = [
        ...allDanishRegionIdsInDropdown,
        ...allNoneDanishRegionsIdsInDropdown.filter(x => selectedOptionKeys?.includes(x))
      ];

      return { allRegions: false, regionIds: allDanishRegionIdsInDropdownAndSelectedOptions };
    } else {
      return { allRegions: false, regionIds: selectedOptionKeys };
    }
  }, [allRegionIds, danishRegionIds, dropdownPermissions, regionIdsInDropdown]);

  return {
    regionerDropdownOptions,
    transformSelectedOptionsToRegionIds,
    amountOfSelectableRegions: regionIdsInDropdown.length,
    allRegionIds
  }
}

export default useRegionerDropdownOptions;
