import useLogbogApi from "../../../core/hooks/useLogbogApi";
import {useCallback, useEffect, useState} from "react";
import {FilterModel} from "../../../core/sharedmodels/filterModel";
import {
    NotificationModel,
    NotifikationEventTypeEnum
} from "../../../core/components/notifications/models/notificationModel";
import {PaginationModel} from "../../../core/sharedmodels/filter/PaginationModel";
import {NotificationHistoryConstants} from "../NotificationHistoryConstants";

const useNotificationHistory = () => {
    const {logbogNotifikationApi} = useLogbogApi()
    const [notificationHistory, setNotificationHistory] = useState<FilterModel<NotificationModel>>()
    const [isLoading, setIsLoading] = useState(true);
    const [paginationState, setPaginationState] = useState(new PaginationModel(1, 10, 0));
    const [hasFetchedInitial, setHasFetchedInitial] = useState<boolean>(false);

    const getNotifikationHistorik = useCallback(async (page: number, pageLength: number, search?: string, typeEnums?: NotifikationEventTypeEnum[]) => {
        setIsLoading(true);
        const historik = await logbogNotifikationApi.getNotifikationHistorik(page, search, pageLength, typeEnums);
        setPaginationState(current => ({
            ...current,
            recordCount: historik.recordsFiltered,
            page: page ?? NotificationHistoryConstants.InitialPage,
            pageLength: pageLength ?? NotificationHistoryConstants.InitialPageSize
        }))
        setNotificationHistory(historik);
        setIsLoading(false);
    }, [logbogNotifikationApi]);


    useEffect(() => {
        if (!hasFetchedInitial) {
            const fetchInitial = async () => {
                await getNotifikationHistorik(NotificationHistoryConstants.InitialPage, NotificationHistoryConstants.InitialPageSize);
            }
            fetchInitial().then(() => {
                setIsLoading(false)
                setHasFetchedInitial(true)
            });
        }
    }, [getNotifikationHistorik, hasFetchedInitial, logbogNotifikationApi])

    return {
        notificationHistory: notificationHistory,
        paginationState: paginationState,
        refetchNotificationHistory: getNotifikationHistorik,
        isLoading,
    }
}
export default useNotificationHistory;
