import UddannelseslaegerContextProvider from "./uddannelseslaegeOverviewContextProvider";
import UddannelseslaegerList from "./components/uddannelseslagerList";
import useRefreshablePage from "core/infrastructure/routing/hooks/useRefreshablePage";

const UddannelseslaegeOverviewPage = () => {
    const { pageKey } = useRefreshablePage();

    return (
        <UddannelseslaegerContextProvider key={pageKey}>
            <UddannelseslaegerList />
        </UddannelseslaegerContextProvider>
    );
}

export default UddannelseslaegeOverviewPage;