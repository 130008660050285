export class ForloebSkalIndgaaIEvalueringsstatistikInputModel {
    constructor(id: string, skalIndgaaIEvalueringsstatistik: boolean, senesteDatoForEvaluering?: string) {
        this.id = id;
        this.skalIndgaaIEvalueringsstatistik = skalIndgaaIEvalueringsstatistik;
        this.senesteDatoForEvaluering = skalIndgaaIEvalueringsstatistik ? senesteDatoForEvaluering : undefined;
    }
    public id: string;
    public skalIndgaaIEvalueringsstatistik: boolean;
    public senesteDatoForEvaluering?: string;
}
