import {Localizer} from "infrastructure/localization/localizer";
import React, {ReactFragment, ReactNode, useCallback, useEffect, useState} from "react";
import {UddannelseslaegeStatusEnum, UddannelseslaegeStatusEnumToString} from "../UddannelseslaegeStatusEnum";
import {UddannelseslaegerFilterModel, useUddannelseslaegerContext} from "pages/uddannelseslaegeOverview/uddannelseslaegeOverviewContextProvider";
import { toDropDownOptionsSpecialer, toDropDownOptionsVejlederTilknytninger } from "./UddannelsesleagerFilterHelpers";
import useScreenResolution from "core/hooks/useScreenResolution";
import useUser from "../../../../core/hooks/useUser";
import {VejlederAfdelingerModel} from "../vejlederAfdelingerModel";
import {Dropdown, DropdownOption} from "../../../../core/components/dropdown/dropdown";
import useEnumDropdownOptions from "../../../../core/components/dropdown/hooks/useEnumDropdownOptions";
import {MultiValue} from "react-select";
import UddannelseslaegerPageLengthDropdown from "./UddannelseslaegerPageLengthDropdown";
import classNames from "classnames";
import { PaginationModel } from "core/sharedmodels/filter/PaginationModel";
import useUserSettings, { useInitializeViaUserSettings } from "core/hooks/useUserSettings";
import { BrugerIndstillingTypeKeyEnum } from "core/sharedmodels/brugerIndstilling/brugerIndstillingTypeKeyEnum";
import {SearchField} from "../../../../core/components/search/searchField";
import FilterNotation from "../../../../core/components/filter/components/filterNotation";

type UddannelsesleagerFilterProps = {
    additionalNodes?: ReactNode;
    uddannelsessteder: VejlederAfdelingerModel[];
    pagination: PaginationModel;
    onPageLengthChangeCallback?: (n: number) => void;
    fetchUddannelseslaeger: (filter: UddannelseslaegerFilterModel) => Promise<void>;
    totalFilerItems: number;
    filteredItems: number;
}

const UddannelseslaegerFilter = ({additionalNodes, uddannelsessteder, onPageLengthChangeCallback, fetchUddannelseslaeger, totalFilerItems, filteredItems, pagination} : UddannelsesleagerFilterProps) => {
    const { isMobile } = useScreenResolution();
    const { currentUser } = useUser();
    const { setUserSettingValue } = useUserSettings();
    const { state, setFilter } = useUddannelseslaegerContext();

    const [specialeOptions, setSpecialeOptions] = useState<DropdownOption<string>[]>([]);
    const [uddannelsesstederOptions, setUddannelsesstederOptions] = useState<DropdownOption<string>[]>([]);
    const [uddannelseslaegeStatusOptions] = useEnumDropdownOptions<UddannelseslaegeStatusEnum>(UddannelseslaegeStatusEnum, UddannelseslaegeStatusEnumToString);

    useEffect(() => {
        setSpecialeOptions(toDropDownOptionsSpecialer(state.original));
        setUddannelsesstederOptions(toDropDownOptionsVejlederTilknytninger(uddannelsessteder));
    }, [state.original, uddannelsessteder]);

    const dropDownClassNames = classNames("uddannelseslaege-filter margin-right-s", isMobile && "margin-top-s");

    const handleStedOnChange = (uddannelsessted?: string) => {
        const newFilter: UddannelseslaegerFilterModel = {...state.filter, uddannelseSted: uddannelsessted ?? ""};
        setFilter(newFilter);
        fetchUddannelseslaeger(newFilter);
    };

    const handleSpecialeOnChange = (speciale?: string) => {
        const newFilter: UddannelseslaegerFilterModel = {...state.filter, speciale: speciale ?? ""};
        setFilter(newFilter);
        fetchUddannelseslaeger(newFilter);
    };

    const handleStatusOnChange = (status:  MultiValue<DropdownOption<UddannelseslaegeStatusEnum>>) => {
        const statuses = status.map(x => x.value);
        const newFilter: UddannelseslaegerFilterModel = {...state.filter, status: status.map(x => x.value)};
        setFilter(newFilter);
        setUserSettingValue(BrugerIndstillingTypeKeyEnum.PageUddannelseslaegerFilterForloebStatus, statuses);
        fetchUddannelseslaeger(newFilter);
    };

    return (
        <div className={classNames(!isMobile && "flex-space")}>

            {isMobile
                ?
                    <>
                        <div className={dropDownClassNames}>
                            <Dropdown
                                placeholder={Localizer.uddannelsessteder()}
                                options={uddannelsesstederOptions}
                                onChange={(newValue) => handleStedOnChange(newValue?.value)}
                                value={uddannelsesstederOptions.find(x => x.value === state.filter.uddannelseSted)}
                            />
                        </div>

                        <div className={dropDownClassNames}>
                            <Dropdown
                                placeholder={Localizer.AlleSpecialer()}
                                options={specialeOptions}
                                onChange={(newValue) => handleSpecialeOnChange(newValue?.value)}
                                value={specialeOptions.find(x => x.value === state.filter.speciale)}
                            />
                        </div>

                        {!currentUser.IsVejleder() &&
                            <div className={dropDownClassNames}>
                                <Dropdown
                                    placeholder={Localizer.global_all()}
                                    options={uddannelseslaegeStatusOptions}
                                    onChange={(newValue) => handleStatusOnChange(newValue)}
                                    value={uddannelseslaegeStatusOptions?.filter(x => state.filter.status.includes(x.value))}
                                    isMulti
                                />
                            </div>
                        }

                        {additionalNodes &&
                            additionalNodes
                        }

                    </>
                :
                    <div  className="flex-container">
                            {onPageLengthChangeCallback &&
                                <UddannelseslaegerPageLengthDropdown onChangeCallback={onPageLengthChangeCallback}/>
                            }

                            <div className={dropDownClassNames}>
                                <Dropdown
                                    placeholder={Localizer.uddannelsessteder()}
                                    options={uddannelsesstederOptions}
                                    onChange={(newValue) => handleStedOnChange(newValue?.value)}
                                    value={uddannelsesstederOptions.find(x => x.value === state.filter.uddannelseSted)}
                                    minWidth={150}
                                />
                            </div>

                            <div className={dropDownClassNames}>
                                <Dropdown
                                    placeholder={Localizer.AlleSpecialer()}
                                    options={specialeOptions}
                                    onChange={(newValue) => handleSpecialeOnChange(newValue?.value)}
                                    value={specialeOptions.find(x => x.value === state.filter.speciale)}
                                    minWidth={150}
                                />
                            </div>

                            {!currentUser.IsVejleder() &&
                                <div className={dropDownClassNames}>
                                    <Dropdown
                                        placeholder={Localizer.global_all()}
                                        options={uddannelseslaegeStatusOptions}
                                        onChange={(newValue) => handleStatusOnChange(newValue)}
                                        value={uddannelseslaegeStatusOptions?.filter(x => state.filter.status.includes(x.value))}
                                        minWidth={100}
                                        isMulti
                                    />
                                </div>
                            }

                        {additionalNodes &&
                            additionalNodes
                        }
                            <div className={"ml-2 d-flex"} >
                                <FilterNotation 
                                    paginationModel={pagination} 
                                    filteredRecords={filteredItems} 
                                    customTotalRecords={totalFilerItems}
                                    hidePaginatedCount={true}
                                    additionalContainerClasses="d-flex align-items-center"
                                />
                            </div>
                    </div>
                }

            </div>
    );
}

export default UddannelseslaegerFilter;
