import { brevskabelonMenuLink } from "core/layout/shared/MenuLinkProps";
import LeftMenuLink from "../leftMenuLink";

const BrevskabelonMenuLink = ({uddannelseslaegeId} : {uddannelseslaegeId: string }) => {

    const {emblaIconName, linkText, routePath, routePathsToHighlight} = brevskabelonMenuLink(uddannelseslaegeId).MenuLinkProps;

    return (
        <LeftMenuLink
            emblaIconName={emblaIconName}
            linkText={linkText}
            routePath={routePath}
            routePathsToHighlight={routePathsToHighlight ?? []}
        />
    );
}

export default BrevskabelonMenuLink;
