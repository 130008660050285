import React from "react";
import {EmblaIcon} from "../emblaIcon/emblaIcon";
import {ContextMenuDefaultProps} from "./contextMenuDefaultProps";
import classNames from "classnames";
import {EmblaIcons, EmblaIconsType} from "core/emblaIcons";
import {Spinner} from "../spinner/spinner";
import Tooltip from "../tooltips/Tooltip";

export class ContextButtonInfo {
    constructor(onClick: () => void, text: string, iconName?: EmblaIconsType, dataTarget?: string, disabled?: boolean, disabledText?: string) {
        this.onClick = onClick;
        this.text = text;
        this.iconName = iconName;
        this.dataTarget = dataTarget;
        this.disabled = disabled;
        this.disabledText = disabledText;
    }

    onClick: () => void;
    text: string;
    dataTarget?: string;
    disabled?: boolean;
    disabledText?: string;
    iconName?: EmblaIconsType;
}

type ContextButtonMenuProps = ContextMenuDefaultProps & {
    buttons: ContextButtonInfo [];
    overrideIcon?: EmblaIconsType;
    showLine?: boolean;
    useButtonIcons?: boolean;
    isLoading?: boolean;
    loadingTooltipText?: string;
}

export function ContextButtonMenu(props: ContextButtonMenuProps) {
    return (
        <>
            {props.isLoading &&
                <Tooltip title={props.loadingTooltipText}>
                    <div
                        className={"rounded-circle btn btn-default btn-icon btn-round-circle align-content-center margin-right-s"}>
                        <Spinner additionalClasses={"justify-content-center"} spinnerSize="extra-tiny-small"/>
                    </div>
                </Tooltip>
            }
            {!props.isLoading &&
                <>
                    <div
                        className={classNames("btn btn-icon", !!props.border && "btn-default rounded-circle", props.additionalClasses)}
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        aria-label="Default dropdown menu">
                        <EmblaIcon iconName={props.overrideIcon ? props.overrideIcon : EmblaIcons.Overflow}/>
                    </div>
                    <div className="dropdown-menu dropdown-menu-padding-0" aria-labelledby="dropdown-menu-primary">
                        {props.buttons.map((button, index) => (
                            <React.Fragment key={`${button.text}-${index}`}>
                                <div
                                    className="text-truncate"
                                    data-tooltip={button.disabled}
                                    data-html="true"
                                    title={button.disabled ? button.disabledText : ""}
                                >

                                    <button className="dropdown-item margin-right-s"
                                            data-toggle="modal"
                                            data-target={!!button?.dataTarget ? `#${button?.dataTarget}` : undefined}
                                            onClick={button.onClick}
                                            disabled={button.disabled}>
                                        {(!!props.useButtonIcons) &&
                                            <EmblaIcon iconName={button.iconName ?? EmblaIcons.Lightning}
                                                       additionalClasses={classNames("margin-right-s small")}/>
                                        }
                                        {button.text}
                                    </button>
                                    {(props.showLine) &&
                                        <hr className={"line"}/>
                                    }
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                </>
            }
        </>
    )
}
