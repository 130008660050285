import {Localizer} from "../../../infrastructure/localization/localizer";
import {EvalueringPdfModel} from "../../sharedmodels/evaluering/evalueringPdfModel";
import PdfHeaderRow from "./pdfHeaderRow";

type PdfEvalueringStatistikHeaderComponentProps = {
    evaluering?: EvalueringPdfModel;
}

export function PdfEvalueringStatistikHeaderComponent(props: PdfEvalueringStatistikHeaderComponentProps) {
    return (
        <div className={"card shadow-none"}>
            <div className={"card-body flex-container-column flex-space"}>
                <PdfHeaderRow>
                    <PdfHeaderRow.Entry
                        label={Localizer.global_stillingstype()}
                        value={props.evaluering?.uddannelsestype}
                    />
                    <PdfHeaderRow.Entry
                        label={Localizer.afdeling()}
                        value={props.evaluering?.afdeling}
                    />
                </PdfHeaderRow>
                <PdfHeaderRow>
                    <PdfHeaderRow.Entry
                        label={Localizer.sygehus()}
                        value={props.evaluering?.laegeinstitution}
                    />
                    <PdfHeaderRow.Entry
                        label={Localizer.global_speciale()}
                        value={props.evaluering?.speciale}
                    />
                </PdfHeaderRow>
                <PdfHeaderRow>
                    <PdfHeaderRow.Entry
                        label={Localizer.ansaettelsesperiodeStartet()}
                        value={props.evaluering?.forloebStart?.dateWithoutTimeFormat(true)}
                    />
                    <PdfHeaderRow.Entry
                        label={Localizer.global_hovedvejleder()}
                        value={props.evaluering?.hovedvejleder}
                    />
                </PdfHeaderRow>
                <PdfHeaderRow>
                    <PdfHeaderRow.Entry
                        label={Localizer.ansaettelsesperiodeAfsluttet()}
                        value={props.evaluering?.forloebSlut?.dateWithoutTimeFormat(true)}
                    />
                    <PdfHeaderRow.Entry
                        label={Localizer.global_uddannelsesansvarlig()}
                        value={props.evaluering?.uddannelsesansvarlige}
                        useRowRightAlignedValues
                    />
                </PdfHeaderRow>
            </div>
        </div>
    );
}
