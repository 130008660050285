import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState,} from 'app/store';

export interface FilterState {
    filterCount : number
}

const initialState: FilterState = {
    filterCount: 0
};

export const FilterSlice = createSlice({
    name: 'FilterSlice',
    initialState,
    reducers: {
        setFilerCount: (state, action: PayloadAction<number>) => {
            state.filterCount = action.payload;
        }
    }
});

export const {setFilerCount} = FilterSlice.actions;
export const FilterReducer = (state: RootState) => state.filterReducer;
export default FilterSlice.reducer;
