import {EmblaIllustration} from "../emblaIcon/emblaIllustration";
import {Localizer} from "../../../infrastructure/localization/localizer";
import React from "react";

export function ForloebOverviewNoFilterResults() {

    return (
        <>
            <div className={"forloeb-list-empty-state"}>
                <EmblaIllustration illustrationName={"failure-mark"}
                                   additionalClasses={"subtle"}
                />
                <h4
                    className={"subtle"}> {Localizer.forloebpage_NoResultsFromFilter()} </h4>
            </div>
        </>
    )
}
