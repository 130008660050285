import React, {useEffect, useState} from 'react'
import {useAppSelector} from '../../../../app/hooks';
import {
    kompetenceCardReducer,
    KompetenceCardState,
    setActiveLaegekompetenceMeta,
    toggleLaegekompetenceNotatChangedPing} from 'pages/kompetencerPage/cards/kompetenceCardSlice';
import {useDispatch} from 'react-redux';
import {setSlideinState, SlideInStateEnum} from 'core/components/slideIn/slideInSlice';
import {Localizer} from 'infrastructure/localization/localizer';
import {SlideIn} from 'core/components/slideIn/slideIn';
import {NotatType} from "../../../sharedmodels/notat/notatType";
import {forloebReducer, ForloebSliceState} from "../../../forloebSlice";
import {BasicNotatModel} from "../../../sharedmodels/notat/basicNotatModel";
import {EditLaegekompetenceNotat} from "./editLaegekompetenceNotat";
import {NotatCard} from "../notatCard";
import {Loading} from "../../../components/loading/loading";
import useLogbogApi from 'core/hooks/useLogbogApi';

const editNotatModalTarget = "kompetenceNotatListEditNotat";

export function KompetenceNotatList() {
    const dispatch = useDispatch();

    const { notatApi } = useLogbogApi();

    const kompetenceCardState = useAppSelector(kompetenceCardReducer) as KompetenceCardState;
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;
    
    const [laegekompetenceNotater, setLaegekompetenceNotater] = useState<BasicNotatModel[]>([]);
    const [selectedLaegekompetenceNotat, setSelectedLaegekompetenceNotat] = useState<BasicNotatModel | undefined>(undefined);
    const [filesHasChanged, setFilesHasChanged] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const isReadOnly = kompetenceCardState.activeLaegekompetenceMeta.brugerMaalbeskrivelseId !== forloebSliceState.forloebState.brugerMaalbeskrivelseId;

    useEffect(() => {
        const fetchNotater = async () => {
            setIsLoading(true);
            
            const laegekompetenceNotater = await notatApi.getNotaterOnContext(NotatType.Kompetence, kompetenceCardState.activeLaegekompetenceMeta.UserId, kompetenceCardState.activeLaegekompetenceMeta.laegekompetenceId);
            const meritkompetenceNotater = await notatApi.getNotaterOnContext(NotatType.Merit, kompetenceCardState.activeLaegekompetenceMeta.UserId, kompetenceCardState.activeLaegekompetenceMeta.laegekompetenceId);
            
            const kompetenceNotater = 
                laegekompetenceNotater
                    .concat(meritkompetenceNotater)
                    .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
    
            setLaegekompetenceNotater(kompetenceNotater);
            dispatch(setActiveLaegekompetenceMeta({ notatCount: kompetenceNotater.length }));
            setIsLoading(false);
        }
        fetchNotater();
    }, [dispatch, kompetenceCardState.activeLaegekompetenceMeta.UserId, kompetenceCardState.activeLaegekompetenceMeta.laegekompetenceId, notatApi, kompetenceCardState.laegekompetenceNotatChangedPing])

    const onEditNotatClicked = (notat: BasicNotatModel) => {
        setSelectedLaegekompetenceNotat(notat);
        dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: editNotatModalTarget}))
    }

    const onNotatEditFinished = () => {
        setSelectedLaegekompetenceNotat(undefined);
        setFilesHasChanged(false);
        dispatch(toggleLaegekompetenceNotatChangedPing());
    }

    const onNotatEditClosed = () => {
        setSelectedLaegekompetenceNotat(undefined);

        if (filesHasChanged) {
            dispatch(toggleLaegekompetenceNotatChangedPing());
            setFilesHasChanged(false);
        }
    }

    const render = (
        <div className={"margin-m"}>
            {laegekompetenceNotater.length > 0 &&
                <>
                    {laegekompetenceNotater.map(laegekompetenceNotat =>
                        <div className='notatCardContainer' key={laegekompetenceNotat.id}>
                            <div className="notat-card-container">
                                <div className="card">
                                    <NotatCard notat={laegekompetenceNotat} clickEditNotatCallBack={() => onEditNotatClicked(laegekompetenceNotat)} disableEdit={isReadOnly}/>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            }

            {laegekompetenceNotater.length === 0 &&
                <div>{Localizer.noNotat()}</div>
            }

            {selectedLaegekompetenceNotat &&
                <SlideIn
                    key={selectedLaegekompetenceNotat.id}
                    id={editNotatModalTarget}
                    title={Localizer.notatPage_editNotat()}
                    actionText={Localizer.notatPage_editingNotat()}
                    defaultOpen={true}
                    bodyContent={<EditLaegekompetenceNotat
                        modalId={editNotatModalTarget}
                        laegekompetenceNotat={selectedLaegekompetenceNotat}
                        onFilesUpdatedCallback={() => setFilesHasChanged(true)}
                        notatType={selectedLaegekompetenceNotat.type}
                        laegekompetenceId={kompetenceCardState.activeLaegekompetenceMeta.laegekompetenceId}
                        key={selectedLaegekompetenceNotat.id}/>
                    }
                    actionFinishedCallback={onNotatEditFinished}
                    actionOnCloseCallback={onNotatEditClosed}
                />
            }
        </div>
    )

    return (
        <>
            <Loading isLoading={isLoading} text={Localizer.notatPage_gettingNotater()}>
                {render}
            </Loading>
        </>
    )
}
